"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.sageConfigPublic = void 0;
var ENV_1 = require("../../core/@common/constant/ENV");
var config_1 = require("../config");
if (config_1.config.env === ENV_1.ENV.PRODUCTION) {
    exports.sageConfigPublic = {
        prefixUuid: 'TEST',
    };
}
