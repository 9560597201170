"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.menus = void 0;
var PATHS_1 = require("../../@common/constant/PATHS");
var helperUrl_1 = require("../../@common/function/helperUrl");
var PROJECT_URL_1 = require("../../@common/constant/PROJECT_URL");
var CATEGORY_1 = require("../../@common/constant/CATEGORY");
var langServiceBrowser_1 = require("../../@common/service/langServiceBrowser");
function menus(nuxtProp) {
    var langService = langServiceBrowser_1.getLangServiceBrowser();
    return [
        /*
         * * * * * * * * * *
         *
         *   MOBILIER DE JARDIN
         *
         * * * * * * * * * *
         */
        {
            id: 0,
            title: langService.term(11),
            subtitle: langService.term(15),
            links: [
                /*
                 * * * * * * * * * *
                 *
                 *   MOBILIER DE JARDIN | PRODUITS
                 *
                 * * * * * * * * * *
                 */
                {
                    id: 0,
                    value: langService.term(19),
                    img: require('../../@common/assets/menu/jpg/1.jpg'),
                    hrefs: [
                        {
                            id: 5,
                            name: langService.term(309),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_PRODUITS_ENSEMBLE_SALON_DE_JARDIN),
                            img: require('../../@common/assets/database/category/png/11.png')
                        },
                        {
                            id: 0,
                            name: langService.term(20),
                            paths: helperUrl_1.getPathIdsByCategoryId(nuxtProp, CATEGORY_1.CATEGORY.TABLE),
                            img: require('../../@common/assets/database/category/png/2.png')
                        },
                        {
                            id: 1,
                            name: langService.term(306),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_PRODUITS_CHAISES_TABOURETS_BANCS_DE_JARDIN),
                            img: require('../../@common/assets/database/category/png/3.png')
                        },
                        {
                            id: 2,
                            name: langService.term(307),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_PRODUITS_CANAPES_FAUTEUILS_DE_JARDIN),
                            img: require('../../@common/assets/database/category/png/6.png')
                        },
                        {
                            id: 6,
                            name: langService.term(26),
                            paths: helperUrl_1.getPathIdsByCategoryId(nuxtProp, CATEGORY_1.CATEGORY.BAIN_DE_SOLEIL),
                            img: require('../../@common/assets/database/category/png/7.png')
                        },
                        {
                            id: 7,
                            name: langService.term(27),
                            paths: helperUrl_1.getPathIdsByCategoryId(nuxtProp, CATEGORY_1.CATEGORY.PARASOL),
                            img: require('../../@common/assets/database/category/png/8.png')
                        },
                        {
                            id: 24,
                            name: langService.term(46),
                            img: require('../../@common/assets/database/category/png/12.png'),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_TOUT_LE_TECK),
                        },
                    ]
                },
                /*
                 * * * * * * * * * *
                 *
                 *   MOBILIER DE JARDIN | ACCESSOIRE DE JARDIN
                 *
                 * * * * * * * * * *
                 */
                {
                    id: 1,
                    value: langService.term(28),
                    img: require('../../@common/assets/menu/jpg/2.jpg'),
                    hrefs: [
                        {
                            id: 9,
                            name: langService.term(30),
                            paths: helperUrl_1.getPathIdsByCategoryId(nuxtProp, CATEGORY_1.CATEGORY_MINUS_1.COUSSIN_FIXE)
                        },
                        {
                            id: 10,
                            name: langService.term(31),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_ACCESSOIRES_DE_JARDIN_PIECES_DETACHEES),
                        },
                    ]
                },
                /*
                 * * * * * * * * * *
                 *
                 *   MOBILIER DE JARDIN | LES COLLECTIONS
                 *
                 * * * * * * * * * *
                 */
                {
                    id: 2,
                    value: langService.term(33),
                    img: require('../../@common/assets/menu/jpg/3.jpg'),
                    hrefs: [
                    // filled by the file MenuContent.vue with API calls
                    ]
                },
                /*
                 * * * * * * * * * *
                 *
                 *   MOBILIER DE JARDIN | INSPIRATION
                 *
                 * * * * * * * * * *
                 */
                {
                    id: 3,
                    value: langService.term(700),
                    img: require('../../@common/assets/menu/jpg/3.jpg'),
                    hrefs: [
                        {
                            id: 1,
                            name: langService.term(701),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_MOBILIER_DE_JARDIN),
                        },
                        {
                            id: 2,
                            name: langService.term(702),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_ENSEMBLE_DE_JARDIN),
                        },
                        {
                            id: 3,
                            name: langService.term(703),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN),
                        },
                        {
                            id: 4,
                            name: langService.term(704),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_EN_ALUMINIUM),
                        },
                        {
                            id: 5,
                            name: langService.term(705),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_DE_LUXE),
                        },
                        {
                            id: 6,
                            name: langService.term(706),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_DESIGN),
                        },
                        {
                            id: 7,
                            name: langService.term(707),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_EN_BOIS),
                        },
                        {
                            id: 8,
                            name: langService.term(708),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_EN_TECK),
                        },
                        {
                            id: 9,
                            name: langService.term(709),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_HAUT_DE_GAMME),
                        },
                        {
                            id: 10,
                            name: langService.term(710),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_ROND),
                        },
                        {
                            id: 11,
                            name: langService.term(711),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_BALCON),
                        },
                        {
                            id: 12,
                            name: langService.term(712),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_8_PERSONNES),
                        },
                        {
                            id: 13,
                            name: langService.term(713),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_10_PERSONNES),
                        },
                        {
                            id: 14,
                            name: langService.term(714),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_CHAISE_DE_JARDIN),
                        },
                        {
                            id: 15,
                            name: langService.term(715),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_CHAISE_DE_JARDIN_PLIANTE),
                        },
                    ]
                },
            ]
        },
        /*
         * * * * * * * * * *
         *
         *   LUMINAIRE SOLAIRE
         *
         * * * * * * * * * *
         */
        {
            id: 1,
            title: langService.term(12),
            subtitle: langService.term(378),
            links: [
                /*
                 * * * * * * * * * *
                 *
                 *   LUMINAIRE SOLAIRE | PRODUITS
                 *
                 * * * * * * * * * *
                 */
                {
                    id: 3,
                    value: langService.term(52),
                    img: require('../../@common/assets/menu/jpg/4.jpg'),
                    hrefs: [
                        {
                            id: 34,
                            name: langService.term(56),
                            paths: helperUrl_1.getPathIdsByCategoryId(nuxtProp, CATEGORY_1.CATEGORY_MINUS_1.LANTERNE),
                            img: require('../../@common/assets/pictogram/kit-3/nomade.png')
                        },
                        {
                            id: 32,
                            name: langService.term(55),
                            paths: helperUrl_1.getPathIdsByCategoryId(nuxtProp, CATEGORY_1.CATEGORY_MINUS_1.CENTRE_DE_TABLE),
                            img: require('../../@common/assets/pictogram/kit-3/lampe-a-poser.png')
                        },
                        {
                            id: 31,
                            name: langService.term(54),
                            paths: helperUrl_1.getPathIdsByCategoryId(nuxtProp, CATEGORY_1.CATEGORY_MINUS_1.LAMPADAIRE),
                            img: require('../../@common/assets/pictogram/kit-1/shower_1.png')
                        },
                        {
                            id: 30,
                            name: langService.term(53),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.LUMINAIRES_SOLAIRES_TORCHES_BORNES_ET_LAMPADAIRES_SOLAIRES),
                            img: require('../../@common/assets/pictogram/kit-3/torche.png')
                        },
                        {
                            id: 35,
                            name: langService.term(57),
                            paths: helperUrl_1.getPathIdsByCategoryId(nuxtProp, CATEGORY_1.CATEGORY_MINUS_1.APPLIQUE_MURAL),
                            img: require('../../@common/assets/pictogram/kit-1/lamp_1.png')
                        },
                        {
                            id: 36,
                            name: langService.term(58),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.LUMINAIRES_SOLAIRES_ACCESSOIRES),
                            img: require('../../@common/assets/pictogram/kit-3/accessoire.png')
                        },
                    ]
                },
                /*
                 * * * * * * * * * *
                 *
                 *   LUMINAIRE SOLAIRE | LES COLLECTIONS
                 *
                 * * * * * * * * * *
                 */
                {
                    id: 4654,
                    value: langService.term(33),
                    img: require('../../@common/assets/menu/jpg/4.jpg'),
                    hrefs: [
                    // filled by the file MenuContent.vue with API calls
                    ]
                },
                /*
                 * * * * * * * * * *
                 *
                 *   LUMINAIRE SOLAIRE | INSPIRATION
                 *
                 * * * * * * * * * *
                 */
                {
                    id: 4655,
                    value: langService.term(716),
                    img: require('../../@common/assets/menu/jpg/4.jpg'),
                    hrefs: [
                        {
                            id: 1,
                            name: langService.term(717),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.LUMINAIRE_SOLAIRE_INSPIRATION_LAMPE_SOLAIRE_DE_JARDIN),
                        },
                        {
                            id: 2,
                            name: langService.term(718),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.LUMINAIRE_SOLAIRE_INSPIRATION_APPLIQUE_EXTERIEUR),
                        },
                        {
                            id: 3,
                            name: langService.term(719),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.LUMINAIRE_SOLAIRE_INSPIRATION_APPLIQUE_DE_JARDIN),
                        },
                        {
                            id: 4,
                            name: langService.term(720),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.LUMINAIRE_SOLAIRE_INSPIRATION_LAMPADAIRE_SOLAIRE_DE_JARDIN),
                        },
                    ]
                },
            ]
        },
        /*
         * * * * * * * * * *
         *
         *   OUTLET
         *
         * * * * * * * * * *
         */
        {
            id: 2,
            title: langService.term(13),
            subtitle: langService.term(17),
            links: [
                /*
                 * * * * * * * * * *
                 *
                 *   OUTLET | PRODUITS
                 *
                 * * * * * * * * * *
                 */
                {
                    id: 6,
                    value: langService.term(59),
                    img: require('../../@common/assets/menu/jpg/5.jpg'),
                    hrefs: [
                        {
                            id: 37,
                            name: langService.term(310),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.OUTLET_PRODUITS_ENSEMBLE_SALON_DE_JARDIN),
                        },
                        {
                            id: 39,
                            name: langService.term(62),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.OUTLET_PRODUITS_TABLES_DE_JARDIN),
                        },
                        {
                            id: 40,
                            name: langService.term(63),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.OUTLET_PRODUITS_CHAISES_TABOURETS_BANCS_DE_JARDIN),
                        },
                        {
                            id: 41,
                            name: langService.term(64),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.OUTLET_PRODUITS_CANAPES_FAUTEUILS_DE_JARDIN),
                        },
                        {
                            id: 42,
                            name: langService.term(65),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.OUTLET_PRODUITS_BAINS_DE_SOLEIL),
                        },
                    ]
                },
                /*
                 * * * * * * * * * *
                 *
                 *   OUTLET | LES COLLECTIONS
                 *
                 * * * * * * * * * *
                 */
                {
                    id: 7,
                    value: langService.term(67),
                    img: require('../../@common/assets/menu/jpg/5.jpg'),
                    hrefs: [
                    // filled by the file MenuContent.vue with API calls
                    ]
                },
            ]
        },
        /*
         * * * * * * * * * *
         *
         *   MARQUE
         *
         * * * * * * * * * *
         */
        {
            id: 3,
            title: langService.term(14),
            subtitle: langService.term(18),
            links: [
                /*
                 * * * * * * * * * *
                 *
                 *   MARQUE | STORY LES JARDINS
                 *
                 * * * * * * * * * *
                 */
                {
                    id: 9,
                    value: langService.term(77),
                    img: require('../../@common/assets/menu/jpg/6.jpg'),
                    hrefs: [
                        {
                            id: 1,
                            name: langService.term(727),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MARQUE_DECOUVRIR_LES_JARDINS),
                        },
                        {
                            id: 2,
                            name: langService.term(728),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MARQUE_CONCEPTION_ET_FABRICATION),
                        },
                        {
                            id: 3,
                            name: langService.term(729),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MARQUE_NOS_ENGAGEMENT),
                        },
                        {
                            id: 4,
                            name: langService.term(730),
                            url: helperUrl_1.getUrlByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MARQUE_LES_SOLAIRES),
                        },
                    ]
                },
                /*
                 * * * * * * * * * *
                 *
                 *   MARQUE | AIDE / SUPPORT
                 *
                 * * * * * * * * * *
                 */
                {
                    id: 13,
                    value: langService.term(89),
                    img: require('../../@common/assets/menu/jpg/6.jpg'),
                    hrefs: [
                        {
                            id: 64,
                            name: langService.term(91),
                            paths: [PATHS_1.PATHS.FAQ],
                        },
                    ]
                },
            ]
        },
    ];
}
exports.menus = menus;
