"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProjectUrl = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Url_1 = require("./Url");
var ProjectUrl = /** @class */ (function () {
    function ProjectUrl(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.urls = deltaService_1.deltaService.array(props.urls, Url_1.Url);
    }
    return ProjectUrl;
}());
exports.ProjectUrl = ProjectUrl;
