<style lang="scss">

@import "../../scss/color";

/*
 * * * * * * * * * * * * * * * * * * * * * *
 *
 *   OVERRIDE
 *
 * * * * * * * * * * * * * * * * * * * * * *
 */

.App-admin .ButtonAction button {
  background: black !important;
  padding: 7px !important;
}

/* message */

.el-message {
  min-width: 300px;
  text-align: center;
  align-items: flex-start;
}

.el-message .el-icon-close {
  padding-left: 5px;
}

/* CHAT */

.sc-chat-window.opened {
  z-index: 9999 !important;
}

.sc-launcher.opened {
  z-index: 9999 !important;
}

/* INPUT NUMBER */

.el-input-number {
  width: 130px !important;
}

.el-input-number--mini {
  width: 90px !important;
}

.el-input-number .el-input-number__decrease {
  background: none !important;
  border-right: none !important;
  border-radius: 50px 0 0 50px !important;
}

.el-input-number .el-input-number__increase {
  background: none !important;
  border-left: none !important;
  border-radius: 0 50px 50px 0 !important;
}

.el-input-number .el-input__inner {
  border-radius: 50px !important;
  border: 2px solid rgba(0, 0, 0, 0.1);
  font-weight: bold !important;
}

/* OTHER */

.el-dialog__body {
  word-break: normal !important;
}

.el-select {
  display: block !important;
}

.el-input {
  display: block !important;
}

.el-tooltip__popper {
  font-family: 'ComfortaaRegular' !important;
  font-size: 9px !important;
  text-transform: uppercase !important;
  letter-spacing: 1px !important;
}

.el-notification {
  font-family: 'ComfortaaRegular' !important;
}

.el-notification__title {
  position: relative !important;
  top: 4px !important;
}

.el-notification__content {
  text-align: left !important;
  font-size: 12px !important;
  line-height: 20px;
  padding-top: 8px;
}

.el-alert {
  font-family: 'ComfortaaRegular' !important;
  font-size: 12px !important;
}

.el-alert__description {
  margin: 0 !important;
  padding: 5px 0;
  line-height: 18px;
}

.el-alert__title {
  display: inline-block;
}

.el-alert__title:first-letter {
  text-transform: uppercase;
}

.el-alert__closebtn {
  top: 15px !important;
}

.el-avatar {
  color: rgba(0, 0, 0, 1) !important;
  background: rgba(0, 0, 0, 0.05) !important;
  font-size: 12px !important;
}

.el-date-editor.el-input {
  width: 100% !important;
  box-sizing: border-box;
}

.el-date-editor.el-input {
  width: 100% !important;
  box-sizing: border-box;
}


.el-tabs__nav-wrap::after {
  z-index: auto !important;
}

.el-tabs__nav {
  z-index: auto !important;
}

.el-tabs__active-bar {
  z-index: auto !important;
  bottom: 2px;
}

/*
 * * * * * * * * * * * * * * * * * * * * * *
 *
 *
 *
 * * * * * * * * * * * * * * * * * * * * * *
 */

.App {

}

.App--zindex {
  /* default min z-index of element ui */
  z-index: 2000;
}

</style>

<template>
  <div class="App" :class="class_root" :style="style_root">
    <Menu v-if="!admin"
          :fixed="menuFixed"
          :onFixedChange="onFixedChange"
          :showBackground="showBackgroundMenu"
          :onShowBackgroundChange="onShowBackgroundChange"
          :nuxtProp="nuxtProp">
      <slot/>
    </Menu>
    <AdminMenu v-else :nuxtProp="nuxtProp">
      <slot/>
    </AdminMenu>

    <AdminContext v-show="displayContextAdmin" :nuxtProp="nuxtProp"></AdminContext>

    <CookieBanner></CookieBanner>
    <GoogleAnalytics></GoogleAnalytics>
    <GoogleTagManager :nuxtProp="nuxtProp"></GoogleTagManager>
    <Chat :nuxtProp="nuxtProp"></Chat>
    <TermModal></TermModal>

    <!--    <Debug></Debug>-->
  </div>
</template>

<script>

import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import Scrollbar from '@bbx/scrollbar~master/core/env/browser/ts/Scrollbar';
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";

import {stateService, STATE_ROLE_ID} from "../../service/stateService";

import {ROLE} from '../../../@common/constant/ROLE'
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {eventService} from "../../service/eventService";
import {EVENT} from "../../../@common/constant/EVENT";
import {scrollerParentService} from "../../service/scrollerParentService";

export default {
  props: {
    admin: {
      type: Boolean,
      default: () => false
    },
    menuFixed: {
      type: Boolean,
      default: () => true
    },
    onFixedChange: {
      type: Function,
      default: () => () => {
      }
    },
    showBackgroundMenu: {
      type: Boolean,
      default: () => true
    },
    onShowBackgroundChange: {
      type: Function,
      default: () => () => {
      }
    },
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      state: {
        rolId: false,
        scrollbarSize: 0,
      },
      events: []
    }
  },
  computed: {
    displayContextAdmin() {
      const roles = [
        ROLE.ROOT,
        ROLE.ADMIN,
        ROLE.SEO
      ]
      return roles.includes(this.state.rolId)
    },
    class_root() {
      const classes = []
      if (this.admin) classes.push('App-admin')
      if (this.nuxtProp.loading) classes.push('App-loading')
      return classes
    },
    style_root() {
      let style = {}
      return style
    }
  },
  beforeMount() {

    // -----

    this.state.scrollbarSize = new Scrollbar().getNativeSize()

    // -----

    this.state.rolId = stateService.get(STATE_ROLE_ID)
    stateService.watch(STATE_ROLE_ID, v => this.state.rolId = v)

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.DISPLAY_SCROLLBAR,
      callback: v => {
        let el = scrollerParentService.elementHTML
        if (el) {
          v ? el.style.overflowY = 'auto' : el.style.overflowY = 'hidden'
        }
      }
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {

    window.addEventListener('resize', this.onResize);

    // -----

    scrollerParentService.elementHTML = document.querySelector('html')

    scrollerParentService.setScrollLeft = (scrollLeft) => {
      window.scrollTo(scrollLeft, scrollerParentService.scrollTop)
    }

    scrollerParentService.setScrollTop = (scrollTop) => {
      window.scrollTo(scrollerParentService.scrollLeft, scrollTop)
    }

    scrollerParentService.scrollTo = (scrollLeft, scrollTop) => {
      window.scrollTo(scrollLeft, scrollTop)
    }

    window.addEventListener("touchstart", this.onTouchStart);

    window.addEventListener('scroll', this.onScroll);
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
    window.removeEventListener('scroll', this.onScroll);
    window.removeEventListener('resize', this.onResize);
    window.removeEventListener("touchstart", this.onTouchStart);
  },
  methods: {
    onScroll(event) {

      let left = 0
      let top = 0

      if (event.target.constructor.name === 'HTMLDocument') {
        left = window.scrollX
        top = window.scrollY
      } else {
        left = event.target.scrollLeft
        top = event.target.scrollTop
      }

      scrollerParentService.scrollLeft = left
      scrollerParentService.scrollTop = top

      eventService.triggerByName(new ListenerTrigger({
        name: EVENT.SCROLL_CHANGE,
        payload: {
          left: left,
          top: top,
        }
      }))
    },
    onTouchStart() {
      scrollerParentService.isTactile = true
    },
    onResize() {
      eventService.triggerByName(new ListenerTrigger({
        name: EVENT.SIZE_CHANGED,
      }))
    },
  }
}

</script>
