<style scoped>
.MarqueHeader {

}

.MarqueHeader--content {
  position: relative;
}

.MarqueHeader-desktop .MarqueHeader--content {
  height: 500px;
}

.MarqueHeader-tablet .MarqueHeader--content {
  height: 500px;
}

.MarqueHeader-mobile .MarqueHeader--content {
  height: 200px;
}

/**/

.MarqueHeader--picture {
  height: 100%;
}

.MarqueHeader--mask {
  background: rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

/**/

.MarqueHeader--term {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  color: #ffffff;
  font-family: 'AmbroiseRegular', sans-serif;
}

.MarqueHeader-desktop .MarqueHeader--term {
  font-size: 70px;
}

.MarqueHeader-tablet .MarqueHeader--term {
  font-size: 60px;
}

.MarqueHeader-mobile .MarqueHeader--term {
  font-size: 30px;
  padding: 0 20px;
  box-sizing: border-box;
}
</style>

<template>
  <div class="MarqueHeader" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <div class="MarqueHeader--content">
        <div class="MarqueHeader--picture">
          <Picture :src="imgSrc"></Picture>
        </div>
        <div class="MarqueHeader--mask"></div>
        <div class="MarqueHeader--term">
          <div>
            <AnimationTitle :fully="false">
              <Term :term="term" tag="h1"></Term>
            </AnimationTitle>
          </div>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>

// -----

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {eventService} from "../../service/eventService";
import {Term} from "../../../@common/delta/database/Term";

// -----

export default {
  props: {
    term: {
      type: Term,
      default: () => new Term()
    },
    imgSrc: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              width: 0
            }),
            new BreakPoint({
              name: SCREEN.TABLET,
              width: 1024
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              width: 1440
            }),
          ],
          verticalBreakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              height: 0
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              height: 600
            }),
          ],
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      state: {},
      events: []
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`MarqueHeader-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`MarqueHeader-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`MarqueHeader-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`MarqueHeader-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`MarqueHeader-desktopY`)
      return classes
    }
  },
  beforeMount() {
    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {}
}
</script>