<style scoped>
.AdminPage {
}
</style>

<template>
  <div class="AdminPage">
    <Pages/>
  </div>
</template>

<script>

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

export default {
  props: {},
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {},
      state: {},
    }
  },
  watch: {},
  computed: {},
  beforeMount() {

  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
