"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getBasketCount = void 0;
function getBasketCount(baskets) {
    var count = 0;
    for (var _i = 0, baskets_1 = baskets; _i < baskets_1.length; _i++) {
        var basket = baskets_1[_i];
        count += basket.quantity;
    }
    return count;
}
exports.getBasketCount = getBasketCount;
