<style scoped>
.PaymentMethods {
}

.PaymentMethods--card {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: space-between;
  margin-bottom: 25px;
  padding: 15px 25px;
  cursor: pointer;
}

.PaymentMethods--left {
  display: flex;
  align-items: center;
}

.PaymentMethods--cards {
  display: flex;
  align-items: center;
}

.PaymentMethods--cardB {
  margin-left: 10px;
  width: 48px;
  height: 32px;
}

.PaymentMethods--checkbox {
  width: 18px;
  height: 18px;
  padding-right: 15px;
}


.PaymentMethods--multiple {
  cursor: pointer;
  /*cursor: not-allowed;*/
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 15px 30px;
  display: flex;
  align-items: center;
  opacity: 1;
}

.PaymentMethods-spplus .PaymentMethods--multiple {

}

.PaymentMethods-paypal .PaymentMethods--multiple {
  cursor: not-allowed;
  opacity: 0.5;
}

.PaymentMethods-empty .PaymentMethods--multiple {
  cursor: not-allowed;
  opacity: 0.5;
}

.PaymentMethods-loading .PaymentMethods--multiple {
  cursor: progress;
  opacity: 0.5;
}

.PaymentMethods--multipleBig {
  font-size: 40px;
  font-family: 'DDinRegular', sans-serif;
  padding-right: 25px;
}

.PaymentMethods--multipleSmall {
  display: flex;
  font-family: 'DDinRegular', sans-serif;
  color: rgba(0, 0, 0, 0.75);
}
</style>

<template>
  <div class="PaymentMethods" :class="class_root">
    <InputLabel :term="lang.term(321)" :svg="icon.credit_card"/>
    <br>
    <br>
    <div class="PaymentMethods--card" @click="() => changePayment('spplus')">
      <div class="PaymentMethods--left">
        <div class="PaymentMethods--checkbox">
          <Vector :svg="state.payment === 'spplus' ? icon.check_box : icon.check_box_outline_blank"></Vector>
        </div>
        <div>
          <Term :term="lang.term(388)"/>
        </div>
      </div>
      <div class="PaymentMethods--cards">
        <div class="PaymentMethods--cardB">
          <Picture :src="picture.visa"></Picture>
        </div>
        <div class="PaymentMethods--cardB">
          <Picture :src="picture.mastercard"></Picture>
        </div>
      </div>
    </div>
    <div class="PaymentMethods--card" @click="() => changePayment('paypal')">
      <div class="PaymentMethods--left">
        <div class="PaymentMethods--checkbox">
          <Vector :svg="state.payment === 'paypal' ? icon.check_box : icon.check_box_outline_blank"></Vector>
        </div>
        <div>
          <Term :term="lang.term(389)"/>
        </div>
      </div>
      <div class="PaymentMethods--cards">
        <div class="PaymentMethods--cardB">
          <Picture :src="picture.paypal"></Picture>
        </div>
      </div>
    </div>

    <br>
    <br>

    <template v-if="canLoan || canDeposit">
      <InputLabel :term="lang.term(329)" :svg="icon.event_available"/>
    </template>

    <template v-if="canLoan">
      <br>
      <div class="PaymentMethods--multiple" @click="() => toggleLoan()">
        <div class="PaymentMethods--multipleBig">3X</div>
        <div class="PaymentMethods--multipleSmall">

          <div class="PaymentMethods--checkbox">
            <Vector :svg="state.hasLoan ? icon.check_box : icon.check_box_outline_blank"></Vector>
          </div>
          <Term :term="lang.term(390)"/>
        </div>
      </div>
    </template>

    <template v-if="canDeposit">
      <template v-for="shop in order.shops">
        <template v-for="deposit in shop.deposits">
          <br>
          <div class="PaymentMethods--multiple" @click="() => toggleDeposit()">
            <div class="PaymentMethods--multipleBig">{{ Math.round(deposit.percentage) }}%</div>
            <div class="PaymentMethods--multipleSmall">
              <div class="PaymentMethods--checkbox">
                <Vector :svg="state.hasDeposit ? icon.check_box : icon.check_box_outline_blank"></Vector>
              </div>
              <Term :term="lang.term(584)"/>
            </div>
          </div>
        </template>
      </template>
    </template>


    <br>
    <Divider></Divider>
    <br>
    <div>
      <InputCheckbox :value="approved" :onChangeValue="onApprovedChange" :label="lang.term(585)"></InputCheckbox>
      <br>
      <InputError v-if="form.display('approved').id" :term="form.display('approved')"></InputError>
    </div>

  </div>
</template>

<script>

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {messageAlert} from "../../function/message";
import {Order} from "../../../@common/delta/database/Order";
import {getBasketTotal} from "../../../@common/function/getBasketTotal";
import {PAYMENT} from "../../../@common/constant/PAYMENT";
import {STATE_ROLE_ID, stateService} from "../../service/stateService";
import {Funding} from "../../../@common/delta/database/Funding";
import {Deposit} from "../../../@common/delta/database/Deposit";
import {FundingOptionList} from "../../../@common/delta/database/FundingOptionList";
import {FUNDING_OPTION_LIST} from "../../../@common/constant/FUNDING_OPTION_LIST";
import {Loan} from "../../../@common/delta/database/Loan";
import {LOAN} from "../../../@common/constant/LOAN";
import {Period} from "../../../@common/delta/database/Period";
import {PERIOD} from "../../../@common/constant/PERIOD";
import {ROLE} from "../../../@common/constant/ROLE";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import InputCheckbox from "../input/InputCheckbox";

export default {
  components: {InputCheckbox},
  props: {
    order: {
      default: () => new Order()
    },
    form: {
      type: ApiResponseForm,
      default: () => new ApiResponseForm(),
    },
    transports: {
      default: () => ([])
    },
    onPaymentChose: {
      default: () => () => {
      }
    },
    onOptionChange: {
      default: () => () => {
      }
    },
    multiple: {
      default: () => () => {
      }
    },
    onOrderChange: {
      type: Function,
      default: () => (order) => {
      }
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    approved: {
      type: Boolean,
      default: () => false
    },
    onApprovedChange: {
      type: Function,
      default: () => () => {
      }
    },
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {},
      icon: {
        credit_card: require('@bbx/vector~master/core/assets/svg/material/credit_card.svg'),
        check_box_outline_blank: require('@bbx/vector~master/core/assets/svg/material/check_box_outline_blank.svg'),
        check_box: require('@bbx/vector~master/core/assets/svg/material/check_box.svg'),
        event_available: require('@bbx/vector~master/core/assets/svg/material/event_available.svg'),
      },
      picture: {
        visa: require('../../../@common/assets/payment/1.png'),
        mastercard: require('../../../@common/assets/payment/2.png'),
        american_express: require('../../../@common/assets/payment/white/3.png'),
        paypal: require('../../../@common/assets/payment/4.png'),
      },
      state: {
        /**
         * @type Order
         */
        order: this.order,
        /**
         * @type number
         */
        roleId: 0,
        /**
         * @type string
         */
        payment: '',
        /**
         * @type boolean
         */
        hasLoan: false,
        /**
         * @type boolean
         */
        hasDeposit: false,
      },
    }
  },
  watch: {
    'state.hasLoan': {
      handler: function (v) {
        this.onOptionChange(v ? FUNDING_OPTION_LIST.LOAN : 0)
      }
    },
    'state.hasDeposit': {
      handler: function (v) {
        this.onOptionChange(v ? FUNDING_OPTION_LIST.DEPOSIT : 0)
      }
    },
    'payment': {
      handler: function (v) {
        this.state.payment = v
      }
    },
    'state.payment': {
      handler: function (v) {
        this.onPaymentChose(v)
      }
    },
    'order': function (v) {
      this.state.order = v
    },
    'state.order': {
      handler: function (v) {
        this.onOrderChange(v)
      },
      deep: true
    },
  },
  computed: {
    class_root() {
      const classes = []
      if (this.state.payment === 'spplus') classes.push(`PaymentMethods-spplus`)
      if (this.state.payment === 'paypal') classes.push(`PaymentMethods-paypal`)
      if (this.state.payment === '') classes.push(`PaymentMethods-empty`)
      if (this.loading) classes.push(`PaymentMethods-loading`)
      return classes
    },
    getBasketTotal() {
      return getBasketTotal({
        order: this.order,
        transports: this.transports,
        roleId: this.state.roleId
      })
    },
    canLoan() {
      return this.state.roleId !== ROLE.SELLER
    },
    canDeposit() {
      let can = false
      for (let shop of this.state.order.shops) {
        for (let deposit of shop.deposits) {
          deposit = new Deposit(deposit)
          can = deposit.active
        }
      }
      return can
    },
  },
  beforeMount() {
    this.state.roleId = stateService.get(STATE_ROLE_ID)
    stateService.watch(STATE_ROLE_ID, v => this.state.roleId = v)

    this.clean([
      'hasLoan',
      'hasDeposit'
    ])
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {

    clean(includes) {
      for (const funding of this.state.order.fundings) {
        funding.fundingOptionLists = []
        funding.deposits = []
        funding.loans = []
      }

      if (includes.includes('hasLoan')) this.state.hasLoan = false
      if (includes.includes('hasDeposit')) this.state.hasDeposit = false
    },

    changePayment(type) {
      this.state.payment = type;
      if (type === 'paypal') {
        this.state.hasLoan = false
        this.state.hasDeposit = false
        for (const funding of this.state.order.fundings) {
          funding.deposits = []
          funding.loans = []
        }
      }
    },

    toggleLoan() {

      // -----

      if (this.loading) return
      if (this.state.payment === '') return messageAlert(`Choisissez un mode de paiement, Paypal ou Carte bancaire pour activer le 3X sans frais`)
      if (this.state.payment === 'paypal') return messageAlert(`Le paiement en plusieurs fois n'est pas disponible avec Paypal`)
      if (this.getBasketTotal.totalTTC < PAYMENT.MIN_FOR_MULTIPLE) return messageAlert(`Le paiement en plusieurs fois est disponible à partir de ${PAYMENT.MIN_FOR_MULTIPLE} €`)

      // -----

      this.clean(['hasDeposit'])

      // -----

      this.state.hasLoan = !this.state.hasLoan

      // -----
      // create the loan
      // -----

      if (this.state.hasLoan) {
        if (!this.state.order.fundings) this.state.order.fundings = []
        if (!this.state.order.fundings.length) this.state.order.fundings = [
          new Funding()
        ]

        for (const funding of this.state.order.fundings) {
          funding.fundingOptionLists = [
            new FundingOptionList({
              id: FUNDING_OPTION_LIST.LOAN
            })
          ]
          funding.loans = [
            new Loan({
              id: LOAN.TROIS_FOIS_SANS_FRAIS,
              occurrence: 3,
              periods: [
                new Period({
                  id: PERIOD.MONTHLY
                })
              ]
            })
          ]
        }
      }

      // -----
      // remove the loan
      // -----

      if (!this.state.hasLoan) {
        for (const funding of this.state.order.fundings) {
          funding.fundingOptionLists = []
          funding.loans = []
        }
      }
    },

    toggleDeposit() {

      // -----

      if (this.loading) return
      if (this.state.payment === '') return messageAlert(`Choisissez un mode de paiement, Paypal ou Carte bancaire pour activer votre acompte`)
      if (this.state.payment === 'paypal') return messageAlert(`Le paiement avec l'acompte n'est pas disponible avec Paypal`)
      if (this.getBasketTotal.totalTTC < PAYMENT.MIN_FOR_MULTIPLE) return messageAlert(`Le paiement en plusieurs fois est disponible à partir de ${PAYMENT.MIN_FOR_MULTIPLE} €`)

      // -----

      this.clean(['hasLoan'])

      // -----

      this.state.hasDeposit = !this.state.hasDeposit

      // -----
      // create the deposit from shop
      // -----

      if (this.state.hasDeposit) {
        if (!this.state.order.fundings) this.state.order.fundings = []
        if (!this.state.order.fundings.length) this.state.order.fundings = [
          new Funding()
        ]

        let deposit = new Deposit()
        for (const shop of this.state.order.shops) {
          deposit = new Deposit(shop.deposits[0])
        }
        for (const funding of this.state.order.fundings) {
          funding.fundingOptionLists = [
            new FundingOptionList({
              id: FUNDING_OPTION_LIST.DEPOSIT
            })
          ]
          funding.deposits = [
            deposit
          ]
        }
      }

      // -----
      // remove deposit
      // -----

      if (!this.state.hasDeposit) {
        for (const funding of this.state.order.fundings) {
          funding.fundingOptionLists = []
          funding.deposits = []
        }
      }
    },
  }
}
</script>
