import { render, staticRenderFns } from "./Async.vue?vue&type=template&id=23aa0bca&scoped=true&"
import script from "./Async.vue?vue&type=script&lang=js&"
export * from "./Async.vue?vue&type=script&lang=js&"
import style0 from "./Async.vue?vue&type=style&index=0&id=23aa0bca&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "23aa0bca",
  null
  
)

export default component.exports