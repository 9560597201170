<style scoped>
.AdminProduct {
  padding: 0px 50px 50px 50px;
}
</style>

<template>
  <div class="AdminProduct">
    <el-tabs type="card" v-model="state.tab">
      <el-tab-pane label="Articles" name="article">
        <ArticleAdmin v-if="state.tab === 'article'"></ArticleAdmin>
      </el-tab-pane>
      <el-tab-pane label="Collections" name="collection">
        <CollectionAdmin v-if="state.tab === 'collection'"></CollectionAdmin>
      </el-tab-pane>
      <el-tab-pane label="Matériaux & Couleurs" name="material">
        <MaterialAdmin v-if="state.tab === 'material'"></MaterialAdmin>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

export default {
  props: {},
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {},
      state: {
        /**
         * @type string
         */
        tab: 'article',
      },
    }
  },
  watch: {},
  computed: {},
  beforeMount() {

  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
