<style scoped>
.FindShop {
  height: 100%;
}

.FindShop ::v-deep .LoaderSpace {
  height: auto;
}

.FindShop--googleMap {
  width: 100%;
  height: 40%;
  background: rgba(0, 0, 0, 0.03);
}

.FindShop--searchBox {
  width: 100%;
  height: 60%;
  overflow: auto;
}

.FindShop--search {
  padding: 50px 50px 25px 50px;
}

.FindShop-mobile .FindShop--search {
  padding: 25px 25px 0px 25px;
}

.FindShop--shops {
  padding: 0px 25px 25px 25px;
}

.FindShop-mobile .FindShop--shops {
  padding: 0px 0px 25px 0px;
}

.FindShop--shop {
  margin: 0px 25px 25px 25px;
  padding: 25px;
  background: rgba(0, 0, 0, 0.03);
  border-left: solid 1px rgba(0, 0, 0, 0);
}

.FindShop--shop-selected.FindShop--shop {
  border-left: solid 1px rgba(0, 0, 0, 1);
}

.FindShop--shopHeader {
  display: flex;
  align-items: center;
  padding-bottom: 15px;
}

.FindShop--shopHeaderIcon {
  width: 24px;
  padding-right: 15px;
}

.FindShop--shopHeaderName {
  font-family: 'RalewayBold', sans-serif;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex: 1;
}

.FindShop--tags {
  display: flex;
  justify-content: flex-end;
}

.FindShop--tags ::v-deep .Tag {
  padding-left: 4px;
}


.FindShop--shopBody {
  font-family: 'ComfortaaRegular', sans-serif;
  font-size: 14px;

}

.FindShop--shopBodyAddress {

}

.FindShop--shopBodyAddressStreet {
  padding-bottom: 4px;
}

.FindShop--shopBodyAddressStreetCity {
  text-transform: uppercase;
}

.FindShop--shopBodyPhoneNumbers {
  padding-top: 15px;
  color: #8A6C59;
}

.FindShop--shopBodyPhoneNumber {

}
</style>

<template>
  <div class="FindShop" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="onBreakPointChange"
                :onVerticalBreakPointChange="onVerticalBreakPointChange">
      <div class="FindShop--googleMap" :id="state.id"></div>
      <div class="FindShop--searchBox">
        <div class="FindShop--search">
          <InputText :value="state.search"
                     :onChangeValue="v => state.search = v"
                     :label="lang().term(327)"
                     :svg="icon.search">
          </InputText>
        </div>
        <br>
        <LoaderSpace v-show="state.loading"></LoaderSpace>
        <div class="FindShop--shops" v-show="!state.loading">
          <div class="FindShop--shop" :class="class_shop(shop)" v-for="shop in state.shops" @click="() => displayShop(shop)">
            <div class="FindShop--shopHeader">
              <div class="FindShop--shopHeaderIcon">
                <Vector :svg="icon.store_mall_directory"></Vector>
              </div>
              <div class="FindShop--shopHeaderName">
                {{ shop.name }}
                <div class="FindShop--tags">
                  <Tag v-for="shopTypeList in shop.shopTypeLists" :key="shopTypeList.id" type="info">
                    {{ shopTypeList.value }}
                  </Tag>
                </div>
              </div>
            </div>
            <div class="FindShop--shopBody">
              <div class="FindShop--shopBodyAddresses">
                <div class="FindShop--shopBodyAddress" v-for="address in shop.addresses">
                  <div class="FindShop--shopBodyAddressStreet" v-for="street in address.streets">
                    {{ street.value }}
                  </div>
                  <div class="FindShop--shopBodyAddressStreet">
                    <span>{{ address.zipCode }}</span>
                    -
                    <span class="FindShop--shopBodyAddressStreetCity">{{ address.city }}</span>
                  </div>
                  <div class="FindShop--shopBodyAddressStreet">
                    <template v-for="country in address.countries">
                      {{ country.name.toUpperCase() }}
                    </template>
                  </div>
                </div>
              </div>
              <div class="FindShop--shopBodyPhoneNumbers">
                <div class="FindShop--shopBodyPhoneNumber" v-for="phoneNumber in shop.phoneNumbers">
                  {{ phoneNumber.number.replace(/([0-9]{2})/g, '$1 ') }}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>
import {Loader} from "@googlemaps/js-api-loader"

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import Id from "@bbx/id~master/core/ts/Id";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {googleConfigPublic} from "../../../../config/public/google.config";
import {waitElement} from "../../../@common/function/waitElement";
import {onlyJSON} from "../../../@common/function/onlyJSON";
import {Shop} from "../../../@common/delta/database/Shop";
import {apiClient} from "../../service/apiClientService";
import selectShops, {Request} from "../../../@common/api/shop/selectShops";
import {getFlatAddressByShop} from "../../../@common/function/getFlatAddressByShop";
import {getDecimal} from "../../../@common/function/getDecimal";
import {Debounce} from "../../../@common/ts/Debounce";
import {sendError} from "../../function/sendError";

const id = new Id()
const debounce = new Debounce()

export default {

  props: {
    activated: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: onlyJSON(new BreakPoint({
              name: SCREEN.DESKTOP
            })),
            verticalBreakPoint: onlyJSON(new BreakPoint()),
            breakPoints: [
              onlyJSON(new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              })),
              onlyJSON(new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              })),
              onlyJSON(new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              })),
            ],
            verticalBreakPoints: [
              onlyJSON(new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              })),
              onlyJSON(new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              })),
            ],
          },
        },
      },
      icon: {
        search: require('@bbx/vector~master/core/assets/svg/material/search.svg'),
        store_mall_directory: require('@bbx/vector~master/core/assets/svg/material/store_mall_directory.svg'),
      },
      state: {
        id: `_${id.uniq()}`,
        search: '',
        map: null,
        geocoder: null,
        selectedShopId: 0,
        shops: [],
        markers: [],
        loading: false,
      },
    }
  },
  async fetch() {
    this.state.id = `_${id.uniq()}`
  },
  watch: {
    'activated': {
      handler: function (v) {
        if (v) this.init().catch(sendError)
      },
    },
    'state.search': {
      handler: function () {
        this.selectShops().catch(sendError)
      },
    }
  },
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FindShop-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FindShop-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FindShop-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FindShop-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FindShop-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {

    async init() {
      const loader = new Loader({
        apiKey: googleConfigPublic.map.apiKey,
        version: "weekly",
      });
      await loader.load()
      this.state.geocoder = new window.google.maps.Geocoder()
      await this.initMap(location, 5)
    },

    async initMap() {
      let location = await this.getLocalizationFromAddress('France')
      let el = await waitElement(async () => document.getElementById(this.state.id))
      this.state.map = new window.google.maps.Map(el, {
        center: location,
        zoom: 5,
      });
    },

    async selectShops() {

      this.state.loading = true

      if (!this.state.search) {
        this.state.loading = false
        return this.state.shops = []
      }

      debounce.callback({
        job: async () => {
          let location = await this.getLocalizationFromAddress(`${this.state.search}, France`)
          if (location.lat && location.lng) {

            /** @type {Request} */
            const filter = {
              active: true,
              visibleOnMap: true,
              $searchNearestPosition: {
                latitude: getDecimal(location.lat, 8),
                longitude: getDecimal(location.lng, 8),
              },
              $limit: 15,
              $offset: 0,
            }

            return apiClient.do(selectShops, selectShops.with(filter))
          }
        },
        success: res => {
          this.state.shops = res.data
          this.cleanMarkers(this.state.markers)
          const markers = this.createMarkersByShops(res.data)
          this.displayMarkers(markers)
          this.state.markers = markers
          if (markers.length) this.displayShop(this.state.shops[0])
          this.state.loading = false
        },
        error: err => {
          this.state.loading = false
          sendError(err)
        }
      })
    },

    /**
     * @returns {Promise<{lat:number, lng:number}>}
     */
    async getLocalizationFromAddress(address) {
      return new Promise((rs, rj) => {
        this.state.geocoder.geocode({address}, (results, status) => {
          if (status === "OK") {
            rs({
              lat: results[0].geometry.location.lat(),
              lng: results[0].geometry.location.lng(),
            })
          } else {
            rs({
              lat: 0,
              lng: 0
            })
          }
        });
      })
    },

    onBreakPointChange(v) {
      this.props.Responsive['0'].breakPoint = v
    },

    onVerticalBreakPointChange(v) {
      this.props.Responsive['0'].verticalBreakPoint = v
    },
    /**
     * @param {Shop} shop
     */
    class_shop(shop) {
      const classes = []
      if (shop.id === this.state.selectedShopId) classes.push(`FindShop--shop-selected`)
      return classes
    },
    /**
     * @param {Shop} shop
     */
    displayShop(shop) {
      const location = {
        lat: 0,
        lng: 0
      }
      for (const address of shop.addresses) {
        for (const geographicalPosition of address.geographicalPositions) {
          location.lat = geographicalPosition.latitude
          location.lng = geographicalPosition.longitude
        }
      }
      this.state.map.setCenter(location)
      this.state.map.setZoom(11)
      this.state.selectedShopId = shop.id

    },
    /**
     * @param {Shop[]} shops
     */
    createMarkersByShops(shops) {
      const markers = []
      for (const shop of shops) {
        for (const address of shop.addresses) {
          for (const geographicalPosition of address.geographicalPositions) {
            const marker = new window.google.maps.Marker({
              position: {
                lat: geographicalPosition.latitude,
                lng: geographicalPosition.longitude,
              },
              url: `https://www.google.com/maps/search/?api=1&query=${getFlatAddressByShop(shop, '+')}`
            });
            window.google.maps.event.addListener(marker, 'click', function () {
              window.open(this.url, '_blank');
            });
            markers.push(marker)
          }
        }
      }
      return markers
    },
    displayMarkers(markers) {
      for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(this.state.map);
      }
    },
    cleanMarkers(markers) {
      for (let i = 0; i < markers.length; i++) {
        markers[i].setMap(null);
      }
    },
    lang: getLangServiceBrowser
  }
}
</script>
