"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Material = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Product_1 = require("./Product");
var Term_1 = require("./Term");
var Photo_1 = require("./Photo");
var Similar_1 = require("./Similar");
var Collection_1 = require("./Collection");
var Material = /** @class */ (function () {
    function Material(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.terms = deltaService_1.deltaService.array(props.terms, Term_1.Term);
        this.photos = deltaService_1.deltaService.array(props.photos, Photo_1.Photo);
        this.products = deltaService_1.deltaService.array(props.products, Product_1.Product);
        this.similars = deltaService_1.deltaService.array(props.similars, Similar_1.Similar);
        this.collections = deltaService_1.deltaService.array(props.collections, Collection_1.Collection);
    }
    return Material;
}());
exports.Material = Material;
