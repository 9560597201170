<style scoped>
.Link {
  display: inline-block;
}

.Link-redirect {
  cursor: pointer;
}
</style>

<template>
  <div class="Link Link-redirect" v-if="redirect" v-bind="attrs" @click="() => onClick()">
    <slot/>
  </div>
  <a v-else-if="href" data-type="href" class="Link" :href="href" v-bind="attrs">
    <slot/>
  </a>
  <a v-else-if="env === ENGINE.NUXT && link" data-type="link" class="Link" :href="link" v-bind="attrs">
    <slot/>
  </a>
  <router-link v-else-if="env === ENGINE.BROWSER && link" data-type="link" class="Link" :to="link" v-bind="attrs">
    <slot/>
  </router-link>
  <a v-else-if="env === ENGINE.NUXT && pathIds.length" data-type="paths" class="Link" :href="getUrl(lang.lang.iso6391, pathIds)" v-bind="attrs">
    <slot/>
  </a>
  <router-link v-else-if="env === ENGINE.BROWSER && pathIds.length" data-type="paths" class="Link" :to="getUrl(lang.lang.iso6391, pathIds)" v-bind="attrs">
    <slot/>
  </router-link>
  <a v-else-if="env === ENGINE.NUXT && url.id" data-type="url" class="Link" :href="getProjectUrl(lang.lang.iso6391, url)" v-bind="attrs">
    <slot/>
  </a>
  <router-link v-else-if="env === ENGINE.BROWSER && url.id" data-type="paths" class="Link" :to="getProjectUrl(lang.lang.iso6391, url)" v-bind="attrs">
    <slot/>
  </router-link>
  <a class="Link" v-else data-type="default" v-bind="attrs">
    <slot/>
  </a>
</template>

<script>
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {urlService} from "../../../@common/service/urlService";
import {Url} from "../../../@common/delta/database/Url";
import {ENGINE} from "../../../@common/constant/ENGINE";
import {envService} from "../../service/envService";

export default {

  props: {
    // https://exemple.com/exemple
    href: {
      type: String,
      default: () => ''
    },
    // /exemple
    link: {
      type: String,
      default: () => ''
    },
    // [1,2,3]
    pathIds: {
      type: Array,
      default: () => ([])
    },
    // new UrlDelta()
    url: {
      default: () => new Url
    },
    // jump in other tab from browser
    newPage: {
      type: Boolean,
      default: () => false
    },
    // should i index this link by google
    indexed: {
      type: Boolean,
      default: () => true
    },
    redirect: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      ENGINE,
      env: envService.getEngine(),
      lang: getLangServiceBrowser(),
      props: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    attrs() {
      let attr = {}
      if (!this.indexed) attr.rel = 'nofollow'
      if (this.newPage) attr.target = '_blanc'
      return attr
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    getUrl: urlService.getUrl,
    getProjectUrl: urlService.getProjectUrl,
    onClick() {
      window.location = this.getUrl(this.lang.lang.iso6391, this.pathIds)
    }
  }
}
</script>
