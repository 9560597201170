"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Detail = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Product_1 = require("./Product");
var Term_1 = require("./Term");
var Detail = /** @class */ (function () {
    function Detail(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.termValues = deltaService_1.deltaService.array(props.termValues, Term_1.Term);
        this.products = deltaService_1.deltaService.array(props.products, Product_1.Product);
    }
    return Detail;
}());
exports.Detail = Detail;
