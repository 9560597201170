"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PATHS = void 0;
exports.PATHS = {
    // DIMENSION 1 <1-199>
    FAQ: 1,
    CATALOG: 2,
    PURCHASE: 3,
    LOGIN: 4,
    REGISTRATION: 5,
    FORGOTTEN_PASSWORD: 6,
    UPDATE_PASSWORD: 7,
    ADMIN: 8,
    ACCOUNT: 9,
    AUTH: 10,
    HOME: 11,
    // DIMENSION 2 <200-299>
    REDIRECT: 200,
    USER: 201,
    PRODUCT: 202,
    METRIC: 203,
    LANG: 204,
    MARKETING: 205,
    BASKET: 206,
    DELIVERY: 207,
    PAYMENT: 208,
    PAGE: 209,
    PROFILE: 210,
    ORDER: 211,
    ADDRESS: 212,
    NOTIFICATION: 213,
    COMPLETE: 214,
};
