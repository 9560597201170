import CODE from "../../constant/CODE";
import {Term} from "../database/Term";

export interface ApiResponseFormInput_constructor_props {
    name?: string;
    error?: Term;
}

export class ApiResponseFormInput {

    name: string;
    error: Term;

    constructor(props: ApiResponseFormInput_constructor_props = {}) {
        this.name = props.name !== undefined ? props.name : ''
        this.error = props.error !== undefined ? new Term(props.error) : new Term()
    }
}

export interface ApiResponseForm_constructor_props {
    inputs?: ApiResponseFormInput[];
}

export class ApiResponseForm {

    inputs: ApiResponseFormInput[];
    errors: string[];

    constructor(props: ApiResponseForm_constructor_props = {}) {
        this.inputs = props.inputs !== undefined ? props.inputs.map(input => new ApiResponseFormInput(input)) : [];
        this.errors = [];
    }

    clean() {
        this.inputs = [];
        this.errors = [];
        return this;
    }

    control() {
        this.errors = this.inputs.map(input => input.name);
        return this;
    }

    find(inputName: string) {
        return this.inputs.find(input => {
            return input.name === inputName
        });
    }

    display(inputName: string) {
        let found = this.find(inputName);
        return found ? found.error : new Term();
    }
}

export interface ApiResponse_constructor_props<T> {
    success?: boolean;
    form?: ApiResponseForm;
    data: T;
    count?: number;
    extra?: any;
    offset?: number;
    limit?: number;
    code?: number;
}

export class ApiResponse<T> {
    success: boolean;
    form: ApiResponseForm;
    data: T;
    count: number;
    extra: any;
    offset: number;
    limit: number;
    code: number;

    constructor(props: ApiResponse_constructor_props<T>) {
        this.success = props.success !== undefined ? props.success : false;
        this.form = props.form !== undefined ? new ApiResponseForm(props.form) : new ApiResponseForm;
        this.data = props.data;
        this.extra = props.extra;
        this.count = props.count !== undefined ? props.count : 0;
        this.offset = props.offset !== undefined ? props.offset : 0;
        this.limit = props.limit !== undefined ? props.limit : 0;
        this.code = props.code !== undefined ? props.code : CODE.OK;
    }
}
