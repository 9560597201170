<style scoped>
.TransportCostFree {
  text-align: center;
  height: 0;
  overflow: hidden;
  transition: height 300ms, background-color 300ms;
}

.TransportCostFree-opened.TransportCostFree {
  height: 30px;
}

.TransportCostFree--texts {
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'CommutersSansBold', sans-serif;
  text-transform: uppercase;
  color: #ffffff;
  font-size: 11px;
}

.TransportCostFree-desktop .TransportCostFree--texts {

}

.TransportCostFree-tablet .TransportCostFree--texts {

}

.TransportCostFree-mobile .TransportCostFree--texts {
  font-size: 9px;
}

.TransportCostFree--text {
  margin: 0 5px;
}
</style>

<template>
  <div class="TransportCostFree" :class="class_root" :style="style_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="TransportCostFree--texts">
        <Term :term="lang.term(698)"></Term>
        <div class="TransportCostFree--text">{{ getDayStart() }} {{ getMonthStart() }}</div>
        <Term :term="lang.term(699)"></Term>
        <div class="TransportCostFree--text">{{ getDayEnd() }} {{ getMonthEnd() }}</div>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {freeTransportCostService} from "../../../@common/service/freeTransportCostService";
import {COLOR} from "../../../@common/constant/COLOR";
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {EVENT} from "../../../@common/constant/EVENT";
import {eventService} from "../../service/eventService";
import {THEME} from "../../../@common/constant/THEME";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    },
    opened: {
      type: Boolean,
      default: () => true
    },
    theme: {
      type: String,
      default: () => THEME.LIGHT
    },
  },
  data() {
    return {
      freeTransportCostService: freeTransportCostService,
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      state: {
        map: {
          '01': 'janvier',
          '02': 'février',
          '03': 'mars',
          '04': 'avril',
          '05': 'mai',
          '06': 'juin',
          '07': 'juillet',
          '08': 'août',
          '09': 'septembre',
          '10': 'octobre',
          '11': 'novembre',
          '12': 'décembre',
        },
        color: COLOR.PRIMARY,
      },
      events: []
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.opened) classes.push(`TransportCostFree-opened`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`TransportCostFree-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`TransportCostFree-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`TransportCostFree-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`TransportCostFree-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`TransportCostFree-desktopY`)
      return classes
    },
    style_root() {
      let style = {}
      if (this.theme === THEME.LIGHT) style.background = this.state.color
      return style
    }
  },
  beforeMount() {

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.HOME_COLOR_CHANGED,
      callback: (color) => {
        this.state.color = color
      }
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    getDayStart() {
      let days = freeTransportCostService.dates.start.split('.')
      return days[2]
    },
    getDayEnd() {
      let days = freeTransportCostService.dates.end.split('.')
      return days[2]
    },
    getMonthStart() {
      let days = freeTransportCostService.dates.start.split('.')
      let day = days[1]
      return this.state.map[day]
    },
    getMonthEnd() {
      let days = freeTransportCostService.dates.end.split('.')
      let day = days[1]
      return this.state.map[day]
    }
  }
}
</script>
