"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NuxtProp = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var NuxtUrl_1 = require("./NuxtUrl");
var Page_1 = require("../database/Page");
var NuxtProp = /** @class */ (function () {
    function NuxtProp(props) {
        if (props === void 0) { props = {}; }
        this.url = deltaService_1.deltaService.object(props.url, NuxtUrl_1.NuxtUrl);
        this.pages = deltaService_1.deltaService.array(props.pages, Page_1.Page);
        this.page = deltaService_1.deltaService.object(props.page, Page_1.Page);
        this.ready = deltaService_1.deltaService.boolean(props.ready, false);
        this.loading = deltaService_1.deltaService.boolean(props.loading, false);
        this.payload = props.payload;
    }
    return NuxtProp;
}());
exports.NuxtProp = NuxtProp;
