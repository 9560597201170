<style scoped lang="scss">

@import "../../scss/color";

.Outlet {
  display: flex;
  align-items: center;
  position: relative;
}

.Outlet--flag {
  background: $danger;
  padding: 7px 14px 7px 14px;
  font-size: 11px;
  text-transform: uppercase;
  color: white;
  font-family: 'CommutersSansBold', sans-serif;
  border-radius: 3px;
}

.Outlet--term {
  padding-left: 15px;
  font-size: 18px;
  font-family: 'AeonikRegular', sans-serif;
  color: $danger;
}

.Outlet--originalPrice {
  font-size: 12px;
  padding-left: 10px;
  color: $danger;
  text-decoration: line-through;
  position: relative;
  top: 2px;
}

.Outlet--originalPrice span {
  color: black;
}
</style>

<template>
  <div class="Outlet">
    <div class="Outlet--flag">
      -{{ Math.round(percentage) }}%
    </div>
    <div class="Outlet--term">
      <Term :term="langService.term(222)"/>
    </div>
    <div v-if="originalPrice" class="Outlet--originalPrice">
      <span>{{ formatPrice(originalPrice) }} €</span>
    </div>
  </div>
</template>

<script>

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {formatPrice} from "../../../@common/function/formatPrice";

export default {
  props: {
    percentage: {
      type: Number,
      default: () => 0
    },
    originalPrice: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      langService: getLangServiceBrowser(),
      props: {},
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      state: {},
    }
  },
  watch: {},
  computed: {},
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    formatPrice,
  }
}
</script>
