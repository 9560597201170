"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Batch = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Article_1 = require("./Article");
var Product_1 = require("./Product");
var Batch = /** @class */ (function () {
    function Batch(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.quantity = deltaService_1.deltaService.number(props.quantity, 0);
        this.products = deltaService_1.deltaService.array(props.products, Product_1.Product);
        this.articles = deltaService_1.deltaService.array(props.articles, Article_1.Article);
    }
    return Batch;
}());
exports.Batch = Batch;
