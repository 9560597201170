"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getArticleStockBrowser = void 0;
var getArticleStock_1 = require("../../@common/function/getArticleStock");
var stateService_1 = require("../service/stateService");
function getArticleStockBrowser(props) {
    // -----
    var checkRestriction = props.checkRestriction !== undefined ? props.checkRestriction : true;
    // -----
    var roleId = stateService_1.stateService.get(stateService_1.STATE_ROLE_ID);
    var userId = stateService_1.stateService.get(stateService_1.STATE_USER_ID);
    // -----
    return getArticleStock_1.getArticleStock({
        article: props.article,
        attr: props.attr,
        roleIds: [roleId],
        userIds: [userId],
        checkRestriction: checkRestriction
    });
}
exports.getArticleStockBrowser = getArticleStockBrowser;
