<style scoped>

</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="content-wrapper none for-title">
      <div class="container_12">
        <div class="main-container main-main-container col1-layout">
          <div class="grid_12 col-main">
            <div class="page-title">
              <h1>Aménager son balcon - Les Jardins Vente Privée</h1>
            </div>
            <div class="std">
              <p style="max-height: 250px; margin-bottom: 20px; overflow: hidden; text-align: justify;">
                <img alt="" src="./media/wysiwyg/4.jpg">
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Vous avez un balcon et souhaitez y aménager un coin repas/détente&nbsp;?
                  Nous vous dévoilons nos idées et astuces pour
                  <b>optimiser votre espace</b>
                  et créer une ambiance conviviale et chaleureuse. Il est important que vous vous sentiez aussi bien à
                  l’intérieur qu’à l’extérieur de votre appartement ou maison.&nbsp;
                </span>
              </p>
              <h2 style="text-align: justify;">Le choix de la table
                <img style="float: right; padding-left: 20px;" alt="" src="./media/wysiwyg/img16.jpg">
              </h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Si vous ne disposez que d’une petite surface en extérieur, vous pouvez
                  tout de même l’aménager de façon astucieuse pour composer un lieu de vie agréable et cosy.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Une
                  <b>table pliante</b>
                  est une solution particulièrement intéressante pour
                  <b>aménager son balcon</b>
                  . Elle peut accueillir 2 à 4 personnes et se range facilement.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Pour accompagner votre table, les
                  <b>chaises pliantes</b>
                  <b> ou</b>
                  <b>empilables</b>
                  permettent un gain de place optimal. Que vous soyez 2, 4 ou 6, vous pouvez ainsi profiter de tout
                  l’espace de votre balcon lorsque vous n’êtes pas autour de la table.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Les balcons plus grands pourront choisir&nbsp;une
                  <b>table fixe</b>
                  . Pour vous laisser une bonne circulation autour de la table, assurez-vous que celle-ci n’occupe pas
                  plus de 40&nbsp;% de la surface totale de votre balcon.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Les
                  <b>mange-debout</b>
                  et autres&nbsp;tables basses sont aussi une solution pratique&nbsp;pour optimiser l’espace et recevoir
                  ses amis pour un repas, un apéritif ou bien pour mettre en valeur la décoration et les plantes.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">&nbsp;</p>
              <h2 style="text-align: justify;">Les matériaux</h2>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Concernant le choix du matériau, c’est avant tout une histoire de goût&nbsp;!
                  Cependant, nous vous recommandons de ne pas négliger la question de l’entretien du mobilier que vous
                  allez choisir.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Si vous souhaitez un mobilier avec un matériau noble, vous pouvez
                  choisir du
                  <b>teck</b>
                  ou du
                  <b>bois composite</b>
                  . Pour éviter une usure prématurée, il est préférable de pouvoir remiser votre mobilier dans une cave
                  ou un box le temps de la saison morte.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Si votre mobilier doit passer toute l’année sur votre balcon, il est
                  alors indispensable de choisir un matériau de qualité et résistant aux intempéries. L’
                  <b>aluminium</b>
                  , qui ne nécessite que peu d’entretien, se présente alors comme une excellente solution ainsi que les
                    <b>tables HPL</b>
                  avec un plateau particulièrement robuste.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Pensez bien à choisir un mobilier de jardin qui s’adapte à la matière et
                  à la couleur de votre sol ainsi qu’au style de votre déco. Vous n’aurez plus qu’à égayer votre balcon
                  et à le décorer avec des meubles de rangement, des pots de fleurs, des jardinières, des coussins de
                  couleur, un éclairage tamisé, du faux gazon…
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Le choix de l'assise</h2>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Pour créer un espace convivial et recevoir votre famille et vos amis,
                  installez des
                  <b>chaises et fauteuils</b>
                  sur votre balcon. Nous mettons à votre disposition différentes collections aux inspirations tantôt
                  chic et design, tantôt vintage.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Les modèles de la
                    <b>collection Twig</b>
                  en toile Batyline® proposent une excellente ergonomie. Ultra résistante et indéchirable, cette toile
                  bénéficie d’un traitement anti-UV et reste facile à l’entretien.
                </span>
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Pour égayer votre balcon et lui apporter une touche récréative,
                  aménagez-vous un espace coloré&nbsp;! La
                    <b>collection Filaciel</b>
                  &nbsp;propose différentes couleurs de tables, de chaises ou de fauteuils à assortir ou mélanger à
                  votre guise.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">À noter, un salon bas n’est pas l’aménagement le plus indiqué pour un
                  balcon. Si celui-ci peut effectivement inviter à la détente, le mur ou la rambarde du balcon à hauteur
                  de regard coupe la vue et peut rapidement créer un effet d’enfermement.
                </span>
              </p>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../../function/getH1";
import {Term} from "../../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../../@common/constant/PROJECT_URL";
import {PATHS} from "../../../../@common/constant/PATHS";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    },
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FAQ-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
