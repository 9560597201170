"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Path = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Page_1 = require("./Page");
var Term_1 = require("./Term");
var Sort_1 = require("./Sort");
var Path = /** @class */ (function () {
    function Path(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.terms = deltaService_1.deltaService.array(props.terms, Term_1.Term);
        this.pages = deltaService_1.deltaService.array(props.pages, Page_1.Page);
        this.sorts = deltaService_1.deltaService.array(props.sorts, Sort_1.Sort);
    }
    return Path;
}());
exports.Path = Path;
