<style scoped>
.ArticleRecommendation {

}

.ArticleRecommendation-mobile ::v-deep .CatalogueArticle--footerLeft {
  align-items: center;
  text-align: center;
}

.ArticleRecommendation--header {
  display: flex;
  justify-content: space-between;
}

.ArticleRecommendation-desktop .ArticleRecommendation--header {
  padding-top: 50px;
  padding-bottom: 50px;
}

.ArticleRecommendation-tablet .ArticleRecommendation--header {
  padding-top: 50px;
  padding-bottom: 50px;
}

.ArticleRecommendation-mobile .ArticleRecommendation--header {
  padding-top: 25px;
  padding-bottom: 25px;
}

.ArticleRecommendation--headerLeft {
  display: flex;
  align-items: center;
}

.ArticleRecommendation--headerLeft ::v-deep .Term {
  font-family: 'RalewayBold', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
}

.ArticleRecommendation--bar {
  width: 70px;
  height: 1px;
  background: black;
  margin-left: 20px;
}

.ArticleRecommendation-mobile .ArticleRecommendation--bar {
  display: none;
}

.ArticleRecommendation--headerRight {

}

.ArticleRecommendation--headerRight ::v-deep .Term {
  font-family: 'RalewayBold', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
}

.ArticleRecommendation--headerRightDesktop {
  display: flex;
  align-items: center;
}

/*.ArticleRecommendation-mobile .ArticleRecommendation--headerRightDesktop {*/
/*  display: none;*/
/*}*/

.ArticleRecommendation--headerRightDesktop ::v-deep .Vector {
  width: 18px;
  height: 18px;
}

.ArticleRecommendation--previous, .ArticleRecommendation--next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  transition: box-shadow 300ms;
  background: #ffffff;
  margin-left: 10px;
}

.ArticleRecommendation--next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 32px;
  height: 32px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  transition: box-shadow 300ms;
  background: #000000;
  margin-left: 10px;
}

.ArticleRecommendation--next ::v-deep .Vector svg {
  fill: white;
}

.ArticleRecommendation--previous:hover, .ArticleRecommendation--previous:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
}

/*.ArticleRecommendation--headerRightMobile {*/
/*  align-items: center;*/
/*  display: none;*/
/*}*/

/*.ArticleRecommendation-mobile .ArticleRecommendation--headerRightMobile {*/
/*  display: flex;*/
/*}*/

/*.ArticleRecommendation--headerRightMobile img {*/
/*  display: block;*/
/*  width: 24px;*/
/*  margin-left: 20px;*/
/*  transform: translateX(0px);*/
/*  animation: ArticleRecommendation--animation 2s infinite;*/
/*}*/

/*@keyframes ArticleRecommendation--animation {*/
/*  0% {*/
/*    transform: translateX(0px);*/
/*  }*/
/*  50% {*/
/*    transform: translateX(-10px);*/
/*  }*/
/*}*/

.ArticleRecommendation--articles {
  overflow-x: scroll;
  overflow-y: hidden;
}

.ArticleRecommendation--articlesContainer {
  display: flex;
}

.ArticleRecommendation--article {
  position: relative;
  overflow: hidden;
}

.ArticleRecommendation-desktop .ArticleRecommendation--article {
  width: 300px;
  height: 300px;
  min-width: 300px;
  min-height: 300px;
}

.ArticleRecommendation-tablet .ArticleRecommendation--article {
  width: 300px;
  height: 300px;
  min-width: 300px;
  min-height: 300px;
}

.ArticleRecommendation-mobile .ArticleRecommendation--article {
  width: 100%;
  min-width: 100%;
  max-height: 100%;
  padding-bottom: 100%;
}

.ArticleRecommendation--articleBox {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ArticleRecommendation--articleSpace {
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
}

.ArticleRecommendation--rest {
  flex: 1;
  background: rgba(0, 0, 0, 0.03);
}

/*
 * * * * * * * * * *
 *
 *   OVERRIDE
 *
 * * * * * * * * * *
 */

.ArticleRecommendation ::v-deep .scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

.ArticleRecommendation ::v-deep .scrollbar-track-x {
  height: 0px;
}

.ArticleRecommendation ::v-deep .scrollbar-thumb {
  border-radius: 0;
  background: rgba(0, 0, 0, 0);
}

.ArticleRecommendation ::v-deep .scrollbar-thumb-x {
  height: 0px;
}
</style>

<template>
  <div class="ArticleRecommendation" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">

      <div class="ArticleRecommendation--header" v-if="displayHeader">
        <div class="ArticleRecommendation--headerLeft">
          <Term :term="lang.term(607)"></Term>
          <div class="ArticleRecommendation--bar"></div>
        </div>
        <div class="ArticleRecommendation--headerRight">
          <div class="ArticleRecommendation--headerRightDesktop">
            <div class="ArticleRecommendation--previous" @click="previous">
              <Vector :svg="icon.chevron_left"></Vector>
            </div>
            <div class="ArticleRecommendation--next" @click="next">
              <Vector :svg="icon.chevron_right"></Vector>
            </div>
          </div>
          <!--          <div class="ArticleRecommendation&#45;&#45;headerRightMobile">-->
          <!--            <Term :term="lang.term(608)"></Term>-->
          <!--            <img src="../../../../assets/article/swipe.png">-->
          <!--          </div>-->
        </div>
      </div>
      <ScrollZone :onScroll="onScroll" :registerCallback="(p) => scrollZoneRegisterCallback(p)">
        <div class="ArticleRecommendation--articlesContainer">
          <template v-for="(articleId, index) in articleIds">
            <div class="ArticleRecommendation--article" ref="article" :key="articleId">
              <div class="ArticleRecommendation--articleBox">
                <Async :fetch="async () => fetch(articleId)" :registerCallback="(p) => asyncRegisterCallback(p)">
                  <template v-slot:default="{data}">
                    <CatalogueArticle :article="data.item"
                                      :display="size"
                                      :index="index">
                    </CatalogueArticle>
                  </template>
                </Async>
              </div>
            </div>
            <!--            <div class="ArticleRecommendation&#45;&#45;articleSpace"></div>-->
          </template>
        </div>
        <div class="ArticleRecommendation--rest"></div>
      </ScrollZone>

    </Responsive>
  </div>
</template>

<script>

// -----

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {eventService} from "../../service/eventService";
import {FILTER_DISPLAY} from "../../../@common/constant/FILTER_DISPLAY";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

// -----

export default {
  props: {
    displayHeader: {
      type: Boolean,
      default: () => true
    },
    fetch: {
      type: Function,
      default: () => async () => {
      }
    },
    size: {
      type: String,
      default: () => FILTER_DISPLAY.SMALL
    },
    articleIds: {
      type: Array, // Array<number>
      default: () => ([])
    },
    breakPoints: {
      type: Array, // Array<number>
      default: () => ([
        new BreakPoint({
          name: SCREEN.MOBILE,
          width: 0
        }),
        new BreakPoint({
          name: SCREEN.TABLET,
          width: 1220
        }),
        new BreakPoint({
          name: SCREEN.DESKTOP,
          width: 1650
        }),
      ])
    }
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      FILTER_DISPLAY,
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: this.breakPoints,
          verticalBreakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              height: 0
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              height: 600
            }),
          ],
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      state: {
        squareSize: 0,
        scrollLeft: 0,
        scrollRight: 0,
        scrollCallbacks: []
      },
      icon: {
        chevron_left: require("@bbx/vector~master/core/assets/svg/material/chevron_left.svg"),
        chevron_right: require("@bbx/vector~master/core/assets/svg/material/chevron_right.svg"),
      },
      events: []
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`ArticleRecommendation-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`ArticleRecommendation-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`ArticleRecommendation-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`ArticleRecommendation-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`ArticleRecommendation-desktopY`)
      return classes
    }
  },
  beforeMount() {
    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    calculSquareSize() {
      const el = this.$refs.article
      if (!el && !el[0]) return
      this.state.squareSize = el[0].clientWidth
    },
    previous() {
      this.calculSquareSize()
      let left = this.state.scrollLeft - this.state.squareSize - 25 /*padding*/
      this.scrollTo({left})
    },
    next() {
      this.calculSquareSize()
      let left = this.state.scrollLeft + this.state.squareSize + 25 /*padding*/
      this.scrollTo({left})
    },
    asyncRegisterCallback({onScroll}) {
      this.state.scrollCallbacks.push(onScroll)
    },
    scrollZoneRegisterCallback({scrollTo}) {
      this.scrollTo = (props) => scrollTo(props)
    },
    onScroll(props = {left: 0, right: 0}) {
      this.state.scrollLeft = props.left
      for (const scrollCallback of this.state.scrollCallbacks) {
        scrollCallback(props)
      }
    },
    scrollTo(props = {left: 0, right: 0}) {
      // overide
    },
  }
}
</script>