import {deltaService} from "@bbx/delta~master/core/service/deltaService"
import {Path} from "./Path";
import {Url} from "./Url";
import {Collection} from "./Collection";
import {Photo} from "./Photo";
import {Carousel} from "./Carousel";
import {Selection} from "./Selection";
import {DeepPartial} from "../../type/DeepPartial";


export type Sort_constructor_props = DeepPartial<Sort>

export class  Sort {

    id: number
    active: boolean
    createdAt: string
    updatedAt: string

    order: number

    paths: Path[]
    photos: Photo[]
    urls: Url[]
    collectionPhotos: Collection[]
    carousels: Carousel[]
    selections: Selection[]

    constructor(props: Sort_constructor_props = {}) {
                this.id = deltaService.number(props.id, 0)
        this.active = deltaService.boolean(props.active, true)
        this.createdAt = deltaService.string(props.createdAt, '')
        this.updatedAt = deltaService.string(props.updatedAt, '')

        this.order = deltaService.number(props.order, 0)

        this.paths = deltaService.array(props.paths, Path)
        this.photos = deltaService.array(props.photos, Photo)
        this.urls = deltaService.array(props.urls, Url)
        this.collectionPhotos = deltaService.array(props.collectionPhotos, Collection)
        this.carousels = deltaService.array(props.carousels, Carousel)
        this.selections = deltaService.array(props.selections, Selection)
    }
}
