"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Carousel = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Photo_1 = require("./Photo");
var Term_1 = require("./Term");
var Sort_1 = require("./Sort");
var Carousel = /** @class */ (function () {
    function Carousel(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.isNightMode = deltaService_1.deltaService.boolean(props.isNightMode, false);
        this.url = deltaService_1.deltaService.string(props.url, '');
        this.opacityShadow = deltaService_1.deltaService.number(props.opacityShadow, 0);
        this.color = deltaService_1.deltaService.string(props.color, '');
        this.photos = deltaService_1.deltaService.array(props.photos, Photo_1.Photo);
        this.termTitles = deltaService_1.deltaService.array(props.termTitles, Term_1.Term);
        this.termDescriptions = deltaService_1.deltaService.array(props.termDescriptions, Term_1.Term);
        this.sorts = deltaService_1.deltaService.array(props.sorts, Sort_1.Sort);
    }
    return Carousel;
}());
exports.Carousel = Carousel;
