<style scoped>
.BasketList {
}

.BasketList--empty {
  background: rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  justify-content: center;
  height: 200px;
  border-radius: 4px;
  margin-bottom: 50px;
}

.BasketList--item {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;
  padding-bottom: 25px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  margin-bottom: 25px;
}

.BasketList-mobile .BasketList--item {
  /*flex-direction: column;*/
  /*align-items: flex-start;*/
}

.BasketList--itemLeft {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
}

.BasketList--image {
}

.BasketList--picture {
  width: 100px;
  height: 100px;
  min-width: 100px;
  min-height: 100px;
  background: rgba(0, 0, 0, 0.03);
  box-sizing: border-box;
  border-radius: 4px;
  margin-right: 25px;
  /*margin-bottom: 10px;*/
  overflow: hidden;
}

.BasketList--name {
  font-family: 'RalewayMedium', sans-serif;
  font-size: 16px;
  letter-spacing: 1px;
}

.BasketList--price {
  font-family: 'DDinRegular', sans-serif;
  color: rgba(0, 0, 0, 0.5);
  padding-top: 10px;
  padding-bottom: 10px;
}

.BasketList--increment {
  padding-top: 5px;
}

.BasketList--itemRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-left: 15px;
}

.BasketList--icon {
  width: 24px;
  height: 24px;
  cursor: pointer;
  position: relative;
  top: -2px;
}
</style>

<template>
  <div class="BasketList" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div v-show="!state.order.baskets.length && showEmpty" class="BasketList--empty">
        <Term :term="lang.term(169)"></Term>
      </div>
      <div class="BasketList--item" v-for="(basket, index_basket) in state.order.baskets">
        <div class="BasketList--itemLeft">
          <div class="BasketList--image">
            <template v-for="article in basket.articles">
              <div class="BasketList--picture">
                <Picture :file="image(article).file" :display="pictureDisplay(image(article).photo)"></Picture>
              </div>
            </template>
          </div>
          <div>
            <template v-for="article in basket.articles">
              <template v-for="termName in article.termNames">
                <div class="BasketList--name">
                  <Term :term="termName"/>
                </div>
              </template>
            </template>
            <div class="BasketList--price">
              {{
                formatPrice(getBasketTotal({
                  order: newOrderForOneBasket(basket),
                  roleId: state.roleId
                }).articleWithPromotionTTC)
              }} €
              TTC
            </div>
            <div class="BasketList--increment">
              <template v-for="article in basket.articles">
                <el-input-number :size="size" :value="basket.quantity" @change="v => onQuantityChange(v, index_basket)" :min="1" :max="getArticleStockBrowser({article})"/>
              </template>
            </div>
          </div>
        </div>
        <div class="BasketList--itemRight">
          <div class="BasketList--icon" @click="() => removeIndex(index_basket)">
            <Vector :svg="icon.delete_forever"/>
          </div>
        </div>
      </div>
      <ModalConfirm :opened="state.openedDelete"
                    :onOpenedChange="v => state.openedDelete = v"
                    :onValidated="() => remove()">
      </ModalConfirm>
    </Responsive>
  </div>
</template>

<script>

import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger.ts";
import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {stateService, STATE_ORDER, STATE_ROLE_ID} from "../../service/stateService";
import {Order} from "../../../@common/delta/database/Order";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {getBasketTotal} from "../../../@common/function/getBasketTotal";
import {formatPrice} from "../../../@common/function/formatPrice";
import {findCoverPhoto} from "../../function/findCoverPhoto";
import {PICTURE_DISPLAY} from "../../../@common/constant/PICTURE_DISPLAY";
import {getArticleStock} from "../../../@common/function/getArticleStock";
import {eventService} from "../../service/eventService";
import {EVENT} from "../../../@common/constant/EVENT";
import {displayMessage} from "../../function/displayMessage";
import {getArticleStockBrowser} from "../../function/getArticleStockBrowwser";

export default {
  props: {
    order: {
      default: () => new Order()
    },
    editable: {
      type: Boolean,
      default: () => true
    },
    showEmpty: {
      type: Boolean,
      default: () => true
    },
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        delete_forever: require('@bbx/vector~master/core/assets/svg/material/delete_forever.svg')
      },
      state: {
        /**
         * @type number
         */
        index: 0,
        /**
         * @type Order
         */
        order: this.order,
        /**
         * @type boolean
         */
        openedDelete: false,
        /**
         * @type number
         */
        roleId: 0
      },
    }
  },
  watch: {
    'order': {
      handler: function (v) {
        this.state.order = v
      },
      deep: true
    }
  },
  computed: {
    size() {
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) return 'mini'
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) return 'mini'
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) return 'mini'
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`BasketList-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`BasketList-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`BasketList-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`BasketList-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`BasketList-desktopY`)
      return classes
    }
  },
  beforeMount() {

    this.state.roleId = stateService.get(STATE_ROLE_ID)
    stateService.watch(STATE_ROLE_ID, v => this.state.roleId = v)
  },
  mounted() {
    if (this.editable) {
      this.state.order = stateService.get(STATE_ORDER)
      stateService.watch(STATE_ORDER, (order) => {
        this.state.order = order
      })
    }
  },
  beforeDestroy() {
  },
  methods: {
    getBasketTotal,
    formatPrice,
    remove() {
      for (const article of this.state.order.baskets[this.state.index].articles) {
        eventService.triggerByName(new ListenerTrigger({
          name: EVENT.ARTICLE_WILL_BE_REMOVED_FROM_BASKET,
          payload: {
            article,
            quantity: this.state.order.baskets[this.state.index].quantity,
          }
        }))
      }
      this.state.order.baskets.splice(this.state.index, 1)
      stateService.set(STATE_ORDER, this.state.order)
      displayMessage({
        vue: this,
        type: 'success',
        message: this.lang.translate(168)
      });
      this.state.openedDelete = false
    },
    removeIndex(index) {
      this.state.openedDelete = true
      this.state.index = index
    },
    onQuantityChange(quantity, index_basket) {

      let isUp = quantity > this.state.order.baskets[index_basket].quantity
      let isDown = quantity < this.state.order.baskets[index_basket].quantity

      this.state.order.baskets[index_basket].quantity = quantity;
      for (const article of this.state.order.baskets[index_basket].articles) {
        if (isUp) {
          eventService.triggerByName(new ListenerTrigger({
            name: EVENT.ARTICLE_WAS_INCREMENTED_IN_BASKET,
            payload: {
              article,
              quantity,
            }
          }))
        } else if (isDown) {
          eventService.triggerByName(new ListenerTrigger({
            name: EVENT.ARTICLE_WAS_DECREMENTED_IN_BASKET,
            payload: {
              article,
              quantity,
            }
          }))
        }
      }
      stateService.set(STATE_ORDER, this.state.order)
    },
    image(article) {
      return findCoverPhoto(article)
    },
    pictureDisplay(photo) {
      return photo.full ? PICTURE_DISPLAY.COVER : PICTURE_DISPLAY.AUTO
    },
    newOrderForOneBasket(basket) {
      return new Order({
        baskets: [basket]
      })
    },

    getArticleStockBrowser({article}) {
      return getArticleStockBrowser({article: article})
    }
  }
}
</script>
