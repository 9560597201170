import Id from "@bbx/id~master/core/ts/Id";

interface Debounce_callback_props {
    job: () => Promise<any>
    success: (res: any) => void
    error: (err: any) => void
    time: number
}

export class Debounce {

    setTimeOut: any
    currents: string[]
    id: Id

    constructor() {
        this.id = new Id()
        this.currents = []
    }

    callback(props: Debounce_callback_props) {
        props.time = props.time !== undefined && props.time.constructor === Number ? props.time : 500
        this.currents = []
        let id = this.id.uniq()
        this.currents = [id]
        if (this.setTimeOut) clearTimeout(this.setTimeOut)
        this.setTimeOut = setTimeout(() => {
            props.job().then(res => {
                if (this.currents.includes(id)) {
                    props.success(res);
                }
            }).catch(props.error);
        }, props.time)
    }
}
