<style scoped lang="scss">

@import "../../scss/color";
@import "../../scss/size";

.HomeHighlight {
  overflow: hidden;
}

/*
 * * * * * * * * * *
 *
 *   OVERIDE
 *
 * * * * * * * * * *
 */

/**/

.HomeHighlight-desktop.HomeHighlight ::v-deep .Async--loader {
  min-height: 417px;
}

.HomeHighlight-tablet.HomeHighlight ::v-deep .Async--loader {
  min-height: 310px;
}

.HomeHighlight-mobile.HomeHighlight ::v-deep .Async--loader {
  min-height: 276px;
}


/**/

.HomeHighlight-desktop ::v-deep .Visible .Visible--animation {
  opacity: 0;
  transform: translateY(25%);
  transition: 500ms;
}

.HomeHighlight-tablet ::v-deep .Visible .Visible--animation {
  opacity: 0;
  transition: 500ms;
}

/**/

.HomeHighlight-desktop ::v-deep .Visible-isVisible .Visible--animation {
  opacity: 1;
  transform: translateY(0%);
}

.HomeHighlight-tablet ::v-deep .Visible-isVisible .Visible--animation {
  opacity: 1;
}

/**/

@for $i from 1 through 4 {
  .HomeHighlight-desktop ::v-deep .ArticleRecommendation--articlesContainer .ArticleRecommendation--article:nth-child(#{$i}) .Visible-isVisible .Visible--animation {
    transition-delay: $i * 100ms;
  }
}

@for $i from 1 through 4 {
  .HomeHighlight-tablet ::v-deep .ArticleRecommendation--articlesContainer .ArticleRecommendation--article:nth-child(#{$i}) .Visible-isVisible .Visible--animation {
    transition-delay: $i * 100ms;
  }
}

/**/

.HomeHighlight ::v-deep .CatalogueArticle {
  background: $primaryLight2;
}


.HomeHighlight ::v-deep .CatalogueArticle:hover {
  background: $primaryLight3;
}

/**/

.HomeHighlight ::v-deep .TabHeader {
  height: 100%;
}

.HomeHighlight ::v-deep .TabHeader--slots {
  height: 100%;
}

.HomeHighlight ::v-deep .TabHeader--slot-selected {
  font-weight: normal;
}


.HomeHighlight ::v-deep .TabBody--slot {
  display: block;
}

/**/

.HomeHighlight ::v-deep .ArticleRecommendation--article {
  width: 100%;
  min-width: auto;
  height: auto;
}

.HomeHighlight-desktop ::v-deep .ArticleRecommendation--article {
  min-height: 450px;
  margin-right: 1px;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeHighlight-desktop ::v-deep .ArticleRecommendation--article {
    min-height: 400px;
  }
}

.HomeHighlight-dekstop ::v-deep .ArticleRecommendation--article:last-child {
  margin-right: 0px;
}

.HomeHighlight-tablet ::v-deep .ArticleRecommendation--article {
  margin-right: 1px;
  min-height: 400px;
}

.HomeHighlight-tablet ::v-deep .ArticleRecommendation--article:last-child {
  margin-right: 0px;
}

.HomeHighlight-mobile ::v-deep .ArticleRecommendation--article {
  margin-right: 20px;
}

.HomeHighlight-mobile ::v-deep .ArticleRecommendation-mobile .ArticleRecommendation--article {
  width: 66%;
  min-width: 66%;
  max-height: 200px;
  height: 150px;
  padding-bottom: 150px;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeHighlight-desktop ::v-deep .CatalogueArticle--name {
    font-size: 15px;
    line-height: 22px;
  }
}

@media screen and (max-width: $menu-image-screen) {
  .HomeHighlight-desktop ::v-deep .CatalogueArticle-big .CatalogueArticle--price[data-v-29325377] .Price--big {
    font-size: 16px;
  }
}

.HomeHighlight-mobile ::v-deep .CatalogueArticle-small .CatalogueArticle--header {
  padding: 15px 15px 0px 15px;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeHighlight-desktop ::v-deep .CatalogueArticle-big .CatalogueArticle--header {
    padding: 20px 20px 0px 20px;
  }
}

.HomeHighlight-mobile ::v-deep .CatalogueArticle-adapt.CatalogueArticle-small .CatalogueArticle--body {
  padding: 15px;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeHighlight-desktop ::v-deep .CatalogueArticle-big .CatalogueArticle--body {
    padding: 20px 45px;
  }
}

.HomeHighlight-mobile ::v-deep .CatalogueArticle-small .CatalogueArticle--footer {
  padding: 0px 15px 15px 15px;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeHighlight-desktop ::v-deep .CatalogueArticle-big .CatalogueArticle--footer {
    padding: 0px 20px 20px 20px;
  }
}

.HomeHighlight-mobile ::v-deep .CatalogueArticle--footerLeft {
  align-items: center;
  text-align: center;
}

.HomeHighlight-mobile ::v-deep .CatalogueArticle--footerRight {
  display: none;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeHighlight-desktop ::v-deep .CatalogueArticle-big .CatalogueArticle--footerRight {
    margin-left: 20px;
  }
}

/*
 * * * * * * * * * *
 *
 *   CLASS
 *
 * * * * * * * * * *
 */

/**/

.HomeHighlight--header {
  margin-bottom: 1px; /* for the plugin visible */
}

.HomeHighlight-desktop .HomeHighlight--header {
  display: flex;
  justify-content: space-between;
  flex-wrap: nowrap;
  height: $home-carousel-dektop-min-bottom;
  padding: 0 $homeCarouselDektopLeft 0px $homeCarouselDektopLeft;
}

.HomeHighlight-tablet .HomeHighlight--header {
  padding: $tabletHomeCarouselLeft;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: nowrap;
}

.HomeHighlight-mobile .HomeHighlight--header {
  padding: 64px 0 31px $home-carousel-mobile-left;
}

.HomeHighlight-mobile .HomeHighlight--header ::v-deep .TabHeader--slots {
  text-align: center;
  display: block;
  white-space: nowrap;
}

.HomeHighlight-mobile .HomeHighlight--header ::v-deep .TabHeader--slot {
  display: inline-block;
  margin: 0;
}

.HomeHighlight--tab {
  font-family: 'AmbroiseRegular', sans-serif;
  color: rgba(27, 25, 25, 1);
  height: 100%;
  display: flex;
  align-items: center;
  position: relative;
  cursor: pointer;
  white-space: nowrap;

  transform: translateY(100%);
  opacity: 0.35;

  transition: 500ms;
}

@for $i from 1 through 3 {
  .HomeHighlight ::v-deep .TabHeader--slot:nth-child(#{$i}) .HomeHighlight--tab {
    transition-delay: $i * 100ms;
  }
}

.HomeHighlight-visible .HomeHighlight--tab {
  transform: translateY(0%);
}

.HomeHighlight--tab:hover {
  opacity: 1;
}

.HomeHighlight--tab-selected {
  opacity: 1;
}

.HomeHighlight-desktop .HomeHighlight--tab {
  font-size: 34px;
  margin-right: 34px;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeHighlight-desktop .HomeHighlight--tab {
    font-size: 32.5px;
    margin-right: 32.5px;
  }
}

.HomeHighlight-tablet .HomeHighlight--tab {
  font-size: 28px;
  margin-right: 40px;
}

.HomeHighlight-mobile .HomeHighlight--tab {
  font-size: 28px;
  margin-right: 28px;
}

/**/

.HomeHighlight--whiteBubble {
  width: 30px;
  height: 30px;
  position: absolute;
  top: 50%;
  background-color: white;
  display: none;
}

.HomeHighlight-desktop .HomeHighlight--whiteBubble {
  margin-top: 26px;
  right: 62px;
  display: block;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeHighlight-desktop .HomeHighlight--whiteBubble {
    margin-top: 24px;
    right: 59px;
  }
}

.HomeHighlight-tablet .HomeHighlight--whiteBubble {
  margin-top: 20px;
  right: 50px;
  display: block;
}

/**/

.HomeHighlight--barre {
  position: absolute;
  top: 50%;
  height: 1px;
  width: 100%;
  background-color: #31302F;
  transform: scaleX(0);
  transition: transform 500ms;
}

.HomeHighlight-desktop .HomeHighlight--barre {
  margin-top: 26px;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeHighlight-desktop .HomeHighlight--barre {
    margin-top: 24px;
  }
}

.HomeHighlight-desktop .HomeHighlight--tab-selected .HomeHighlight--barre {
  transform: scaleX(1);
}

.HomeHighlight-tablet .HomeHighlight--barre {
  margin-top: 21px;
}

.HomeHighlight-tablet .HomeHighlight--tab-selected .HomeHighlight--barre {
  transform: scaleX(1);
}

/**/

.HomeHighlight--link {
  font-family: 'MontserratSemiBold', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  display: none;
  height: 100%;
  position: relative;
  box-sizing: border-box;
  cursor: pointer;

  transform: translateY(100%);

  transition: 500ms;
}

.HomeHighlight-visible .HomeHighlight--link {
  transform: translateY(0%);
}

.HomeHighlight--link ::v-deep .Link {
  text-decoration: none;
  color: #31302F;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
}

.HomeHighlight-desktop .HomeHighlight--link {
  display: flex;
  align-items: center;
}

.HomeHighlight-tablet .HomeHighlight--link {
  display: flex;
}

.HomeHighlight--linkBarre {
  position: absolute;
  top: 50%;
  height: 1px;
  width: 100%;
  background-color: #4a4a49;
  transition: 500ms;
}

.HomeHighlight-desktop .HomeHighlight--linkBarre {
  transform: scaleX(1);
  margin-top: 13px;
}

.HomeHighlight-desktop .HomeHighlight--link ::v-deep .Link:hover .HomeHighlight--linkBarre {
  transform: scaleX(0.5);
}

.HomeHighlight-tablet .HomeHighlight--linkBarre {
  transform: scaleX(1);
  margin-top: 12px;
}

/**/

.HomeHighlight--body {

}

.HomeHighlight-desktop .HomeHighlight--body {
  padding: 0 $homeCarouselDektopLeft;
}

.HomeHighlight-tablet .HomeHighlight--body {
  padding: 0 40px;
}

.HomeHighlight-mobile .HomeHighlight--body {
  padding-left: 20px;
}

.HomeHighlight-spacer {
  min-height: 1px;
  min-width: 1px;
  height: 1px;
  width: 1px;
}

/**/

.HomeHighlight--buttonLink {
  padding: 40px ($home-carousel-mobile-left) 0 ($home-carousel-mobile-left);
}

.HomeHighlight--buttonLink ::v-deep .Link {
  width: 100%;
  height: 100%;
}

</style>

<template>
  <div class="HomeHighlight" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <ScrollZone>
        <div class="HomeHighlight--header">
          <TabHeader :currentId="state.shopTypeListId"
                     :getId="v => v.id"
                     :items="state.headers"
                     :onClick="onClickTab">
            <template v-slot:default="{data}">
              <div class="HomeHighlight--tab"
                   :class="class_tab(data.item)"
                   @mouseenter="() => onEnterTab(data.item)">
                <Term v-if="data.item.id === 0" :term="lang.term(671)"></Term>
                <Term v-else-if="data.item.id === 1" :term="lang.term(672)"></Term>
                <Term v-else-if="data.item.id === 2" :term="lang.term(673)"></Term>
                <div class="HomeHighlight--barre"></div>
                <div class="HomeHighlight--whiteBubble" v-if="data.item.id === 1"></div>
              </div>
            </template>
          </TabHeader>
          <div class="HomeHighlight--link">
            <Link :pathIds="[PATHS.CATALOG]">
              <Term :term="lang.term(674)"></Term>
              <div class="HomeHighlight--linkBarre"></div>
            </Link>
          </div>
        </div>
      </ScrollZone>
      <div class="HomeHighlight--body">
        <Async :fetch="async () => init()">
          <TabBody :currentId="state.shopTypeListId" :getId="v => v.id" :items="state.bodies">
            <template v-slot:default="{data}">
              <template v-if="data.isSelected">
                <template v-if="props.Responsive.breakPoint.name === SCREEN.TABLET">
                  <ArticleRecommendation :displayHeader="false"
                                         :articleIds="articleIds.slice(0,2)"
                                         :size="articleRecommendationSize"
                                         :breakPoints="props.Responsive.breakPoints"
                                         :fetch="selectArticleAsync">
                  </ArticleRecommendation>
                  <div class="HomeHighlight-spacer"></div>
                  <ArticleRecommendation :displayHeader="false"
                                         :articleIds="articleIds.slice(2,4)"
                                         :size="articleRecommendationSize"
                                         :breakPoints="props.Responsive.breakPoints"
                                         :fetch="selectArticleAsync">
                  </ArticleRecommendation>
                </template>
                <template v-else>
                  <ArticleRecommendation :displayHeader="false"
                                         :articleIds="articleIds.slice(0,4)"
                                         :size="articleRecommendationSize"
                                         :breakPoints="props.Responsive.breakPoints"
                                         :fetch="selectArticleAsync">
                  </ArticleRecommendation>
                </template>
              </template>
            </template>
          </TabBody>
        </Async>
      </div>
      <div class="HomeHighlight--buttonLink" v-if="props.Responsive.breakPoint.name === SCREEN.MOBILE">
        <Link :pathIds="[PATHS.CATALOG]">
          <ButtonAction :term="lang.term(675)"></ButtonAction>
        </Link>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {apiClient} from "../../service/apiClientService";

import selectSelection from "../../../@common/api/selection/selectSelection";
import selectArticles from "../../../@common/api/article/selectArticles";

import {Article} from "../../../@common/delta/database/Article";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {PATHS} from "../../../@common/constant/PATHS";
import {FILTER_DISPLAY} from "../../../@common/constant/FILTER_DISPLAY";

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    },
    breakPoints: {
      type: Array,
      default: () => ([])
    },
    verticalBreakPoints: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      PATHS,
      FILTER_DISPLAY,
      SCREEN,
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: this.breakPoints,
          verticalBreakPoints: this.verticalBreakPoints,
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      icon: {},
      state: {
        /**
         * @type boolean
         */
        visible: false,
        /**
         * @type Selection[]
         */
        selections: [],
        /**
         * @type number
         */
        shopTypeListId: 0,
        /**
         * @type any[]
         */
        headers: [
          {
            id: 0,
          },
          {
            id: 1,
          },
          {
            id: 2,
          }
        ],
        /**
         * @type any[]
         */
        bodies: [
          {
            id: 0,
          },
          {
            id: 1,
          },
          {
            id: 2,
          }
        ]
      },
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.state.visible) classes.push(`HomeHighlight-visible`)
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`HomeHighlight-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`HomeHighlight-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`HomeHighlight-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`HomeHighlight-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`HomeHighlight-desktopY`)
      return classes
    },
    articleIds() {
      return this.state.selections.filter(selection => {
        let shopTypeListId = 0
        for (const shopTypeList of selection.shopTypeLists) {
          shopTypeListId = shopTypeList.id
        }
        return selection.articles.length && shopTypeListId === this.state.shopTypeListId
      }).map(selection => {
        let articleId = 0
        for (const article of selection.articles) {
          articleId = article.id
        }
        return articleId
      })
    },
    articleRecommendationSize() {
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) return FILTER_DISPLAY.BIG
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) return FILTER_DISPLAY.BIG
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) return FILTER_DISPLAY.SMALL
    }
  },
  beforeMount() {
  },
  mounted() {
    setTimeout(() => {
      this.state.visible = true
    })
  },
  beforeDestroy() {
  },
  methods: {
    async init() {
      await this.select()
    },
    async select() {
      const {data} = await apiClient.do(selectSelection, selectSelection.with({
        $scope: 2,
        active: true,
        $sort: 'ask'
      }))
      this.state.selections = data
    },
    async selectArticleAsync(articleId) {
      const {data} = await apiClient.do(selectArticles, selectArticles.with({
        $scope: 4,
        $count: false,
        $ids: [articleId],
      }))
      return new Article(data[0])
    },
    onClickTab(item) {
      this.state.shopTypeListId = item.id
    },
    onEnterTab(item) {
      return
      if (this.props.Responsive.breakPoint.name !== SCREEN.DESKTOP) return
      this.onClickTab(item)
    },
    class_tab(item) {
      const classes = []
      if (this.state.shopTypeListId === item.id) classes.push(`HomeHighlight--tab-selected`)
      return classes
    },
  }
}
</script>
