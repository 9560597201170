export const ROLE = {
    ROOT: 1,
    ADMIN: 2,
    CHR: 3, // GESTION
    SELLER: 4,
    CUSTOMER: 5,
    SEO: 6,
}

export const ROLE_ALL = [
    ROLE.ROOT,
    ROLE.ADMIN,
    ROLE.CHR,
    ROLE.SELLER,
    ROLE.CUSTOMER,
    ROLE.SEO,
]

export const ROLE_STAFF = [
    ROLE.ROOT,
    ROLE.ADMIN,
    ROLE.CHR,
]

export const ROLE_BUYER = [
    ROLE.SELLER,
    ROLE.CUSTOMER,
    ROLE.SEO,
]
