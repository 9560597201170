<style scoped lang="scss">

@import "../../scss/color";
@import "../../scss/size";

$pictureWidth: 660px;
$desktopSmallPictureWidth: 460px;

/**/

.Footer {
}

.Footer-desktop {

}

.Footer-tablet {
  padding-top: 80px;
}

.Footer-mobile {

}

/**/

.Footer ::v-deep .Link * {
  text-decoration: none;
}

.Footer--container {
  position: relative;
}

/**/

.Footer--newsletter {
  display: flex;
  align-items: flex-start;
}

.Footer-desktop .Footer--newsletter {
  min-height: 146px;
  padding-right: $pictureWidth;
  padding-left: $homeCarouselDektopLeft;
}

@media screen and (max-width: $menu-image-screen) {
  .Footer-desktop .Footer--newsletter {
    min-height: 136px;
    padding-right: $desktopSmallPictureWidth;
  }
}

.Footer-tablet .Footer--newsletter {
  min-height: 136px;
  padding-right: 40px;
  padding-left: 40px;
}

.Footer-mobile .Footer--newsletter {
  flex-direction: column;
  padding: 40px 20px;
}

/**/

.Footer--suscribe {
  font-family: 'AmbroiseRegular', sans-serif;
  position: relative;
  top: -11px;
}

.Footer-desktop .Footer--suscribe {
  font-size: 45px;
  line-height: 55px;
}

@media screen and (max-width: $menu-image-screen) {
  .Footer-desktop .Footer--suscribe {
    font-size: 35px;
    line-height: 50px;
  }
}

.Footer-tablet .Footer--suscribe {
  font-size: 45px;
  line-height: 55px;
}

.Footer-mobile .Footer--suscribe {
  font-size: 30px;
  line-height: 40px;
  flex: 1;
  width: 100%;
  text-align: center;
  padding-bottom: 40px - 21px;
}

/**/


.Footer--form {
  flex: 1;
  width: 100%;
}

.Footer-desktop .Footer--form {
  padding-left: 100px;
  padding-right: 50px;
}

@media screen and (max-width: $menu-image-screen) {
  .Footer-desktop .Footer--form {
    padding-left: 80px;
    padding-right: 40px;
  }
}

.Footer-tablet .Footer--form {
  padding-left: 80px;
}

.Footer-mobile .Footer--form {

}

/**/

.Footer--form ::v-deep input {
  border: none;
  opacity: 1;
  border-top: solid 1px #31302F;
  border-bottom: solid 1px #31302F;
  border-radius: 0;
  font-family: 'AeonikRegular', sans-serif;
  letter-spacing: 1px;
  color: #000000;
  transition: 500ms;
}

.Footer-desktop .Footer--form ::v-deep input {
  padding: (40px + 6px) (40px - 1px) (40px + 8px) (40px - 1px);
  font-size: 20px;
  line-height: 20px;
}

.Footer-tablet .Footer--form ::v-deep input {
  padding: (40px + 6px) (40px - 1px) (40px + 8px) (40px - 1px);
  font-size: 20px;
  line-height: 20px;
}

.Footer-mobile .Footer--form ::v-deep input {
  padding: (20px + 6px) (20px - 1px) (20px + 8px) 0;
  font-size: 16px;
  line-height: 20px;
}

/**/

.Footer--form ::v-deep input::placeholder {
  color: #000000;
}

.Footer--form ::v-deep input:hover {
  opacity: 0.5;
}

.Footer--input {
  position: relative;
}

.Footer--button {
  position: absolute;
  top: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  border: none;
  background: none;
  transition: 500ms;
  transform: translateX(0);
  cursor: pointer;
}

.Footer-desktop .Footer--button {
  right: 20px;
  width: 86px;
  height: 96px;
}

.Footer-tablet .Footer--button {
  right: 20px;
  width: 86px;
  height: 96px;
}

.Footer-mobile .Footer--button {
  right: 0px;
  width: 46px;
  height: 56px;
}

/**/

.Footer--button ::v-deep .Vector {
  width: 100%;
  height: auto;
}

.Footer-desktop .Footer--button ::v-deep .Vector {

}

.Footer-tablet .Footer--button ::v-deep .Vector {

}

.Footer-mobile .Footer--button ::v-deep .Vector {
  position: relative;
  top: 2px;
}

.Footer--button:hover {
  transform: translateX(20px);
}

/**/

.Footer--body {
  background-color: $primaryLight;
  z-index: 1;
  position: relative;
}

.Footer-desktop .Footer--body {
  margin-right: $pictureWidth * 60 / 100;
  padding: (100px - 9px) 100px 50px $homeCarouselDektopLeft;
}

@media screen and (max-width: $menu-image-screen) {
  .Footer-desktop .Footer--body {
    margin-right: $desktopSmallPictureWidth * 50 / 100;
    padding: (100px - 9px) 57px 50px $homeCarouselDektopLeft;
  }
}

.Footer-tablet .Footer--body {
  padding: 100px 40px 80px 40px;
}

.Footer-mobile .Footer--body {
  padding: 40px 20px 40px 20px;
}


/**/

.Footer--columns {
  display: flex;
}

.Footer-desktop .Footer--columns {
  padding-bottom: 100px;
}

@media screen and (max-width: $menu-image-screen) {
  .Footer-desktop .Footer--columns {
    justify-content: space-between;
  }
}

.Footer-tablet .Footer--columns {
  justify-content: space-between;
  padding-bottom: 80px;
}

.Footer-mobile .Footer--columns {
  justify-content: space-between;
  flex-direction: column-reverse;
}

/**/

.Footer--column {
}

.Footer-desktop .Footer--column {

}

.Footer-tablet .Footer--column {

}

.Footer-mobile .Footer--column {
}

/**/

.Footer--column-1 {
}

.Footer-desktop .Footer--column-1 {
  padding-right: 200px;
  padding-top: 6px;
}

@media screen and (max-width: $menu-image-screen) {
  .Footer-desktop .Footer--column-1 {
    padding-right: 0;
  }
}

.Footer-tablet .Footer--column-1 {
  padding-top: 6px;
  padding-right: 0;
  position: relative;
  top: -20px;
}

.Footer-mobile .Footer--column-1 {
  padding-right: 0;
}

/**/

.Footer--column-2 {
}

.Footer-desktop .Footer--column-2 {
  padding-top: 6px;
  flex: 1;
  padding-right: 100px;
}

@media screen and (max-width: $menu-image-screen) {
  .Footer-desktop .Footer--column-2 {
    flex: initial;
    padding-right: 0;
  }
}

.Footer-tablet .Footer--column-2 {
  padding-top: 6px;
  flex: initial;
  padding-right: 0;
  position: relative;
  top: -20px;
}

.Footer-mobile .Footer--column-2 {
  flex: initial;
  padding-right: 0;
}

/**/

.Footer--column-3 {
}

.Footer-desktop .Footer--column-3 {

}

@media screen and (max-width: $menu-image-screen) {
  .Footer-desktop .Footer--column-3 {

  }
}

.Footer-tablet .Footer--column-3 {
  text-align: right;
}

.Footer-mobile .Footer--column-3 {

}

/**/

.Footer--column-4 {

}

.Footer-desktop .Footer--column-4 {
  display: none;
}

.Footer-tablet .Footer--column-4 {
  display: none;
}

.Footer-mobile .Footer--column-4 {

}

/**/

.Footer--subColumn {
}

/**/

.Footer--subColumn-1 {
}

.Footer-desktop .Footer--subColumn-1 {
  padding-bottom: 100px - 12px;
}

.Footer-tablet .Footer--subColumn-1 {
  padding-bottom: 100px - 12px;
}

.Footer-mobile .Footer--subColumn-1 {

}

/**/

.Footer--title {
  font-family: 'CommutersSansBold', sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: #31302F;
}

.Footer-desktop .Footer--title {
  padding-bottom: 50px - 5px;
}

.Footer-tablet .Footer--title {
  padding-bottom: 50px - 5px;
}

.Footer-mobile .Footer--title {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 18px 0 17px 0;
}

/**/

.Footer--title ::v-deep .Vector {

}

.Footer-desktop .Footer--title ::v-deep .Vector {
  display: none;
}

.Footer-tablet .Footer--title ::v-deep .Vector {
  display: none;
}

.Footer-mobile .Footer--title ::v-deep .Vector {
  width: 23px;
  height: 23px;
  padding-right: 11px;
}

/**/

.Footer-mobile .Footer--title ::v-deep .Vector svg {
  transform: rotate(0deg);
  transition: 500ms;
}

.Footer-mobile .Footer--title-isOpened ::v-deep .Vector svg {
  transform: rotate(45deg);
}

/**/

.Footer--title-1 {

}

.Footer-desktop .Footer--title-1 {
  display: none;
}

.Footer-tablet .Footer--title-1 {
  display: none;
}

.Footer-mobile .Footer--title-1 {
  border-top: solid 1px #31302F;
}

/**/

.Footer--contents {
  font-family: 'AeonikRegular', sans-serif;
  font-size: 16px;
  line-height: 31px;
  letter-spacing: 0.20px;
}

.Footer-desktop .Footer--contents {

}

.Footer-tablet .Footer--contents {

}

.Footer-mobile .Footer--contents {
  border-bottom: solid 1px #31302F;
  height: 0;
  overflow: hidden;
}

.Footer-mobile .Footer--contents-isOpened {
  height: auto;
}

/**/

.Footer--contents1 {

}

.Footer-desktop .Footer--contents1 {

}

.Footer-tablet .Footer--contents1 {

}

.Footer-mobile .Footer--contents1 {
  opacity: 0;
  transform: translateX(50px);
  transition: 500ms;
  padding-bottom: 24px;
  padding-left: 10px;
}

.Footer-mobile .Footer--contents-isOpened .Footer--contents1 {
  transform: translateX(0px);
  opacity: 1;
}

/**/

.Footer--link {
  cursor: pointer;
}

/**/

.Footer--contents ::v-deep p, .Footer--contents ::v-deep a {
  color: #31302F;
  text-decoration: none;
  opacity: 1;
  transition: 300ms;
}

.Footer--contents ::v-deep p:hover, .Footer--contents ::v-deep a:hover {
  opacity: 0.6;
}

.Footer--contents ::v-deep p:first-letter {
  text-transform: uppercase;
}

/**/

.Footer--content-1 {
  display: flex;
  align-items: center;
}

.Footer--content-1 ::v-deep svg {
  fill: rgba(0, 0, 0, 0.3);
  position: relative;
  top: -2px;
  left: 10px;
}

/**/

.Footer--logo {
  padding-bottom: 1px;
}

.Footer-desktop .Footer--logo {
  width: 375px;
  position: relative;
  top: -27px;
}

.Footer-tablet .Footer--logo {
  width: 300px;
  position: relative;
  top: -27px;
}

.Footer-mobile .Footer--logo {
  width: 100%;
  padding-bottom: 40px;
}

/**/

.Footer--logo ::v-deep .Vector {
  fill: #31302F;
}

/**/

.Footer--social ::v-deep a:first-letter {
  text-transform: uppercase;
}

/**/

.Footer--contact {
}

.Footer-desktop .Footer--contact {
  padding-top: 30px;
}

.Footer-tablet .Footer--contact {
  padding-top: 30px;
}

.Footer-mobile .Footer--contact {
  padding-top: 24px;
}

/**/

.Footer--contact a {
  display: flex;
  align-items: center;
}

.Footer-desktop .Footer--contact a {

}

.Footer-tablet .Footer--contact a {
  flex-direction: row-reverse;
}

.Footer-mobile .Footer--contact a {
  flex-direction: row-reverse;
  justify-content: flex-end;
  color: $primary;
}

/**/

.Footer--contact ::v-deep .Vector {
  padding-right: 10px;
  position: relative;
  top: 1px;
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Footer-tablet .Footer--contact ::v-deep .Vector {
  padding-right: 0px;
  padding-left: 10px;
}

.Footer-mobile .Footer--contact ::v-deep .Vector {
  padding-right: 0px;
  padding-left: 10px;
  fill: $primary;;
}

/*
 * * * * * * * * * *
 *
 *   MOBILE APP
 *
 * * * * * * * * * *
 */

.Footer--app {
  position: relative;
}

.Footer--appSpacer {
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
}

.Footer--appContainer {
  display: flex;
  align-items: center;
}

.Footer-desktop .Footer--appContainer {
  position: absolute;
  right: 0;
  top: -133px;
  width: 375px;
}

.Footer-tablet .Footer--appContainer {
  position: absolute;
  right: 0;
  flex-direction: row-reverse;
  top: -142px;
  width: 400px;
  text-align: right;
}

.Footer-mobile .Footer--appContainer {
  padding-top: 50px;
  flex-direction: column;
}

/**/

.Footer--appTitle {
  font-family: 'AeonikRegular', sans-serif;
  font-size: 10px;
  letter-spacing: 0.2px;
  color: #31302F;
  text-transform: uppercase;
}

/**/

.Footer--appPicture {
  display: flex;
}

.Footer--appPicture img {
  display: block;
  height: 33px;
}

/*
 * * * * * * * * * *
 *
 *   BOTTOM
 *
 * * * * * * * * * *
 */


.Footer--bottom {
  display: flex;
  align-items: center;
}

.Footer-desktop .Footer--bottom {

}

@media screen and (max-width: $menu-image-screen) {
  .Footer-desktop .Footer--bottom {
    justify-content: space-between;
  }
}

.Footer-tablet .Footer--bottom {
  justify-content: space-between;
  flex-direction: column;
  height: 150px;
}

.Footer-mobile .Footer--bottom {
  justify-content: space-between;
  flex-direction: column-reverse;
}

/**/

.Footer--copyright {
  display: flex;
  color: #000000;
  font-family: 'AeonikRegular', sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  align-items: center;
}

.Footer-desktop .Footer--copyright {

}

.Footer-tablet .Footer--copyright {

}

.Footer-mobile .Footer--copyright {
  flex-direction: column-reverse;
}

/**/

.Footer--copyrighText {
  display: flex;
}

.Footer-desktop .Footer--copyrighText {

}

.Footer-tablet .Footer--copyrighText {

}

.Footer-mobile .Footer--copyrighText {
}

/**/

.Footer--year {
  padding-right: 4px;
}

/**/

.Footer--lang {
}

.Footer-desktop .Footer--lang {
  padding-right: 10px;
}

.Footer-tablet .Footer--lang {
  padding-right: 10px;
}

.Footer-mobile .Footer--lang {
  padding-top: 20px;
  padding-left: 10px;
}

/**/

.Footer--legals {
  display: flex;
}

.Footer-desktop .Footer--legals {
  padding-left: 83px;
  flex: 1;
}

@media screen and (max-width: $menu-image-screen) {
  .Footer-desktop .Footer--legals {
    padding-left: 0;
    flex: initial;
  }
}

.Footer-tablet .Footer--legals {
}

.Footer-mobile .Footer--legals {
  display: none;
}

/**/

.Footer--legals ::v-deep svg {
  fill: rgba(0, 0, 0, 0.3);
  transition: 300ms;
  cursor: pointer;
}

.Footer--legals ::v-deep svg:hover {
  fill: rgba(0, 0, 0, 1);
}

.Footer--legals ::v-deep a {
  color: rgba(0, 0, 0, 0.6);
  font-family: 'AeonikRegular', sans-serif;
  font-size: 14px;
  letter-spacing: 1px;
  margin-right: 15px;
  display: block;
  opacity: 1;
  transition: 300ms;
}

.Footer--legals ::v-deep a:hover {
  opacity: 0.6;
}

.Footer--cookieSetting {
  width: 18px;
  height: 18px;
}

/**/

.Footer--payments {
  display: flex;
  align-items: center;
}

.Footer-desktop .Footer--payments {

}

.Footer-tablet .Footer--payments {

}

.Footer-mobile .Footer--payments {
  padding-top: 50px;
  padding-bottom: 40px;
}

/**/

.Footer--payment {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-left: 20px;
}

.Footer--payment ::v-deep .Vector * {
  height: 20px;
  width: auto;
}

/**/

.Footer--payment-1 {

}

.Footer-desktop .Footer--payment-1 {

}

.Footer-tablet .Footer--payment-1 {

}

.Footer-mobile .Footer--payment-1 {
  margin-left: 0px;
}


/**/

.Footer--picture {
  height: 100%;
  overflow: hidden;
}

.Footer-desktop .Footer--picture {
  position: absolute;
  top: 0;
  right: 0;
  width: $pictureWidth;
}

@media screen and (max-width: $menu-image-screen) {
  .Footer-desktop .Footer--picture {
    width: $desktopSmallPictureWidth;
  }
}

.Footer-tablet .Footer--picture {
  width: 100%;
  height: 500px;
}

.Footer-mobile .Footer--picture {
  width: 100%;
  height: 65vh;
}

/**/

.Footer--pictureImmage {
  width: 100%;
  height: 100%;
  height: calc(100% + 100px);
  min-height: calc(100% + 100px);
  position: absolute;
  top: 0;
  left: 0;
  background-color: $primaryLight3;
}

</style>

<template>
  <footer class="Footer" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <div class="Footer--container">
        <div class="Footer--newsletter">
          <div class="Footer--suscribe">
            <AnimationTitle>
              <div>
                <Term :term="lang.term(641)"></Term>
              </div>
            </AnimationTitle>
            <AnimationTitle>
              <div>
                <Term :term="lang.term(642)"></Term>
              </div>
            </AnimationTitle>
          </div>
          <div class="Footer--form">
            <Form>
              <div class="Footer--input">
                <InputText :error="state.formNewsletter.display('email')"
                           :placeholder="lang.translate(654)"
                           :svg="icon.mail"
                           :value="state.newsletter.email"
                           :onChangeValue="v => state.newsletter.email = v">
                </InputText>
                <button class="Footer--button" @click="() => saveNewsletter()">
                  <Loader v-if="state.loading"></Loader>
                  <Vector v-else :svg="icon.arrow"></Vector>
                </button>
              </div>
            </Form>
          </div>
        </div>
        <div class="Footer--body">
          <div class="Footer--columns">


            <div class="Footer--column Footer--column-4">
              <div class="Footer--title" :class="class_title('légal')" @click="() => selectTab('légal')">
                <Term :term="lang.term(643)"></Term>
                <Vector :svg="icon.cross"></Vector>
              </div>
              <div class="Footer--contents" :class="class_contents('légal')">
                <div class="Footer--contents1">
                  <CountryLocation>
                    <div>
                      <Link :url="projectUrl(PROJECT_URL.LEGAL_CGU_CGV)">
                        <Term :term="lang.term(258)"/>
                      </Link>
                    </div>
                  </CountryLocation>
                  <div>
                    <Link :url="projectUrl(PROJECT_URL.LEGAL_INFORMATIONS_LÉGALES)">
                      <Term :term="lang.term(259)"/>
                    </Link>
                  </div>
                  <div>
                    <Link :url="projectUrl(PROJECT_URL.LEGAL_CONFIDENTIALITÉ)">
                      <Term :term="lang.term(260)"/>
                    </Link>
                  </div>
                  <div class="Footer--content-1">
                    <Link :url="projectUrl(PROJECT_URL.LEGAL_COOKIES)">
                      <Term :term="lang.term(261)"/>
                    </Link>
                    <div class="Footer--cookieSetting" @click="() => openCookie()">
                      <Vector :svg="icon.settings"></Vector>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="Footer--column Footer--column-1">
              <div class="Footer--subColumn Footer--subColumn-1">
                <div class="Footer--title" :class="class_title('menu')" @click="() => selectTab('menu')">
                  <Term :term="lang.term(644)"></Term>
                  <Vector :svg="icon.cross"></Vector>
                </div>
                <div class="Footer--contents" :class="class_contents('menu')">
                  <div class="Footer--contents1">
                    <div>
                      <Link :href="urlMobilier()">
                        <Term :term="lang.term(645)"></Term>
                      </Link>
                    </div>
                    <div>
                      <Link :href="urlLuminaire()">
                        <Term :term="lang.term(646)"></Term>
                      </Link>
                    </div>
                    <div>
                      <Link :href="urlOutlet()">
                        <Term :term="lang.term(647)"></Term>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
              <div class="Footer--subColumn">
                <div class="Footer--title" :class="class_title('suivez-nous')" @click="() => selectTab('suivez-nous')">
                  <Term :term="lang.term(648)"></Term>
                  <Vector :svg="icon.cross"></Vector>
                </div>
                <div class="Footer--contents" :class="class_contents('suivez-nous')">
                  <div class="Footer--contents1">
                    <div class="Footer--social" v-for="social in state.socials" :key="social.id">
                      <Link :href="social.href" :indexed="false" :newPage="true">
                        {{ social.title }}
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>


            <div class="Footer--column Footer--column-2">
              <div class="Footer--title" :class="class_title('collections')" @click="() => selectTab('collections')">
                <Term :term="lang.term(649)"></Term>
                <Vector :svg="icon.cross"></Vector>
              </div>
              <div class="Footer--contents" :class="class_contents('collections')">
                <div class="Footer--contents1">
                  <Async :fetch="async () => selectCollections()">
                    <div v-for="collection in collections" :key="collection.id">
                      <Link v-if="collection.url.id" :url="collection.url">
                        <Term :term="collection.term"/>
                      </Link>
                      <Link v-if="collection.paths.length" :pathIds="collection.paths">
                        <Term :term="collection.term"/>
                      </Link>
                    </div>
                  </Async>
                </div>
              </div>
            </div>


            <div class="Footer--column Footer--column-3">
              <div class="Footer--logo">
                <Vector :svg="icon.logo"/>
              </div>
              <div class="Footer--title Footer--title-1" :class="class_title('les jardins')"
                   @click="() => selectTab('les jardins')">
                <Term :term="lang.term(650)"></Term>
                <Vector :svg="icon.cross"></Vector>
              </div>
              <div class="Footer--contents" :class="class_contents('les jardins')">
                <div class="Footer--contents1">
                  <div v-for="marque in marques" :key="marque.id">
                    <Link v-if="marque.url.id" :url="marque.url">
                      <Term :term="marque.term"/>
                    </Link>
                    <Link v-if="marque.paths.length" :pathIds="marque.paths">
                      <Term :term="marque.term"/>
                    </Link>
                  </div>
                  <div class="Footer--link" @click="() => findSeller()" v-if="state.isHome">
                    <Term :term="lang.term(651)"/>
                  </div>
                  <div>
                    <Link :pathIds="[PATHS.ACCOUNT]">
                      <Term :term="lang.term(652)"/>
                    </Link>
                  </div>
                  <div class="Footer--link">
                    <Link v-if="displayLinkNewStore" :url="projectUrl(PROJECT_URL.MARQUE_NEW_STORE)">
                      Magasin d'usine
                    </Link>
                  </div>
                  <div class="Footer--contact">
                    <a href="mailto:contact@lesjardins.com">
                      <Vector :svg="icon.mail"/>
                      <span>contact@lesjardins.com</span>
                    </a>
                  </div>
                </div>
              </div>
            </div>
          </div>


          <div class="Footer--app">
            <div class="Footer--appContainer">
              <div class="Footer--appPicture">
                <a href="https://apps.apple.com/fr/app/les-jardins-3d/id1528946540" target="_blank" rel="nofollow">
                  <img :src="img.appleStore" alt="">
                </a>
                <div class="Footer--appSpacer"></div>
                <a href="https://play.google.com/store/apps/details?id=com.innersense.osmose.android.lesjardins" target="_blank" rel="nofollow">
                  <img :src="img.googlePlay" alt="">
                </a>
              </div>
              <div class="Footer--appSpacer"></div>
              <div class="Footer--appSpacer"></div>
              <div class="Footer--appTitle">
                <Term :term="lang.term(731)"></Term>
              </div>
            </div>
          </div>


          <div class="Footer--bottom">
            <div class="Footer--copyright">
              <div class="Footer--lang">
                <FlagLang
                    :opened="state.openedFlagLang"
                    :loading="state.loadingFlagLang"
                    :onOpenedChange="v => onFlagLangOpenedChange(v)"
                    :theme="THEME.DARK"
                    :lang="lang.lang"
                    :page="state.page"
                    :nuxtProp="nuxtProp"
                    :langs="lang.langs">
                </FlagLang>
              </div>
              <div class="Footer--copyrighText">
                <div class="Footer--year">© {{ year }} -</div>
                <Term :term="lang.term(653)"></Term>
              </div>
            </div>
            <div class="Footer--legals">
              <CountryLocation>
                <Link :url="projectUrl(PROJECT_URL.LEGAL_CGU_CGV)">
                  <Term :term="lang.term(258)"/>
                </Link>
              </CountryLocation>
              <Link :url="projectUrl(PROJECT_URL.LEGAL_INFORMATIONS_LÉGALES)">
                <Term :term="lang.term(259)"/>
              </Link>
              <Link :url="projectUrl(PROJECT_URL.LEGAL_CONFIDENTIALITÉ)">
                <Term :term="lang.term(260)"/>
              </Link>
              <Link :url="projectUrl(PROJECT_URL.LEGAL_COOKIES)">
                <Term :term="lang.term(261)"/>
              </Link>
              <div class="Footer--cookieSetting" @click="() => openCookie()">
                <Vector :svg="icon.settings"></Vector>
              </div>
            </div>
            <CountryLocation>
              <div class="Footer--payments">
                <div class="Footer--payment Footer--payment-1" :title="lang.translate(655)">
                  <Vector :svg="icon.sansFrais"></Vector>
                </div>
                <div class="Footer--payment" :title="lang.translate(656)">
                  <Vector :svg="icon.paypal"></Vector>
                </div>
                <div class="Footer--payment" :title="lang.translate(657)">
                  <Vector :svg="icon.masterCard"></Vector>
                </div>
                <div class="Footer--payment" :title="lang.translate(658)">
                  <Vector :svg="icon.visa"></Vector>
                </div>
              </div>
            </CountryLocation>
          </div>
        </div>
        <div class="Footer--picture">
          <ScrollParallax :distance="100" :reverse="true">
            <div class="Footer--pictureImmage">
              <Picture :src="img.arbre"></Picture>
            </div>
          </ScrollParallax>
        </div>
      </div>
    </Responsive>
  </footer>
</template>

<script>
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {dataSocials} from "../../data/socials";
import {THEME} from "../../../@common/constant/THEME";
import {menus} from "../../data/menus";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import Id from "@bbx/id~master/core/ts/Id";
import {Url} from "../../../@common/delta/database/Url";
import {getUrlByProjectUrlId} from "../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../@common/constant/PROJECT_URL";
import {controlForm} from "../../function/controlForm";
import createNewsletter from "../../../@common/api/newsletter/createNewsletter";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {Newsletter} from "../../../@common/delta/database/Newsletter";
import SCREEN from "@bbx/responsive~master/core/constant/SCREEN";
import BreakPoint from "@bbx/responsive~master/core/delta/BreakPoint";
import {eventService} from "../../service/eventService";
import {EVENT} from "../../../@common/constant/EVENT";
import {apiClient} from "../../service/apiClientService";
import selectCollections from "../../../@common/api/collection/selectCollections";
import {Term} from "../../../@common/delta/database/Term";
import selectPages from "../../../@common/api/page/selectPages";
import {Page} from "../../../@common/delta/database/Page";
import {sendError} from "../../function/sendError";
import {PATHS} from "../../../@common/constant/PATHS";
import {urlService} from "../../../@common/service/urlService";
import {isHome} from "../../../@common/function/isHome";
import {seoService} from "../../service/seoService";
import {getCountry} from "../../service/countryService";
import {COUNTRY_ISO} from "../../../@common/constant/COUNTRY";

const id = new Id()

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    },
  },
  data() {
    const menu = menus(this.nuxtProp);
    return {
      PROJECT_URL,
      THEME,
      PATHS,
      lang: getLangServiceBrowser(),
      icon: {
        shield: require('../../../@common/assets/icon/broken/Iconly-Broken-Shield Done.svg'),
        calling: require('../../../@common/assets/icon/broken/Iconly-Broken-Calling.svg'),
        lock: require('../../../@common/assets/icon/broken/Iconly-Broken-Lock.svg'),
        logo: require('../../../@common/assets/logo-black.svg'),
        mail: require('@bbx/vector~master/core/assets/svg/material/mail.svg'),
        settings: require('@bbx/vector~master/core/assets/svg/material/settings.svg'),
        sansFrais: require('../../../@common/assets/footer/3x-sans-frais.svg'),
        paypal: require('../../../@common/assets/footer/paypal.svg'),
        masterCard: require('../../../@common/assets/footer/master-card.svg'),
        visa: require('../../../@common/assets/footer/visa.svg'),
        arrow: require('../../../@common/assets/footer/arrow.svg'),
        cross: require('../../../@common/assets/footer/cross.svg'),
      },
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              width: 0
            }),
            new BreakPoint({
              name: SCREEN.TABLET,
              width: 900
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              width: 1400
            }),
          ],
          verticalBreakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              height: 0
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              height: 600
            }),
          ],
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      state: {
        socials: dataSocials,
        menu: menu,
        loading: false,
        formNewsletter: new ApiResponseForm(),
        newsletter: new Newsletter(),
        /**
         * @type Collection[]
         */
        collections: [],
        /**
         * @type Boolean
         */
        openedFlagLang: false,
        /**
         * @type Boolean
         */
        loadingFlagLang: true,
        /**
         * @type Page
         */
        page: new Page(),
        /**
         * @type string
         */
        tab: '',
        /**
         * @type boolean
         */
        isHome: false,
      },
      img: {
        arbre: require('../../../@common/assets/footer/arbre.jpg'),
        appleStore: require('../../../@common/assets/home/app/apple-store.png'),
        googlePlay: require('../../../@common/assets/home/app/google-play.png'),
      },
    }
  },
  watch: {
    'nuxtProp.page.id': {
      handler() {
        this.state.isHome = isHome(this.nuxtProp)
      }
    }
  },
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`Footer-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`Footer-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`Footer-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`Footer-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`Footer-desktopY`)
      return classes
    },
    collections() {

      const links = []

      if (this.state.collections.length) {
        for (const collection of this.state.collections) {
          for (const page of collection.pages) {
            for (const url of page.urls) {
              for (const sort of url.sorts) {
                for (const path of sort.paths) {
                  for (const path of sort.paths) {
                    links.push({
                      id: id.uniq(),
                      term: new Term(path.terms[1]),
                      paths: [],
                      url: url,
                    })
                  }
                }
              }
            }
          }
        }
      } else {
        for (const href of this.state.menu[0].links[2].hrefs) {
          links.push({
            id: id.uniq(),
            term: href.name,
            paths: href.paths || [],
            url: href.url || new Url(),
          })
        }
      }

      return links
    },
    marques() {
      const links = []
      for (const link of this.state.menu[3].links) {
        for (const href of link.hrefs) {
          links.push({
            id: id.uniq(),
            term: href.name,
            paths: href.paths || [],
            url: href.url || new Url(),
          })
        }
      }
      return links
    },
    year() {
      return new Date().getFullYear()
    },
    displayLinkNewStore(){
      return this.nuxtProp.url.paths[0].value === 'fr' && getCountry() === COUNTRY_ISO.FR;
    }
  },
  beforeMount() {
  },
  mounted() {
    this.state.isHome = isHome(this.nuxtProp)
    if(seoService.isActive) this.selectPage().catch(sendError)
  },
  beforeDestroy() {
  },
  methods: {

    async selectCollections() {
      const {data} = await apiClient.do(selectCollections, selectCollections.with({
        active: true,
        $popularFirst: true,
        $limit: 12
      }))
      this.state.collections = data.sort((a, b) => a.value.localeCompare(b.value))
    },

    async selectPage() {
      this.state.loadingFlagLang = true
      const {data} = await apiClient.do(selectPages, selectPages.with({$ids: [this.nuxtProp.page.id]}))
      this.state.page = new Page(data[0])
      this.state.loadingFlagLang = false
    },

    projectUrl(id) {
      return getUrlByProjectUrlId(this.nuxtProp, id)
    },

    async saveNewsletter() {
      return await controlForm({
        apiAction: createNewsletter,
        formAttribute: 'formNewsletter',
        formState: this.state,
        loaderAttribute: 'loading',
        loaderState: this.state,
        payload: this.state.newsletter,
        message: this.lang.translate(316),
        vue: this,
      });
    },
    openCookie() {
      eventService.triggerByName(new ListenerTrigger({
        name: EVENT.COOKIE_OPEN,
      }))
    },

    onFlagLangOpenedChange(v) {
      this.state.openedFlagLang = v
      if (v) this.selectPage().catch(sendError)
    },

    class_title(tab) {
      const classes = []
      if (this.state.tab === tab) classes.push(`Footer--title-isOpened`)
      return classes
    },

    class_contents(tab) {
      const classes = []
      if (this.state.tab === tab) classes.push(`Footer--contents-isOpened`)
      return classes
    },

    selectTab(tab) {
      this.state.tab = this.state.tab === tab ? '' : tab
    },

    urlLuminaire() {
      return urlService.getUrl(this.lang.lang.iso6391, [PATHS.CATALOG], {categoryIds: 1})
    },

    urlMobilier() {
      return urlService.getUrl(this.lang.lang.iso6391, [PATHS.CATALOG])
    },

    urlOutlet() {
      return urlService.getUrl(this.lang.lang.iso6391, [PATHS.CATALOG], {isOutlet: 1})
    },

    findSeller() {
      eventService.triggerByName(new ListenerTrigger({
        name: EVENT.CHANGE_OPENED_SELLER,
        payload: true
      }))
    }
  }
}
</script>
