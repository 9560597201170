import {deltaService} from "@bbx/delta~master/core/service/deltaService"

export interface NuxtPathInterface {
    value: any
}

export interface NuxtPath_constructor_props {
    [key: string]: any;
}

export class NuxtPath implements NuxtPathInterface {

    value: any

    constructor(props: NuxtPathInterface | NuxtPath_constructor_props = {}) {
        this.value = props.value
    }
}
