<style scoped>
.CookieChoice {
}

.CookieChoice--header {
  background: rgba(0, 0, 0, 0.03);
  padding: 25px;
  display: flex;
  justify-content: center;
}

.CookieChoice--header ::v-deep h2 {
  font-family: 'AmbroiseRegular', sans-serif;
}

.CookieChoice--body {
  padding: 0px 50px 50px 50px;
}

.CookieChoice--body ::v-deep .ButtonAction {
  display: inline-block;
}

.CookieChoice--description {
  line-height: 20px;
  font-size: 14px;
}

.CookieChoice--details {
  font-family: 'RalewayBold', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 1px;
}

.CookieChoice--detail {
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  font-family: 'ComfortaaRegular', sans-serif;
  text-transform: initial;
  padding: 10px;
  margin: 10px 0px;
}
</style>

<template>
  <div class="CookieChoice" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <!--      <div class="CookieChoice&#45;&#45;header">-->
      <!--        <Term :term="lang.term(354)" tag="h2"></Term>-->
      <!--      </div>-->
      <div class="CookieChoice--body">
        <InputSection :svg="icon.tune" :term="lang.term(367)">
          <InputSwitch :value="true" :disabled="true" :label="lang.term(363)"></InputSwitch>
          <br>
          <div class="CookieChoice--description">
            <Term :term="lang.term(357)"/>
          </div>
          <br>
          <br>
          <div class="CookieChoice--details">
            <Term :term="lang.term(358)"/>
            <div class="CookieChoice--detail">lesjardins.com ~ state-d246b3b9015a0f82ddda724e9b0b1f4a</div>
            <div class="CookieChoice--detail">google.com ~ _grecaptcha</div>
          </div>
        </InputSection>
        <InputSection :svg="icon.tune" :term="lang.term(360)">
          <InputSwitch :value="state.cookie.analayze" :onChangeValue="v => onChange(v, 'analayze')" :label="lang.term(363)"></InputSwitch>
          <br>
          <div class="CookieChoice--description">
            <Term :term="lang.term(361)"/>
          </div>
          <br>
          <br>
          <div class="CookieChoice--details">
            <Term :term="lang.term(358)"/>
            <div class="CookieChoice--detail">google.com ~ google analytics</div>
          </div>
        </InputSection>
        <InputSection :svg="icon.tune" :term="lang.term(538)">
          <InputSwitch :value="state.cookie.marketing" :onChangeValue="v => onChange(v, 'marketing')" :label="lang.term(363)"></InputSwitch>
          <br>
          <div class="CookieChoice--description">
            <Term :term="lang.term(539)"/>
          </div>
          <br>
          <br>
          <div class="CookieChoice--details">
            <Term :term="lang.term(358)"/>
            <div class="CookieChoice--detail">facebook.com</div>
            <div class="CookieChoice--detail">google.com ~ google ads</div>
          </div>
        </InputSection>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {STATE_COOKIE, stateService} from "../../service/stateService";
import {Cookie as CookieDelta} from "../../../@common/delta/cookie/Cookie";

export default {
  props: {},
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      state: {
        cookie: new CookieDelta()
      },
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`CookieChoice-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`CookieChoice-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`CookieChoice-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`CookieChoice-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`CookieChoice-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
    this.state.cookie = stateService.get(STATE_COOKIE)
    stateService.watch(STATE_COOKIE, v => this.state.cookie = v)
  },
  beforeDestroy() {
  },
  methods: {
    validate() {

    },
    onChange(v, attr) {
      this.state.cookie[attr] = v
      this.state.cookie.configured = true
      stateService.set(STATE_COOKIE, this.state.cookie)
    }
  }
}
</script>
