"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Shop = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Address_1 = require("./Address");
var PhoneNumber_1 = require("./PhoneNumber");
var User_1 = require("./User");
var Sage_1 = require("./Sage");
var ShopTypeList_1 = require("./ShopTypeList");
var Order_1 = require("./Order");
var Promotion_1 = require("./Promotion");
var Deposit_1 = require("./Deposit");
var Shop = /** @class */ (function () {
    function Shop(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.name = deltaService_1.deltaService.string(props.name, '');
        this.siret = deltaService_1.deltaService.string(props.siret, '');
        this.nafCOde = deltaService_1.deltaService.string(props.nafCOde, '');
        this.tvaNumber = deltaService_1.deltaService.string(props.tvaNumber, '');
        this.autoSagePromo = deltaService_1.deltaService.boolean(props.autoSagePromo, true);
        this.visibleOnMap = deltaService_1.deltaService.boolean(props.visibleOnMap, false);
        this.addresses = deltaService_1.deltaService.array(props.addresses, Address_1.Address);
        this.phoneNumbers = deltaService_1.deltaService.array(props.phoneNumbers, PhoneNumber_1.PhoneNumber);
        this.users = deltaService_1.deltaService.array(props.users, User_1.User);
        this.sages = deltaService_1.deltaService.array(props.sages, Sage_1.Sage);
        this.shopTypeLists = deltaService_1.deltaService.array(props.shopTypeLists, ShopTypeList_1.ShopTypeList);
        this.orders = deltaService_1.deltaService.array(props.orders, Order_1.Order);
        this.promotions = deltaService_1.deltaService.array(props.promotions, Promotion_1.Promotion);
        this.deposits = deltaService_1.deltaService.array(props.deposits, Deposit_1.Deposit);
    }
    return Shop;
}());
exports.Shop = Shop;
