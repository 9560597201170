<style scoped>
.Basket {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  position: relative;
}

.Basket-desktop.Basket {
  padding: 50px 50px 0px 50px;
}

.Basket-tablet.Basket {
  padding: 50px 50px 0px 50px;
}

.Basket-mobile.Basket {
  padding: 25px 25px 0px 25px;
}

.Basket--content {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.Basket--header {
  display: flex;
  font-size: 20px;
  align-items: center;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.Basket-desktop .Basket--header {
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.Basket-tablet .Basket--header {
  padding-bottom: 50px;
  margin-bottom: 50px;
}

.Basket-mobile .Basket--header {
  padding-bottom: 25px;
  margin-bottom: 25px;
}

.Basket--title {
  font-family: 'RalewayBold', sans-serif;
}

.Basket--bubble {
  width: 5px;
  height: 5px;
  background: rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  margin: 0 15px;
}

.Basket--count {
  color: rgba(0, 0, 0, 0.5);
  font-family: 'ComfortaaRegular', sans-serif;
  display: flex;
  align-items: center;
  font-size: 18px;
}

.Basket--countNumber {
  padding-right: 10px;
}

.Basket--body {
  overflow: auto;
  flex: 1;
}

.Basket--footer {
  text-align: center;
  display: flex;
  justify-content: center;
}

.Basket-desktop .Basket--footer {
  min-height: 90px;
}

.Basket-tablet .Basket--footer {
  min-height: 90px;
}

.Basket-mobile .Basket--footer {
  min-height: 65px;
}

.Basket--footer ::v-deep .ButtonAction {
  height: 100%;
  width: 100%;
}
</style>

<template>
  <div class="Basket" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="Basket--content">
        <div class="Basket--header">
          <div class="Basket--title">
            <Term :term="lang.term(170)"></Term>
          </div>
          <div class="Basket--bubble">
          </div>
          <div class="Basket--count">
            <div class="Basket--countNumber">{{ countBasket }}</div>
            <Term :term="lang.term(171)"></Term>
          </div>
        </div>
        <BasketList></BasketList>
        <div class="Basket--footer">
          <ButtonAction :term="lang.term(167)" :onClick="() => onClickButton()" :loading="state.loading"></ButtonAction>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {stateService, STATE_ORDER} from "../../service/stateService";
import {Order} from "../../../@common/delta/database/Order";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {getBasketCount} from "../../function/getBasketCount";
import {PATHS} from "../../../@common/constant/PATHS";
import {changeRoute} from "../../function/changeRoute";
import {eventService} from "../../service/eventService";
import {EVENT} from "../../../@common/constant/EVENT";

export default {
  props: {},
  data() {
    return {
      PATHS,
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        delete_forever: require('@bbx/vector~master/core/assets/svg/material/delete_forever.svg')
      },
      state: {
        order: new Order(),
        loading: false
      },
    }
  },
  watch: {},
  computed: {
    countBasket() {
      return getBasketCount(this.state.order.baskets)
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`Basket-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`Basket-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`Basket-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`Basket-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`Basket-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
    this.state.order = stateService.get(STATE_ORDER)
    stateService.watch(STATE_ORDER, (order) => {
      this.state.order = order
    })
  },
  beforeDestroy() {
  },
  methods: {
    onClickButton() {
      if (!this.state.order.baskets.length) return;
      this.state.loading = true
      setTimeout(() => {
        this.state.loading = false
      }, 5000)
      eventService.triggerByName(new ListenerTrigger({
        name: EVENT.BASKET_WAS_CONFIRMERD,
      }))
      changeRoute([PATHS.PURCHASE])
    }
  }
}
</script>
