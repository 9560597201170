<style scoped>
.InputSwitch {

}

.InputSwitch ::v-deep .InputError {
  padding-top: 8px;
}

.InputSwitch--body {
  display: flex;
  align-items: center;
}

.InputSwitch--label {
  display: flex;
}

.InputSwitch--label ::v-deep .Tag {
  padding-left: 8px;
}

.InputSwitch--label ::v-deep .Term {
  font-family: 'ComfortaaRegular', sans-serif;
  font-size: 12px;
  padding-left: 16px;
  cursor: pointer;
  line-height: 18px;
}
</style>

<template>
  <div class="InputSwitch">
    <div class="InputSwitch--body">
      <el-switch v-model="state.value" :disabled="disabled"/>
      <div class="InputSwitch--label" @click="state.value = !state.value">
        <Term :term="label"/>
        <Tag v-if="label.id && tag" type="success">{{ tag }}</Tag>
      </div>
    </div>
    <InputError v-if="error.id" :error="error"/>
  </div>
</template>

<script>

import {Term as TermDelta} from '../../../@common/delta/database/Term.ts'

export default {

  props: {
    svg: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    error: {
      default: () => new TermDelta()
    },
    label: {
      default: () => new TermDelta()
    },
    value: {
      type: Boolean,
      default: () => false
    },
    tag: {
      type: String,
      default: () => ''
    },
    onChangeValue: {
      type: Function,
      default: () => () => {
      }
    },
  },
  data() {
    return {
      props: {},
      state: {
        value: this.value
      },
    }
  },
  watch: {
    'value': function (v) {
      this.state.value = v;
    },
    'state.value': function (v) {
      this.onChangeValue(v)
    }
  },
  computed: {},
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
