<style scoped>

</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="content-wrapper none for-title">
      <div class="container_12">
        <div class="main-container main-main-container col1-layout">
          <div class="grid_12 col-main">
            <div class="page-title">
              <h1>Conseils et astuces pour peindre ses meubles d’extérieur</h1>
            </div>
            <div class="std">
              <p style="text-align: justify;">
                <img alt="" src="./media/wysiwyg/bandeau-peindre.jpg">
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Vos chaises en plastique ont jauni, votre</span>
                table basse
                <span style="font-size: large;"> en bois a pris une teinte grisâtre, la peinture de votre salon de
                  jardin s’écaille et est rongée par la rouille, votre
                </span>
                  canapé
                <span style="font-size: large;"> en résine tressée a perdu de son éclat, etc. Donner une seconde
                  jeunesse à votre mobilier d’extérieur grâce à de la
                </span>
                <b style="font-size: large;">peinture</b>
                <span style="font-size: large;"> n’a jamais été aussi simple, même si vous n’êtes pas un as du
                  bricolage.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Il est important de choisir un produit adapté à vos meubles. On trouve
                  une large gamme de peintures, de toutes les couleurs, spécialement conçues pour faire face aux
                  intempéries auxquelles sont soumis nos
                  <b>salons de jardin</b>
                  . Elles vous permettent également de changer le style et la décoration de votre terrasse en apportant
                  une touche de
                  <b>couleur</b>
                  . Effet satiné ou mat, couleur pastel ou vive, teinte claire ou foncée, de nombreux choix s’offrent à
                  vous pour personnaliser votre mobilier.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Vous aurez besoin de quelques outils de base pour
                  <b>peindre vos meubles</b>&nbsp;: pinceau, rouleau adapté à la peinture choisie, bâche de protection
                  pour votre sol, bac à peinture et ruban de marquage (pour les parties de votre meuble que vous
                  souhaitez préserver). Pour un résultat optimal, n’oubliez pas de bien mélanger votre peinture avant de
                  l’utiliser et respectez le temps de séchage indiqué sur le pot avant de passer la seconde couche.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Meubles en bois</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Si vous souhaitez
                  <b>repeindre</b>
                  sur du boisbrut, une lasure ou de la peinture en bon état, poncez légèrement votre meuble avec du
                  papier de verre. Choisissez un grain fin, qui débouchera et lèvera les fibres de bois, pour que la
                  couleur accroche mieux. Un décapage sera nécessaire pour une peinture qui s’est écaillée.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Dépoussiérez soigneusement la surface de vos
                  <b>meubles de jardin</b>
                  avant de commencer à appliquer une peinture pour bois extérieur. Aidez-vous d’un pinceau pour les
                  recoins et utilisez un
                  <b>rouleau laqueur</b>
                  pour les surfaces planes. Faites des passes régulières dans le sens des veines du bois pour un
                  résultat parfait.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;<img alt="" src="./media/wysiwyg/tableenbois.jpg">
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Mobilier en plastique</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Nettoyez vos
                  <b>meubles en résine</b>
                  ou en PVC à l’aide d’un chiffon et d’
                  <b>eau savonneuse</b>
                  , et dégraissez-les avec du vinaigre blanc. Pour permettre une meilleure adhérence à la peinture,
                  poncez-les légèrement avec de la laine d’acier. Privilégiez une
                  <b>peinture 100&nbsp;% acrylique</b>
                  et adaptée à l’extérieur, une sous-couche est recommandée selon la gamme.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Utilisez un
                  <b>rouleau à poil court</b>
                  pour les surfaces planes et appliquez la peinture en passes croisées. Pour les reliefs, utilisez un
                  pinceau et finissez par un geste de lissage à l’aide du rouleau, sans le recharger. Plusieurs couches
                  peuvent être nécessaires, en particulier pour les teintes soutenues, afin d’obtenir un résultat
                  optimal. Vous pouvez appliquer un
                  <b>vernis acrylique mat</b>
                  , une fois la peinture séchée, pour protéger votre mobilier et le garder dans un bon état le plus
                  longtemps possible.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Salon de jardin en fer</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Rénover des meubles en fer est plus fastidieux. La première étape
                  consiste à les décaper, s’ils sont peints, à l’aide d’une brosse métallique, d’un grattoir ou d’une
                  brosse à décaper fixée sur une perceuse électrique. Vous pouvez également utiliser un décapant
                  chimique, spécial métaux, qui se rince à l’eau une fois qu’il a fini d’agir. N’oubliez pas de détruire
                  la rouille sur votre
                  <b>mobilier de jardin</b>
                  présente à l’aide d’un dérouillant. Il s’étale avec un pinceau et se retire avec de l’eau.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Une sous-couche pour métaux ferreux, qui protègera vos meubles de
                  l’humidité, peut être nécessaire selon la
                  <b>peinture</b>
                  que vous choisirez. Sélectionnez-en un spécial fer, dont les propriétés antirouille vous assureront de
                  conserver votre mobilier en bon état. Vous trouverez ces
                  <b>peintures en pot ou en bombe</b>
                  . Les meubles en fer forgé ayant des courbes travaillées, une peinture en aérosol peut
                  considérablement vous faciliter le travail.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">
                  <img alt="" src="./media/wysiwyg/tablealu.jpg">
                </span>
              </p>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../../function/getH1";
import {Term} from "../../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../../@common/constant/PROJECT_URL";
import {PATHS} from "../../../../@common/constant/PATHS";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    },
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FAQ-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
