<style scoped>
.OrderStatus {
}

.OrderStatus--tagBlue {
  background-color: #ecf5ff;
  display: inline-block;
  height: 20px;
  padding: 0 5px;
  line-height: 19px;
  font-size: 12px;
  color: #409eff;
  border: 1px solid #d9ecff;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
}
</style>

<template>
  <div class="OrderStatus" :class="class_root">
    <template v-for="orderStateList in order.orderStateLists">
      <Tag v-if="ORDER_STATE_LIST.NEED_VALIDATION === orderStateList.id" type="warning">En validation</Tag>
      <span class="OrderStatus--tagBlue" v-else-if="ORDER_STATE_LIST.IN_PREPARATION === orderStateList.id">En
        préparation
      </span>
      <Tag v-else-if="ORDER_STATE_LIST.IN_DELIVERING === orderStateList.id" type="success">Expédié</Tag>
      <Tag v-else-if="ORDER_STATE_LIST.CANCELED === orderStateList.id" type="error">Annulé</Tag>
    </template>
    <Tag v-if="!order.orderStateLists.length" type="info">non complète</Tag>
  </div>
</template>

<script>

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {Order} from "../../../@common/delta/database/Order";
import {ORDER_STATE_LIST} from "../../../@common/constant/ORDER_STATE_LIST";

export default {

  props: {
    order: {
      default: () => new Order()
    },
  },
  data() {
    return {
      ORDER_STATE_LIST,
      lang: getLangServiceBrowser(),
      props: {},
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
