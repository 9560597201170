"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertToPages = void 0;
var Page_1 = require("../../@common/delta/database/Page");
var Term_1 = require("../../@common/delta/database/Term");
var Translation_1 = require("../../@common/delta/database/Translation");
var Lang_1 = require("../../@common/delta/database/Lang");
var Path_1 = require("../../@common/delta/database/Path");
var Category_1 = require("../../@common/delta/database/Category");
var Article_1 = require("../../@common/delta/database/Article");
var Collection_1 = require("../../@common/delta/database/Collection");
var Url_1 = require("../../@common/delta/database/Url");
var ProjectUrl_1 = require("../../@common/delta/database/ProjectUrl");
var Sort_1 = require("../../@common/delta/database/Sort");
function convertToPages(maps, lang) {
    var pages = [];
    for (var _i = 0, maps_1 = maps; _i < maps_1.length; _i++) {
        var map = maps_1[_i];
        pages.push(new Page_1.Page({
            id: map[0],
            indexed: !!map[1],
            termTitles: [
                new Term_1.Term({
                    id: map[2],
                    translations: [
                        new Translation_1.Translation({
                            id: map[3],
                            value: map[4],
                            langs: [
                                new Lang_1.Lang(lang)
                            ]
                        })
                    ]
                })
            ],
            termDescriptions: [
                new Term_1.Term({
                    id: map[5],
                    translations: [
                        new Translation_1.Translation({
                            id: map[6],
                            value: map[7],
                            langs: [
                                new Lang_1.Lang(lang)
                            ]
                        })
                    ]
                })
            ],
            termH1s: [
                new Term_1.Term({
                    id: map[8],
                    translations: [
                        new Translation_1.Translation({
                            id: map[9],
                            value: map[10],
                            langs: [
                                new Lang_1.Lang(lang)
                            ]
                        })
                    ]
                })
            ],
            paths: map[11].map(function (path) {
                return new Path_1.Path({
                    id: path[0],
                    terms: path[1].map(function (term) {
                        return new Term_1.Term({
                            id: term[0],
                            translations: [
                                new Translation_1.Translation({
                                    id: term[1],
                                    value: term[2],
                                    langs: [
                                        new Lang_1.Lang(lang)
                                    ]
                                })
                            ]
                        });
                    })
                });
            }),
            categories: map[12].map(function (id) { return new Category_1.Category({ id: id }); }),
            articles: map[13].map(function (id) { return new Article_1.Article({ id: id }); }),
            collections: map[14].map(function (id) { return new Collection_1.Collection({ id: id }); }),
            urls: map[15].map(function (url) {
                return new Url_1.Url({
                    id: url[0],
                    projectUrls: !url[1] ? [] : [new ProjectUrl_1.ProjectUrl({ id: url[1] })],
                    sorts: url[2].map(function (sort, i) {
                        return new Sort_1.Sort({
                            id: sort[0],
                            order: i + 1,
                            paths: [
                                new Path_1.Path({
                                    id: sort[1],
                                    terms: sort[2].map(function (term) { return new Term_1.Term({
                                        id: term[0],
                                        translations: [
                                            new Translation_1.Translation({
                                                id: term[1],
                                                value: term[2],
                                                langs: [
                                                    new Lang_1.Lang(lang)
                                                ]
                                            })
                                        ]
                                    }); })
                                })
                            ]
                        });
                    })
                });
            }),
        }));
    }
    return {
        pages: pages,
    };
}
exports.convertToPages = convertToPages;
