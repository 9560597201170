<style scoped lang="scss">

$right-space: 27px;

.ButtonCircle {
  position: relative;
  cursor: pointer;
  background-color: white;
}

.ButtonCircle--term {
  font-family: 'MontserratSemiBold';
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  text-align: right;
  position: absolute;
  top: 50%;
  right: 30px;
  height: 40px;
  margin-top: -20px;
  line-height: 20px;
  opacity: 1;
  transition: opacity 500ms;
}

.ButtonCircle-hide .ButtonCircle--term {
  opacity: 0.5;
}

.ButtonCircle--vector {
  height: 24px;
  width: 54px;
  position: absolute;
  left: -$right-space;
  top: 50%;
  margin-top: -12px;
  background-color: white;
  opacity: 1;
  transform: translateX(0px);
  transition: opacity 500ms, transform 500ms;
}

.ButtonCircle-hide .ButtonCircle--vector {
  opacity: 0;
  transform: translateX(-25px);
}

.ButtonCircle--cicrle {
  transform: rotate(-35deg);
}

.ButtonCircle--cicrle ::v-deep .CircleProgress--progressBarre {
  transition: stroke-dashoffset 500ms linear;
}
</style>

<template>
  <div class="ButtonCircle" :class="class_root" :style="style_root" @mouseenter="onMouseEnter"
       @mouseleave="onMouseLeave">
    <div class="ButtonCircle--term" :style="style_term">
      <Term :term="term"></Term>
    </div>
    <div class="ButtonCircle--vector" :style="style_vector">
      <Vector :svg="icon.arrowBig"></Vector>
    </div>
    <div class="ButtonCircle--cicrle">
      <CircleProgress :size="size" :percentage="this.state.hide ? 0 : 80" :barreSize="1"></CircleProgress>
    </div>
  </div>
</template>

<script>

// -----

import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {eventService} from "../../service/eventService";
import {Term} from "../../../@common/delta/database/Term";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

// -----

export default {
  props: {
    term: {
      type: Term,
      default: () => new Term()
    },
    size: {
      type: Number,
      default: () => 180
    }
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      icon: {
        arrowBig: require('../../../@common/assets/home/arrow-big.svg'),
      },
      state: {
        hovered: false,
        hide: false,
        timeout: null,
      },
      events: []
    }
  },
  watch: {
    'state.hovered': {
      handler(hovered) {
        if (hovered) {
          this.state.hide = true
          this.state.timeout = setTimeout(() => {
            this.state.hide = false
          }, 500)
        } else {
          this.state.hide = false
        }
      }
    }
  },
  computed: {
    class_root() {
      const classes = []
      if (this.state.hide) classes.push('ButtonCircle-hide')
      return classes
    },
    style_root() {
      const style = {}
      return style
    },
    style_vector() {
      const style = {}
      return style
    },
    style_term() {
      const style = {}
      style.width = `${this.size - 54 - 20}px`
      return style
    }
  },
  beforeMount() {
    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    onMouseEnter() {
      this.state.hovered = true
    },
    onMouseLeave() {
      this.state.hovered = false
    },
  }
}
</script>