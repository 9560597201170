"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Similar = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Material_1 = require("./Material");
var Product_1 = require("./Product");
var Similar = /** @class */ (function () {
    function Similar(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.materials = deltaService_1.deltaService.array(props.materials, Material_1.Material);
        this.productDowns = deltaService_1.deltaService.array(props.productDowns, Product_1.Product);
        this.productUps = deltaService_1.deltaService.array(props.productUps, Product_1.Product);
    }
    return Similar;
}());
exports.Similar = Similar;
