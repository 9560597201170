"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLangServiceBrowser = exports.setLangServiceBrowser = void 0;
var langServiceBrowser;
function setLangServiceBrowser(v) {
    langServiceBrowser = v;
}
exports.setLangServiceBrowser = setLangServiceBrowser;
function getLangServiceBrowser() {
    return langServiceBrowser;
}
exports.getLangServiceBrowser = getLangServiceBrowser;
