import { render, staticRenderFns } from "./AccountOrder.vue?vue&type=template&id=29350964&scoped=true&"
import script from "./AccountOrder.vue?vue&type=script&lang=js&"
export * from "./AccountOrder.vue?vue&type=script&lang=js&"
import style0 from "./AccountOrder.vue?vue&type=style&index=0&id=29350964&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "29350964",
  null
  
)

export default component.exports