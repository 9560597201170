"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getH1Term = exports.getH1 = void 0;
var langServiceBrowser_1 = require("../../@common/service/langServiceBrowser");
function getH1(nuxtProp) {
    var langService = langServiceBrowser_1.getLangServiceBrowser();
    var h1 = "";
    for (var _i = 0, _a = nuxtProp.page.termH1s; _i < _a.length; _i++) {
        var term = _a[_i];
        var term_ = langService.translateTerm(term);
        if (term_.trim())
            return term_.trim();
    }
    var paths = [];
    for (var _b = 0, _c = nuxtProp.page.paths; _b < _c.length; _b++) {
        var path = _c[_b];
        for (var _d = 0, _e = path.terms; _d < _e.length; _d++) {
            var term = _e[_d];
            var term_ = langService.translateTerm(term);
            if (term_.trim())
                paths.push(term_.trim());
        }
    }
    if (!paths.length)
        return h1;
    paths[0] = paths[0].charAt(0).toUpperCase() + paths[0].slice(1);
    h1 = "" + paths.join(' ');
    return h1;
}
exports.getH1 = getH1;
function getH1Term(nuxtProp) {
    var langService = langServiceBrowser_1.getLangServiceBrowser();
    for (var _i = 0, _a = nuxtProp.page.termH1s; _i < _a.length; _i++) {
        var term = _a[_i];
        var term_ = langService.translateTerm(term);
        if (term_.trim())
            return [term];
    }
    var terms = [];
    for (var _b = 0, _c = nuxtProp.page.paths; _b < _c.length; _b++) {
        var path = _c[_b];
        for (var _d = 0, _e = path.terms; _d < _e.length; _d++) {
            var term = _e[_d];
            var term_ = langService.translateTerm(term);
            if (term_.trim())
                terms.push(term);
        }
    }
    return terms;
}
exports.getH1Term = getH1Term;
