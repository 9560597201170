"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.User = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Auth_1 = require("./Auth");
var Role_1 = require("./Role");
var Gender_1 = require("./Gender");
var Order_1 = require("./Order");
var Address_1 = require("./Address");
var PromotionCode_1 = require("./PromotionCode");
var Shop_1 = require("./Shop");
var Sage_1 = require("./Sage");
var Article_1 = require("./Article");
var Device_1 = require("./Device");
var Comment_1 = require("./Comment");
var User = /** @class */ (function () {
    function User(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.firstName = deltaService_1.deltaService.string(props.firstName, '');
        this.lastName = deltaService_1.deltaService.string(props.lastName, '');
        this.email = deltaService_1.deltaService.string(props.email, '');
        this.password = deltaService_1.deltaService.string(props.password, '');
        this.birthday = deltaService_1.deltaService.string(props.birthday, '');
        this.phoneNumber = deltaService_1.deltaService.string(props.phoneNumber, '');
        this.source = deltaService_1.deltaService.number(props.source, 0);
        this.auths = deltaService_1.deltaService.array(props.auths, Auth_1.Auth);
        this.roles = deltaService_1.deltaService.array(props.roles, Role_1.Role);
        this.genders = deltaService_1.deltaService.array(props.genders, Gender_1.Gender);
        this.orders = deltaService_1.deltaService.array(props.orders, Order_1.Order);
        this.addresses = deltaService_1.deltaService.array(props.addresses, Address_1.Address);
        this.sages = deltaService_1.deltaService.array(props.sages, Sage_1.Sage);
        this.devices = deltaService_1.deltaService.array(props.devices, Device_1.Device);
        this.comments = deltaService_1.deltaService.array(props.comments, Comment_1.Comment);
        this.promotionCodes = deltaService_1.deltaService.array(props.promotionCodes, PromotionCode_1.PromotionCode);
        this.shops = deltaService_1.deltaService.array(props.shops, Shop_1.Shop);
        this.articleRestrictions = deltaService_1.deltaService.array(props.articleRestrictions, Article_1.Article);
    }
    return User;
}());
exports.User = User;
