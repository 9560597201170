<style scoped lang="scss">

@import "../../scss/color";

.FilterCollapse {
}

.FilterCollapse--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px;
  background: #f7f7f7;
}

.FilterCollapse--headerLeft {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.FilterCollapse--down ::v-deep .Vector {
  fill: rgba(0, 0, 0, 0.25);
}

.FilterCollapse--icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  /*background: rgba(0, 0, 0, 0.06);*/
  border-radius: 4px;
  margin-right: 10px;
  padding: 0 10px;
}

.FilterCollapse--down {
  width: 18px;
  height: 18px;
  min-width: 18px;
  min-height: 18px;
  margin-left: 8px;
}

.FilterCollapse--headerRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  cursor: pointer;
}

.FilterCollapse--title {
  font-family: 'DDinBold', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  color: rgba(0, 0, 0, 1);
}

.FilterCollapse--count {
  border-radius: 2px;
  font-size: 10px;
  font-family: 'consolas', sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  text-transform: uppercase;
  font-weight: bold;
  overflow: hidden;
  color: rgba(0, 0, 0, 0.5);
  transition: border 300ms, color 300ms;
  margin-left: 8px;
}

.FilterCollapse--count:hover {
  color: rgba(0, 0, 0, 1);
}

.FilterCollapse--countNumber {
  padding: 2px 8px 2px 8px;
  white-space: nowrap;
}

.FilterCollapse--toggle {
  width: 24px;
  cursor: pointer;
  position: relative;
  top: 2px;
}

.FilterCollapse--toggle ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 0.25);
  transition: fill 300ms;
}

.FilterCollapse--headerRight:hover .FilterCollapse--toggle ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 0.5);
}

.FilterCollapse--body {
  background: white;
  padding: 0 25px 25px 25px;
  /*padding: 0 0 25px 0;*/
  /*border-top: solid 1px rgba(0, 0, 0, 0.05);*/
  max-height: 400px;
  overflow: auto;
}
</style>

<template>
  <div class="FilterCollapse" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="FilterCollapse--header">
        <div class="FilterCollapse--headerLeft" @click="() => state.opened = !state.opened">
          <div class="FilterCollapse--icon" v-show="withIcon">
            <Vector :svg="svg"/>
          </div>
          <div class="FilterCollapse--title">
            <Term :term="title"/>
          </div>
          <div class="FilterCollapse--down">
            <Vector :svg="state.opened  ? icon.expand_less : icon.expand_more"/>
          </div>
        </div>
        <Tooltip v-if="count" text="réinitialiser">
          <div class="FilterCollapse--headerRight" v-show="withToggle" @click="() => onCancel()">
            <template>
              <div class="FilterCollapse--count" v-if="count">
                <span class="FilterCollapse--countNumber">{{ count }} filtre{{ count > 1 ? 's' : '' }}</span>
              </div>
              <div class="FilterCollapse--toggle">
                <Vector :svg="icon.backspace"/>
              </div>
            </template>
          </div>
        </Tooltip>
        <div v-else class="FilterCollapse--headerRight" v-show="withToggle">
          <div class="FilterCollapse--toggle" @click="() => state.opened = !state.opened">
            <Vector :svg="state.opened ? icon.indeterminate_check_box : icon.add_box"/>
          </div>
        </div>
      </div>
      <div class="FilterCollapse--body" v-show="state.opened">
        <slot/>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {Term} from "../../../@common/delta/database/Term";
import {PICTURE_DISPLAY} from "../../../@common/constant/PICTURE_DISPLAY";

export default {

  props: {
    svg: {
      type: String,
      default: () => ''
    },
    count: {
      type: Number,
      default: () => 0
    },
    opened: {
      type: Boolean,
      default: () => false
    },
    onOpenedChange: {
      type: Function,
      default: () => () => {
      }
    },
    title: {
      default: () => new Term()
    },
    withToggle: {
      type: Boolean,
      default: () => true
    },
    withIcon: {
      type: Boolean,
      default: () => true
    },
    onCancel: {
      type: Function,
      default: () => () => {
      }
    },
  },
  data() {
    return {
      PICTURE_DISPLAY,
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        add_box: require('@bbx/vector~master/core/assets/svg/material/add_box.svg'),
        indeterminate_check_box: require('@bbx/vector~master/core/assets/svg/material/indeterminate_check_box.svg'),
        backspace: require('@bbx/vector~master/core/assets/svg/material/backspace.svg'),
        expand_more: require('@bbx/vector~master/core/assets/svg/material/expand_more.svg'),
        expand_less: require('@bbx/vector~master/core/assets/svg/material/expand_less.svg'),
      },
      state: {
        opened: this.opened,
        selected: this.selected,
      },
    }
  },
  watch: {
    'opened': function (v) {
      this.state.opened = v
    },
    'state.opened': function (v) {
      this.onOpenedChange(v)
    },
  },
  computed: {
    class_root() {
      const classes = []
      return classes;
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
