"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.TransportCost = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Country_1 = require("./Country");
var Transport_1 = require("./Transport");
var Delivery_1 = require("./Delivery");
var TransportCost = /** @class */ (function () {
    function TransportCost(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.amount = deltaService_1.deltaService.number(props.amount, 0);
        this.countries = deltaService_1.deltaService.array(props.countries, Country_1.Country);
        this.transports = deltaService_1.deltaService.array(props.transports, Transport_1.Transport);
        this.deliveries = deltaService_1.deltaService.array(props.deliveries, Delivery_1.Delivery);
    }
    return TransportCost;
}());
exports.TransportCost = TransportCost;
