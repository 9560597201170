"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Forum = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Device_1 = require("./Device");
var Comment_1 = require("./Comment");
var Forum = /** @class */ (function () {
    function Forum(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.comments = deltaService_1.deltaService.array(props.comments, Comment_1.Comment);
        this.devices = deltaService_1.deltaService.array(props.devices, Device_1.Device);
    }
    return Forum;
}());
exports.Forum = Forum;
