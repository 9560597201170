<style scoped>
.Loader {
  font-size: 16px;
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  color: rgba(0, 0, 0, 1);
  -webkit-box-orient: vertical;
  -webkit-box-direction: normal;
  flex-direction: column;
  -webkit-box-align: center;
  align-items: center;
  -webkit-box-pack: center;
  justify-content: center;
  display: inline-flex;
}

.Loader--animation {
  font-size: 16px;
  font-family: Roboto, Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -webkit-box-direction: normal;
  animation: rotating .7s linear infinite;
  border-radius: 50%;
  border: 2px solid transparent;
}


</style>

<template>
  <div class="Loader" :style="style_root">
    <div class="Loader--animation" :style="style_animation"></div>
  </div>
</template>

<script>

export default {

  props: {
    rgb: {
      type: Array,
      default: () => ([
        0,
        0,
        0
      ])
    },
    size: {
      type: Number,
      default: () => 24
    }
  },
  data() {
    return {
      state: {},
    }
  },
  watch: {},
  computed: {
    style_root() {
      const style = {}
      style.color = `rgba(${this.rgb.join(',')}, 1)`
      return style;
    },
    style_animation() {
      const style = {}
      style.color = `rgba(${this.rgb.join(',')}, 1)`
      style.borderColor = `rgba(${this.rgb.join(',')}, 0.25)`
      style.borderRightColor = `rgba(${this.rgb.join(',')}, 1)`
      style.width = `${this.size}px`
      style.height = `${this.size}px`
      return style;
    },
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
