"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.displayMessage = void 0;
function displayMessage(props) {
    props.vue.$message({
        type: props.type,
        message: props.message,
        showClose: true
    });
}
exports.displayMessage = displayMessage;
