"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.EVENT = void 0;
exports.EVENT = {
    PAGE_START: 'PAGE_START',
    PAGE_FINISH: 'PAGE_FINISH',
    PAGE_COMPLETE: 'PAGE_COMPLETE',
    CHANGE_LANG: 'CHANGE_LANG',
    CHANGE_ROUTE: 'CHANGE_ROUTE',
    ROUTE_WILL_CHANGE: 'ROUTE_WILL_CHANGE',
    ROUTE_QUERY_CHANGED: 'ROUTE_QUERY_CHANGED',
    ROUTE_HASH_CHANGED: 'ROUTE_HASH_CHANGED',
    ROUTE_HAVE_CHANGED: 'ROUTE_HAVE_CHANGED',
    TO_PREVIOUS_URL: 'TO_PREVIOUS_URL',
    TERM_ACTIVATED: 'TERM_ACTIVATED',
    TERM_MODAL_OPENED: 'TERM_MODAL_OPENED',
    TERM_UPDATED: 'TERM_UPDATED',
    CHANGE_COOKIE: 'CHANGE_COOKIE',
    MODAL_OPENED: 'MODAL_OPENED',
    SEND_DEBUG: 'SEND_DEBUG',
    BASKET_WAS_OPENED: 'BASKET_WAS_OPENED',
    BASKET_WAS_CONFIRMERD: 'BASKET_WAS_CONFIRMERD',
    PAYMENT_METHOD_CHOSEN: 'PAYMENT_METHOD_CHOSEN',
    ORDER_WAS_COMPLETED: 'ORDER_WAS_COMPLETED',
    ADD_BASKET: 'ADD_BASKET',
    ARTICLE_WAS_ADDED_TO_BASKET: 'ARTICLE_WAS_ADDED_TO_BASKET',
    ARTICLE_WAS_INCREMENTED_IN_BASKET: 'ARTICLE_WAS_INCREMENTED_IN_BASKET',
    ARTICLE_WILL_BE_REMOVED_FROM_BASKET: 'ARTICLE_WILL_BE_REMOVED_FROM_BASKET',
    ARTICLE_WAS_DECREMENTED_IN_BASKET: 'ARTICLE_WAS_DECREMENTED_IN_BASKET',
    SCROLL_CHANGE: 'SCROLL_CHANGE',
    SIZE_CHANGED: 'SIZE_CHANGED',
    CHANGE_OPENED_SELLER: 'CHANGE_OPENED_SELLER',
    ARTICLE_TOGGELED: 'ARTICLE_TOGGELED',
    HIDE_MENU: 'HIDE_MENU',
    MENU_THEME_CHANGED: 'MENU_THEME_CHANGED',
    DISPLAY_SCROLLBAR: 'DISPLAY_SCROLLBAR',
    SEARCH_OPENED_CHANGE: 'SEARCH_OPENED_CHANGE',
    CAPTCHA_INIT: 'CAPTCHA_INIT',
    COOKIE_OPEN: 'COOKIE_OPEN',
    CLICK_ARTICLE: 'CLICK_ARTICLE',
    CHANGE_COUNTRY_LOCATION: 'CHANGE_COUNTRY_LOCATION',
    CHAT_OPEN_FORUM: 'CHAT_OPEN_FORUM',
    CHAT_SET_FORUM: 'CHAT_SET_FORUM',
    IS_CONNECTED: 'IS_CONNECTED',
    ERROR: 'ERROR',
    HOME_COLOR_CHANGED: 'HOME_COLOR_CHANGED',
    CIRCLE_LINK_ACTIVATED: 'CIRCLE_LINK_ACTIVATED',
    CIRCLE_LINK_RESET_POSITION: 'CIRCLE_LINK_RESET_POSITION',
    DISABLE_CAROUSEL_LINK: 'DISABLE_CAROUSEL_LINK',
    UNABLE_CAROUSEL_LINK: 'UNABLE_CAROUSEL_LINK',
};
