"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.catalogArticleFilterExtract = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var FILTER_OPTION_1 = require("../constant/FILTER_OPTION");
function catalogArticleFilterExtract(props) {
    var nuxtProp = props.nuxtProp, request = props.request;
    for (var _i = 0, _a = nuxtProp.url.queries; _i < _a.length; _i++) {
        var query = _a[_i];
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.PAGE) {
            request.$page = deltaService_1.deltaService.number(parseInt(("" + query.value).replace(/^[^0-9]+/g, '')), 0); // replace everything dont start with number
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.SEARCH) {
            request.$search = deltaService_1.deltaService.string("" + query.value, ''); // force string
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.SORT) {
            request.sortId = deltaService_1.deltaService.number(query.value, 0);
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.IDS) {
            request.$ids = query.value.split ? query.value.split(',').map(function (a) { return deltaService_1.deltaService.number(parseInt(a), 0); }) : [deltaService_1.deltaService.number(parseInt(query.value), 0)];
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.IS_OUTLET) {
            request.isOutlet = deltaService_1.deltaService.boolean(query.value === 1, false);
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.IS_SET) {
            request.$isSet = deltaService_1.deltaService.boolean(query.value === 1, false);
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.IS_BATCH) {
            request.isBatch = deltaService_1.deltaService.boolean(query.value === 1, false);
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.IS_BATCH_BIS) {
            request.$isBatchBis = deltaService_1.deltaService.boolean(query.value === 1, false);
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.COLLECTION_IDS) {
            request.collectionIds = query.value.split ? query.value.split(',').map(function (a) { return deltaService_1.deltaService.number(parseInt(a), 0); }) : [deltaService_1.deltaService.number(parseInt(query.value), 0)];
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.SHAPE_IDS) {
            request.$shapeIds = query.value.split ? query.value.split(',').map(function (a) { return deltaService_1.deltaService.number(parseInt(a), 0); }) : [deltaService_1.deltaService.number(parseInt(query.value), 0)];
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.COLLECTION_TYPE_IDS) {
            request.$collectionTypeIds = query.value.split ? query.value.split(',').map(function (a) { return deltaService_1.deltaService.number(parseInt(a), 0); }) : [deltaService_1.deltaService.number(parseInt(query.value), 0)];
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.CATEGORY_IDS) {
            request.categoryIds = query.value.split ? query.value.split(',').map(function (a) { return deltaService_1.deltaService.number(parseInt(a), 0); }) : [deltaService_1.deltaService.number(parseInt(query.value), 0)];
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.CATEGORY_CHILDREN_IDS) {
            request.$categoryChildrenIds = query.value.split ? query.value.split(',').map(function (a) { return deltaService_1.deltaService.number(parseInt(a), 0); }) : [deltaService_1.deltaService.number(parseInt(query.value), 0)];
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.MATERIAL_IDS) {
            request.$materialIds = query.value.split ? query.value.split(',').map(function (a) { return deltaService_1.deltaService.number(parseInt(a), 0); }) : [deltaService_1.deltaService.number(parseInt(query.value), 0)];
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.MIN_PRICE) {
            request.$minPrice = deltaService_1.deltaService.number(query.value, 0);
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.MAX_PRICE) {
            request.$maxPrice = deltaService_1.deltaService.number(query.value, 0);
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.TAXED_PRICE) {
            request.$taxedPrice = deltaService_1.deltaService.boolean(query.value === 1, false);
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.MIN_SITTING_PLACE) {
            request.$minSittingPlace = deltaService_1.deltaService.number(query.value, 0);
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.MAX_SITTING_PLACE) {
            request.$maxSittingPlace = deltaService_1.deltaService.number(query.value, 0);
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.LUMEN_SEARCH) {
            request.$lumenSearch = deltaService_1.deltaService.number(query.value, 0);
        }
        // -----
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.MIN_SIZE_LENGTH) {
            request.$minSizeLength = deltaService_1.deltaService.number(query.value, 0);
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.MIN_SIZE_WIDTH) {
            request.$minSizeWidth = deltaService_1.deltaService.number(query.value, 0);
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.MIN_SIZE_HEIGHT) {
            request.$minSizeHeight = deltaService_1.deltaService.number(query.value, 0);
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.MAX_SIZE_LENGTH) {
            request.$maxSizeLength = deltaService_1.deltaService.number(query.value, 0);
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.MAX_SIZE_WIDTH) {
            request.$maxSizeWidth = deltaService_1.deltaService.number(query.value, 0);
        }
        if (query.name === FILTER_OPTION_1.FILTER_OPTION.MAX_SIZE_HEIGHT) {
            request.$maxSizeHeight = deltaService_1.deltaService.number(query.value, 0);
        }
    }
    return request;
}
exports.catalogArticleFilterExtract = catalogArticleFilterExtract;
