<style scoped>
.OrderAdmin {
  padding-top: 25px;
}

.OrderAdmin--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-bottom: 26px;
}

.OrderAdmin--name {
  text-transform: uppercase;
}

.OrderAdmin--headerLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.OrderAdmin--headerRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.OrderAdmin--headerRightSearch {
  display: flex;
}

.OrderAdmin--headerRightSearch ::v-deep .InputText input {
  border-radius: 4px 0 0 4px;
  position: relative;
  left: 1px;
}

.OrderAdmin--headerRightSearch ::v-deep .InputSelect {
  width: 170px;
}

.OrderAdmin--headerRightSearch ::v-deep .InputSelect input {
  border-radius: 0 4px 4px 0;
}

.OrderAdmin--headerRightStatus {
  width: 150px;
}

.OrderAdmin--headerRightStatus ::v-deep .InputSelect {
  width: 150px;
}


.OrderAdmin--stat {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  font-family: 'ComfortaaRegular', sans-serif;
  margin: 14px 0 50px 0;
}

.OrderAdmin--card {
  background: rgba(0, 0, 0, 0.03);
  flex: 1;
  height: 200px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.OrderAdmin--card:nth-child(1) {
  margin: 0 25px 0 0;
}

.OrderAdmin--card:nth-child(2) {
  margin: 0 25px 0 25px;
}

.OrderAdmin--card:nth-child(3) {
  margin: 0 0 0 25px;
}

.OrderAdmin--cardHeader {
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  padding: 15px;
}

.OrderAdmin--cardBody {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.OrderAdmin--cardFooter {
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
}

.OrderAdmin--cardFooter span {
  padding-right: 15px;
}


.OrderAdmin--loader {
  width: 100%;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.OrderAdmin--pagination {
  padding: 5px;
}

.OrderAdmin--modal {
  padding: 0px 50px 50px 50px;
}

.OrderAdmin--footer {
  display: flex;
  justify-content: flex-end;
}

.OrderAdmin--spacer {
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
}

.OrderAdmin--text {
  font-size: 12px;
}

.OrderAdmin--textEmail {
  display: flex;
  align-items: center;
}

.OrderAdmin--textEmailIconBox {
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 3px 4px 4px 4px;
  margin-right: 10px;
  border-radius: 3px;
}

.OrderAdmin--textEmailIcon {
  width: 18px;
  height: 18px;
  opacity: 0.25;
}

.OrderAdmin--textEmail ::v-deep .el-tag {
  font-size: 10px !important;
  margin-right: 8px;
  display: block;
}

.OrderAdmin--link {
  display: block;
  text-decoration: none;
  width: 100%;
}

.OrderAdmin--option {
  width: 24px;
  cursor: pointer;
}

.OrderAdmin--referenceTooltip ::v-deep .Tooltip {
  display: inline-block;
}

.OrderAdmin--references {
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: nowrap;
}

.OrderAdmin--reference {
  display: inline-block;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 0 4px;
  line-height: 22px;
  height: 22px;
  margin: 4px;
  overflow: hidden;
  /*white-space: nowrap;*/
}

.OrderAdmin--referenceComponent {
  border: solid 1px rgba(0, 0, 0, 0.1);
  color: rgba(138, 108, 89, 1);
  background: rgba(138, 108, 89, 0.05);
}

.OrderAdmin--payment {
  display: flex;
  align-items: center;
}

.OrderAdmin--tagBlue {
  background-color: #ecf5ff;
  display: inline-block;
  height: 20px;
  padding: 0 5px;
  line-height: 19px;
  font-size: 12px;
  color: #409eff;
  border: 1px solid #d9ecff;
  border-radius: 4px;
  box-sizing: border-box;
  white-space: nowrap;
}

.OrderAdmin--payementMethod {

}

.OrderAdmin--payementMethod img {
  height: 16px;
  width: 24px;
  margin-left: 4px;
  display: block;
}
</style>

<template>
  <div class="OrderAdmin">
    <div class="OrderAdmin--header">

      <div class="OrderAdmin--headerLeft">
        <!--        <Popover position="bottom-left">-->
        <!--          <template slot="reference">-->
        <!--            <ButtonAction :term="lang.term(157)" :svg="icon.add"></ButtonAction>-->
        <!--          </template>-->
        <!--          <template slot="default">-->
        <!--            <List :svg="icon.add" :text="lang.translate(416)" :onClick="() => openInsert()"></List>-->
        <!--            &lt;!&ndash;            <List :svg="icon.add" :text="lang.translate(197)" :onClick="() => openInsert()"></List>&ndash;&gt;-->
        <!--          </template>-->
        <!--        </Popover>-->
        <el-date-picker
            v-model="state.value2"
            type="daterange"
            align="left"
            unlink-panels
            range-separator="à"
            value-format="yyyy.MM.dd.HH.mm.ss.000"
            format="dd/MM/yyyy"
            start-placeholder="Période début"
            end-placeholder="Période fin"
            :picker-options="state.pickerOptions">
        </el-date-picker>

        <div class="OrderAdmin--spacer"></div>

        <InputSwitch :label="lang.term(569)"
                     :value="state.filterComplete"
                     :onChangeValue="v => state.filterComplete = v">
        </InputSwitch>
      </div>

      <div class="OrderAdmin--headerRight">
        <div style="width: 24px;">
          <Vector :svg="icon.search"></Vector>
        </div>
        <div class="OrderAdmin--spacer"></div>
        <div class="OrderAdmin--headerRightSearch">
          <InputText :value="state.filterSearch" :onChangeValue="v => state.filterSearch = v"></InputText>
          <InputSelect :items="state.searchTypes"
                       :values="state.selectedSearchTypes"
                       :max="1"
                       :onChangeValues="v => state.selectedSearchTypes = v">
          </InputSelect>
        </div>
        <div class="OrderAdmin--spacer"></div>
        <div class="OrderAdmin--headerRightStatus">
          <!--          <InputSelect :items="state.status"-->
          <!--                       :values="state.selectedStatus"-->
          <!--                       :max="1"-->
          <!--                       :placeholder="lang.translateTerm(lang.term(570))"-->
          <!--                       :onChangeValues="v => state.selectedStatus = v">-->
          <!--          </InputSelect>-->
          <el-select v-model="state.selectedOption" :clearable="true" :placeholder="lang.translateTerm(lang.term(571))">
            <el-option-group
                v-for="group in state.options"
                :key="group.label"
                :label="group.label">
              <el-option
                  v-for="item in group.options"
                  :key="item.value"
                  :label="item.label"
                  :value="item.value">
              </el-option>
            </el-option-group>
          </el-select>
        </div>
      </div>
    </div>

    <div class="OrderAdmin--stat">
      <div class="OrderAdmin--card OrderAdmin--cardCount">
        <div class="OrderAdmin--cardHeader">Chiffre d'affaire</div>
        <div class="OrderAdmin--cardBody">{{ (state.orderSum).toLocaleString('fr-FR') }} €</div>
        <div class="OrderAdmin--cardFooter">
          Sur la période
        </div>
      </div>
      <div class="OrderAdmin--card OrderAdmin--cardTraduction">
        <div class="OrderAdmin--cardHeader">panier moyen</div>
        <div class="OrderAdmin--cardBody">
          {{
            state.orderCount ? (parseFloat((state.orderSum / state.orderCount).toFixed(2))).toLocaleString('fr-FR') : 0
          }} €
        </div>
        <div class="OrderAdmin--cardFooter">Sur la période</div>
      </div>
      <div class="OrderAdmin--card OrderAdmin--cardProgress">
        <div class="OrderAdmin--cardHeader">commande</div>
        <div class="OrderAdmin--cardBody">{{ (state.orderCount).toLocaleString('fr-FR') }}</div>
        <div class="OrderAdmin--cardFooter">Sur la période</div>
      </div>
    </div>

    <div class="OrderAdmin--body" v-show="!state.loadingOrders">
      <el-table :data="state.orders"
                style="width: 100%"
                @sort-change="onDateSortChange"
                :default-sort="{prop: 'createdAt', order: 'descending'}">
        <el-table-column width="50" :label="lang.translateTerm(lang.term(572))">
          <template slot-scope="scope">
            <span class="OrderAdmin--text">{{ state.orders[scope.$index].id }}</span>
          </template>
        </el-table-column>
        <el-table-column width="100" :label="lang.translateTerm(lang.term(573))">
          <template slot-scope="scope">
            <span class="OrderAdmin--text">{{ getSagePrefixOrder(state.orders[scope.$index].id) }}</span>
          </template>
        </el-table-column>
        <el-table-column width="270px" :label="lang.translateTerm(lang.term(574))">
          <template slot-scope="scope">
            <span class="OrderAdmin--text OrderAdmin--textEmail" v-for="user in state.orders[scope.$index].users" :key="user.id">
              <Tooltip text="B2C" position="top" v-if="user.roles.some(a => a.id === ROLE.CUSTOMER)">
                <div class="OrderAdmin--textEmailIconBox">
                  <div class="OrderAdmin--textEmailIcon">
                    <Vector :svg="icon.person"></Vector>
                  </div>
                </div>
              </Tooltip>
              <Tooltip text="B2B" position="top" v-if="user.roles.some(a => a.id === ROLE.SELLER)">
                <div class="OrderAdmin--textEmailIconBox">
                  <div class="OrderAdmin--textEmailIcon">
                    <Vector :svg="icon.store"></Vector>
                  </div>
                </div>
              </Tooltip>
              {{ user.email }}
            </span>
          </template>
        </el-table-column>
        <el-table-column width="110" :label="lang.translateTerm(lang.term(575))">
          <template slot-scope="scope">
            <b>{{ price(state.orders[scope.$index]) }} €</b>
          </template>
        </el-table-column>
        <el-table-column :label="lang.translateTerm(lang.term(576))" min-width="270px">
          <template slot-scope="scope">
            <div class="OrderAdmin--references">
              <template v-for="referenceQuantity in referenceQuantities(state.orders[scope.$index])">
                <Tooltip :text="referenceQuantity.reference" position="top">
                  <div class="OrderAdmin--text OrderAdmin--reference" v-if="referenceQuantity.type === 1">
                    {{ referenceQuantity.quantity }} : {{ referenceQuantity.reference }}
                  </div>
                </Tooltip>
              </template>
            </div>
          </template>

        </el-table-column>
        <el-table-column prop="createdAt" width="100" :label="lang.translateTerm(lang.term(577))" sortable="custom"
                         fixed="right">
          <template slot-scope="scope">
            {{ toFrenchDate(state.orders[scope.$index]) }}
          </template>
        </el-table-column>
        <el-table-column width="140" :label="lang.translateTerm(lang.term(578))"
                         fixed="right">
          <template slot-scope="scope">
            <OrderStatus :order="state.orders[scope.$index]"></OrderStatus>
          </template>
        </el-table-column>
        <el-table-column width="130" :label="lang.translateTerm(lang.term(579))"
                         fixed="right">
          <template slot-scope="scope">
            <div class="OrderAdmin--payment">
              <Tag v-if="PAYMENT_STATE_LIST.PAID === paymentStatus(state.orders[scope.$index])" type="success">
                Payé
              </Tag>
              <span class="OrderAdmin--tagBlue" v-else-if="PAYMENT_STATE_LIST.PENDING === paymentStatus(state.orders[scope.$index])" type="warning">
                En
                cours
              </span>
              <Tag v-else type="danger">
                Non
                payé
              </Tag>
              <div class="OrderAdmin--payementMethod" v-for="image in paymentMethods(state.orders[scope.$index])">
                <img :src="image">
              </div>
            </div>
          </template>
        </el-table-column>
        <el-table-column width="100" :label="lang.translate(203)" fixed="right">
          <template slot-scope="scope">
            <Popover position="bottom-end">
              <template slot="default">
                <List :svg="icon.visibility" :text="lang.translateTerm(lang.term(580))" :onClick="() => openUpdate(state.orders[scope.$index])"></List>
              </template>
              <template slot="reference">
                <div class="OrderAdmin--option">
                  <Vector :svg="icon.more_horiz"></Vector>
                </div>
              </template>
            </Popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="OrderAdmin--loader" v-show="state.loadingOrders">
      <Loader></Loader>
    </div>
    <br>
    <br>
    <div class="OrderAdmin--pagination">
      <Pagination :limit="state.filterLimit"
                  :offset="state.filterOffset"
                  :total="state.orderCount"
                  :range="10"
                  :onOffsetChange="v => state.filterOffset = v">
      </Pagination>
    </div>
    <Modal :opened="state.openedUpsert" :onOpenedChange="v => state.openedUpsert = v">
      <div class="OrderAdmin--modal">
        <Form>
          <OrderDetail :order="state.order">
          </OrderDetail>
          <br>
          <Divider></Divider>
          <br>
          <div class="OrderAdmin--footer">
            <!--            <ButtonAction :term="lang.term(158)" :onClick="() => upsert()" :loading="state.loading"></ButtonAction>-->
            <!--            <div class="OrderAdmin&#45;&#45;spacer"></div>-->
            <ButtonLink :term="lang.term(581)" :onClick="() => state.openedUpsert = false"></ButtonLink>
          </div>
        </Form>
      </div>
    </Modal>
    <ModalConfirm :opened="state.openedRemove"
                  :onOpenedChange="v => state.openedRemove = v"
                  :on-validated="() => remove()"
                  :loading="state.loading">
    </ModalConfirm>
  </div>
</template>

<script>

import moment from "moment";
import Carbon from "@bbx/carbon~master/core/ts/Carbon.ts";

import {apiClient} from "../../service/apiClientService";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

import select from "../../../@common/api/order/selectOrders";
import create from "../../../@common/api/order/createOrder";

import {controlForm} from "../../function/controlForm";

import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {Order} from "../../../@common/delta/database/Order";

import {ORDER_SEARCH_ID, ORDER_SORT, ORDER_SORT_DIRECTION} from "../../../@common/constant/ORDER";
import {ORDER_STATE_LIST} from "../../../@common/constant/ORDER_STATE_LIST";
import {PAYMENT_ENGINE_LIST} from "../../../@common/constant/PAYMENT_ENGINE_LIST";
import {getSagePrefixOrder} from "../../../@common/function/getSagePrefixOrder";
import {formatPrice} from "../../../@common/function/formatPrice";
import {PAYMENT_STATE_LIST} from "../../../@common/constant/PAYMENT_STATE_LIST";
import {getBasketTotal} from "../../../@common/function/getBasketTotal";
import {Debounce} from "../../../@common/ts/Debounce";
import {ROLE} from "../../../@common/constant/ROLE";
import {sendError} from "../../function/sendError";

const carbon = new Carbon()
const debounce = new Debounce()


const DATE_FORMAT  = 'YYYY-MM-DD HH:mm:ss'

export default {

  props: {},
  data() {
    return {
      ROLE,
      ORDER_STATE_LIST,
      PAYMENT_STATE_LIST,
      lang: getLangServiceBrowser(),
      icon: {
        add: require('@bbx/vector~master/core/assets/svg/material/add.svg'),
        edit: require('@bbx/vector~master/core/assets/svg/material/edit.svg'),
        visibility: require('@bbx/vector~master/core/assets/svg/material/visibility.svg'),
        delete: require('@bbx/vector~master/core/assets/svg/material/delete.svg'),
        archive: require('@bbx/vector~master/core/assets/svg/material/archive.svg'),
        unarchive: require('@bbx/vector~master/core/assets/svg/material/unarchive.svg'),
        more_horiz: require('@bbx/vector~master/core/assets/svg/material/more_horiz.svg'),
        search: require('@bbx/vector~master/core/assets/svg/material/search.svg'),
        store: require('@bbx/vector~master/core/assets/svg/material/store.svg'),
        person: require('@bbx/vector~master/core/assets/svg/material/person.svg'),
      },
      props: {},
      picture: {
        visa: require('../../../@common/assets/payment/1.png'),
        mastercard: require('../../../@common/assets/payment/2.png'),
        paypal: require('../../../@common/assets/payment/4.png'),
        multiple: require('../../../@common/assets/payment/5.png'),
      },
      state: {
        /**
         * @type boolean
         */
        openedUpsert: false,
        /**
         * @type boolean
         */
        openedRemove: false,
        /**
         * @type ApiResponseForm
         */
        form: new ApiResponseForm(),
        /**
         * @type Order
         */
        order: this.defaultOrder(),
        /**
         * @type boolean
         */
        loading: false,
        /**
         * @type boolean
         */
        loadingOrders: false,
        /**
         * @type number
         */
        filterOffset: 0,
        /**
         * @type number
         */
        filterLimit: 8,
        /**
         * @type boolean
         */
        filterComplete: true,
        /**
         * @type string
         */
        filterSearch: '',
        /**
         * @type number
         */
        sort: ORDER_SORT.CREATED_AT,
        /**
         * @type number
         */
        sortDirection: ORDER_SORT_DIRECTION.DESC,
        /**
         * @type Order[]
         */
        orders: [],
        /**
         * @type number
         */
        orderCount: 0,
        /**
         * @type number
         */
        orderAvg: 0,
        /**
         * @type number
         */
        orderSum: 0,
        /**
         * @type any[]
         */
        searchTypes: [
          {
            id: ORDER_SEARCH_ID.ID,
            value: 'Id'
          },
          {
            id: ORDER_SEARCH_ID.ALIAS,
            value: 'Alias'
          },
          {
            id: ORDER_SEARCH_ID.USER_EMAIL,
            value: 'Email acheteur'
          },
          {
            id: ORDER_SEARCH_ID.PRODUCT_REFERENCE,
            value: 'Référence produits'
          },
        ],
        /**
         * @type any[]
         */
        selectedSearchTypes: [
          {
            id: ORDER_SEARCH_ID.ALIAS,
            value: 'Alias'
          },
        ],
        selectedOption: '',
        options: [
          {
            label: 'Statut Commande',
            options: [
              {
                value: `ORDER_STATE_LIST.${ORDER_STATE_LIST.NEED_VALIDATION}`,
                label: 'En validation'
              },
              {
                value: `ORDER_STATE_LIST.${ORDER_STATE_LIST.IN_PREPARATION}`,
                label: 'En préparation'
              },
              {
                value: `ORDER_STATE_LIST.${ORDER_STATE_LIST.IN_DELIVERING}`,
                label: 'Expédié'
              },
            ]
          },
          {
            label: 'Statut Paiement',
            options: [
              {
                value: `PAYMENT_STATE_LIST.${PAYMENT_STATE_LIST.PAID}`,
                label: 'Payé'
              },
              {
                value: `PAYMENT_STATE_LIST.${PAYMENT_STATE_LIST.PENDING}`,
                label: 'En cours'
              },
              {
                value: `PAYMENT_STATE_LIST.${PAYMENT_STATE_LIST.DECLINED}`,
                label: 'Non payé'
              },
            ]
          }
        ],
        selectedStatus: '',
        /**
         * @type any[]
         */
        // status: [
        //   {
        //     id: ORDER_STATE_LIST.NEED_VALIDATION,
        //     value: 'En validation'
        //   },
        //   {
        //     id: ORDER_STATE_LIST.IN_PREPARATION,
        //     value: 'En préparation'
        //   },
        //   {
        //     id: ORDER_STATE_LIST.IN_DELIVERING,
        //     value: 'Expédié'
        //   },
        // ],
        /**
         * @type any[]
         */
        selectedStatus: [],
        pickerOptions: {
          shortcuts: [
            {
              text: `Mois en cours`,
              onClick(picker) {
                const start = new Date(moment().startOf('month').format(DATE_FORMAT));
                const end = new Date(moment().endOf('day').format(DATE_FORMAT))
                picker.$emit('pick', [
                  start,
                  end
                ]);
              }
            },
            {
              text: `Mois dernier`,
              onClick(picker) {
                const start = new Date(moment().subtract(1, 'month').startOf('month').format(DATE_FORMAT));
                const end = new Date(moment().subtract(1, 'month').endOf('month').format(DATE_FORMAT))
                picker.$emit('pick', [
                  start,
                  end
                ]);
              }
            },
            {
              text: `Cette année`,
              onClick(picker) {
                const start = new Date(moment().startOf('year').format(DATE_FORMAT));
                const end = new Date(moment().endOf('day').format(DATE_FORMAT))
                picker.$emit('pick', [
                  start,
                  end
                ]);
              }
            }
          ]
        },
        value2: [
          carbon.fromDateTime(moment().startOf('month').format(DATE_FORMAT)),
          carbon.fromDateTime(moment().endOf('day').format(DATE_FORMAT)),
        ]
      },
    }
  },
  watch: {
    'state.filterActive': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.filterComplete': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.filterOffset': function () {
      if (this.state.loadingOrders === false) {
        this.select().catch(sendError)
      }
    },
    'state.filterLimit': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.filterSearch': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.filterIsBatch': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.selectedSearchTypes': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.selectedStatus': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.selectedOption': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.value2': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
  },
  computed: {},
  beforeMount() {
    console.log(new Date(moment().endOf('day').format(DATE_FORMAT)))
  },
  mounted() {
    this.select().catch(sendError)
  },
  beforeDestroy() {
  },
  methods: {
    getSagePrefixOrder,
    async select() {
      this.state.loadingOrders = true
      const filter = {
        active: this.state.filterActive,
        $search: this.state.filterSearch,
        $searchType: this.state.selectedSearchTypes.map(a => a.id).pop(),
        $limit: this.state.filterLimit,
        $offset: this.state.filterOffset,
        $hasStatus: this.state.filterComplete,
        $sort: this.state.sort,
        $sortDirection: this.state.sortDirection,
        // $orderStateListsIds: this.state.selectedStatus.map(a => a.id),
        $createdAtStart: this.state.value2 ? this.state.value2[0] : undefined,
        $createdAtEnd: this.state.value2 ? this.state.value2[1] : undefined,
        $orderStateListsIds: this.extractOption(this.state.selectedOption, 'ORDER_STATE_LIST'),
        $paymentStateListsIds: this.extractOption(this.state.selectedOption, 'PAYMENT_STATE_LIST'),
      }
      debounce.callback({
        job: () => apiClient.do(select, select.with(filter)),
        success: res => {
          this.state.orders = res.data;
          this.state.orderCount = res.count;
          this.state.orderAvg = res.extra.avg || 0;
          this.state.orderSum = res.extra.sum || 0;
          this.state.loadingOrders = false
        },
        error: err => {
          this.state.loadingOrders = false
          sendError(err)
        }
      })
    },

    extractOption(option, typePass) {
      if (!option) return []
      let splited = option.split('.')
      let type = splited.shift()
      let value = splited.shift()
      return typePass === type ? [parseInt(value)] : []
    },

    /**
     * @returns {Promise<void>}
     */
    async remove() {
      this.state.order.active = !this.state.order.active;
      await this.upsert()
    },

    async upsert() {

      let payload = new Order(this.state.order)

      // return console.log(JSON.stringify(payload, null, 4));

      const res = await controlForm({
        apiAction: create,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loading',
        loaderState: this.state,
        payload: payload,
        vue: this,
      });

      if (res.success) {

        this.state.order = this.defaultOrder()

        this.state.openedUpsert = false
        this.state.openedRemove = false

        await this.select();
      }
    },

    /**
     * @param {Order} order
     * @returns {Promise<void>}
     */
    async openUpdate(order) {
      this.state.form.clean()
      this.state.order = this.defaultOrder(new Order(order))
      this.state.openedUpsert = true
    },

    /**
     * @param {Order} order
     * @returns {Promise<void>}
     */
    async openDelete(order) {
      this.state.order = this.defaultOrder(new Order(order))
      this.state.openedRemove = true
    },

    /**
     * @param {boolean} isBatch
     * @returns {Promise<void>}
     */
    async openInsert() {
      this.state.form.clean()
      this.state.order = this.defaultOrder(new Order())
      this.state.openedUpsert = true
    },

    /**
     * @param {Order} order
     * @returns {Order}
     */
    defaultOrder(order = new Order) {
      return order
    },

    /**
     * @param {Order} order
     * @return {string}
     */
    toFrenchDate(order) {
      const dateIso = carbon.toDate(order.createdAt)
      let a = dateIso.split('-')
      a = a.reverse()
      return a.join('/')
    },

    /**
     * @param {Order} order
     * @return {number}
     */
    price(order) {
      let price = 0
      for (const funding of order.fundings) {
        for (const payment of funding.payments) {
          price += payment.amount
        }
      }
      if (!price) {
        let roleId = 0
        for (const user of order.users) {
          for (const role of user.roles) {
            roleId = role.id
          }
        }
        const {totalTTC} = getBasketTotal({
          order,
          roleId
        })
        price = totalTTC
      }
      return formatPrice(price)
    },

    /**
     * @param {Order} order
     * @return {{reference:string, quantity:number, type:number}[]}
     */
    referenceQuantities(order) {
      let references = []
      for (const basket of order.baskets) {
        for (const article of basket.articles) {
          for (const batch of article.batches) {
            for (const product of batch.products) {
              references.push({
                reference: product.reference,
                quantity: basket.quantity * batch.quantity,
                type: 1
              })
              for (const component of product.components) {
                for (const productComponent of component.productComponents) {
                  references.push({
                    reference: productComponent.reference,
                    quantity: basket.quantity * batch.quantity,
                    type: 2
                  })
                }
              }
            }
          }
        }
      }
      return references
    },
    /**
     * @param {Order} order
     * @return {number}
     */
    paymentStatus(order) {

      let paymentStateListId = 0

      for (const funding of order.fundings) {
        for (const payment of funding.payments) {
          for (const paymentStateList of payment.paymentStateLists) {
            paymentStateListId = paymentStateList.id
            if (![
              PAYMENT_STATE_LIST.PAID,
              PAYMENT_STATE_LIST.PENDING
            ].includes(paymentStateList.id)) {
              return PAYMENT_STATE_LIST.ERROR
            }
          }
        }
      }

      return paymentStateListId
    },
    /**
     * @param {Order} order
     * @return {number}
     */
    paymentMethods(order) {
      let images = []
      for (const funding of order.fundings) {
        if (funding.loans.length) {
          if (!images.includes(this.picture.multiple)) images.push(this.picture.multiple)
        }
        for (const payment of funding.payments) {
          for (const paymentEngineList of payment.paymentEngineLists) {
            if (paymentEngineList.id === PAYMENT_ENGINE_LIST.PAYPAL) {
              if (!images.includes(this.picture.paypal)) images.push(this.picture.paypal)
            }
            if (paymentEngineList.id === PAYMENT_ENGINE_LIST.SYSTEM_PAY) {
              if (!images.includes(this.picture.visa)) images.push(this.picture.visa)
            }
          }
        }
      }
      return images
    },
    onDateSortChange(a) {

      // -----

      if (a.prop === 'createdAt') {
        this.state.sort = ORDER_SORT.CREATED_AT
      }

      // -----

      if (a.order === null) {
        this.state.sortDirection = 0
      } else if (a.order === 'ascending') {
        this.state.sortDirection = ORDER_SORT_DIRECTION.ASC
      } else if (a.order === 'descending') {
        this.state.sortDirection = ORDER_SORT_DIRECTION.DESC
      }

      // -----

      this.select().catch(sendError)
    }
  }
}
</script>
