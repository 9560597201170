"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dataSocials = void 0;
exports.dataSocials = [
    {
        id: 1,
        title: 'facebook',
        href: 'https://www.facebook.com/lesjardins.fr?fref=ts',
        icon: require('../../@common/assets/home/caroussel/social-1.svg')
    },
    {
        id: 2,
        title: 'instagram',
        href: 'https://www.instagram.com/lesjardins.fr/',
        icon: require('../../@common/assets/home/caroussel/social-2.svg')
    },
    {
        id: 3,
        title: 'youtube',
        href: 'https://www.youtube.com/channel/UCVdKF3V0owOL4_CYZ1-VPHw',
        icon: require('../../@common/assets/home/caroussel/social-3.svg')
    },
];
