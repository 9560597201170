"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.urlService = void 0;
var UrlService = /** @class */ (function () {
    function UrlService() {
    }
    UrlService.prototype.pathStringToPathArray = function (_a) {
        var pathString = _a.pathString;
        var chains = pathString.split('/');
        chains.shift();
        chains.shift();
        chains.shift();
        var pathObject = {};
        for (var _i = 0, chains_1 = chains; _i < chains_1.length; _i++) {
            var chain = chains_1[_i];
            pathObject[chain] = chain;
        }
        return pathObject;
    };
    UrlService.prototype.queryStringToQueryObject = function (_a) {
        var queryString = _a.queryString;
        queryString = queryString.replace(/^\?/, '');
        var chains = queryString.split('&').filter(function (a) { return a; });
        var queryObject = {};
        for (var _i = 0, chains_2 = chains; _i < chains_2.length; _i++) {
            var chain = chains_2[_i];
            var _b = chain.split('='), key = _b[0], value = _b[1];
            queryObject[key] = value;
        }
        return queryObject;
    };
    return UrlService;
}());
exports.urlService = new UrlService();
