"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.documentMakerConfigPublic = void 0;
var ENV_1 = require("../../core/@common/constant/ENV");
var config_1 = require("../config");
if (config_1.config.env === ENV_1.ENV.PRODUCTION) {
    exports.documentMakerConfigPublic = {
        urlDownload: "https://document-maker.lesjardins-int.com/download",
        urlPdf: "https://document-maker.lesjardins-int.com/pdf",
        urlPrepare: "https://document-maker.lesjardins-int.com/prepare",
    };
}
