<style scoped>

</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="content-wrapper none for-title">
      <div class="container_12">
        <div class="main-container main-main-container col1-layout">
          <div class="grid_12 col-main">
            <div class="page-title">
              <h1>Chaise de jardin en aluminium et transat - Les Jardins Vente Privée</h1>
            </div>
            <div class="std">
              <p style="text-align: justify;">
                <img alt="" src="./media/wysiwyg/6_1.jpg">
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Comme tout le mobilier d’un salon de jardin,
                  <b>la</b>

                  <b>chaise</b>
                  <b> et le bain de soleil</b>
                  sont des éléments très appréciés pour aménager une terrasse en bois, un balcon ou un jardin. Ces
                  derniers doivent être confortables et stables, quelle que soit la personne qui s’y assoie.
                  <b>L’aluminium</b>
                  est un matériau inoxydable qui peut être travaillé pour proposer des modèles de chaises classiques, de
                  chaises longues ou de bains de soleil résistants aux intempéries et parfaitement adaptés à
                  l’extérieur. Découvrez les avantages de l’alu pour vos chaises de jardin et transat.
                </span>
              </p>
              <h2 style="text-align: justify;">Léger et résistant
                <img style="float: right; padding-left: 20px;" alt="" src="./media/wysiwyg/img15.jpg">
              </h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">L’aluminium est un
                  <b>métal léger</b>
                  permettant de réaliser des meubles&nbsp;:
                  <b>faciles à déplacer</b>
                  , moduler ou ranger. La plupart du temps recouverts de résine ou de polyester tressé, les bains de
                  soleil peuvent résister aux assauts du soleil et de ses UV, mais également à la pluie et aux climats
                  humides. Leur utilisation est préconisée en littoral méditerranéen ou le degré de salinité de l’air
                  est important. L’aluminium
                  <b>résistera</b>
                  là ou un acier ou un autre métal ferreux rouillera rapidement. Vous pouvez opter pour une chaise
                  pliante ou empilable pour
                  <b>optimiser l’espace</b>
                  et faciliter le stockage.
                </span>
              </p>
              <h2 style="text-align: justify;">Des assises élégantes et avec du style</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">
                  <b>Les formes et les couleurs</b>
                  disponibles dans les catalogues de mobilier de jardin montrent l’étendue des possibilités avec
                  l’aluminium. Optez pour du blanc afin d’apporter de la lumière à moins que vous ne préfériez un modèle
                  foncé, taupe, noir ou gris anthracite par exemple. Les différents meubles sauront s’accorder avec
                  toutes les
                  <b>décorations</b>&nbsp;: rétro, bohème, chic... Cette matière chaleureuse à la vue et au toucher
                  pousse les designers à faire preuve d’inventivité pour proposer des modèles toujours plus tendance, et
                  parfois même futuristes. Qu’ils soient plats ou galbés, sur pieds ou avec roulettes, le choix ne
                  manque pas. Trouvez le modèle ou le lot de chaises et fauteuils, avec accoudoirs ou non, qui
                  s’accordera parfaitement avec votre
                  table de
                  jardin en aluminium
                  et qui composera un espace de vie agréable et convivial.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <h2 style="text-align: justify;">Des chaises faciles à entretenir</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Rien de plus facile pour
                  <b>nettoyer ses chaises</b>
                  , empilables ou pliantes, ou son transat en alu. Il suffit d’un peu d’eau savonneuse et d’une éponge
                  et le tour est joué. Avec un faible pouvoir adhésif, il est facile de nettoyer les taches et
                  projections sur de l’aluminium, sans effort et s
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">urtout sans abrasion. C’est un matériau tendre et un frottement trop
                  important entraîner
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">ait des rayures. On évitera également le chlore et les hydrocarbures qui
                  peuvent réagir chimiquement et ternir le mobilier.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p>
                <img alt="" src="./media/wysiwyg/6c.jpg" height="600" width="400">
                <img style="float: right; padding-left: 20px; padding-right: 220px;" alt="" src="./media/wysiwyg/6b.jpg">
              </p>
              <h2 style="text-align: justify;">Un matériau à bas prix ?</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">La fabrication des chaises ou des
                  bains de soleil
                  peut être réalisée en
                  <b>structure tubulaire</b>
                  avec un produit très abordable et plus design que certains PVC. Il peut être également
                  <b>entièrement moulé</b>
                  afin d’obtenir des formes modernes et sculptées dans la matière. Les différentes méthodes de
                  production offrent un éventail de prix intéressant et permettent à chacun de trouver un modèle qui
                  s’intègre à sa terrasse ou son jardin.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Bref, vous l’aurez compris, choisir
                  <b>une chaise de jardin ou un bain de soleil en aluminium</b>
                  c’est l’assurance d’un matériau qui allie
                  <b> qualité, résistance et élégance</b>
                  .
                </span>
              </p>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../../function/getH1";
import {Term} from "../../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../../@common/constant/PROJECT_URL";
import {PATHS} from "../../../../@common/constant/PATHS";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    },
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FAQ-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
