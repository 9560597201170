<style>

.Purchase--spplus {
}

.Purchase--spplus .kr-payment-button {
  background: rgb(226 1 15) !important;
}

.Purchase--spplusHeader {
  background: rgb(226 1 15);
  height: 50px;
  color: white;
  font-family: 'RalewayBold', sans-serif;
  font-size: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Purchase--spplusBody {
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
}

.Purchase--spplusBody img {
  display: block;
  padding-bottom: 50px;
}

.Purchase--spplus-desktop .Purchase--spplusBody {
  padding: 100px;
}

.Purchase--spplus-tablet .Purchase--spplusBody {
  padding: 100px;
}

.Purchase--spplus-mobile .Purchase--spplusBody {
  padding: 50px;
}
</style>
<style scoped>
.Purchase {
}

.Purchase--complete {
  width: 100%;
  height: 500px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.Purchase--completeTitle {
  margin-bottom: 25px;
  font-family: 'AmbroiseRegular', sans-serif;
  font-size: 32px;
}

.Purchase--completeLinkOrder ::v-deep .Term {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

.Purchase--completeLinkOrder:hover ::v-deep .Term p {
  text-decoration: underline;
}

.Purchase--header {
}

.Purchase--body {
  display: flex;
  align-items: flex-start;
}

.Purchase-desktop .Purchase--body {
  flex-direction: row;
  padding: 100px;
}

.Purchase-tablet .Purchase--body {
  flex-direction: column;
  display: flex;
  align-items: center;
  padding: 50px 0px;
}

.Purchase-mobile .Purchase--body {
  flex-direction: column;
  display: flex;
  align-items: center;
}

.Purchase--tabs {
  flex: 1;
}

.Purchase-desktop .Purchase--tabs {
  min-width: 600px;
  width: 600px;
}

.Purchase-tablet .Purchase--tabs {
  min-width: 600px;
  width: 600px;
  padding: 50px 0px 0px 0px;
}

.Purchase-mobile .Purchase--tabs {
  min-width: 100%;
  width: 100%;
  padding: 50px 25px 0px 25px;
  box-sizing: border-box;
}

.Purchase--tabsContent {
}

.Purchase-desktop .Purchase--tabsContent {
  max-width: 600px;
  margin: auto;
}

.Purchase-tablet .Purchase--tabsContent {
}

.Purchase-mobile .Purchase--tabsContent {
}

.Purchase--tab {
}

.Purchase-desktop .Purchase--tab {
}

.Purchase-tablet .Purchase--tab {
}

.Purchase-mobile .Purchase--tab {
}

.Purchase--loader {
  height: 100%;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}


/*.Purchase--tab ::v-deep .BasketList--picture {*/
/*  width: 150px;*/
/*  height: 150px;*/
/*  min-width: 150px;*/
/*  min-height: 150px;*/
/*}*/

.Purchase--infos {

}

.Purchase-desktop .Purchase--infos {
  min-width: 500px;
  width: 500px;
  position: relative;
  top: -100px;
}

.Purchase-tablet .Purchase--infos {
  min-width: 600px;
  width: 600px;
  padding: 50px 0px;
}

.Purchase-mobile .Purchase--infos {
  min-width: 100%;
  width: 100%;
  padding: 50px 25px;
  box-sizing: border-box;
}
</style>

<template>
  <div class="Purchase" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">


      <div class="Purchase--complete" v-if="state.purchaseTab === PATHS.COMPLETE">
        <div class="Purchase--completeTitle">
          <Term :term="lang.term(293)" tag="h1"></Term>
        </div>
        <ButtonLink :pathIds="[PATHS.HOME]" :term="lang.term(292)"></ButtonLink>
        <br>
        <div class="Purchase--completeLinkOrder">
          <Link :pathIds="[PATHS.ACCOUNT, PATHS.ORDER]">
            <Term :term="lang.term(294)"></Term>
          </Link>
        </div>
      </div>


      <div class="Purchase--header" v-if="state.purchaseTab !== PATHS.COMPLETE">
        <PurchaseState :onClickIcon="v => changeTab(v)"
                       :currentPathIds="state.currentPathIds"
                       :donePathIds="state.donePathIds">
        </PurchaseState>
      </div>


      <div class="Purchase--body" v-if="state.purchaseTab !== PATHS.COMPLETE">
        <div class="Purchase--tabs">
          <div class="Purchase--tabsContent">


            <div class="Purchase--tab" v-if="state.purchaseTab === PATHS.BASKET">
              <PurchaseRecap :order="state.order"
                             :onOrderChange="v => state.order = v">
              </PurchaseRecap>
            </div>


            <div class="Purchase--tab" v-if="state.purchaseTab === PATHS.DELIVERY">
              <PurchaseDelivery :order="state.order"
                                :form="state.form"
                                :addresses="state.addresses"
                                :transports="state.transports"
                                :onOrderChange="v => state.order = v">
              </PurchaseDelivery>
            </div>


            <div class="Purchase--tab" v-if="state.purchaseTab === PATHS.PAYMENT">
              <PurchasePayment :onPaymentChose="v => onPaymentChose(v)"
                               :onOptionChange="v => onOptionChange(v)"
                               :order="state.order"
                               :form="state.form"
                               :loading="state.loading"
                               :transports="state.transports"
                               :approved="state.approved"
                               :onApprovedChange="v => state.approved = v"
                               :onOrderChange="v => state.order = v">
              </PurchasePayment>
              <Modal :opened="state.spPlusOpened" :on-opened-change="v => state.spPlusOpened = v">
                <div class="Purchase--spplus" :class="class_rootSPPLUS">
                  <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                              :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                              :breakPoints="props.Responsive['0'].breakPoints"
                              :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                              :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                              :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
                    <div class="Purchase--spplusHeader">Propulsé par la Caisse d'Épargne</div>
                    <div class="Purchase--spplusBody">
                      <template v-if="state.paymentType === 'spplus'">
                        <div v-if="!state.loading">
                          <img src="./image/national_logo.png" alt="caise d'épargne" width="260" height="60">
                          <div id="Purchase--spplusForm">
                          </div>
                        </div>
                        <div v-if="state.loading" class="Purchase--loader">
                          <LoaderSpace></LoaderSpace>
                        </div>
                      </template>
                    </div>
                  </Responsive>
                </div>
              </Modal>
            </div>


          </div>
        </div>


        <div class="Purchase--infos">
          <PurchaseDetail :order="state.order"
                          :onOrderChange="v => state.order = v"
                          :loading="state.loading"
                          :transports="state.transports"
                          :purchaseTab="state.purchaseTab"
                          :displayTransportCost="canDisplayTransportCost"
                          :onClickButton="v => save(v)">
            <template slot="payment">
              <div v-show="state.paymentType === 'paypal'">
                <div v-show="state.loading">
                  <LoaderSpace></LoaderSpace>
                </div>
                <div v-show="!state.loading" id="paypal-button"></div>
              </div>
              <ButtonAction v-show="state.paymentType === 'spplus'"
                            :loading="state.loading"
                            :term="lang.term(290)"
                            :onClick="() => onClickConfirmSPPLus()">
              </ButtonAction>
              <br v-show="state.paymentType">
              <br v-show="state.paymentType">
            </template>
          </PurchaseDetail>
        </div>

      </div>
    </Responsive>
  </div>
</template>

<script>

import KRGlue from "@lyracom/embedded-form-glue";

import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';

import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {Order} from "../../../@common/delta/database/Order";

import {paypalConfigPublic} from "../../../../config/public/paypal.config";
import {spplusConfigPublic} from "../../../../config/public/spplus.config";

import {changeRoute} from "../../function/changeRoute";
import {controlForm} from "../../function/controlForm";
import {canDisplayTransportCost} from "../../../@common/function/canDisplayTransportCost";
import {getBasketTotal} from "../../../@common/function/getBasketTotal";

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {apiClient} from "../../service/apiClientService";
import {eventService} from "../../service/eventService";
import {STATE_ORDER, STATE_ROLE_ID, stateService} from "../../service/stateService";

import createOrder from "../../../@common/api/order/createOrder";
import initOrder from "../../../@common/api/order/initOrder";
import selectUsers from "../../../@common/api/user/selectUsers";
import selectTransports from "../../../@common/api/transport/selectTransports";

import {FUNDING_OPTION_LIST} from "../../../@common/constant/FUNDING_OPTION_LIST";
import {EVENT} from "../../../@common/constant/EVENT";
import {PATHS} from "../../../@common/constant/PATHS";
import {sendError} from "../../function/sendError";

export default {
  props: {
    purchaseTab: {
      type: Number,
      default: () => PATHS.BASKET
    },
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      PATHS,
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        box: require('../../../@common/assets/icon/broken/Iconly-Broken-Buy.svg'),
        local_shipping: require('@bbx/vector~master/core/assets/svg/material/local_shipping.svg'),
        shopping_basket: require('@bbx/vector~master/core/assets/svg/material/shopping_basket.svg'),
        credit_card: require('@bbx/vector~master/core/assets/svg/material/credit_card.svg'),
        done: require('@bbx/vector~master/core/assets/svg/material/done.svg'),
      },
      state: {
        /**
         * @type ApiResponseForm
         */
        form: new ApiResponseForm(),
        /**
         * @type Order
         */
        order: new Order(),
        /**
         * @type boolean
         */
        loading: false,
        /**
         * @type boolean
         */
        approved: false,
        /**
         * @type boolean
         */
        loadingTransport: false,
        /**
         * @type string
         */
        paymentType: '',
        /**
         * @type number
         */
        paymentOption: 0,
        /**
         * @type number
         */
        transportCostPriceTTC: 0,
        /**
         * @type boolean
         */
        spPlusOpened: false,
        /**
         * @type boolean
         */
        initialized: false,
        /**
         * @type number
         */
        purchaseTab: this.purchaseTab,
        /**
         * @type Transport[]
         */
        transports: [],
        /**
         * @type Array
         */
        currentPathIds: [],
        /**
         * @type Array
         */
        donePathIds: [],
        /**
         * @type number
         */
        roleId: 0,
      },
    }
  },
  watch: {
    'state.paymentOption': {
      handler: function (v) {
        if (this.state.paymentType === 'spplus' && [
          FUNDING_OPTION_LIST.LOAN,
          FUNDING_OPTION_LIST.DEPOSIT
        ].includes(v)) {
          this.initSPPLUS(false).catch(sendError)
        }
      }
    },
    'state.paymentType': {
      handler: function (v) {
        if (v === 'paypal') {
          this.initPAYPAL().catch(sendError)
        }
      }
    },
    'purchaseTab': {
      handler: function (v) {
        this.state.purchaseTab = v
      }
    },
    'state.purchaseTab': {
      handler: function (v) {
        this.dispatchPathIds()
      }
    },
    'state.order': {
      handler: function (v) {
        stateService.set(STATE_ORDER, v)
      },
      deep: true
    }
  },
  computed: {
    canDisplayTransportCost() {
      return canDisplayTransportCost(this.state.order.deliveries)
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`Purchase-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`Purchase-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`Purchase-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`Purchase-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`Purchase-desktopY`)
      return classes
    },
    class_rootSPPLUS() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`Purchase--spplus-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`Purchase--spplus-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`Purchase--spplus-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`Purchase--spplus-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`Purchase--spplus-desktopY`)
      return classes
    },
  },
  beforeMount() {

    // -----

    this.state.order = stateService.get(STATE_ORDER)
    stateService.watch(STATE_ORDER, v => this.state.order = v)

    this.state.roleId = stateService.get(STATE_ROLE_ID)
    stateService.watch(STATE_ROLE_ID, v => this.state.roleId = v)

    // -----
  },
  mounted() {

    // -----

    this.dispatchPathIds()

    // -----

    const a = document.getElementById('purchase-spplus-css')
    if (!a) {
      let spplusCSS = document.createElement('link');
      spplusCSS.rel = 'stylesheet';
      spplusCSS.href = spplusConfigPublic.urlCSS;
      spplusCSS.id = 'purchase-spplus-css';
      document.body.appendChild(spplusCSS);
    }

    const b = document.getElementById('purchase-spplus-js')
    if (!b) {
      let spplusJS = document.createElement('script');
      spplusJS.src = spplusConfigPublic.urlJS;
      spplusJS.id = 'purchase-spplus-js';
      document.body.appendChild(spplusJS);
    }

    // -----

    const c = document.getElementById('purchase-paypal-js')
    if (!c) {
      let scriptPaypal = document.createElement('script');
      scriptPaypal.src = 'https://www.paypalobjects.com/api/checkout.js';
      scriptPaypal.id = 'purchase-paypal-js';
      document.body.appendChild(scriptPaypal);
    }

    // -----

    this.select().catch(sendError)

    // -----
  },
  beforeDestroy() {
    if (document.getElementById('purchase-spplus-js')) document.body.removeChild(document.getElementById('purchase-spplus-js'))
    if (document.getElementById('purchase-spplus-css')) document.body.removeChild(document.getElementById('purchase-spplus-css'))
    if (document.getElementById('purchase-paypal-js')) document.body.removeChild(document.getElementById('purchase-paypal-js'))
  },
  methods: {
    dispatchPathIds() {
      this.state.currentPathIds = [this.purchaseTab]
      if (this.purchaseTab === PATHS.BASKET) {
      } else if (this.purchaseTab === PATHS.DELIVERY) {
        this.state.donePathIds = [PATHS.BASKET]
      } else if (this.purchaseTab === PATHS.PAYMENT) {
        this.state.donePathIds = [
          PATHS.BASKET,
          PATHS.DELIVERY
        ]
      }
    },

    /**
     * * * * * * * * *
     *
     *    SELECT
     *
     * * * * * * * * *
     */

    async select() {
      await Promise.all([
        this.selectTransports(),
      ])
    },

    async selectTransports() {
      this.state.loadingTransport = true
      const {data} = await apiClient.do(selectTransports, selectUsers.with({}))
      this.state.transports = data
      this.state.loadingTransport = false
    },

    /**
     * * * * * * * * *
     *
     *    OTHER
     *
     * * * * * * * * *
     */

    changeTab(pathId) {
      if (PATHS.DELIVERY === pathId && !this.state.order.baskets.length) return
      this.state.purchaseTab = pathId
      changeRoute([
        PATHS.PURCHASE,
        pathId
      ])
    },

    /**
     * * * * * * * * *
     *
     *    PAYEMENT
     *
     * * * * * * * * *
     */
    onClickConfirmSPPLus() {
      if (this.state.paymentOption === FUNDING_OPTION_LIST.LOAN) {
        eventService.triggerByName(new ListenerTrigger({
          name: EVENT.PAYMENT_METHOD_CHOSEN,
          payload: '3X'
        }))
      } else if (this.state.paymentOption === FUNDING_OPTION_LIST.DEPOSIT) {
        eventService.triggerByName(new ListenerTrigger({
          name: EVENT.PAYMENT_METHOD_CHOSEN,
          payload: '2X'
        }))
      } else {
        eventService.triggerByName(new ListenerTrigger({
          name: EVENT.PAYMENT_METHOD_CHOSEN,
          payload: 'CB'
        }))
      }
      this.initSPPLUS().catch(sendError)
    },
    async initSPPLUS(open = true) {

      // -----

      const payload = Object.assign(this.state.order, {
        $step: 3,
        $approved: this.state.approved
      })

      let res = await controlForm({
        apiAction: createOrder,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loading',
        loaderState: this.state,
        payload: payload,
        vue: this,
      })

      if (!res.success) return;
      stateService.set(STATE_ORDER, new Order(res.data))

      // -----

      this.state.spPlusOpened = open

      res = await controlForm({
        apiAction: initOrder,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loading',
        loaderState: this.state,
        payload: {
          $paymentType: this.state.paymentType,
          $order: this.state.order,
          $option: 'spplus-init',
          $data: {}
        },
        vue: this,
      })

      if (!res.success) return alert('SPPlus<2> : Un problème est survenu, réessayez plus tard.');

      let lib = await KRGlue.loadLibrary(spplusConfigPublic.url, spplusConfigPublic.publicKey)

      await lib.KR.setFormConfig({
        // 'kr-get-url-success': `${window.location.origin}${window.location.pathname}`,
        'formToken': res.data.$token,
        'kr-language': 'fr-FR',
      })

      await lib.KR.onSubmit(event => {
        const asyncFunction = async () => {
          if (event.clientAnswer.orderStatus === "PAID") {

            const res = await controlForm({
              apiAction: initOrder,
              formAttribute: 'form',
              formState: this.state,
              loaderAttribute: 'loading',
              loaderState: this.state,
              payload: {
                $paymentType: this.state.paymentType,
                $order: this.state.order,
                $option: 'spplus-confirm',
                $data: event,
              },
              vue: this,
            })

            if (res.success) {
              this.onComplete()
              this.state.spPlusOpened = false
            }

          } else {
            sendError(new Error(JSON.stringify(event)))
            alert('SPPlus<3> : Un problème est survenu, réessayez plus tard.');
          }
        }
        return asyncFunction()
      })

      const form = await KR.addForm('#Purchase--spplusForm')

      form.KR.showForm(form.result.formId)
    },
    async initPAYPAL() {
      if (this.initialized) return;

      this.initialized = true

      // -----

      const payload = Object.assign(this.state.order, {
        $step: 3,
        $approved: this.state.approved
      })

      let res = await controlForm({
        apiAction: createOrder,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loading',
        loaderState: this.state,
        payload: payload,
        vue: this,
      })

      if (!res.success) return
      stateService.set(STATE_ORDER, new Order(res.data))

      // -----

      window.paypal.Button.render({
        env: paypalConfigPublic.env,
        locale: 'fr_FR',
        style: {size: 'responsive'},
        onClick() {
          eventService.triggerByName(new ListenerTrigger({
            name: EVENT.PAYMENT_METHOD_CHOSEN,
            payload: 'Paypal'
          }))
        },
        payment: (data, actions) => {
          const asyncFunction = async () => {
            const res = await controlForm({
              apiAction: initOrder,
              formAttribute: 'form',
              formState: this.state,
              loaderAttribute: 'loading',
              loaderState: this.state,
              payload: {
                $paymentType: this.state.paymentType,
                $order: this.state.order,
                $option: 'paypal-init',
                $data: {
                  redirect: window.location.href
                }
              },
              vue: this,
            })

            if (!res.success) return alert('Paypal<2> : Un problème est survenu, réessayez plus tard.');

            return res.data.$payload.id
          }
          return asyncFunction()
        },
        onAuthorize: (data, actions) => {
          const asyncFunction = async () => {
            const res = await controlForm({
              apiAction: initOrder,
              formAttribute: 'form',
              formState: this.state,
              loaderAttribute: 'loading',
              loaderState: this.state,
              payload: {
                $paymentType: this.state.paymentType,
                $order: this.state.order,
                $option: 'paypal-confirm',
                $data: {
                  redirect: window.location.href,
                  paymentID: data.paymentID,
                  payerID: data.payerID,
                  data: data
                }
              },
              vue: this,
            })
            if (res.success) {
              this.state.spPlusOpened = false
              this.onComplete()
            } else {
              sendError(new Error(JSON.stringify(data)))
              return alert('Paypal<3> : Un problème est survenu, réessayez plus tard.');
            }
          }
          return asyncFunction()
        }
      }, '#paypal-button');

    },
    async save(pathId) {

      // -----
      // STAPE 1
      // -----

      if (this.state.purchaseTab === PATHS.BASKET) {
        this.changeTab(pathId)
      }

          // -----
          // STAPE 2
      // -----

      else if (this.state.purchaseTab === PATHS.DELIVERY) {

        const payload = Object.assign(this.state.order, {$step: 2})

        const res = await controlForm({
          apiAction: createOrder,
          formAttribute: 'form',
          formState: this.state,
          loaderAttribute: 'loading',
          loaderState: this.state,
          payload: payload,
          vue: this,
        })

        if (res.success) {
          stateService.set(STATE_ORDER, new Order(res.data))
          this.changeTab(pathId)
        }
      }

          // -----
          // STAPE 3
      // -----

      else if (this.state.purchaseTab === PATHS.PAYMENT) {
        // controlled by payment callback
      }
    },
    onPaymentChose(p) {
      this.state.paymentType = p
    },
    onOptionChange(p) {
      this.state.paymentOption = p
    },
    onComplete() {
      let order = new Order(this.state.order)

      stateService.set(STATE_ORDER, new Order())

      this.changeTab(PATHS.COMPLETE)

      eventService.triggerByName(new ListenerTrigger({
        name: EVENT.ORDER_WAS_COMPLETED,
        payload: {
          order: order,
          basketTotal: getBasketTotal({
            order: order,
            transports: this.state.transports,
            roleId: this.state.roleId
          })
        }
      }))
    }
  }
}
</script>
