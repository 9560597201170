"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ShopTypeList = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Shop_1 = require("./Shop");
var Collection_1 = require("./Collection");
var Selection_1 = require("./Selection");
var ShopTypeList = /** @class */ (function () {
    function ShopTypeList(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.value = deltaService_1.deltaService.string(props.value, '');
        this.shops = deltaService_1.deltaService.array(props.shops, Shop_1.Shop);
        this.collections = deltaService_1.deltaService.array(props.collections, Collection_1.Collection);
        this.selections = deltaService_1.deltaService.array(props.selections, Selection_1.Selection);
    }
    return ShopTypeList;
}());
exports.ShopTypeList = ShopTypeList;
