import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {EVENT} from "../../@common/constant/EVENT";
import {eventService} from "../service/eventService";

export function changeLocalLang(langCode: string) {
    eventService.triggerByName(new ListenerTrigger({
        name: EVENT.CHANGE_LANG,
        payload: langCode
    }))
}
