"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.stateConfig = void 0;
var config_1 = require("../config");
var ENV_1 = require("../../core/@common/constant/ENV");
if (config_1.config.env === ENV_1.ENV.PRODUCTION) {
    exports.stateConfig = {
        stateName: 'state-d246b3b9015a0f82ddda724e9b0b1f4a',
        version: 13
    };
}
