<style scoped>
.PhotoForm {
}

.PhotoForm--photo {
  background: rgba(0, 0, 0, 0.05);
  width: 100%;
  border-radius: 8px;
  position: relative;
  padding-bottom: 50%;
}

.PhotoForm--photoContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  border-radius: 8px;
  overflow: hidden;
}
</style>

<template>
  <div class="PhotoForm">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="PhotoForm--photo">
        <div class="PhotoForm--photoContent">
          <template v-for="file in state.photo.files">
            <Picture :file="file" :display="photo.full ? PICTURE_DISPLAY.COVER : PICTURE_DISPLAY.AUTO "/>
          </template>
        </div>
      </div>
      <br>
      <br>
      <template v-for="file in state.photo.files">
        <InputText :label="lang.term(304)" :value="file.name" :onChangeValue="v => file.name = v"></InputText>
      </template>
      <br>
      <InputLabel :term="lang.term(227)"/>
      <br>
      <InputSwitch :value="state.photo.cover" :onChangeValue="v => state.photo.cover = v"/>
      <br>
      <InputLabel :term="lang.term(228)"/>
      <br>
      <InputSwitch :value="state.photo.full" :onChangeValue="v => state.photo.full = v"/>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {Photo} from "../../../@common/delta/database/Photo";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {PICTURE_DISPLAY} from "../../../@common/constant/PICTURE_DISPLAY";

export default {

  props: {
    photo: {
      default: () => new Photo()
    },
    onPhotoChange: {
      type: Function,
      default: () => (photo) => {
      }
    }
  },
  data() {
    return {
      PICTURE_DISPLAY,
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      state: {
        /**
         * @type Photo
         */
        photo: this.photo
      },
    }
  },
  watch: {
    'photo': {
      handler: function (v) {
        this.state.photo = v
      },
      deep: true
    },
    'state.photo': {
      handler: function (v) {
        this.onPhotoChange(v)
      },
      deep: true
    },
  },
  computed: {},
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
