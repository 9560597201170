export function canDisplayTransportCost(deliveries): boolean {
    for (const delivery of deliveries) {
        for (const address of delivery.addresses) {
            if (address.countries.length) {
                return true
            }
        }
    }
    return false
}
