<style scoped>

</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="content-wrapper none for-title">
      <div class="container_12">
        <div class="main-container main-main-container col1-layout">
          <div class="grid_12 col-main">
            <div class="page-title">
              <h1>Comment choisir sa table de jardin ? - Les Jardins Vente Privée</h1>
            </div>
            <div class="std">
              <p style="text-align: justify;">
                <img alt="" src="./media/wysiwyg/7.jpg">
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">La
                  <strong>table de jardin</strong>&nbsp;
                </span>
                <span style="font-size: large;">a le pouvoir de réunir les membres d’une famille ou d’un groupe d’amis.
                  Élément central et incontournable d’une décoration extérieure, elle vous permet de profiter du beau
                  temps pour partager un repas sous le soleil ou à l’ombre d’un parasol. La
                  table de jardin
                  doit répondre à vos besoins et vos envies. Elle doit donc être choisie avec soin. Découvrez nos 5
                  conseils pour bien choisir sa table de jardin.
                </span>&nbsp;
              </p>
              <h2 style="margin-top: 22px;   clear: left; text-align: justify;">
                La surface du jardin ou de la terrasse
                <img style=" float: right; padding: 25px" alt="" src="./media/wysiwyg/img11.jpg" height="394" width="351">
              </h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">La place dont vous disposez dans votre espace extérieur, jardin,
                  terrasse, balcon, est un élément essentiel à prendre en compte dans le choix d’une table de jardin. Si
                  cette dernière est destinée à être placée sur une petite terrasse, alors vous pouvez&nbsp;<b>optimiser
                    l’espace en optant pour une table pliante ou une table de café
                  </b>
                  . En revanche, si votre jardin est vaste, alors vous pourrez vous diriger vers un modèle aux
                  dimensions plus importantes.
                </span>&nbsp;
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Il existe
                  <b>plusieurs formes de table</b>
                  : ronde, rectangulaire, carrée... Vous aurez l’embarras du choix pour trouver le meuble qui vous
                  convient et qui correspond à votre déco.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <h2 style="margin-top: 22px;   clear: left; text-align: justify;">
                Le nombre de convives
              </h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Lors de votre décision, vous devrez également tenir compte
                  <b>du nombre de personnes</b>&nbsp;qui viendront partager un repas autour de votre table de jardin.
                  Sélectionnez ainsi la vôtre en fonction des membres de votre ménage et de vos invités ! Une table aux
                  dimensions de 40x40 cm ou 80x80 cm conviendra parfaitement pour une ou deux personnes, là où une table
                  180/240x90 cm vous permettra d’accueillir jusqu’à 10 personnes et une 217/320x106 cm jusqu’à 14
                  personnes ! Tout dépend donc de l’usage que vous en aurez. Pour des grandes tablées occasionnelles,
                  misez sur une table
                  <b>extensible manuellement ou automatiquement</b>&nbsp;pour les modèles les plus haut de gamme.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <img style="float: right;  padding: 25px;" alt="" src="./media/wysiwyg/img12.jpg" height="426" width="349">
              </p>
              <h2 style="margin-top: 22px;   clear: left; text-align: justify;">
                Le design
              </h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le design de la table est très important, car celle-ci doit donner du
                  cachet à votre extérieur. Vous trouverez des meubles de
                  <b>style classique</b>
                  comme la table en
                  <i>zelliges</i>
                  ou encore des modèles aux
                  <b>formes plus contemporaines</b>
                  . Quant aux pieds, vous avez l’embarras du choix entre les&nbsp;<b>pieds traineau, les monopieds ou
                    les tables tréteaux
                  </b>&nbsp;qui ont un charme très bucolique. Parmi les meubles pliables, vous aimerez les tables
                  pliantes et ergonomiques avec leurs 4 sièges qui leur servent de pieds. Pour apporter une touche de
                  décoration, vous pourrez choisir une housse de protection de couleur pour les coussins de vos chaises.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <h2 style="margin-top: 22px;   clear: left; text-align: justify;">
                Les matériaux
              </h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le choix du matériau de votre table de jardin est primordial. Certaines
                  tables sont fabriquées dans
                  <b>des matériaux très divers</b>
                  . Le bois et le teck restent des incontournables. Tandis qu’ils acquièrent une belle patine au fil des
                  ans, ils sont particulièrement résistants et ne nécessitent que peu d’entretien. Les modèles avec une
                  structure en inox ou en alu et avec un plateau en verre ont également la côte. Le fer forgé a un
                  aspect très rustique et rehausse immédiatement la décoration de votre jardin grâce à ses lignes
                  raffinées. Il existe de même des tables en résine tressée, une matière très à la mode. Enfin, les
                  meubles en PVC ou en plastique se déclinent en divers coloris et offrent un bon rapport qualité/prix.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <h2 style="margin-top: 22px;   clear: left; text-align: justify;">
                Le prix&nbsp;
              </h2>
              <p>
                <span style="font-size: large;">
                  <b>Il existe des tables pour tous les budgets</b>
                  . Il est important de choisir un modèle de qualité qui soit suffisamment
                  <b>résistant</b>
                  pour durer dans le temps plutôt qu’une table « low cost » qui ne résistera pas au poids, à l’eau, aux
                  UV, aux intempéries et qui risquerait de se casser et donc de gâcher votre journée d’été.
                </span>
              </p>
              <h4 style="text-align: justify;">
                <span style="font-size: large;">Autre conseils</span>
              </h4>
              <ul>
                <li style="text-align: justify;">
                  <span style="font-size: large;">
                    Comment nettoyer une table de jardin en aluminium et composite ?
                  </span>
                </li>
                <li style="text-align: justify;">
                  <span style="font-size: large;">
                    Comment nettoyer une table de salon de jardin en teck ?
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../../function/getH1";
import {Term} from "../../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../../@common/constant/PROJECT_URL";
import {PATHS} from "../../../../@common/constant/PATHS";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    },
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FAQ-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
