<style scoped lang="scss">

@import "../../scss/color";

.MobileApp {
  background-color: $primaryLight;
}

.MobileApp-desktop.MobileApp {
  height: 466px;
  margin-top: 76px;
}

.MobileApp-tablet.MobileApp {
}

.MobileApp-mobile.MobileApp {

}

.MobileApp-body {
  display: flex;
  height: 100%;
}

.MobileApp-desktop .MobileApp-body {
  align-items: center;
  justify-content: center;
}

.MobileApp-tablet .MobileApp-body {
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-start;
}

.MobileApp-mobile .MobileApp-body {
  flex-direction: column-reverse;
  align-items: center;
  justify-content: flex-start;
}

.MobileApp--tag {

  transform: translateX(300px);
  opacity: 0;

  transition: transform 1000ms, opacity 1000ms;
}

.MobileApp-tablet .MobileApp--tag {
  display: none;
}

.MobileApp-mobile .MobileApp--tag {
  display: none;
}

.MobileApp ::v-deep .Visible-isVisible .MobileApp--tag {
  transform: translateX(40px);
  opacity: 1;
}

.MobileApp--phone {
  overflow: hidden;
  position: relative;
}

.MobileApp-desktop .MobileApp--phone {
  height: calc(100% + 76px);
  top: -38px;
  min-width: 304px;
}

.MobileApp-tablet .MobileApp--phone {
  height: 290px;
  padding-top: 50px;
}

.MobileApp-mobile .MobileApp--phone {
  height: 290px;
  padding-top: 50px;
  padding-left: 25px;
  padding-right: 25px;
  width: 100%;
  box-sizing: border-box;
  text-align: center;
}

.MobileApp--phone img {
  display: block;
  transform: translateY(200px);
  transition: transform 1000ms;
}

.MobileApp-mobile .MobileApp--phone img {
  width: 100%;
  max-width: 304px;
  display: inline-block;
}

.MobileApp ::v-deep .Visible-isVisible .MobileApp--phone img {
  transform: translateY(0px);
}

.MobileApp--info {
}

.MobileApp-desktop .MobileApp--info {
  padding-left: 50px;
}

.MobileApp-tablet .MobileApp--info {
  text-align: center;
}

.MobileApp-mobile .MobileApp--info {
  text-align: center;
}

.MobileApp--titles {
  transform: translateY(-50px);
  opacity: 0;
  transition: transform 1000ms, opacity 1000ms;
}

.MobileApp-tablet .MobileApp--titles {
  padding-top: 100px;
}

.MobileApp-mobile .MobileApp--titles {
  padding-top: 50px;
  padding-left: 25px;
  padding-right: 25px;
}

.MobileApp ::v-deep .Visible-isVisible .MobileApp--titles {
  transform: translateY(0px);
  opacity: 1;
}

.MobileApp--title {
  font-family: 'AmbroiseRegular', sans-serif;
  color: $primary;
}

.MobileApp-desktop .MobileApp--title {
  font-size: 40px;
}

.MobileApp-tablet .MobileApp--title {
  font-size: 40px;
}

.MobileApp-mobile .MobileApp--title {
  font-size: 30px;
}

.MobileApp--descriptions {
  padding-top: 30px;
  transform: translateY(100px);
  opacity: 0;
  transition: transform 1000ms, opacity 1000ms;
}

.MobileApp-desktop .MobileApp--descriptions {
  padding-bottom: 110px;
}

.MobileApp-tablet .MobileApp--descriptions {
  padding-bottom: 50px;
}

.MobileApp-mobile .MobileApp--descriptions {
  padding-bottom: 50px;
  padding-left: 25px;
  padding-right: 25px;
}

.MobileApp ::v-deep .Visible-isVisible .MobileApp--descriptions {
  transform: translateY(0px);
  opacity: 1;
}

.MobileApp--description {
  font-family: 'AeonikMedium', sans-serif;
  font-size: 15px;
  letter-spacing: 0.25px;
  line-height: 28px;
  color: rgba(0, 0, 0, 0.75);
}

.MobileApp-desktop .MobileApp--description {
  font-size: 20px;
}

.MobileApp-mobile .MobileApp--description {
  font-size: 15px;
}

.MobileApp--download {
  display: flex;
  transform: translateY(50px);
  opacity: 0;
  transition: transform 1000ms, opacity 1000ms;
}

.MobileApp ::v-deep .Visible-isVisible .MobileApp--download {
  transform: translateY(0px);
  opacity: 1;
}

.MobileApp-tablet .MobileApp--download {
  justify-content: center;
}

.MobileApp-mobile .MobileApp--download {
  justify-content: center;
  flex-wrap: wrap;
}

.MobileApp--apple {
}

.MobileApp-desktop .MobileApp--apple {
  padding-right: 20px;
}

.MobileApp-tablet .MobileApp--apple {
  padding-right: 20px;
}

.MobileApp-mobile .MobileApp--apple {
  padding: 10px;
}

.MobileApp--apple a {
  display: block;
}

.MobileApp--apple img {
  display: block;
}

.MobileApp-mobile .MobileApp--apple img {
  height: 30px;
}

.MobileApp--android {
  position: relative;
  top: -2px;
}

.MobileApp-mobile .MobileApp--android {
  padding: 10px;
}

.MobileApp--android a {
  display: block;
}

.MobileApp--android img {
  display: block;
}

.MobileApp-mobile .MobileApp--android img {
  height: 32px;
}
</style>

<template>
  <div class="MobileApp" :class="class_root" :style="style_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <Visible>
        <div class="MobileApp-body">
          <div class="MobileApp--tag">
            <img src="../../../@common/assets/mobile-app/Logo.png" alt="Les Jardins Mobile Logo">
          </div>
          <div class="MobileApp--phone">
            <img src="../../../@common/assets/mobile-app/Phone.png" alt="Les Jardins Mobile Phone">
          </div>
          <div class="MobileApp--info">
            <div class="MobileApp--titles">
              <div class="MobileApp--title">
                <Term :term="lang.term(613)"></Term>
              </div>
              <div class="MobileApp--title">
                <Term :term="lang.term(614)"></Term>
              </div>
            </div>
            <div class="MobileApp--descriptions">
              <div class="MobileApp--description">
                <Term :term="lang.term(615)"></Term>
              </div>
              <div class="MobileApp--description">
                <Term :term="lang.term(616)"></Term>
              </div>
            </div>
            <div class="MobileApp--download">
              <div class="MobileApp--apple">
                <a href="https://apps.apple.com/fr/app/les-jardins-3d/id1528946540" target="_blank" rel="external">
                  <img src="../../../@common/assets/mobile-app/apple.png" alt="Les Jardins Download Apple">
                </a>
              </div>
              <div class="MobileApp--android">
                <a href="https://play.google.com/store/apps/details?id=com.innersense.osmose.android.lesjardins"
                   target="_blank" rel="external">
                  <img src="../../../@common/assets/mobile-app/android.png" alt="Les Jardins Download Android">
                </a>
              </div>
            </div>
          </div>
        </div>
      </Visible>
    </Responsive>
  </div>
</template>

<script>

// -----

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {eventService} from "../../service/eventService";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

// -----

export default {
  props: {
    backgroundColor: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              width: 0
            }),
            new BreakPoint({
              name: SCREEN.TABLET,
              width: 1024
            }),
            new BreakPoint({
              name: SCREEN.TABLET,
              width: 1440
            }),
          ],
          verticalBreakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              height: 0
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              height: 600
            }),
          ],
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      state: {},
      events: []
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`MobileApp-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`MobileApp-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`MobileApp-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`MobileApp-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`MobileApp-desktopY`)
      return classes
    },
    style_root() {
      const style = {}
      if (this.backgroundColor) style.backgroundColor = this.backgroundColor
      return style
    }
  },
  beforeMount() {
    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {}
}
</script>