"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Deposit = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Funding_1 = require("./Funding");
var Shop_1 = require("./Shop");
var Period_1 = require("./Period");
var Deposit = /** @class */ (function () {
    function Deposit(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.percentage = deltaService_1.deltaService.number(props.percentage, 0);
        this.timeLimit = deltaService_1.deltaService.number(props.timeLimit, 0);
        this.periods = deltaService_1.deltaService.array(props.periods, Period_1.Period);
        this.fundings = deltaService_1.deltaService.array(props.fundings, Funding_1.Funding);
        this.shops = deltaService_1.deltaService.array(props.shops, Shop_1.Shop);
    }
    return Deposit;
}());
exports.Deposit = Deposit;
