"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Device = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var User_1 = require("./User");
var Forum_1 = require("./Forum");
var Device = /** @class */ (function () {
    function Device(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.mac = deltaService_1.deltaService.string(props.mac, '');
        this.forums = deltaService_1.deltaService.array(props.forums, Forum_1.Forum);
        this.users = deltaService_1.deltaService.array(props.users, User_1.User);
    }
    return Device;
}());
exports.Device = Device;
