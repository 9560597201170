<style scoped>
.AuthTitle {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
}

.AuthTitle ::v-deep .Term h1 {
  font-family: 'AmbroiseRegular', sans-serif;
  font-size: 36px;
  padding: 0 16px;
}

.AuthTitle--barre {
  height: 2px;
  width: 20px;
  background: #000000;
  opacity: 0.5;
}
</style>

<template>
  <div class="AuthTitle">
    <div class="AuthTitle--barre"></div>
    <Term :term="term" tag="h1"/>
    <div class="AuthTitle--barre"></div>
  </div>
</template>

<script>

import {Term as TermDelta} from '../../../@common/delta/database/Term.ts'

export default {
  props: {
    term: {
      default: () => new TermDelta()
    }
  },
  data() {
    return {
      props: {},
      state: {},
    }
  },
  watch: {},
  computed: {},
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
