"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getArianeArticlePages = void 0;
var Page_1 = require("../delta/database/Page");
var helperUrl_1 = require("./helperUrl");
var PATHS_1 = require("../constant/PATHS");
var PROJECT_URL_1 = require("../constant/PROJECT_URL");
function getArianeArticlePages(nuxtProp, article) {
    var pages = [];
    var pageHome = helperUrl_1.getPageByPathIds(nuxtProp, [PATHS_1.PATHS.HOME]);
    var pageCatalog = helperUrl_1.getPageByPathIds(nuxtProp, [PATHS_1.PATHS.CATALOG]);
    pageHome = helperUrl_1.convertPathToUrl(pageHome);
    pageCatalog = helperUrl_1.convertPathToUrl(pageCatalog);
    pages.push(pageHome);
    pages.push(pageCatalog);
    // if this is not a batch we add the 0 category level to the ariane
    if (!article.isBatch) {
        for (var _i = 0, _a = article.batches; _i < _a.length; _i++) {
            var batch = _a[_i];
            for (var _b = 0, _c = batch.products; _b < _c.length; _b++) {
                var product = _c[_b];
                for (var _d = 0, _e = product.categories; _d < _e.length; _d++) {
                    var category = _e[_d];
                    if (category.level === 0) {
                        for (var _f = 0, _g = category.pages; _f < _g.length; _f++) {
                            var page = _g[_f];
                            var pageCategory = helperUrl_1.getPageByPathIds(nuxtProp, page.paths.map(function (path) { return path.id; }));
                            pageCategory = helperUrl_1.convertPathToUrl(pageCategory);
                            pages.push(pageCategory);
                        }
                    }
                }
            }
        }
    }
    // if this is a batch we add the promotion salon or no promotion
    else {
        pages.push(helperUrl_1.getPageByProjectUrlId(nuxtProp, PROJECT_URL_1.PROJECT_URL.MOBILIER_DE_JARDIN_PRODUITS_ENSEMBLE_SALON_DE_JARDIN));
    }
    // delete the uuid from terms
    var articlePages = article.pages.map(function (page) { return new Page_1.Page(page); });
    for (var _h = 0, articlePages_1 = articlePages; _h < articlePages_1.length; _h++) {
        var page = articlePages_1[_h];
        for (var _j = 0, _k = page.paths; _j < _k.length; _j++) {
            var path = _k[_j];
            path.terms.pop();
        }
        page = helperUrl_1.convertPathToUrl(page);
        pages.push(page);
    }
    // we st the pas in the right orders
    pages = pages.map(function (page) {
        page.urls = page.urls.map(function (url) {
            url.sorts = url.sorts.sort(function (a, b) { return a.order - b.order; });
            return url;
        });
        return page;
    });
    // -----
    return pages;
}
exports.getArianeArticlePages = getArianeArticlePages;
