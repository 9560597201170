<style scoped>
.ScrollParallax {
  width: 100%;
  height: 100%;
  transition: transform 1500ms ease;
}

.ScrollParallax-isTactile {
  transition: transform 0ms ease;
}
</style>

<template>
  <div class="ScrollParallax" :class="class_root" :style="style_root" ref="root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <Visible :onVisibilityChanged="onVisibilityChanged">
        <slot></slot>
      </Visible>
    </Responsive>
  </div>
</template>

<script>

// -----

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {eventService} from "../../service/eventService";
import {EVENT} from "../../../@common/constant/EVENT";
import {scrollerParentService} from "../../service/scrollerParentService";

// -----

export default {
  props: {
    distance: {
      type: Number,
      default: () => 200
    },
    debug: {
      type: Boolean,
      default: () => false
    },
    reverse: {
      type: Boolean,
      default: () => false
    },
    distanceOversize: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              width: 0
            }),
            new BreakPoint({
              name: SCREEN.TABLET,
              width: 1024
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              width: 1440
            }),
          ],
          verticalBreakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              height: 0
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              height: 600
            }),
          ],
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      state: {
        topPercentage: 0,
        topCurrent: 0,
        isVisible: false,
        isTactile: true,
        timeout: null, // only for not tactile
      },
      events: []
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.state.isTactile) classes.push(`ScrollParallax-isTactile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`ScrollParallax-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`ScrollParallax-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`ScrollParallax-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`ScrollParallax-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`ScrollParallax-desktopY`)
      return classes
    },
    style_root() {
      const style = {}
      let topPercentage = this.state.topPercentage
      if (topPercentage > 100 && !this.distanceOversize) topPercentage = 100
      if (topPercentage < 0 && !this.distanceOversize) topPercentage = 0

      if (!this.distanceOversize) topPercentage = this.distance - (topPercentage * this.distance / 100)

      if (this.debug) console.log({topPercentage})

      if (!this.reverse) style.transform = `translateY(${topPercentage}px)`
      if (this.reverse) style.transform = `translateY(-${topPercentage}px)`
      return style
    }
  },
  beforeMount() {

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.SCROLL_CHANGE,
      callback: ({top, left}) => this.onScroll({top, left}),
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.SIZE_CHANGED,
      callback: () => this.onScroll({
        top: scrollerParentService.scrollTop,
        left: scrollerParentService.scrollLeft
      }),
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    getTop() {
      if (!this.$refs.root) return 0
      return this.$refs.root.getBoundingClientRect().top
    },
    onScroll(props = {left: 0, top: 0}) {
      if (!this.state.isVisible) return
      if (this.debug) console.log(JSON.stringify(this.state))

      this.state.isTactile = scrollerParentService.isTactile

      const action = () => {
        this.state.topCurrent = this.getTop()
        this.state.topPercentage = 100 - (this.state.topCurrent * 100 / window.innerHeight)
      }

      if (this.state.isTactile) {
        action()
      } else {
        if (this.state.timeout) clearTimeout(this.state.timeout)
        this.state.timeout = setTimeout(() => {
          action()
        }, 0)
      }
    },
    onVisibilityChanged({isVisible}) {
      this.state.isVisible = isVisible
    },
  }
}
</script>