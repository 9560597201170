<style scoped>
.FlagLang {

}

.FlagLang--loader {
  display: flex;
  justify-content: center;
}

.FlagLang--lang {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.FlagLang--lang ::v-deep .Vector svg {
  width: 18px;
}

.FlagLang-white .FlagLang--lang ::v-deep .Vector svg {
  fill: rgba(255, 255, 255, 0.5);
}

.FlagLang-dark .FlagLang--lang ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 0.5);
}

.FlagLang--country {
  font-family: 'RalewayBold', sans-serif;
  font-size: 14px;
  text-transform: uppercase;
  padding-right: 16px;
}

.FlagLang-white .FlagLang--country {
  color: rgba(255, 255, 255, 0.75);
}

.FlagLang-dark .FlagLang--country {
  color: rgba(0, 0, 0, 0.75);
}

.FlagLang--flag {
  padding-right: 10px;
  position: relative;
  top: 2px;
}

.FlagLang--langIcon {
  width: 24px;
  position: relative;
  top: 2px;
}

.FlagLang-white .FlagLang--langIcon ::v-deep .Vector svg {
  fill: rgba(255, 255, 255, 0.75);
}

.FlagLang-dark .FlagLang--langIcon ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 0.75);
}
</style>

<template>
  <div class="FlagLang" :class="class_root">
    <Popover position="top" :opened="state.opened" :onOpenedChange="v => state.opened = v">
      <template slot="default">
        <div class="FlagLang--loader">
          <Loader v-if="loading"></Loader>
        </div>
        <template v-if="!loading" v-for="lang of state.langs">
          <List v-if="withHref" :svg="getFlag(lang)" :text="lang.name" :onClick="() => _onChangeLang(lang)" :href="getHref(lang.iso6391)"/>
          <List v-else :svg="getFlag(lang)" :text="lang.name" :onClick="() => _onChangeLang(lang)"/>
        </template>
      </template>
      <template slot="reference">
        <div class="FlagLang--lang">
          <div class="FlagLang--country">{{ lang.iso6391 }}</div>
          <div class="FlagLang--flag">
            <Tooltip :text="langService.translate(308)">
              <Vector :svg="getFlag(lang)"></Vector>
            </Tooltip>
          </div>
          <div class="FlagLang--langIcon">
            <Vector :svg="vector.expand_more"></Vector>
          </div>
        </div>
      </template>
    </Popover>
  </div>
</template>

<script>

import {Lang as LangDelta} from "../../../@common/delta/database/Lang";
import {THEME} from "../../../@common/constant/THEME";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {extractUrlInfoFromPage} from "../../../@common/function/helperUrl";
import {Page} from "../../../@common/delta/database/Page";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";

export default {

  props: {
    lang: {
      default: () => new LangDelta()
    },
    onChangeLang: {
      type: Function,
      default: () => (lang) => {
      }
    },
    langs: {
      type: Array,
      default: () => ([])
    },
    theme: {
      type: String,
      default: () => THEME.LIGHT
    },
    withHref: {
      type: Boolean,
      default: () => true
    },
    page: {
      type: Page,
      default: () => new Page()
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    opened: {
      type: Boolean,
      default: () => false
    },
    onOpenedChange: {
      type: Function,
      default: () => (opened) => {
      }
    },
  },
  data() {
    return {
      langService: getLangServiceBrowser(),
      vector: {
        expand_more: require('@bbx/vector~master/core/assets/svg/material/expand_more.svg'),
      },
      props: {},
      state: {
        langIcons: {
          1: require('../../../@common/assets/flag/001-france.svg'),
          2: require('../../../@common/assets/flag/002-united-states.svg'),
        },
        lang: this.lang,
        langs: this.langs,
        opened: this.opened
      },
    }
  },
  watch: {
    'lang': function (v) {
      this.state.lang = v;
    },
    'state.lang': function (v) {
      this.onChangeLang(v)
    },
    'opened': {
      handler(v) {
        this.state.opened = v
      },
      deep: true
    },
    'state.opened': {
      handler(v) {
        this.onOpenedChange(v)
      },
      deep: true
    }
  },
  computed: {
    class_root() {
      const classes = [];
      if (this.theme === THEME.LIGHT) classes.push(`FlagLang-white`)
      if (this.theme === THEME.DARK) classes.push(`FlagLang-dark`)
      return classes;
    }
  },
  beforeMount() {

  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    /**
     * @type {lang} lang
     */
    _onChangeLang(lang) {
      this.state.lang = lang;
    },
    /**
     * @type {lang} lang
     */
    getFlag(lang) {
      return this.state.langIcons[lang.id] ? this.state.langIcons[lang.id] : '';
    },
    /**
     * @type {lang} lang
     */
    getHref(iso6391) {
      const urlInfo = extractUrlInfoFromPage(new NuxtProp(), this.page)
      return `/${urlInfo.canonical[iso6391]}`;
    }
  }
}
</script>
