"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setupEvent = void 0;
var eventService_1 = require("../service/eventService");
var ListenerRegister_1 = require("@bbx/listener~master/core/delta/ListenerRegister");
var EVENT_1 = require("../../@common/constant/EVENT");
var stateService_1 = require("../service/stateService");
var urlService_1 = require("../../@common/service/urlService");
var createDevice_1 = require("../function/createDevice");
var sendError_1 = require("../function/sendError");
var historyService_1 = require("../service/historyService");
var changeRoute_1 = require("../function/changeRoute");
var Article_1 = require("../../@common/delta/database/Article");
var getArticleStockBrowwser_1 = require("../function/getArticleStockBrowwser");
var displayMessage_1 = require("../function/displayMessage");
var Basket_1 = require("../../@common/delta/database/Basket");
var Delivery_1 = require("../../@common/delta/database/Delivery");
var Address_1 = require("../../@common/delta/database/Address");
var Street_1 = require("../../@common/delta/database/Street");
var ListenerTrigger_1 = require("@bbx/listener~master/core/delta/ListenerTrigger");
function setupEvent(config) {
    /**
     * * * * * * * * *
     *  LANG
     * * * * * * * * *
     */
    eventService_1.eventService.register(new ListenerRegister_1.ListenerRegister({
        name: EVENT_1.EVENT.CHANGE_LANG,
        callback: function (iso6391) { return stateService_1.stateService.set(stateService_1.STATE_LANG, iso6391); }
    }));
    /**
     * * * * * * * * *
     *  ROUTE
     * * * * * * * * *
     */
    eventService_1.eventService.register(new ListenerRegister_1.ListenerRegister({
        name: EVENT_1.EVENT.CHANGE_ROUTE,
        /**
         * @param {EventChangeRoute} props
         */
        callback: function (props) {
            props.raw = props.raw ? props.raw : urlService_1.urlService.getUrl(config.langServiceBrowser.lang.iso6391, props.pathIds, props.query);
            var currentRoute = "" + window.location.pathname + window.location.search;
            console.log('EVENT.CHANGE_ROUTE', currentRoute, '=>', props.raw, currentRoute !== props.raw);
            if (currentRoute !== props.raw)
                config.vueInstance.$router.push(props.raw);
        }
    }));
    /**
     * * * * * * * * *
     *  MAC ADRESSE
     * * * * * * * * *
     */
    eventService_1.eventService.register(new ListenerRegister_1.ListenerRegister({
        name: EVENT_1.EVENT.IS_CONNECTED,
        callback: function () {
            createDevice_1.createNewDevice(true).catch(sendError_1.sendError);
        }
    }));
    /**
     * * * * * * * * *
     *  HISTORY
     * * * * * * * * *
     */
    eventService_1.eventService.register(new ListenerRegister_1.ListenerRegister({
        name: EVENT_1.EVENT.ROUTE_QUERY_CHANGED,
        callback: function (nuxtUrl) {
            historyService_1.historyService.set(urlService_1.urlService.getUrlByNuxtUrl(nuxtUrl));
        }
    }));
    eventService_1.eventService.register(new ListenerRegister_1.ListenerRegister({
        name: EVENT_1.EVENT.TO_PREVIOUS_URL,
        callback: function () {
            var histories = __spreadArrays(historyService_1.historyService.get());
            histories.reverse();
            var lastPage = histories[0];
            var rightPage = '';
            for (var _i = 0, histories_1 = histories; _i < histories_1.length; _i++) {
                var history = histories_1[_i];
                if (history !== lastPage) {
                    rightPage = history;
                    break;
                }
            }
            if (rightPage)
                changeRoute_1.changeRoute([], {}, rightPage);
        }
    }));
    /**
     * * * * * * * * *
     *  BASKET
     * * * * * * * * *
     */
    eventService_1.eventService.register(new ListenerRegister_1.ListenerRegister({
        name: EVENT_1.EVENT.ADD_BASKET,
        /**
         * @param {Object} props
         * @param {Article} props.article
         * @param {number} props.quantity
         */
        callback: function (props) {
            var article = new Article_1.Article(props.article);
            if (!getArticleStockBrowwser_1.getArticleStockBrowser({ article: article })) {
                return displayMessage_1.displayMessage({
                    vue: config.vueInstance,
                    type: 'error',
                    message: "Cet article n'est plus en stock"
                });
            }
            // -----
            var quantity = props.quantity;
            var allQuantity = quantity;
            var order = stateService_1.stateService.get(stateService_1.STATE_ORDER);
            var exist = false;
            for (var _i = 0, _a = order.baskets; _i < _a.length; _i++) {
                var basket = _a[_i];
                for (var _b = 0, _c = basket.articles; _b < _c.length; _b++) {
                    var article_ = _c[_b];
                    if (article_.id === article.id) {
                        exist = true;
                        if (basket.quantity >= getArticleStockBrowwser_1.getArticleStockBrowser({ article: article })) {
                            return displayMessage_1.displayMessage({
                                vue: config.vueInstance,
                                type: 'error',
                                message: "Vous avez atteint le nombre maximum de stock pour cette article"
                            });
                        }
                        basket.quantity += quantity;
                        allQuantity = basket.quantity;
                        break;
                    }
                }
            }
            // -----
            if (!exist) {
                order.baskets.push(new Basket_1.Basket({
                    quantity: quantity,
                    articles: [
                        article
                    ]
                }));
                order.deliveries = [
                    new Delivery_1.Delivery({
                        addresses: [
                            new Address_1.Address({
                                streets: [
                                    new Street_1.Street(),
                                    new Street_1.Street(),
                                ],
                            })
                        ]
                    })
                ];
            }
            // -----
            stateService_1.stateService.set(stateService_1.STATE_ORDER, order);
            displayMessage_1.displayMessage({
                vue: config.vueInstance,
                type: 'success',
                message: config.langServiceBrowser.translate(166)
            });
            eventService_1.eventService.triggerByName(new ListenerTrigger_1.ListenerTrigger({
                name: EVENT_1.EVENT.ARTICLE_WAS_ADDED_TO_BASKET,
                payload: {
                    article: article,
                    quantity: allQuantity,
                }
            }));
        }
    }));
    /**
     * * * * * * * * *
     *  ERROR
     *  Catch any js front error and send them to the api
     * * * * * * * * *
     */
    window.addEventListener('error', function (err) {
        sendError_1.sendError(err.error, err.message);
    });
    eventService_1.eventService.register(new ListenerRegister_1.ListenerRegister({
        name: EVENT_1.EVENT.ERROR,
        callback: sendError_1.sendError
    }));
}
exports.setupEvent = setupEvent;
