"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.COLLECTION_SEARCH_ID = exports.COLLECTION = void 0;
exports.COLLECTION = {
    JET_STREAM: 1,
    BEAUTY: 2,
    ELISA: 3,
    DUNA: 4,
    JEPARA: 5,
    PARALLELE: 6,
    KOTON: 7,
    CURVILIS: 8,
    POL: 9,
    BRITON: 10,
    SELVA: 11,
    CERISE: 12,
    VALONGO: 13,
    FILACIEL: 14,
    TEKURA: 15,
    SKAAL: 16,
    POLOFA: 17,
    AMAKA: 18,
    HEGOA: 19,
    VALTECK: 20,
    RIO: 21,
    NARDI: 22,
    DRIPPER: 23,
    XENAH: 24,
    TWIG: 25,
    KENEAH: 26,
    DRAKAR: 27,
    ENTRETIEN: 28,
    THEMIS: 29,
    OSIRIS: 30,
    FLEX: 31,
    OSMOZ: 32,
    BUMP: 33,
    TRADITION: 34,
    TECKA: 35,
    TECKALU: 36,
    TINKA: 37,
    DOUCHES: 38,
    PARASOL: 39,
    BISTRO: 40,
    BASTINGAGE: 41,
    ZAYO: 42,
    TECKINOX: 43,
    TICAO: 44,
    TULIP: 45,
    COPENHAGUE: 46,
    YOLO: 47,
    SAGAMORE: 48,
    METRO: 49,
    BLADE: 50,
    CORNER: 51,
    BALCON: 52,
    MONA_TERRA: 53,
    PASTEK: 54,
    ECORCE: 55,
    PATIO: 56,
    TECK: 57,
    STANDY: 58,
};
exports.COLLECTION_SEARCH_ID = {
    ID: 1,
    VALUE: 2,
};
