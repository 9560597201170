"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ButtonProp = void 0;
var Term_1 = require("../../../@common/delta/database/Term");
exports.ButtonProp = {
    loading: {
        type: Boolean,
        default: function () { return false; }
    },
    disabled: {
        type: Boolean,
        default: function () { return false; }
    },
    svg: {
        type: String,
        default: function () { return ''; }
    },
    term: {
        default: function () { return new Term_1.Term(); }
    },
    onClick: {
        type: Function,
        default: function () { return function () {
        }; }
    },
    minWidth: {
        type: Boolean,
        default: function () { return true; }
    },
};
