"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculatePromotion = void 0;
var getDecimal_1 = require("./getDecimal");
function calculatePromotion(value, rate) {
    if (rate === void 0) { rate = 0; }
    value = value - (value * rate / 100);
    return getDecimal_1.getDecimal(value);
}
exports.calculatePromotion = calculatePromotion;
