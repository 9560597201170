import {deltaService} from "@bbx/delta~master/core/service/deltaService"
import {Term} from "./Term";
import {Page} from "./Page";
import {Product} from "./Product";
import {DeepPartial} from "../../type/DeepPartial";


export type Category_constructor_props = DeepPartial<Category>

export class  Category {

    id: number
    active: boolean
    createdAt: string
    updatedAt: string

    level: number

    terms: Term[]
    categoryChildren: Category[]
    categoryParents: Category[]
    pages: Page[]
    products: Product[]

    constructor(props: Category_constructor_props = {}) {
                this.id = deltaService.number(props.id, 0)
        this.active = deltaService.boolean(props.active, true)
        this.createdAt = deltaService.string(props.createdAt, '')
        this.updatedAt = deltaService.string(props.updatedAt, '')

        this.level = deltaService.number(props.level, 0)

        this.terms = deltaService.array(props.terms, Term)
        this.categoryChildren = deltaService.array(props.categoryChildren, Category)
        this.categoryParents = deltaService.array(props.categoryParents, Category)
        this.pages = deltaService.array(props.pages, Page)
        this.products = deltaService.array(props.products, Product)
    }
}
