"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateHT = void 0;
var getDecimal_1 = require("./getDecimal");
var TVA_1 = require("../constant/TVA");
function calculateHT(value, rate) {
    if (rate === void 0) { rate = TVA_1.TVA.NORMAL; }
    value = value / (1 + (rate / 100));
    return getDecimal_1.getDecimal(value);
}
exports.calculateHT = calculateHT;
