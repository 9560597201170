<style scoped>
.Term {
  display: inline-block;
}

.Term--text > h1, .Term--text > h2, .Term--text > h3 {
  font-size: inherit;
  font-weight: inherit;
  margin: 0;
}

.Term ::v-deep .Term--text ul {
  margin: 0em 1em;
  padding: 0em 1em;
}

.Term ::v-deep .Term--text li {
  list-style: initial;
}

.Term--text {
  display: inline-block;
  transition: box-shadow 300ms;
  box-shadow: inset 0px 0px 0px 0px red;
}

.Term-activated:hover .Term--text {
  box-shadow: inset 0px 0px 0px 1px red;
}
</style>

<template>
  <div class="Term" :data-term-id="term.id" :class="class_root">
    <div class="Term--text" v-for="translation in state.term.translations" :key="translation.id" v-if="selectLang(translation) && type === 'text'" @contextmenu="onOption($event)" @click="onOption($event)">
      <p v-if="tag === 'p'">{{ translation.value }}</p>
      <h1 v-if="tag === 'h1'">{{ translation.value }}</h1>
      <h2 v-if="tag === 'h2'">{{ translation.value }}</h2>
      <h3 v-if="tag === 'h3'">{{ translation.value }}</h3>
    </div>
    <div class="Term--text" v-for="translation in state.term.translations" :key="translation.id" v-if="selectLang(translation) && type === 'textarea'" @contextmenu="onOption($event)" @click="onOption($event)" v-html="translation.value">
    </div>
  </div>
</template>

<script>

import {ListenerRegister} from '@bbx/listener~master/core/delta/ListenerRegister.ts'
import {ListenerTrigger} from '@bbx/listener~master/core/delta/ListenerTrigger.ts'

import {Term} from '../../../@common/delta/database/Term.ts';
import {Translation} from '../../../@common/delta/database/Translation.ts';
import {Lang} from '../../../@common/delta/database/Lang.ts';

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser.ts";

import {eventService} from '../../service/eventService'
import {EVENT} from "../../../@common/constant/EVENT";
import {apiClient} from "../../service/apiClientService";
import selectTerms from "../../../@common/api/term/selectTerms";
import {sendError} from "../../function/sendError";

export default {
  props: {
    term: {
      default: () => new Term()
    },
    type: {
      type: String,
      default: () => 'text' // 'text'|'textarea'
    },
    tag: {
      type: String,
      default: () => 'p'
    },
    fullOption: {
      type: Boolean,
      default: () => false
    }
  },

  data() {
    return {
      langServiceBrowser: getLangServiceBrowser(),
      state: {
        /**
         * @type boolean
         */
        activated: false,
        /**
         * @type Term
         */
        term: new Term(),
        /**
         * @type Lang
         */
        lang: new Lang(),
        /**
         * @type Lang[]
         */
        langs: [],
      },
      events: []
    }
  },

  computed: {
    class_root() {
      const classes = [];
      if (this.state.activated) classes.push(`Term-activated`)
      return classes;
    }
  },

  beforeMount() {

    // -----

    this.setTerm(this.term)
    this.setLang()
    this.setLangs()

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.TERM_ACTIVATED,
      callback: (activated) => {
        this.state.activated = activated;
      }
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.TERM_UPDATED,
      callback: (props) => {
        this.onTermUpdated(props).catch(sendError)
      }
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    /**
     *
     * @param {Term} term
     */
    setTerm(term) {
      this.state.term = this.langServiceBrowser.fillEmptyTerm(new Term(term))
    },

    setLang() {
      this.state.lang = new Lang(this.langServiceBrowser.lang)
    },

    setLangs() {
      this.state.langs = this.langServiceBrowser.langs.map(lang => new Lang(lang))
    },

    /**
     * @param {Object} props
     * @param {number} props.termId
     */
    async onTermUpdated(props) {
      if (props.termId !== this.term.id) return
      const term = await this.selectTerm(props.termId)
      this.setTerm(term)
    },

    /**
     *
     * @param {number} termId
     * @returns {Promise<Term>}
     */
    async selectTerm(termId) {
      const {data} = await apiClient.do(
          selectTerms,
          selectTerms.with({
            $ids: [termId],
            $scope: 1
          })
      );
      return new Term(data[0])
    },

    /**
     * @param {Translation} translation
     * @returns {boolean}
     */
    selectLang(translation) {
      for (const lang of translation.langs) {
        if (lang.iso6391 === this.state.lang.iso6391) return true
      }
      return false
    },

    onOption(e) {
      if (!this.state.activated || this.term.id < 1) return;
      e.preventDefault();
      eventService.triggerByName(new ListenerTrigger({
        name: EVENT.TERM_MODAL_OPENED,
        payload: {
          termId: this.term.id,
          type: this.type,
          lang: this.state.lang,
          fullOption: this.fullOption,
        }
      }))
    },
  }
}
</script>
