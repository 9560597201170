<style scoped>
.ScrollFixe {
  width: 100%;
  height: 100%;
  position: relative;
  display: flex;
  flex-direction: column;
}

.ScrollFixe--debug {
  position: fixed;
  top: 0;
  left: 0;
  background: white;
  z-index: 99999;
}

.ScrollFixe--start {
  height: 0px;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.ScrollFixe--centerGhost {
}

.ScrollFixe--center {
  position: relative;
  bottom: 0;
}

.ScrollFixe--endRest {
  flex: 1;
}

.ScrollFixe--end {
  height: 0px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
}
</style>

<template>
  <div class="ScrollFixe" ref="root">
    <div class="ScrollFixe--debug" v-if="debug">{{ state }}</div>
    <div class="ScrollFixe--start">
      <Visible :registerCallback="registerCallback"
               :onVisibilityChanged="onVisibilityChangedStart">
      </Visible>
    </div>
    <!--   usefull for determinate the endRest size -->
    <div class="ScrollFixe--centerGhost" ref="centerGhost" :style="style_centerGhost">
    </div>
    <div class="ScrollFixe--center" ref="center" :style="style_center">
      <slot></slot>
    </div>
    <div class="ScrollFixe--endRest">
      <Visible :registerCallback="registerCallback"
               :onVisibilityChanged="onVisibilityChangedEndRest">
      </Visible>
    </div>
    <div class="ScrollFixe--end">
      <Visible :registerCallback="registerCallback"
               :onVisibilityChanged="onVisibilityChangedEnd">
      </Visible>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    debug: {
      type: Boolean,
      default: () => false
    },
    registerCallback: {
      type: Function,
      default: () => ({onScroll, onResize}) => {
      }
    },
  },
  data() {
    return {
      state: {
        startIsVisible: false,
        startIsVisibleFully: false,

        endIsVisible: false,
        endIsVisibleFully: false,

        endRestIsVisible: false,
        endRestIsVisibleFully: false,

        topRoot: 0,
        heightRoot: 0,

        topCenter: 0,
        heightCenter: 0,
        widthCenter: 0,

        scrollTop: 0,
        timeOut: null,
      },
      events: []
    }
  },
  beforeMount() {
    this.registerCallback({
      onScroll: props => this.onScroll(props),
      onResize: props => this.onResize(props),
    })
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
  },
  computed: {
    style_center() {
      const style = {}
      if (this.state.endRestIsVisible) style.position = `fixed`
      if (this.state.endIsVisibleFully) {
        style.position = `absolute`
        style.bottom = 0
      }
      if (this.state.widthCenter) style.width = `${this.state.widthCenter}px`
      return style
    },
    style_centerGhost() {
      const style = {}
      if (!this.state.endRestIsVisible) style.display = `none`
      if (this.state.widthCenter) style.width = `${this.state.widthCenter}px`
      if (this.state.heightCenter) style.height = `${this.state.heightCenter}px`
      return style
    },
  },
  methods: {
    init() {
      this.reset()
      this.setSize()
    },
    reset() {
      this.state.startIsVisible = false
      this.state.startIsVisibleFully = false
      this.state.endIsVisible = false
      this.state.endIsVisibleFully = false
      this.state.endRestIsVisible = false
      this.state.endRestIsVisibleFully = false
      this.state.topRoot = 0
      this.state.heightRoot = 0
      this.state.topCenter = 0
      this.state.heightCenter = 0
      this.state.widthCenter = 0
      this.state.scrollTop = 0
      this.state.timeOut = null
    },
    onVisibilityChangedStart({isVisible, isVisibleFully}) {
      this.state.startIsVisible = isVisible
      this.state.startIsVisibleFully = isVisibleFully
    },
    onVisibilityChangedEnd({isVisible, isVisibleFully}) {
      this.state.endIsVisible = isVisible
      this.state.endIsVisibleFully = isVisibleFully
    },
    onVisibilityChangedEndRest({isVisible, isVisibleFully}) {
      this.state.endRestIsVisible = isVisible
      this.state.endRestIsVisibleFully = isVisibleFully
    },

    onScroll({top} = {top: 0}) {
      this.setScroll({top})
      this.setSize() // we reset topCenter
    },
    onResize() {
      this.setSize()
    },

    setScroll({top} = {top: 0}) {
      this.state.scrollTop = top
    },
    setSize() {
      if (!this.$refs.root) return
      if (!this.$refs.center) return

      const rectRoot = this.$refs.root.getBoundingClientRect()
      const rectCenter = this.$refs.center.getBoundingClientRect()

      this.state.topRoot = rectRoot.top
      this.state.heightCenter = rectCenter.height
      this.state.widthCenter = rectRoot.width
      this.state.topCenter = (window.innerHeight - this.state.topRoot - this.state.heightCenter) + this.state.topRoot
    },
  }
}
</script>