"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SittingPlace = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Product_1 = require("./Product");
var SittingPlace = /** @class */ (function () {
    function SittingPlace(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.number = deltaService_1.deltaService.number(props.number, 0);
        this.products = deltaService_1.deltaService.array(props.products, Product_1.Product);
    }
    return SittingPlace;
}());
exports.SittingPlace = SittingPlace;
