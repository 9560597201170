<style scoped>
.AccountHeader {
}

.AccountHeader--content {
  display: flex;
  justify-content: space-between;
}

.AccountHeader--content ::v-deep .ButtonLink {
  min-width: 200px;
}

.AccountHeader-desktop .AccountHeader--content {
  flex-direction: row;
}

.AccountHeader-tablet .AccountHeader--content {
  flex-direction: row;
}

.AccountHeader-mobile .AccountHeader--content {
  flex-direction: column;
}

.AccountHeader--hello {
  display: flex;
  align-items: center;
}

.AccountHeader-mobile .AccountHeader--hello {
  flex-direction: column;
}

.AccountHeader--title {
  font-family: 'AmbroiseRegular', sans-serif;
  font-size: 40px;
  position: relative;
  top: -5px;
}

.AccountHeader-mobile .AccountHeader--title {
  padding-bottom: 25px;
}

.AccountHeader--bubble {
  width: 5px;
  height: 5px;
  background: rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  margin: 0 25px;
}

.AccountHeader-mobile .AccountHeader--bubble {
  display: none;
}

.AccountHeader--firstNameContent {
  display: flex;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
}

.AccountHeader-mobile .AccountHeader--firstNameContent {
  padding-bottom: 25px;
}

.AccountHeader--firstName {
  padding-left: 5px;
  text-transform: capitalize;
}
</style>

<template>
  <div class="AccountHeader" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="AccountHeader--content">
        <div class="AccountHeader--hello">
          <div class="AccountHeader--title">
            <Term :term="lang.term(266)"/>
          </div>
          <div class="AccountHeader--bubble"></div>
          <div class="AccountHeader--firstNameContent">
            <Term :term="lang.term(267)"/>
            <div class="AccountHeader--firstName">{{ state.user.firstName }}</div>
          </div>
        </div>
        <ButtonLink :term="lang.term(268)" :onClick="() => disconnect()" :svg="icon.exit_to_app"></ButtonLink>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {disconnect} from "../../function/disconnect";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {STATE_ROLE_ID, STATE_USER_FIRST_NAME, STATE_USER_LAST_NAME, stateService} from "../../service/stateService";
import {User} from "../../../@common/delta/database/User";

export default {
  props: {},
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 920
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        exit_to_app: require('@bbx/vector~master/core/assets/svg/material/exit_to_app.svg'),
      },
      state: {
        user: new User(),

      },
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`AccountHeader-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`AccountHeader-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`AccountHeader-desktop`)
      return classes
    }
  },
  beforeMount() {

    this.state.user.lastName = stateService.get(STATE_USER_LAST_NAME)
    this.state.user.firstName = stateService.get(STATE_USER_FIRST_NAME)

    stateService.watch(STATE_USER_LAST_NAME, v => this.state.user.lastName = v)
    stateService.watch(STATE_USER_FIRST_NAME, v => this.state.user.firstName = v)
  },
  mounted() {

  },
  beforeDestroy() {
  },
  methods: {

    disconnect() {
      disconnect()
    }
  }
}
</script>
