<style scoped lang="scss">

@import "../../scss/color";

.Catalogue {
}

.Catalogue-mobile ::v-deep .CatalogueArticle--footerLeft {
  align-items: center;
  text-align: center;
}

.Catalogue-mobile ::v-deep .CatalogueArticle--footerRight {
  display: none;
}

.Catalogue--header {
  background: $primary;
  /*height: 100px;*/
}

.Catalogue--body {
  display: flex;
}

.Catalogue-desktop .Catalogue--body {
  padding: 12.5px 12.5px 150px 12.5px;
}

.Catalogue-tablet .Catalogue--body {
  padding: 12.5px 12.5px 25px 12.5px;
}

.Catalogue-mobile .Catalogue--body {
  padding: 12.5px;
}

.Catalogue--bodyFilter {
}

.Catalogue-desktop .Catalogue--bodyFilter {
  min-width: 400px;
  width: 25%;
  max-width: 25%;
}

.Catalogue-tablet .Catalogue--bodyFilter {
  min-width: 400px;
  width: 25%;
  max-width: 25%;
}

.Catalogue-mobile .Catalogue--bodyFilter {
}

.Catalogue--bodyFilterContent {
  box-sizing: border-box;
  width: 100%;
  height: 100%;
}

.Catalogue-desktop .Catalogue--bodyFilter {
  padding: 12.5px;
}

.Catalogue-tablet .Catalogue--bodyFilter {
  padding: 12.5px;
}

.Catalogue-mobile .Catalogue--bodyFilter {
  padding: 0px;
}

.Catalogue--articles {
  flex: 1;
}

.Catalogue--loader {
  height: 248px;
  /*border: solid 1px rgba(0, 0, 0, 0.1);*/
  /*margin: 12.5px;*/
}

.Catalogue--pagination {
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
}

.Catalogue-desktop .Catalogue--pagination {
  padding-top: 50px;
  justify-content: center;
}

.Catalogue-tablet .Catalogue--pagination {
  padding-top: 50px;
  justify-content: center;
}

.Catalogue-mobile .Catalogue--pagination {
  justify-content: center;
  padding-bottom: 12.5px;
}

.Catalogue--pagination ::v-deep .page-selected a {
  background: $primary;
}

/**/

.Catalogue--story {
  display: flex;
  display: flex;
  justify-content: center;
  text-align: center;
}

.Catalogue-desktop .Catalogue--story {
  padding: 50px 100px 0 100px;
}

.Catalogue-tablet .Catalogue--story {
  padding: 25px 50px 0 50px;
}

.Catalogue-mobile .Catalogue--story {
  padding: 0px 25px 50px 25px;
}
</style>

<template>
  <div class="Catalogue" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <CatalogueCollection v-if="isCollection && isFirstPage"
                           :termShowMore="lang.term(491)"
                           :withBackground="false"
                           :collection="collection">
      </CatalogueCollection>
      <div class="Catalogue--header">
        <CatalogueHeader :selectedSorts="state.selectedSorts"
                         :sorts="sorts"
                         :nuxtProp="nuxtProp"
                         :articleCount="state.articleCount"
                         :display="state.display"
                         :onClickFilter="onClickFilter"
                         :onDisplayChange="onDisplayChange"
                         :onChangeSorts="onChangeSorts">
        </CatalogueHeader>
      </div>
      <div class="Catalogue--body">
        <div class="Catalogue--bodyFilter">
          <div class="Catalogue--bodyFilterContent">
            <template v-if="props.Responsive['0'].breakPoint.name === SCREEN.MOBILE">
              <Modal :opened="state.openedFilter" :on-opened-change="v => state.openedFilter = v">
                <CatalogueFilter :nuxtProp="nuxtProp"/>
              </Modal>
            </template>
            <template v-else>
              <CatalogueFilter :nuxtProp="nuxtProp"/>
            </template>
          </div>
        </div>
        <div class="Catalogue--articles">
          <div class="Catalogue--loader" v-if="nuxtProp.loading">
            <LoaderSpace></LoaderSpace>
          </div>
          <template v-else>
            <CatalogueArticles
                :nuxtProp="nuxtProp"
                :display="displaySize"
                :articles="state.articles"
                :catalogType="state.catalogType">
            </CatalogueArticles>
          </template>
          <div class="Catalogue--pagination">
            <PaginationLink :total="state.articleCount"
                            :offset="state.articleOffset"
                            :limit="state.articleLimit"
                            :range="range"
                            :onGeneratedLink="onGeneratedLink">
            </PaginationLink>
          </div>
          <div class="Catalogue--story">
            <CatalogueStory :pageId="nuxtProp.page.id"></CatalogueStory>
          </div>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>

// -----

import {deltaService} from "@bbx/delta~master/core/service/deltaService";
import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";

// -----

import {stateService, STATE_CATALOG_DISPLAY} from "../../service/stateService";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {urlService} from "../../../@common/service/urlService";
import {eventService} from "../../service/eventService";

// -----

import {Article} from "../../../@common/delta/database/Article";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {NuxtUrl} from "../../../@common/delta/nuxt/NuxtUrl";

// -----

import {EVENT} from "../../../@common/constant/EVENT";
import {SORT} from "../../../@common/constant/SORT";
import {FILTER_OPTION} from "../../../@common/constant/FILTER_OPTION";
import {FILTER_DISPLAY} from "../../../@common/constant/FILTER_DISPLAY";

// -----

import {getQueryValueByName, addPathQuery, convertPathToUrl} from "../../../@common/function/helperUrl";
import {changeRoute} from "../../function/changeRoute";
import {Page} from "../../../@common/delta/database/Page";
import {Url} from "../../../@common/delta/database/Url";
import {canDisplay, getCountry} from "../../service/countryService";
import {Collection} from "../../../@common/delta/database/Collection";

// -----

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    const lang = getLangServiceBrowser()
    return {
      SCREEN,
      lang,
      props: {
        Responsive: {
          '0': {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 920
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {
        country: '',
        loadingArticle: false,
        loadingCategory: false,
        loadingProjectUrl: false,
        openedFilter: false,
        selectedSorts: [
          {
            id: getQueryValueByName(this.nuxtProp, FILTER_OPTION.SORT, SORT.NEW_DESC),
            value: lang.translate(218)
          }
        ],
        display: FILTER_DISPLAY.BIG,
        articleCount: deltaService.number(this.nuxtProp.payload.articleCount, 0),
        articleLimit: deltaService.number(this.nuxtProp.payload.articleLimit, 0),
        catalogType: deltaService.number(this.nuxtProp.payload.catalogType, 0),
        articleOffset: deltaService.number(this.nuxtProp.payload.articleOffset, 0),
        /**
         * @type {Article[]}
         */
        articles: deltaService.array(this.nuxtProp.payload.articles, Article),
      },
      events: []
    }
  },
  watch: {
    'nuxtProp.url': {
      handler: function () {
        const value = this.lang.translate(218)
        const id = getQueryValueByName(this.nuxtProp, FILTER_OPTION.SORT, SORT.NEW_DESC)
        this.state.selectedSorts = [
          {
            id,
            value
          }
        ]
      },
      deep: true
    },
    'nuxtProp.payload': {
      handler: function () {
        this.state.articleCount = deltaService.number(this.nuxtProp.payload.articleCount, 0)
        this.state.articleLimit = deltaService.number(this.nuxtProp.payload.articleLimit, 0)
        this.state.articleOffset = deltaService.number(this.nuxtProp.payload.articleOffset, 0)
        this.state.catalogType = deltaService.number(this.nuxtProp.payload.catalogType, 0)
        this.state.articles = deltaService.array(this.nuxtProp.payload.articles, Article)
      },
      deep: true
    }
  },
  computed: {
    sorts() {

      let sorts = []

      sorts.push({
        id: SORT.NEW_DESC,
        value: this.lang.translate(218)
      })

      if (canDisplay(this.state.country)) {

        sorts.push({
          id: SORT.PRICE_ASC,
          value: this.lang.translate(219)
        })

        sorts.push({
          id: SORT.PRICE_DESC,
          value: this.lang.translate(220)
        })

      }

      return sorts
    },
    range() {
      return this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE ? 3 : 10
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`Catalogue-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`Catalogue-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`Catalogue-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`Catalogue-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`Catalogue-desktopY`)
      return classes
    },
    isCollection() {
      return !!this.nuxtProp.page.collections.length
    },
    isFirstPage() {
      let hasQuery = this.nuxtProp.url.queries.some(query => query.name === FILTER_OPTION.PAGE)
      let isAfterFirstPage = this.nuxtProp.url.queries.some(query => query.name === FILTER_OPTION.PAGE && query.value > 1)
      return !hasQuery && !isAfterFirstPage
    },
    collection() {
      return new Collection(this.nuxtProp.page.collections[0])
    },
    displaySize() {
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) return FILTER_DISPLAY.SMALL
      return this.state.display
    }
  },
  beforeMount() {
    const display = stateService.get(STATE_CATALOG_DISPLAY)
    this.state.display = display ? display : FILTER_DISPLAY.BIG
    stateService.watch(STATE_CATALOG_DISPLAY, v => this.state.display = v)

    // -----

    this.state.country = getCountry()

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.CHANGE_COUNTRY_LOCATION,
      callback: (country) => {
        this.state.country = country
      }
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.ROUTE_QUERY_CHANGED,
      callback: (nuxtUrl) => {
        this.state.openedFilter = false
      }
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    onDisplayChange(v) {
      stateService.set(STATE_CATALOG_DISPLAY, v)
    },
    onChangeSorts(selectedSorts) {
      for (const selectedSort of selectedSorts) {
        if (selectedSort.id === this.state.selectedSorts[0].id) return;
        const nuxtUrl = new NuxtUrl(this.nuxtProp.url)
        addPathQuery(nuxtUrl.queries, FILTER_OPTION.SORT, selectedSort.id)
        const url = urlService.getUrlByNuxtUrl(nuxtUrl)
        changeRoute([], {}, url, nuxtUrl)
      }
    },
    onGeneratedLink(page) {
      const nuxtUrl = new NuxtUrl(this.nuxtProp.url)
      if (page === 1) {
        nuxtUrl.queries = nuxtUrl.queries.filter(query => query.name !== FILTER_OPTION.PAGE)
      } else {
        addPathQuery(nuxtUrl.queries, FILTER_OPTION.PAGE, page)
      }
      return urlService.getUrlByNuxtUrl(nuxtUrl)
    },
    onClickFilter() {
      this.state.openedFilter = true
    },
    /**
     * @param {Article} article
     */
    getHref(article) {
      const page = convertPathToUrl(new Page(article.pages[0]))
      return urlService.getProjectUrl(this.lang.lang.iso6391, new Url(page.urls[0]))
    },
  }
}
</script>
