"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.dispatchConnection = void 0;
var stateService_1 = require("../service/stateService");
var eventService_1 = require("../service/eventService");
var ListenerTrigger_1 = require("@bbx/listener~master/core/delta/ListenerTrigger");
var EVENT_1 = require("../../@common/constant/EVENT");
function dispatchConnection(auth) {
    stateService_1.stateService.set(stateService_1.STATE_TOKEN, auth.token);
    for (var _i = 0, _a = auth.users; _i < _a.length; _i++) {
        var user = _a[_i];
        stateService_1.stateService.set(stateService_1.STATE_USER_ID, user.id);
        stateService_1.stateService.set(stateService_1.STATE_USER_FIRST_NAME, user.firstName);
        stateService_1.stateService.set(stateService_1.STATE_USER_LAST_NAME, user.lastName);
        for (var _b = 0, _c = user.roles; _b < _c.length; _b++) {
            var role = _c[_b];
            stateService_1.stateService.set(stateService_1.STATE_ROLE_ID, role.id);
        }
    }
    eventService_1.eventService.triggerByName(new ListenerTrigger_1.ListenerTrigger({
        name: EVENT_1.EVENT.IS_CONNECTED,
    }));
}
exports.dispatchConnection = dispatchConnection;
