import ApiRegistery from '@bbx/api~master/core/delta/ApiRegistery';


import {ApiRequest_constructor_props as ApiRequest} from "../../delta/http/ApiRequest";
import {ApiResponse} from "../../delta/http/ApiResponse";
import {Country, Country_constructor_props} from "../../delta/database/Country";

export interface Request extends Country_constructor_props {
    $selectAll?: boolean;
    $ids?: number[];
    $cache?:boolean;
}

export interface Response extends Country {
}

export default new ApiRegistery<Request & ApiRequest, ApiResponse<Response[]>>({
    hydrate: (apiResponse) => Object.assign(apiResponse, {data: apiResponse.data.map(data => new Country(data))}),
    name: 'selectCountries',
})
