<style scoped lang="scss">

@import "../../scss/color";

.ArticleNew {
  background: $primary;
  color: white;
  border-radius: 3px;
  padding: 7px 14px;
  font-size: 9px;
  text-transform: uppercase;
  line-height: normal;
  letter-spacing: 0.15px;
  font-family: 'CommutersSansBold', sans-serif;
}
</style>

<template>
  <div v-show="article.priorityDisplay === 1" class="ArticleNew" :class="class_root">
    <Term :term="lang.term(639)"></Term>
  </div>
</template>

<script>

import {Article} from "../../../@common/delta/database/Article";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

export default {
  props: {
    article: {
      type: Article,
      default: () => new Article()
    }
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {},
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
