"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PromotionCode = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Promotion_1 = require("./Promotion");
var Order_1 = require("./Order");
var User_1 = require("./User");
var PromotionCode = /** @class */ (function () {
    function PromotionCode(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.code = deltaService_1.deltaService.string(props.code, '');
        this.promotions = deltaService_1.deltaService.array(props.promotions, Promotion_1.Promotion);
        this.users = deltaService_1.deltaService.array(props.users, User_1.User);
        this.orders = deltaService_1.deltaService.array(props.orders, Order_1.Order);
    }
    return PromotionCode;
}());
exports.PromotionCode = PromotionCode;
