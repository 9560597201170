import {File} from "../delta/database/File";
import {imageMakerPublicConfig} from "../../../config/public/image-maker.config";

type Props = {
    file: File
    size: number
    ext?: string
    square?: boolean
}

export function getImageMakerPath(props: Props) {

    const {file, size, ext = '', square = false} = props

    const fileNames: string[] = []

    fileNames.push(file.uuid)
    if (size) fileNames.push(size.toString())
    fileNames.push(ext ? ext : file.ext.toLowerCase())

    return `${imageMakerPublicConfig.protocol}://${imageMakerPublicConfig.domain}:${imageMakerPublicConfig.port}/${square ? 'square/' : '/'}${square ? `?imageName=${fileNames.join('.')}` : fileNames.join('.')}`
}
