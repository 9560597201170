<style scoped>
.Inscription {
  margin: auto;
}

.Inscription-desktop {
  width: 400px;
  padding: 150px 50px;
}

.Inscription-tablet {
  width: 400px;
  padding: 150px 50px;
}

.Inscription-mobile {
  width: 100%;
  padding: 50px;
  box-sizing: border-box;
}

.Inscription--description {
  text-align: center;
  padding: 0px 25px;
}

.Inscription--description ::v-deep .Term {
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

.Inscription--forgot {
  text-align: center;
}

.Inscription--forgot ::v-deep .Term {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

.Inscription--forgot:hover ::v-deep .Term p {
  text-decoration: underline;
}

.Inscription--name {
  display: flex;
}

.Inscription--spacer {
  min-width: 16px;
}

.Inscription--cgv {
  color: rgba(0, 0, 0, 0.4);
  font-size: 12px;
  line-height: 16px;
}

.Inscription--info {
  text-align: center;
  background: rgba(0, 0, 0, 0.03);
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 8px;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Inscription--info ::v-deep .Vector {
  width: 24px;
  height: 24px;
  opacity: 0.25;
}

.Inscription--googleRecaptcha {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}

.Inscription--googleRecaptcha ::v-deep .InputError {
  padding-top: 10px;
}
</style>

<template>
  <div class="Inscription" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <AuthTitle :term="h1"/>
      <br>
      <div class="Inscription--description">
        <Term :term="lang.term(112)"/>
        <Term :term="lang.term(113)"/>
        <Term :term="lang.term(114)"/>
      </div>
      <br>
      <Divider/>

          <el-radio v-model="state.roleId" :label="ROLE.CUSTOMER.toString()">
            {{ lang.translate(116) }}
          </el-radio>
          <el-radio v-model="state.roleId" :label="ROLE.SELLER.toString()">
            {{ lang.translate(117) }}
          </el-radio>
      <br>
      <Divider/>
      <br>
      <div v-show="state.roleId === ROLE.CUSTOMER.toString()">
        <Form class="Auth--form" @submit.native.prevent>
          <InputSelectTerm :items="state.genders"
                           :values="state.user.genders"
                           :label="lang.term(135)"
                           :onChangeValues="v => state.user.genders = v"
                           :error="state.formUser.display('genders')">
          </InputSelectTerm>
          <br>
          <InputText :value="state.user.firstName"
                     :onChangeValue="v => state.user.firstName = v"
                     :mandatory="true"
                     :label="lang.term(119)"
                     :error="state.formUser.display('firstName')">
          </InputText>
          <br>
          <InputText :value="state.user.lastName"
                     :onChangeValue="v => state.user.lastName = v"
                     :mandatory="true"
                     :label="lang.term(118)"
                     :error="state.formUser.display('lastName')">
          </InputText>
          <br>
          <InputDatePicker :value="state.user.birthday"
                           :onChangeValue="v => state.user.birthday = v"
                           :label="lang.term(120)"
                           :error="state.formUser.display('birthday')">
          </InputDatePicker>
          <br>
          <Divider/>
          <br>
          <InputText :value="state.user.email"
                     :mandatory="true"
                     :onChangeValue="v => state.user.email = v"
                     :label="lang.term(102)"
                     :svg="icon.mail"
                     :error="state.formUser.display('email')">
          </InputText>
          <br/>
          <InputText :value="state.user.password"
                     :password="true"
                     :mandatory="true"
                     :onChangeValue="v => state.user.password = v"
                     :label="lang.term(103)"
                     :svg="icon.fingerprint"
                     :error="state.formUser.display('password')">
          </InputText>
          <br>
          <Divider/>
          <br>
          <InputSwitch :label="lang.term(123)"
                       :error="state.formNewsletter.display('email')"
                       :value="state.newsletter.active"
                       :onChangeValue="v => state.newsletter.active = v">
          </InputSwitch>
          <br>
          <Divider/>
          <br>
          <InputCheckbox :label="lang.term(335)"
                         :error="state.formUser.display('cgv')"
                         :value="state.cgv"
                         :onChangeValue="v => state.cgv = v">
          </InputCheckbox>
          <br>
          <div class="Inscription--cgv">
            <Term :term="lang.term(334)"/>
          </div>
          <br>
          <Divider/>
          <br>
          <!--        <InputSwitch :label="lang.term(110)"/>-->
          <div class="Inscription--googleRecaptcha">
            <GoogleRecaptcha :token="state.googleRecaptchaToken"
                             :onChangeToken="v => state.googleRecaptchaToken = v">
            </GoogleRecaptcha>
            <InputError v-if="state.formUser.display('googleRecaptchaToken').id" :term="state.formUser.display('googleRecaptchaToken')"/>
          </div>
          <br>
          <Divider/>
          <br>
          <ButtonAction :term="lang.term(124)" :onClick="() => save()" :loading="state.loading"/>
        </Form>
      </div>
      <div v-show="state.roleId === ROLE.SELLER.toString()">
        <div class="Inscription--info">
          <Vector :svg="icon.info"></Vector>
          <br>
          <Term :term="lang.term(126)"/>
          <br>
          <Term :term="lang.term(128)"/>
          <br>
          <br>
          <b>
            <Term :term="lang.term(127)"/>
          </b>
        </div>
      </div>
      <br/>
      <ButtonLink :term="lang.term(125)" :pathIds="[PATHS.LOGIN]"/>
    </Responsive>
  </div>
</template>

<script>

import Carbon from "@bbx/carbon~master/core/ts/Carbon.ts";
import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint.ts';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN.ts';

import {PATHS} from "../../../@common/constant/PATHS";
import {ROLE} from "../../../@common/constant/ROLE";

import {apiClient} from "../../service/apiClientService";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

import {controlForm} from "../../function/controlForm";

import createUser from "../../../@common/api/user/createUser";
import createNewsletter from "../../../@common/api/newsletter/createNewsletter";
import selectGenders from "../../../@common/api/gender/selectGenders";

import {Newsletter} from "../../../@common/delta/database/Newsletter";
import {User} from "../../../@common/delta/database/User";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {changeRoute} from "../../function/changeRoute";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../function/getH1";
import {Term} from "../../../@common/delta/database/Term";
import {displayMessage} from "../../function/displayMessage";

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      ROLE,
      PATHS,
      lang: getLangServiceBrowser(),
      icon: {
        fingerprint: require('@bbx/vector~master/core/assets/svg/material/fingerprint.svg'),
        mail: require('@bbx/vector~master/core/assets/svg/material/mail.svg'),
        event: require('@bbx/vector~master/core/assets/svg/material/event.svg'),
        info: require('@bbx/vector~master/core/assets/svg/material/info.svg'),
        person: require('@bbx/vector~master/core/assets/svg/material/person.svg'),
      },
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      state: {
        cgv: false,
        formUser: new ApiResponseForm(),
        formNewsletter: new ApiResponseForm(),
        roleId: ROLE.CUSTOMER.toString(),
        loading: false,
        user: new User(),
        newsletter: new Newsletter({
          active: false
        }),
        genders: [],
        googleRecaptchaToken: ''
      },
      h1: this.getH1()
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`Inscription-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`Inscription-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`Inscription-desktop`)
      return classes
    }
  },
  beforeMount() {
  },
  async mounted() {
    const {data} = await apiClient.do(selectGenders, selectGenders.with())
    this.state.genders = data;
  },
  beforeDestroy() {
  },
  methods: {
    getH1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    async save() {
      if (this.state.roleId === ROLE.SELLER.toString()) return;
      const {success} = await this.saveUser();
      if (success) {
        await this.saveNewsletter();
        displayMessage({
          vue: this,
          type: 'success',
          message: this.lang.translate(137)
        })
        changeRoute([
          PATHS.LOGIN,
          PATHS.REDIRECT
        ]);
      }
    },

    async saveUser() {

      const user = new User(this.state.user)

      user.birthday = new Carbon().fromDate(user.birthday)

      const payload = Object.assign(user, {
        $cgv: this.state.cgv,
        $googleRecaptchaToken: this.state.googleRecaptchaToken,
      })

      const res = await controlForm({
        apiAction: createUser,
        formAttribute: 'formUser',
        formState: this.state,
        loaderAttribute: 'loading',
        loaderState: this.state,
        payload: payload,
        vue: this,
      });

      this.state.googleRecaptchaToken = ""

      return res
    },

    async saveNewsletter() {

      if (!this.state.newsletter.active) return;

      this.state.newsletter.email = this.state.user.email;

      return await controlForm({
        apiAction: createNewsletter,
        formAttribute: 'formNewsletter',
        formState: this.state,
        loaderAttribute: 'loading',
        loaderState: this.state,
        payload: this.state.newsletter,
        vue: this,
      });
    }
  }
}
</script>
