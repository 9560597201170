export const FILTER_OPTION = {
    NO_SCROLL: 'noScroll',
    SORT: 'sort',
    PAGE: 'page',
    SEARCH: 'search',
    IDS: 'ids',
    IS_OUTLET: 'isOutlet',
    COLLECTION_IDS: 'collectionIds',
    COLLECTION_TYPE_IDS: 'collectionTypeIds',
    CATEGORY_IDS: 'categoryIds',
    CATEGORY_CHILDREN_IDS: 'categoryChildrenIds',
    MATERIAL_IDS: 'materialIds',
    SHAPE_IDS: 'shapeIds',
    IS_SET: 'isSet',
    IS_BATCH: 'isBatch',
    IS_BATCH_BIS: 'isBatchBis', // not the db one
    MIN_PRICE: 'minPrice',
    MAX_PRICE: 'maxPrice',
    TAXED_PRICE: 'taxedPrice',
    MIN_SITTING_PLACE: 'minSittingPlace',
    MAX_SITTING_PLACE: 'maxSittingPlace',
    LUMEN_SEARCH: 'lumenSearch',

    MIN_SIZE_LENGTH: 'minSizeLength',
    MIN_SIZE_WIDTH: 'minSizeWidth',
    MIN_SIZE_HEIGHT: 'minSizeHeight',
    MAX_SIZE_LENGTH: 'maxSizeLength',
    MAX_SIZE_WIDTH: 'maxSizeWidth',
    MAX_SIZE_HEIGHT: 'maxSizeHeight',

    SEO: 'seo',
}


