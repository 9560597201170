<style scoped>
.Connexion {
  margin: auto;
}

.Connexion-desktop {
  width: 400px;
  padding: 150px 50px;
}

.Connexion-tablet {
  width: 400px;
  padding: 150px 50px;
}

.Connexion-mobile {
  width: 100%;
  padding: 50px;
  box-sizing: border-box;
}

/**/

.Connexion--description {
  text-align: center;
  padding: 0px 25px;
}

.Connexion--description ::v-deep .Term {
  line-height: 24px;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

.Connexion--forgot {
  text-align: center;
}

.Connexion--forgot ::v-deep .Term {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

.Connexion--forgot:hover ::v-deep .Term p {
  text-decoration: underline;
}

.Connexion-googleRecaptcha {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  flex: 1;
}

.Connexion-googleRecaptcha ::v-deep .InputError {
  padding-top: 10px;
}
</style>

<template>
  <div class="Connexion" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">

      <AuthTitle :term="h1"/>
      <br>
      <div class="Connexion--description">
        <Term :term="lang.term(106)"/>
      </div>
      <br>
      <Divider/>
      <br>
      <Form class="Auth--form">
        <InputText :value="state.user.email"
                   :onChangeValue="v => state.user.email = v"
                   :label="lang.term(102)"
                   :svg="icon.mail"
                   :error="state.form.display('email')"/>
        <br/>

        <InputText :value="state.user.password"
                   :password="true"
                   :onChangeValue="v => state.user.password = v"
                   :label="lang.term(103)"
                   :svg="icon.fingerprint"
                   :error="state.form.display('password')"/>
        <br/>
        <Divider/>
        <br>
        <!--        <InputSwitch :label="lang.term(110)"/>-->
        <div class="Connexion-googleRecaptcha">
          <GoogleRecaptcha :token="state.googleRecaptchaToken"
                           :onChangeToken="v => state.googleRecaptchaToken = v">
          </GoogleRecaptcha>
          <InputError v-if="state.form.display('googleRecaptchaToken').id" :term="state.form.display('googleRecaptchaToken')"/>
        </div>
        <br>
        <Divider/>
        <br>
        <ButtonAction :term="lang.term(109)" :onClick="() => save()" :loading="state.loading"/>
      </Form>
      <br/>
      <ButtonLink :term="lang.term(108)" :pathIds="[PATHS.REGISTRATION]"/>
      <br/>
      <Divider/>
      <div class="Connexion--forgot">
        <Link :pathIds="[PATHS.FORGOTTEN_PASSWORD]">
          <Term :term="lang.term(105)"/>
        </Link>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {User} from "../../../@common/delta/database/User";
import {controlForm} from "../../function/controlForm";
import createAuth from "../../../@common/api/user/createUserAuth";
import {
  stateService,
  STATE_ROLE_ID,
} from "../../service/stateService";
import {dispatchConnection} from "../../function/dispatchConnection";
import {ROLE, ROLE_BUYER, ROLE_STAFF} from "../../../@common/constant/ROLE";
import {changeRoute} from "../../function/changeRoute";
import {historyService} from "../../service/historyService";
import {PATHS} from "../../../@common/constant/PATHS";
import {getH1Term} from "../../function/getH1";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {Term} from "../../../@common/delta/database/Term";
import {urlService} from "../../../@common/service/urlService";
import {LANG_ISO} from "../../../@common/constant/LANG";

export default {
  props: {
    redirect: {
      type: Boolean,
      default: () => false
    },
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    const lang = getLangServiceBrowser();
    return {
      lang: lang,
      PATHS,
      icon: {
        fingerprint: require('@bbx/vector~master/core/assets/svg/material/fingerprint.svg'),
        mail: require('@bbx/vector~master/core/assets/svg/material/mail.svg'),
      },
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      state: {
        loading: false,
        form: new ApiResponseForm(),
        user: new User(),
        googleRecaptchaToken: ''
      },
      h1: this.getH1(lang)
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`Connexion-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`Connexion-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`Connexion-desktop`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    getH1(lang) {
      if (this.redirect) return lang.term(107)
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    async save() {

      const payload = Object.assign(this.state.user, {
        $googleRecaptchaToken: this.state.googleRecaptchaToken
      })

      const res = await controlForm({
        apiAction: createAuth,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loading',
        loaderState: this.state,
        payload: payload,
        message: this.lang.translate(136),
        vue: this,
      });

      this.state.googleRecaptchaToken = ""

      if (res.success) {
        dispatchConnection(res.data)
        if (this.redirect) {
          const links = historyService.get()
          const link = this.findLinkBack(links).pop()
          if (!link) {
            if (ROLE_STAFF.includes(stateService.get(STATE_ROLE_ID))) return changeRoute([
              PATHS.ADMIN,
              PATHS.ORDER
            ])
            else if (ROLE_BUYER.includes(stateService.get(STATE_ROLE_ID))) return changeRoute([
              PATHS.ACCOUNT,
              PATHS.PROFILE
            ])
            else changeRoute([]);
          } else {
            changeRoute([], {}, link);
          }
        } else {
          if (ROLE_STAFF.includes(stateService.get(STATE_ROLE_ID))) return changeRoute([
            PATHS.ADMIN,
            PATHS.ORDER
          ])
          else if (ROLE_BUYER.includes(stateService.get(STATE_ROLE_ID))) return changeRoute([
            PATHS.ACCOUNT,
            PATHS.PROFILE
          ])
          else changeRoute([]);
        }
      }
    },
    /**
     * @param {string[]} links
     * @returns {string[]}
     */
    findLinkBack(links) {

      const newLinks = []

      // it is very important that this word stay here if
      // we are no confident about getting word with ref
      // if this redirect does not work the client is not redirected to his basket and it is a drama

      let words = [
        'connexion',
        'login',
        'inscription',
        'registration',
        'mot-de-passe-oublie',
        'forgotten-password',
      ]

      for (const alpha2 in LANG_ISO) {
        let iso6391 = LANG_ISO[alpha2]
        words.push(urlService.getPathValueById(PATHS.LOGIN, iso6391))
        words.push(urlService.getPathValueById(PATHS.REGISTRATION, iso6391))
        words.push(urlService.getPathValueById(PATHS.FORGOTTEN_PASSWORD, iso6391))
      }

      for (const link of links) {
        let regex = new RegExp(`(${words.join('|')})`, `g`)
        if (!regex.test(link)) newLinks.push(link)
      }

      return newLinks
    }
  }
}
</script>
