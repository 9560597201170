<style scoped>
.UserAdmin {
}

.UserAdmin--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 25px 0 32px 0;
}

.UserAdmin--firstName {
  text-transform: capitalize;
}

.UserAdmin--lastName {
  text-transform: uppercase;
}

.UserAdmin--role {
  text-transform: uppercase;
}

.UserAdmin--headerLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.UserAdmin--headerRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.UserAdmin--headerRight ::v-deep .InputText input {
  border-radius: 4px 0 0 4px;
  position: relative;
  left: 1px;
}

.UserAdmin--headerRight ::v-deep .InputSelect input {
  border-radius: 0 4px 4px 0;
}

.UserAdmin--loader {
  width: 100%;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.UserAdmin--pagination {
  padding: 5px;
}

.UserAdmin--modal {
  padding: 0px 50px 50px 50px;
}

.UserAdmin--footer {
  display: flex;
  justify-content: flex-end;
}

.UserAdmin--spacer {
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
}

.UserAdmin--link {
  display: block;
  text-decoration: none;
  width: 100%;
}

.UserAdmin--option {
  width: 24px;
  cursor: pointer;
}
</style>

<template>
  <div class="UserAdmin">
    <div class="UserAdmin--header">

      <div class="UserAdmin--headerLeft">
        <Popover position="bottom-start">
          <template slot="reference">
            <ButtonAction :term="lang.term(157)" :svg="icon.add"></ButtonAction>
          </template>
          <template slot="default">
            <List :svg="icon.add" :text="lang.translate(425)" :onClick="() => openInsert()"></List>
          </template>
        </Popover>

        <div class="UserAdmin--spacer"></div>

        <InputSwitch :label="lang.term(160)"
                     :value="state.filterActive"
                     :onChangeValue="v => state.filterActive = v">
        </InputSwitch>
      </div>

      <div class="UserAdmin--headerRight">
        <div style="width: 24px;">
          <Vector :svg="icon.search"></Vector>
        </div>
        <div class="UserAdmin--spacer"></div>
        <InputText :value="state.filterSearch" :onChangeValue="v => state.filterSearch = v"></InputText>
        <InputSelect :items="state.searchTypes"
                     :values="state.selectedSearchTypes"
                     :max="1"
                     :onChangeValues="v => state.selectedSearchTypes = v">
        </InputSelect>
      </div>
    </div>
    <div class="UserAdmin--body" v-show="!state.loadingSelect">
      <el-table :data="state.users" style="width: 100%">
        <el-table-column width="100" label="Id">
          <template slot-scope="scope">
            {{ state.users[scope.$index].id }}
          </template>
        </el-table-column>
        <el-table-column width="200" :label="lang.translate(119)">
          <template slot-scope="scope">
            <span class="UserAdmin--firstName">{{ state.users[scope.$index].firstName }}</span>
          </template>
        </el-table-column>
        <el-table-column width="200" :label="lang.translate(118)">
          <template slot-scope="scope">
            <span class="UserAdmin--lastName">{{ state.users[scope.$index].lastName }}</span>
          </template>
        </el-table-column>
        <el-table-column :label="lang.translate(429)">
          <template slot-scope="scope">
            {{ state.users[scope.$index].email }}
          </template>
        </el-table-column>
        <el-table-column width="200" :label="lang.translate(428)">
          <template slot-scope="scope">
            <Tag v-for="role in state.users[scope.$index].roles" :key="role.id" :type="typeRole(role.id)">
              <span class="UserAdmin--role">{{ role.value }}</span>
            </Tag>
          </template>
        </el-table-column>
        <el-table-column width="100" :label="lang.translate(203)">
          <template slot-scope="scope">
            <Popover position="bottom-end">
              <template slot="default">
                <List :svg="icon.edit" :text="`éditer`" :onClick="() => openUpdate(state.users[scope.$index])"></List>
                <List :svg="state.users[scope.$index].active ? icon.archive : icon.unarchive" :text="state.users[scope.$index].active ? 'désactiver' : 'activer'" :onClick="() => openDelete(state.users[scope.$index])"></List>
              </template>
              <template slot="reference">
                <div class="UserAdmin--option">
                  <Vector :svg="icon.more_horiz"></Vector>
                </div>
              </template>
            </Popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="UserAdmin--loader" v-show="state.loadingSelect">
      <Loader></Loader>
    </div>
    <br>
    <br>
    <div class="UserAdmin--pagination">
      <Pagination :limit="state.filterLimit"
                  :offset="state.filterOffset"
                  :total="state.userCount"
                  :range="10"
                  :onOffsetChange="v => state.filterOffset = v">
      </Pagination>
    </div>
    <Modal :opened="state.openedUpsert" :onOpenedChange="v => state.openedUpsert = v">
      <div class="UserAdmin--modal">
        <Form>
          <UserForm :form="state.form"
                    :user="state.user"
                    :onUserChange="v => state.user = v">
          </UserForm>
          <br>
          <Divider></Divider>
          <br>
          <div class="UserAdmin--footer">
            <ButtonAction :term="lang.term(158)" :onClick="() => upsert()" :loading="state.loading"></ButtonAction>
            <div class="UserAdmin--spacer"></div>
            <ButtonLink :term="lang.term(159)" :onClick="() => state.openedUpsert = false"></ButtonLink>
          </div>
        </Form>
      </div>
    </Modal>
    <ModalConfirm :opened="state.openedRemove"
                  :onOpenedChange="v => state.openedRemove = v"
                  :on-validated="() => remove()"
                  :loading="state.loading">
    </ModalConfirm>
  </div>
</template>

<script>
import {apiClient} from "../../service/apiClientService";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

import select from "../../../@common/api/user/selectUsers";
import create from "../../../@common/api/user/createUser";

import {controlForm} from "../../function/controlForm";

import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {User} from "../../../@common/delta/database/User";
import {ROLE} from "../../../@common/constant/ROLE";
import {USER_SEARCH_ID} from "../../../@common/constant/USER";
import {Debounce} from "../../../@common/ts/Debounce";
import {sendError} from "../../function/sendError";

const debounce = new Debounce()

export default {

  props: {},
  data() {
    return {
      lang: getLangServiceBrowser(),
      icon: {
        add: require('@bbx/vector~master/core/assets/svg/material/add.svg'),
        edit: require('@bbx/vector~master/core/assets/svg/material/edit.svg'),
        visibility: require('@bbx/vector~master/core/assets/svg/material/visibility.svg'),
        delete: require('@bbx/vector~master/core/assets/svg/material/delete.svg'),
        archive: require('@bbx/vector~master/core/assets/svg/material/archive.svg'),
        unarchive: require('@bbx/vector~master/core/assets/svg/material/unarchive.svg'),
        more_horiz: require('@bbx/vector~master/core/assets/svg/material/more_horiz.svg'),
        search: require('@bbx/vector~master/core/assets/svg/material/search.svg'),
      },
      props: {},
      state: {
        /**
         * @type boolean
         */
        openedUpsert: false,
        /**
         * @type boolean
         */
        openedRemove: false,
        /**
         * @type ApiResponseForm
         */
        form: new ApiResponseForm(),
        /**
         * @type User
         */
        user: this.defaultUser(),
        /**
         * @type boolean
         */
        loading: false,
        /**
         * @type boolean
         */
        loadingSelect: false,
        /**
         * @type number
         */
        filterOffset: 0,
        /**
         * @type number
         */
        filterLimit: 12,
        /**
         * @type boolean
         */
        filterActive: true,
        /**
         * @type string
         */
        filterSearch: '',
        /**
         * @type User[]
         */
        users: [],
        /**
         * @type any[]
         */
        searchTypes: [
          {
            id: USER_SEARCH_ID.ID,
            value: 'Id'
          },
          {
            id: USER_SEARCH_ID.FIRST_NAME,
            value: 'Prénom'
          },
          {
            id: USER_SEARCH_ID.LAST_NAME,
            value: 'Nom'
          },
          {
            id: USER_SEARCH_ID.EMAIL,
            value: 'Email'
          },
          {
            id: USER_SEARCH_ID.ROLE,
            value: 'Rôle'
          },
        ],
        /**
         * @type any[]
         */
        selectedSearchTypes: [
          {
            id: USER_SEARCH_ID.EMAIL,
            value: 'Email'
          },
        ],
        /**
         * @type number
         */
        userCount: 0,
      },
    }
  },
  watch: {
    'state.filterActive': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.filterOffset': function () {
      if (this.state.loadingSelect === false) {
        this.select().catch(sendError)
      }
    },
    'state.filterLimit': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.filterSearch': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.filterIsBatch': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.selectedSearchTypes': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    }
  },
  computed: {},
  beforeMount() {
  },
  mounted() {
    this.select().catch(sendError)
  },
  beforeDestroy() {
  },
  methods: {
    async select() {
      this.state.loadingSelect = true
      const filter = {
        active: this.state.filterActive,
        $search: this.state.filterSearch,
        $searchType: this.state.selectedSearchTypes.map(a => a.id).pop(),
        $limit: this.state.filterLimit,
        $offset: this.state.filterOffset,
        $sortById: true,
      }
      debounce.callback({
        job: () => apiClient.do(select, select.with(filter)),
        success: res => {
          this.state.users = res.data;
          this.state.userCount = res.count;
          this.state.loadingSelect = false
        },
        error: err => {
          this.state.loadingSelect = false
          sendError(err)
        }
      })
    },

    /**
     * @returns {Promise<void>}
     */
    async remove() {
      this.state.user.active = !this.state.user.active;
      await this.upsert()
    },

    async upsert() {

      const payload = new User(this.state.user)

      // return console.log(JSON.stringify(payload, null, 4));

      const res = await controlForm({
        apiAction: create,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loading',
        loaderState: this.state,
        payload: payload,
        vue: this,
      });

      if (res.success) {

        this.state.user = this.defaultUser()

        this.state.openedUpsert = false
        this.state.openedRemove = false

        await this.select();
      }
    },

    /**
     * @param {User} user
     * @returns {Promise<void>}
     */
    async openUpdate(user) {
      this.state.form.clean()
      this.state.user = this.defaultUser(new User(user))
      this.state.openedUpsert = true
    },

    /**
     * @param {User} user
     * @returns {Promise<void>}
     */
    async openDelete(user) {
      this.state.user = this.defaultUser(new User(user))
      this.state.openedRemove = true
    },

    /**
     * @param {boolean} isBatch
     * @returns {Promise<void>}
     */
    async openInsert() {
      this.state.form.clean()
      this.state.user = this.defaultUser(new User())
      this.state.openedUpsert = true
    },

    /**
     * @param {User} user
     * @returns {User}
     */
    defaultUser(user = new User) {
      return user
    },

    /**
     * @param {number} roleId
     * @returns {string}
     */
    typeRole(roleId) {
      const map = {
        [ROLE.ROOT]: 'danger',
        [ROLE.ADMIN]: 'warning',
        [ROLE.CHR]: 'success',
        [ROLE.SEO]: 'success',
        [ROLE.CUSTOMER]: 'success',
        [ROLE.SELLER]: 'info',
      }
      return map[roleId]
    },
  }
}
</script>
