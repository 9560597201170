<style scoped>
.OrderForm {
}

.OrderForm p {
  font-size: 13px;
  padding-top: 10px;
  color: rgba(0, 0, 0, 0.75);
}

.OrderForm b {
  font-size: 14px;
  text-transform: uppercase;
  padding-bottom: 3px;
}

.OrderForm--upper {
  text-transform: uppercase;
}

.OrderForm--capitalize {
  text-transform: capitalize;
}
</style>

<template>
  <div class="OrderForm" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">

      <InputSection :svg="icon.subject" :term="lang.term(564)">
        <div>
          <div>
            <b>Statut</b>
          </div>
          <p>
            <OrderStatus :order="order"></OrderStatus>
          </p>
        </div>
        <br>
        <div>
          <div>
            <b>N° de commande</b>
          </div>
          <p>{{ order.number || 'Aucun' }}</p>
        </div>
        <br>
        <div>
          <div>
            <b>Alias</b>
          </div>
          <p>{{ getSagePrefixOrder(order.id) }}</p>
        </div>
        <br>
        <div>
          <div>
            <b>Date de la commande</b>
          </div>
          <p>{{ toFrenchDate(order.createdAt) }}</p>
        </div>
      </InputSection>
      <InputSection :svg="icon.person" :term="lang.term(565)">
        <template v-for="user in order.users">
          <div>
            <div>
              <b>Type de client</b>
            </div>
            <br>
            <Tag v-if="user.roles.some(a => a.id === ROLE.SELLER)" type="">Revendeur B2B</Tag>
            <Tag v-if="user.roles.some(a => a.id === ROLE.CUSTOMER)" type="success">Client B2C</Tag>
          </div>
          <br>
          <div>
            <div>
              <b>Email</b>
            </div>
            <p>{{ user.email }}</p>
          </div>
          <br>
        </template>
        <template v-for="delivery in order.deliveries">
          <div>
            <div>
              <b>Téléphone</b>
            </div>
            <p>{{ delivery.contactPhone }}</p>
          </div>
          <br>
          <div>
            <div>
              <b>Personne à contacter</b>
            </div>
            <p>{{ delivery.contactName }}</p>
          </div>
        </template>
      </InputSection>
      <InputSection :svg="icon.local_shipping" :term="lang.term(566)">
        <div>
          <div>
            <b>Adresse de livraison</b>
          </div>
          <div>
            <div v-for="delivery in order.deliveries">
              <div v-for="address in delivery.addresses">
                <p v-for="street in address.streets" v-if="street.value.trim()">
                  <span class="OrderForm--upper">{{ street.value }}</span>
                </p>
                <p>
                  <span>{{ address.zipCode }}</span>
                  -
                  <span class="OrderForm--upper">{{ address.city }}</span>
                </p>
                <p class="OrderForm--upper">
                  <span>{{ getCountry(order) }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div>
          <div>
            <b>Information complémentaire</b>
          </div>
          <p>{{ order.detail || 'Aucune' }}</p>
        </div>
      </InputSection>
      <InputSection :svg="icon.local_grocery_store" :term="lang.term(567)">
        <div>
          <div>
            <b>Référence / quantité</b>
          </div>
          <p v-for="article in articles">{{ article }}</p>
        </div>
        <br>
        <div>
          <div>
            <b>Composant interne</b>
          </div>
          <template v-for="component in components">
            <p>( {{ component.reference }} x {{ component.quantity }} )</p>
          </template>
        </div>
      </InputSection>
      <InputSection :svg="icon.payment" :term="lang.term(568)">
        <div>
          <div>
            <b>Adresse de facturation</b>
          </div>
          <div>
            <div v-for="funding in order.fundings">
              <div v-for="address in funding.addresses">
                <p v-for="street in address.streets" v-if="street.value.trim()">
                  <span class="OrderForm--upper">{{ street.value }}</span>
                </p>
                <p>
                  <span>{{ address.zipCode }}</span>
                  -
                  <span class="OrderForm--upper">{{ address.city }}</span>
                </p>
                <p class="OrderForm--upper">
                  <span>{{ getCountry(order) }}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <br>
        <div>
          <div>
            <b>Montant TTC ( € )</b>
          </div>
          <p>{{ price(order) }} €</p>
        </div>
        <br>
        <div>
          <div>
            <b>Moyen de paiement</b>
          </div>
          <p v-for="funding in order.fundings">{{ paymentMethods(funding) }}</p>
        </div>
        <br>
        <div>
          <div>
            <b>Option de paiement</b>
          </div>
          <p v-for="funding in order.fundings">{{ paymentOption(funding) }}</p>
        </div>
        <br>
        <div>
          <div>
            <b>Échéance de paiement</b>
          </div>
          <template v-for="(funding, index) in order.fundings">
            <p v-if="!funding.payments.length">Aucune</p>
            <br v-if="funding.payments.length">
            <!--            <el-steps :active="payementCurrent(funding)">-->
            <el-steps>
              <template v-for="payment in funding.payments">
                <el-step :key="payment.id"
                         :title="formatPrice(payment.amount) + ' €'"
                         :description="statusDescription(payment) + ' ' + toFrenchDate(payment.dateDeadline)"
                         :status="statusPayment(payment)">
                </el-step>
              </template>
            </el-steps>
          </template>
        </div>
      </InputSection>

    </Responsive>
  </div>
</template>

<script>

import Carbon from '@bbx/carbon~master/core/ts/Carbon';
import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {Order} from "../../../@common/delta/database/Order";
import {getSagePrefixOrder} from "../../../@common/function/getSagePrefixOrder";
import {getFlatBasketArticle} from "../../../@common/function/getFlatBasketArticle";
import {getBasketTotal} from "../../../@common/function/getBasketTotal";
import {formatPrice} from "../../../@common/function/formatPrice";
import {PAYMENT_ENGINE_LIST} from "../../../@common/constant/PAYMENT_ENGINE_LIST";
import {PAYMENT_STATE_LIST} from "../../../@common/constant/PAYMENT_STATE_LIST";
import {FUNDING_OPTION_LIST} from "../../../@common/constant/FUNDING_OPTION_LIST";
import {ROLE} from "../../../@common/constant/ROLE";

const carbon = new Carbon()

export default {

  props: {
    order: {
      type: Order,
      default: () => new Order()
    },
    onOrderChange: {
      type: Function,
      default: () => (order) => {
      }
    },
    form: {
      default: () => new ApiResponseForm()
    },
  },
  data() {
    return {
      ROLE,
      PAYMENT_ENGINE_LIST,
      PAYMENT_STATE_LIST,
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
        style: require('@bbx/vector~master/core/assets/svg/material/style.svg'),
        subject: require('@bbx/vector~master/core/assets/svg/material/subject.svg'),
        phone: require('@bbx/vector~master/core/assets/svg/material/phone.svg'),
        people: require('@bbx/vector~master/core/assets/svg/material/people.svg'),
        person: require('@bbx/vector~master/core/assets/svg/material/person.svg'),
        local_shipping: require('@bbx/vector~master/core/assets/svg/material/local_shipping.svg'),
        local_grocery_store: require('@bbx/vector~master/core/assets/svg/material/local_grocery_store.svg'),
        payment: require('@bbx/vector~master/core/assets/svg/material/payment.svg'),
      },
      state: {
        /**
         * @type Order
         */
        order: new Order(this.order),

        activities: [
          {
            content: 'Event start',
            timestamp: '2018-04-15'
          },
          {
            content: 'Approved',
            timestamp: '2018-04-13'
          },
          {
            content: 'Success',
            timestamp: '2018-04-11'
          }
        ]
      },
    }
  },
  watch: {
    'order': {
      handler(v) {
        this.state.order = v
      },
      deep: true
    },
    'state.order': {
      handler(v) {
        this.onOrderChange(v)
      },
      deep: true
    },
  },
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`OrderForm-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`OrderForm-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`OrderForm-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`OrderForm-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`OrderForm-desktopY`)
      return classes
    },
    articles() {
      return getFlatBasketArticle(this.order).split(',')
    },
    components() {
      let productComponents = []

      for (const basket of this.order.baskets) {
        for (const article of basket.articles) {
          for (const batch of article.batches) {
            for (const product of batch.products) {
              for (const component of product.components) {
                for (const productComponent of component.productComponents) {
                  productComponents.push({
                    reference: productComponent.reference,
                    quantity: basket.quantity * batch.quantity
                  })
                }
              }
            }
          }
        }
      }

      return productComponents
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    formatPrice,
    getSagePrefixOrder,

    /**
     * @param {string} date
     * @return {string}
     */
    toFrenchDate(date) {
      const dateIso = carbon.toDate(date)
      let a = dateIso.split('-')
      a = a.reverse()
      return a.join('/')
    },

    /**
     * @param {Order} order
     * @return {string}
     */
    getCountry(order) {
      let country_ = ''

      for (const delivery of order.deliveries) {
        for (const address of delivery.addresses) {
          country_ = address.country
          for (const country of address.countries) {
            country_ = country.name
          }
        }
      }

      return country_
    },

    /**
     * @param {Order} order
     * @return {number}
     */
    price(order) {
      let price = 0
      for (const funding of order.fundings) {
        for (const payment of funding.payments) {
          price += payment.amount
        }
      }
      if (!price) {
        let roleId = 0
        for (const user of order.users) {
          for (const role of user.roles) {
            roleId = role.id
          }
        }
        const {totalTTC} = getBasketTotal({
          order,
          roleId
        })
        price = totalTTC
      }
      return formatPrice(price)
    },

    /**
     * @param {Payment} payment
     * @return {string}
     */
    statusPayment(payment) {
      let price = 'wait'
      for (const paymentStateList of payment.paymentStateLists) {
        if (paymentStateList.id === PAYMENT_STATE_LIST.PENDING) price = 'wait'
        else if (paymentStateList.id === PAYMENT_STATE_LIST.PAID) price = 'success'
        else price = 'error'
      }
      return price
    },

    /**
     * @param {Payment} payment
     * @return {string}
     */
    statusDescription(payment) {
      let price = ''
      for (const paymentStateList of payment.paymentStateLists) {
        if (paymentStateList.id === PAYMENT_STATE_LIST.PENDING) price = ''
        else if (paymentStateList.id === PAYMENT_STATE_LIST.PAID) price = 'payé le'
        else price = 'refusé le'
      }
      return price
    },

    /**
     * @param {Funding} funding
     * @return {number}
     */
    payementCurrent(funding) {
      let level = 0
      let max = funding.payments.length + 1
      funding.payments.forEach((payment) => {
        payment.paymentStateLists.forEach((paymentStateList, i) => {
          if (paymentStateList.id === PAYMENT_STATE_LIST.PENDING) level = i + 1
        })
      })
      return level ? level : max
    },

    /**
     * @param {Funding} funding
     * @return {number}
     */
    paymentOption(funding) {
      let option = 'Aucun'
      funding.fundingOptionLists.forEach((fundingOptionList) => {
        if (fundingOptionList.id === FUNDING_OPTION_LIST.LOAN) option = '3X sans frais'
        if (fundingOptionList.id === FUNDING_OPTION_LIST.DEPOSIT) option = '2X sans frais ( acompte )'
        if (fundingOptionList.id === FUNDING_OPTION_LIST.CASH) option = 'Comptant'
      })
      return option
    },

    /**
     * @param {Funding} funding
     * @return {number}
     */
    paymentMethods(funding) {
      let methods = []
      funding.payments.forEach((payment) => {
        payment.paymentEngineLists.forEach((paymentEngineList, i) => {
          if (paymentEngineList.id === PAYMENT_ENGINE_LIST.PAYPAL && !methods.includes('PayPal')) methods.push('PayPal')
          if (paymentEngineList.id === PAYMENT_ENGINE_LIST.SYSTEM_PAY && !methods.includes('Carte bancaire')) methods.push('Carte bancaire')
        })
      })
      return methods.join(', ') || 'Aucun'
    },
  }
}
</script>
