<style scoped lang="scss">

@import "../../scss/color";
@import "../../scss/size";

.HomeCategory {
}

.HomeCategory-desktop {
  padding-bottom: 50px;
}

.HomeCategory-tablet {

  padding-bottom: 50px;
}

.HomeCategory-mobile {
  padding-bottom: 80px;
}

/**/

.HomeCategory-desktop.HomeCategory ::v-deep .Async--loader {
  min-height: 417px;
}

.HomeCategory-tablet.HomeCategory ::v-deep .Async--loader {
  min-height: 310px;
}

.HomeCategory-mobile.HomeCategory ::v-deep .Async--loader {
  min-height: 276px;
}

/**/

.HomeCategory--title {
  font-family: 'CommutersSansBold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  color: $primary;
  text-align: center;
}

.HomeCategory-desktop .HomeCategory--title {
  padding-top: 111px;
  padding-bottom: 41px;
  font-size: $desktopTitle4FontSize;
}

.HomeCategory-tablet .HomeCategory--title {
  padding-top: $tabletHomeCarouselLeft * 2;
  padding-bottom: 41px;
  font-size: $tabletTitle4FontSize;
}

.HomeCategory-mobile .HomeCategory--title {
  padding-top: 40px;
  padding-bottom: 41px;
  font-size: $mobileTitle4FontSize;
}

/**/

.HomeCategory--categories {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding-bottom: 8px;
  align-items: center;
  padding: 0 40px;
  text-align: center;
}

.HomeCategory-desktop .HomeCategory--categories {

}

@media screen and (max-width: $menu-image-screen) {
  .HomeCategory-desktop .HomeCategory--categories {
    transform: scale(0.8);
  }
}

.HomeCategory-tablet .HomeCategory--categories {

}

.HomeCategory-mobile .HomeCategory--categories {

}

/**/

$desktopCategoryMarginRight: 70px;
$tabletCategoryMarginRight: 60px;

.HomeCategory--category {
  font-family: 'AmbroiseRegular', sans-serif;
  position: relative;
  color: #31302F;
}

.HomeCategory-desktop .HomeCategory--category {
  font-size: 63px;
  line-height: 98px;
  margin-right: $desktopCategoryMarginRight;
}

.HomeCategory-tablet .HomeCategory--category {
  font-size: 40px;
  line-height: 60px;
  margin-right: $tabletCategoryMarginRight;
}

.HomeCategory-mobile .HomeCategory--category {
  font-size: 30px;
  line-height: 50px;
}

.HomeCategory--category ::v-deep .Term--text:first-letter {
  text-transform: uppercase;
}

.HomeCategory--count {
  font-family: 'AeonikRegular', sans-serif;
  font-size: 10px;
  letter-spacing: 0.25px;
  border: solid 1px #31302F;
  width: 25px;
  height: 25px;
  border-radius: 25px;
  position: absolute;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
}

.HomeCategory-desktop .HomeCategory--count {
  right: -$desktopCategoryMarginRight + 45px;
}

.HomeCategory-tablet .HomeCategory--count {
  right: -$tabletCategoryMarginRight + 25px;
}

.HomeCategory-mobile .HomeCategory--count {
  display: none;
}

.HomeCategory--count span {
  position: relative;
  top: -1px;
}

.HomeCategory--barre {
  position: absolute;
  top: 50%;
  height: 1px;
  width: 100%;
  background-color: #31302F;
  transition: 500ms;
  transform: scaleX(0);
}

.HomeCategory-desktop .HomeCategory--barre {
  margin-top: 36px;
}

.HomeCategory-tablet .HomeCategory--barre {
  margin-top: 24px;
}

.HomeCategory-mobile .HomeCategory--barre {
  display: none;
}

.HomeCategory--category:hover .HomeCategory--barre {
  transform: scaleX(1);
}

/**/

.HomeCategory--point {
  width: 3px;
  height: 3px;
  border-radius: 3px;
  background: black;
  margin: 0 8px;
  position: relative;
  top: 2px;
}
</style>

<template>
  <ScrollParallax :distance="50">
    <div class="HomeCategory" :class="class_root">
      <Async :fetch="async () => init()">
        <Responsive :breakPoint="props.Responsive.breakPoint"
                    :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                    :breakPoints="props.Responsive.breakPoints"
                    :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                    :onBreakPointChange="props.Responsive.onBreakPointChange"
                    :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
          <div class="HomeCategory--title">
            <AnimationTitle>
              <Term :term="lang.term(665)"></Term>
            </AnimationTitle>
          </div>
          <template v-if="[SCREEN.DESKTOP,SCREEN.TABLET].includes(props.Responsive.breakPoint.name)">
            <div class="HomeCategory--categories">
              <template v-for="category in categories.slice(0,3)">
                <template v-for="term in category.terms">
                  <Link :redirect="true" :pathIds="getPathIdsByCategoryId(nuxtProp, category.id)" :key="category.id">
                    <div class="HomeCategory--category">
                      <div class="HomeCategory--count">
                        <span>{{ getCategoryCount(category) }}</span>
                      </div>
                      <div class="HomeCategory--barre"></div>
                      <Term :term="term"></Term>
                    </div>
                  </Link>
                </template>
              </template>
            </div>
            <div class="HomeCategory--categories">
              <template v-for="category in categories.slice(3,6)">
                <template v-for="term in category.terms">
                  <Link :redirect="true" :pathIds="getPathIdsByCategoryId(nuxtProp, category.id)" :key="category.id">
                    <div class="HomeCategory--category">
                      <div class="HomeCategory--count">
                        <span>{{ getCategoryCount(category) }}</span>
                      </div>
                      <div class="HomeCategory--barre"></div>
                      <Term :term="term"></Term>
                    </div>
                  </Link>
                </template>
              </template>
            </div>
          </template>
          <template v-if="[SCREEN.MOBILE].includes(props.Responsive.breakPoint.name)">
            <div class="HomeCategory--categories">
              <template v-for="(category, index) in categories">
                <template v-for="term in category.terms">
                  <Link :redirect="true" :pathIds="getPathIdsByCategoryId(nuxtProp, category.id)" :key="category.id">
                    <div class="HomeCategory--category">
                      <div class="HomeCategory--count">
                        <span>{{ getCategoryCount(category) }}</span>
                      </div>
                      <div class="HomeCategory--barre"></div>
                      <Term :term="term"></Term>
                    </div>
                  </Link>
                  <div class="HomeCategory--point" v-if="index !== categories.length -1"></div>
                </template>
              </template>
            </div>
          </template>
        </Responsive>
      </Async>
    </div>
  </ScrollParallax>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {apiClient} from "../../service/apiClientService";
import {sendError} from "../../function/sendError";

import selectCategories from "../../../@common/api/category/selectCategories";
import selectArticles from "../../../@common/api/article/selectArticles";
import {CATEGORY} from "../../../@common/constant/CATEGORY";
import {getPathIdsByCategoryId} from "../../../@common/function/helperUrl";

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    },
    breakPoints: {
      type: Array,
      default: () => ([])
    },
    verticalBreakPoints: {
      type: Array,
      default: () => ([])
    },
  },
  data() {
    return {
      SCREEN,
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: this.breakPoints,
          verticalBreakPoints: this.verticalBreakPoints,
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      icon: {},
      state: {
        /**
         * @type Category[]
         */
        categories: [],
        /**
         * @type {count:number,id:number}[]
         */
        categoryCounts: [],
        /**
         * @type Object
         */
        categoryCountIndex: {},
        /**
         * @type boolean
         */
        loading: true
      },
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`HomeCategory-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`HomeCategory-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`HomeCategory-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`HomeCategory-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`HomeCategory-desktopY`)
      return classes
    },
    categories() {
      let order = undefined
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) {
        order = (a, b) => this.termLength(a) - this.termLength(b)
      } else {
        order = (a, b) => this.termLength(b) - this.termLength(a)
      }

      const categories = this.state.categories.sort((a, b) => this.state.categoryCountIndex[b.id] - this.state.categoryCountIndex[a.id])
      return categories.slice(0, 6).sort(order)
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    getPathIdsByCategoryId,
    async init() {
      await this.selectCategories()
      await this.selectCategoryCounts()
      await this.setIndex()
    },
    async selectCategories() {
      this.state.loading = true
      const {data} = await apiClient.do(selectCategories, selectCategories.with({
        $scope: 2,
        active: true,
        level: 0,
      }))
      this.state.categories = data.filter(c => c.id !== CATEGORY.ACCESSOIRE)
      this.state.loading = false
    },
    async selectCategoryCounts() {
      this.state.categoryCounts = await Promise.all(this.state.categories.map(async (category) => this.selectArticles(category.id)))
    },
    async selectArticles(id) {
      const {count} = await apiClient.do(selectArticles, selectArticles.with({
        $scope: 2,
        $data: false,
        active: true,
        visible: true,
        categoryIds: [id]
      }))
      return {id, count}
    },
    setIndex() {
      let categoryCountIndex = {}
      for (const categoryCount of this.state.categoryCounts) {
        categoryCountIndex[categoryCount.id] = categoryCount.count
      }
      this.state.categoryCountIndex = categoryCountIndex
    },
    /**
     * @param {Category} category
     * @returns {boolean}
     */
    termLength(category) {
      for (const term of category.terms) {
        return this.lang.translateTerm(term).length
      }
      return 0
    },
    /**
     * @param {Category} category
     * @returns {boolean}
     */
    getCategoryCount(category) {
      return this.state.categoryCountIndex[category.id]
    },
  }
}
</script>
