<style scoped>
.AdminUser {
  padding: 0px 50px 50px 50px;
}
</style>

<template>
  <div class="AdminUser">
    <el-tabs type="card" v-model="state.tab">
      <el-tab-pane label="Utilisateurs" name="user">
        <UserAdmin v-if="state.tab === 'user'"></UserAdmin>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

export default {
  props: {},
  data() {
    return {
      props: {},
      state: {
        /**
         * @type string
         */
        tab: 'user',
      },
    }
  },
  watch: {},
  computed: {},
  beforeMount() {

  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
