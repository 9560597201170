"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.calculateTTC = void 0;
var getDecimal_1 = require("./getDecimal");
var TVA_1 = require("../constant/TVA");
function calculateTTC(value, rate) {
    if (rate === void 0) { rate = TVA_1.TVA.NORMAL; }
    value = value + (value * rate / 100);
    return getDecimal_1.getDecimal(value);
}
exports.calculateTTC = calculateTTC;
