<style scoped>
.AddressForm {
}

.AddressForm--row {
  display: flex;
}

.AddressForm--space {
  width: 30px;
  min-width: 30px;
}

.AddressForm--row ::v-deep .InputText {
  flex: 1;
}

.AddressForm--row ::v-deep .InputNumber {
  flex: 1;
}

</style>

<template>
  <div class="AddressForm" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">

      <InputSection :svg="icon.location_city" :term="lang.term(412)">
        <template v-if="!inputHiddens.includes('name')">
          <InputText :value="state.address.name"
                     :mandatory="true"
                     :onChangeValue="v => state.address.name = v"
                     :label="lang.term(324)"
                     :error="form.display(`${prefixError}name`)">
          </InputText>
        </template>

        <template v-if="!inputHiddens.includes('street')">
          <br v-if="!inputHiddens.includes('name')">
          <template v-for="(street, street_index) in state.address.streets">
            <br v-if="street_index">
            <InputText :value="street.value"
                       :mandatory="!street_index"
                       :onChangeValue="v => street.value = v"
                       :label="street_index ? lang.term(180) : lang.term(179)"
                       :svgSuffix="getSvgSuffix(street_index)"
                       :onClickSuffix="() => toggleComplement(street_index, state.address.streets)"
                       :error="form.display(`${prefixError}streets[${street_index}].value`)">
            </InputText>
          </template>
        </template>


        <template v-if="!inputHiddens.includes('city') && !inputHiddens.includes('zipCode')">
          <br>
          <div class="AddressForm--row">
            <InputText :value="state.address.city"
                       :mandatory="true"
                       :onChangeValue="v => state.address.city = v"
                       :label="lang.term(182)"
                       :error="form.display(`${prefixError}city`)">
            </InputText>
            <div class="AddressForm--space"></div>
            <InputText :value="state.address.zipCode"
                       :mandatory="true"
                       :onChangeValue="v => state.address.zipCode = v"
                       :label="lang.term(181)"
                       :error="form.display(`${prefixError}zipCode`)">
            </InputText>
          </div>
        </template>

        <template v-if="!inputHiddens.includes('country')">
          <br>
          <InputText :value="state.address.country"
                     :mandatory="true"
                     :onChangeValue="v => state.address.country = v"
                     :label="lang.term(183)"
                     :error="form.display(`${prefixError}country`)">
          </InputText>
        </template>


        <template v-if="!inputHiddens.includes('countries')">
          <br>
          <InputSelect :items="state.countries"
                       :mandatory="true"
                       :values="state.address.countries"
                       :label="lang.term(183)"
                       :display="country => country.name"
                       :onChangeValues="v => state.address.countries = v"
                       :error="form.display(`${prefixError}countries`)">
          </InputSelect>
        </template>


        <template v-if="!inputHiddens.includes('favorite')">
          <br>
          <InputSwitch :value="state.address.favorite"
                       :label="lang.term(325)"
                       :onChangeValue="v => state.address.favorite = v">
          </InputSwitch>
        </template>

      </InputSection>


      <template v-if="!inputHiddens.includes('geographicalPositions')">
        <InputSection :svg="icon.room" :term="lang.term(415)">
          <InputSwitch :value="state.address.geoAuto"
                       :label="lang.term(430)"
                       tag="google"
                       :onChangeValue="v => state.address.geoAuto = v">
          </InputSwitch>
          <br>
          <br>
          <div class="AddressForm--row">
            <template v-for="(geographicalPosition, geographicalPosition_index) in state.address.geographicalPositions">
              <InputNumber :value="geographicalPosition.latitude"
                           :onChangeValue="v => geographicalPosition.latitude = v"
                           :label="lang.term(413)"
                           :disabled="state.address.geoAuto"
                           :decimal="10"
                           :error="form.display(`${prefixError}latitude`)">
              </InputNumber>
              <div class="AddressForm--space"></div>
              <InputNumber :value="geographicalPosition.longitude"
                           :onChangeValue="v => geographicalPosition.longitude = v"
                           :label="lang.term(414)"
                           :disabled="state.address.geoAuto"
                           :decimal="10"
                           :error="form.display(`${prefixError}longitude`)">
              </InputNumber>
            </template>

          </div>

        </InputSection>
      </template>


    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {Address} from "../../../@common/delta/database/Address";
import {Street} from "../../../@common/delta/database/Street";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {apiClient} from "../../service/apiClientService";
import selectCountries from "../../../@common/api/country/selectCountries";
import {sendError} from "../../function/sendError";

export default {

  props: {
    address: {
      default: () => new Address({
        streets: [
          new Street(),
          new Street(),
        ]
      })
    },
    form: {
      default: () => new ApiResponseForm
    },
    onAddressChange: {
      type: Function,
      default: () => () => {
      }
    },
    prefixError: {
      type: String,
      default: () => ''
    },
    inputHiddens: {
      type: Array,
      default: () => ([])
    },
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        add: require('@bbx/vector~master/core/assets/svg/material/add.svg'),
        delete_forever: require('@bbx/vector~master/core/assets/svg/material/delete_forever.svg'),
        location_city: require('@bbx/vector~master/core/assets/svg/material/location_city.svg'),
        room: require('@bbx/vector~master/core/assets/svg/material/room.svg'),
      },
      state: {
        /**
         * @type Address
         **/
        address: this.address,
        /**
         * @type Country[]
         **/
        countries: [],
      },
    }
  },
  watch: {
    'address': {
      handler(v) {
        this.state.address = v
      },
      deep: true
    },
    'state.address': {
      handler(v) {
        this.onAddressChange(v)
      },
      deep: true
    },
  },
  computed: {
    class_root() {
      const classes = []
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
    this.selectCountries().catch(sendError)
  },
  beforeDestroy() {
  },
  methods: {
    async selectCountries() {
      const {data} = await apiClient.do(selectCountries, selectCountries.with({
        active: true
      }))
      this.state.countries = data
    },
    toggleComplement(index, streets) {
      if (index === 1) this.addComplement(streets)
      else this.removeComplement(index, streets)
    },
    addComplement(streets) {
      streets.push(new Street())
    },
    removeComplement(index, streets) {
      streets.splice(index, 1)
    },
    getSvgSuffix(index) {
      if (index === 0) return ''
      if (index === 1) return this.icon.add
      else return this.icon.delete_forever
    },
  }
}
</script>
