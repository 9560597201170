import {deltaService} from "@bbx/delta~master/core/service/deltaService"

import {User} from "./User"
import {Basket} from "./Basket"
import {Delivery} from "./Delivery"
import {PromotionCode} from "./PromotionCode";
import {OrderStateList} from "./OrderStateList";
import {Funding} from "./Funding";
import {Shop} from "./Shop";
import {DeepPartial} from "../../type/DeepPartial";


export type Order_constructor_props = DeepPartial<Order>

export class  Order {

    id: number
    active: boolean
    createdAt: string
    updatedAt: string

    number: string
    detail: string
    sameFundingAddress: boolean
    useCustomAddress: boolean

    baskets: Basket[]
    deliveries: Delivery[]
    promotionCodes: PromotionCode[]
    orderStateLists: OrderStateList[]
    fundings: Funding[]
    users: User[]
    shops: Shop[]

    constructor(props: Order_constructor_props = {}) {
                this.id = deltaService.number(props.id, 0)
        this.active = deltaService.boolean(props.active, true)
        this.createdAt = deltaService.string(props.createdAt, '')
        this.updatedAt = deltaService.string(props.updatedAt, '')

        this.number = deltaService.string(props.number, '')
        this.detail = deltaService.string(props.detail, '')
        this.sameFundingAddress = deltaService.boolean(props.sameFundingAddress, true)
        this.useCustomAddress = deltaService.boolean(props.useCustomAddress, true)

        this.baskets = deltaService.array(props.baskets, Basket)
        this.deliveries = deltaService.array(props.deliveries, Delivery)
        this.promotionCodes = deltaService.array(props.promotionCodes, PromotionCode)
        this.orderStateLists = deltaService.array(props.orderStateLists, OrderStateList)
        this.fundings = deltaService.array(props.fundings, Funding)
        this.users = deltaService.array(props.users, User)
        this.shops = deltaService.array(props.shops, Shop)
    }
}
