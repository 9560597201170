<style scoped>
.PaymentCard {
}

.PaymentCard--paymentCards {
  display: flex;
}

.PaymentCard--paymentCard {
  margin-right: 10px;
  width: 48px;
  height: 32px;
}
</style>

<template>
  <div class="PaymentCard" :class="class_root">
    <div class="PaymentCard--paymentCards">
      <div class="PaymentCard--paymentCard">
        <Picture :src="picture.visa"></Picture>
      </div>
      <div class="PaymentCard--paymentCard">
        <Picture :src="picture.mastercard"></Picture>
      </div>
<!--      <div class="PaymentCard&#45;&#45;paymentCard">-->
<!--        <Picture :src="picture.american_express"></Picture>-->
<!--      </div>-->
      <div class="PaymentCard--paymentCard">
        <Picture :src="picture.paypal"></Picture>
      </div>
      <div class="PaymentCard--paymentCard">
        <Picture :src="picture.multiple"></Picture>
      </div>
    </div>
  </div>
</template>

<script>

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

export default {

  props: {},
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
      },
      icon: {
      },
      state: {},
      picture: {
        visa: require('../../../@common/assets/payment/1.png'),
        mastercard: require('../../../@common/assets/payment/2.png'),
        american_express: require('../../../@common/assets/payment/3.png'),
        paypal: require('../../../@common/assets/payment/4.png'),
        multiple: require('../../../@common/assets/payment/5.png'),
      },
    }
  },
  watch: {},
  computed: {
    class_root(){
        const classes = []
        return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
