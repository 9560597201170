"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Page = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Term_1 = require("./Term");
var Path_1 = require("./Path");
var Category_1 = require("./Category");
var Article_1 = require("./Article");
var Url_1 = require("./Url");
var Collection_1 = require("./Collection");
var Page = /** @class */ (function () {
    function Page(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.indexed = deltaService_1.deltaService.boolean(props.indexed, true);
        this.termTitles = deltaService_1.deltaService.array(props.termTitles, Term_1.Term);
        this.termDescriptions = deltaService_1.deltaService.array(props.termDescriptions, Term_1.Term);
        this.termH1s = deltaService_1.deltaService.array(props.termH1s, Term_1.Term);
        this.termStories = deltaService_1.deltaService.array(props.termStories, Term_1.Term);
        this.paths = deltaService_1.deltaService.array(props.paths, Path_1.Path);
        this.categories = deltaService_1.deltaService.array(props.categories, Category_1.Category);
        this.articles = deltaService_1.deltaService.array(props.articles, Article_1.Article);
        this.urls = deltaService_1.deltaService.array(props.urls, Url_1.Url);
        this.collections = deltaService_1.deltaService.array(props.collections, Collection_1.Collection);
    }
    return Page;
}());
exports.Page = Page;
