"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CATEGORY_MINUS_1 = exports.CATEGORY = void 0;
exports.CATEGORY = {
    LUMINAIRE: 1,
    TABLE: 2,
    CHAISE: 3,
    TABOURET: 4,
    FAUTEUIL: 5,
    CANAPE: 6,
    BAIN_DE_SOLEIL: 7,
    PARASOL: 8,
    ACCESSOIRE: 10,
    DOUCHE: 46,
};
exports.CATEGORY_MINUS_1 = {
    ACCOUDOIR: 11,
    TABLE_BASSE: 12,
    TABLE_FIXE: 13,
    TABLE_EXTENSIBLE: 14,
    TABLE_BISTRO: 15,
    DECORATION: 16,
    TOILE_DE_PARASOL: 17,
    LAMPADAIRE: 18,
    TORCHE: 19,
    LANTERNE: 20,
    BORNE: 21,
    POUF: 22,
    BAC_A_FLEURS: 23,
    CENTRE_DE_TABLE: 24,
    TABOURET_DE_BAR: 25,
    NETTOYANT: 26,
    APPLIQUE_MURAL: 27,
    PLATEAU_HPL: 28,
    TABLE_BASCULANTE: 29,
    SOCLE_DE_PARASOL: 30,
    TABLETTE_ANGLE: 31,
    TABLETTE_DROITE: 32,
    COUSSIN: 33,
    TABLE_HAUTE: 34,
    HOUSSE_DE_PROTECTION: 35,
    MEUBLE_DE_RANGEMENT: 36,
    LIT_BALINAIS: 37,
    ROULETTES: 38,
    TABLE_BALCON_EXTENSIBLE: 39,
    KIT_ACCOUDOIRS_FIXE: 40,
    BAIN_DE_SOLEIL_FIXE: 41,
    KIT_DE_FIXATION: 42,
    CHILIENNE: 43,
    TABLE_AUTO_ELEVATRICE: 44,
    TABLE_BOUT_DE_CANAPE: 45,
    KIT_DE_ROBINETTERIE: 47,
    BANC: 48,
    ARMATURE_POUR_CHILIENNE_FIXE: 49,
    TOILE_BATYLINE_FIXE: 50,
    TETIERE_DE_PLAGE_FIXE: 51,
    METTEUR_EN_SCENE: 52,
    SOCLE: 53,
    SUPPORT_MURAL: 54,
    PROTECTEUR: 55,
    RAVIVEUR_PROTECTEUR: 56,
    NETTOYANT_PROTECTEUR: 57,
    POLISH_CHROME: 58,
    PINCEAU: 59,
    PAILLE: 60,
    FAUTEUIL_EMPILABLE: 61,
    CHAISE_PLIANTE: 62,
    COUSSIN_FIXE: 63,
};
