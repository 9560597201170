<style scoped lang="scss">

@import '../../scss/color';

.CatalogueArticle {
  background: rgba(0, 0, 0, 0.03);
  width: 100%;
  height: 100%;
  transition: background 500ms;
  overflow: hidden;
}

.CatalogueArticle:hover {
  background: rgba(0, 0, 0, 0.06);
}

.CatalogueArticle ::v-deep .Link {
  display: block;
  width: 100%;
  height: 100%;
}

.CatalogueArticle ::v-deep * {
  text-decoration: none;
}

.CatalogueArticle ::v-deep .Outlet--term {
  display: none;
}

/**/

.CatalogueArticle--square {
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
  position: relative;
}

.CatalogueArticle-pan .CatalogueArticle--square {
  justify-content: space-between;
}

/**
 * * * * * * * * *
 *  HEADER
 * * * * * * * * *
 */

.CatalogueArticle--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  //position: absolute;
  //top: 0;
  //left: 0;
  width: 100%;
  //height: 50px;
  box-sizing: border-box;
  padding: 30px 30px 0px 30px;
  opacity: 1;
  transition: 500ms;
}

.CatalogueArticle-pan .CatalogueArticle--header {
  z-index: 1;
}

.CatalogueArticle-small .CatalogueArticle--header {
  padding: 25px 25px 0px 25px;
}

.CatalogueArticle--square:hover .CatalogueArticle--header {
  opacity: 0;
}

/**/

.CatalogueArticle--headerLeft {
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  height: 100%;
}

.CatalogueArticle--headerRight {
  display: flex;
  justify-content: flex-end;
  height: 100%;
  align-items: flex-start;
}

.CatalogueArticle--batch {
  border: solid 1px rgba(128, 128, 128, 1);
  color: rgba(128, 128, 128, 1);
  font-family: 'CommutersSansBold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 0.15px;
  border-radius: 3px;
}

.CatalogueArticle-big .CatalogueArticle--batch {
  font-size: 9px;
  padding: 6px 13px 6px 13px;
}

.CatalogueArticle-small .CatalogueArticle--batch {
  font-size: 8px;
  padding: 3px 7px 3px 7px;
}

.CatalogueArticle-pan .CatalogueArticle--batch {
  border: solid 1px rgba(255, 255, 255, 1);
  color: rgba(255, 255, 255, 1);
}

.CatalogueArticle--batchRupture {
  /*border: solid 1px #F56C6C;*/
  /*color: #F56C6C;*/
}

/**/

.CatalogueArticle--new {
  margin-left: 8px;
  display: inline-block;
}

.CatalogueArticle-small .CatalogueArticle--new ::v-deep .ArticleNew {
  padding: 4px 8px 4px 8px;
  font-size: 8px;
}

.CatalogueArticle-big .CatalogueArticle--new {

}

/**/

.CatalogueArticle--outlet {
  display: none;
  align-items: baseline;
  position: relative;
}

.CatalogueArticle-isOutlet .CatalogueArticle--outlet {
  display: flex;
}

.CatalogueArticle-small .CatalogueArticle--outlet {
  transform: scale(0.8);
  position: relative;
  top: -5px;
  left: -5px;
}

.CatalogueArticle-pan .CatalogueArticle--outlet ::v-deep .Outlet--term {
  color: white;
}

/**
 * * * * * * * * *
 *  BODY
 * * * * * * * * *
 */

.CatalogueArticle--body {
  cursor: pointer;
  box-sizing: border-box;
  transform: scale(1);
  flex: 1;
  transition: transform 500ms;
}

.CatalogueArticle--bodyContent ::v-deep .Picture {
  width: 100%;
  height: 100%;
}

.CatalogueArticle-adapt.CatalogueArticle:hover .CatalogueArticle--body {
  transform: scale(0.9);
}

.CatalogueArticle-adapt .CatalogueArticle--body {
  padding: 30px 60px;
}

.CatalogueArticle-adapt.CatalogueArticle-small .CatalogueArticle--body {
  padding: 25px;
}

.CatalogueArticle-pan .CatalogueArticle--body {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
}

.CatalogueArticle--bodyContent {
  height: 100%;
  width: 100%;
  //position: relative;
  align-items: center;
  display: inline-flex;
}

.CatalogueArticle--mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: opacity 250ms;
  background: linear-gradient(180deg, rgba(4, 5, 29, 0.4) 0%, rgba(4, 5, 29, 0) 30%, rgba(4, 5, 29, 0) 100%);
}

.CatalogueArticle-adapt .CatalogueArticle--mask {
  display: none;
}

.CatalogueArticle--mask-bottom {
  transform: rotate(180deg);
}

/**
 * * * * * * * * *
 *  FOOTER
 * * * * * * * * *
 */

.CatalogueArticle--footer {
  //position: absolute;
  //bottom: 0;
  //left: 0;
  width: 100%;
  //height: 100px;
  box-sizing: border-box;
  display: flex;
  align-items: flex-end;
  justify-content: space-between;
}

.CatalogueArticle-pan .CatalogueArticle--footer {
  z-index: 1;
}

.CatalogueArticle-big .CatalogueArticle--footer {
  padding: 0 30px 30px 30px;
}

.CatalogueArticle-small .CatalogueArticle--footer {
  padding: 0 25px 25px 25px;
}

.CatalogueArticle--footerLeft {
  flex: 1;
  justify-content: flex-end;
  align-items: flex-start;
  text-align: left;
  flex-direction: column;
  display: flex;
}

.CatalogueArticle--name {
  font-family: 'AeonikRegular', sans-serif;
  letter-spacing: 0.25px;
  transition: color 300ms;
  font-weight: normal;
  text-transform: lowercase;
}

.CatalogueArticle--name:first-letter {
  text-transform: uppercase;
}

.CatalogueArticle-big .CatalogueArticle--name {
  font-size: 16px;
  line-height: 24px;
}

.CatalogueArticle-small .CatalogueArticle--name {
  font-size: 15px;
  line-height: 22px;
}

.CatalogueArticle-adapt .CatalogueArticle--name {
  color: #262626;
}

.CatalogueArticle:hover.CatalogueArticle-adapt .CatalogueArticle--name {
  color: #262626;
}

.CatalogueArticle-pan .CatalogueArticle--name {
  color: white;
}

.CatalogueArticle--price {
  padding-top: 8px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.CatalogueArticle-pan .CatalogueArticle--price ::v-deep .Price--big {
  color: white;
}

.CatalogueArticle-adapt .CatalogueArticle--price ::v-deep .Price--big {
  color: $primary;
}

.CatalogueArticle-isOutlet.CatalogueArticle-pan .CatalogueArticle--price ::v-deep .Price--big {
  color: white;
}

.CatalogueArticle-isOutlet.CatalogueArticle-adapt .CatalogueArticle--price ::v-deep .Price--big {
  color: $danger;
}

.CatalogueArticle-big .CatalogueArticle--price ::v-deep .Price--big {
  font-size: 20px;
}

.CatalogueArticle-small .CatalogueArticle--price ::v-deep .Price--big {
  font-size: 14px;
}

.CatalogueArticle-pan .CatalogueArticle--price ::v-deep .Price--small {
  color: white;
  text-decoration: line-through;
}

.CatalogueArticle-adapt .CatalogueArticle--price ::v-deep .Price--small {
  color: $danger;
  text-decoration: line-through;
}

.CatalogueArticle-pan .CatalogueArticle--price ::v-deep .Price--small span {
  color: rgba(255, 255, 255, 0.85);
}

.CatalogueArticle-adapt .CatalogueArticle--price ::v-deep .Price--small span {
  color: rgba(0, 0, 0, 0.5);
}

/**/

.CatalogueArticle--footerRight {
  min-width: 80px;
  min-height: 80px;
  width: 80px;
  height: 80px;
  border-radius: 80px;
  margin-left: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  transform: translateY(100%);
  opacity: 0;
  transition: 500ms;
}

.CatalogueArticle-pan .CatalogueArticle--footerRight {
  border: solid 1px white;
}

.CatalogueArticle-small .CatalogueArticle--footerRight {
  min-width: 50px;
  min-height: 50px;
  width: 50px;
  height: 50px;
  border-radius: 50px;
}

.CatalogueArticle-adapt .CatalogueArticle--footerRight {
  border: solid 1px #31302F;
}

.CatalogueArticle--square:hover .CatalogueArticle--footerRight {
  transform: translateY(0);
  opacity: 1;
}

/**/

.CatalogueArticle--footerRightCross {
  width: 24px;
  height: 24px;
  position: relative;
  transform: scale(0);
  transition: 500ms;
}

.CatalogueArticle-small .CatalogueArticle--footerRightCross {
  width: 16px;
  height: 16px;
}

.CatalogueArticle--square:hover .CatalogueArticle--footerRightCross {
  transform: scale(1);
}

.CatalogueArticle--square .CatalogueArticle--footerRight:hover .CatalogueArticle--footerRightCross {
  transform: scale(0.5);
}

/**/

.CatalogueArticle--barreHorizontal {
  position: absolute;
  top: 50%;
  left: 0;
  width: 100%;
  height: 1px;
}

.CatalogueArticle-pan .CatalogueArticle--barreHorizontal {
  background: white;
}

.CatalogueArticle-adapt .CatalogueArticle--barreHorizontal {
  background: #31302F;
}

/**/

.CatalogueArticle--barreVertical {
  position: absolute;
  top: 0;
  left: 50%;
  margin-left: -1px;
  height: 100%;
  width: 1px;
}

.CatalogueArticle-pan .CatalogueArticle--barreVertical {
  background: white;
}

.CatalogueArticle-adapt .CatalogueArticle--barreVertical {
  background: #31302F;
}

</style>

<template>
  <div class="CatalogueArticle" :class="class_root" @click="() => onClick()">
    <Link :link="getHref(article)">
      <div class="CatalogueArticle--square">

        <div class="CatalogueArticle--header">
          <div class="CatalogueArticle--headerLeft">
            <div class="CatalogueArticle--outlet">
              <CountryLocation>
                <Outlet :percentage="percentage"/>
              </CountryLocation>
            </div>
          </div>
          <div class="CatalogueArticle--headerRight">

            <template
                v-if="[ROLE.ROOT, ROLE.ADMIN, ROLE.CHR, ROLE.SELLER].includes(state.roleId) && getArticleStockBrowser">
              <div class="CatalogueArticle--batch">
                <span>{{ getArticleStockBrowser }}&nbsp;</span>
                <Term :key="1" :term="lang.term(408)"/>
              </div>
            </template>
            <template v-if="(getArticleStockBrowser < 1)">
              <div class="CatalogueArticle--batch CatalogueArticle--batchRupture">
                <Term :key="2" :term="lang.term(503)"/>
              </div>
            </template>

            <template
                v-if="![ROLE.ROOT, ROLE.ADMIN, ROLE.CHR, ROLE.SELLER].includes(state.roleId) && getArticleStockBrowser && article.priorityDisplay === 1">
              <div class="CatalogueArticle--new">
                <ArticleNew :article="article"></ArticleNew>
              </div>
            </template>
          </div>
        </div>

        <div class="CatalogueArticle--body">
          <div class="CatalogueArticle--bodyContent">
            <template v-for="termName in findDefaultArticleTermNames">
              <Picture :key="termName.id" :file="image.file" :alt="lang.translateTerm(termName)"
                       :display="pictureDisplay"/>
            </template>
          </div>
          <div class="CatalogueArticle--mask CatalogueArticle--mask-top"></div>
          <div class="CatalogueArticle--mask CatalogueArticle--mask-bottom"></div>
        </div>

        <div class="CatalogueArticle--footer">
          <div class="CatalogueArticle--footerLeft">
            <h2 class="CatalogueArticle--name" v-for="termName in findDefaultArticleTermNames" :key="termName.id">
              {{ lang.translateTerm(termName) }}
            </h2>

            <CountryLocation>
              <div class="CatalogueArticle--price">
                <Price :amount="calculateTTC(article.price)"
                       :outletAmount="calculatePromotion(calculateTTC(article.price), percentage)"
                       :isOutlet="isOutlet">
                </Price>
              </div>
            </CountryLocation>
          </div>
          <div class="CatalogueArticle--footerRight">
            <div class="CatalogueArticle--footerRightCross">
              <div class="CatalogueArticle--barreHorizontal"></div>
              <div class="CatalogueArticle--barreVertical"></div>
            </div>
          </div>
        </div>
      </div>
    </Link>
  </div>
</template>

<script>

import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {Article} from "../../../@common/delta/database/Article";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {PICTURE_DISPLAY} from "../../../@common/constant/PICTURE_DISPLAY";
import {FILTER_DISPLAY} from "../../../@common/constant/FILTER_DISPLAY";
import {calculateTTC} from "../../../@common/function/calculateTTC";
import {formatPrice} from "../../../@common/function/formatPrice";
import {Photo} from "../../../@common/delta/database/Photo";
import {findCoverPhoto} from "../../function/findCoverPhoto";
import {calculatePromotion} from "../../../@common/function/calculatePromotion";
import {findDefaultArticleTermNames} from "../../function/findDefaultArticleTermNames";
import {ROLE} from "../../../@common/constant/ROLE";
import {STATE_ROLE_ID, stateService} from "../../service/stateService";
import {eventService} from "../../service/eventService";
import {EVENT} from "../../../@common/constant/EVENT";
import {getArticleStockBrowser} from "../../function/getArticleStockBrowwser";
import {convertPathToUrl} from "../../../@common/function/helperUrl";
import {Page} from "../../../@common/delta/database/Page";
import {urlService} from "../../../@common/service/urlService";
import {Url} from "../../../@common/delta/database/Url";
import {apiClient} from "../../service/apiClientService";
import selectArticles from "../../../@common/api/article/selectArticles";

export default {
  props: {
    article: {
      default: () => new Article()
    },
    display: {
      type: String,
      default: () => FILTER_DISPLAY.BIG
    },
    index: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      PICTURE_DISPLAY,
      ROLE,
      lang: getLangServiceBrowser(),
      props: {},
      icon: {
        add_box: require('@bbx/vector~master/core/assets/svg/material/add_box.svg'),
        shopping_basket: require('@bbx/vector~master/core/assets/svg/material/shopping_basket.svg'),
      },
      state: {
        roleId: ROLE.CUSTOMER,
      },
    }
  },
  watch: {},
  computed: {
    findDefaultArticleTermNames() {
      return findDefaultArticleTermNames(this.article)
    },
    test() {
      let text = ''
      let lotText = 'lot'
      let ensembleText = 'ensemble'

      if (this.article.batches.length > 1) {
        text = ensembleText
      } else {
        for (const batch of this.article.batches) {
          if (batch.quantity > 1) {
            text = lotText
          }
        }
      }
      return text
    },
    class_root() {
      const classes = [];
      if (this.display === FILTER_DISPLAY.BIG) classes.push(`CatalogueArticle-big`)
      if (this.display === FILTER_DISPLAY.SMALL) classes.push(`CatalogueArticle-small`)
      if (this.pictureDisplay === PICTURE_DISPLAY.COVER) classes.push(`CatalogueArticle-pan`)
      if (this.pictureDisplay === PICTURE_DISPLAY.AUTO) classes.push(`CatalogueArticle-adapt`)
      if (this.isOutlet) classes.push(`CatalogueArticle-isOutlet`)
      return classes;
    },
    /**
     *
     * @param {Photo} photo
     * @returns {string}
     */
    pictureDisplay() {
      return this.image.photo.full ? PICTURE_DISPLAY.COVER : PICTURE_DISPLAY.AUTO
    },
    image() {
      return findCoverPhoto(this.article)
    },
    isOutlet() {
      return this.percentage > 0 && this.percentage < 100 && this.state.roleId !== ROLE.SELLER
    },
    getArticleStockBrowser() {
      return getArticleStockBrowser({article: this.article})
    },
    percentage() {
      let percentage = 0
      for (const promotion of this.article.promotions) {
        percentage = promotion.percentage
      }
      return percentage
    }
  },
  beforeMount() {
    this.state.roleId = stateService.get(STATE_ROLE_ID)
    stateService.watch(STATE_ROLE_ID, v => this.state.roleId = v)
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    calculateTTC,
    formatPrice,
    calculatePromotion,
    async onClick() {
      // fetch the $scope1 of the article with more data before send
      const {data} = await apiClient.do(selectArticles, selectArticles.with({
        $scope: 1,
        $ids: [this.article.id]
      }))
      const article = new Article(data[0])
      eventService.triggerByName(new ListenerTrigger({
        name: EVENT.CLICK_ARTICLE,
        payload: {
          article: article,
          source: 'catalogue-search',
          index: this.index
        }
      }))
    },
    /**
     * @param {Article} article
     */
    getHref(article) {
      const page = convertPathToUrl(new Page(article.pages[0]))
      return urlService.getProjectUrl(this.lang.lang.iso6391, new Url(page.urls[0]))
    },
  }
}
</script>
