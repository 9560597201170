"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findCoverPhoto = void 0;
var File_1 = require("../../@common/delta/database/File");
var Photo_1 = require("../../@common/delta/database/Photo");
function findCoverPhoto(article, attribute) {
    if (attribute === void 0) { attribute = 'cover'; }
    var fileFound = new File_1.File();
    var photoFound = new Photo_1.Photo();
    // cover photo first
    for (var _i = 0, _a = article.photos; _i < _a.length; _i++) {
        var photo = _a[_i];
        if (photo[attribute] && photo.files[0]) {
            fileFound = new File_1.File(photo.files[0]);
            photoFound = new Photo_1.Photo(photo);
            return {
                photo: photoFound,
                file: fileFound
            };
        }
    }
    if (!fileFound.id) {
        for (var _b = 0, _c = article.photos; _b < _c.length; _b++) {
            var photo = _c[_b];
            fileFound = new File_1.File(photo.files[0]);
            photoFound = new Photo_1.Photo(photo);
            return {
                photo: photoFound,
                file: fileFound
            };
        }
    }
    if (!fileFound.id) {
        for (var _d = 0, _e = article.batches; _d < _e.length; _d++) {
            var batch = _e[_d];
            for (var _f = 0, _g = batch.products; _f < _g.length; _f++) {
                var product = _g[_f];
                for (var _h = 0, _j = product.photos; _h < _j.length; _h++) {
                    var photo = _j[_h];
                    if (photo[attribute] && photo.files[0]) {
                        fileFound = new File_1.File(photo.files[0]);
                        photoFound = new Photo_1.Photo(photo);
                        return {
                            photo: photoFound,
                            file: fileFound
                        };
                    }
                }
            }
        }
    }
    if (!fileFound.id) {
        for (var _k = 0, _l = article.batches; _k < _l.length; _k++) {
            var batch = _l[_k];
            for (var _m = 0, _o = batch.products; _m < _o.length; _m++) {
                var product = _o[_m];
                for (var _p = 0, _q = product.photos; _p < _q.length; _p++) {
                    var photo = _q[_p];
                    fileFound = new File_1.File(photo.files[0]);
                    photoFound = new Photo_1.Photo(photo);
                    return {
                        photo: photoFound,
                        file: fileFound
                    };
                }
            }
        }
    }
    return {
        photo: photoFound,
        file: fileFound
    };
}
exports.findCoverPhoto = findCoverPhoto;
