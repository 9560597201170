<style scoped>
.AccountAddress {
  min-height: 100vh;
}

.AccountAddress--content {
  padding: 0 0px 50px 50px;
}

.AccountAddress-mobile .AccountAddress--content {
  padding: 0 0px 50px 0px;
}


.AccountAddress--header {
  display: flex;
  align-items: center;
  padding-bottom: 50px;
}

.AccountAddress-mobile .AccountAddress--header {
  justify-content: center;
}

.AccountAddress--addresses {
  display: flex;
  flex-flow: wrap;
}

.AccountAddress-mobile .AccountAddress--addresses {
  justify-content: center;
}

.AccountAddress--empty {
  border: dotted 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AccountAddress--address {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  margin-right: 25px;
  margin-bottom: 25px;
  width: 300px;
}

.AccountAddress-mobile .AccountAddress--address {
  margin-right: 0px;
  width: 100%;
}

.AccountAddress--addressHeader {
  display: flex;
  align-items: center;
  padding: 25px;
}

.AccountAddress--addressHeaderIcon {
  width: 24px;
}

.AccountAddress--addressHeaderName {
  font-family: 'RalewayBold', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  padding-left: 10px;
}

.AccountAddress--addressHeaderFavorite {
  flex: 1;
  display: flex;
  justify-content: flex-end;
}

.AccountAddress--addressHeaderFavoriteText {
  color: #8A6C59;
  border: solid 1px #8A6C59;
  padding: 4px 15px;
  border-radius: 2px;
  font-size: 10px;
  text-transform: uppercase;
  font-family: 'RalewayBold', sans-serif;
  letter-spacing: 1px;
}

.AccountAddress--addressBody {
  padding: 0 25px;
}

.AccountAddress--addressBody div {
  color: rgba(0, 0, 0, 0.5);
  padding-bottom: 10px;
}

.AccountAddress--addressBody div:first-child {
  color: rgba(0, 0, 0, 1);
}

.AccountAddress--addressFooter {
  display: flex;
  justify-content: flex-end;
}

.AccountAddress--optionIcon {
  width: 24px;
  cursor: pointer;
  padding: 10px 20px;
}

.AccountAddress--optionIcon ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 1);
}

.AccountAddress--optionIcon:hover ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 0.5);
}

.AccountAddress--modal {
  padding: 0px 50px 50px 50px;
}

.AccountAddress--formTitle {
  font-family: 'AmbroiseRegular', sans-serif;
}

.AccountAddress--formFooter {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
}

.AccountAddress--formFooter ::v-deep .ButtonAction {
  margin: 5px 15px;
}

.AccountAddress--formFooter ::v-deep .ButtonLink {
  margin: 5px 15px;
}

.AccountAddress--spacer {
  width: 30px;
  min-width: 30px;
  min-height: 30px;
}
</style>

<template>
  <div class="AccountAddress" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="AccountAddress--content">

        <div class="AccountAddress--header">
          <ButtonAction :term="lang.term(283)" :onClick="() => onClickAdd()" :svg="icon.add"/>
        </div>


        <LoaderSpace v-show="state.loadingSelect"></LoaderSpace>
        <Form>
          <div class="AccountAddress--addresses" v-show="!state.loadingSelect">
            <div class="AccountAddress--empty" v-show="!state.user.addresses.length">
              <Term :term="lang.term(326)"/>
            </div>
            <div class="AccountAddress--address" v-for="address in this.state.user.addresses" :key="'address' + address.id">
              <div class="AccountAddress--addressHeader">
                <div class="AccountAddress--addressHeaderIcon">
                  <Vector :svg="icon.location_on"></Vector>
                </div>
                <div class="AccountAddress--addressHeaderName">
                  {{ address.name }}
                </div>
                <div class="AccountAddress--addressHeaderFavorite" v-show="address.favorite">
                  <div class="AccountAddress--addressHeaderFavoriteText">
                    <Term :term="lang.term(323)"/>
                  </div>
                </div>
              </div>
              <div class="AccountAddress--addressBody">
                <div v-for="street in address.streets" :key="'street' + street.id">{{ street.value }}</div>
                <div>{{ address.city }}, {{ address.zipCode }}</div>
                <div v-for="country in address.countries" :key="'country' + country.id">{{ country.name }}</div>
              </div>
              <div class="AccountAddress--addressFooter">
                <Popover position="bottom">
                  <template slot="default">
                    <List :svg="icon.edit" :text="lang.translate(281)" :onClick="() => onClickEdit(address)"/>
                    <List :svg="icon.delete_forever" :text="lang.translate(282)" :onClick="() => onClickDelete(address)"/>
                  </template>
                  <template slot="reference">
                    <div class="AccountAddress--optionIcon">
                      <Vector :svg="icon.more_horiz"></Vector>
                    </div>
                  </template>
                </Popover>
              </div>
            </div>
          </div>
        </Form>

        <Modal :opened="state.openedInsert" :onOpenedChange="v => state.openedInsert = v">
          <div class="AccountAddress--modal">
            <Form>
              <!--              <Term :term="lang.term(271)" tag="h2"/>-->
              <!--              <br>-->
              <!--              <Divider/>-->
              <!--              <br>-->
              <AddressForm :address="state.addressInsert"
                           :form="state.form"
                           :input-hiddens="['country', 'geographicalPositions']"
                           :onAddressChange="v => state.addressInsert = v">
              </AddressForm>
              <br>
              <Divider/>
              <br>
              <div class="AccountAddress--formFooter">
                <ButtonAction :term="lang.term(158)" :onClick="() => insert()" :loading="state.loadingCreate"/>
                <!--                <div class="AccountAddress&#45;&#45;spacer"></div>-->
                <ButtonLink :term="lang.term(159)" :onClick="() => state.openedInsert = false"/>
              </div>
            </Form>
          </div>
        </Modal>


        <Modal :opened="state.openedUpdate" :onOpenedChange="v => state.openedUpdate = v">
          <div class="AccountAddress--modal">
            <Form>
              <!--              <Term :term="lang.term(271)" tag="h2"/>-->
              <!--              <br>-->
              <!--              <Divider/>-->
              <!--              <br>-->
              <AddressForm :address="state.addressUpdate"
                           :form="state.form"
                           :input-hiddens="['country', 'geographicalPositions']"
                           :onAddressChange="v => state.addressUpdate = v">
              </AddressForm>
              <br>
              <Divider/>
              <br>
              <div class="AccountAddress--formFooter">
                <ButtonAction :term="lang.term(158)" :onClick="() => update()" :loading="state.loadingCreate"/>
                <!--                <div class="AccountAddress&#45;&#45;spacer"></div>-->
                <ButtonLink :term="lang.term(159)" :onClick="() => state.openedUpdate = false"/>
              </div>
            </Form>
          </div>
        </Modal>


        <ModalConfirm :opened="state.openedDelete"
                      :loading="state.loadingCreate"
                      :onOpenedChange="v => state.openedDelete = v"
                      :onValidated="() => remove()">
        </ModalConfirm>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {apiClient} from "../../service/apiClientService";
import selectUsers from "../../../@common/api/user/selectUsers";
import {User} from "../../../@common/delta/database/User";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {STATE_TOKEN, stateService} from "../../service/stateService";
import {Address} from "../../../@common/delta/database/Address";
import {Street} from "../../../@common/delta/database/Street";
import {controlForm} from "../../function/controlForm";
import createAddress from "../../../@common/api/address/createAddress";
import {sendError} from "../../function/sendError";

export default {

  props: {},
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 920
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        add_box: require('@bbx/vector~master/core/assets/svg/material/add_box.svg'),
        more_horiz: require('@bbx/vector~master/core/assets/svg/material/more_horiz.svg'),
        edit: require('@bbx/vector~master/core/assets/svg/material/edit.svg'),
        delete_forever: require('@bbx/vector~master/core/assets/svg/material/delete_forever.svg'),
        location_on: require('@bbx/vector~master/core/assets/svg/material/location_on.svg'),
        add: require('@bbx/vector~master/core/assets/svg/material/add.svg'),
      },
      state: {
        /**
         * @type ApiResponseForm
         */
        form: new ApiResponseForm(),
        /**
         * @type boolean
         */
        loadingSelect: false,
        /**
         * @type boolean
         */
        loadingCreate: false,
        /**
         * @type boolean
         */
        openedInsert: false,
        /**
         * @type boolean
         */
        openedUpdate: false,
        /**
         * @type boolean
         */
        openedDelete: false,
        /**
         * @type User
         */
        user: new User(),
        /**
         * @type Address
         */
        addressInsert: this.defaultAddress(),
        /**
         * @type Address
         */
        addressUpdate: new Address(),
        /**
         * @type string
         */
        token: '',
      },
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`AccountAddress-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`AccountAddress-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`AccountAddress-desktop`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
    this.state.token = stateService.get(STATE_TOKEN)
    stateService.watch(STATE_TOKEN, v => this.state.token = v)
    this.init().catch(sendError);
  },
  beforeDestroy() {
  },
  methods: {
    defaultAddress() {
      return new Address({
        streets: [
          new Street(),
          new Street(),
        ]
      })
    },
    async init() {
      await Promise.all([
        this.selectUser(),
      ])
    },
    async selectUser() {
      this.state.loadingSelect = true
      const {data} = await apiClient.do(selectUsers, selectUsers.with({
        $token: this.state.token
      }))
      this.state.user = new User(data[0]);
      this.state.loadingSelect = false;
    },
    onClickAdd() {
      this.state.addressInsert = this.defaultAddress()
      this.state.openedInsert = true
    },
    onClickEdit(address) {
      this.state.addressUpdate = new Address(address)
      this.state.openedUpdate = true
    },
    onClickDelete(address) {
      this.state.addressUpdate = new Address(address)
      this.state.openedDelete = true
    },
    async insert() {
      this.state.addressInsert.users = [
        this.state.user
      ]
      await controlForm({
        apiAction: createAddress,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loadingCreate',
        loaderState: this.state,
        payload: this.state.addressInsert,
        vue: this,
      });
      await this.selectUser()
      this.state.openedInsert = false
    },
    async update() {
      this.state.addressUpdate.users = [
        this.state.user
      ]
      await controlForm({
        apiAction: createAddress,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loadingCreate',
        loaderState: this.state,
        payload: this.state.addressUpdate,
        vue: this,
      });
      await this.selectUser()
      this.state.openedUpdate = false
    },
    async remove() {
      this.state.addressUpdate.users = [
        this.state.user
      ]
      await controlForm({
        apiAction: createAddress,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loadingCreate',
        loaderState: this.state,
        payload: {
          id: this.state.addressUpdate.id,
          active: false,
        },
        vue: this,
      });
      await this.selectUser()
      this.state.openedDelete = false
    },
  }
}
</script>
