<style scoped>
.ButtonLink {
  position: relative;
}

.ButtonLink ::v-deep .Link {
  width: 100% !important;
  display: block !important;
}

.ButtonLink ::v-deep .Vector {
  fill: black !important;
}

.ButtonLink ::v-deep button {
  border-color: transparent !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15) !important;
  transition: box-shadow 300ms !important;
  background: white !important;
  color: black !important;
}

.ButtonLink ::v-deep button:hover {
  background: white !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25) !important;
}

.ButtonLink ::v-deep button:active {
  background: white !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15) !important;
}

.ButtonLink ::v-deep button:focus {
  background: white !important;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15) !important;
}
</style>

<template>
  <div class="ButtonLink">

    <template v-if="url.id">
      <Link :url="url">
        <ButtonAction v-bind="$props"></ButtonAction>
      </Link>
    </template>
    <template v-else>
      <Link :pathIds="pathIds">
        <ButtonAction v-bind="$props"></ButtonAction>
      </Link>
    </template>

  </div>
</template>

<script>

import {ButtonProp} from "./ButtonProp";
import {Url} from "../../../@common/delta/database/Url";

export default {
  props: Object.assign({}, ButtonProp, {
    pathIds: {
      type: Array,
      default: () => ([])
    },
    url: {
      type: Url,
      default: () => new Url
    },
  }),
  data() {
    return {
      props: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = [];
      return classes;
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
