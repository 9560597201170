<style scoped>

</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="content-wrapper none for-title">
      <div class="container_12">
        <div class="main-container main-main-container col1-layout">
          <div class="grid_12 col-main">
            <div class="page-title">
              <h1>Ajouter un mobilier de jardin qui se fond dans le décor</h1>
            </div>
            <div class="std">
              <p style="max-height: 250px; margin-bottom: 20px; overflow: hidden; text-align: justify;">
                <img alt="" src="./media/wysiwyg/5.jpg">
              </p>
              <p style="max-height: 250px; margin-bottom: 20px; overflow: hidden; text-align: justify;">
                <span style="font-size: large;">Avec les soldes, on commence à se projeter dans son jardin pour le
                  printemps. Alors que celui-ci commence à reprendre un éclat vert plus poussé, que les arbres et
                  arbustes plantés en automne commence à apparaitre, on aimerait se choisir le bon mobilier qui se fond
                  parfaitement dans son jardin. Découvrez nos conseils pour choisir le mobilier adapté en fonction de
                  votre jardin.
                </span>
              </p>
              <h2 style="text-align: justify;">Un jardin entretenu, un jardin qui donne envie d’y rester !</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Prendre plaisir à jardiner amène à vouloir passer plus de temps dans son
                  jardin. En effet, non seulement, vous apprécierez prendre le temps de vous occuper de vos plantations,
                  mais également de les voir s’épanouir sous vos yeux au quotidien. Pour cela, rien de mieux que d’avoir
                  un mobilier de jardin dans lequel vous serez à l’aise pour cultiver et jardiner mais aussi pour en
                  profiter.
                </span>
              </p>
              <h3 style="margin-top: 20px; text-align: justify;">Jardin traditionnel</h3>
              <p style="text-align: justify;">&nbsp;<span style="font-size: large;">Vous avez un jardin que l’on
                qualifie de traditionnel ? Des
                conifères
                , des rosiers, des daphnés ou encore des agapanthes remplissent votre jardin de senteurs et de couleurs
                tout au long de l’année. Avec ce type de jardin, adoptez pour une table de jardin qui pourra vous
                apporter un lieu idéal pour recevoir et partager un repas mais également pour déposer des plantes en pot
                si vous souhaitez leur faire prendre un peu de hauteur.
              </span>
              </p>
              <h3 style="margin-top: 20px; text-align: justify;">Jardin de type bord de mer</h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">Les jardins de type bord de mer sont souvent composés d’agaves, d’
                  albizias de roses trémières, de gazons d’Espagne et de lilas de Californie, entre autres. On apprécie
                  donc de pouvoir se prélasser dans son jardin parmi toutes ces fleurs et ces senteurs. Il est alors
                  idéal d’adapter son jardin avec un bar d’extérieur que vous pourrez apprécier lors de vos soirées
                  d’été en rentrant de la plage mais également de bain de soleil.
                </span>
              </p>
              <h3 style="margin-top: 20px; text-align: justify;">Jardin japonais</h3>
              <p style="margin-top: 20px; text-align: justify;">
                <span style="font-size: large;">Si vous avez aménagé votre jardin comme un
                 jardin japonais
                  , alors vous avez fait attention à ne pas avoir de ligne droite dans votre espace vert, mais des
                  lignes sinueuses et vous avez associé le minéral au végétal. Idem pour votre mobilier d’extérieur,
                  pensez minéral et végétal tout en gardant la sobriété. N’hésitez pas à n’avoir qu’un nombre impair de
                  chaise autour de votre table d’extérieur. En effet, les nombres impairs sont supposés porter chance.
                </span>
              </p>
              <p style="margin-top: 20px; text-align: justify; font-size: large; display: flex;flex-wrap: wrap;justify-content: center;">
                <img style="width: 50%" alt="" src="./media/wysiwyg/5a.jpg"/>
                <img style="width: 50%" alt="" src="./media/wysiwyg/5b.jpg"/>
              </p>
              <p style="margin-top: 20px; text-align: justify;">&nbsp;</p>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../../function/getH1";
import {Term} from "../../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../../@common/constant/PROJECT_URL";
import {PATHS} from "../../../../@common/constant/PATHS";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    },
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FAQ-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
