<template>
      <json-viewer
          :value="json"
          :expand-depth=5
          copyable
          sort></json-viewer>
</template>

<script>


export default {

  props: {
    json: {}
  }
}
</script>
