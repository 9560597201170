<style scoped lang="scss">

@import "../../scss/color";

.ButtonAction {
  position: relative;
}

.ButtonAction button {
  width: 100%;
  font-family: 'MontserratSemiBold', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  padding: 13px;
  transition: initial;
  border: none;
  background: $primary;
  //background: black;
  color: white;
  border-radius: 50px;
  cursor: pointer;
  outline: none;
  display: flex;
  align-items: center;
  justify-content: center;
  letter-spacing: 0.25px;
}

.ButtonAction.ButtonAction-svg button {
  /*padding: 15px 65px 14px 80px;*/
}

.ButtonAction.ButtonAction-loading button {
  /*padding-top: 9px;*/
  /*padding-bottom: 9px;*/
}

.ButtonAction.ButtonAction-disabled button {
  cursor: not-allowed;
}

.ButtonAction ::v-deep .el-button > span {
  display: flex;
  align-items: center;
  justify-content: center;
}

.ButtonAction--iconBox {
  height: 24px;
  width: 24px;
  position: relative;
  overflow: hidden;
  padding-right: 20px;
}

.ButtonAction-svg .ButtonAction--iconBox {
  left: -8px;
}

.ButtonAction--icon {
  position: absolute;
  top: -10px;
  left: -8px;
  width: 40px;
  height: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.ButtonAction--icon ::v-deep .Vector {
  width: 18px;
  height: 18px;
  fill: white;
}

.ButtonAction--term {
  line-height: 24px;
  position: relative;
}

.ButtonAction-svg .ButtonAction--term {
  left: -8px;
}
</style>

<template>
  <div class="ButtonAction" @click="() => disabled ? () => {} : onClick()" :class="class_root" :style="style_root">
    <button type="submit" :disabled="loading || disabled">
      <div v-show="!loading && svg" class="ButtonAction--iconBox">
        <div class="ButtonAction--icon">
          <Vector :svg="svg"></Vector>
        </div>
      </div>
      <div v-show="!loading" class="ButtonAction--term">
        <Term :term="term"></Term>
        <slot></slot>
      </div>
      <Loader v-show="loading" :rgb="[255,255,255]" :size="20"></Loader>
    </button>
  </div>
</template>

<script>

import {ButtonProp} from "./ButtonProp";

export default {
  props: ButtonProp,
  data() {
    return {
      props: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = [];
      if (this.loading) classes.push(`ButtonAction-loading`)
      if (this.disabled) classes.push(`ButtonAction-disabled`)
      if (this.svg) classes.push(`ButtonAction-svg`)
      return classes;
    },
    style_root() {
      const style = {
        minWidth: this.minWidth ? '175px' : 'auto',
      };
      return style;
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
