<style scoped>

</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="content-wrapper none for-title">
      <div class="container_12">
        <div class="main-container main-main-container col1-layout">
          <div class="grid_12 col-main">
            <div class="page-title">
              <h1>Comment rénover son mobilier en bois ?</h1>
            </div>
            <div class="std">
              <p style="text-align: justify;">
                <img alt="" src="./media/wysiwyg/bandeaubois.jpg">
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Vous souhaitez donner un coup de jeune à votre
                  <b>vieille table en bois</b>
                  ou moderniser les chaises récupérées chez mamie&nbsp;? Découvrez les différentes étapes nécessaires
                  pour la rénovation de
                  <b>vos meubles en bois</b>
                  .&nbsp;
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <h2 style="text-align: justify;">Préparer son meuble</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Avant de pouvoir
                  <b>peindre un meuble</b>
                  ou le teinter, par exemple, la première étape est de le remettre à
                  <b>l’état brut</b>
                  , de le traiter et de réparer les éventuels dégâts causés par des parasites.
                </span>
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h3 style="text-align: justify;">Décaper</h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le but du
                  <b>décapage</b>
                  est de remettre votre meuble à nu. Il est utile pour du mobilier peint ou un meuble trop verni.
                  Appliquez un décapant universel, ou spécial bois, avec un pinceau et retirez les morceaux de
                  <b>peinture</b>
                  ou de vernis à l’aide d’un grattoir, une fois que le produit aura agi. Terminez votre décapage en
                  passant un chiffon pour éliminer les éventuels résidus.
                </span>
              </p>
              <h3 style="text-align: justify;">Poncer</h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">Grâce au ponçage, les fibres du bois se soulèvent et permettent une
                  meilleure accroche au produit qui sera appliqué. Un
                  <b>papier de verre</b>
                  à grain fin est généralement suffisant, mais si le meuble est ciré ou verni, utilisez du papier de
                  verre à gros grain. Une
                  <b>ponceuse électrique</b>
                  vous sera utile pour les grandes surfaces comme le plateau d’une
                  table.
                </span>
              </p>
              <h3 style="text-align: justify;">Traiter le bois
                <span style="font-size: large;">&nbsp;</span>
              </h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">Les meubles anciens sont souvent victime de
                  <b>parasites</b>
                  . Les petits trous présents sur votre table en témoignent. Un produit adapté vous permettra de traiter
                  votre mobilier, pour vous débarrasser de ces indésirables. Vous pouvez également l’utiliser à titre
                  préventif, c’est d’ailleurs conseillé par les professionnels de la restauration. Étalez le produit à
                  l’aide d’un pinceau, jusqu’à ce que le bois ne soit plus capable d’en absorber. Votre meuble sera
                  ainsi traité en profondeur.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">&nbsp;<img alt="" src="./media/wysiwyg/boistable.jpg">
              </p>
              <p style="text-align: justify;">&nbsp;&nbsp;</p>
              <h3 style="text-align: justify;">Combler les trous</h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">Pour redonner un aspect neuf à votre mobilier, vous devrez reboucher
                  tous les petits trous présents dans le bois. Appliquez de la
                  <b>pâte à bois</b>
                  à l’aide d’une spatule pour combler les interstices. Une fois sèche,
                  <b>poncer</b>
                  l’excédent de produit pour obtenir une surface plane.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Donner un nouvel aspect à son mobilier</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Vous n’êtes pas un as du bricolage&nbsp;? Pas de paniques, différentes
                  possibilités s’offrent à vous pour rénover un
                  <b>meuble en bois</b>
                  . Il existe différentes solutions pour le remettre à neuf&nbsp;: lui donner un peu de couleur avec de
                  la peinture, garder l’aspect naturel du bois en le teintant ou le blanchir pour lui donner de la
                  personnalité et un aspect ancien. Quel que soit votre choix, pensez à bien dépoussiérer votre meuble
                  avant d’y appliquer un produit.
                </span>
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h3 style="text-align: justify;">Peindre</h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">Si vous souhaitez apporter de la
                  <b>couleur</b>
                  dans votre intérieur ou moderniser des
                  <b>chaises</b>
                  vieillissantes, par exemple, n’hésitez pas à peindre vos meubles en bois. Il existe des peintures
                  spécifiques pour le bois. Utilisez un pinceau pour les recoins et un rouleau laqueur pour les surfaces
                  planes. Faites des passes régulières et dans le sens du bois, puis laissez sécher, en respectant le
                  temps indiqué sur le pot, avant de passer une seconde couche. Même si le résultat vous semble
                  satisfaisant après la première couche, une deuxième couche vous permettra d’obtenir parfait et plus
                  durable.
                </span>
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h3 style="text-align: justify;">Teinter</h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">En appliquant une teinte, vous pourrez conserver l’aspect bois de votre
                  table tout en lui donnant une couleur. Vous pourrez choisir un ton comme le chêne doré ou le merisier
                  pour un effet naturel, mais vous trouverez aussi des teintures blanches ou grises, par exemple.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Utilisez un pinceau ou un chiffon pour appliquer la
                  <b>teinte</b>
                  , et suivez les fibres du bois. Travaillez rapidement pour obtenir un résultat uniforme. S’il n’est
                  pas satisfaisant, passez une deuxième couche en respectant les temps de séchage recommandés.
                </span>
              </p>
              <h3 style="text-align: justify;">Blanchir</h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">Vous pouvez donner un aspect vieilli à votre mobilier, grâce à la
                  <b>céruse</b>
                  qui fera ressortir les veines du bois, en les blanchissant. Cette technique ne convient pas à toutes
                  les essences de bois. Appliquez-la sur du chêne, du pin ou du châtaignier, par exemple, mais évitez
                  les bois exotiques dont les pores serrés se prêtent mal à l’utilisation de ce produit.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Commencez par frotter votre meuble avec une brosse en acier, dans le
                  sens des fibres du bois, et dépoussiérez-le. Appliquez la céruse à l’aide d’un pinceau, puis faites-la
                  pénétrer avec un chiffon. Laissez sécher pendant le temps indiqué sur le pot, puis égrainez légèrement
                  la surface, avec du
                  <b>papier de verre aux grains fins</b>
                  , afin que le bois ressorte sous la céruse.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <h2 style="text-align: justify;">Protéger son ameublement</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Il est important de passer un
                  <b>produit</b>
                  de finition après avoir restauré son
                  <b>mobilier en bois</b>
                  , pour le protéger un maximum de l’usure du temps. Différents produits existent, à vous de faire votre
                  choix en fonction du rendu final que vous souhaitez obtenir.
                </span>
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">
                  <img alt="" src="./media/wysiwyg/bois.jpg">
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <h3 style="text-align: justify;">Vernis</h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le vernis donne une finition transparente et brillante à vos meubles en
                  bois. Il protègera votre meuble des rayures et des tâches. Il s’applique avec un pinceau en suivant le
                  fil du bois. Deux à trois couches de
                  <b>vernis</b>
                  seront nécessaires pour un rendu lumineux et résistant.
                </span>
              </p>
              <h3 style="text-align: justify;">Cire</h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">La cire révèle la beauté naturelle du bois. Choisissez-en une incolore
                  pour protéger votre mobilier si vous l’avez teinté ou blanchi à la céruse.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Appliquez la
                  <b>cire</b>
                  avec un chiffon, en effectuant des mouvements circulaires. Prenez le temps de bien l’étirer et
                  laissez-la sécher pendant une heure avant de passer une deuxième couche. Celle-ci s’appliquera en
                  suivant les veines du bois, comme la troisième qui permettra, elle, d’obtenir un rendu brillant.
                </span>
              </p>
              <h3 style="text-align: justify;">Lasure</h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">La
                  <b>lasure</b>
                  s’imprègne en profondeur et donne donc un résultat plus durable. Elle laisse apparaître les veines du
                  <b>bois</b>
                  et aura un rendu naturel. Il existe une large gamme de lasure, il vous faudra choisir la couleur et la
                  finition avec soin, en fonction de l’essence du bois et de l’état de votre meuble.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Étalez-la généreusement avec un pinceau en suivant les fils du bois.
                  Pour un meilleur rendu, appliquez deux couches.
                </span>
              </p>
              <h3 style="text-align: justify;">Huile</h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">Grâce à la porosité du bois, l’huile pénètre et protège votre meuble
                  dans la masse. Vous pouvez choisir de l’
                  <b>huile de lin</b>
                  si vous souhaitez privilégier un produit naturel ou vous décidez pour une huile synthétique, destinée
                  à renforcer un type de protection spécifique.
                </span>
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Utilisez un pinceau plat pour appliquer l’huile et enlevez le surplus
                  avec un chiffon. Attendez 24 à 48 heures avant de passer une deuxième couche, qui assurera une
                  meilleure protection.
                </span>
              </p>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../../function/getH1";
import {Term} from "../../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../../@common/constant/PROJECT_URL";
import {PATHS} from "../../../../@common/constant/PATHS";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    },
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FAQ-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
