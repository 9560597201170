<style scoped>
.Spacer {

}
</style>

<template>
  <div class="Spacer" :style="style_root"></div>
</template>

<script>

export default {
  props: {
    size: {
      type: Number,
      default: () => 10
    }
  },
  computed: {
    style_root() {
      return {
        width: `${this.size}px`,
        height: `${this.size}px`,
        minWidth: `${this.size}px`,
        minHeight: `${this.size}px`,
      }
    },
  },
}
</script>