<style scoped>
.ServerSideRendering {
  width: inherit;
  height: inherit;
}

.ServerSideRendering-client {
  width: inherit;
  height: inherit;
}

.ServerSideRendering-server {
  display: none;
}
</style>

<template>
  <div class="ServerSideRendering" :id="state.id">
    <div class="ServerSideRendering-client" v-if="state.ssr === false">
      <slot name="client"></slot>
    </div>
    <div class="ServerSideRendering-server" v-if="state.ssr === true">
      <slot name="server"></slot>
    </div>
  </div>
</template>

<script>

import Id from "@bbx/id~master/core/ts/Id";

const id = new Id()

export default {

  props: {},
  async fetch() {
    this.state.id = `_${id.uniq()}`
  },
  data() {
    return {
      state: {
        id: `_${id.uniq()}`,
        ssr: true
      },
    }
  },
  watch: {},
  computed: {},
  beforeMount() {
  },
  mounted() {
    setTimeout(() => {
      this.state.ssr = false
    }, 0)
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
