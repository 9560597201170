import ApiRegistery from '@bbx/api~master/core/delta/ApiRegistery';



import {ApiRequest_constructor_props as ApiRequest} from "../../delta/http/ApiRequest";

import {User} from '../../delta/database/User'
import {ApiResponse} from "../../delta/http/ApiResponse";

export interface Request extends User {
}

export interface Response {
}

export default new ApiRegistery<Request & ApiRequest, ApiResponse<Response>>({
    name: 'updateUserPassword',

})
