"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.s3ConfigPublic = void 0;
var ENV_1 = require("../../core/@common/constant/ENV");
var config_1 = require("../config");
if (config_1.config.env === ENV_1.ENV.PRODUCTION) {
    exports.s3ConfigPublic = {
        protocol: 'https',
        bucket: 's3-59e7f1a6d1ccec8d1b4216d968aa582e',
        region: 'eu-central-1',
        domain: 'amazonaws.com',
    };
}
