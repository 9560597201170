import {deltaService} from "@bbx/delta~master/core/service/deltaService"
import {Translation} from './Translation'
import {DeepPartial} from "../../type/DeepPartial";


export type Lang_constructor_props = DeepPartial<Lang>

export class  Lang {

    id: number
    active: boolean
    createdAt: string
    updatedAt: string
    
    name: string
    iso6391: string

    translations: Translation[]

    constructor(props: Lang_constructor_props = {}) {
                this.id = deltaService.number(props.id, 0)
        this.active = deltaService.boolean(props.active, true)
        this.createdAt = deltaService.string(props.createdAt, '')
        this.updatedAt = deltaService.string(props.updatedAt, '')

        this.name = deltaService.string(props.name, '')
        this.iso6391 = deltaService.string(props.iso6391, '')

        this.translations = deltaService.array(props.translations, Translation)
    }
}
