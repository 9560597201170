<style scoped lang="scss">

@import "../../scss/color";
@import "../../scss/size";

$height-preview-mobile: 100px;
$bottom-preview-desktop: 40px;

.HomeCarousel {
  position: relative;
}

.HomeCarousel ::v-deep .Responsive--root {
  height: 100%;
  position: relative;
}

.HomeCarousel-desktop {
  height: calc(100vh - (#{$home-carousel-dektop-min-bottom} + #{$bottom-preview-desktop}));
  min-height: 655px;
  margin-bottom: $bottom-preview-desktop;
}

.HomeCarousel-tablet {
  height: 100vh;
  min-height: 700px;
}

.HomeCarousel-mobile {
  height: 100vh;
}

/*
 * * * * * * * * * *
 *
 *   MEDIA
 *
 * * * * * * * * * *
 */

.HomeCarousel--items {
  width: 100%;
  height: 100%;
  position: relative;
}

.HomeCarousel-mobile .HomeCarousel--items {
  height: calc(100% - #{$height-preview-mobile});
}

.HomeCarousel--item {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: opacity 500ms;
  overflow: hidden; /* should be cut and hidden when you use the search */
}

.HomeCarousel-visible .HomeCarousel--item {
  opacity: 1;
}

//.HomeCarousel-menuIsVisible .HomeCarousel--item {
//  opacity: 0;
//}

.HomeCarousel--color {
  width: 100%;
  height: 100%;
  opacity: 0.5;
  background-color: $primary;
  transition: background-color 1000ms;
}

.HomeCarousel--mask {
  width: 100%;
  height: 100%;
  background: linear-gradient(180deg, rgba(4, 5, 29, 0.38) 0%, rgba(4, 5, 29, 0) 65%, rgba(4, 5, 29, 0) 100%);
  position: absolute;
  top: 0;
  left: 0;
  transition: background 500ms;
}

.HomeCarousel--mask-top {

}

.HomeCarousel--mask-bottom {

}

.HomeCarousel-desktop .HomeCarousel--mask-bottom {
  display: none;
}

.HomeCarousel-tablet .HomeCarousel--mask-bottom {
  display: none;
}

.HomeCarousel-mobile .HomeCarousel--mask-bottom {
  transform: rotate(180deg);
  top: -$height-preview-mobile;
}

/*
 * * * * * * * * * *
 *
 *   INFORAMTIONS
 *
 * * * * * * * * * *
 */

.HomeCarousel--infos {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

.HomeCarousel--info {
  position: absolute;
  width: 100%;
  height: 100%;
  color: white;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.HomeCarousel-desktop .HomeCarousel--info {
  padding-top: 191px;
  padding-right: 161px;
  padding-left: 169px;
  align-items: flex-start;
}

.HomeCarousel-tablet .HomeCarousel--info {
  padding-top: 160px;
  padding-right: 141px;
  padding-left: 149px;
  align-items: flex-start;
}

.HomeCarousel-mobile .HomeCarousel--info {
  padding-bottom: $height-preview-mobile + 40px;
  padding-right: 20px;
  padding-left: 20px;
  align-items: center;
  justify-content: flex-end;
  text-align: center;
}

/**/

.HomeCarousel--infoNumber {
  font-family: 'AeonikRegular', sans-serif;
  letter-spacing: 0.25px;
  padding-left: 5px;
  padding-bottom: 1px;
  transform: scale(0);
  transition: transform 500ms;
}

.HomeCarousel-visible .HomeCarousel--infoNumber {
  transform: scale(1);
}

.HomeCarousel-desktop .HomeCarousel--infoNumber {
  font-size: 31px;
}

.HomeCarousel-tablet .HomeCarousel--infoNumber {
  font-size: 31px;
  line-height: 20px;
}

.HomeCarousel-mobile .HomeCarousel--infoNumber {
  font-size: 26px;
}

/**/

.HomeCarousel--infoTitle {
  font-family: 'AmbroiseRegular', sans-serif;
  padding-bottom: 4px;
  overflow: hidden;
}

.HomeCarousel-desktop .HomeCarousel--infoTitle {
  font-size: 80px;
  padding-top: 8px;
}

@media screen and (max-width: $menu-image-screen) {
  .Menu-desktop .HomeCarousel--infoTitle {
    font-size: 70px;
  }
}

.HomeCarousel-tablet .HomeCarousel--infoTitle {
  font-size: 85px;
  padding-top: 8px;
}

.HomeCarousel-mobile .HomeCarousel--infoTitle {
  font-size: 35px;
  padding-top: 14px;
  padding-bottom: 18px;
}

/**/

.HomeCarousel-desktop .HomeCarousel--infoTitle ::v-deep .Term {
  transition: transform 1000ms;
  transform: translateY(150%);
}

.HomeCarousel-desktop.HomeCarousel-visible .HomeCarousel--infoTitle ::v-deep .Term {
  transform: translateY(0%);
}

.HomeCarousel-tablet .HomeCarousel--infoTitle ::v-deep .Term {
  transition: transform 1000ms;
  transform: translateY(150%);
}

.HomeCarousel-tablet.HomeCarousel-visible .HomeCarousel--infoTitle ::v-deep .Term {
  transform: translateY(0%);
}

.HomeCarousel-mobile .HomeCarousel--infoTitle ::v-deep .Term {
  transition: transform 1000ms;
  transform: translateY(150%);
}

.HomeCarousel-mobile.HomeCarousel-visible .HomeCarousel--infoTitle ::v-deep .Term {
  transform: translateY(0%);
}

/**/

.HomeCarousel--infoDescription {
  font-family: 'AeonikRegular', sans-serif;
  letter-spacing: 0.25px;
  padding-left: 5px;
  opacity: 0;
  transform: translate(0px, 50px);
  transition: opacity 500ms, transform 1500ms;
}

.HomeCarousel-visible .HomeCarousel--infoDescription {
  opacity: 1;
  transform: translate(0px, 0px);
}


.HomeCarousel-desktop .HomeCarousel--infoDescription {
  width: 400px;
  font-size: 18px;
  line-height: 29px;
}

.HomeCarousel-tablet .HomeCarousel--infoDescription {
  width: 400px;
  font-size: 18px;
  line-height: 29px;
}

.HomeCarousel-mobile .HomeCarousel--infoDescription {
  font-size: 16px;
  line-height: 29px;
}

/**/

.HomeCarousel--discover {
  width: 100%;
}

.HomeCarousel--discoverButton {
  color: white;
  border: solid 1px rgba(255, 255, 255, 0.5);
  border-radius: 100px;
  width: calc(100% - 40px);
  height: 48px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-family: 'MontserratSemiBold', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  margin-top: 32px;
  margin-left: 20px;
  margin-right: 20px;
  box-sizing: border-box;
}

/*
 * * * * * * * * * *
 *
 *   SOCIAL
 *
 * * * * * * * * * *
 */

.HomeCarousel--socials {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  transform: translateX(-100%);
  transition: transform 500ms;
}

.HomeCarousel-mounted .HomeCarousel--socials {
  transform: translateX(0%);
}

.HomeCarousel-desktop .HomeCarousel--socials {
  padding: 58px 58px 216px 58px;
  align-items: center;
  justify-content: flex-end;
}

.HomeCarousel-tablet .HomeCarousel--socials {
  display: none;
}

.HomeCarousel-mobile .HomeCarousel--socials {
  display: none;
}

.HomeCarousel--social {
  display: flex;
  justify-content: center;
  padding-top: 10px;
  transition: opacity 500ms;
}

.HomeCarousel-desktop .HomeCarousel--social {
  opacity: 1;
}

.HomeCarousel-desktop .HomeCarousel--social:hover {
  opacity: 0.5;
}

.HomeCarousel--social ::v-deep .Link {
  display: block;
}

.HomeCarousel--socialIcon {
  width: 20px;
}

.HomeCarousel--socialIcon ::v-deep .Vector svg {
  fill: white;
}

/*
 * * * * * * * * * *
 *
 *   NAVIGATION
 *
 * * * * * * * * * *
 */

.HomeCarousel--nav {
  position: absolute;
  bottom: 0px;
  right: 0px;
  height: 100%;
  display: flex;
  flex-direction: column;
  box-sizing: border-box;
  align-items: center;
}

.HomeCarousel-desktop .HomeCarousel--nav {
  padding: 241px 50px 50px 50px;
}

.HomeCarousel-tablet .HomeCarousel--nav {
  padding: 211px 50px 50px 50px;
}

.HomeCarousel-mobile .HomeCarousel--nav {
  display: none;
}

.HomeCarousel--navCurrent {
  color: white;
  font-family: 'AeonikMedium', sans-serif;
  padding-bottom: 16px;
  transform: scale(0);
  transition: transform 500ms;
}

.HomeCarousel-visible .HomeCarousel--navCurrent {
  transform: scale(1);
}

.HomeCarousel--navBarre {
  background-color: rgba(255, 255, 255, 0.5);
  height: 100px;
  width: 1px;
  transform: scaleY(0);
  transition: transform 500ms;
}

.HomeCarousel-visible .HomeCarousel--navBarre {
  transform: scaleY(1);
}

.HomeCarousel--navTotal {
  color: white;
  font-family: 'AeonikMedium', sans-serif;
  padding-top: 16px;
  opacity: 0;
  transform: translateY(-20px);
  transition: opacity 500ms, transform 500ms;
}

.HomeCarousel-visible .HomeCarousel--navTotal {
  opacity: 1;
  transform: translateY(0px);
}

/*
 * * * * * * * * * *
 *
 *   PREVIEW
 *
 * * * * * * * * * *
 */

.HomeCarousel--preview1 {
}

.HomeCarousel--preview {
  position: absolute;
  bottom: 0px;
  right: 0px;
  background-color: $primary;
  display: flex;
  overflow: hidden;
}

.HomeCarousel-desktop .HomeCarousel--preview {
  transition: background-color 500ms, transform 1500ms;
  transform: translateY(-$bottom-preview-desktop);
  width: 565px;
  height: 150px;
  bottom: -$bottom-preview-desktop;
  left: $homeCarouselDektopLeft;
}

.HomeCarousel-desktop.HomeCarousel-visible .HomeCarousel--preview {
  transform: translateY(0px);
}

.HomeCarousel-tablet .HomeCarousel--preview {
  transition: background-color 500ms;
  width: 565px;
  height: 150px;
  bottom: 0px;
  right: 0px;
}

.HomeCarousel-tablet.HomeCarousel-visible .HomeCarousel--preview {
}

.HomeCarousel-mobile .HomeCarousel--preview {
  transition: background-color 500ms;
  width: 100%;
  height: $height-preview-mobile;
  bottom: 0px;
  left: 0px;
}

.HomeCarousel-mobile.HomeCarousel-visible .HomeCarousel--preview {

}

.HomeCarousel--previewLeft {
  display: flex;
  justify-content: space-between;
  flex: 1;
}

.HomeCarousel--previewLeftInfo {
  height: 100%;
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-between;
  align-items: flex-start;
  box-sizing: border-box;
  cursor: pointer;
}

.HomeCarousel-desktop .HomeCarousel--previewLeftInfo {
  padding-left: 35px;
  padding-top: 27px;
  padding-bottom: 32px;
}

.HomeCarousel-tablet .HomeCarousel--previewLeftInfo {
  padding-left: 35px;
  padding-top: 27px;
  padding-bottom: 32px;
}

.HomeCarousel-mobile .HomeCarousel--previewLeftInfo {
  padding-left: 20px;
  padding-top: 20px;
  padding-bottom: 20px;
}

/**/

.HomeCarousel--previewText {
  font-family: 'AeonikRegular', sans-serif;
  font-size: 16px;
  letter-spacing: 0.25px;
  color: white;
  transition: opacity 500ms, transform 500ms;
}

.HomeCarousel--previewText:nth-child(1) {
  opacity: 0;
}

.HomeCarousel--previewText:nth-child(2) {
  transform: scale(0);
}

.HomeCarousel-desktop .HomeCarousel--previewText {
  font-size: 16px;
}

.HomeCarousel-tablet .HomeCarousel--previewText {
  font-size: 16px;
}

.HomeCarousel-mobile .HomeCarousel--previewText {
  font-size: 14px;
}

.HomeCarousel-visible .HomeCarousel--previewText:nth-child(1) {
  opacity: 1;
}

.HomeCarousel-visible .HomeCarousel--previewText:nth-child(2) {
  transform: scale(1);
}

/**/

.HomeCarousel--previewLeftSpinner {
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
}

.HomeCarousel-desktop .HomeCarousel--previewLeftSpinner {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
}

.HomeCarousel-tablet .HomeCarousel--previewLeftSpinner {
  width: 150px;
  min-width: 150px;
  max-width: 150px;
}

.HomeCarousel-mobile .HomeCarousel--previewLeftSpinner {
  width: $height-preview-mobile;
  min-width: $height-preview-mobile;
  max-width: $height-preview-mobile;
}

/**/

.HomeCarousel--previewLeftSpinnerCercle {
  border: solid 1px rgba(255, 255, 255, 0.5);
  border-radius: 50px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border 300ms;
}

.HomeCarousel-desktop .HomeCarousel--previewLeftSpinnerCercle {
  height: 50px;
  width: 50px;
}

.HomeCarousel-tablet .HomeCarousel--previewLeftSpinnerCercle {
  height: 50px;
  width: 50px;
}

.HomeCarousel-mobile .HomeCarousel--previewLeftSpinnerCercle {
  height: 40px;
  width: 40px;
}

.HomeCarousel-desktop .HomeCarousel--previewLeftSpinnerCercle:hover {
  border: solid 1px rgba(255, 255, 255, 1);
}

/**/

.HomeCarousel--circleProgress {
  position: absolute;
  top: -2px;
  left: -2px;
}

.HomeCarousel--circleProgress ::v-deep .CircleProgress--progressBarre {
  stroke: rgba(255, 255, 255, 1);
  transition: stroke-dashoffset 5000ms linear;
}

/**/

.HomeCarousel--previewSmallRowRight {
  position: relative;
  top: 2px;
}

.HomeCarousel--previewSmallRowRight ::v-deep svg {
  fill: white;
}

.HomeCarousel--previewSmallRowRight ::v-deep svg {
  fill: white;
}

.HomeCarousel-desktop .HomeCarousel--previewSmallRowRight ::v-deep svg {
  width: 16px;
  height: 16px;
}

.HomeCarousel-tablet .HomeCarousel--previewSmallRowRight ::v-deep svg {
  width: 16px;
  height: 16px;
}

.HomeCarousel-mobile .HomeCarousel--previewSmallRowRight ::v-deep svg {
  width: 13px;
  height: 13px;
}

/**/

.HomeCarousel--previewRight {
  height: 100%;
  cursor: pointer;
  transition: opacity 500ms, transform 500ms;
  opacity: 0;
  transform: translateX(20px);
}

.HomeCarousel-desktop .HomeCarousel--previewRight {
  width: 250px;
  min-width: 250px;
}

.HomeCarousel-desktop.HomeCarousel-visible .HomeCarousel--previewRight:hover {
  opacity: 1;
}

.HomeCarousel-tablet .HomeCarousel--previewRight {
  width: 250px;
  min-width: 250px;
}

.HomeCarousel-mobile .HomeCarousel--previewRight {
  width: $height-preview-mobile;
  min-width: $height-preview-mobile;
}

.HomeCarousel-visible .HomeCarousel--previewRight {
  opacity: 0.75;
  transform: translateX(0);
}

</style>

<template>
  <div class="HomeCarousel" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <div class="HomeCarousel--items">
        <div class="HomeCarousel--color" :style="style_color"></div>
        <div class="HomeCarousel--item">
          <Picture v-if="file.id"
                   :file="file"
                   :id="state.carousel.id"
                   :position="flattenPosition"
                   :onLoaded="onLoaded"
                   :rgb-loader="[255, 255, 255]">
          </Picture>
        </div>
      </div>

      <div class="HomeCarousel--mask HomeCarousel--mask-top" :style="style_mask"></div>
      <div class="HomeCarousel--mask HomeCarousel--mask-bottom" :style="style_mask1"></div>

      <div class="HomeCarousel--infos" @mouseenter="showCircleLink" @mouseleave="hideCircleLink"
           @click="() => changeRoute(state.carousel.url)">
        <div class="HomeCarousel--info" ref="info">
          <div class="HomeCarousel--infoNumber">{{
              state.index < 10 ? '0' : ''
            }}{{ state.index + 1 }}
          </div>
          <div class="HomeCarousel--infoTitle"
               v-for="termTitle in state.carousel.termTitles">
            <Term :key="termTitle.id" :term="termTitle"></Term>
          </div>
          <div class="HomeCarousel--infoDescription"
               v-for="termDescription in state.carousel.termDescriptions">
            <Term :key="termDescription.id" :term="termDescription"></Term>
          </div>
          <div class="HomeCarousel--discover">
            <template v-if="state.mounted">
              <CircleLink v-if="props.Responsive.breakPoint.name === SCREEN.DESKTOP"
                          :activated="circleLinkActivated"
                          :defaultX="defautlX"
                          :defaultY="defaultY"></CircleLink>
              <div class="HomeCarousel--discoverButton" v-else-if="props.Responsive.breakPoint.name === SCREEN.MOBILE">
                {{ lang.translate(664) }}
              </div>
            </template>
          </div>
        </div>
      </div>

      <div class="HomeCarousel--nav" v-show="state.carousels.length">
        <div class="HomeCarousel--navCurrent">{{ state.index < 10 ? '0' : '' }}{{ state.index + 1 }}</div>
        <div class="HomeCarousel--navBarre"></div>
        <div class="HomeCarousel--navTotal">{{ state.carousels.length < 10 ? '0' : '' }}{{
            state.carousels.length
          }}
        </div>
      </div>

      <div class="HomeCarousel--preview1">
        <div class="HomeCarousel--preview" :style="style_preview">
        <div class="HomeCarousel--previewLeft">
          <div class="HomeCarousel--previewLeftInfo" @click="() => playNext()">
            <div class="HomeCarousel--previewText" v-for="termTitle in nextCarousel.termTitles">
              <Term :key="termTitle.id" :term="termTitle"></Term>
            </div>
            <div class="HomeCarousel--previewText">
              {{ nextIndex < 10 ? '0' : '' }}{{ nextIndex + 1 }}
            </div>
          </div>
          <div class="HomeCarousel--previewLeftSpinner" @click="() => play()">
            <div class="HomeCarousel--previewLeftSpinnerCercle">
              <div class="HomeCarousel--circleProgress">
                <CircleProgress v-if="state.visible && !this.state.stopped"
                                :size="sizeCercle"
                                :barreSize="3"
                                :percentage="state.percentage">
                </CircleProgress>
              </div>
              <div class="HomeCarousel--previewSmallRowRight">
                <Vector :svg="icon.smallRowRight"></Vector>
              </div>
            </div>
          </div>
        </div>
        <div class="HomeCarousel--previewRight" @click="() => playNext()">
          <Picture :file="nextFile"
                   :position="nextFlattenPosition"
                   :rgb-loader="[255, 255, 255]">
          </Picture>
        </div>
      </div>
      </div>

      <div class="HomeCarousel--socials">
        <div class="HomeCarousel--social"
             v-for="social in state.socials"
             :key="social.id">
          <Link :href="social.href"
                :indexed="false"
                :newPage="true">
            <div class="HomeCarousel--socialIcon">
              <Vector :svg="social.icon"></Vector>
            </div>
          </Link>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {PATHS} from "../../../@common/constant/PATHS";
import {ROLE} from "../../../@common/constant/ROLE";
import {dataSocials} from "../../data/socials";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {STATE_ROLE_ID, stateService} from "../../service/stateService";
import {getCountry} from "../../service/countryService";
import {EVENT} from "../../../@common/constant/EVENT";
import {eventService} from "../../service/eventService";
import {sendError} from "../../function/sendError";
import {apiClient} from "../../service/apiClientService";
import selectCarousel from "../../../@common/api/carousel/selectCarousel";
import {Carousel} from "../../../@common/delta/database/Carousel";
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {File} from "../../../@common/delta/database/File";
import {THEME} from "../../../@common/constant/THEME";
import {changeRoute} from "../../function/changeRoute";
import {LANG_ISO} from "../../../@common/constant/LANG";

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    },
    breakPoints: {
      type: Array,
      default: () => ([])
    },
    verticalBreakPoints: {
      type: Array,
      default: () => ([])
    },
  },
  data() {
    const lang = getLangServiceBrowser()
    return {
      lang,
      PATHS,
      SCREEN,
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: this.breakPoints,
          verticalBreakPoints: this.verticalBreakPoints,
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      icon: {
        smallRowRight: require('../../../@common/assets/home/small-row-right.svg'),
      },
      state: {
        /**
         * @type boolean
         */
        mounted: false,
        /**
         * @type boolean
         */
        canClick: true,
        /**
         * @type boolean
         */
        circleLinkActivated: false,
        /**
         * @type boolean
         */
        stopped: false,
        /**
         * @type boolean
         */
        menuIsVisible: false,
        /**
         * @type boolean
         */
        visible: false,
        /**
         * @type number
         */
        index: 0,
        /**
         * @type number[]
         */
        loadedCarouselIds: [],
        /**
         * @type number
         */
        roleId: ROLE.CUSTOMER,
        /**
         * @type string
         */
        country: '',
        /**
         * @type any
         */
        timeOut: null,
        /**
         * @type number
         */
        clientWidth: 0,
        /**
         * @type number
         */
        percentage: 0,
        /**
         * @type number
         */
        transitionTime: 500,
        /**
         * @type number
         */
        transitionStandTime: 5000,
        /**
         * @type Carousel
         */
        carousel: new Carousel(),
        /**
         * @type Carousel[]
         */
        carousels: [],
        /**
         * @type any[]
         */
        socials: dataSocials
      },
      events: []
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.state.visible) classes.push(`HomeCarousel-visible`)
      if (this.state.stopped) classes.push(`HomeCarousel-stopped`)
      if (this.state.mounted) classes.push(`HomeCarousel-mounted`)
      if (this.state.menuIsVisible) classes.push(`HomeCarousel-menuIsVisible`)
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`HomeCarousel-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`HomeCarousel-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`HomeCarousel-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`HomeCarousel-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`HomeCarousel-desktopY`)
      return classes
    },
    style_color() {
      const style = {}
      if (!this.state.menuIsVisible) style.background = this.state.carousel.color
      return style
    },
    style_mask() {
      const style = {}
      let pourcent = 65
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) pourcent = 30
      style.background = `linear-gradient(180deg, rgba(4, 5, 29, ${this.state.carousel.opacityShadow}) 0%, rgba(4, 5, 29, 0) ${pourcent}%, rgba(4, 5, 29, 0) 100%)`
      return style
    },
    style_mask1() {
      const style = {}
      let pourcent = 50
      style.background = `linear-gradient(180deg, rgba(4, 5, 29, ${this.state.carousel.opacityShadow}) 0%, rgba(4, 5, 29, 0) ${pourcent}%, rgba(4, 5, 29, 0) 100%)`
      return style
    },
    style_preview() {
      const style = {}
      if (!this.state.menuIsVisible) style.background = this.state.carousel.color
      return style
    },
    file() {
      return this.getFile(this.state.carousel)
    },
    nextIndex() {
      return this.getNextIndex()
    },
    nextCarousel() {
      return new Carousel(this.state.carousels[this.nextIndex])
    },
    flattenPosition() {
      return this.getFlattenPosition(this.state.carousel)
    },
    nextFlattenPosition() {
      return this.getFlattenPosition(this.nextCarousel)
    },
    nextFile() {
      return this.getFile(this.nextCarousel)
    },
    sizeCercle() {
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) return 44
      return 54
    },
    defautlX() {
      return this.state.clientWidth - 209
    },
    defaultY() {
      return 327
    },
    circleLinkActivated() {
      return !this.state.menuIsVisible && this.state.circleLinkActivated
    }
  },
  beforeMount() {

    // -----

    this.state.roleId = stateService.get(STATE_ROLE_ID)
    stateService.watch(STATE_ROLE_ID, v => this.state.roleId = v)

    // -----

    this.state.country = getCountry()

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.CHANGE_COUNTRY_LOCATION,
      callback: (country) => {
        this.state.country = country
      }
    }))

    // -----
    this.events.push(new ListenerRegister({
      name: EVENT.SIZE_CHANGED,
      callback: () => {
        this.state.clientWidth = this.$refs.info.clientWidth
        eventService.triggerByName(new ListenerTrigger({
          name: EVENT.CIRCLE_LINK_RESET_POSITION
        }))
      }
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.MENU_THEME_CHANGED,
      callback: (theme) => {
        this.state.menuIsVisible = theme === THEME.DARK
      }
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.DISABLE_CAROUSEL_LINK,
      callback: () => {
        this.state.canClick = false
      }
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.UNABLE_CAROUSEL_LINK,
      callback: () => {
        this.state.canClick = true
      }
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
    this.init().catch(sendError)
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    /**
     *
     * @returns {Promise<void>}
     */
    async init() {
      setTimeout(() => {
        this.state.mounted = true
        this.state.clientWidth = this.$refs.info.clientWidth
        this.showCircleLink()
      })
      await this.setCarousels()
      this.setCarousel()
    },
    /**
     *
     * @returns {Promise<void>}
     */
    async setCarousels() {
      const {data} = await apiClient.do(selectCarousel, selectCarousel.with({
        active: true,
        $sort: 'ask'
      }))
      this.state.carousels = data
    },
    /**
     *
     * @returns {Promise<void>}
     */
    async loop() {
      if (this.state.timeOut) clearTimeout(this.state.timeOut)
      await this.show()
      if (this.state.stopped) return
      await new Promise(rs => {
        this.state.timeOut = setTimeout(rs, this.state.transitionStandTime)
      });
      if (this.state.stopped) return
      await this.hide()
      this.next()
      this.setCarousel()
    },
    /**
     *
     */
    async show() {
      this.state.visible = true
      this.state.percentage = 0
      this.$nextTick(() => {
        setTimeout(() => this.state.percentage = 100, this.state.transitionTime)
      })
      await new Promise(rs => setTimeout(rs, this.state.transitionTime));
    },
    /**
     *
     */
    async hide() {
      this.state.visible = false
      await new Promise(rs => setTimeout(rs, this.state.transitionTime));
    },
    /**
     *
     */
    next() {
      this.state.index = this.getNextIndex()
    },
    /**
     *
     */
    stop() {
      this.state.stopped = true
    },
    /**
     *
     */
    async playNext() {
      this.stop()
      await this.hide()
      this.next()
      this.setCarousel()
    },
    /**
     *
     */
    async play() {
      if (!this.state.stopped) return
      this.state.stopped = false
      await this.loop()
    },
    /**
     *
     */
    setCarousel() {
      const carousel = new Carousel(this.state.carousels[this.state.index])
      this.state.carousel = carousel

      eventService.triggerByName(new ListenerTrigger({
        name: EVENT.HOME_COLOR_CHANGED,
        payload: carousel.color,
        // payload: ' #F56C6C',
      }))
    },
    /**
     *
     * @param {Carousel} carousel
     * @returns {string}
     */
    getFlattenPosition(carousel = new Carousel) {
      let backgroundPositions = []
      for (const photo of carousel.photos) {
        for (const position of photo.positions) {
          if (position.top) backgroundPositions.push('top')
          if (position.right) backgroundPositions.push('right')
          if (position.center) backgroundPositions.push('center')
          if (position.bottom) backgroundPositions.push('bottom')
          if (position.left) backgroundPositions.push('left')
        }
      }
      return backgroundPositions.join(' ')
    },
    /**
     *
     * @param {Carousel} carousel
     */
    isLoaded(carousel = new Carousel) {
      return this.state.loadedCarouselIds.includes(carousel.id)
    },
    onLoaded(id) {
      this.state.loadedCarouselIds.push(id)
      this.loop().catch(sendError)
    },
    /**
     *
     * @param {Carousel} carousel
     * @returns {File}
     */
    getFile(carousel = new Carousel) {
      let file = new File()
      for (const photo of carousel.photos) {
        for (const file_ of photo.files) {
          file = file_
        }
      }
      return file
    },
    /**
     *
     * @returns {number}
     */
    getNextIndex() {
      let index = this.state.index
      index++
      if (index >= this.state.carousels.length) {
        index = 0
      }
      return index
    },
    hideCircleLink() {
      this.state.circleLinkActivated = false
    },
    showCircleLink() {
      this.state.circleLinkActivated = true
    },
    changeRoute(url) {
      if (!this.state.canClick) return
      url = url.replace(/^(http|https)\:\/\/[a-z-A-Z0-9\:\.]+\//, '/')
      url = url.replace(new RegExp(`^\\/(${Object.keys(LANG_ISO).join('|')})\\/`), `/${this.lang.lang.iso6391}/`)
      changeRoute([], {}, url)
    },
  }
}
</script>
