<style scoped>
.List {
  display: flex;
  align-items: center;
  padding: 10px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0);
  transition: background 300ms;
  cursor: pointer;
  text-decoration: none;
}

.List:hover {
  background: rgba(0, 0, 0, 0.05);
}

.List--icon {
  width: 24px;
  height: 24px;
}

.List--icon img {
  width: 100%;
}

.List--icon ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 0.5);
  transition: fill 300ms;
}

.List:hover .List--icon ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 1);
}

.List--text {
  font-family: 'ComfortaaBold', sans-serif;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  font-size: 10px;
  margin-left: 10px;
}

.List:hover .List--text {
  color: rgba(0, 0, 0, 1);
}
</style>

<template>
  <Link v-if="href" class="List" @click="onClick" :href="href">
    <div class="List--icon">
      <Vector v-if="svg" :svg="svg"></Vector>
      <img v-if="img" :src="img">
    </div>
    <div class="List--text">
      {{ text }}
    </div>
  </Link>
  <div v-else class="List" @click="onClick">
    <div class="List--icon">
      <Vector v-if="svg" :svg="svg"></Vector>
      <img v-if="img" :src="img">
    </div>
    <div class="List--text">
      {{ text }}
    </div>
  </div>
</template>

<script>

export default {

  props: {
    svg: {
      type: String,
      default: () => ''
    },
    img: {
      type: String,
      default: () => ''
    },
    href: {
      type: String,
      default: () => ''
    },
    text: {
      type: String,
      default: () => ''
    },
    onClick: {
      type: Function,
      default: () => () => {
      }
    }
  },
  data() {
    return {
      props: {},
      state: {},
    }
  },
  watch: {},
  computed: {},
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
