<style scoped>

</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="content-wrapper none for-title">
      <div class="container_12">
        <div class="main-container main-main-container col1-layout">
          <div class="grid_12 col-main">
            <div class="page-title">
              <h1>Aménager sa terrasse - Les Jardins Vente privée</h1>
            </div>
            <div class="std">
              <p>
                <img alt="" src="./media/wysiwyg/2_1.jpg">&nbsp;&nbsp;
              </p>
              <p>&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Vous disposez d’une
                  <b>terrasse avec un revêtement en bois</b>
                  et vous cherchez des idées pour l’aménagement extérieur ? Vous avez réalisé des travaux ou vous
                  entamez un projet de rénovation de votre extérieur&nbsp;? Nous vous délivrons tous nos conseils pour
                  créer un espace de vie chaleureux et agréable que vous apprécierez retrouver pour partager un moment
                  entre amis ou en famille ou pour vous détendre.
                </span>
              </p>
              <h2 style="text-align: justify;">Mobilier en teck, aluminium ou rotin tressé ?</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Rien de tel que d’
                  <b>aménager sa terrasse</b>
                  avec un salon de jardin confortable et élégant. Vous pourrez y partager un repas, un apéritif ou bien
                  vous y installer pour lire un livre et profiter de votre extérieur dans une ambiance zen. Le choix du
                  matériau de vos meubles de jardin est primordial. Ce dernier doit être suffisamment résistant et
                  s’intégrer dans votre décoration. Il existe différentes collections d’inspirations design, bohème ou
                  rétro.
                </span>
              </p>
              <p>
                <img style="float: right; margin-left: 30px;" alt="" src="./media/wysiwyg/2a_1.jpg">
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Nous vous conseillons d’opter pour un&nbsp;<b>salon de jardin haut de
                  gamme
                </b>&nbsp;avec une base en
                  <b>aluminium</b>
                  marron recouvert avec un tressage en wiker. Le wiker est composé de fil polyéthylène traité pour ne
                  pas s’altérer avec le temps. Ainsi vous avez un mobilier aux couleurs exotiques, qui va tenir de
                  nombreuses années sans changer de teinte.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le
                  <b>teck</b>
                  est également souvent utilisé pour la conception de mobilier de jardin comme
                  une table et des chaises
                  pour le coin repas. Son aspect bois est très recherché. En revanche, il faut l’entretenir pour qu’il
                  résiste aux agressions du soleil et de la pluie.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le mobilier en plastique, en revanche, est moins élégant, se ternit avec
                  le temps et s’avère moins solide.&nbsp;&nbsp;
                </span>
              </p>
              <h2>Avec ou sans piscine ?</h2>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Avec bien sûr ! Enfin si le terrain de votre maison le permet. Si vous
                  optez pour une piscine hors sol, privilégiez un modèle en bois. Disposez des
                  <b>bains de soleil</b>
                  ainsi qu’un
                  <b>banc de jardin</b>
                  pour créer un coin détente au bord de l’eau. Vous pourrez également installer une douche solaire de
                  piscine qui vous permettra de vous rincer avant et après la baignade. Le mobilier de jardin vous offre
                  la possibilité de différencier les divers espaces de votre extérieur.
                </span>&nbsp;
              </p>
              <h2>Comment se préserver du vis-à-vis ?&nbsp;</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Panneau, claustra… comment préserver son intimité du regard des voisins
                  ? Il n’existe pas qu’une seule façon de
                  <b>se préserver du vis-à-vis</b>
                  . Pour être raccord avec votre terrasse en bois, deux solutions s’offrent à vous. Vous pouvez opter
                  pour de la verdure pour un esprit naturel et faire une
                  <b>haie</b>
                  , naturelle ou artificielle, ou installer des brise-vues en bois sur toute la hauteur ou les poser sur
                  un mur. Nous vous conseillons de vous diriger vers des
                  <b>panneaux en lames de noisetier</b>
                  , à la fois chic et pas trop cher. Ces derniers apportent une touche exotique et chaleureuse à votre
                  extérieur.&nbsp;
                </span>
              </p>
              <h2 style="text-align: justify;">Les plantes
                <img style="float: right; padding-left: 20px;" alt="" src="./media/wysiwyg/img18.jpg">
              </h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">La couleur qui va le mieux avec un extérieur tout en bois, c’est le vert
                  ! Optez pour des
                  <b>arbres robustes</b>
                  comme le kumquat, l’olivier, le citronnier ou encore l’oranger. Vous pouvez aussi jouer sur le relief
                  en faisant monter une vigne vierge. Si vous vous sentez l’âme de créer un potager, vous pouvez
                  commencer par planter quelques plants de tomates. Pour le nez et les abeilles, plantez un peu de
                  lavande dans un coin, vous verrez que les abeilles et bourdons iront dessus plutôt que dans votre
                  assiette ! Découvrez plus d’infos et d’astuces sur notre article&nbsp;:&nbsp;
                  associer plantes et mobilier de jardin.
                </span>
              </p>
              <h2>Ombrage</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Il est important d’avoir un
                  <b>coin d’ombre</b>
                  sur sa terrasse. Suivant votre budget, optez pour une solution fixe comme une pergola ou un
                  toit-terrasse en plaque PVC imitation tuile par exemple. Autrement, pour pouvoir couvrir une table
                  entière, vous pouvez choisir un parasol rond classique ou un parasol double pied à 2 pentes (comme sur
                  les terrasses des restaurants), qui résiste au vent et qui se replie très facilement.&nbsp;
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <h2 style="text-align: justify;">Le rangement</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Vous devez pouvoir ranger tous vos outils, vos accessoires de piscines,
                  les coussins des fauteuils, un peu de vaisselles, etc… dans votre
                  <b>rangement extérieur</b>
                  . Optez donc pour plusieurs bancs en bois avec un coffre de rangement en dessous. Vous pouvez aussi
                  installer une grande armoire en bois ou encore mieux un grand coffre de rangement en bois de 2 000
                  litres style « fourre-tout ».
                </span>
                <span style="font-size: large;">Vous êtes prêts à passer un superbe été au soleil sur votre terrasse ou
                  au bord de la piscine en bois, à l’abri des regards indiscrets.
                </span>
              </p>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../../function/getH1";
import {Term} from "../../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../../@common/constant/PROJECT_URL";
import {PATHS} from "../../../../@common/constant/PATHS";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    },
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FAQ-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
