import ApiRegistery from '@bbx/api~master/core/delta/ApiRegistery';

import {ApiRequest_constructor_props as ApiRequest} from "../../delta/http/ApiRequest";
import {ApiResponse} from "../../delta/http/ApiResponse";
import {ShopTypeList, ShopTypeList_constructor_props} from "../../delta/database/ShopTypeList";

export interface Request extends ShopTypeList_constructor_props {
    $cache?: boolean;
    $selectAll?: boolean;
    $ids?: number[];
    $limit?: number;
    $offset?: number;
}

export interface Response extends ShopTypeList {
}

export default new ApiRegistery<Request & ApiRequest, ApiResponse<Response[]>>({
    hydrate: (apiResponse) => Object.assign(apiResponse, {data: apiResponse.data.map(data => new ShopTypeList(data))}),
    name: 'selectShopTypeList',
})
