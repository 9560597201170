<style scoped>
.GoogleRecaptcha {
  position: relative;
}
</style>
<template>
  <div className="GoogleRecaptcha" ref="root">
  </div>
</template>
<script>
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import Id from "@bbx/id~master/core/ts/Id";
import {eventService} from "../../service/eventService";
import {EVENT} from "../../../@common/constant/EVENT";
import {googleConfigPublic} from "../../../../config/public/google.config";

const identifier = new Id()

let scriptImported = false;
let scriptImportedProcessing = false;

// https://www.google.com/u/2/recaptcha/admin/site/346532894/settings

export default {
  props: {
    publicKey: {
      default: () => googleConfigPublic.recaptcha.publicKey
    },
    token: {
      default: () => ''
    },
    onChangeToken: {
      default: () => () => {
      }
    }
  },
  data() {
    return {
      state: {
        token: this.token,
      },
      events: []
    };
  },
  watch: {
    'token': function (token) {
      this.state.token = token;
    },
    'state.token': function (token) {
      if (token === "") grecaptcha.reset()
      this.onChangeToken(token);
    }
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  beforeMount() {
  },
  mounted() {
    if (scriptImported === false && scriptImportedProcessing === false) {
      scriptImportedProcessing = true;

      let script = document.createElement("script");
      script.id = `_${identifier.uniq()}`;
      script.defer = true;
      script.async = true;
      script.src = googleConfigPublic.recaptcha.url;
      document.body.appendChild(script)

      this.events.push(new ListenerRegister({
        name: EVENT.CAPTCHA_INIT,
        callback: () => {
          this.init()
        }
      }))

      // -----

      for (const event of this.events) {
        eventService.register(event)
      }

      // -----

      script.addEventListener("load", () => {
        grecaptcha.ready(() => {
          scriptImported = true;
          scriptImportedProcessing = false;
          eventService.triggerByName(new ListenerTrigger({
            name: EVENT.CAPTCHA_INIT,
          }))
        });
      });
    } else if (scriptImported === false && scriptImportedProcessing === true) {

      this.events.push(new ListenerRegister({
        name: EVENT.CAPTCHA_INIT,
        callback: () => {
          this.init()
        }
      }))

      // -----

      for (const event of this.events) {
        eventService.register(event)
      }
    } else if (scriptImported === true && scriptImportedProcessing === false) {
      this.init();
    }
  },
  methods: {
    init() {
      grecaptcha.render(this.$refs.root, {
        sitekey: this.publicKey,
        callback: token => {
          this.state.token = token;
        }
      });
    }
  }
};
</script>
