<style scoped lang="scss">

@import "../../scss/size";

.HomeSeller {
  width: 100%;
  height: 100%;
}

.HomeSeller--body {
  opacity: 1;
  transition: 500ms;
}

.HomeSeller-desktop .HomeSeller--body:hover {
  opacity: 0.5;
}

.HomeSeller-tablet ::v-deep .AnimationTitle--content {
  transform: translateY(0);
}

.HomeSeller-mobile ::v-deep .AnimationTitle--content {
  transform: translateY(0);
}

/**/

.HomeSeller--button {
  box-sizing: border-box;
  cursor: pointer;
  border: solid 1px #31302F;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.HomeSeller-desktop .HomeSeller--button {
  height: 60px;
  border-radius: 60px;
  margin: 75px auto 75px auto;
  max-width: 500px;
}

.HomeSeller-tablet .HomeSeller--button {
  height: 60px;
  border-radius: 60px;
  margin: $tabletHomeCarouselLeft + 20px auto $tabletHomeCarouselLeft + 20px auto;
  max-width: 500px;
}

.HomeSeller-mobile .HomeSeller--button {
  height: 50px;
  border-radius: 50px;
  margin-top: $home-carousel-mobile-left;
  margin-left: $home-carousel-mobile-left;
  margin-right: $home-carousel-mobile-left;
  margin-bottom: $home-carousel-mobile-left * 2;
}

/**/

.HomeSeller--button {
  padding: 0 25px;
  display: flex;
  align-items: center;
  transition: opacity 300ms;
  cursor: pointer;
}

.HomeSeller--buttonTerm {
  font-family: 'MontserratSemiBold', sans-serif;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  color: #31302F;
  white-space: nowrap;
}

.HomeSeller-desktop .HomeSeller--buttonTerm {
  font-size: 13px;
}

.HomeSeller-tablet .HomeSeller--buttonTerm {
  font-size: 13px;
}

.HomeSeller-mobile .HomeSeller--buttonTerm {
  font-size: 11px;
}

.HomeSeller--buttonIcon {
  width: 15px;
  height: 15px;
  padding-right: 11px;
  position: relative;
  top: -3px;
}

.HomeSeller--button:hover .HomeSeller--buttonIcon {
}

.HomeSeller--buttonIcon ::v-deep .Vector svg {
  fill: #31302F;
}
</style>

<template>
  <div class="HomeSeller" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <AnimationTitle>
        <div class="HomeSeller--body">
          <div class="HomeSeller--button" @click="() => state.openedSeller = true">
            <div class="HomeSeller--buttonIcon">
              <Vector :svg="icon.location_on"></Vector>
            </div>
            <div class="HomeSeller--buttonTerm">
              <Term :term="lang.term(676)"/>
            </div>
          </div>
        </div>
      </AnimationTitle>
      <Modal :opened="state.openedSeller" :onOpenedChange="v => state.openedSeller = v">
        <FindShop :activated="state.openedSeller"></FindShop>
      </Modal>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {EVENT} from "../../../@common/constant/EVENT";
import {eventService} from "../../service/eventService";

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    },
    breakPoints: {
      type: Array,
      default: () => ([])
    },
    verticalBreakPoints: {
      type: Array,
      default: () => ([])
    },
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: this.breakPoints,
          verticalBreakPoints: this.verticalBreakPoints,
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      icon: {
        location_on: require('../../../@common/assets/home/pin.svg'),
        east: require('@bbx/vector~master/core/assets/svg/material/east.svg'),
      },
      state: {
        /**
         * @type boolean
         */
        openedSeller: false,
      },
      events: []
    }
  },
  watch: {
  },
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`HomeSeller-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`HomeSeller-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`HomeSeller-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`HomeSeller-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`HomeSeller-desktopY`)
      return classes
    }
  },
  beforeMount() {

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.CHANGE_OPENED_SELLER,
      callback: (value) => this.state.openedSeller = value
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {}
}
</script>
