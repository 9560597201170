<style scoped>
.CookieBanner {
  background: #ffffff;
  width: 600px;
  position: fixed;
  bottom: 25px;
  right: 25px;
  z-index: 88888;
  border-radius: 4px;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.25);
  transform: translateX(calc(100% + 25px));
  opacity: 0;
  transition: transform 500ms, opacity 500ms;
  padding: 50px;
}

.CookieBanner ::v-deep .Responsive--root {
  width: 100%;
}

.CookieBanner-animated.CookieBanner {
  transform: translateX(0);
  opacity: 1;
}

.CookieBanner-mobile {
  width: calc(100% - 30px);
  bottom: 15px;
  right: 15px;
  box-sizing: border-box;
  padding: 25px;
  text-align: center;
}

.CookieBanner--body {
  font-size: 14px;
  font-family: 'DDinRegular', sans-serif;
  padding-bottom: 25px;
}

.CookieBanner--body ::v-deep .Link {
  padding-top: 10px;
  display: block;
  font-weight: bold;
  color: black;
}

.CookieBanner--body ::v-deep .Link p {
  text-decoration: underline !important;
}

.CookieBanner--links {
}

.CookieBanner-desktop .CookieBanner--links {
  display: flex;
}

.CookieBanner-tablet .CookieBanner--links {
  display: flex;
}

.CookieBanner-mobile .CookieBanner--links {
}

.CookieBanner-desktop .CookieBanner--links ::v-deep .Link {
  padding-right: 10px;
}

.CookieBanner-tablet .CookieBanner--links ::v-deep .Link {
  padding-right: 10px;
}

.CookieBanner-mobile .CookieBanner--links ::v-deep .Link {
  padding-right: 0px;
}

.CookieBanner--link {
  cursor: pointer;
}

.CookieBanner--footer {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.CookieBanner--footer ::v-deep .ButtonAction {
  flex: 1;
  padding-right: 15px;
}

.CookieBanner--footer ::v-deep .ButtonLink {
  flex: 1;
}

.CookieBanner-mobile .CookieBanner--footer {
  flex-direction: column;
}

.CookieBanner-mobile ::v-deep .ButtonLink {
  padding-bottom: 15px;
}

</style>

<template>
  <div class="CookieBanner" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="CookieBanner--body">
        <Term :term="lang.term(362)"></Term>
        <div class="CookieBanner--links">
          <Link :link="`/${lang.lang.iso6391}/legal/cookies`">
            <Term :term="lang.term(366)"></Term>
          </Link>
          <span class="CookieBanner--link" @click="() => openConfiguration()">
            <Link>
              <Term :term="lang.term(521)"></Term>
            </Link>
          </span>
        </div>
      </div>
      <div class="CookieBanner--footer">
        <ButtonLink :onClick="() => deniedAll()" :term="lang.term(522)"></ButtonLink>
        <ButtonAction :onClick="() => acceptAll()" :term="lang.term(364)"></ButtonAction>
      </div>
      <Modal :opened="state.opened" :onOpenedChange="v => state.opened = v">
        <CookieChoice></CookieChoice>
      </Modal>
    </Responsive>
  </div>
</template>

<script>

import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {STATE_COOKIE, stateService} from "../../service/stateService";
import {Cookie as CookieDelta} from "../../../@common/delta/cookie/Cookie";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {EVENT} from "../../../@common/constant/EVENT";
import {eventService} from "../../service/eventService";

export default {
  props: {},
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      state: {
        animated: false,
        opened: false,
        cookie: new CookieDelta()
      },
      events: []
    }
  },
  watch: {
    'state.opened': {
      handler(v) {
        if (!v) this.openOrNot()
      }
    },
  },
  computed: {
    class_root() {
      const classes = []
      if (this.state.animated) classes.push(`CookieBanner-animated`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`CookieBanner-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`CookieBanner-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`CookieBanner-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`CookieBanner-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`CookieBanner-desktopY`)
      return classes
    }
  },
  beforeMount() {

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.COOKIE_OPEN,
      callback: () => {
        this.state.opened = true
      }
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
    this.state.cookie = stateService.get(STATE_COOKIE)
    stateService.watch(STATE_COOKIE, v => this.state.cookie = v)

    setTimeout(() => {
      if (!this.state.cookie.configured) {
        this.state.animated = true
      }
    }, 0)
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    acceptAll() {
      this.state.animated = false

      // -----

      this.state.cookie.analayze = true
      this.state.cookie.marketing = true

      // -----

      this.state.cookie.configured = true
      stateService.set(STATE_COOKIE, this.state.cookie)
    },
    deniedAll() {
      this.state.animated = false

      // -----

      this.state.cookie.analayze = false
      this.state.cookie.marketing = false

      // -----

      this.state.cookie.configured = true
      stateService.set(STATE_COOKIE, this.state.cookie)
    },
    openConfiguration() {
      this.state.opened = true
      this.state.animated = false
    },
    openOrNot() {
      if (!this.state.cookie.configured && !this.state.opened) {
        this.state.animated = true
      }
    }
  }
}
</script>
