"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NuxtQuery = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var NuxtQuery = /** @class */ (function () {
    function NuxtQuery(props) {
        if (props === void 0) { props = {}; }
        this.name = deltaService_1.deltaService.string(props.name, '');
        this.value = props.value;
    }
    return NuxtQuery;
}());
exports.NuxtQuery = NuxtQuery;
