<style scoped lang="scss">

@import "../../scss/size";

.MarqueDiscover {
}


/* 
 * * * * * * * * * *
 *
 *   First
 *
 * * * * * * * * * *
 */

.MarqueDiscover--first {

}

.MarqueDiscover-desktop .MarqueDiscover--first {
  max-width: 800px;
  margin: auto;
  text-align: center;
}

.MarqueDiscover-tablet .MarqueDiscover--first {
  max-width: 800px;
  margin: auto;
  text-align: center;
}

.MarqueDiscover-mobile .MarqueDiscover--first {
}

/* 
 * * * * * * * * * *
 *
 *   Second
 *
 * * * * * * * * * *
 */

.MarqueDiscover--second {
  display: flex;
  align-items: center;
}

.MarqueDiscover-desktop .MarqueDiscover--second {

}

.MarqueDiscover-tablet .MarqueDiscover--second {

}

.MarqueDiscover-mobile .MarqueDiscover--second {
  flex-direction: column;
}

/**/

.MarqueDiscover--secondRight {
  flex: 1;
  display: flex;
  align-items: center;
}

/**/

.MarqueDiscover--block-1 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

/*
 * * * * * * * * * *
 *
 *   Third
 *
 * * * * * * * * * *
 */

.MarqueDiscover--third {
  display: flex;
}

.MarqueDiscover-desktop .MarqueDiscover--third {
  align-items: center;
}

.MarqueDiscover-tablet .MarqueDiscover--third {
  align-items: center;
}

.MarqueDiscover-mobile .MarqueDiscover--third {
  flex-direction: column-reverse;
}

/**/

.MarqueDiscover--thirdLeft {

}

.MarqueDiscover-desktop .MarqueDiscover--thirdLeft {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.MarqueDiscover-tablet .MarqueDiscover--thirdLeft {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.MarqueDiscover-mobile .MarqueDiscover--thirdLeft {
}

/**/

.MarqueDiscover--thirdRight {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

/*
 * * * * * * * * * *
 *
 *   Four
 *
 * * * * * * * * * *
 */

.MarqueDiscover--four {
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: 'AmbroiseRegular', sans-serif;
}

.MarqueDiscover-desktop .MarqueDiscover--four {
  font-size: 45px;
  max-width: 1000px;
  margin: auto;
}

.MarqueDiscover-tablet .MarqueDiscover--four {
  font-size: 35px;
  max-width: 800px;
  margin: auto;
}

.MarqueDiscover-mobile .MarqueDiscover--four {
  font-size: 30px;
  padding: 0px 20px 50px 20px;
}

/*
 * * * * * * * * * *
 *
 *   Five
 *
 * * * * * * * * * *
 */

.MarqueDiscover--five {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}

.MarqueDiscover-desktop .MarqueDiscover--five {
  margin: 0 100px;
  height: 600px;
}

.MarqueDiscover-tablet .MarqueDiscover--five {
  height: 600px;
}

.MarqueDiscover-mobile .MarqueDiscover--five {
  height: 400px;
}

/**/

.MarqueDiscover--fiveTitle {
  font-family: 'AnittoRegular', sans-serif;
  color: #ffffff;
  position: relative;
}


.MarqueDiscover-desktop .MarqueDiscover--fiveTitle {
  font-size: 40px;
}

.MarqueDiscover-tablet .MarqueDiscover--fiveTitle {
  font-size: 30px;
}

.MarqueDiscover-mobile .MarqueDiscover--fiveTitle {
  font-size: 20px;
}

/**/

.MarqueDiscover--fiveBlock {
  position: relative;
  background-color: #ffffff;
  box-sizing: border-box;
}


.MarqueDiscover-desktop .MarqueDiscover--fiveBlock {
  width: calc(100% - 500px);
}

@media screen and (max-width: $menu-image-screen) {
  .MarqueDiscover-desktop .MarqueDiscover--fiveBlock {
    width: calc(100% - 400px);
  }
}

.MarqueDiscover-tablet .MarqueDiscover--fiveBlock {
  width: calc(100% - 300px);
}

.MarqueDiscover-mobile .MarqueDiscover--fiveBlock {
  width: calc(100% - 40px);
  padding: 0 20px;
}

/*
 * * * * * * * * * *
 *
 *   Six
 *
 * * * * * * * * * *
 */

.MarqueDiscover--six {
  margin: auto;
}

.MarqueDiscover--six * {
  text-align: left !important;
}

.MarqueDiscover-desktop .MarqueDiscover--six {
  width: calc(100% - 900px);
}

@media screen and (max-width: $menu-image-screen) {
  .MarqueDiscover-desktop .MarqueDiscover--six {
    width: calc(100% - 800px);
  }
}

.MarqueDiscover-tablet .MarqueDiscover--six {
  width: calc(100% - 500px);
}

.MarqueDiscover-mobile .MarqueDiscover--six {
  width: calc(100% - 40px);
}

/*
 * * * * * * * * * *
 *
 *   Seven
 *
 * * * * * * * * * *
 */

.MarqueDiscover--higlight {
  font-family: 'AmbroiseRegular', sans-serif;
}

.MarqueDiscover--higlight * {
  text-align: center !important;
}

.MarqueDiscover-desktop .MarqueDiscover--higlight {
  font-size: 40px;
  max-width: 800px;
  margin: auto;
}

.MarqueDiscover-tablet .MarqueDiscover--higlight {
  font-size: 30px;
  max-width: 600px;
  margin: auto;
}

.MarqueDiscover-mobile .MarqueDiscover--higlight {
  font-size: 25px;
  max-width: 400px;
  margin: auto;
  padding: 0px 20px 50px 20px;
}

/**/

.MarqueDiscover--higlightDivider {
  width: 100px;
  height: 1px;
  background-color: #31302F;
  margin: auto;
}

/*
 * * * * * * * * * *
 *
 *   Next
 *
 * * * * * * * * * *
 */

.MarqueDiscover--next {
  display: flex;
  justify-content: center;
}

.MarqueDiscover-desktop .MarqueDiscover--next {

}

.MarqueDiscover-tablet .MarqueDiscover--next {
  transform: scale(0.75);
}

.MarqueDiscover-mobile .MarqueDiscover--next {
  transform: scale(0.5);
}

/*
 * * * * * * * * * *
 *
 *   Component
 *
 * * * * * * * * * *
 */

.MarqueDiscover--photo-1 {

}

.MarqueDiscover-desktop .MarqueDiscover--photo-1 {
  width: 600px;
  height: 800px;
}

@media screen and (max-width: $menu-image-screen) {
  .MarqueDiscover-desktop .MarqueDiscover--photo-1 {
    width: 500px;
    height: 800px;
  }
}

.MarqueDiscover-tablet .MarqueDiscover--photo-1 {
  width: 500px;
  height: 600px;
}

.MarqueDiscover-mobile .MarqueDiscover--photo-1 {
  width: 100%;
  height: 500px;
  padding: 20px;
  box-sizing: border-box;
}

/**/

.MarqueDiscover--photo-2 {
}

.MarqueDiscover-desktop .MarqueDiscover--photo-2 {
  width: 300px;
  height: 300px;
}

@media screen and (max-width: $menu-image-screen) {
  .MarqueDiscover-desktop .MarqueDiscover--photo-2 {
    width: 250px;
    height: 250px;
  }
}

.MarqueDiscover-tablet .MarqueDiscover--photo-2 {
  display: none;
}

.MarqueDiscover-mobile .MarqueDiscover--photo-2 {
  display: none;
}

/**/

.MarqueDiscover--photo-3 {
}

.MarqueDiscover-desktop .MarqueDiscover--photo-3 {
  width: 500px;
  height: 600px;
}

.MarqueDiscover-tablet .MarqueDiscover--photo-3 {
  width: 400px;
  height: 500px;
}

.MarqueDiscover-mobile .MarqueDiscover--photo-3 {
  width: 100%;
  height: 400px;
  box-sizing: border-box;
  padding: 0 20px;
}


/**/

.MarqueDiscover--photo-4 {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.MarqueDiscover-desktop .MarqueDiscover--photo-4 {

}

.MarqueDiscover-tablet .MarqueDiscover--photo-4 {

}

.MarqueDiscover-mobile .MarqueDiscover--photo-4 {

}

/**/

.MarqueDiscover--spacer {
}

.MarqueDiscover-desktop .MarqueDiscover--spacer {
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
}

.MarqueDiscover-tablet .MarqueDiscover--spacer {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.MarqueDiscover-mobile .MarqueDiscover--spacer {
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
}

/**/

.MarqueDiscover--title {
  font-family: 'AmbroiseRegular', sans-serif;
  color: #1B1919;
}

.MarqueDiscover-desktop .MarqueDiscover--title {
  font-size: 50px;
}

.MarqueDiscover-tablet .MarqueDiscover--title {
  font-size: 40px;
}

.MarqueDiscover-mobile .MarqueDiscover--title {
  font-size: 30px;
  padding: 0 20px;
  text-align: center;
}

/**/

.MarqueDiscover--titleBrown {
  font-family: 'CommutersSansBold', sans-serif;
  color: #895B4F;
  text-transform: uppercase;
}

.MarqueDiscover-desktop .MarqueDiscover--titleBrown {
  font-size: 14px;
}

.MarqueDiscover-tablet .MarqueDiscover--titleBrown {
  font-size: 14px;
}

.MarqueDiscover-mobile .MarqueDiscover--titleBrown {
  font-size: 12px;
  text-align: center;
  padding: 0 20px;
}

/**/

.MarqueDiscover--subTitle {
  font-family: 'AmbroiseRegular', sans-serif;
  color: #1B1919;
}

.MarqueDiscover-desktop .MarqueDiscover--subTitle {
  font-size: 50px;
}

.MarqueDiscover-tablet .MarqueDiscover--subTitle {
  font-size: 40px;
}

.MarqueDiscover-mobile .MarqueDiscover--subTitle {
  font-size: 30px;
  padding: 0 20px;
  text-align: center;
}

.MarqueDiscover--subTitle-1 {
  text-align: right;
}

/**/

.MarqueDiscover--paragraph {
  font-family: 'AeonikRegular', sans-serif;
  letter-spacing: 0.25px;
  line-height: 28px;
  color: #31302F;
}

.MarqueDiscover-desktop .MarqueDiscover--paragraph {
  font-size: 18px;
}

.MarqueDiscover-tablet .MarqueDiscover--paragraph {
  font-size: 17px;
}

.MarqueDiscover-mobile .MarqueDiscover--paragraph {
  font-size: 15px;
  text-align: center;
  padding: 0 20px;
}

/**/

.MarqueDiscover--question {
  font-family: 'AeonikMedium', sans-serif;
  letter-spacing: 0.25px;
  line-height: 26px;
  color: #31302F;
  font-style: italic;
}

.MarqueDiscover-desktop .MarqueDiscover--question {
  font-size: 17px;
}

.MarqueDiscover-tablet .MarqueDiscover--question {
  font-size: 17px;
}

.MarqueDiscover-mobile .MarqueDiscover--question {
  font-size: 15px;
  text-align: center;
  padding: 0 20px;
}

/**/

.MarqueDiscover--author {
  font-family: 'AeonikRegular', sans-serif;
  letter-spacing: 0.25px;
  line-height: 26px;
  color: #31302F;
}

.MarqueDiscover-desktop .MarqueDiscover--author {
  font-size: 17px;
  display: flex;
  align-items: center;
}

.MarqueDiscover-tablet .MarqueDiscover--author {
  font-size: 17px;
  display: flex;
  align-items: center;
}

.MarqueDiscover-mobile .MarqueDiscover--author {
  font-size: 15px;
  text-align: center;
  padding: 0 20px;
}

.MarqueDiscover--author span:first-child {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  margin-right: 10px;
}

.MarqueDiscover--author-1 span:first-child {
  background-color: #895B4F;
}

.MarqueDiscover--author-2 span:first-child {
  background-color: #C9B086;
}

.MarqueDiscover--author span:last-child {

}

/**/

.MarqueDiscover--paragraph-1 {
}

.MarqueDiscover-desktop .MarqueDiscover--paragraph-1 {
  max-width: 500px;
}

.MarqueDiscover-tablet .MarqueDiscover--paragraph-1 {
  max-width: 400px;
}

.MarqueDiscover-mobile .MarqueDiscover--paragraph-1 {
}

/**/

.MarqueDiscover--paragraph-2 {
}

.MarqueDiscover-desktop .MarqueDiscover--paragraph-2 {
  max-width: 400px;
  text-align: right;
}

.MarqueDiscover-tablet .MarqueDiscover--paragraph-2 {
  max-width: 400px;
  text-align: right;
}

/**/

.MarqueDiscover--paragraph-3 {
}

.MarqueDiscover-desktop .MarqueDiscover--paragraph-3 {
  max-width: 400px;
}

.MarqueDiscover-tablet .MarqueDiscover--paragraph-3 {
  max-width: 300px;
}

.MarqueDiscover-mobile .MarqueDiscover--paragraph-3 {
}

/**/

.MarqueDiscover--paragraph-4 {
  text-transform: uppercase;
}
</style>

<template>
  <div class="MarqueDiscover" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <MarqueHeader :term="lang.term(737)" :imgSrc="img.header"></MarqueHeader>
      <MarqueTab :tab="state.tab" :tabs="state.tabs" :onChange="tab => onChangeTab(tab)"></MarqueTab>

      <div class="MarqueDiscover--first" data-id="story">

        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--spacer"></div>

        <div class="MarqueDiscover--titleBrown">
          <AnimationTitle :fully="false">
            <Term :term="lang.term(738)"></Term>
          </AnimationTitle>
        </div>

        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--spacer"></div>

        <div class="MarqueDiscover--title">
          <AnimationTitle :fully="false">
            <Term :term="lang.term(739)" tag="h2"></Term>
          </AnimationTitle>
        </div>

        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--spacer"></div>

        <div class="MarqueDiscover--paragraph">
          <AnimationTitle :fully="false">
            <Term type="textarea" :term="lang.term(740)"></Term>
          </AnimationTitle>
        </div>
      </div>

      <div class="MarqueDiscover--spacer"></div>
      <div class="MarqueDiscover--spacer"></div>
      <div class="MarqueDiscover--spacer"></div>

      <div class="MarqueDiscover--spacer"></div>
      <div class="MarqueDiscover--spacer"></div>
      <div class="MarqueDiscover--spacer"></div>

      <div class="MarqueDiscover--second">
        <div class="MarqueDiscover--photo-1">
          <ScrollParallax :distance="50" :reverse="true">
            <Picture :src="img.photo1"></Picture>
          </ScrollParallax>
        </div>
        <div class="MarqueDiscover--secondRight">
          <div class="MarqueDiscover--block-1">
            <div>
              <div class="MarqueDiscover--paragraph MarqueDiscover--paragraph-1">
                <!--                <AnimationTitle :fully="false">-->
                <Term type="textarea" :term="lang.term(741)"></Term>
                <!--                </AnimationTitle>-->
              </div>
            </div>
          </div>
          <div class="MarqueDiscover--photo-2">
            <ScrollParallax :distance="50" :reverse="false">
              <Picture :src="img.photo2"></Picture>
            </ScrollParallax>
          </div>
        </div>
      </div>

      <div class="MarqueDiscover--spacer"></div>
      <div class="MarqueDiscover--spacer"></div>
      <div class="MarqueDiscover--spacer"></div>

      <div class="MarqueDiscover--third">
        <div class="MarqueDiscover--thirdLeft">
          <div>
            <div class="MarqueDiscover--paragraph MarqueDiscover--paragraph-2">
              <!--              <AnimationTitle :fully="false">-->
              <Term type="textarea" :term="lang.term(742)"></Term>
              <!--              </AnimationTitle>-->
            </div>
          </div>
        </div>

        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--spacer"></div>

        <div class="MarqueDiscover--thirdRight">
          <div class="MarqueDiscover--photo-3">
            <ScrollParallax :distance="50" :reverse="false">
              <Picture :src="img.photo3"></Picture>
            </ScrollParallax>
          </div>
        </div>
      </div>

      <div class="MarqueDiscover--spacer"></div>
      <div class="MarqueDiscover--spacer"></div>
      <div class="MarqueDiscover--spacer"></div>

      <div class="MarqueDiscover--spacer"></div>
      <div class="MarqueDiscover--spacer"></div>
      <div class="MarqueDiscover--spacer"></div>

      <div class="MarqueDiscover--four">
        <AnimationTitle :fully="false">
          <Term :term="lang.term(743)"></Term>
        </AnimationTitle>
      </div>

      <div class="MarqueDiscover--spacer"></div>
      <div class="MarqueDiscover--spacer"></div>
      <div class="MarqueDiscover--spacer"></div>

      <div class="MarqueDiscover--spacer"></div>
      <div class="MarqueDiscover--spacer"></div>
      <div class="MarqueDiscover--spacer"></div>

      <div class="MarqueDiscover--five" data-id="interview">
        <div class="MarqueDiscover--photo-4">
          <Picture :src="img.photo4"></Picture>
        </div>

        <div class="MarqueDiscover--fiveTitle">
          <AnimationTitle :fully="false">
            <Term :term="lang.term(744)"></Term>
          </AnimationTitle>
        </div>

        <div class="MarqueDiscover--spacer"></div>

        <div class="MarqueDiscover--fiveBlock">

          <div class="MarqueDiscover--spacer"></div>
          <div class="MarqueDiscover--spacer"></div>
          <div class="MarqueDiscover--spacer"></div>

          <div class="MarqueDiscover--title">
            <AnimationTitle :fully="false">
              <Term :term="lang.term(745)"></Term>
            </AnimationTitle>
          </div>

          <div class="MarqueDiscover--spacer"></div>

          <div class="MarqueDiscover--paragraph MarqueDiscover--paragraph-4">
            <AnimationTitle :fully="false">
              <Term :term="lang.term(746)"></Term>
            </AnimationTitle>
          </div>
        </div>
      </div>

      <div class="MarqueDiscover--spacer"></div>
      <div class="MarqueDiscover--spacer"></div>
      <div class="MarqueDiscover--spacer"></div>
      <div class="MarqueDiscover--spacer"></div>

      <div class="MarqueDiscover--six">


        <div class="MarqueDiscover--question">
          <AnimationTitle :fully="false">
            <Term :term="lang.term(747)"></Term>
          </AnimationTitle>
        </div>
        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--author MarqueDiscover--author-1">
          <span></span>
          <span>Denis Marcel :</span>
        </div>
        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--paragraph">
          <Term type="textarea" :term="lang.term(748)"></Term>
        </div>

        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--spacer"></div>

        <div class="MarqueDiscover--question">
          <AnimationTitle :fully="false">
            <Term :term="lang.term(749)"></Term>
          </AnimationTitle>
        </div>
        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--author MarqueDiscover--author-1">
          <span></span>
          <span>Denis Marcel :</span>
        </div>
        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--paragraph">
          <Term type="textarea" :term="lang.term(750)"></Term>
        </div>

        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--spacer"></div>

        <div class="MarqueDiscover--question">
          <AnimationTitle :fully="false">
            <Term :term="lang.term(751)"></Term>
          </AnimationTitle>
        </div>
        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--author MarqueDiscover--author-1">
          <span></span>
          <span>Denis Marcel :</span>
        </div>
        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--paragraph">
          <Term type="textarea" :term="lang.term(752)"></Term>
        </div>

        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--spacer"></div>

        <div class="MarqueDiscover--higlight">
          <AnimationTitle :fully="false">
            <Term :term="lang.term(753)"></Term>
            <div class="MarqueDiscover--spacer"></div>
            <div class="MarqueDiscover--higlightDivider"></div>
          </AnimationTitle>
        </div>

        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--spacer"></div>


        <div class="MarqueDiscover--question">
          <AnimationTitle :fully="false">
            <Term :term="lang.term(754)"></Term>
          </AnimationTitle>
        </div>
        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--author MarqueDiscover--author-2">
          <span></span>
          <span>Georges Bonichon :</span>
        </div>
        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--paragraph">
          <Term type="textarea" :term="lang.term(755)"></Term>
        </div>

        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--spacer"></div>


        <div class="MarqueDiscover--question">
          <AnimationTitle :fully="false">
            <Term :term="lang.term(756)"></Term>
          </AnimationTitle>
        </div>
        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--author MarqueDiscover--author-2">
          <span></span>
          <span>Georges Bonichon :</span>
        </div>
        <div class="MarqueDiscover--spacer"></div>
        <div class="MarqueDiscover--paragraph">
          <Term type="textarea" :term="lang.term(757)"></Term>
        </div>
      </div>

      <div class="MarqueDiscover--spacer"></div>
      <div class="MarqueDiscover--spacer"></div>
      <div class="MarqueDiscover--spacer"></div>
      <div class="MarqueDiscover--spacer"></div>

      <div class="MarqueDiscover--next">
        <MarqueNext :term="lang.term(758)" :url="next"></MarqueNext>
      </div>

      <CountryLocation :alternate="true">
        <template slot="default">
          <HomeSpot :nuxtProp="nuxtProp"
                    :breakPoints="props.Responsive.breakPoints"
                    :verticalBreakPoints="props.Responsive.verticalBreakPoints">
          </HomeSpot>
        </template>
        <template slot="alternate">
          <div class="MarqueSolar--spacer"></div>
          <div class="MarqueSolar--spacer"></div>
          <div class="MarqueSolar--spacer"></div>
          <div class="MarqueSolar--spacer"></div>
          <div class="MarqueSolar--spacer"></div>
          <div class="MarqueSolar--spacer"></div>
        </template>
      </CountryLocation>

    </Responsive>
  </div>
</template>

<script>

// -----

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {eventService} from "../../service/eventService";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {scrollerParentService} from "../../service/scrollerParentService";
import {getUrlByProjectUrlId} from "../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../@common/constant/PROJECT_URL";

// -----

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    const lang = getLangServiceBrowser()
    return {
      lang,
      img: {
        header: require('../../../@common/assets/marque/discover/header.jpg'),
        photo1: require('../../../@common/assets/marque/discover/photo1.jpg'),
        photo2: require('../../../@common/assets/marque/discover/photo2.jpg'),
        photo3: require('../../../@common/assets/marque/discover/photo3.jpg'),
        photo4: require('../../../@common/assets/marque/discover/photo4.png'),
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              width: 0
            }),
            new BreakPoint({
              name: SCREEN.TABLET,
              width: 1024
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              width: 1440
            }),
          ],
          verticalBreakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              height: 0
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              height: 600
            }),
          ],
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      state: {
        tab: {
          id: 'story',
          term: lang.term(759),
        },
        tabs: [
          {
            id: 'story',
            term: lang.term(760),
          },
          {
            id: 'interview',
            term: lang.term(761)
          },
        ]
      },
      events: []
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`MarqueDiscover-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`MarqueDiscover-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`MarqueDiscover-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`MarqueDiscover-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`MarqueDiscover-desktopY`)
      return classes
    },
    next() {
      return getUrlByProjectUrlId(this.nuxtProp, PROJECT_URL.MARQUE_CONCEPTION_ET_FABRICATION)
    }
  },
  beforeMount() {
    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    onChangeTab(tab) {
      this.state.tab = tab
      scrollerParentService.scrollToElement(`div[data-id="${tab.id}"]`, v => v - 100)
    }
  }
}
</script>