<style scoped>
.ArticleAdmin {
}

.ArticleAdmin--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 32px;
}

.ArticleAdmin--headerLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ArticleAdmin--headerRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ArticleAdmin--headerRight ::v-deep .InputText input {
  border-radius: 4px 0 0 4px;
  position: relative;
  left: 1px;
}

.ArticleAdmin--headerRight ::v-deep .InputSelect input {
  border-radius: 0 4px 4px 0;
}

.ArticleAdmin--loader {
  width: 100%;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ArticleAdmin--pagination {
  padding: 5px;
}

.ArticleAdmin--modal {
  padding: 0px 50px 50px 50px;
}

.ArticleAdmin--modalPhoto {
  padding: 50px;
}

.ArticleAdmin--footer {
  display: flex;
  justify-content: flex-end;
}

.ArticleAdmin--spacer {
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
}

.ArticleAdmin--link {
  display: block;
  text-decoration: none;
  width: 100%;
}

.ArticleAdmin--option {
  width: 24px;
  cursor: pointer;
}

.ArticleAdmin--name {
  display: flex;
}

.ArticleAdmin--name ::v-deep .Tag {
  margin-right: 10px;
}
</style>

<template>
  <div class="ArticleAdmin">
    <div class="ArticleAdmin--header">
      <div class="ArticleAdmin--headerLeft">
        <Popover position="bottom-start">
          <template slot="reference">
            <ButtonAction :term="lang.term(157)" :svg="icon.add"></ButtonAction>
          </template>
          <template slot="default">
            <List :svg="icon.add" :text="lang.translate(196)" :onClick="() => openInsert()"></List>
            <List :svg="icon.add" :text="lang.translate(197)" :onClick="() => openInsert(true)"></List>
          </template>
        </Popover>

        <div class="ArticleAdmin--spacer"></div>

        <InputSwitch :label="lang.term(160)"
                     :value="state.filterActive"
                     :onChangeValue="v => state.filterActive = v">
        </InputSwitch>

        <div class="ArticleAdmin--spacer"></div>

        <InputSwitch :label="lang.term(305)"
                     :value="state.filterIsBatch"
                     :onChangeValue="v => state.filterIsBatch = v">
        </InputSwitch>
      </div>
      <div class="ArticleAdmin--headerRight">
        <div style="width: 24px;">
          <Vector :svg="icon.search"></Vector>
        </div>
        <div class="ArticleAdmin--spacer"></div>
        <InputText :value="state.filterSearch" :onChangeValue="v => state.filterSearch = v"></InputText>
        <InputSelect :items="state.searchTypes"
                     :values="state.selectedSearchTypes"
                     :max="1"
                     :onChangeValues="v => state.selectedSearchTypes = v">
        </InputSelect>
      </div>
    </div>
    <div class="ArticleAdmin--body" v-show="!state.loadingSelect">
      <el-table
          :data="state.articles"
          style="width: 100%">
        <el-table-column
            width="60"
            label="Id"
            prop="id"
        >
        </el-table-column>
        <el-table-column
            width="150"
            :label="lang.translate(161)"
            :formatter="getReference"
        >
        </el-table-column>
        <el-table-column
            :label="lang.translate(200)"
        >
          <template slot-scope="scope">
            <div class="ArticleAdmin--name">
              <Tag v-if="!state.articles[scope.$index].visible">
                invisible
              </Tag>
              {{ getTerm(state.articles[scope.$index]) }}
            </div>
          </template>
        </el-table-column>
        <el-table-column
            width="180"
            :formatter="getPrice"
            :label="lang.translate(202)">
        </el-table-column>
        <el-table-column
            width="100"
            :label="lang.translate(203)">
          <template slot-scope="scope">
            <Popover position="bottom-end">
              <template slot="default">
                <List :svg="icon.edit" :text="`éditer`" :onClick="() => openUpdate(state.articles[scope.$index])"></List>
                <Link class="ArticleAdmin--link" :href="getHref(state.articles[scope.$index])" :newPage="true">
                  <List :svg="icon.visibility" :text="`voir sur le shop`"></List>
                </Link>
                <List :svg="state.articles[scope.$index].active ? icon.archive : icon.unarchive" :text="state.articles[scope.$index].active ? 'désactiver' : 'activer'" :onClick="() => openDelete(state.articles[scope.$index])"></List>
              </template>
              <template slot="reference">
                <div class="ArticleAdmin--option">
                  <Vector :svg="icon.more_horiz"></Vector>
                </div>
              </template>
            </Popover>
          </template>
        </el-table-column>
      </el-table>
    </div>
    <div class="ArticleAdmin--loader" v-show="state.loadingSelect">
      <Loader></Loader>
    </div>
    <br>
    <br>
    <div class="ArticleAdmin--pagination">
      <Pagination :limit="state.filterLimit"
                  :offset="state.filterOffset"
                  :total="state.articleCount"
                  :range="10"
                  :onOffsetChange="v => state.filterOffset = v">
      </Pagination>
    </div>
    <Modal :opened="state.opened.upsert" :onOpenedChange="v => state.opened.upsert = v">
      <div class="ArticleAdmin--modal">
        <Form>
          <ArticleForm v-if="state.opened.upsert"
                       :form="state.form"
                       :article="state.article"
                       :onClickUpdatePhoto="v => onClickUpdatePhoto(v)"
                       :onArticleChange="v => onArticleChange(v)">
          </ArticleForm>
          <br>
          <Divider></Divider>
          <br>
          <div class="ArticleAdmin--footer">
            <ButtonAction :term="lang.term(158)" :onClick="() => upsert()" :loading="state.loading"></ButtonAction>
            <div class="ArticleAdmin--spacer"></div>
            <ButtonLink :term="lang.term(159)" :onClick="() => state.opened.upsert = false"></ButtonLink>
          </div>
        </Form>
      </div>
    </Modal>
    <ModalConfirm :opened="state.opened.remove"
                  :onOpenedChange="v => state.opened.remove = v"
                  :on-validated="() => remove()"
                  :loading="state.loading">
    </ModalConfirm>
    <Modal :opened="state.opened.photo" :onOpenedChange="v => state.opened.photo = v">
      <div class="ArticleAdmin--modalPhoto">
        <PhotoForm :photo="state.photo" :onPhotoChange="v => onPhotoChange(v)"></PhotoForm>
      </div>
    </Modal>
  </div>
</template>

<script>
import {apiClient} from "../../service/apiClientService";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

import select from "../../../@common/api/article/selectArticles";
import create from "../../../@common/api/article/createArticle";

import {controlForm} from "../../function/controlForm";

import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {Article} from "../../../@common/delta/database/Article";
import {Product} from "../../../@common/delta/database/Product";
import {Term} from "../../../@common/delta/database/Term";
import {Photo} from "../../../@common/delta/database/Photo";
import {Page} from "../../../@common/delta/database/Page";
import {Batch} from "../../../@common/delta/database/Batch";
import {calculateTTC} from "../../../@common/function/calculateTTC";
import {formatPrice} from "../../../@common/function/formatPrice";
import {convertPathToUrl} from "../../../@common/function/helperUrl";
import {urlService} from "../../../@common/service/urlService";
import {Url} from "../../../@common/delta/database/Url";
import {findDefaultArticleTermNames} from "../../function/findDefaultArticleTermNames";
import {ARTICLE_SEARCH_ID} from "../../../@common/constant/ARTICLE";
import {Debounce} from "../../../@common/ts/Debounce";
import {Component} from "../../../@common/delta/database/Component";
import {sendError} from "../../function/sendError";

let debounce = new Debounce()

export default {
  props: {},
  data() {
    return {
      lang: getLangServiceBrowser(),
      icon: {
        add: require('@bbx/vector~master/core/assets/svg/material/add.svg'),
        edit: require('@bbx/vector~master/core/assets/svg/material/edit.svg'),
        visibility: require('@bbx/vector~master/core/assets/svg/material/visibility.svg'),
        delete: require('@bbx/vector~master/core/assets/svg/material/delete.svg'),
        archive: require('@bbx/vector~master/core/assets/svg/material/archive.svg'),
        unarchive: require('@bbx/vector~master/core/assets/svg/material/unarchive.svg'),
        more_horiz: require('@bbx/vector~master/core/assets/svg/material/more_horiz.svg'),
        search: require('@bbx/vector~master/core/assets/svg/material/search.svg'),
      },
      props: {},
      state: {
        /**
         * @type boolean
         */
        tab: 'all',
        opened: {
          upsert: false,
          remove: false,
          photo: false,
        },
        /**
         * @type ApiResponseForm
         */
        form: new ApiResponseForm(),
        /**
         * @type Article
         */
        article: this.defaultArticle(),
        /**
         * @type boolean
         */
        loading: false,
        /**
         * @type boolean
         */
        loadingSelect: false,
        /**
         * @type boolean
         */
        loadingArticle: false,
        /**
         * @type number
         */
        filterOffset: 0,
        /**
         * @type number
         */
        filterLimit: 12,
        /**
         * @type boolean
         */
        filterActive: true,
        /**
         * @type boolean
         */
        filterIsBatch: false,
        /**
         * @type string
         */
        filterSearch: '',
        /**
         * @type Article[]
         */
        articles: [],
        /**
         * @type number
         */
        articleCount: 0,
        /**
         * @type Photo
         */
        photo: new Photo(),
        /**
         * @type any[]
         */
        searchTypes: [
          {
            id: ARTICLE_SEARCH_ID.ID,
            value: 'Id'
          },
          {
            id: ARTICLE_SEARCH_ID.REFERENCE,
            value: 'Référence'
          },
          {
            id: ARTICLE_SEARCH_ID.NAME,
            value: 'Nom'
          },
        ],
        /**
         * @type any[]
         */
        selectedSearchTypes: [
          {
            id: ARTICLE_SEARCH_ID.REFERENCE,
            value: 'Référence'
          },
        ],
      },
    }
  },
  watch: {
    'state.filterActive': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.filterOffset': function () {
      if (this.state.loadingSelect === false) {
        this.select().catch(sendError)
      }
    },
    'state.filterLimit': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.filterSearch': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.filterIsBatch': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.selectedSearchTypes': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    }
  },
  computed: {},
  beforeMount() {
  },
  mounted() {
    this.select().catch(sendError)
  },
  beforeDestroy() {
  },
  methods: {
    async select() {
      this.state.loadingSelect = true

      const filter = {
        active: this.state.filterActive,
        isBatch: this.state.filterIsBatch,
        $search: this.state.filterSearch,
        $searchType: this.state.selectedSearchTypes.map(a => a.id).pop(),
        limit: this.state.filterLimit,
        offset: this.state.filterOffset,
      }

      debounce.callback({
        job: () => apiClient.do(select, select.with(filter)),
        success: res => {
          this.state.articles = res.data;
          this.state.articleCount = res.count;
          this.state.loadingSelect = false
        },
        error: err => {
          this.state.loadingSelect = false
          sendError(err)
        }
      })
    },

    /**
     * @returns {Promise<void>}
     */
    async remove() {
      this.state.article.active = !this.state.article.active;
      await this.upsert()
    },

    async upsert() {

      const payload = new Article(this.state.article)

      // return console.log(JSON.stringify(payload, null, 4));

      let res = await controlForm({
        apiAction: create,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loading',
        loaderState: this.state,
        payload: payload,
        vue: this,
      });

      if (res.success) {

        this.state.article = this.defaultArticle()

        this.state.opened.upsert = false
        this.state.opened.remove = false

        await this.select();
      }
    },

    /**
     * @param {Article} article
     * @returns {Promise<void>}
     */
    async openUpdate(article) {
      this.state.article = this.defaultArticle(new Article(article))
      this.state.opened.upsert = true
      this.state.form = new ApiResponseForm()
    },

    /**
     * @param {Article} article
     * @returns {Promise<void>}
     */
    async openDelete(article) {
      this.state.article = this.defaultArticle(new Article(article))
      this.state.opened.remove = true
    },

    /**
     * @param {boolean} isBatch
     * @returns {Promise<void>}
     */
    async openInsert(isBatch = false) {
      this.state.article = this.defaultArticle(new Article({isBatch: isBatch}))
      this.state.opened.upsert = true
      this.state.form = new ApiResponseForm()
    },

    /**
     * @param {Article} article
     * @returns {Article}
     */
    defaultArticle(article = new Article) {

      if (!article.isBatch) {
        if (!article.batches.length) article.batches.push(new Batch({
          quantity: 1
        }))
        for (const batch of article.batches) {
          if (!batch.products.length) batch.products.push(new Product())
          for (const product of batch.products) {
            if (!product.termNames.length) product.termNames.push(new Term)
            if (!product.termDescriptions.length) product.termDescriptions.push(new Term)
            if (!product.termMaintains.length) product.termMaintains.push(new Term)
            if (!product.components.length) product.components.push(new Component())
          }
        }
      }

      if (!article.pages.length) article.pages.push(new Page())
      for (const page of article.pages) {
        if (!page.termDescriptions.length) page.termDescriptions.push(new Term)
      }
      if (!article.termNames.length) article.termNames.push(new Term)
      if (!article.termDescriptions.length) article.termDescriptions.push(new Term)

      return article
    },
    /**
     * @param {Article} article
     */
    getTerm(article) {
      const terms = findDefaultArticleTermNames(article)
      let names = []
      for (const term of terms) {
        names.push(this.lang.translateTerm(term))
      }
      return names.join(' ')
    },
    /**
     * @param {Article} article
     */
    getPrice(article) {
      return formatPrice(calculateTTC(article.price))
    },
    /**
     * @param {Article} article
     */
    getReference(article) {
      /** @type {string[]} */
      const references = []
      for (const batch of article.batches) {
        for (const product of batch.products) {
          references.push(product.reference)
        }
      }
      return references.join('+')
    },
    /**
     * @param {Photo} photo
     */
    onClickUpdatePhoto(photo) {
      this.state.opened.photo = true
      this.state.photo = photo
    },
    /**
     * @param {Article} article
     */
    getHref(article) {
      const page = convertPathToUrl(new Page(article.pages[0]))
      return urlService.getProjectUrl(this.lang.lang.iso6391, new Url(page.urls[0]))
    },
    /**
     * @param {Photo} photo
     */
    onPhotoChange(photo) {
      // console.log('GROUND', photo.cover, photo.id);

      // we clean up all the previous covers
      if (photo.cover) {
        for (const photo_ of this.state.article.photos) {
          // console.log('1. FIRST', photo.id, photo_.id, photo_.cover);
          if (photo_.id !== photo.id) photo_.cover = false
        }
        for (const batch of this.state.article.batches) {
          for (const product of batch.products) {
            for (const photo_ of product.photos) {
              // console.log('1. SECOND', photo.id, photo_.id, photo_.cover);
              if (photo_.id !== photo.id) photo_.cover = false
            }
          }
        }
        // for (const photo_ of this.state.article.photos) {
        //   console.log('2. FIRST', photo.id, photo_.id, photo_.cover);
        // }
        // for (const product of this.state.article.products) {
        //   for (const photo_ of product.photos) {
        //     console.log('2. SECOND', photo.id, photo_.id, photo_.cover);
        //   }
        // }
      }
      this.state.photo = photo
    },

    /**
     *
     * @param {Article} article
     */
    onArticleChange(article) {
      this.state.article = article
    }
  }
}
</script>
