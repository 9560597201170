import { render, staticRenderFns } from "./MaterialAdmin.vue?vue&type=template&id=25a3e696&scoped=true&"
import script from "./MaterialAdmin.vue?vue&type=script&lang=js&"
export * from "./MaterialAdmin.vue?vue&type=script&lang=js&"
import style0 from "./MaterialAdmin.vue?vue&type=style&index=0&id=25a3e696&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "25a3e696",
  null
  
)

export default component.exports