<style scoped>
.ModalConfirm {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  overflow: hidden;
  display: none;
}

.ModalConfirm-opened {
  display: block;
}

.ModalConfirm--mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 300ms;
  cursor: pointer;
  opacity: 0;
}

.ModalConfirm-opening .ModalConfirm--mask {
  opacity: 1;
}

.ModalConfirm--body {
  position: absolute;
  bottom: 50px;
  left: 50%;
  margin-left: -250px;
  transition: transform 300ms, opacity 300ms;
  transform: translateY(100%);
}

.ModalConfirm-mobile .ModalConfirm--body {
  width: 100%;
  margin: 0px;
  padding: 0 25px;
  bottom: 25px;
  left: 0%;
  text-align: center;
  box-sizing: border-box;
}

.ModalConfirm-opening .ModalConfirm--body {
  transform: translateY(0%);
}

.ModalConfirm--bodyCard {
  background: rgba(255, 255, 255, 1);
  border-radius: 4px;
  padding: 50px;
  max-width: 400px;
}

.ModalConfirm-mobile .ModalConfirm--bodyCard {
  max-width: none;
}

.ModalConfirm--footer {
}

.ModalConfirm--formFooter {
  display: flex;
  justify-content: flex-end;
}

.ModalConfirm-mobile .ModalConfirm--formFooter {
  flex-wrap: wrap;
}

.ModalConfirm-mobile .ModalConfirm--formFooter .ModalConfirm--spacer {
  display: none;
}

.ModalConfirm-mobile .ModalConfirm--formFooter ::v-deep .ButtonAction {
  width: 100%;
  margin-bottom: 15px;
}

.ModalConfirm-mobile .ModalConfirm--formFooter ::v-deep .ButtonAction .ButtonAction--icon {
  display: none;
}

.ModalConfirm-mobile .ModalConfirm--formFooter ::v-deep .ButtonAction button {
  padding: 15px 0px 14px 0px;
}

.ModalConfirm-mobile .ModalConfirm--formFooter ::v-deep .ButtonLink {
  width: 100%;
}

.ModalConfirm-mobile .ModalConfirm--formFooter ::v-deep .ButtonLink .ButtonAction--icon {
  display: none;
}

.ModalConfirm-mobile .ModalConfirm--formFooter ::v-deep .ButtonLink button {
  padding: 15px 0px 14px 0px;
}

.ModalConfirm--spacer {
  width: 30px;
  min-width: 30px;
  min-height: 30px;
}
</style>

<template>
  <div class="ModalConfirm App--zindex" :class="class_root" :id="state.idRoot">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="onBreakPointChange"
                :onVerticalBreakPointChange="onVerticalBreakPointChange">
      <div class="ModalConfirm--mask" @click="() => close()"></div>
      <div class="ModalConfirm--body">
        <div class="ModalConfirm--bodyCard">
          <slot>
            <Term :term="langTerm(286)"/>
          </slot>
          <br>
          <Divider></Divider>
          <br>
          <div class="ModalConfirm--footer">
            <Form>
              <div class="ModalConfirm--formFooter">
                <ButtonAction :term="langTerm(284)" :onClick="() => onValidated()" :loading="loading"/>
                <div class="ModalConfirm--spacer"></div>
                <ButtonLink :term="langTerm(285)" :onClick="() => close()"/>
              </div>
            </Form>
          </div>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {EVENT} from "../../../@common/constant/EVENT";
import {eventService} from "../../service/eventService";
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger.ts";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {waitElement} from "../../../@common/function/waitElement";
import Id from "@bbx/id~master/core/ts/Id";
import {sendError} from "../../function/sendError";

const id = new Id()

export default {

  props: {
    opened: {
      type: Boolean,
      default: () => false
    },
    onOpenedChange: {
      type: Function,
      default: () => (opened) => {
      }
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    onValidated: {
      type: Function,
      default: () => (opened) => {
      }
    },
  },
  data() {
    return JSON.parse(JSON.stringify({
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
          },
        },
      },
      state: {
        idRoot: `_${id.uniq()}`,
        setTimeOutOpen: null,
        setTimeOutClose: null,
        position: this.position,
        opened: this.opened,
        opening: false,
        parent: null,
        ready: false,
      },
    }))
  },
  async fetch() {
    this.state.idRoot = `_${id.uniq()}`
  },
  watch: {
    'opened': function (v) {
      v ? this.open() : this.close()
    },
    'state.opened': function (v) {
      this.onOpenedChange(v);
    }
  }
  ,
  computed: {
    class_root() {
      const classes = []
      if (this.state.opened) classes.push('ModalConfirm-opened')
      if (this.state.opening) classes.push('ModalConfirm-opening')
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`ModalConfirm-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`ModalConfirm-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`ModalConfirm-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`ModalConfirm-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`ModalConfirm-desktopY`)
      return classes
    }
  },
  mounted() {
    waitElement(async () => document.getElementById(this.state.idRoot)).then(modal => {
      this.state.parent = modal.parentNode
      this.state.ready = true;
      this.opened ? this.open() : this.close()
    }).catch(sendError);
  },
  beforeDestroy() {
    this.close()
  },
  methods: {
    open() {
      if (!this.state.ready) return
      if (this.setTimeOutOpen) clearTimeout(this.setTimeOutOpen)
      this.state.opened = true
      waitElement(async () => document.getElementById(this.state.idRoot)).then(modal => {
        document.body.appendChild(modal)
        eventService.triggerByName(new ListenerTrigger({
          name: EVENT.MODAL_OPENED
        }))
        setTimeout(() => {
          this.state.opening = this.state.opened
        })
      }).catch(sendError);
    },
    close() {
      const id = this.state.id
      if (!this.state.ready) return
      if (this.setTimeOutClose) clearTimeout(this.setTimeOutClose)
      this.state.opening = false
      this.setTimeOutClose = setTimeout(() => {
        this.state.opened = this.state.opening
        waitElement(async () => document.getElementById(this.state.idRoot)).then(modal => {
          try {
            this.state.parent.appendChild(modal)
          } catch (e) {
            let el = document.querySelector(`#${id}`)
            if (el) el.remove()
          }
        }).catch(sendError);
      }, 300)
    },
    onBreakPointChange(v) {
      this.props.Responsive['0'].breakPoint = v
    },
    onVerticalBreakPointChange(v) {
      this.props.Responsive['0'].verticalBreakPoint = v
    },
    langTerm(browserId) {
      const langServiceBrowser = getLangServiceBrowser()
      return langServiceBrowser.term(browserId)
    }
  },
}
</script>
