<style scoped lang="scss">

@import "../../scss/color";

.Price {
}

.Price--content {
  font-family: 'AeonikRegular', sans-serif;
  letter-spacing: 0.3px;
  display: flex;
  align-items: center;
}

.Price--big {
  color: $primary;
  font-size: 20px;
}

.Price-isOutlet .Price--big {
  color: $danger;
}

.Price--small {
  font-size: 12px;
  padding-left: 10px;
  color: $danger;
  text-decoration: line-through;
}

.Price--small span {
  color: $primary;
}
</style>

<template>
  <div class="Price" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="Price--content">
        <span class="Price--big">{{ formatPrice(isOutlet ? outletAmount : amount) }} €</span>
        <span v-if="isOutlet && withSmallPrice" class="Price--small">
          <span>{{ formatPrice(amount) }} €</span>
        </span>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {calculateTTC} from "../../../@common/function/calculateTTC";
import {formatPrice} from "../../../@common/function/formatPrice";

export default {

  props: {
    isOutlet: {
      type: Boolean,
      default: () => false
    },
    withSmallPrice: {
      type: Boolean,
      default: () => true
    },
    amount: {
      type: Number,
      default: () => 0
    },
    outletAmount: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      state: {},
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.isOutlet) classes.push('Price-isOutlet')
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    calculateTTC,
    formatPrice,
  }
}
</script>
