<style scoped>

</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="content-wrapper none for-title">
      <div class="container_12">
        <div class="main-container main-main-container col1-layout">
          <div class="grid_12 col-main">
            <div class="page-title">
              <h1>Comment entretenir son jardin en été ?</h1>
            </div>

            <div class="std">
              <p>
                <img alt="" src="./media/wysiwyg/nosconseils/bandeau-entretenir-son-jardin.jpg">&nbsp;&nbsp;
              </p>
              <p>&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">La saison estivale approche et vient avec,
                  <b>l’entretien de votre jardin</b>
                  . Rendre son extérieur agréable pour profiter de l’été qui s’annonce implique quelques
                  rafraichissements. Remise en état de la pelouse, redynamiser le sol, tailler les fleurs fanées,
                  éliminer les feuilles accumulées l’automne dernier, entretien du mobilier de jardin&nbsp;: découvrez
                  tous
                  <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_BIEN_DECORER_SON_JARDIN_EN_ETE)">
                    nos conseils d’entretien pour votre jardin
                  </Link>
                  cet été&nbsp;!
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <h2 style="text-align: justify;">Idée n°1&nbsp;: tondre sans difficulté avec le robot tondeuse tout
                terrain
              </h2>
              <p>
                <img style="float: right; margin-left: 30px;" alt="" src="./media/wysiwyg/nosconseils/pelouse.jpg">
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le charme de votre jardin et la pérennité de vos plantes reposent sur un
                  entretien accru. À l’approche de&nbsp;
                </span>
                <span style="font-size: large;">l’été, il est impératif de débroussailler et tailler votre pelouse, vos
                  arbustes, vos haies et vos arbres. Les recoins isolés ou difficilement accessibles demandent parfois
                  plus de travail, c’est pourquoi vous devez vous armer d’outils fiables. Équipez-vous d’un
                  <a rel="nofollow" href="https://www.bestofrobots.fr/robot-jardin/tondeuses-robots.html">bon modèle de
                    robot
                    tondeuse
                  </a>
                  tout terrain pour une tonte précise de votre pelouse même dans les passages les plus étroits. Vous
                  pourrez donner vie au jardin de vos rêves sans faire appel à un jardinier.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">L’autre avantage du
                  <b>robot tondeuse</b>
                  se trouve dans la simplicité et la rapidité d’utilisation. Des technologies offrant une
                  <b>mise en marche facile et autonome</b>
                  en plus d’une
                  <b>efficacité tout terrain</b>
                  que vous pourrez mettre en œuvre sur de très grandes surfaces ainsi que sur des pentes allant jusqu’à
                  30°. Certains modèles broient même le gazon en fines particules et le déposent à la base des racines
                  des herbes de sorte qu’il agisse comme un engrais ou un fertilisant.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Il existe également des
                  <b>packs robot tondeuse et coupe-bordure</b>
                  parfaits pour les finitions précises au niveau des haies, des arbres fruitiers ou encore pour
                  l’élagage des hautes herbes qui se sont installées dans votre jardin. À l’aide d’un robot tondeuse,
                  vous retrouvez un espace plus grand et plus agréable été comme hiver pour profiter de votre jardin
                  toute l’année.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;&nbsp;</p>
              <h2 style="text-align: justify;">Idée n°2&nbsp;: remettre à neuf votre mobilier de jardin</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le
                  <b>mobilier de jardin</b>
                  tient une grande place dans l’esthétique de votre extérieur. Or, une exposition prolongée au soleil
                  altère vos meubles de jardin. Terrasse, table, chaise ettransat demandent donc d’être protégés. Si
                  vous souhaitez prolonger la durée de vie de votre mobilier de jardin alors quelques conseils sont à
                  prendre en compte&nbsp;:
                  <br>
                  <br>
                </span>
              </p>
              <ul>
                <li style="text-align: justify;">
                  <span style="font-size: large;">Installer un parasol ou une tonnelle vous permet de profiter de votre
                    terrasse aux heures les plus chaudes, mais limite aussi l’impact du soleil sur le bois.
                    <br>
                    <br>
                  </span>
                </li>
                <li style="text-align: justify;">
                  <span style="font-size: large;">
                    <b>Protéger vos meubles</b>
                    en cas de pluie et les mettre à l’abri l’hiver évite que l’humidité ne les abîme.
                    <br>
                    <br>
                  </span>
                </li>
                <li style="text-align: justify;">
                  <span style="font-size: large;">
                    <b>Appliquer un vernis protecteur</b>
                    et nourrissant dès le printemps sur tous vos meubles en bois lutte contre le vieillissement
                    prématuré et l’apparition d’échardes.
                    <br>
                    <br>
                  </span>
                </li>
              </ul>
              <p>
                <img alt="" src="./media/wysiwyg/nosconseils/mobilier.jpg">
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <h2 style="text-align: justify;">
                <span>Idée n°3&nbsp;: un arrosage performant pour profiter de votre pelouse</span>
              </h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Rien de mieux qu’une pelouse bien verte pour bronzer au frais&nbsp;!
                  Néanmoins, il est parfois difficile de garder un gazon en pleine forme quand la chaleur se présente.
                  Durant les jours chauds de la saison estivale, les rayons du soleil viennent assécher la terre et
                  jaunir la verdure de votre extérieur.
                  <br>
                  <br>
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Pour lutter contre cette sècheresse intense que l’on connait des jours
                  de canicule, vous pouvez opter pour un
                  <b>arrosage automatique</b>
                  . Toutefois pour être sûr de garder un gazon vert tout l’été, vous devez bien choisir ce dernier. Un
                  large déploiement d’eau pour couvrir toutes les surfaces arides, une pluie fine et en quantité
                  suffisante, une programmation matin et soir, travaux à prévoir&nbsp;: de nombreux critères entrent en
                  jeu dans le choix d’un arrosage.
                  <br>
                  <br>
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">L’été, le jardin se transforme en une pièce supplémentaire à votre
                  maison aussi bien dans le nord que dans le sud de la France. Jardiner devient alors une activité qui
                  vous permet de décorer cet environnement à votre goût pour un été agréable.
                  <br>
                  <br>
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Devenez un as du jardinage en suivant pas à pas nos conseils pour
                  l’entretien de votre jardin. Quelle que soit la taille de votre extérieur, que vous ayez un potager,
                  une terrasse ou des arbres fruitiers, prendre soin de vos plantes vous permet de profiter d’un été au
                  frais dans un cadre harmonieux.
                </span>
              </p>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../../function/getH1";
import {Term} from "../../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../../@common/constant/PROJECT_URL";

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      PROJECT_URL,
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FAQ-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    },
  }
}
</script>
