"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ORDER_STATE_LIST = void 0;
exports.ORDER_STATE_LIST = {
    ARTICLE_CHOSEN: 1,
    DELIVERY_VALIDATED: 2,
    PAYMENT_CHOSEN: 3,
    WAITING_FOR_PAYMENT: 4,
    PAID: 5,
    NEED_VALIDATION: 6,
    IN_PREPARATION: 7,
    IN_DELIVERING: 8,
    DELIVERED: 9,
    CANCELED_PENDING: 10,
    CANCELED: 11,
};
