"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Translation = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Lang_1 = require("./Lang");
var Term_1 = require("./Term");
var Translation = /** @class */ (function () {
    function Translation(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.value = deltaService_1.deltaService.string(props.value, '');
        this.isSameAsDefault = deltaService_1.deltaService.boolean(props.isSameAsDefault, false);
        this.langs = deltaService_1.deltaService.array(props.langs, Lang_1.Lang);
        this.terms = deltaService_1.deltaService.array(props.terms, Term_1.Term);
    }
    return Translation;
}());
exports.Translation = Translation;
