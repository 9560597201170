"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LANG = exports.COUNTRY_CODE = exports.LANG_ISO = void 0;
// http://en.wikipedia.org/wiki/List_of_ISO_639-1_codes
var COUNTRY_1 = require("./COUNTRY");
exports.LANG_ISO = {
    FR: 'fr',
    EN: 'en',
};
exports.COUNTRY_CODE = COUNTRY_1.COUNTRY_ISO;
exports.LANG = {
    FR: 1,
    EN: 2,
};
