<style scoped>

.root {
  display: block;
}

.container {
  user-select: none;
  display: flex;
  align-items: center;
}

.previous, .next {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  cursor: pointer;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  transition: box-shadow 300ms;
  background: #ffffff;
}

.previous:hover, .next:hover {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.25);
}

.body {
  display: flex;
  align-items: center;
}

.body-empty {
  border: solid 1px #f2f2f2;
  width: 120px;
  height: 40px;
  font-family: "ComfortaaRegular", sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 12px;
  border-radius: 3px;
  color: #bfbfbf;
}

.page {
  font-size: 13px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  color: #bfbfbf;
  width: 40px;
  height: 40px;
  cursor: pointer;
  font-family: "ComfortaaRegular", sans-serif;
  transition: color 200ms;
}

.page:hover {
  color: #000000;
}

.page-selected {
  background: #000000;
  color: #ffffff;
  border-radius: 3px;
}

.page-selected:hover {
  color: #ffffff;
}

.root ::v-deep .Vector {
  width: 24px;
  height: 24px;
}

.root ::v-deep .Vector svg {
  fill: #bfbfbf;
  transition: fill 200ms;
}

.next:hover ::v-deep .Vector svg {
  fill: #000000;
}

.previous:hover ::v-deep .Vector svg {
  fill: #000000;
}

.divider {
  width: 20px;
  min-width: 20px;
}
</style>

<template>
  <div class="root">
    <div class="container">
      <div class="previous" @click="previous">
        <Vector :svg="icon.chevron_left"></Vector>
      </div>
      <div class="divider"></div>
      <div class="body">
        <div v-for="page_ in pages" v-if="page_.visible" :class="current(page_)" @click="() => page(page_.offset)">
          {{ page_.number }}
        </div>
        <div v-if="!pages.length" class="body-empty">
          <Term :term="lang.term(295)"></Term>
        </div>
      </div>
      <div class="divider"></div>
      <div class="next" @click="next">
        <Vector :svg="icon.chevron_right"></Vector>
      </div>
    </div>
  </div>
</template>

<script>

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

export default {

  props: {
    // nombre de numéro de page affiché
    range: {
      type: Number,
      default: () => 3
    },
    limit: {
      type: Number,
      default: () => 0
    },
    offset: {
      type: Number,
      default: () => 0
    },
    total: {
      type: Number,
      default: () => 0
    },
    onOffsetChange: {
      default: () => () => {
      }
    },
    onPrevious: {
      default: () => () => {
      }
    },
    onPage: {
      default: () => () => {
      }
    },
    onNext: {
      default: () => () => {
      }
    },
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      icon: {
        chevron_left: require("@bbx/vector~master/core/assets/svg/material/chevron_left.svg"),
        chevron_right: require("@bbx/vector~master/core/assets/svg/material/chevron_right.svg")
      }
    }
  },
  computed: {
    pages() {
      let {
        limit,
        total,
        offset,
        range
      } = this;
      let pagesLength = Math.ceil(total / limit);

      let pages = [];
      let indexMatch = 0;

      if (!limit) return pages;
      if (!total) return pages;
      if (!range) return pages;

      for (let i = 0; i < pagesLength; i++) {
        pages.push({
          visible: true,
          offset: i * limit,
          number: i + 1
        });
        if (i * limit === offset) indexMatch = i;
      }

      // on défini la range a afficher
      let left = indexMatch - Math.ceil((range - 1) / 2);
      let right = indexMatch + Math.floor((range - 1) / 2);
      if (left < 0) right = right + left * -1;
      let count = 0;
      pages.map((page, i) => {
        if (i >= left && i <= right) count++;
        page.visible = i >= left && i <= right;
      });
      left = left - (range - count);

      // on regarde quelle numéro de page est dans la range
      pages.map((page, i) => {
        page.visible = i >= left && i <= right;
      });

      return pages;
    },
  },
  methods: {

    canPrevious(){
      let { offset, limit } = this;
      return !(offset - limit < 0);
    },

    previous() {
      if(!this.canPrevious()) return;
      let { offset, limit } = this;
      this.onPrevious(offset - limit);
      this.onOffsetChange(offset - limit);
    },

    page(offset) {
      this.onPage(offset);
      this.onOffsetChange(offset);
    },

    canNext(){
      let { offset, limit, total } = this;
      return !(offset + limit >= total);
    },

    next() {
      if(!this.canNext()) return;
      let { offset, limit } = this;
      this.onNext(offset + limit);
      this.onOffsetChange(offset + limit);
    },

    current(page) {
      let classes = ['page'];
      if (page.offset === this.offset) classes.push('page-selected');
      return classes;
    }
  }
}
</script>
