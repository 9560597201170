<style scoped>
.MaterialForm {
}

.MaterialForm--photo {
  width: 100%;
  box-sizing: border-box;
  height: 200px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.05);
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
}

.MaterialForm--photoContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}
</style>

<template>
  <div class="MaterialForm" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">


      <InputSection :svg="icon.panorama" :term="lang.term(557)">
        <div class="MaterialForm--photo">
          <div class="MaterialForm--photoContent">
            <Picture v-if="file.id" :file="file"/>
          </div>
        </div>
        <br>
        <br>
        <PhotosUpload :photos="state.material.photos" :onPhotosChange="v => state.material.photos = v"/>
        <Photos :photos="state.material.photos"
                :updatable="false"
                :onClickDelete="(i) => state.material.photos.splice(i, 1)"/>
      </InputSection>


      <InputSection :svg="icon.subject" :term="lang.term(558)">
        <InputTerm v-for="term in state.material.terms"
                   :key="term.id"
                   :term="term"
                   :mandatory="true"
                   :onChangeTerm="v => term = v"
                   :label="lang.term(559)">
        </InputTerm>
      </InputSection>


    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {Material} from "../../../@common/delta/database/Material";
import {File} from "../../../@common/delta/database/File";

export default {
  props: {
    material: {
      default: () => new Material()
    },
    onChangeMaterial: {
      type: Function,
      default: () => (material) => {
      }
    },
    form: {
      default: () => new ApiResponseForm()
    },
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        panorama: require('@bbx/vector~master/core/assets/svg/material/panorama.svg'),
        subject: require('@bbx/vector~master/core/assets/svg/material/subject.svg'),
      },
      state: {
        /**
         * @type Material
         */
        material: new Material(this.material),
      },
    }
  },
  watch: {
    'material': {
      handler(v) {
        this.state.material = v
      },
      deep: true
    },
    'state.material': {
      handler(v) {
        this.onChangeMaterial(v)
      },
      deep: true
    },
  },
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`MaterialForm-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`MaterialForm-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`MaterialForm-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`MaterialForm-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`MaterialForm-desktopY`)
      return classes
    },
    file() {
      for (const photo of this.state.material.photos) {
        for (const file of photo.files) {
          return new File(file)
        }
      }
      return new File()
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
