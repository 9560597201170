<style lang="scss">

@import "../../scss/color";

.CatalogueStory {
  font-family: "AeonikRegular", sans-serif;
  letter-spacing: 0.25px;
  font-size: 16px;
  line-height: 28px;
}

.CatalogueStory h1 {
  font-family: 'AmbroiseRegular', sans-serif;
  color: #31302F;
  letter-spacing: 0px;
  padding: 50px 0;
  font-weight: normal;
}

.CatalogueStory-desktop .CatalogueStory h1 {
  font-size: 50px;
  line-height: 50px;
}

.CatalogueStory-tablet .CatalogueStory h1 {
  font-size: 50px;
  line-height: 50px;
}

.CatalogueStory-mobile .CatalogueStory h1 {
  font-size: 30px;
  line-height: 30px;
}

/**/

.CatalogueStory h2 {
  font-weight: normal;
  padding: 25px 0;
  font-size: 15px;
  line-height: auto;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  font-family: 'AmbroiseRegular', sans-serif;
  color: $primary;
  font-family: "CommutersSansBold", sans-serif;
}

/**/

.CatalogueStory--container {

}

/**/

.CatalogueStory--body {
  position: relative;
  overflow: hidden;
}

.CatalogueStory-desktop .CatalogueStory--body {
  height: 250px;
}

.CatalogueStory-tablet .CatalogueStory--body {
  height: 250px;
}

.CatalogueStory-mobile .CatalogueStory--body {
  height: 300px;
}

.CatalogueStory-opened .CatalogueStory--body {
  height: auto;
}

.CatalogueStory--mask {
  height: 100px;
  width: 100%;
  position: absolute;
  bottom: 0;
  left: 0;
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
}

.CatalogueStory-opened .CatalogueStory--mask {
  display: none;
}

/**/

.CatalogueStory--footer {
  padding-top: 50px;
  display: flex;
  justify-content: center;
}


.CatalogueStory--footer .ButtonAction--icon .Vector {
  width: 24px;
  height: 24px;
  position: relative;
  top: 2px;
  left: 6px;
}

</style>

<template>
  <div class="CatalogueStory" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <template v-for="termStory in state.page.termStories">
        <div class="CatalogueStory--container" :key="termStory.id">
          <div class="CatalogueStory--body">
            <Term :term="termStory" type="textarea" :fullOption="true"></Term>
            <div class="CatalogueStory--mask"></div>
          </div>
          <div class="CatalogueStory--footer">
            <ButtonAction :key="0" v-if="state.opened" :svg="icon.arrow_drop_up" :onClick="() => state.opened = false" :term="lang.term(721)"></ButtonAction>
            <ButtonAction :key="1" v-if="!state.opened" :svg="icon.arrow_drop_down" :onClick="() => state.opened = true" :term="lang.term(722)"></ButtonAction>
          </div>
        </div>
      </template>
    </Responsive>
  </div>
</template>

<script>

// -----

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {eventService} from "../../service/eventService";
import {sendError} from "../../function/sendError";
import {apiClient} from "../../service/apiClientService";
import selectPages from "../../../@common/api/page/selectPages";
import {Page} from "../../../@common/delta/database/Page";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

// -----

export default {
  props: {
    pageId: {
      type: Number,
      default: () => 0
    }
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      icon: {
        arrow_drop_down: require('@bbx/vector~master/core/assets/svg/material/arrow_circle_down.svg'),
        arrow_drop_up: require('@bbx/vector~master/core/assets/svg/material/arrow_circle_up.svg'),
      },
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              width: 0
            }),
            new BreakPoint({
              name: SCREEN.TABLET,
              width: 1024
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              width: 1440
            }),
          ],
          verticalBreakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              height: 0
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              height: 600
            }),
          ],
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      state: {
        /**
         * @type Page
         */
        page: new Page(),
        /**
         * @type boolean
         */
        opened: false
      },
      events: []
    }
  },
  watch: {
    'pageId': function () {
      this.init().catch(sendError)
    }
  },
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`CatalogueStory-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`CatalogueStory-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`CatalogueStory-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`CatalogueStory-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`CatalogueStory-desktopY`)
      if (this.state.opened) classes.push(`CatalogueStory-opened`)
      return classes
    },
  },
  beforeMount() {
    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
    this.init().catch(sendError)
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    async init() {
      await this.selectPage()
    },
    async selectPage() {
      if (!this.pageId) return

      const {data} = await apiClient.do(selectPages, selectPages.with({
        $scope: 2,
        $ids: [this.pageId]
      }))
      
      this.state.page = new Page(data[0])
    }
  }
}
</script>