"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.convertToBrowers = void 0;
var Browser_1 = require("../../@common/delta/database/Browser");
var Term_1 = require("../../@common/delta/database/Term");
var Translation_1 = require("../../@common/delta/database/Translation");
var Lang_1 = require("../../@common/delta/database/Lang");
function convertToBrowers(map, lang) {
    var browserIds = [];
    var browsers = [];
    for (var key in map) {
        var keys = key.split('.').map(function (a) { return parseInt(a); });
        var browserId = keys[0];
        var termId = keys[1];
        var translationId = keys[2];
        var translationValue = map[key];
        // let langId = keys[3]
        browserIds.push(browserId);
        browsers.push(new Browser_1.Browser({
            id: browserId,
            terms: [
                new Term_1.Term({
                    id: termId,
                    translations: [
                        new Translation_1.Translation({
                            id: translationId,
                            value: translationValue,
                            langs: [
                                new Lang_1.Lang(lang)
                            ]
                        })
                    ]
                })
            ]
        }));
    }
    return {
        browsers: browsers,
        browserIds: browserIds
    };
}
exports.convertToBrowers = convertToBrowers;
