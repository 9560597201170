<style scoped>
.Popover {
  /*position: relative;*/
}
</style>

<template>
  <div class="Popover">
    <el-popover
        :popper-class="classOverride"
        :placement="position"
        :width="width"
        v-model="state.opened"
        :visible-arrow="false"
        trigger="click">
      <template slot="default">
        <slot name="default"></slot>
      </template>
      <template slot="reference">
        <slot name="reference"></slot>
      </template>
    </el-popover>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {EVENT} from "../../../@common/constant/EVENT";
import {eventService} from "../../service/eventService";

export default {
  props: {
    opened: {
      type: Boolean,
      default: () => false
    },
    onOpenedChange: {
      type: Function,
      default: () => () => {
      }
    },
    position: {
      type: String,
      default: () => 'bottom-center'
    },
    classOverride: {
      type: String,
      default: () => ''
    },
    width: {
      type: Number,
      default: () => 200
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
          },
        },
      },
      state: {
        /**
         * @type boolean
         */
        opened: this.opened,
      },
      events: []
    }
  },
  watch: {
    'opened': {
      handler(v) {
        this.state.opened = v
      },
      deep: true
    },
    'state.opened': {
      handler(v) {
        this.onOpenedChange(v)
      },
      deep: true
    },
  },
  computed: {},
  beforeMount() {

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.MODAL_OPENED,
      callback: () => {
        this.state.opened = false;
      }
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    onBreakPointChange(v) {
      this.props.Responsive['0'].breakPoint = v
    },
    onVerticalBreakPointChange(v) {
      this.props.Responsive['0'].verticalBreakPoint = v
    },
  }
}
</script>
