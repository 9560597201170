<style scoped>
.ArticleForm {
}

.ArticleForm ::v-deep .InputNumber {
  flex: 1;
}

.AdminArticleForm--row {
  display: flex;
}

.AdminArticleForm--batches {
}

.AdminArticleForm--batches ::v-deep .InputSection--remove {
  position: relative;
  top: 11px;
}

.AdminArticleForm--batches .InputSelectTerm {
  flex: 1;
}

.AdminArticleForm--space {
  width: 30px;
  min-width: 30px;
}

.AdminArticleForm--photo {
  width: 100%;
  box-sizing: border-box;
  height: 200px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.05);
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
}

.AdminArticleForm--photoContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}


.AdminArticleForm--material {
  display: flex;
  align-items: center;
}

.AdminArticleForm--materialImage {
  width: 30px;
  height: 30px;
  background: rgba(0, 0, 0, 0.05);
  border-radius: 4px;
  margin-right: 10px;
  overflow: hidden;
}

.AdminArticleForm--materialText {

}
</style>

<template>
  <div class="ArticleForm">


    <InputSection :svg="icon.panorama" :term="lang.term(226)">
      <div class="AdminArticleForm--photo">
        <div class="AdminArticleForm--photoContent">
          <Picture v-if="image.file.id" :file="image.file" :display="pictureDisplay"/>
        </div>
      </div>
      <br>
      <br>
      <div v-show="!state.article.isBatch">
        <template v-for="batch in state.article.batches">
          <template v-for="product in batch.products">
            <PhotosUpload :photos="product.photos" :onPhotosChange="v => product.photos = v"/>
            <Photos :photos="product.photos"
                    :onClickUpdate="(v) => onClickUpdatePhoto(v)"
                    :onClickDelete="(i) => product.photos.splice(i, 1)">
            </Photos>
          </template>
        </template>
      </div>
      <div v-show="state.article.isBatch">
        <PhotosUpload :photos="article.photos" :onPhotosChange="v => article.photos = v"/>
        <Photos :photos="article.photos"
                :onClickUpdate="(v) => onClickUpdatePhoto(v)"
                :onClickDelete="(i) => article.photos.splice(i, 1)"/>
      </div>
    </InputSection>


    <div v-show="state.article.isBatch" class="AdminArticleForm--batches">
      <InputSection :svg="icon.subject"
                    :term="lang.term(204)"
                    :multiple="true"
                    :addable="true"
                    :items="state.article.batches"
                    :onClickAdd="() => addBatch(state.article.batches)"
                    :onItemChange="v => state.article.batches = v">
        <template v-slot:default="{item}">
          <div class="AdminArticleForm--row">
            <InputNumber :value="item.quantity"
                         :onChangeValue="v => item.quantity = v"
                         :label="lang.term(300)">
            </InputNumber>
            <div class="AdminArticleForm--space"></div>
            <InputSelectTerm :remotable="true"
                             :onRemoteChange="remoteProducts"
                             :label="lang.term(301)"
                             :items="state.products.length  ? state.products : item.products"
                             :values="item.products"
                             :onChangeValues="v => onChangeValuesProduct(item, v)"
                             :termAttribute="a => getTerm(a)"
                             :loading="state.loadingProduct">
            </InputSelectTerm>
          </div>
        </template>
      </InputSection>
    </div>


    <InputSection :svg="icon.subject" :term="lang.term(192)">
      <template v-for="termName in state.article.termNames">
        <InputTerm v-show="state.article.isBatch"
                   :label="lang.term(189)"
                   :term="termName"
                   :onChangeTerm="v => termName = v"/>
      </template>
      <template v-for="batch in state.article.batches">
        <template v-for="product in batch.products">
          <template v-for="termName in product.termNames">
            <InputTerm v-show="!state.article.isBatch"
                       :label="lang.term(189)"
                       :term="termName"
                       :onChangeTerm="v => termName = v"/>
          </template>
        </template>
      </template>
      <div v-show="!state.article.isBatch">
        <template v-for="batch in state.article.batches">
          <template v-for="product in batch.products">
            <br>
            <InputText :value="product.reference"
                       :onChangeValue="v => product.reference = v"
                       :label="lang.term(161)"
                       :error="form.display('article.batches.products.reference')">
            </InputText>
            <br>
            <InputText :value="product.gencod"
                       :onChangeValue="v => product.gencod = v"
                       :label="lang.term(162)"
                       :error="form.display('article.batches.products.gencod')">
            </InputText>
          </template>
        </template>
      </div>
      <br>
      <template v-for="termDescription in state.article.termDescriptions">
        <InputTerm v-show="state.article.isBatch"
                   type="textarea"
                   :label="lang.term(190)"
                   :term="termDescription"
                   :onChangeTerm="v => termDescription = v"/>
      </template>
      <template v-for="batch in state.article.batches">
        <template v-for="product in batch.products">
          <template v-for="termDescription in product.termDescriptions">
            <InputTerm v-show="!state.article.isBatch"
                       type="textarea"
                       :label="lang.term(190)"
                       :term="termDescription"
                       :onChangeTerm="v => termDescription = v"/>
          </template>
        </template>
      </template>
      <br>
      <InputSelect :items="state.priorities"
                   :values="state.article.priorityDisplay ? [{id:state.article.priorityDisplay}] : []"
                   :label="lang.term(464)"
                   :onChangeValues="v => state.article.priorityDisplay = v[0] ? v[0].id : 4"
                   :error="form.display('article.priorityDisplay')">
      </InputSelect>
      <br>
      <InputLabel :term="lang.term(195)"/>
      <br>
      <InputSwitch :value="state.article.visible" :onChangeValue="v => state.article.visible = v"/>
    </InputSection>


    <InputSection :svg="icon.subject" :term="lang.term(233)">
      <template v-for="page in article.pages">
        <template v-for="term in page.termDescriptions">
          <InputTerm :label="lang.term(211)"
                     :term="term" :onChangeTerm="v => term = v"/>
        </template>
        <br>
        <InputLabel :term="lang.term(232)"/>
        <br>
        <InputSwitch :value="page.indexed" :onChangeValue="v => page.indexed = v"/>
      </template>
    </InputSection>


    <InputSection :svg="icon.attach_money" :term="lang.term(193)">

      <div v-show="state.loadingArticlePrice">
        <LoaderSpace></LoaderSpace>
      </div>

      <div class="AdminArticleForm--row">
        <InputNumber :value="state.article.price"
                     :onChangeValue="v => setPriceHT(v)"
                     :label="lang.term(163)"
                     :disabled="state.article.isBatch"
                     :error="form.display('article.price')">
        </InputNumber>
        <div class="AdminArticleForm--space"></div>
        <InputNumber :value="state.articlePriceTTC"
                     :onChangeValue="v => setPriceTTC(v)"
                     :disabled="state.article.isBatch"
                     :label="lang.term(178)">
        </InputNumber>
      </div>
      <br>
      <div class="AdminArticleForm--row">
        <InputNumber :value="state.article.ecoParticipation"
                     :onChangeValue="v => setEcoParticipationHT(v)"
                     :disabled="state.article.isBatch"
                     :label="lang.term(172)">
        </InputNumber>
        <div class="AdminArticleForm--space"></div>
        <InputNumber :value="state.articleEcoParticipationTTC"
                     :onChangeValue="v => setEcoParticipationTTC(v)"
                     :disabled="state.article.isBatch"
                     :label="lang.term(177)"
                     :error="form.display('article.ecoParticipation')">
        </InputNumber>
      </div>
      <br>
      <InputSelect :items="state.taxes"
                   :values="state.article.taxes.length ? state.article.taxes : state.taxes[0] ? [state.taxes[0]] : []"
                   :label="lang.term(174)"
                   :disabled="state.article.isBatch"
                   :display="tax => `${tax.name} ${tax.value} ${tax.units.map(unit => unit.symbol)}`"
                   :onChangeValues="v => onChangeValueTaxes(v)"
                   :error="form.display('article.taxes')">
      </InputSelect>
      <br>
      <InputLabel :term="lang.term(296)" helper="%"/>
      <br>
      <InputSwitch :value="!!state.article.promotions.length" :onChangeValue="v => onPromotionChange(v)"/>
      <template v-for="promotion in state.article.promotions">
        <br>
        <div class="AdminArticleForm--row">
          <InputNumber :value="promotion.percentage"
                       :decimal="13"
                       :onChangeValue="v => setPromotionPercentage(v)"
                       :label="lang.term(298)">
          </InputNumber>
          <div class="AdminArticleForm--space"></div>
          <InputNumber :value="state.articlePromotionTTC"
                       :onChangeValue="v => setPromotionTTC(v)"
                       :label="lang.term(297)">
          </InputNumber>
        </div>
      </template>
    </InputSection>


    <div v-show="!state.article.isBatch">
      <InputSection :svg="icon.style"
                    :term="lang.term(465)">
        <template v-for="batch in state.article.batches">
          <template v-for="product in batch.products">
            <InputSelect :label="lang.term(466)"
                         :items="state.collections"
                         :max="1"
                         :values="product.collections"
                         :loading="state.loadingCollection"
                         :onChangeValues="v => product.collections = v">
            </InputSelect>
          </template>
        </template>
      </InputSection>
    </div>


    <div v-show="!state.article.isBatch">
      <InputSection :svg="icon.local_offer"
                    :term="lang.term(229)">
        <template v-for="batch in state.article.batches">
          <template v-for="product in batch.products">
            <InputSelectTerm :label="lang.term(230)"
                             :items="categories()"
                             :cloned="true"
                             :values="categoryValues(product)"
                             :onChangeValues="v => onChangeCategory(product, v, 1)">
            </InputSelectTerm>
            <br>
            <InputSelectTerm :label="lang.term(231)"
                             :items="categoryChildren(product)"
                             :cloned="true"
                             :values="categoryChildrenValues(product)"
                             :onChangeValues="v => onChangeCategory(product, v, 2)">
            </InputSelectTerm>
          </template>
        </template>
      </InputSection>
    </div>


    <div v-show="!state.article.isBatch">
      <InputSection :svg="icon.texture"
                    :term="lang.term(347)">
        <template v-for="batch in state.article.batches">
          <template v-for="product in batch.products">
            <InputSelectTerm :label="lang.term(348)"
                             :items="state.filteredMaterials"
                             :max="state.materials.length"
                             :filterable="true"
                             :onFilterChange="onSearchMaterialChange"
                             :values="product.materials"
                             :loading="state.loadingMaterial"
                             :onChangeValues="v => product.materials = v">
              <template v-slot:default="{item}">
                <div class="AdminArticleForm--material">
                  <div class="AdminArticleForm--materialImage">
                    <template v-for="photo in item.item.photos">
                      <template v-for="file in photo.files">
                        <Picture :file="file"></Picture>
                      </template>
                    </template>
                  </div>
                  <div class="AdminArticleForm--materialText">{{ item.translated }}</div>
                </div>
              </template>
            </InputSelectTerm>
          </template>
        </template>
      </InputSection>
    </div>


    <div v-show="!state.article.isBatch" class="AdminArticleForm--batches">
      <template v-for="batch in state.article.batches">
        <template v-for="product in batch.products">
          <InputSection :svg="icon.texture"
                        :term="lang.term(467)"
                        :multiple="true"
                        :addable="true"
                        :items="product.similarUps"
                        :onClickAdd="() => addSimilar(product.similarUps)"
                        :onItemChange="v => product.similarUps = v">
            <template v-slot:default="{item}">
              <div class="AdminArticleForm--row">
                <InputSelectTerm :label="lang.term(468)"
                                 :items="state.filteredMaterials"
                                 :filterable="true"
                                 :onFilterChange="onSearchMaterialChange"
                                 :values="item.materials"
                                 :loading="state.loadingMaterial"
                                 :onChangeValues="v => item.materials = v">
                  <template v-slot:default="{item}">
                    <div class="AdminArticleForm--material">
                      <div class="AdminArticleForm--materialImage">
                        <template v-for="photo in item.item.photos">
                          <template v-for="file in photo.files">
                            <Picture :file="file"></Picture>
                          </template>
                        </template>
                      </div>
                      <div class="AdminArticleForm--materialText">{{ item.translated }}</div>
                    </div>
                  </template>
                </InputSelectTerm>
                <div class="AdminArticleForm--space"></div>
                <InputSelectTerm :remotable="true"
                                 :onRemoteChange="remoteProducts"
                                 :label="lang.term(469)"
                                 :items="state.products.length  ? state.products : item.productDowns"
                                 :values="item.productDowns"
                                 :onChangeValues="v => onChangeValuesSimilarProductDowns(item, v)"
                                 :termAttribute="a => getTerm(a)"
                                 :loading="state.loadingProduct">
                </InputSelectTerm>
              </div>
            </template>
          </InputSection>
        </template>
      </template>
    </div>


    <InputSection :svg="icon.local_shipping"
                  :term="lang.term(349)">
      <InputSelect :label="lang.term(350)"
                   :items="state.transports"
                   :values="state.article.transports"
                   :loading="state.loadingTransport"
                   :display="displayTransport"
                   :onChangeValues="v => state.article.transports = v">
      </InputSelect>
    </InputSection>


    <div v-show="!state.article.isBatch">
      <template v-for="batch in state.article.batches">
        <template v-for="product in batch.products">
          <InputSection :svg="icon.subject"
                        :term="lang.term(199)"
                        :multiple="true"
                        :addable="true"
                        :items="product.highlights"
                        :onClickAdd="() => addHighlight(product.highlights)"
                        :onItemChange="v => product.highlights = v">
            <template v-slot:default="{item}">
              <template v-for="termValue in item.termValues">
                <InputTerm :term="termValue"
                           :onChangeTerm="v => termValue = v"/>
              </template>
            </template>
          </InputSection>
        </template>
      </template>
    </div>


    <div v-show="!state.article.isBatch">
      <template v-for="batch in state.article.batches">
        <template v-for="product in batch.products">
          <InputSection :svg="icon.subject"
                        :term="lang.term(328)"
                        :multiple="true"
                        :addable="true"
                        :items="product.details"
                        :onClickAdd="() => addDetail(product.details)"
                        :onItemChange="v => product.details = v">
            <template v-slot:default="{item}">
              <template v-for="termValue in item.termValues">
                <InputTerm :term="termValue"
                           :onChangeTerm="v => termValue = v"/>
              </template>
            </template>
          </InputSection>
        </template>
      </template>
    </div>


    <div v-show="!state.article.isBatch">
      <InputSection :svg="icon.subject" :term="lang.term(191)">
        <template v-for="batch in state.article.batches">
          <template v-for="product in batch.products">
            <template v-for="termMaintain in product.termMaintains">
              <InputTerm type="textarea"
                         :term="termMaintain"
                         :onChangeTerm="v => termMaintain = v"/>
            </template>
          </template>
        </template>
      </InputSection>
    </div>


    <div>
      <InputSection :svg="icon.trending_up" :term="lang.term(470)">
        <InputSelect :label="lang.term(471)"
                     :items="state.evolves"
                     :clearable="true"
                     :remotable="true"
                     :onRemoteChange="remoteEvolves"
                     :values="state.article.evolveAncestors"
                     :loading="state.loadingEvolve"
                     :display="displayEvolve"
                     :onChangeValues="v => state.article.evolveAncestors = v">
        </InputSelect>
      </InputSection>
    </div>


    <div>
      <InputSection :svg="icon.close_fullscreen" :term="lang.term(472)">
        <InputSelect :items="state.roles"
                     :values="state.article.roleRestrictions"
                     :max="1"
                     :loading="state.loadingRole"
                     :label="lang.term(473)"
                     :onChangeValues="v => state.article.roleRestrictions = v"
                     :error="form.display(`article.roleRestrictions`)">
        </InputSelect>
        <br>
        <InputSelect :remotable="true"
                     :onRemoteChange="remoteUsers"
                     :items="state.users.length ? state.users : state.article.userRestrictions"
                     :values="state.article.userRestrictions"
                     :label="lang.term(474)"
                     :error="form.display(`article.userRestrictions`)"
                     :display="displayUser"
                     :max="9999"
                     :collapseTags="true"
                     :onChangeValues="users => onChangeUserValues(users)"
                     :loading="state.loadingUser">
        </InputSelect>
        <br>
        <InputLabel :term="lang.term(475)"/>
        <br>
        <InputNumber :value="state.article.restrictionStockNumber"
                     :onChangeValue="v => state.article.restrictionStockNumber = v"
                     :disabled="!state.article.restrictionStockActif">
        </InputNumber>
        <br>
        <InputSwitch :value="state.article.restrictionStockActif"
                     :onChangeValue="v => state.article.restrictionStockActif = v"
                     :label="lang.term(476)"/>
      </InputSection>
    </div>


    <div v-show="!state.article.isBatch">
      <template v-for="batch in state.article.batches">
        <template v-for="product in batch.products">
          <InputSection :svg="icon.square_foot"
                        :term="lang.term(477)"
                        :multiple="true"
                        :addable="true"
                        :items="product.sizes"
                        :onClickAdd="() => addSize(product.sizes)"
                        :onItemChange="v => product.sizes = v">
            <template v-slot:default="{item}">
              <div class="AdminArticleForm--row">
                <InputNumber :value="item.length"
                             :onChangeValue="v => item.length = v"
                             helper="cm"
                             :label="lang.term(478)">
                </InputNumber>
                <div class="AdminArticleForm--space"></div>
                <InputNumber :value="item.width"
                             helper="cm"
                             :onChangeValue="v => item.width = v"
                             :label="lang.term(479)">
                </InputNumber>
              </div>
              <br>
              <div class="AdminArticleForm--row">
                <InputNumber :value="item.height"
                             helper="cm"
                             :onChangeValue="v => item.height = v"
                             :label="lang.term(480)">
                </InputNumber>
                <div class="AdminArticleForm--space"></div>
                <InputNumber :value="item.weight"
                             helper="kg"
                             :onChangeValue="v => item.weight = v"
                             :label="lang.term(481)">
                </InputNumber>
              </div>
            </template>
          </InputSection>
        </template>
      </template>
    </div>


    <div v-show="!state.article.isBatch">
      <template v-for="batch in state.article.batches">
        <template v-for="product in batch.products">
          <InputSection :svg="icon.archive"
                        :term="lang.term(482)"
                        :multiple="true"
                        :addable="true"
                        :items="product.packages"
                        :onClickAdd="() => addPackage(product.packages)"
                        :onItemChange="v => product.packages = v">
            <template v-slot:default="{item}">
              <template v-for="size in item.sizes">
                <div class="AdminArticleForm--row">
                  <InputNumber :value="size.length"
                               helper="cm"
                               :onChangeValue="v => size.length = v"
                               :label="lang.term(483)">
                  </InputNumber>
                  <div class="AdminArticleForm--space"></div>
                  <InputNumber :value="size.width"
                               helper="cm"
                               :onChangeValue="v => size.width = v"
                               :label="lang.term(484)">
                  </InputNumber>
                </div>
                <br>
                <div class="AdminArticleForm--row">
                  <InputNumber :value="size.height"
                               helper="cm"
                               :onChangeValue="v => size.height = v"
                               :label="lang.term(485)">
                  </InputNumber>
                  <div class="AdminArticleForm--space"></div>
                  <InputNumber :value="size.weight"
                               helper="kg"
                               :onChangeValue="v => size.weight = v"
                               :label="lang.term(486)">
                  </InputNumber>
                </div>
              </template>
            </template>
          </InputSection>
        </template>
      </template>
    </div>


    <div v-show="!state.article.isBatch">
      <InputSection :svg="icon.extension"
                    :term="lang.term(487)">
        <template v-for="batch in state.article.batches">
          <template v-for="product in batch.products">
            <template v-for="component in product.components">
              <InputSelectTerm :remotable="true"
                               :collapseTags="true"
                               :max="9999"
                               :onRemoteChange="remoteProducts"
                               :label="lang.term(488)"
                               :items="state.products.length ? state.products : component.productComponents"
                               :values="component.productComponents"
                               :onChangeValues="v => onChangeProductCompoents(v)"
                               :termAttribute="a => getTerm(a)"
                               :loading="state.loadingProduct">
              </InputSelectTerm>
            </template>
          </template>
        </template>
      </InputSection>
    </div>


    <div v-show="!state.article.isBatch">
      <InputSection :svg="icon.beenhere"
                    :term="lang.term(609)">
        <template v-for="batch in state.article.batches">
          <template v-for="product in batch.products">
            <PhotosUpload :photos="product.photoLabels" :onPhotosChange="v => product.photoLabels = v"/>
            <Photos :photos="product.photoLabels"
                    :onClickDelete="(i) => product.photoLabels.splice(i, 1)">
            </Photos>
          </template>
        </template>
      </InputSection>
    </div>


    <div v-show="!state.article.isBatch">
      <InputSection :svg="icon.qr_code_scanner"
                    :term="lang.term(610)">
        <template v-for="batch in state.article.batches">
          <template v-for="product in batch.products">
            <PhotosUpload :photos="product.photoBarCodes" :onPhotosChange="v => product.photoBarCodes = v"/>
            <Photos :photos="product.photoBarCodes"
                    :onClickDelete="(i) => product.photoBarCodes.splice(i, 1)">
            </Photos>
          </template>
        </template>
      </InputSection>
    </div>

    <div>
      <InputSection :svg="icon.double_arrow"
                    :term="lang.term(611)"
                    :multiple="true"
                    :addable="true"
                    :items="state.article.articleChildren"
                    :onClickAdd="() => addArticleRecommandation()">
        <template slot-scope="{item, index}">
          <InputSelectTerm :remotable="true"
                           :onRemoteChange="remoteArticles"
                           :label="lang.term(612)"
                           :items="state.articles.length ? state.articles : [item]"
                           :values="state.articles.length ? [] : [item]"
                           :onChangeValues="v => onChangeArticleRecommandations(index, v)"
                           :termAttribute="a => getArticleTermName(a)"
                           :loading="state.loadingArticle">
          </InputSelectTerm>
        </template>
      </InputSection>
    </div>
  </div>
</template>

<script>

// -----

import {Debounce} from "../../../@common/ts/Debounce";

// -----

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {apiClient} from "../../service/apiClientService";

// -----

import {calculateHT} from "../../../@common/function/calculateHT";
import {calculateTTC} from "../../../@common/function/calculateTTC";
import {findCoverPhoto} from "../../function/findCoverPhoto";
import {toCapitalyze} from "../../../@common/function/toCapitalyze";

// -----

import {PICTURE_DISPLAY} from "../../../@common/constant/PICTURE_DISPLAY";
import {USER_SEARCH_ID} from "../../../@common/constant/USER";

// -----

import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {Article} from "../../../@common/delta/database/Article";
import {Category} from "../../../@common/delta/database/Category";
import {Promotion} from "../../../@common/delta/database/Promotion";
import {Batch} from "../../../@common/delta/database/Batch";
import {Product} from "../../../@common/delta/database/Product";
import {Translation} from "../../../@common/delta/database/Translation";
import {Lang} from "../../../@common/delta/database/Lang";
import {Material} from "../../../@common/delta/database/Material";
import {Detail} from "../../../@common/delta/database/Detail";
import {Highlight} from "../../../@common/delta/database/Highlight";
import {Term} from "../../../@common/delta/database/Term";
import {Evolve} from "../../../@common/delta/database/Evolve";
import {User} from "../../../@common/delta/database/User";
import {Size} from "../../../@common/delta/database/Size";
import {Package} from "../../../@common/delta/database/Package";

// -----

import selectCategories from "../../../@common/api/category/selectCategories";
import selectTaxes from "../../../@common/api/tax/selectTaxes";
import selectMaterials from "../../../@common/api/material/selectMaterials";
import selectTransports from "../../../@common/api/transport/selectTransports";
import selectArticlePrice from "../../../@common/api/article/selectArticlePrice";
import selectProducts from "../../../@common/api/product/selectProducts";
import selectArticles from "../../../@common/api/article/selectArticles";
import selectEvolve from "../../../@common/api/evolve/selectEvolve";
import selectRole from "../../../@common/api/role/selectRole";
import selectUsers from "../../../@common/api/user/selectUsers";
import selectCollections from "../../../@common/api/collection/selectCollections";
import {Similar} from "../../../@common/delta/database/Similar";
import {convertPathToUrl} from "../../../@common/function/helperUrl";
import {Page} from "../../../@common/delta/database/Page";
import {urlService} from "../../../@common/service/urlService";
import {Url} from "../../../@common/delta/database/Url";
import {sendError} from "../../function/sendError";
import {getArticleTermNames} from "../../../@nodejs/function/getArticleTermNames";

// -----

const debounce = new Debounce()

// -----

export default {

  props: {
    article: {
      default: () => new Article()
    },
    onArticleChange: {
      type: Function,
      default: () => (article) => {
      }
    },
    onClickUpdatePhoto: {
      type: Function,
      default: () => (photo) => {
      }
    },
    form: {
      default: () => new ApiResponseForm()
    },
  },
  data() {
    const lang = getLangServiceBrowser()
    return {
      PICTURE_DISPLAY,
      lang,
      icon: {
        style: require('@bbx/vector~master/core/assets/svg/material/style.svg'),
        subject: require('@bbx/vector~master/core/assets/svg/material/subject.svg'),
        attach_money: require('@bbx/vector~master/core/assets/svg/material/attach_money.svg'),
        delete_forever: require('@bbx/vector~master/core/assets/svg/material/delete_forever.svg'),
        panorama: require('@bbx/vector~master/core/assets/svg/material/panorama.svg'),
        local_offer: require('@bbx/vector~master/core/assets/svg/material/local_offer.svg'),
        gps_fixed: require('@bbx/vector~master/core/assets/svg/material/gps_fixed.svg'),
        texture: require('@bbx/vector~master/core/assets/svg/material/texture.svg'),
        local_shipping: require('@bbx/vector~master/core/assets/svg/material/local_shipping.svg'),
        trending_up: require('@bbx/vector~master/core/assets/svg/material/trending_up.svg'),
        close_fullscreen: require('@bbx/vector~master/core/assets/svg/material/close_fullscreen.svg'),
        archive: require('@bbx/vector~master/core/assets/svg/material/archive.svg'),
        square_foot: require('@bbx/vector~master/core/assets/svg/material/square_foot.svg'),
        extension: require('@bbx/vector~master/core/assets/svg/material/extension.svg'),
        card_membership: require('@bbx/vector~master/core/assets/svg/material/card_membership.svg'),
        beenhere: require('@bbx/vector~master/core/assets/svg/material/beenhere.svg'),
        qr_code_scanner: require('@bbx/vector~master/core/assets/svg/material/qr_code_scanner.svg'),
        double_arrow: require('@bbx/vector~master/core/assets/svg/material/double_arrow.svg'),
      },
      props: {},
      state: {
        /**
         * @type Article
         **/
        article: this.article,
        /**
         * @type Material[]
         **/
        materials: [],
        /**
         * @type User[]
         **/
        users: [],
        /**
         * @type Role[]
         */
        roles: [],
        /**
         * @type Material[]
         **/
        filteredMaterials: [],
        /**
         * @type Transport[]
         **/
        transports: [],
        /**
         * @type Evolve[]
         **/
        evolves: [],
        /**
         * @type Tax[]
         **/
        taxes: [],
        /**
         * @type Tax[]
         **/
        priorities: [
          {
            id: 1,
            value: '1 ~ [NEW] - Afficher en nouveauté'
          },
          {
            id: 2,
            value: '2 ~ Afficher en deuxième priorité'
          },
          {
            id: 3,
            value: '3 ~ Afficher en troisième priorité'
          },
          {
            id: 4,
            value: '4 ~ Afficher en quatrième priorité'
          },
        ],
        /**
         * @type Product[]
         **/
        products: [],
        /**
         * @type Article[]
         **/
        articles: [],
        /**
         * @type boolean
         **/
        loadingArticlePrice: false,
        /**
         * @type boolean
         **/
        loadingUser: false,
        /**
         * @type boolean
         **/
        loadingCollection: false,
        /**
         * @type boolean
         **/
        loadingProduct: false,
        /**
         * @type boolean
         **/
        loadingArticle: false,
        /**
         * @type boolean
         */
        loadingRole: false,
        /**
         * @type boolean
         **/
        loadingMaterial: false,
        /**
         * @type boolean
         **/
        loadingTransport: false,
        /**
         * @type boolean
         **/
        loadingEvolve: false,
        /**
         * @type Category[]
         **/
        categories: [],
        /**
         * @type Collection[]
         **/
        collections: [],
        /**
         * @type number
         **/
        articlePriceTTC: 0,
        /**
         * @type number
         **/
        articleEcoParticipationTTC: 0,
        /**
         * @type boolean
         **/
        articlePricePending: false,
        /**
         * @type boolean
         **/
        articleEcoParticipationPending: false,
        /**
         * @type boolean
         **/
        promotion: false,
        /**
         * @type number
         **/
        articlePromotionTTC: 0,
        /**
         * @type boolean
         **/
        promotionPending: false,
      },
    }
  },
  watch: {
    'article': {
      handler: function (v) {
        this.state.article = v
      },
      deep: true
    },
    'state.article': {
      handler: function (v) {
        this.onArticleChange(v)
      },
      deep: true
    },
    'article.id': {
      handler: function (v) {
        this.selectArticlePrice().catch(sendError)
        this.selectEvolve().catch(sendError)
      },
      deep: true
    },
    'state.categorySelecteds': {
      handler: function () {
      },
      deep: true
    },
    'state.categoryChildrenSelecteds': {
      handler: function () {

      },
      deep: true
    },
    'state.articlePriceTTC': {
      handler: function (v) {
        for (const promotion of this.state.article.promotions) {
          this.state.articlePromotionTTC = v - (v * promotion.percentage / 100)
        }
      },
      deep: true
    },
  },
  computed: {
    image() {
      return findCoverPhoto(this.state.article)
    },
    pictureDisplay() {
      return this.image.photo.full ? PICTURE_DISPLAY.COVER : PICTURE_DISPLAY.AUTO
    }
  },
  beforeMount() {
  },
  mounted() {
    this.select().catch(sendError)

    this.setPriceHT(this.state.article.price)
    this.setEcoParticipationHT(this.state.article.ecoParticipation)
  },
  beforeDestroy() {
  },
  methods: {
    async select() {
      await Promise.all([
        this.selectTaxes(),
        this.selectCollections(),
        this.selectCategories(),
        this.selectMaterials(),
        this.selectTransports(),
        this.selectArticlePrice(),
        this.selectRole(),
      ])
    },
    async selectTaxes() {
      const {data} = await apiClient.do(selectTaxes, selectTaxes.with())
      this.state.taxes = data
    },
    async selectEvolve() {
      this.state.loadingEvolve = true
      const ids = this.article.evolveAncestors.map(a => a.id)
      if (ids.length) {
        const {data} = await apiClient.do(selectEvolve, selectEvolve.with({
          $ids: ids
        }))
        this.article.evolveAncestors = data
        this.state.evolves = data
      }
      this.state.loadingEvolve = false
    },
    async selectArticlePrice() {
      if (this.article.isBatch) {
        this.state.loadingArticlePrice = true
        const {data} = await apiClient.do(selectArticlePrice, selectArticlePrice.with({
          id: this.article.id
        }))
        this.setPriceHT(data.priceHT)
        this.setEcoParticipationHT(data.ecoParticipationHT)
        this.state.loadingArticlePrice = false
      }
    },
    async selectTransports() {
      this.state.loadingTransport = true
      const {data} = await apiClient.do(selectTransports, selectTransports.with())
      this.state.transports = data
      this.state.loadingTransport = false
    },
    async selectMaterials() {
      this.state.loadingMaterial = true
      const {data} = await apiClient.do(selectMaterials, selectMaterials.with({
        active: true
      }))
      this.state.materials = data
      this.state.filteredMaterials = data
      this.state.loadingMaterial = false
    },
    async selectCollections() {
      this.state.loadingCollection = true
      const {data} = await apiClient.do(selectCollections, selectCollections.with({active: true}))
      this.state.collections = data
      this.state.loadingCollection = false
    },
    async selectCategories() {
      const {data} = await apiClient.do(selectCategories, selectCategories.with({
        active: true
      }))
      this.state.categories = data
    },
    getTaxValue() {
      let taxValue = 0;
      for (const tax of this.article.taxes) {
        taxValue = tax.value;
      }
      return taxValue
    },
    setPriceHT(v) {
      this.state.article.price = v
      if (this.state.articlePricePending) return;
      this.state.articlePricePending = true;
      const priceTTC = calculateTTC(v, this.getTaxValue())
      this.state.articlePriceTTC = priceTTC
      setTimeout(() => {
        this.state.articlePricePending = false;
      })
    },
    setPriceTTC(v) {
      this.state.articlePriceTTC = v
      if (this.state.articlePricePending) return;
      this.state.articlePricePending = true;
      const priceHT = calculateHT(v, this.getTaxValue())
      this.state.article.price = priceHT
      setTimeout(() => {
        this.state.articlePricePending = false;
      })
    },
    setEcoParticipationHT(v) {
      this.state.article.ecoParticipation = v
      if (this.state.articleEcoParticipationPending) return;
      this.state.articleEcoParticipationPending = true;
      const priceTTC = calculateTTC(v, this.getTaxValue())
      this.state.articleEcoParticipationTTC = priceTTC
      setTimeout(() => {
        this.state.articleEcoParticipationPending = false;
      })
    },
    setEcoParticipationTTC(v) {
      this.state.articleEcoParticipationTTC = v
      if (this.state.articleEcoParticipationPending) return;
      this.state.articleEcoParticipationPending = true;
      const priceHT = calculateHT(v, this.getTaxValue())
      this.state.article.ecoParticipation = priceHT
      setTimeout(() => {
        this.state.articleEcoParticipationPending = false;
      })
    },
    setPromotionTTC(v) {
      this.state.articlePromotionTTC = v
      if (this.state.promotionPending) return;
      this.state.promotionPending = true;
      for (const promotion of this.state.article.promotions) {
        const percentage = 100 - (this.state.articlePromotionTTC * 100 / this.state.articlePriceTTC)
        promotion.percentage = percentage
      }
      setTimeout(() => {
        this.state.promotionPending = false;
      })
    },
    setPromotionPercentage(v) {
      if (v > 100) v = 100
      for (const promotion of this.state.article.promotions) {
        promotion.percentage = v
      }
      if (this.state.promotionPending) return;
      this.state.promotionPending = true;
      this.state.articlePromotionTTC = this.state.articlePriceTTC - (this.state.articlePriceTTC * v / 100)
      setTimeout(() => {
        this.state.promotionPending = false;
      })
    },
    /**
     * @param {Detail[]} details
     */
    addDetail(details) {
      const detail = new Detail()
      if (!detail.termValues.length) detail.termValues.push(new Term)
      details.push(detail)
    },
    /**
     * @param {Highlight[]} highlights
     */
    addHighlight(highlights) {
      const highlight = new Highlight()
      if (!highlight.termValues.length) highlight.termValues.push(new Term)
      highlights.push(highlight)
    },
    /**
     * @param {Similar[]} simillars
     */
    addSimilar(simillars) {
      const similar = new Similar()
      simillars.push(similar)
    },
    /**
     * @param {Batch[]} batches
     */
    addBatch(batches) {
      const batch = new Batch()
      batches.push(batch)
    },
    /**
     * @return void
     */
    addArticleRecommandation() {
      const article = new Article()
      this.state.article.articleChildren.push(article)
    },
    /**
     * @param {Size[]} batches
     */
    addSize(sizes) {
      const size = new Size()
      sizes.push(size)
    },
    /**
     * @param {Package[]} packages
     */
    addPackage(packages) {
      const package_ = new Package({
        sizes: [new Size()]
      })
      packages.push(package_)
    },

    categories() {
      return this.state.categories.filter(c => !c.categoryParents.length)
    },

    categoryChildren(product) {
      for (const categoryOriginal of this.state.categories) {
        for (const category of product.categories) {
          if (category.id === categoryOriginal.id) {
            return categoryOriginal.categoryChildren
          }
        }
      }
    },

    onChangeCategory(product, categories, dimension) {
      if (!categories.length) return
      if (dimension === 1) {
        const currentIds = product.categories.map(c => c.id)
        const changeId = categories.map(c => c.id).pop()
        // console.log('BEFORE', currentIds, changeId, dimension);
        if (!currentIds.includes(changeId)) {
          product.categories = [
            this.state.categories.find(c => c.id === changeId)
          ]
        }
        // console.log('AFTER', product.categories.map(c => c.id), changeId, dimension, !currentIds.includes(changeId));
      } else if (dimension === 2) {
        const currentIds = product.categories.map(c => c.id)
        currentIds.sort()
        const changeId = categories.map(c => c.id).pop()
        // console.log('BEFORE', currentIds, changeId, dimension);
        if (!currentIds.includes(changeId)) {
          product.categories = [
            this.state.categories.find(c => c.id === currentIds[0]),
            this.state.categories.find(c => c.id === changeId)
          ]
        }
        // console.log('AFTER', product.categories.map(c => c.id), changeId, dimension, !currentIds.includes(changeId));
      }
    },
    /**
     * @param {Product} product
     * @returns {Category[]}
     */
    categoryValues(product) {
      const categorySelecteds = []
      for (const categoryOriginal of this.state.categories) {
        for (const category of product.categories) {
          if (categoryOriginal.id === category.id && !categoryOriginal.categoryParents.length) {
            categorySelecteds.push(categoryOriginal)
          }
        }
      }
      return categorySelecteds
    },

    /**
     * @param {Product} product
     * @returns {Category[]}
     */
    categoryChildrenValues(product) {
      const categorySelecteds = []
      for (const categoryOriginal of this.state.categories) {
        for (const category of product.categories) {
          if (categoryOriginal.id === category.id && categoryOriginal.categoryParents.length) {
            categorySelecteds.push(categoryOriginal)
          }
        }
      }
      return categorySelecteds
    },
    /**
     */
    onPromotionChange(v) {
      setTimeout(() => {
        if (v && this.state.article.promotions.length === 0) {
          this.state.article.promotions = [new Promotion()]
          this.state.articlePromotionTTC = 0
        } else if (!v) {
          this.state.articlePromotionTTC = 0
          this.state.article.promotions = []
        }
      })
    },

    remoteProducts(query) {
      this.state.loadingProduct = true

      if (!query) {
        this.state.loadingProduct = false
        this.state.products = []
        return
      }

      debounce.callback({
        job: async () => {
          return apiClient.do(selectProducts, selectProducts.with({
            $articleActive: true,
            $articleIsBatch: false,
            search: query,
          }))
        },
        success: res => {
          this.state.products = res.data
          this.state.loadingProduct = false
        },
        error: err => {
          this.state.loadingProduct = false
          sendError(err);
        }
      })
    },

    remoteArticles(query) {
      this.state.loadingArticle = true

      if (!query) {
        this.state.loadingArticle = false
        this.state.articles = []
        return
      }

      debounce.callback({
        job: async () => {
          return apiClient.do(selectArticles, selectArticles.with({
            $scope: 3,
            active: true,
            visible: true,
            $search: query,
          }))
        },
        success: res => {
          this.state.articles = res.data
          this.state.loadingArticle = false
        },
        error: err => {
          this.state.loadingArticle = false
          sendError(err);
        }
      })
    },

    remoteEvolves(query) {
      this.state.loadingEvolve = true

      if (!query) {
        this.state.loadingEvolve = false
        this.state.evolves = []
        return
      }

      debounce.callback({
        job: async () => {
          return apiClient.do(selectArticles, selectArticles.with({
            active: true,
            visible: true,
            $ids: [parseInt(query)],
          }))
        },
        success: res => {
          /** @type Article[] */
          const articles = res.data
          this.state.evolves = articles.map(article => {
            /** @type Evolve */
            const evolve = {
              articleDescendants: [
                {
                  id: article.id,
                  termNames: article.termNames
                }
              ]
            }
            return evolve
          })
          this.state.loadingEvolve = false
        },
        error: err => {
          this.state.loadingEvolve = false
          sendError(err);
        }
      })
    },
    /**
     * @param {Product} product
     * @returns {[]}
     */
    getTerm(product) {
      let terms = []
      terms.push(new Term({
        translations: [
          new Translation({
            langs: [
              new Lang({
                iso6391: this.lang.lang.iso6391
              })
            ],
            value: `${product.reference} - `
          }),
        ]
      }))
      for (const termName of product.termNames) {
        terms.push(termName)
      }
      return terms
    },
    /**
     * @param {Article} article
     * @returns {Term[]}
     */
    getArticleTermName(article) {
      return getArticleTermNames(article)
    },

    onChangeValuesProduct(item, v) {
      item.products = v
      this.state.products = []
    },

    onChangeValuesSimilarProductDowns(item, v) {
      item.productDowns = v
      this.state.products = []
    },

    /**
     * @param {Product[]} productComponents
     * @return {string}
     */
    onChangeProductCompoents(productComponents) {
      for (const batch of this.state.article.batches) {
        for (const product of batch.products) {
          for (const component of product.components) {
            if (JSON.stringify(component.productComponents) !== JSON.stringify(productComponents)) {
              component.productComponents = productComponents
              this.state.products = []
            }
          }
        }
      }
    },

    /**
     * @return {void}
     */
    onChangeArticleRecommandations(index, value) {
      if (value.length && JSON.stringify(value[0].id) !== JSON.stringify(this.state.article.articleChildren[index].id)) {
        this.state.article.articleChildren.splice(index, 1)
        this.state.article.articleChildren.splice(index, 0, value[0])
        this.state.articles = []
      }
    },

    /**
     * @param {Transport} transport
     * @return {string}
     */
    displayTransport(transport) {
      return calculateTTC(transport.transportCosts[0] ? transport.transportCosts[0].amount : 0) + " € - " + transport.description
    },

    /**
     * @param {Evolve} evolve
     * @return {string}
     */
    displayEvolve(evolve) {
      let display = ``
      for (const articleDescendant of evolve.articleDescendants) {
        display = `# ${articleDescendant.id} ~ ${this.lang.translateTerm(new Term(articleDescendant.termNames[0]))}`
      }
      return display
    },

    onSearchMaterialChange(query) {
      let materials = []
      if (!query) {
        materials = this.state.materials.map(m => new Material(m))
      } else {
        for (const material of this.state.materials) {
          for (const term of material.terms) {
            for (const translation of term.translations) {
              for (const lang of translation.langs) {
                if (lang.iso6391 === this.lang.lang.iso6391) {
                  const regex = new RegExp(query, 'g')
                  if (regex.test(translation.value)) {
                    materials.push(new Material(material))
                  }
                }
              }
            }
          }
        }
      }
      this.state.filteredMaterials = materials
    },

    async selectRole() {
      this.state.loadingRole = true
      const filter = {
        active: true,
      }
      apiClient.do(selectRole, selectRole.with(filter)).then((res) => {
        this.state.roles = res.data;
        this.state.loadingRole = false
      }).catch(err => {
        this.state.loadingRole = false
        sendError(err)
      });
    },

    remoteUsers(query) {
      if (!query) return this.state.users = []
      if (this.state.loadingUser) return
      this.state.loadingUser = true
      apiClient.do(selectUsers, selectUsers.with({
        active: true,
        $search: query,
        $searchType: USER_SEARCH_ID.EMAIL
      })).then(res => {
        this.state.users = res.data
        this.state.loadingUser = false
      }).catch(err => {
        this.state.loadingUser = false
        sendError(err);
      });
    },

    /**
     * @param {User} user
     */
    displayUser(user) {
      let names = []
      if (user.firstName.trim()) names.push(toCapitalyze(user.firstName.trim()))
      if (user.lastName.trim().toUpperCase()) names.push(user.lastName.trim().toUpperCase())
      let name = names.join(' ')
      let displays = []
      if (name) displays.push(name)
      displays.push(user.email)
      return displays.join(' - ')
    },

    /**
     * @param {User[]} users
     */
    onChangeUserValues(users) {
      if (JSON.stringify(this.state.article.userRestrictions) !== JSON.stringify(users)) {
        this.state.article.userRestrictions = users.map(user => new User(user))
        this.state.users = []
      }
    },
    /**
     * @param {Article} article
     */
    getHref(article) {
      const page = convertPathToUrl(new Page(article.pages[0]))
      return urlService.getProjectUrl(this.lang.lang.iso6391, new Url(page.urls[0]))
    },

    onChangeValueTaxes(taxes) {
      if (JSON.stringify(taxes.map(a => a.id)) !== JSON.stringify(this.state.article.taxes.map(a => a.id))) {
        this.state.article.taxes = taxes
      }
    }
  }
}
</script>
