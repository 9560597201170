<style scoped>
.PurchaseDetail {
}

.PurchaseDetail h4 {
  text-transform: uppercase;
}

.PurchaseDetail--row {
  display: flex;
}

.PurchaseDetail--row {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 15px;
  margin-bottom: 15px;
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.PurchaseDetail--payment {
  padding: 25px;
  background: white;
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.PurchaseDetail--body {
  background: rgba(0, 0, 0, 0.03);
  border-radius: 4px;
}

.PurchaseDetail-desktop .PurchaseDetail--body {
  padding: 50px;
}

.PurchaseDetail-tablet .PurchaseDetail--body {
  padding: 50px;
}

.PurchaseDetail-mobile .PurchaseDetail--body {
  padding: 25px;
}

.PurchaseDetail--list {
  display: flex;
  justify-content: space-between;
}

.PurchaseDetail--transportCost {
}

.PurchaseDetail--transportCost-free {
  color: #6DB93A;
  font-weight: bold;
  font-size: 12px;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.PurchaseDetail--total {
  display: flex;
  justify-content: space-between;
  padding: 25px 50px;
  text-transform: uppercase;
  font-size: 16px;
  border: solid 1px rgba(0, 0, 0, 0.5);
  border-radius: 2px;
}

.PurchaseDetail--promotionFlag {
  background: #F56C6C;
  padding: 5px 15px 5px 15px;
  font-size: 16px;
  color: white;
  font-family: 'DDinRegular', sans-serif;
  border-radius: 2px;
}

.PurchaseDetail--promotionPrice {
  color: #F56C6C;
}
</style>

<template>
  <div class="PurchaseDetail" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="PurchaseDetail--body">

        <!------------------------------------------------------>

        <div class="PurchaseDetail--promotionCode">
          <div v-for="promotionCode in state.order.promotionCodes" :key="promotionCode.id">
            <InputText :value="promotionCode.code"
                       :clearable="true"
                       :disabled="!isConnected || state.roleId === ROLE.SELLER"
                       :onChangeValue="v => onChangeValuePromoCode(v)"
                       :label="lang.term(318)"
                       :error="state.form.display('code')"
                       :svg="icon.style">
            </InputText>
          </div>
          <div v-show="!state.order.promotionCodes.length">
            <InputText :value="state.promotionCode.code"
                       :clearable="true"
                       :disabled="!isConnected"
                       :onChangeValue="v => onChangeValuePromoCode(v)"
                       :label="lang.term(318)"
                       :error="state.form.display('code')"
                       :svg="icon.style">
            </InputText>
          </div>
          <br>
          <template v-if="state.loading">
            <LoaderSpace></LoaderSpace>
          </template>
          <br>
          <br>
        </div>

        <!------------------------------------------------------>

        <h4>
          <Term :term="lang.term(383)"/>
        </h4>
        <br>
        <br>

        <div class="PurchaseDetail--row">
          <Term :term="lang.term(176)"/>
          <div>{{ formatPrice(basketTotal.articleWithPromotionTTC + basketTotal.articleCodePromotionTTC) }} €</div>
        </div>

        <div class="PurchaseDetail--row" v-if="basketTotal.codePromotionPercentage && basketTotal.articleCodePromotionTTC">
          <div class="PurchaseDetail--promotionFlag">
            <Term :term="lang.term(725)"/>
          </div>
          <div class="PurchaseDetail--promotionPrice">-
            {{ formatPrice(basketTotal.articleCodePromotionTTC) }} €
          </div>
        </div>

        <div class="PurchaseDetail--row" v-show="basketTotal.ecoTTC">
          <Term :term="lang.term(175)"/>
          <div>+ {{ formatPrice(basketTotal.ecoTTC) }} €</div>
        </div>

        <!--        <div class="PurchaseDetail&#45;&#45;row">-->
        <!--          <div>Dont TVA 20 %</div>-->
        <!--          <div>{{ formatPrice(basketTotal.totalTVA) }} €</div>-->
        <!--        </div>-->
        <br>
        <br>

        <!------------------------------------------------------>

        <div class="PurchaseDetail--transport" v-show=" displayTransportCost">
          <h4>
            <Term :term="lang.term(384)"/>
          </h4>
          <br>
          <br>

          <div class="PurchaseDetail--row">
            <div>
              <Term :term="lang.term(385)"/>
            </div>
            <div class="PurchaseDetail--transportCost" :class="class_transportCost">
              {{ basketTotal.transportCostTTC ? `${formatPrice(basketTotal.transportCostTTC)} €` : 'offert' }}
            </div>
          </div>
          <br>
          <br>
        </div>

        <!------------------------------------------------------>

        <template v-for="funding in order.fundings">
          <template v-for="loan in funding.loans">
            <div class="PurchaseDetail--transport" :key="1">
              <h4>
                <Term :term="lang.term(386)"/>
              </h4>
              <br>
              <br>

              <div class="PurchaseDetail--row" v-for="echeance in basketTotal.echeances">
                <div v-html="replaceText(echeance.text)"></div>
                <div>
                  {{ formatPrice(echeance.amount) }} €
                </div>
              </div>
              <br>
              <br>
            </div>
          </template>
          <template v-for="deposit in funding.deposits">
            <div class="PurchaseDetail--transport" :key="2">
              <h4>
                <Term :term="lang.term(586)"/>
              </h4>
              <br>
              <br>

              <div class="PurchaseDetail--row" v-for="echeance in basketTotal.echeances">
                <div v-html="replaceText(echeance.text)"></div>
                <div>
                  {{ formatPrice(echeance.amount) }} €
                </div>
              </div>
              <br>
              <br>
            </div>
          </template>
        </template>

        <!------------------------------------------------------>

        <!--        <div v-show="state.purchaseTab === PATHS.PAYMENT">-->
        <!--          <h4>options de livraison</h4>-->
        <!--          <br>-->
        <!--          <br>-->
        <!--        </div>-->

        <!------------------------------------------------------>

        <!--        <div v-show="state.purchaseTab === PATHS.PAYMENT">-->
        <!--          <h4>adresse</h4>-->
        <!--        </div>-->

        <!------------------------------------------------------>

        <div class="PurchaseDetail--total">
          <div>
            <b>
              <Term :term="lang.term(387)"/>
            </b>
          </div>
          <div>
            <b>{{
                formatPrice(basketTotal.totalTTC)
              }} €
            </b>
          </div>
        </div>
        <br>
        <br>

        <!------------------------------------------------------>

        <div class="PurchaseDetail--info" v-show="state.purchaseTab === PATHS.BASKET">
          <ButtonAction :loading="loading" :term="lang.term(173)" :onClick="() => onClickButton(PATHS.DELIVERY)"/>
          <br>
          <br>
        </div>

        <div class="PurchaseDetail--info" v-show="state.purchaseTab === PATHS.DELIVERY">
          <ButtonAction :loading="loading" :term="lang.term(291)" :onClick="() => onClickButton(PATHS.PAYMENT)"/>
          <br>
          <br>
        </div>

        <div class="PurchaseDetail--info" v-show="state.purchaseTab === PATHS.PAYMENT">
          <slot name="payment"></slot>
        </div>

        <!------------------------------------------------------>

        <div class="PurchaseDetail--payment">
          <InputLabel :term="lang.term(317)" :svg="icon.lock"/>
          <br>
          <PaymentCard></PaymentCard>
        </div>

      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {PATHS} from "../../../@common/constant/PATHS";
import {formatPrice} from "../../../@common/function/formatPrice";
import {getBasketTotal} from "../../../@common/function/getBasketTotal";
import {Order} from "../../../@common/delta/database/Order";
import {PromotionCode} from "../../../@common/delta/database/PromotionCode";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {controlForm} from "../../function/controlForm";
import selectPromotionCodes from "../../../@common/api/promotion_code/selectPromotionCodes";
import {Debounce} from "../../../@common/ts/Debounce";
import {STATE_ROLE_ID, STATE_TOKEN, stateService} from "../../service/stateService";
import {ROLE} from "../../../@common/constant/ROLE";
import {sendError} from "../../function/sendError";

const debounce = new Debounce()

export default {

  props: {
    order: {
      default: () => new Order()
    },
    transports: {
      default: () => ([])
    },
    purchaseTab: {
      type: Number,
      default: () => PATHS.BASKET
    },
    displayTransportCost: {
      type: Boolean,
      default: () => false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    onClickButton: {
      type: Function,
      default: () => (pathId) => {
      }
    },
    onOrderChange: {
      type: Function,
      default: () => (order) => {
      }
    },
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      ROLE,
      PATHS,
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        lock: require('@bbx/vector~master/core/assets/svg/material/lock.svg'),
        style: require('@bbx/vector~master/core/assets/svg/material/style.svg'),
      },
      state: {
        /**
         * @type loading
         */
        loading: false,
        /**
         * @type string
         */
        token: '',
        /**
         * @type number
         */
        roleId: 0,
        /**
         * @type any
         */
        timeout: null,
        /**
         * @type Order
         */
        order: this.order,
        /**
         * @type PromotionCode
         */
        promotionCode: new PromotionCode(),
        /**
         * @type ApiResponseForm
         */
        form: new ApiResponseForm(),
        /**
         * @type number
         */
        purchaseTab: this.purchaseTab,
      },
    }
  },
  watch: {
    'purchaseTab': {
      handler(v) {
        this.state.purchaseTab = v
      },
      deep: true
    },
    'order': {
      handler(v) {
        this.state.order = v
      },
      deep: true
    },
    'state.order': {
      handler(v) {
        this.onOrderChange(v)
      },
      deep: true
    },
    'state.order.shops': {
      handler(v) {
        this.findCodeByShop()
      },
      deep: true
    }
  },
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`PurchaseDetail-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`PurchaseDetail-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`PurchaseDetail-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`PurchaseDetail-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`PurchaseDetail-desktopY`)
      return classes
    },
    class_transportCost() {
      const classes = []
      if (!this.basketTotal.transportCostTTC) classes.push('PurchaseDetail--transportCost-free')
      return classes
    },
    basketTotal() {
      return getBasketTotal({
        order: this.state.order,
        transports: this.transports,
        roleId: this.state.roleId
      })
    },
    isConnected() {
      return !!this.state.token
    }
  },
  beforeMount() {
    this.state.token = stateService.get(STATE_TOKEN)
    stateService.watch(STATE_TOKEN, v => this.state.token = v)

    this.state.roleId = stateService.get(STATE_ROLE_ID)
    stateService.watch(STATE_ROLE_ID, v => this.state.roleId = v)

    // -----

    this.findCodeByShop()
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    formatPrice,
    findCodeByShop() {
      if (this.state.roleId === ROLE.SELLER) {
        let code = ''
        for (const shop of this.state.order.shops) {
          for (const promotion of shop.promotions) {
            for (const promotionCode of promotion.promotionCodes) {
              code = promotionCode.code
            }
          }
        }
        if (code) {
          this.onChangeValuePromoCode(code)
        }
      }
    },

    onChangeValuePromoCode(code) {
      this.state.promotionCode.code = code

      if (!this.state.promotionCode.code) return this.state.order.promotionCodes = []

      debounce.callback({
        time: 1000,
        job: () => controlForm({
          apiAction: selectPromotionCodes,
          formAttribute: 'form',
          formState: this.state,
          loaderAttribute: 'loading',
          loaderState: this.state,
          payload: this.state.promotionCode,
          vue: this,
        }),
        success: res => {
          if (res.success) {
            this.state.order.promotionCodes = res.data
          }
        },
        error: err => {
          sendError(err)
        },
      })
    },

    replaceText(text) {
      return text.replace(`Aujourd'hui`, `<span style='color:orange;'>Aujourd'hui</span>`)
    }
  }
}
</script>
