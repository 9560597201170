<style scoped>
.GoogleTagManager {
  position: relative;
}
</style>
<template>
  <div class="GoogleTagManager"></div>
</template>
<script>

/**
 * @help https://www.youtube.com/watch?v=1dwk_erXAko
 */

import moment from 'moment';

import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import Carbon from '@bbx/carbon~master/core/ts/Carbon';
import {deltaService} from "@bbx/delta~master/core/service/deltaService";

import {googleConfigPublic} from "../../../../config/public/google.config";

import selectUsers from "../../../@common/api/user/selectUsers";

import {STATE_COOKIE, STATE_TOKEN, stateService} from "../../service/stateService";
import {apiClient} from "../../service/apiClientService";
import {eventService} from "../../service/eventService";

import {Cookie as CookieDelta} from "../../../@common/delta/cookie/Cookie";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {User} from "../../../@common/delta/database/User";

import {EVENT} from "../../../@common/constant/EVENT";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {Term} from "../../../@common/delta/database/Term";
import {convertPathToUrl, isPath} from "../../../@common/function/helperUrl";
import {calculateTTC} from "../../../@common/function/calculateTTC";
import {calculatePromotion} from "../../../@common/function/calculatePromotion";
import {noDecimalPrice} from "../../../@common/function/noDecimalPrice";
import {Article} from "../../../@common/delta/database/Article";
import {PATHS} from "../../../@common/constant/PATHS";
import {FILTER_OPTION} from "../../../@common/constant/FILTER_OPTION";
import {sendError} from "../../function/sendError";

const SCRIPT_HEAD_ID = 'google-tag-manager-head'
const SCRIPT_BODY_ID = 'google-tag-manager-body'

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      state: {
        /**
         * @type string
         */
        token: stateService.get(STATE_TOKEN),
        /**
         * @type CookieDelta
         */
        cookie: new CookieDelta(),
        /**
         * @type User
         */
        user: new User(),
        /**
         * @type boolean
         */
        previousIsHome: false,
        /**
         * @type boolean
         */
        previousIsCategory: false,
        /**
         * @type boolean
         */
        previousIsSearch: false,
      },
      events: [],
    };
  },
  watch: {
    'state.token': {
      handler() {
        this.selectUser().catch(sendError)
      }
    }
  },
  beforeMount() {

    this.state.cookie = stateService.get(STATE_COOKIE)
    stateService.watch(STATE_COOKIE, v => this.state.cookie = v)

    this.state.token = stateService.get(STATE_TOKEN)
    stateService.watch(STATE_TOKEN, v => this.state.token = v)

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.ROUTE_WILL_CHANGE,
      callback: nuxtProp => {
        const asyncFunction = async () => {
          this.state.previousIsHome = this.isHome(nuxtProp)
          this.state.previousIsCategory = this.isCategory(nuxtProp)
          this.state.previousIsSearch = this.isSearch(nuxtProp)
        }
        asyncFunction().catch(sendError);
      }
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.ROUTE_HAVE_CHANGED,
      callback: () => {
        const asyncFunction = async () => {
          this.dispatch()
        }
        asyncFunction().catch(sendError);
      }
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.ARTICLE_WAS_ADDED_TO_BASKET,
      callback: (props) => {
        const {
          article,
          quantity
        } = props
        let products = this.mapArticles([article], {}, {position: {}}, {withQuantity: true})
        products = this.uniqArticles({maps: products})
        const asyncFunction = async () => {
          this.pushDataLayer({
            'event': 'addToCart',
            'ecommerce': {
              'currencyCode': 'EUR',
              'add': {
                'products': products
              }
            }
          })
        }
        asyncFunction().catch(sendError);
      }
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.ARTICLE_WAS_INCREMENTED_IN_BASKET,
      callback: (props) => {
        const {
          article,
          quantity
        } = props
        let products = this.mapArticles([article], {}, {position: {}}, {withQuantity: true})
        products = this.uniqArticles({maps: products})
        const asyncFunction = async () => {
          this.pushDataLayer({
            'event': 'addToCart',
            'ecommerce': {
              'currencyCode': 'EUR',
              'add': {
                'products': products
              }
            }
          })
        }
        asyncFunction().catch(sendError);
      }
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.ARTICLE_WAS_DECREMENTED_IN_BASKET,
      callback: (props) => {
        const {
          article,
          quantity
        } = props
        let products = this.mapArticles([article], {}, {position: {}}, {withQuantity: true})
        products = this.uniqArticles({maps: products})
        const asyncFunction = async () => {
          this.pushDataLayer({
            'event': 'removeFromCart',
            'ecommerce': {
              'currencyCode': 'EUR',
              'remove': {
                'products': products
              }
            }
          })
        }
        asyncFunction().catch(sendError);
      }
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.ARTICLE_WILL_BE_REMOVED_FROM_BASKET,
      callback: (props) => {
        const {
          article,
          quantity
        } = props
        let products = this.mapArticles([article], {}, {position: {}}, {withQuantity: true})
        products = this.uniqArticles({maps: products})
        products = products.map(product => {
          product.quantity = product.quantity * quantity
          return products
        })
        const asyncFunction = async () => {
          this.pushDataLayer({
            'event': 'removeFromCart',
            'ecommerce': {
              'currencyCode': 'EUR',
              'remove': {
                'products': products
              }
            }
          })
        }
        asyncFunction().catch(sendError);
      }
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.CLICK_ARTICLE,
      callback: (props) => {
        const asyncFunction = async () => {
          let list = ''
          if (this.state.previousIsHome) list = 'Selection home'
          if (this.state.previousIsCategory) list = 'Listing categorie'
          if (this.state.previousIsSearch || props.source === 'speed-search') list = 'Listing recherche'

          let products = this.mapArticles([props.article])
          products = this.uniqArticles({maps: products})

          for (const product of products) {
            product.position = props.index + 1
          }

          this.pushDataLayer({
            'event': 'productClick',
            'ecommerce': {
              'currencyCode': 'EUR',
              'click': {
                'actionField': {'list': list},
                'products': products
              }
            }
          })
        }
        asyncFunction().catch(sendError);
      }
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.BASKET_WAS_OPENED,
      callback: (baskets) => {
        const asyncFunction = async () => {

          const products = this.getAllProductsFromBasket(baskets)

          this.pushDataLayer({
            'event': 'checkout',
            'ecommerce': {
              'currencyCode': 'EUR',
              'checkout': {
                'actionField': {'step': 1},
                'products': products
              }
            }

          })
        }
        asyncFunction().catch(sendError);
      }
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.BASKET_WAS_CONFIRMERD,
      callback: () => {
        const asyncFunction = async () => {
          this.pushDataLayer({
            'event': 'checkout',
            'ecommerce': {
              'checkout': {
                'actionField': {'step': 2},
              }
            }
          })
        }
        asyncFunction().catch(sendError);
      }
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.PAYMENT_METHOD_CHOSEN,
      callback: (option) => {
        const asyncFunction = async () => {
          this.pushDataLayer({
            'event': 'checkout',
            'ecommerce': {
              'checkout': {
                'actionField': {
                  'step': 6,
                  'option': option
                },
              }
            }
          })
        }
        asyncFunction().catch(sendError);
      }
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.ORDER_WAS_COMPLETED,
      callback: (props) => {

        const products = this.getAllProductsFromBasket(props.order.baskets)

        this.pushDataLayer({
          'event': 'purchase',
          'ecommerce': {
            'purchase': {
              'actionField': {
                'id': props.order.id,
                'affiliation': 'Online Store',
                'revenue': props.basketTotal.totalTTC,
                'tax': props.basketTotal.totalTVA,
                'shipping': props.basketTotal.transportCostTTC,
                'coupon': ''
              },
              'products': products
            }
          }
        })
      }
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
    this.toggleInit(true).catch(sendError);
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    async toggleInit(active) {
      active ? this.init().catch(sendError) : this.destroyScript().catch(sendError)
    },
    async init() {
      await Promise.all([
        this.initScript(),
        this.selectUser(),
      ])
      this.dispatch()
    },
    async initScript() {
      await Promise.all([
        this.initScriptHead(),
        this.initScriptBody(),
      ])
    },
    async initScriptHead() {
      return new Promise(rs => {
        const exist = document.getElementById(SCRIPT_HEAD_ID)
        if (!exist) {
          let script = document.createElement('script')
          script.id = SCRIPT_HEAD_ID
          script.type = 'text/javascript'
          script.innerHTML = `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer','${googleConfigPublic.gtm.id}');
          `
          document.head.appendChild(script)
          rs()
        } else rs()
      });
    },
    async initScriptBody() {
      return new Promise(rs => {
        const exist = document.getElementById(SCRIPT_BODY_ID)
        if (!exist) {
          let noscript = document.createElement('noscript')
          noscript.id = SCRIPT_BODY_ID
          let iframe = document.createElement('iframe')
          iframe.src = `https://www.googletagmanager.com/ns.html?id=${googleConfigPublic.gtm.id}`
          iframe.height = `0`
          iframe.width = `0`
          iframe.style.display = `none`
          iframe.style.visibility = `hidden`
          iframe.addEventListener('load', () => {
            rs()
          })
          noscript.appendChild(iframe)
          document.body.insertBefore(noscript, document.body.firstChild)
        } else rs()
      });
    },
    async destroyScript() {
      return Promise.all([
        this.destroyScriptHead(),
        this.destroyScriptBody(),
      ])
    },
    async destroyScriptHead() {
      const exist = document.getElementById(SCRIPT_HEAD_ID)
      if (exist) {
        document.head.removeChild(exist)
      }
    },
    async destroyScriptBody() {
      const exist = document.getElementById(SCRIPT_BODY_ID)
      if (exist) {
        document.body.removeChild(exist)
      }
    },
    pushDataLayer(props = {}) {

      let defaultProps = {
        event: undefined,
        ecommerce: undefined,
        typePage: undefined,
        visitorLoginState: undefined,
        visitorId: undefined,
        visitorType: undefined,
      }

      dataLayer.push(defaultProps);

      props = this.dispatchTypePage(props)
      props = this.dispatchVisitor(props)
      props = this.dispatchCookie(props)

      // console.log('GTM', props)

      dataLayer.push(props)
    },
    resetDataLayer() {
      // window.dataLayer.push(function () {
      //   this.reset();
      // })
    },
    resetDataLayerAttr(attr) {
      window.dataLayer.push(function () {
        this.set(attr, null);
      })
    },
    isNewOrOld(createdAt) {
      const carbon = new Carbon()
      let lastWeekDateTime = moment().subtract(1, 'weeks').format('YYYY-MM-DD HH:mm:ss')
      let lastWeekTimestamp = carbon.fromDateTime(lastWeekDateTime)
      if (createdAt > lastWeekTimestamp) {
        return 'Nouveau client'
      } else {
        return 'Ancien client'
      }
    },
    dispatch() {

      let dataLayer = {}

      dataLayer = this.dispatchHome(dataLayer)
      dataLayer = this.dispatchCategory(dataLayer)
      dataLayer = this.dispatchSearch(dataLayer)
      dataLayer = this.dispatchArticle(dataLayer)
      dataLayer = this.dispatchBasket(dataLayer)
      dataLayer = this.dispatchDelivery(dataLayer)
      dataLayer = this.dispatchPayment(dataLayer)

      this.pushDataLayer(dataLayer)
    },
    dispatchTypePage(dataLayer) {

      let term = this.getPageTerm()
      let typePage = this.lang.translateTerm(term)

      // -----

      if (this.isCategory(this.nuxtProp)) typePage = 'Catégorie'
      if (this.isArticle(this.nuxtProp)) typePage = 'Produit'
      if (this.isCollection(this.nuxtProp)) typePage = 'Collection'
      if (this.isBasket(this.nuxtProp)) typePage = 'Panier'

      // -----

      dataLayer.typePage = this.toCapitalize(typePage)

      // -----

      return dataLayer
    },
    dispatchVisitor(dataLayer) {
      dataLayer.visitorLoginState = this.state.token ? 'Logged in' : 'Logged out'
      if (this.state.token && this.state.user.id && this.state.cookie.analayze) dataLayer.visitorId = this.state.user.id
      if (this.state.token && this.state.user.id) dataLayer.visitorType = this.isNewOrOld(this.state.user.createdAt)
      return dataLayer
    },
    dispatchCookie(dataLayer) {
      dataLayer.consentementCookiesAnalytiques = this.state.cookie.analayze ? '1' : '0'
      dataLayer.consentementCookiesMarketing = this.state.cookie.marketing ? '1' : '0'
      return dataLayer
    },
    dispatchHome(dataLayer) {
      if (this.isHome(this.nuxtProp)) {
        dataLayer.ecommerce = {
          'currencyCode': 'EUR',
          'impressions': this.mapArticles(deltaService.array(this.nuxtProp.payload.articles, Article), {
            list: 'Selection home'
          })
        }
      }
      return dataLayer
    },
    dispatchCategory(dataLayer) {
      if (this.isCategory(this.nuxtProp)) {
        dataLayer.ecommerce = {
          'currencyCode': 'EUR',
          'impressions': this.mapArticles(deltaService.array(this.nuxtProp.payload.articles, Article), {
            list: 'Listing categorie'
          })
        }
      }
      return dataLayer
    },
    dispatchSearch(dataLayer) {
      if (this.isSearch(this.nuxtProp)) {
        dataLayer.ecommerce = {
          'currencyCode': 'EUR',
          'impressions': this.mapArticles(deltaService.array(this.nuxtProp.payload.articles, Article), {
            list: 'Listing recherche'
          })
        }
      }
      return dataLayer
    },
    dispatchArticle(dataLayer) {
      if (this.isArticle(this.nuxtProp)) {
        let article = deltaService.object(this.nuxtProp.payload.article, Article)
        dataLayer.ecommerce = {
          'currencyCode': 'EUR',
          'detail': {
            'actionField': {'list': 'Fiche produit'},
            'products': this.mapArticles([article], {}, {position: {}})
          }
        }
      }
      return dataLayer
    },
    dispatchBasket(dataLayer) {
      if (this.isBasket(this.nuxtProp)) {
        dataLayer.event = 'checkout'
        dataLayer.ecommerce = {
          'checkout': {
            'actionField': {'step': 3},
          }
        }
      }
      return dataLayer
    },
    dispatchDelivery(dataLayer) {
      if (this.isDelivery(this.nuxtProp)) {
        dataLayer.event = 'checkout'
        dataLayer.ecommerce = {
          'checkout': {
            'actionField': {'step': 4},
          }
        }
      }
      return dataLayer
    },
    dispatchPayment(dataLayer) {
      if (this.isPayment(this.nuxtProp)) {
        dataLayer.ecommerce = {
          'checkout': {
            'actionField': {'step': 5},
          }
        }
      }
      return dataLayer
    },

    // -----

    /**
     * @param {NuxtProp} nuxtProp
     * @return {boolean}
     */
    isSearch(nuxtProp) {
      return !!(isPath(nuxtProp, [PATHS.CATALOG]) && nuxtProp.url.queries.some(query => query.name === FILTER_OPTION.SEARCH))
    },
    /**
     * @param {NuxtProp} nuxtProp
     * @return {boolean}
     */
    isHome(nuxtProp) {
      return !!(isPath(nuxtProp, [PATHS.HOME]) || nuxtProp.url.paths.length < 2)
    },
    /**
     * @param {NuxtProp} nuxtProp
     * @return {boolean}
     */
    isCategory(nuxtProp) {
      return !!nuxtProp.page.categories.length
    },
    /**
     * @param {NuxtProp} nuxtProp
     * @return {boolean}
     */
    isCollection(nuxtProp) {
      return !!nuxtProp.page.collections.length
    },
    /**
     * @param {NuxtProp} nuxtProp
     * @return {boolean}
     */
    isArticle(nuxtProp) {
      return !!nuxtProp.page.articles.length
    },
    /**
     * @param {NuxtProp} nuxtProp
     * @return {boolean}
     */
    isBasket(nuxtProp) {
      return !!(isPath(nuxtProp, [PATHS.PURCHASE])) || isPath(nuxtProp, [
        PATHS.PURCHASE,
        PATHS.BASKET
      ])
    },
    /**
     * @param {NuxtProp} nuxtProp
     * @return {boolean}
     */
    isDelivery(nuxtProp) {
      return !!isPath(nuxtProp, [
        PATHS.PURCHASE,
        PATHS.DELIVERY
      ])
    },
    /**
     * @param {NuxtProp} nuxtProp
     * @return {boolean}
     */
    isPayment(nuxtProp) {
      return !!isPath(nuxtProp, [
        PATHS.PURCHASE,
        PATHS.PAYMENT
      ])
    },

    // -----

    async selectUser() {
      const {data} = await apiClient.do(selectUsers, selectUsers.with({
        $token: this.state.token,
        $scope: 2
      }))
      this.state.user = new User(data[0]);
    },

    getAllProductsFromBasket(baskets) {
      let allProducts = []

      for (const basket of baskets) {
        let products = this.mapArticles(basket.articles, {}, {position: {}}, {withQuantity: true})
        products = products.map(product => {
          product.quantity = product.quantity * basket.quantity
          return products
        })
        allProducts = [
          ...allProducts,
          products
        ]
      }

      allProducts = this.uniqArticles({maps: allProducts})

      return allProducts
    },
    /**
     * @param {any[]} maps
     * @return {any[]}
     */
    uniqArticles({maps}) {
      let uniqMaps = []
      let uniqRefMaps = []

      for (const map of maps) {
        let index = uniqRefMaps.indexOf(map.SKU)
        if (index === -1) {
          uniqMaps.push(map)
        } else if (map.quantity) {
          if (!uniqMaps[index].quantity) {
            uniqMaps[index].quantity = 1
          }
          uniqMaps[index].quantity += map.quantity
        }
      }

      return uniqMaps
    },
    /**
     * @param {Article[]} articles
     * @param {any} overide
     * @param {any} excludes
     * @param {{withQuantity?:boolean}} option
     * @return {any[]}
     */
    mapArticles(articles, overide = {}, excludes = {}, option = {}) {

      let maps = []

      articles.forEach((article, i) => {
        article.batches.forEach(batch => {

          let map = {
            // name: 'Nom du produit',
            // id: '12345',
            // SKU: '67890',
            // price: '15.25',
            // brand: 'Les Jardins',
            // category: 'Catéorie du produit',
            // list: 'Selection home',
            // position: i + 1
          }

          // -----

          batch.products.forEach(product => {
            product.termNames.forEach(termName => {
              map.name = this.lang.translateTerm(termName)
            })
          })

          // -----

          map.id = article.id

          // -----

          batch.products.forEach(product => {
            map.SKU = product.reference
          })

          // -----

          map.price = noDecimalPrice(calculateTTC(article.price)) / 100
          for (const promotion of article.promotions) {
            map.price = noDecimalPrice(calculatePromotion(calculateTTC(article.price), promotion.percentage)) / 100
          }

          // -----

          map.brand = 'Les Jardins'

          // -----

          let categories = []
          batch.products.forEach(product => {
            product.categories.forEach(category => {
              categories.push(this.toCapitalize(this.lang.translateTerm(new Term(category.terms[0]))))
            })
          })
          if (categories.length) {
            map.category = categories.join('/')
          }

          // -----

          if (overide.list) map.list = overide.list

          // -----

          if (!excludes.position) map.position = i + 1

          // -----

          if (option.withQuantity) map.quantity = batch.quantity

          // -----

          maps.push(map)
        })
      })

      return maps
    },
    getPageTerm() {
      let page = convertPathToUrl(this.nuxtProp.page)
      let term = new Term()

      for (const url of page.urls) {
        for (const sort of url.sorts) {
          for (const path of sort.paths) {
            term = new Term(path.terms[0])
          }
        }
      }

      return term
    },
    toCapitalize(str) {
      return str.charAt(0).toUpperCase() + str.slice(1)
    }
  }
}
;
</script>
