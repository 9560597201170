<style scoped>
.LangAdmin {
}

.LangAdmin--headerTop {
  width: 100%;
  display: flex;
}

.LangAdmin--headerTopBox {
  flex: 1;
  padding: 20px 20px 0px 20px;
}

.LangAdmin--header {
  width: 100%;
  display: flex;
  box-sizing: border-box;
  font-family: 'ComfortaaRegular', sans-serif;
}

.LangAdmin--card {
  background: rgba(0, 0, 0, 0.03);
  flex: 1;
  height: 200px;
  display: flex;
  flex-direction: column;
  border-radius: 8px;
}

.LangAdmin--card:nth-child(1) {
  margin: 35px 25px 50px 0px;
}

.LangAdmin--card:nth-child(2) {
  margin: 35px 25px 50px 25px;
}

.LangAdmin--card:nth-child(3) {
  margin: 35px 0px 50px 25px;
}

.LangAdmin--cardHeader {
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  padding: 15px;
}

.LangAdmin--cardBody {
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 24px;
}

.LangAdmin--cardFooter {
  padding: 15px;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  font-size: 12px;
}

.LangAdmin--cardFooter span {
  padding-right: 15px;
}

.LangAdmin--body {
  padding-top: 50px;
}

.LangAdmin--loader {
  width: 100%;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.LangAdmin--translation {
  display: flex;
  align-items: flex-start;
  padding: 0 50px;
}

.LangAdmin--translationPrimary {
  flex: 1;
}

.LangAdmin--translationSecondary {
  flex: 1;
}

.LangAdmin--save {
  display: flex;
  justify-content: space-between;
  padding: 0 50px 50px 50px;
}

.LangAdmin--save button {
  border-radius: 4px;
  padding: 4px 8px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  transition: background-color 300ms;
  cursor: pointer;
  background: #ffffff;
  color: #000000;
}

.LangAdmin--save button:first-child {
  margin-top: 15px;
}

.LangAdmin--save button:hover {
  background: #000000;
  color: #ffffff;
  border: solid 1px rgba(0, 0, 0, 1);
}

.LangAdmin--saveSecondary {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 15px;
}

.LangAdmin--saveSecondary ::v-deep .InputSwitch {
  padding-right: 15px;
}

.LangAdmin--spacer {
  width: 100px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 15px;
}

.LangAdmin--spacerBarre {
  height: 1px;
  background: rgba(0, 0, 0, 0.1);
  flex: 1;
  margin: 0 15px;
}

.LangAdmin--spacerBall {
  background: rgba(0, 0, 0, 0.1);
  width: 10px;
  height: 10px;
  border-radius: 10px;
}

.LangAdmin--footer {
  padding: 5px 5px 5px 5px;
  display: flex;
  justify-content: center;
}
</style>

<template>
  <div class="LangAdmin" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="LangAdmin--headerTop">
        <div class="LangAdmin--headerTopBox">
        </div>
        <div class="LangAdmin--headerTopBox">
          <el-input v-model="state.filterSearch" icon="search" placeholder="Rechercher un terme...">
            <i slot="prefix" class="el-input__icon el-icon-search"></i>
          </el-input>
        </div>
        <div class="LangAdmin--headerTopBox">
        </div>
      </div>

      <div class="LangAdmin--header">
        <div class="LangAdmin--card LangAdmin--cardCount">
          <div class="LangAdmin--cardHeader">termes</div>
          <div class="LangAdmin--cardBody">{{ format(state.count) }}</div>
          <div class="LangAdmin--cardFooter">
            <span>Afficher les termes traduits</span>
            <InputSwitch :value="state.filterIsTranslated"
                         :onChangeValue="v => state.filterIsTranslated = v">
            </InputSwitch>
          </div>
        </div>
        <div class="LangAdmin--card LangAdmin--cardTraduction">
          <div class="LangAdmin--cardHeader">langues</div>
          <div class="LangAdmin--cardBody">2</div>
          <div class="LangAdmin--cardFooter">Disponibles sur l'application</div>
        </div>
        <div class="LangAdmin--card LangAdmin--cardProgress">
          <div class="LangAdmin--cardHeader">traductions</div>
          <div class="LangAdmin--cardBody">{{ state.progress.toFixed(0) }}%</div>
          <div class="LangAdmin--cardFooter">{{ state.countWords }} mots restants à traduire</div>
        </div>
      </div>
      <div class="LangAdmin--body">


        <div class="LangAdmin--translation">
          <div class="LangAdmin--translationPrimary">
            <InputSelect :items="state.primaryLangues"
                         :values="state.selectedPrimaryLangs"
                         :max="1"
                         :label="lang.term(553)"
                         :clearable="false"
                         :display="v => v.name"
                         :onChangeValues="v => state.selectedPrimaryLangs = v">
            </InputSelect>
          </div>
          <div class="LangAdmin--spacer">

          </div>
          <div class="LangAdmin--translationSecondary">
            <InputSelect :items="state.secondaryLangues"
                         :values="state.selectedSecondaryLangues"
                         :max="1"
                         :display="v => v.name"
                         :label="lang.term(554)"
                         :onChangeValues="v => state.selectedSecondaryLangues = v">
            </InputSelect>
          </div>
        </div>
        <div class="LangAdmin--save"></div>

        <div class="LangAdmin--loader" v-show="state.loadingSelect">
          <Loader></Loader>
        </div>

        <template v-for="term in state.terms">
          <div class="LangAdmin--translation" :key="term.id" v-show="!state.loadingSelect">
            <div class="LangAdmin--translationPrimary">
              <InputTerm :term="term"
                         :onChangeTerm="v => term = v"
                         :lang="selectedPrimaryLang"
                         :fillEmptyTerm="false"
                         :langs="state.selectedPrimaryLangs"
                         :type="term.isHtml ? `textarea` : `text`">
              </InputTerm>
            </div>
            <div class="LangAdmin--spacer">
              <div class="LangAdmin--spacerBarre"></div>
              <div class="LangAdmin--spacerBall"></div>
              <div class="LangAdmin--spacerBarre"></div>
            </div>
            <div class="LangAdmin--translationSecondary">
              <template v-for="(translation, index) in term.translations" v-if="index === 1">
                <div
                    :title="translation.isSameAsDefault ? lang.translate(724) : ''">
                  <InputTerm :term="term"
                             :lang="selectedSecondaryLang"
                             :disabled="translation.isSameAsDefault"
                             :langs="state.selectedSecondaryLangues"
                             :fillEmptyTerm="false"
                             :onChangeTerm="v => term = v"
                             :type="term.isHtml ? `textarea` : `text`">
                  </InputTerm>
                </div>
              </template>
            </div>
          </div>
          <div class="LangAdmin--save" v-show="!state.loadingSelect">
            <button @click="() => save(term)">
              {{ state.loadingCreate ? 'Chargement...' : 'Sauvegarder' }}
            </button>
            <div class="LangAdmin--saveSecondary">
              <template v-for="(translation, index) in term.translations" v-if="index === 1">
                <InputSwitch :value="translation.isSameAsDefault"
                             :label="lang.term(723)"
                             :onChangeValue="v => translation.isSameAsDefault = v">
                </InputSwitch>
              </template>
              <button @click="() => save(term)">
                {{ state.loadingCreate ? 'Chargement...' : 'Sauvegarder' }}
              </button>
            </div>
          </div>
        </template>


      </div>
      <div class="LangAdmin--footer">
        <Pagination :limit="state.filterLimit"
                    :offset="state.filterOffset"
                    :total="state.count"
                    :range="10"
                    :onOffsetChange="v => state.filterOffset = v">
        </Pagination>
      </div>
    </Responsive>
  </div>
</template>

<script>

import Carbon from "@bbx/carbon~master/core/ts/Carbon.ts";
import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {apiClient} from "../../service/apiClientService";
import selectTerms from "../../../@common/api/term/selectTerms";
import {Debounce} from "../../../@common/ts/Debounce";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {Lang} from "../../../@common/delta/database/Lang";
import {LANG, LANG_ISO} from "../../../@common/constant/LANG";
import {controlForm} from "../../function/controlForm";
import createTerm from "../../../@common/api/term/createTerm";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {sendError} from "../../function/sendError";

let debounce = new Debounce()

export default {
  components: {
    Responsive: () => import("@bbx/responsive~master/core/env/browser/vue/Responsive.vue"),
    Vector: () => import("@bbx/vector~master/core/env/browser/vue/Vector.vue"),
    Term: () => import("../term/Term.vue"),
  },
  props: {},
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      state: {
        /**
         * @type ApiResponseForm
         */
        form: new ApiResponseForm(),
        /**
         * @type Term[]
         */
        terms: [],
        /**
         * @type boolean
         */
        loadingSelect: false,
        /**
         * @type boolean
         */
        loadingCreate: false,
        /**
         * @type {string}
         */
        filterSearch: '',
        /**
         * @type {number}
         */
        filterLimit: 8,
        /**
         * @type {number}
         */
        filterOffset: 0,
        /**
         * @type {boolean}
         */
        filterIsTranslated: true,
        /**
         * @type number
         */
        count: 0,
        /**
         * @type number
         */
        countWords: 0,
        /**
         * @type number
         */
        progress: 0,
        /**
         * @type {Lang[]}
         */
        primaryLangues: [
          new Lang({
            id: LANG.FR,
            name: 'Français',
            iso6391: LANG_ISO.FR
          })
        ],
        /**
         * @type {Lang[]}
         */
        selectedPrimaryLangs: [
          new Lang({
            id: LANG.FR,
            name: 'Français',
            iso6391: LANG_ISO.FR
          })
        ],
        /**
         * @type {Lang[]}
         */
        secondaryLangues: [
          new Lang({
            id: LANG.EN,
            name: 'Anglais',
            iso6391: LANG_ISO.EN
          })
        ],
        /**
         * @type {Lang[]}
         */
        selectedSecondaryLangues: [
          new Lang({
            id: LANG.EN,
            name: 'Anglais',
            iso6391: LANG_ISO.EN
          })
        ],
      },
    }
  },
  watch: {
    'state.filterOffset': function () {
      if (this.state.loadingSelect === false) {
        this.select().catch(sendError)
      }
    },
    'state.filterLimit': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.filterSearch': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.filterIsTranslated': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
  },
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`LangAdmin-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`LangAdmin-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`LangAdmin-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`LangAdmin-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`LangAdmin-desktopY`)
      return classes
    },
    selectedPrimaryLang() {
      return new Lang(this.state.selectedPrimaryLangs[0])
    },
    selectedSecondaryLang() {
      return new Lang(this.state.selectedSecondaryLangues[0])
    },
  },
  beforeMount() {
    this.select().catch(sendError)
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    format(number) {
      return new Intl.NumberFormat('fr-FR').format(number).replace('.', ' ')
    },
    async select() {
      debounce.callback({
        job: () => Promise.all([
          this.selectToggle(),
          this.selectTranslatedCount(),
        ]),
        success: res => {
        },
        error: sendError
      })
    },
    async selectToggle() {

      this.state.loadingSelect = true

      let filter = {
        active: true,
        $limit: this.state.filterLimit,
        $offset: this.state.filterOffset,
        $search: this.state.filterSearch,
        $isUuid: false,
        $hasPrimaryValue: true,
        $isTranslated: this.state.filterIsTranslated,
        $orderType: 1,
        $withCountWords: true,
      }

      let res = await apiClient.do(selectTerms, selectTerms.with(filter))

      this.state.terms = res.data;
      this.state.count = res.count;
      this.state.countWords = res.extra.countWords;

      this.state.loadingSelect = false
    },
    async selectTranslatedCount() {
      let res1 = await apiClient.do(selectTerms, selectTerms.with({
        active: true,
        $limit: 1,
        $offset: 0,
        $hasPrimaryValue: true,
        $isTranslated: true,
      }))
      let res2 = await apiClient.do(selectTerms, selectTerms.with({
        active: true,
        $limit: 1,
        $offset: 0,
        $hasPrimaryValue: true,
      }))
      let countTranslated = res1.count
      let countTotal = res2.count
      this.state.progress = countTranslated * 100 / countTotal;
    },
    /**
     * @param {Term} term
     * @return {Promise<void>}
     */
    async save(term) {

      term.updatedAt = new Carbon().timestamp()

      const res = await controlForm({
        apiAction: createTerm,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loadingCreate',
        loaderState: this.state,
        payload: term,
        vue: this,
      });

      if (res.success) await this.select()
    },

    isSimilar(term) {
      let isSimilar = false
      for (const translation of term.translations) {
        if (translation.isSameAsDefault) isSimilar = true
      }
      return isSimilar
    }
  }
}
</script>
