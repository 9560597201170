"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Tax = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Article_1 = require("./Article");
var Unit_1 = require("./Unit");
var Tax = /** @class */ (function () {
    function Tax(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.name = deltaService_1.deltaService.string(props.name, '');
        this.value = deltaService_1.deltaService.number(props.value, 0);
        this.units = deltaService_1.deltaService.array(props.units, Unit_1.Unit);
        this.articles = deltaService_1.deltaService.array(props.articles, Article_1.Article);
    }
    return Tax;
}());
exports.Tax = Tax;
