<style>

html {
  overflow-y: scroll;
  overflow-x: hidden;
}

body {
  margin: 0;
  font-family: "Helvetica Neue", Helvetica, "PingFang SC", "Hiragino Sans GB", "Microsoft YaHei", "微软雅黑", Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  margin: 0;
}

h2 {
  margin: 0;
}

h3 {
  margin: 0;
}

h4 {
  margin: 0;
}

h5 {
  margin: 0;
}

h6 {
  margin: 0;
}

ul {
  margin: 0;
  padding: 0;
}

li {
  list-style: none;
}

p {
  margin: 0;
}

pre {
  margin: 0;
}

.Index {
}

.Index--loaderContent {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100vh;
}

.Index--loader {
  width: 100%;
  height: 100vh;
  background: rgba(255, 255, 255, 1);
  transition: opacity 500ms;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.Index--loaderSpinner {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: center;
  color: rgba(138, 108, 89);
  flex: 1;
}

.Index--loaderSpinner span {
  display: block;
  padding-top: 15px;
  font-family: sans-serif;
  letter-spacing: 4px;
  text-transform: uppercase;
  font-size: 10px;
}

.Index--loaderLogo {
  width: 230px;
  font-size: 14px;
  margin-top: 50px;
  margin-bottom: 50px;
}

.Index--loaderLogo img {
  width: 230px;
}

.Index--core {
}
</style>

<template>
  <div class="Index">
    <div class="Index--loaderContent">
      <div class="Index--loader">
        <div class="Index--loaderLogo">
          <img src="../../../core/@common/assets/logo-brown-laoder.png" alt="logo-loader">
        </div>
        <div class="Index--loaderSpinner">
          <Loader :rgb="[138, 108, 89]"></Loader>
          <span>Chargement</span>
        </div>
      </div>
    </div>
    <div class="Index--core">
      <Router v-if="nuxtProp.ready" :nuxtProp="nuxtProp"></Router>
    </div>
  </div>
</template>
<script>
import {index} from "../../../core/@browser";

export default index
</script>
