"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Evolve = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Article_1 = require("./Article");
var Evolve = /** @class */ (function () {
    function Evolve(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.articleDescendants = deltaService_1.deltaService.array(props.articleDescendants, Article_1.Article);
        this.articleAncestors = deltaService_1.deltaService.array(props.articleAncestors, Article_1.Article);
    }
    return Evolve;
}());
exports.Evolve = Evolve;
