"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Url = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Page_1 = require("./Page");
var Sort_1 = require("./Sort");
var ProjectUrl_1 = require("./ProjectUrl");
var Url = /** @class */ (function () {
    function Url(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.sorts = deltaService_1.deltaService.array(props.sorts, Sort_1.Sort);
        this.pages = deltaService_1.deltaService.array(props.pages, Page_1.Page);
        this.projectUrls = deltaService_1.deltaService.array(props.projectUrls, ProjectUrl_1.ProjectUrl);
    }
    return Url;
}());
exports.Url = Url;
