<style scoped lang="scss">

@import "../../scss/color";

.CatalogueCollection {
}

.CatalogueCollection--big {
  height: calc(100vh - 190px);
  position: relative;
  min-height: 700px;
}

.CatalogueCollection-opened .CatalogueCollection--big {
  height: auto;
}

.CatalogueCollection--photos {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
}

.CatalogueCollection--photoLeft {
  width: calc(50% + 40px);
  height: calc(50% + 150px);
  position: relative;
  box-sizing: border-box;
  padding-top: 100px; /* TOP */

  transform: translateY(-100px);
  opacity: 0;

  transition: transform 1000ms, opacity 1000ms;
}

.CatalogueCollection-isVisible .CatalogueCollection--photoLeft {
  transform: translateY(0px);
  opacity: 1;
}

.CatalogueCollection--pictureLeft {
  width: 80%;
  height: 100%;
  position: relative;
  left: 20%;
  background: rgba(0, 0, 0, 0.05);
}

.CatalogueCollection--materials {
  display: flex;
  position: relative;
  left: 20%;
}

.CatalogueCollection--material {
  padding: 50px 25px 0 0;
}

.CatalogueCollection--photoRight {
  width: calc(50% - 40px);
  height: calc(50% + 40px);
  position: relative;
  top: calc(50% - 40px);

  transform: translateY(100px);
  opacity: 0;

  transition: transform 1000ms, opacity 1000ms;
}

.CatalogueCollection-isVisible .CatalogueCollection--photoRight {
  transform: translateY(0px);
  opacity: 1;
}

.CatalogueCollection--pictureRight {
  width: 60%;
  height: calc(100% - 100px); /* BOTTOM */
  background: rgba(0, 0, 0, 0.05);
}

.CatalogueCollection--square {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CatalogueCollection-opened .CatalogueCollection--square {
  height: auto;
  position: relative;
}

.CatalogueCollection--squareWhite {
  background: #ffffff;
  border: solid 1px rgba(0, 0, 0, 0.05);
  box-sizing: border-box;
  width: 400px;
  height: 400px;
  position: relative;
  transition: width 500ms, height 500ms;
}

.CatalogueCollection-opened .CatalogueCollection--squareWhite {
  width: 100%;
  min-height: calc(100vh - 190px);
  height: auto;
}

.CatalogueCollection--close {
  padding: 50px;
  width: 24px;
  height: 24px;
  display: none;
  cursor: pointer;
}

.CatalogueCollection-opened .CatalogueCollection--close {
  display: block;
}

.CatalogueCollection--squareName {
  font-family: 'AmbroiseRegular', sans-serif;
  font-size: 80px;
  position: relative;
  right: -75%;
  text-align: left;
  text-transform: capitalize;
  line-height: 104px;
  white-space: nowrap;
}

.CatalogueCollection-opened .CatalogueCollection--squareName {
  position: relative;
  right: auto;
  text-align: center;
  line-height: normal;
}

.CatalogueCollection--squareSubTitle {
  text-align: center;
  font-family: 'DDinBold', sans-serif;
  font-size: 18px;
  position: absolute;
  top: 42px;
  width: 100%;
}

.CatalogueCollection-opened .CatalogueCollection--squareSubTitle {
  position: relative;
  top: auto;
}

.CatalogueCollection--squareDescription {
  text-align: center;
  font-family: 'DDinRegular', sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.75);
  line-height: 26px;
  padding: 0 50px;
  height: 246px;
  max-width: 400px;
  margin: auto;
}

.CatalogueCollection-opened .CatalogueCollection--squareDescription {
  height: auto;
  margin: auto;
  padding-top: 30px;
  padding-bottom: 100px;
}

.CatalogueCollection--squareDescriptionLink {
  text-decoration: underline;
  color: #000000;
  cursor: pointer;
}

.CatalogueCollection-opened .CatalogueCollection--squareDescriptionLink {
  display: none;
}

.CatalogueCollection--squareDownload {
  width: calc(50% - 40px);
  background: rgb(0, 0, 0, 0.03);
  height: 50px;
  position: relative;
  left: calc(50% + 40px);
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 300ms;
}

.CatalogueCollection--squareDownload:hover {
  background: rgb(0, 0, 0, 0.1);
}

.CatalogueCollection-opened .CatalogueCollection--squareDownload {
  display: none;
}

.CatalogueCollection--squareDownloadText {
  text-transform: capitalize;
  padding-right: 10px;
  font-size: 14px;
  font-family: 'DDinRegular', sans-serif;
  position: relative;
  top: -1px;
}

.CatalogueCollection--squareDownloadIcon {
  color: #ffffff;
  background: #000000;
  text-transform: uppercase;
  border-radius: 3px;
  font-family: 'DDinBold', sans-serif;
  padding: 2px 8px 4px 8px;
  letter-spacing: 1px;
  font-size: 10px;
  position: relative;
  top: 1px;
}

.CatalogueCollection--mobileShowMore {
  padding-top: 40px;
  padding-left: 20px;
  padding-right: 20px;
  padding-bottom: 40px;
  display: flex;
  justify-content: center;
}

.CatalogueCollection--mobileShowMore ::v-deep .ButtonLink {
  width: 100%;
}

/**/

.CatalogueCollection--small {
  position: relative;
}

.CatalogueCollection--smallPictures {
  position: relative;
}

.CatalogueCollection--smallPicture {
  position: relative;
}

.CatalogueCollection--smallPicture-1 {
  width: 100%;
  height: 570px;
  padding-left: 20px;
  padding-right: 20px;
  padding-top: 100px;
  box-sizing: border-box;
}

.CatalogueCollection--smallPicture-2 {
  position: absolute;
  bottom: 50px;
  left: 10px;
  width: 100px;
  height: 100px;
  box-sizing: border-box;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.15);
  //border: solid 1px white;
}

.CatalogueCollection--smallDownload {
  position: absolute;
  bottom: 0;
  right: 20px;
  width: calc(100% - 40px);
  height: 50px;
  display: flex;
  align-items: center;
  justify-content: center;
  background: #f7f7f7;
}

.CatalogueCollection--smallDescription {
  padding: 20px;
  text-align: center;
  font-family: 'DDinRegular', sans-serif;
  font-size: 16px;
  color: rgba(0, 0, 0, 0.5);
  line-height: 26px;
}

.CatalogueCollection--smallMaterials {
  padding: 0 50px 50px 50px;
}

.CatalogueCollection--smallMaterial {
  padding: 20px 0 0 0;
}

.CatalogueCollection--smallTitles {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 100px;
}

.CatalogueCollection--smallTitleSquare {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 100px;
  height: 100px;
  background: #f2f2f2;
  //border: solid 1px white;
  box-sizing: border-box;
}

.CatalogueCollection--smallTitleCollection {
  position: relative;
  text-align: center;
  font-family: 'DDinBold', sans-serif;
  font-size: 16px;
  left: 25px;
  top: 25px;
}

.CatalogueCollection-desktop .CatalogueCollection--smallTitleCollection {

}

.CatalogueCollection-tablet .CatalogueCollection--smallTitleCollection {

}

.CatalogueCollection-mobile .CatalogueCollection--smallTitleCollection {
  opacity: 0;
}

/**/

.CatalogueCollection--smallTitleName {
  position: relative;
  font-family: 'AmbroiseRegular', sans-serif;
  font-size: 50px;
  text-transform: capitalize;
  right: 25px;
}
</style>

<template>
  <div class="CatalogueCollection" :class="class_root" v-if="canDisplay">
    <Visible :onVisibilityChanged="onVisibilityChanged" :registerCallback="registerCallback">

      <Responsive :breakPoint="stateProps.Responsive['0'].breakPoint"
                  :verticalBreakPoint="stateProps.Responsive['0'].verticalBreakPoint"
                  :breakPoints="stateProps.Responsive['0'].breakPoints"
                  :verticalBreakPoints="stateProps.Responsive['0'].verticalBreakPoints"
                  :onBreakPointChange="stateProps.Responsive['0'].onBreakPointChange"
                  :onVerticalBreakPointChange="stateProps.Responsive['0'].onVerticalBreakPointChange">
        <template v-slot:default="{breakPoint}">
          <template v-if="[SCREEN.DESKTOP, SCREEN.TABLET].includes(breakPoint.name)">
            <div class="CatalogueCollection--big" :style="style_big">
              <div class="CatalogueCollection--photos">
                <div class="CatalogueCollection--photoLeft">
                  <div class="CatalogueCollection--pictureLeft">
                    <template v-for="(sortphoto, index) in sortPhotos">
                      <template v-for="photo in sortphoto.photos">
                        <template v-for="file in photo.files">
                          <Picture v-if="index === 0" :file="file"></Picture>
                        </template>
                      </template>
                    </template>
                  </div>
                  <div class="CatalogueCollection--materials" v-if="withMaterials">
                    <div class="CatalogueCollection--material"
                         v-for="material in state.collection.materials"
                         :key="material.id">
                      <MaterialArticle :material="material">
                      </MaterialArticle>
                    </div>
                  </div>
                </div>
                <div class="CatalogueCollection--photoRight">
                  <div class="CatalogueCollection--pictureRight">
                    <template v-for="(sortphoto, index) in sortPhotos">
                      <template v-for="photo in sortphoto.photos">
                        <template v-for="file in photo.files">
                          <Picture v-if="index === 1" :file="file"></Picture>
                        </template>
                      </template>
                    </template>
                  </div>
                </div>
              </div>
              <div class="CatalogueCollection--square">
                <div class="CatalogueCollection--squareWhite" :style="style_squareWhite">
                  <div class="CatalogueCollection--close"
                       @click="() => state.opened = false">
                    <Vector :css="style_close" :svg="icon.clear"></Vector>
                  </div>
                  <div class="CatalogueCollection--squareName" :style="style_squareName">
                    {{ state.collection.value }}
                  </div>
                  <div class="CatalogueCollection--squareSubTitle" :style="style_squareSubTitle">Collection</div>
                  <div class="CatalogueCollection--squareDescription">
                    <template v-for="termDescription in state.collection.termDescriptions">
                      <div v-if="!state.opened"
                           v-html="getMaxString(noHTML(lang.translateTerm(termDescription)), 230)">
                      </div>
                      <div v-else v-html="lang.translateTerm(termDescription)">
                      </div>

                      <template v-if="withLinkShowMore">
                        <template v-for="page in state.collection.pages">
                          <template v-for="url in page.urls">
                            <Link :url="url">
                              <div class="CatalogueCollection--squareDescriptionLink">
                                <br>
                                <Term :term="termShowMore"></Term>
                              </div>
                            </Link>
                          </template>
                        </template>
                      </template>
                      <template v-else>
                        <div class="CatalogueCollection--squareDescriptionLink"
                             @click="() => state.opened = true"
                             v-if="!state.opened && noHTML(lang.translateTerm(termDescription)).length > 230">
                          <br>
                          <Term :term="termShowMore"></Term>
                        </div>
                      </template>
                    </template>
                  </div>

                  <template v-if="withDownloadPDF">
                    <div class="CatalogueCollection--squareDownload"
                         v-for="fileCatalog in state.collection.fileCatalogs"
                         :key="fileCatalog.id"
                         @click="download(fileCatalog)">
                      <div class="CatalogueCollection--squareDownloadText" :style="style_squareDownloadText">
                        télécharger
                      </div>
                      <div class="CatalogueCollection--squareDownloadIcon"
                           :style="style_squareDownloadIcon">pdf
                      </div>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <template v-if="[SCREEN.MOBILE].includes(breakPoint.name)">
            <div class="CatalogueCollection--small">

              <div class="CatalogueCollection--smallPictures">
                <template v-for="(sortphoto, index) in sortPhotos">
                  <template v-for="photo in sortphoto.photos">
                    <template v-for="file in photo.files">
                      <ScrollParallax :distance="withBackground ? 100 : 0" :distanceOversize="false" :reverse="true">
                        <div class="CatalogueCollection--smallPicture CatalogueCollection--smallPicture-1"
                             v-if="index === 0">
                          <Picture :file="file"></Picture>
                          <template v-if="withDownloadPDF">
                            <div class="CatalogueCollection--smallDownload"
                                 v-for="fileCatalog in state.collection.fileCatalogs"
                                 :key="fileCatalog.id"
                                 @click="() => download(fileCatalog)">
                              <div class="CatalogueCollection--squareDownloadText" :style="style_squareDownloadText">
                                télécharger
                              </div>
                              <div class="CatalogueCollection--squareDownloadIcon"
                                   :style="style_squareDownloadIcon">pdf
                              </div>
                            </div>
                          </template>
                        </div>
                      </ScrollParallax>
                      <ScrollParallax :distance="300" :distanceOversize="true" :reverse="true">
                        <div class="CatalogueCollection--smallPicture CatalogueCollection--smallPicture-2"
                             v-if="index === 1">
                          <Picture :file="file"></Picture>
                        </div>
                      </ScrollParallax>
                    </template>
                  </template>
                </template>
              </div>

              <template v-if="withLinkShowMore">
                <template v-for="page in state.collection.pages">
                  <template v-for="url in page.urls">
                    <div class="CatalogueCollection--mobileShowMore">
                      <ButtonLink :url="url" :term="lang.term(492)"></ButtonLink>
                    </div>
                  </template>
                </template>
              </template>

              <template v-for="termDescription in state.collection.termDescriptions" v-if="!withLinkShowMore">
                <div class="CatalogueCollection--smallDescription" v-html="lang.translateTerm(termDescription)"></div>
              </template>

              <div class="CatalogueCollection--smallMaterials" v-if="withMaterials">
                <div class="CatalogueCollection--smallMaterial"
                     v-for="material in state.collection.materials"
                     :key="material.id">
                  <MaterialArticle :material="material">
                  </MaterialArticle>
                </div>
              </div>

              <div class="CatalogueCollection--smallTitles">
                <div class="CatalogueCollection--smallTitleSquare" :style="style_smallTitleSquare"></div>
                <div class="CatalogueCollection--smallTitleCollection" :style="style_smallTitleCollection">Collection
                </div>
                <div class="CatalogueCollection--smallTitleName" :style="stylesmallTitleName"> {{
                    state.collection.value
                  }}
                </div>
              </div>
            </div>
          </template>
        </template>
      </Responsive>
    </Visible>
  </div>
</template>

<script>

// -----

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {Collection} from "../../../@common/delta/database/Collection";
import {apiClient} from "../../service/apiClientService";
import selectCollections from "../../../@common/api/collection/selectCollections";
import {sendError} from "../../function/sendError";
import {getS3PathFromFile} from "../../../@common/function/getS3Path";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {getMaxString} from "../../../@common/function/getMaxString";
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {EVENT} from "../../../@common/constant/EVENT";
import {eventService} from "../../service/eventService";
import {Term} from "../../../@common/delta/database/Term";

// -----

export default {
  props: {
    collection: {
      type: Collection,
      default: () => new Collection
    },
    withDownloadPDF: {
      type: Boolean,
      default: () => true
    },
    withMaterials: {
      type: Boolean,
      default: () => true
    },
    termShowMore: {
      type: Term,
      default: () => new Term
    },
    withLinkShowMore: {
      type: Boolean,
      default: () => false
    },
    onDescription: {
      type: Function,
      default: () => () => {
      }
    },
    withBackground: {
      type: Boolean,
      default: () => true
    }
  },
  data() {
    return {
      SCREEN,
      lang: getLangServiceBrowser(),
      icon: {
        clear: require('@bbx/vector~master/core/assets/svg/material/clear.svg'),
      },
      stateProps: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 1024
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1440
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.stateProps.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.stateProps.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      state: {
        collection: new Collection(this.collection),
        opened: false,
        isVisible: false,
        isVisibleFully: false,
      },
      events: []
    }
  },
  watch: {
    'collection.id': function (id) {
      this.state.collection = new Collection({id: id})
      this.select().catch(sendError)
    },
    'description': function (v) {
    }
  },
  computed: {
    class_root() {
      const classes = []
      if (!this.withBackground) classes.push(`CatalogueCollection-withNoBackground`)
      if (this.stateProps.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`CatalogueCollection-mobile`)
      if (this.stateProps.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`CatalogueCollection-tablet`)
      if (this.stateProps.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`CatalogueCollection-desktop`)
      if (this.stateProps.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`CatalogueCollection-mobileY`)
      if (this.stateProps.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`CatalogueCollection-desktopY`)
      if (this.state.opened) classes.push(`CatalogueCollection-opened`)
      if (this.state.isVisible) classes.push(`CatalogueCollection-isVisible`)
      return classes
    },
    style_big() {
      const style = {}
      for (const colorLight of this.state.collection.colorLights) {
        if (this.withBackground) style.backgroundColor = colorLight.hexadecimal
      }
      return style
    },
    style_squareName() {
      const style = {}
      for (const colorDark of this.state.collection.colorDarks) {
        style.color = colorDark.hexadecimal
      }
      return style
    },
    style_squareWhite() {
      const style = {}
      for (const colorLight of this.state.collection.colorLights) {
        if (!this.withBackground) style.border = `solid 1px ${ colorLight.hexadecimal}`
      }
      return style
    },
    style_close() {
      const style = {}
      for (const colorDark of this.state.collection.colorDarks) {
        style.fill = colorDark.hexadecimal
      }
      return style
    },
    style_squareSubTitle() {
      const style = {}
      for (const colorDark of this.state.collection.colorDarks) {
        style.color = colorDark.hexadecimal
      }
      return style
    },
    style_squareDownloadIcon() {
      const style = {}
      for (const colorDark of this.state.collection.colorDarks) {
        style.backgroundColor = colorDark.hexadecimal
      }
      return style
    },
    style_smallTitleSquare() {
      const style = {}
      for (const colorLight of this.state.collection.colorLights) {
        style.backgroundColor = colorLight.hexadecimal
      }
      return style
    },
    style_smallTitleCollection() {
      const style = {}
      for (const colorDark of this.state.collection.colorDarks) {
        style.color = colorDark.hexadecimal
      }
      return style
    },
    stylesmallTitleName() {
      const style = {}
      for (const colorDark of this.state.collection.colorDarks) {
        style.color = colorDark.hexadecimal
      }
      return style
    },
    style_squareDownloadText() {
      const style = {}
      for (const colorDark of this.state.collection.colorDarks) {
        style.color = colorDark.hexadecimal
      }
      return style
    },
    sortPhotos() {
      return [...this.state.collection.sortPhotos].sort((a, b) => a.order - b.order)
    },
    description() {
      return this.getDescription()
    },
    canDisplay() {
      return !!this.description
    }
  },
  beforeMount() {
    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
    this.select().catch(sendError)
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    getMaxString,
    async select() {
      await Promise.all([
        this.selectCollection()
      ])
    },
    async selectCollection() {
      const {data} = await apiClient.do(selectCollections, selectCollections.with({
        active: true,
        $ids: [this.collection.id],
        $scope: 2
      }))
      this.state.collection = new Collection(data[0])
    },
    noHTML(str) {
      str = str.replace(/(<([^>]+)>)/ig, '');
      return str
    },
    /**
     * @param {File} file
     * @returns void
     */
    download(file) {
      const link = document.createElement('a');
      link.setAttribute('href', getS3PathFromFile(file))
      link.setAttribute('target', '_blank')
      link.click();
    },
    onVisibilityChanged({isVisible, isVisibleFully}) {
      this.state.isVisible = isVisible
      this.state.isVisibleFully = isVisibleFully
    },
    /**
     * Usefull for ScrollFixe component
     * @param onScroll
     */
    registerCallback({onScroll, onResize}) {

      const event1 = new ListenerRegister({
        name: EVENT.SCROLL_CHANGE,
        callback: ({top, left}) => onScroll({top, left})
      })

      const event2 = new ListenerRegister({
        name: EVENT.SIZE_CHANGED,
        callback: () => onResize()
      })

      eventService.register(event1)
      eventService.register(event2)

      this.events.push(event1)
      this.events.push(event2)
    },

    getDescription() {
      let description = ''
      for (const termDescription of this.state.collection.termDescriptions) {
        description = this.noHTML(this.lang.translateTerm(termDescription)).trim()
      }
      this.onDescription(description)
      return description
    }
  }
}
</script>
