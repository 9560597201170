"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Period = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Loan_1 = require("./Loan");
var Deposit_1 = require("./Deposit");
var Period = /** @class */ (function () {
    function Period(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.value = deltaService_1.deltaService.string(props.value, '');
        this.loans = deltaService_1.deltaService.array(props.loans, Loan_1.Loan);
        this.deposits = deltaService_1.deltaService.array(props.deposits, Deposit_1.Deposit);
    }
    return Period;
}());
exports.Period = Period;
