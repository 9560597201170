"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Photo = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var File_1 = require("./File");
var Product_1 = require("./Product");
var Article_1 = require("./Article");
var Material_1 = require("./Material");
var Sort_1 = require("./Sort");
var Position_1 = require("./Position");
var Carousel_1 = require("./Carousel");
var Photo = /** @class */ (function () {
    function Photo(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.cover = deltaService_1.deltaService.boolean(props.cover, false);
        this.full = deltaService_1.deltaService.boolean(props.full, false);
        this.files = deltaService_1.deltaService.array(props.files, File_1.File);
        this.positions = deltaService_1.deltaService.array(props.positions, Position_1.Position);
        this.products = deltaService_1.deltaService.array(props.products, Product_1.Product);
        this.productBarCodes = deltaService_1.deltaService.array(props.productBarCodes, Product_1.Product);
        this.productLabels = deltaService_1.deltaService.array(props.productLabels, Product_1.Product);
        this.articles = deltaService_1.deltaService.array(props.articles, Article_1.Article);
        this.sorts = deltaService_1.deltaService.array(props.sorts, Sort_1.Sort);
        this.materials = deltaService_1.deltaService.array(props.materials, Material_1.Material);
        this.carousels = deltaService_1.deltaService.array(props.carousels, Carousel_1.Carousel);
    }
    return Photo;
}());
exports.Photo = Photo;
