<style scoped lang="scss">

@import "../../scss/size";

.MarqueConception {

}

/*
 * * * * * * * * * *
 *
 *   Second
 *
 * * * * * * * * * *
 */

.MarqueConception--second {
  display: flex;
  align-items: center;
}

.MarqueConception-desktop .MarqueConception--second {

}

.MarqueConception-tablet .MarqueConception--second {

}

.MarqueConception-mobile .MarqueConception--second {
  flex-direction: column;
}

/**/

.MarqueConception--secondRight {
  flex: 1;
  display: flex;
  align-items: center;
}

/**/

.MarqueConception--block-1 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

/**/

.MarqueConception--block-2 {

}

.MarqueConception-desktop .MarqueConception--block-2 {
  max-width: 500px;
}

.MarqueConception-tablet .MarqueConception--block-2 {
  max-width: 400px;
}

.MarqueConception-mobile .MarqueConception--block-2 {
}

/**/

.MarqueConception--block-3 {

}

.MarqueConception-desktop .MarqueConception--block-3 {
  max-width: 400px;
}

.MarqueConception-tablet .MarqueConception--block-3 {
  max-width: 300px;
}

.MarqueConception-mobile .MarqueConception--block-3 {
}

/*
 * * * * * * * * * *
 *
 *   Third
 *
 * * * * * * * * * *
 */

.MarqueConception--third {
  display: flex;
}

.MarqueConception-desktop .MarqueConception--third {
  align-items: center;
}

.MarqueConception-tablet .MarqueConception--third {
  align-items: center;
}

.MarqueConception-mobile .MarqueConception--third {
  flex-direction: column-reverse;
}

/**/

.MarqueConception--thirdLeft {

}

.MarqueConception-desktop .MarqueConception--thirdLeft {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.MarqueConception-tablet .MarqueConception--thirdLeft {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.MarqueConception-mobile .MarqueConception--thirdLeft {
}

/**/

.MarqueConception--thirdRight {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

/*
 * * * * * * * * * *
 *
 *   Four
 *
 * * * * * * * * * *
 */

.MarqueConception--four {
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: 'AmbroiseRegular', sans-serif;
}

.MarqueConception-desktop .MarqueConception--four {
  font-size: 40px;
  max-width: 1000px;
  margin: auto;
}

.MarqueConception-tablet .MarqueConception--four {
  font-size: 30px;
  max-width: 800px;
  margin: auto;
}

.MarqueConception-mobile .MarqueConception--four {
  font-size: 25px;
  padding: 0px 20px 50px 20px;
}

/*
 * * * * * * * * * *
 *
 *   Component
 *
 * * * * * * * * * *
 */

.MarqueConception--spacer {
}

.MarqueConception-desktop .MarqueConception--spacer {
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
}

.MarqueConception-tablet .MarqueConception--spacer {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.MarqueConception-mobile .MarqueConception--spacer {
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
}

/**/

.MarqueConception--title {
  font-family: 'AmbroiseRegular', sans-serif;
  color: #1B1919;
  text-align: center;
}

.MarqueConception-desktop .MarqueConception--title {
  font-size: 50px;
}

.MarqueConception-tablet .MarqueConception--title {
  font-size: 40px;
}

.MarqueConception-mobile .MarqueConception--title {
  font-size: 30px;
  padding: 0 20px;
}

/**/

.MarqueConception--titleBrown {
  font-family: 'CommutersSansBold', sans-serif;
  color: #895B4F;
  text-transform: uppercase;
}

.MarqueConception-desktop .MarqueConception--titleBrown {
  font-size: 14px;
}

.MarqueConception-tablet .MarqueConception--titleBrown {
  font-size: 14px;
}

.MarqueConception-mobile .MarqueConception--titleBrown {
  font-size: 12px;
  text-align: center;
  padding: 0 20px;
}

/**/

.MarqueConception--subTitle {
  font-family: 'AmbroiseRegular', sans-serif;
  color: #1B1919;
}

.MarqueConception-desktop .MarqueConception--subTitle {
  font-size: 50px;
}

.MarqueConception-tablet .MarqueConception--subTitle {
  font-size: 40px;
}

.MarqueConception-mobile .MarqueConception--subTitle {
  font-size: 30px;
  padding: 0 20px;
  text-align: center;
}

/**/

.MarqueConception--subTitle-1 {
}

.MarqueConception-desktop .MarqueConception--subTitle-1 {
  max-width: 400px;
}

.MarqueConception-tablet .MarqueConception--subTitle-1 {
  max-width: 400px;
}

.MarqueConception-mobile .MarqueConception--subTitle-1 {

}

/**/

.MarqueConception--paragraph {
  font-family: 'AeonikRegular', sans-serif;
  letter-spacing: 0.25px;
  line-height: 28px;
  color: #31302F;
}

.MarqueConception-desktop .MarqueConception--paragraph {
  font-size: 18px;
}

.MarqueConception-tablet .MarqueConception--paragraph {
  font-size: 17px;
}

.MarqueConception-mobile .MarqueConception--paragraph {
  font-size: 15px;
  text-align: center;
  padding: 0 20px;
}

/**/

.MarqueConception--photo-1 {
  position: relative;
}

.MarqueConception-desktop .MarqueConception--photo-1 {
  width: 700px;
  height: 800px;
  padding-left: 100px;
}

@media screen and (max-width: $menu-image-screen) {
  .MarqueConception-desktop .MarqueConception--photo-1 {
    width: 600px;
    height: 600px;
  }
}

.MarqueConception-tablet .MarqueConception--photo-1 {
  height: 500px;
  width: 500px;
  padding-left: 100px;
}

.MarqueConception-mobile .MarqueConception--photo-1 {
  width: 100%;
  height: 500px;
  padding: 20px;
  box-sizing: border-box;
}

/**/

.MarqueConception--photo-2 {
  position: absolute;
  bottom: -50px;
  right: -100px;
}

.MarqueConception-desktop .MarqueConception--photo-2 {
  width: 200px;
  height: 250px;
}

.MarqueConception-tablet .MarqueConception--photo-2 {
  display: none;
}

.MarqueConception-mobile .MarqueConception--photo-2 {
  display: none;
}

/**/

.MarqueConception--photo-3 {
}

.MarqueConception-desktop .MarqueConception--photo-3 {
  width: 500px;
  height: 500px;
}

.MarqueConception-tablet .MarqueConception--photo-3 {
  width: 400px;
  height: 400px;
}

.MarqueConception-mobile .MarqueConception--photo-3 {
  width: 100%;
  height: 400px;
  box-sizing: border-box;
  padding: 0 20px;
}

/*
 * * * * * * * * * *
 *
 *   Next
 *
 * * * * * * * * * *
 */

.MarqueConception--next {
  display: flex;
  justify-content: center;
}

.MarqueConception-desktop .MarqueConception--next {

}

.MarqueConception-tablet .MarqueConception--next {
  transform: scale(0.75);
}

.MarqueConception-mobile .MarqueConception--next {
  transform: scale(0.5);
}

/*
 * * * * * * * * * *
 *
 *   Designer
 *
 * * * * * * * * * *
 */

$sizeDesktop: 200px;
$sizeTablet: 100px;
$sizeMobile: 100px;

.MarqueConception--designer {
}

/**/

.MarqueConception--designerContainer {
  background-color: #ECE8E4;
}

.MarqueConception-desktop .MarqueConception--designerContainer {
  margin-left: 100px + $sizeDesktop;
  margin-right: 100px;
  padding: 50px 0 100px 0;
}

.MarqueConception-tablet .MarqueConception--designerContainer {
  margin: 0 100px;
  padding: 40px;
}

.MarqueConception-mobile .MarqueConception--designerContainer {
  margin: $sizeMobile 20px 0 20px;
}

/**/

.MarqueConception--designerBody {
  position: relative;
}

.MarqueConception-desktop .MarqueConception--designerBody {
  left: -$sizeDesktop;
}

.MarqueConception-tablet .MarqueConception--designerBody {
  left: -$sizeTablet;
}

.MarqueConception-mobile .MarqueConception--designerBody {
  top: -$sizeMobile;
}

/**/

.MarqueConception--designerName {
  font-family: 'AmbroiseRegular', sans-serif;
  color: #895B4F;
}

.MarqueConception-desktop .MarqueConception--designerName {
  font-size: 60px;
}

.MarqueConception-tablet .MarqueConception--designerName {
  font-size: 50px;
}

.MarqueConception-mobile .MarqueConception--designerName {
  font-size: 40px;
  text-align: center;
}

.MarqueConception-mobile .MarqueConception-designer-smallText .MarqueConception--designerName {
  font-size: 25px;
}

/**/

.MarqueConception--designerBlock1 {
  display: flex;
}

.MarqueConception-desktop .MarqueConception--designerBlock1 {
  align-items: center;
  align-items: flex-start;
}

.MarqueConception-tablet .MarqueConception--designerBlock1 {
  align-items: flex-start;
}

.MarqueConception-mobile .MarqueConception--designerBlock1 {
  align-items: center;
  flex-direction: column;
}

/**/

.MarqueConception--designerPhoto {

}

.MarqueConception-desktop .MarqueConception--designerPhoto {
  width: 400px;
  height: 400px;
  min-width: 400px;
  min-height: 400px;
}

.MarqueConception-tablet .MarqueConception--designerPhoto {
  width: 300px;
  height: 300px;
  min-width: 300px;
  min-height: 300px;
}

.MarqueConception-mobile .MarqueConception--designerPhoto {
  width: 75%;
  height: 300px;
  min-height: 300px;
}

/**/

.MarqueConception--designerText {
  flex: 1;
}

.MarqueConception-desktop .MarqueConception--designerText {

}

.MarqueConception-tablet .MarqueConception--designerText {

}

.MarqueConception-mobile .MarqueConception--designerText {

}

/**/

.MarqueConception--designerIntro {
  text-transform: uppercase;
}

.MarqueConception-desktop .MarqueConception--designerIntro {
  font-size: 16px;
  padding-right: 200px;
}

.MarqueConception-tablet .MarqueConception--designerIntro {
  font-size: 15px;
  padding-right: 100px;
}

.MarqueConception-mobile .MarqueConception--designerIntro {
  font-size: 14px;
}

/**/

.MarqueConception--designerDescprionTitle {
  text-transform: uppercase;
  font-weight: bold;
  font-family: 'CommutersSansBold', sans-serif;
  position: relative;
}

/**/

.MarqueConception--designerDescprionTitle img {
  position: absolute;
}

.MarqueConception-desktop .MarqueConception--designerDescprionTitle img {
  width: 82px;
  top: -20px;
  left: -41px;
}

.MarqueConception-tablet .MarqueConception--designerDescprionTitle img {
  width: 50px;
  top: -20px;
  left: -41px;
}

.MarqueConception-mobile .MarqueConception--designerDescprionTitle img {
  width: 50px;
  top: -10px;
  left: 50%;
  margin-left: -25px;
}

/**/

.MarqueConception--designerDesciption {

}

.MarqueConception-desktop .MarqueConception--designerDesciption {
  padding-right: 200px;
  padding-bottom: 100px;
}

.MarqueConception-tablet .MarqueConception--designerDesciption {
  padding-right: 100px;
  padding-bottom: 100px;
}

.MarqueConception-mobile .MarqueConception--designerDesciption {

}

/**/

.MarqueConception--designerCollectionTitle {
  font-family: 'AmbroiseRegular', sans-serif;
  text-align: center;
}

.MarqueConception-desktop .MarqueConception--designerCollectionTitle {
  font-size: 40px;
}

.MarqueConception-tablet .MarqueConception--designerCollectionTitle {
  font-size: 35px;
  padding: 0 40px;
}

.MarqueConception-mobile .MarqueConception--designerCollectionTitle {
  font-size: 25px;
  padding: 0 40px;
}

/**/

.MarqueConception--collections {
}

/**/

.MarqueConception--designerCollectionZone {
  display: flex;
  width: 100%;
}

.MarqueConception-designer-last .MarqueConception--designerCollectionZone {
  justify-content: center;
}

/**/

.MarqueConception--collection {

}

.MarqueConception-desktop .MarqueConception--collection {
  width: 28vw;
  min-width: 28vw;
}

.MarqueConception-tablet .MarqueConception--collection {
  width: 40vw;
  min-width: 40vw;
}

.MarqueConception-mobile .MarqueConception--collection {
  width: 60vw;
  min-width: 60vw;
}

.MarqueConception--collection ::v-deep .Link {
  text-decoration: none;
  width: 100%;
  display: flex;
  justify-content: center;
  position: relative;
  flex-direction: column;
  align-items: center;
}

/**/

.MarqueConception--collectionPhoto {
  position: relative;
  overflow: hidden;
}

.MarqueConception--collectionPhoto ::v-deep .Picture {
  transform: scale(1);
  opacity: 1;
  transition: transform 300ms, opacity 300ms;
}

.MarqueConception--collection:hover .MarqueConception--collectionPhoto ::v-deep .Picture {
  transform: scale(0.8);
  opacity: 0.25;
}

.MarqueConception-desktop .MarqueConception--collectionPhoto {
  width: 250px;
  height: 250px;
}

.MarqueConception-tablet .MarqueConception--collectionPhoto {
  width: 200px;
  height: 200px;
}

.MarqueConception-mobile .MarqueConception--collectionPhoto {
  width: 150px;
  height: 150px;
}


/**/

.MarqueConception--collectionCross {
  position: absolute;
  top: 50%;
  left: 50%;
  margin-left: -35px;
  margin-top: -35px;
  width: 71px;
  height: 71px;
  border-radius: 100px;
  border: solid 1px #31302F;
  transform: translateY(250%);
  transition: transform 300ms;
}

.MarqueConception--collection:hover .MarqueConception--collectionCross {
  transform: translateY(0);
}

.MarqueConception--collectionCrossHorizontal {
  position: absolute;
  top: 50%;
  left: 35%;
  width: 30%;
  height: 1px;
  background-color: #31302F;
}

.MarqueConception--collectionCrossVertical {
  position: absolute;
  left: 50%;
  top: 35%;
  width: 1px;
  height: 30%;
  background-color: #31302F;
}

/**/

.MarqueConception--collectionName {
  font-family: 'CommutersSansBold', sans-serif;
  color: #895B4F;
  text-transform: uppercase;
  text-align: center;
}

.MarqueConception-desktop .MarqueConception--collectionName {
  font-size: 16px;
}

.MarqueConception-tablet .MarqueConception--collectionName {
  font-size: 15px;
}

.MarqueConception-mobile .MarqueConception--collectionName {
  font-size: 14px;
}

/**/

.MarqueConception--collectionScroll {
}

.MarqueConception-desktop .MarqueConception--collectionScroll {

}

.MarqueConception-tablet .MarqueConception--collectionScroll {

}

.MarqueConception-mobile .MarqueConception--collectionScroll {
  display: none;
}

.MarqueConception-designer-last .MarqueConception--collectionScroll {
  display: none;
}

.MarqueConception--collectionScrollBarr {
  width: 34%;
  margin: auto;
  overflow-x: scroll;
  overflow-y: hidden;
}

.MarqueConception--collectionScrollBarr::-webkit-scrollbar {
  width: 6px;
  height: 6px;
  cursor: pointer;
}

.MarqueConception--collectionScrollBarr::-webkit-scrollbar-track {
  background-color: #E4E4E4;
}

.MarqueConception--collectionScrollBarr::-webkit-scrollbar-thumb {
  background-color: #895B4F;
}

.MarqueConception--collectionScrollContent {
  width: 300%;
  min-width: 300%;
  height: 1px;
}

</style>

<template>
  <div class="MarqueConception" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">

      <MarqueHeader :term="lang.term(800)" :imgSrc="img.header"></MarqueHeader>

      <MarqueTab :tab="state.tab" :tabs="state.tabs" :onChange="tab => onChangeTab(tab)"></MarqueTab>

      <div class="MarqueConception--spacer"></div>
      <div class="MarqueConception--spacer"></div>
      <div class="MarqueConception--spacer"></div>

      <div class="MarqueConception--second" data-id="spirit">

        <div class="MarqueConception--photo-1">
          <Picture :src="img.photo1"></Picture>
          <div class="MarqueConception--photo-2">
            <ScrollParallax :distance="100">
              <Picture :src="img.photo2"></Picture>
            </ScrollParallax>
          </div>
        </div>

        <div class="MarqueConception--secondRight">
          <div class="MarqueConception--block-1">
            <div class="MarqueConception--block-2">
              <div class="MarqueConception--titleBrown">
                <AnimationTitle>
                  <Term :term="lang.term(801)"></Term>
                </AnimationTitle>
              </div>
              <div class="MarqueConception--spacer"></div>
              <div class="MarqueConception--subTitle MarqueConception--subTitle-1">
                <AnimationTitle>
                  <Term :term="lang.term(802)" tag="h3"></Term>
                </AnimationTitle>
              </div>
              <div class="MarqueConception--spacer"></div>
              <div class="MarqueConception--paragraph">
                <Term type="textarea" :term="lang.term(803)"></Term>
              </div>
            </div>
          </div>
        </div>
      </div>

      <div class="MarqueConception--spacer"></div>
      <div class="MarqueConception--spacer"></div>
      <div class="MarqueConception--spacer"></div>

      <div class="MarqueConception--spacer"></div>
      <div class="MarqueConception--spacer"></div>
      <div class="MarqueConception--spacer"></div>

      <div class="MarqueConception--spacer"></div>
      <div class="MarqueConception--spacer"></div>
      <div class="MarqueConception--spacer"></div>

      <div class="MarqueConception--four">
        <AnimationTitle>
          <Term :term="lang.term(804)"></Term>
        </AnimationTitle>
      </div>

      <div class="MarqueConception--spacer"></div>
      <div class="MarqueConception--spacer"></div>
      <div class="MarqueConception--spacer"></div>

      <div class="MarqueConception--spacer"></div>
      <div class="MarqueConception--spacer"></div>
      <div class="MarqueConception--spacer"></div>

      <div class="MarqueConception--third" data-id="design">
        <div class="MarqueConception--thirdLeft">
          <div class="MarqueConception--block-3">
            <div class="MarqueConception--titleBrown">
              <AnimationTitle>
                <Term :term="lang.term(805)"></Term>
              </AnimationTitle>
            </div>
            <div class="MarqueConception--spacer"></div>
            <div class="MarqueConception--subTitle">
              <AnimationTitle>
                <Term :term="lang.term(806)" tag="h3"></Term>
              </AnimationTitle>
            </div>
            <div class="MarqueConception--spacer"></div>
            <div class="MarqueConception--paragraph">
              <Term type="textarea" :term="lang.term(807)"></Term>
            </div>
          </div>
        </div>

        <div class="MarqueConception--spacer"></div>
        <div class="MarqueConception--spacer"></div>
        <div class="MarqueConception--spacer"></div>

        <div class="MarqueConception--spacer"></div>
        <div class="MarqueConception--spacer"></div>
        <div class="MarqueConception--spacer"></div>

        <div class="MarqueConception--thirdRight">
          <div class="MarqueConception--photo-3">
            <ScrollParallax :distance="50" :reverse="false">
              <Picture :src="img.photo3"></Picture>
            </ScrollParallax>
          </div>
        </div>
      </div>

      <div class="MarqueConception--spacer"></div>
      <div class="MarqueConception--spacer"></div>
      <div class="MarqueConception--spacer"></div>

      <div class="MarqueConception--spacer"></div>
      <div class="MarqueConception--spacer"></div>
      <div class="MarqueConception--spacer"></div>

      <div class="MarqueConception--designer" :class="class_designer(designer)" v-for="designer in state.designers">

        <div class="MarqueConception--designerContainer">
          <div class="MarqueConception--designerBody">
            <div class="MarqueConception--designerName">
              <AnimationTitle>
                <Term :term="designer.termName"></Term>
              </AnimationTitle>
            </div>
            <div class="MarqueConception--spacer"></div>
            <div class="MarqueConception--designerBlock1">
              <div class="MarqueConception--designerPhoto">
                <Picture :src="designer.img"></Picture>
              </div>

              <div class="MarqueConception--spacer"></div>
              <div class="MarqueConception--spacer"></div>
              <div class="MarqueConception--spacer"></div>

              <div class="MarqueConception--spacer"></div>
              <div class="MarqueConception--spacer"></div>
              <div class="MarqueConception--spacer"></div>

              <div class="MarqueConception--designerText">
                <div class="MarqueConception--paragraph MarqueConception--designerIntro">
                  <Term :term="designer.termIntro"></Term>
                </div>
                <div class="MarqueConception--spacer"></div>
                <div class="MarqueConception--spacer"></div>
                <div class="MarqueConception--spacer"></div>
                <div class="MarqueConception--paragraph MarqueConception--designerDescprionTitle">
                  <img :src="img.paragraph" alt="">
                  <div style="position: relative">
                    <Term :term="lang.term(808)"></Term>
                    &ensp;
                    <Term :term="designer.termName"></Term>
                  </div>
                </div>
                <div class="MarqueConception--spacer"></div>
                <div class="MarqueConception--paragraph MarqueConception--designerDesciption">
                  <Term :term="designer.termDescription"></Term>
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="MarqueConception--designerCollection">
          <div class="MarqueConception--spacer"></div>
          <div class="MarqueConception--spacer"></div>
          <div class="MarqueConception--spacer"></div>
          <div class="MarqueConception--designerCollectionTitle">
            <Term :term="lang.term(809)"></Term>
            &ensp;
            <Term :term="designer.termName"></Term>
          </div>
          <div class="MarqueConception--spacer"></div>
          <div class="MarqueConception--spacer"></div>
          <div class="MarqueConception--spacer"></div>

          <div class="MarqueConception--collections">
            <ScrollZone :registerCallback="(p) => scrollZoneRegisterCallback(designer, p)">
              <div class="MarqueConception--designerCollectionZone">
                <div v-for="collection in designer.collections" class="MarqueConception--collection">
                  <Link :href="collection.href">
                    <div class="MarqueConception--spacer"></div>
                    <div class="MarqueConception--spacer"></div>
                    <div class="MarqueConception--spacer"></div>
                    <div class="MarqueConception--collectionPhoto">
                      <Picture :display="PICTURE_DISPLAY.AUTO" :src="collection.img"></Picture>
                      <div class="MarqueConception--collectionCross">
                        <div class="MarqueConception--collectionCrossHorizontal"></div>
                        <div class="MarqueConception--collectionCrossVertical"></div>
                      </div>
                    </div>
                    <div class="MarqueConception--spacer"></div>
                    <div class="MarqueConception--spacer"></div>
                    <div class="MarqueConception--spacer"></div>
                    <div class="MarqueConception--collectionName">{{ collection.name }}</div>
                  </Link>
                </div>
              </div>
            </ScrollZone>
          </div>

          <div class="MarqueConception--spacer"></div>
          <div class="MarqueConception--spacer"></div>
          <div class="MarqueConception--spacer"></div>
          <div class="MarqueConception--spacer"></div>
          <div class="MarqueConception--spacer"></div>

          <div class="MarqueConception--collectionScroll">
            <div class="MarqueConception--collectionScrollBarr" @scroll="($event) => onScroll($event, designer)">
              <div class="MarqueConception--collectionScrollContent"></div>
            </div>
          </div>
        </div>

        <div class="MarqueConception--spacer"></div>
        <div class="MarqueConception--spacer"></div>
        <div class="MarqueConception--spacer"></div>
        <div class="MarqueConception--spacer"></div>
        <div class="MarqueConception--spacer"></div>
      </div>


      <div class="MarqueConception--next">
        <MarqueNext :term="lang.term(810)" :url="next"></MarqueNext>
      </div>

      <CountryLocation :alternate="true">
        <template slot="default">
          <HomeSpot :nuxtProp="nuxtProp"
                    :breakPoints="props.Responsive.breakPoints"
                    :verticalBreakPoints="props.Responsive.verticalBreakPoints">
          </HomeSpot>
        </template>
        <template slot="alternate">
          <div class="MarqueConception--spacer"></div>
          <div class="MarqueConception--spacer"></div>
          <div class="MarqueConception--spacer"></div>
          <div class="MarqueConception--spacer"></div>
          <div class="MarqueConception--spacer"></div>
          <div class="MarqueConception--spacer"></div>
        </template>
      </CountryLocation>
    </Responsive>
  </div>
</template>

<script>

// -----

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {eventService} from "../../service/eventService";
import {getUrlByProjectUrlId} from "../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../@common/constant/PROJECT_URL";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {scrollerParentService} from "../../service/scrollerParentService";
import {PICTURE_DISPLAY} from "../../../@common/constant/PICTURE_DISPLAY";

// -----

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    const lang = getLangServiceBrowser()
    return {
      PICTURE_DISPLAY,
      lang,
      img: {
        header: require('../../../@common/assets/marque/conception/header.jpg'),
        photo1: require('../../../@common/assets/marque/conception/photo1.png'),
        photo2: require('../../../@common/assets/marque/conception/photo2.png'),
        photo3: require('../../../@common/assets/marque/conception/photo3.jpg'),
        paragraph: require('../../../@common/assets/marque/conception/paragraph.png'),
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              width: 0
            }),
            new BreakPoint({
              name: SCREEN.TABLET,
              width: 1024
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              width: 1440
            }),
          ],
          verticalBreakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              height: 0
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              height: 600
            }),
          ],
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      state: {
        tab: {
          id: 'spirit',
          term: lang.term(811),
        },
        tabs: [
          {
            id: 'spirit',
            term: lang.term(812),
          },
          {
            id: 'design',
            term: lang.term(813),
          },
        ],
        designers: [
          {
            id: 1,
            img: require('../../../@common/assets/marque/conception/designer1.jpg'),
            termName: lang.term(814),
            termIntro: lang.term(815),
            termDescription: lang.term(816),
            collections: [
              {
                img: require('../../../@common/assets/marque/conception/collection/bastingage.png'),
                name: 'Bastingage',
                href: `/${lang.lang.iso6391}/collection-bastingage`
              },
              {
                img: require('../../../@common/assets/marque/conception/collection/skall.png'),
                name: 'Skaal',
                href: `/${lang.lang.iso6391}/collection-skaal`
              },
              {
                img: require('../../../@common/assets/marque/conception/collection/koton.png'),
                name: 'Koton',
                href: `/${lang.lang.iso6391}/collection-koton`
              },
              {
                img: require('../../../@common/assets/marque/conception/collection/teckalu.png'),
                name: 'Teckalu',
                href: `/${lang.lang.iso6391}/collection-teckalu`
              },
              {
                img: require('../../../@common/assets/marque/conception/collection/tradition.png'),
                name: 'Tradition',
                href: `/${lang.lang.iso6391}/collection-tradition`
              },
            ],
          },
          {
            id: 2,
            img: require('../../../@common/assets/marque/conception/designer2.jpg'),
            termName: lang.term(817),
            termIntro: lang.term(818),
            termDescription: lang.term(819),
            collections: [
              {
                img: require('../../../@common/assets/marque/conception/collection/copenhague.png'),
                name: 'Copenhague',
                href: `/${lang.lang.iso6391}/collection-copenhague`
              },
              {
                img: require('../../../@common/assets/marque/conception/collection/sagamore.png'),
                name: 'Sagamore',
                href: `/${lang.lang.iso6391}/collection-sagamore`
              },
              {
                img: require('../../../@common/assets/marque/conception/collection/corner.png'),
                name: 'Corner',
                href: `/${lang.lang.iso6391}/collection-corner`
              },
              {
                img: require('../../../@common/assets/marque/conception/collection/metro.png'),
                name: 'Métro',
                href: `/${lang.lang.iso6391}/collection-metro`
              },
            ],
            smallText: true
          },
          {
            id: 3,
            img: require('../../../@common/assets/marque/conception/designer3.jpg'),
            termName: lang.term(820),
            termIntro: lang.term(821),
            termDescription: lang.term(822),
            collections: [
              {
                img: require('../../../@common/assets/marque/conception/collection/selva.png'),
                name: 'Selva',
                href: `/${lang.lang.iso6391}/collection-selva`
              },
              {
                img: require('../../../@common/assets/marque/conception/collection/osmoz.png'),
                name: 'Osmoz',
                href: `/${lang.lang.iso6391}/collection-osmoz`
              },
            ],
            smallText: true,
            last: true
          },
        ],
        scrollTo: {}
      },
      events: []
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`MarqueConception-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`MarqueConception-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`MarqueConception-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`MarqueConception-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`MarqueConception-desktopY`)
      return classes
    },
    next() {
      return getUrlByProjectUrlId(this.nuxtProp, PROJECT_URL.MARQUE_NOS_ENGAGEMENT)
    }
  },
  beforeMount() {
    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    onChangeTab(tab) {
      this.state.tab = tab
      scrollerParentService.scrollToElement(`div[data-id="${tab.id}"]`, v => v - 125)
    },
    class_designer(designer) {
      const classes = []
      if (designer.last) classes.push(`MarqueConception-designer-last`)
      if (designer.smallText) classes.push(`MarqueConception-designer-smallText`)
      return classes
    },
    onScroll($event, designer) {
      // console.log($event.target.scrollLeft, designer.id)

      this.state.scrollTo[designer.id]({
        left: $event.target.scrollLeft * 1.5,
        top: 0,
      })
    },
    scrollZoneRegisterCallback(designer, {scrollTo}) {
      this.state.scrollTo[designer.id] = (props) => scrollTo(props)
    }
  }
}
</script>