<style scoped>
.AdminContext {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 0;
}

.AdminContext--toggle {
  cursor: pointer;
  width: 40px;
  height: 40px;
  background: #fff;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.15);
  position: absolute;
  bottom: 0;
  right: -40px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AdminContext--toggle ::v-deep .Vector {
  width: 18px;
  height: 18px;
}

.AdminContext--toggle ::v-deep .Vector svg {
  fill: #000;
}

.AdminContext--modal {
  padding: 50px;
}

</style>

<template>
  <div class="AdminContext App--zindex" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange">
      <div>
        <Modal :opened="state.opened" :onOpenedChange="v => state.opened = v" position="left">
          <div class="AdminContext--modal">


            <div>
              <h2>
                <Term :term="lang.term(101)"></Term>
              </h2>
              <br>
              <InputLabel :term="lang.term(213)"></InputLabel>
              <br>
              <InputSwitch :value="state.textEditor"
                           :onChangeValue="v => state.textEditor = v">
              </InputSwitch>
              <br>
              <Divider></Divider>
              <br>
            </div>


            <div>
              <h2>
                <Term :term="lang.term(431)"></Term>
              </h2>
              <br>
              <InputLabel :term="lang.term(432)"></InputLabel>
              <br>
              <InputSwitch :value="state.simulator"
                           :onChangeValue="v => state.simulator = v">
              </InputSwitch>
              <br>
              <Divider></Divider>
              <br>
            </div>


            <div v-if="[ROLE.ROOT, ROLE.ADMIN].includes(state.roleId) && state.opened && isHome">
              <AdminContextCarousel></AdminContextCarousel>
              <br>
              <Divider></Divider>
              <br>
            </div>


            <div v-if="[ROLE.ROOT, ROLE.ADMIN].includes(state.roleId) && state.opened && isHome">
              <AdminContextHighLight></AdminContextHighLight>
              <br>
              <Divider></Divider>
              <br>
            </div>


            <div v-show="state.hasPage">
              <h2>
                <Term :term="lang.term(208)"></Term>
              </h2>
              <br>
              <Form>
                <div v-for="term in state.page.termTitles" :key="`termTitles${term.id}`">
                  <InputTerm :label="lang.term(210)"
                             :error="state.form.display('termTitles')"
                             :term="term" :onChangeTerm="v => term = v"></InputTerm>
                </div>
                <br>
                <div v-for="term in state.page.termDescriptions" :key="`termDescriptions${term.id}`">
                  <InputTerm :label="lang.term(211)"
                             :error="state.form.display('termDescriptions')"
                             :term="term" :onChangeTerm="v => term = v"></InputTerm>
                </div>
                <br>
                <div v-for="term in state.page.termH1s" :key="`termH1s${term.id}`">
                  <InputTerm :label="lang.term(212)"
                             :error="state.form.display('termH1s')"
                             :term="term" :onChangeTerm="v => term = v"></InputTerm>
                </div>
                <br>
                <InputLabel :term="lang.term(209)"></InputLabel>
                <br>
                <InputSwitch :value="state.page.indexed"
                             :onChangeValue="v => state.page.indexed = v">
                </InputSwitch>
                <br>
                <br>
                <ButtonAction :term="lang.term(207)" :onClick="() => save()" :loading="state.loading"></ButtonAction>
              </Form>
              <br>
              <Divider></Divider>
              <br>
            </div>

          </div>
        </Modal>

        <div class="AdminContext--toggle" @click="() => state.opened = !state.opened">
          <Vector :svg="icon.settings"></Vector>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint.ts';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN.ts';
import {ListenerTrigger} from '@bbx/listener~master/core/delta/ListenerTrigger.ts'
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";

import {eventService} from '../../service/eventService'
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser.ts";

import {EVENT} from "../../../@common/constant/EVENT";
import {Page} from "../../../@common/delta/database/Page";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {urlService} from "../../../@common/service/urlService";
import {apiClient} from "../../service/apiClientService";
import selectPages from "../../../@common/api/page/selectPages";
import {Term} from "../../../@common/delta/database/Term";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {controlForm} from "../../function/controlForm";
import create from "../../../@common/api/page/createPage";
import {canDisplay, getCountry, setCountry} from "../../service/countryService";
import {COUNTRY_CODE} from "../../../@common/constant/LANG";
import {sendError} from "../../function/sendError";
import {STATE_ROLE_ID, stateService} from "../../service/stateService";
import {ROLE} from "../../../@common/constant/ROLE";
import {isHome} from "../../../@common/function/isHome";

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      SCREEN,
      ROLE,
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
          },
        },
      },
      icon: {
        clear: require('@bbx/vector~master/core/assets/svg/material/clear.svg'),
        settings: require('@bbx/vector~master/core/assets/svg/material/settings.svg'),
      },
      state: {
        /**
         * @type number
         */
        roleId: 0,
        country: '',
        opened: false,
        loading: false,
        textEditor: false,
        simulator: false,
        hasPage: false,
        page: new Page(),
        form: new ApiResponseForm()
      },
      events: []
    }
  },
  watch: {
    'state.textEditor': function (value) {
      eventService.triggerByName(new ListenerTrigger({
        name: EVENT.TERM_ACTIVATED,
        payload: value
      }))
    },
    'nuxtProp.page.id': function () {
      // this.select().catch(sendError)
    },
    'state.opened': function () {
      this.init().catch(sendError)
    },
    'state.simulator': function (v) {
      setCountry(v ? COUNTRY_CODE.FR : 'US')
    }
  },
  computed: {
    class_root() {
      const classes = [];
      if (this.state.opened) classes.push(`AdminContext-opened`)
      return classes;
    },
    isHome() {
      return isHome(this.nuxtProp)
    }
  },
  beforeMount() {

    // -----

    this.state.country = getCountry()
    this.state.simulator = canDisplay(this.state.country)

    // -----

    this.state.roleId = stateService.get(STATE_ROLE_ID)
    stateService.watch(STATE_ROLE_ID, v => this.state.roleId = v)

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.CHANGE_COUNTRY_LOCATION,
      callback: (country) => {
        this.state.country = country
        this.state.simulator = canDisplay(country)
      }
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    async init() {
      await this.select()
    },

    async select() {
      const {data} = await apiClient.do(selectPages, selectPages.with({$ids: [this.nuxtProp.page.id]}))
      const page = data.pop()
      this.state.hasPage = !!page
      if (this.state.hasPage) {
        this.state.page = new Page(page);
        if (!this.state.page.termTitles.length) this.state.page.termTitles.push(new Term())
        if (!this.state.page.termDescriptions.length) this.state.page.termDescriptions.push(new Term())
        if (!this.state.page.termH1s.length) this.state.page.termH1s.push(new Term())
      }
    },

    async save() {
      if (!this.state.hasPage) return

      const payload = new Page(this.state.page)

      await controlForm({
        apiAction: create,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loading',
        loaderState: this.state,
        payload: payload,
        vue: this,
        message: this.lang.translate(214)
      });

      await this.select();
    },
    /**
     * @param {Page} page
     */
    getPath(page) {
      return urlService.getUrl(this.lang.lang.iso6391, page.paths.map(path => path.id))
    }
  }
}
</script>
