import {deltaService} from "@bbx/delta~master/core/service/deltaService"
import {TransportCost} from "./TransportCost";
import {Article} from "./Article";
import {DeepPartial} from "../../type/DeepPartial";


export type Transport_constructor_props = DeepPartial<Transport>

export class  Transport {

    id: number
    active: boolean
    createdAt: string
    updatedAt: string

    description: string

    transportCosts: TransportCost[]
    articles: Article[]

    constructor(props: Transport_constructor_props = {}) {
                this.id = deltaService.number(props.id, 0)
        this.active = deltaService.boolean(props.active, true)
        this.createdAt = deltaService.string(props.createdAt, '')
        this.updatedAt = deltaService.string(props.updatedAt, '')

        this.description = deltaService.string(props.description, '')

        this.transportCosts = deltaService.array(props.transportCosts, TransportCost)
        this.articles = deltaService.array(props.articles, Article)
    }
}
