<style scoped lang="scss">

@import "../../scss/size";

.HomeSpot {
}

.HomeSpot-desktop {
  padding-top: 100px;
  padding-bottom: 100px;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeSpot-desktop {
    transform: scale(0.85);
  }
}

.HomeSpot-tablet {
  padding-top: 100px;
  padding-bottom: 100px;
}

.HomeSpot-mobile {
  padding-bottom: 40px;
}

/**/

.HomeSpot--plus {
  background: white;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  flex-wrap: wrap;
}

.HomeSpot-desktop .HomeSpot--plus {
  padding: 0 100px;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeSpot-desktop .HomeSpot--plus {
    padding: 0;
  }
}

.HomeSpot-tablet .HomeSpot--plus {
}

.HomeSpot-mobile .HomeSpot--plus {
  flex-direction: column;
}

/**/

.HomeSpot--plusItemContent {
  height: 100%;
  padding: 25px 0;
  box-sizing: border-box;
}

.HomeSpot-desktop .HomeSpot--plusItemContent {
  width: 20%;
}

.HomeSpot-tablet .HomeSpot--plusItemContent {
  width: 33.33%;
}

.HomeSpot-mobile .HomeSpot--plusItemContent {
  width: 100%;
}

.HomeSpot--plusItem {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 100%;
  min-height: 100%;
  /*background: rgba(0, 0, 0, 0.03);*/
  box-sizing: border-box;
}

.HomeSpot--plusIcon {
  width: 37px;
  height: 37px;
  margin-bottom: 25px;
}

.HomeSpot--plusIcon img {
  display: block;
  width: 100%;
}

.HomeSpot--plusIcon * ::v-deep .Vector svg {
  fill: #8A6C59;
}

.HomeSpot--plusIcon-1 {

}

.HomeSpot--plusIcon-2 ::v-deep svg {
  height: 47px;
  position: relative;
  left: -5px;
  top: -4px;
}

.HomeSpot--plusIcon-3 ::v-deep svg {
  height: 40px;
  position: relative;
  left: 0px;
  top: 0px;
}

.HomeSpot--plusIcon-4 ::v-deep svg {
  height: 40px;
  position: relative;
  left: 0px;
  top: 0px;
}

.HomeSpot--plusIcon-5 ::v-deep svg {
  height: 38px;
  position: relative;
  left: -5px;
  top: 0px;
}

/**/

.HomeSpot--plusTitle {
  text-transform: uppercase;
  font-family: 'AeonikMedium', sans-serif;
  letter-spacing: 1px;
  margin-bottom: 10px;
  font-size: 18px;
  text-align: center;
}

.HomeSpot--plusInfo {
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  text-align: center;
  font-family: 'AeonikMedium', sans-serif;
}

.HomeSpot--plusInfo ::v-deep .Term--text:first-letter {
  text-transform: uppercase;
}
</style>

<template>
  <footer class="HomeSpot" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
<!--      <ScrollParallax :distance="50">-->
        <div class="HomeSpot--plus">


          <div class="HomeSpot--plusItemContent">
            <div class="HomeSpot--plusItem">
              <div class="HomeSpot--plusIcon HomeSpot--plusIcon-1">
                <img src="../../../@common/assets/home/spot/icon-waranty.png">
              </div>
              <div class="HomeSpot--plusTitle">
                <Term :term="lang.term(247)"/>
              </div>
              <div class="HomeSpot--plusInfo">
                <Term :term="lang.term(248)"/>
              </div>
            </div>
          </div>


          <div class="HomeSpot--plusItemContent">
            <div class="HomeSpot--plusItem">
              <div class="HomeSpot--plusIcon HomeSpot--plusIcon-2">
                <Vector :svg="icon.local_shipping"></Vector>
              </div>
              <div class="HomeSpot--plusTitle">
                <Term :term="lang.term(546)"/>
              </div>
              <div class="HomeSpot--plusInfo">
                <Term :term="lang.term(547)"/>
              </div>
            </div>
          </div>


          <div class="HomeSpot--plusItemContent">
            <div class="HomeSpot--plusItem">
              <div class="HomeSpot--plusIcon HomeSpot--plusIcon-3">
                <Vector :svg="icon.calling"></Vector>
              </div>
              <div class="HomeSpot--plusTitle">
                <Term :term="lang.term(249)"/>
              </div>
              <div class="HomeSpot--plusInfo">
                <Term :term="lang.term(250)"/>
              </div>
              <div class="HomeSpot--plusInfo">
                <Term :term="lang.term(684)"></Term>
              </div>
              <div class="HomeSpot--plusInfo">
                <Term :term="lang.term(251)"/>
              </div>
            </div>
          </div>


          <div class="HomeSpot--plusItemContent">
            <div class="HomeSpot--plusItem">
              <div class="HomeSpot--plusIcon HomeSpot--plusIcon-4">
                <Vector :svg="icon.lock"></Vector>
              </div>
              <div class="HomeSpot--plusTitle">
                <Term :term="lang.term(252)"/>
              </div>
              <div class="HomeSpot--plusInfo">
                <Term :term="lang.term(253)"/>
              </div>
            </div>
          </div>


          <div class="HomeSpot--plusItemContent">
            <div class="HomeSpot--plusItem">
              <div class="HomeSpot--plusIcon HomeSpot--plusIcon-5">
                <Vector :svg="icon.credit_card"></Vector>
              </div>
              <div class="HomeSpot--plusTitle">
                <Term :term="lang.term(685)"></Term>
              </div>
              <div class="HomeSpot--plusInfo">
                <Term :term="lang.term(686)"></Term>
              </div>
            </div>
          </div>


        </div>
<!--      </ScrollParallax>-->
    </Responsive>
  </footer>
</template>

<script>
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    },
    breakPoints: {
      type: Array,
      default: () => ([])
    },
    verticalBreakPoints: {
      type: Array,
      default: () => ([])
    },
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      icon: {
        calling: require('../../../@common/assets/home/spot/phone-icon.svg'),
        lock: require('../../../@common/assets/home/spot/cadenas-icon.svg'),
        credit_card: require('../../../@common/assets/home/spot/paiement-icon.svg'),
        local_shipping: require('../../../@common/assets/home/spot/shipped.svg'),
      },
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: this.breakPoints,
          verticalBreakPoints: this.verticalBreakPoints,
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      state: {},
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`HomeSpot-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`HomeSpot-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`HomeSpot-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`HomeSpot-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`HomeSpot-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
