<style scoped>

.Tab {

}

.Tab--header {
}

.Tab--header {
  display: flex;
}
</style>

<template>
  <div class="Tab" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <div class="Tab--header">
        <TabHeader :currentId="state.currentId" :getId="getId" :items="headers" :onClick="onClick">
          <template v-slot:default="{data}">
            <slot name="header" :data="data"></slot>
          </template>
        </TabHeader>
      </div>
      <div class="Tab--body">
        <TabBody :currentId="state.currentId" :getId="getId" :items="bodies">
          <template v-slot:default="{data}">
            <slot name="body" :data="data"></slot>
          </template>
        </TabBody>
      </div>
    </Responsive>
  </div>
</template>

<script>

// -----

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {eventService} from "../../service/eventService";

// -----

export default {
  props: {
    currentId: {
      type: Number,
      default: () => 0
    },
    getId: {
      type: Function,
      default: (item) => item.id
    },
    headers: {
      type: Array,
      default: () => ([])
    },
    bodies: {
      type: Array,
      default: () => ([])
    },
    onCurrentIdChange: {
      type: Function,
      default: () => () => {

      }
    }
  },
  data() {
    return {
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              width: 0
            }),
            new BreakPoint({
              name: SCREEN.TABLET,
              width: 1024
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              width: 1440
            }),
          ],
          verticalBreakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              height: 0
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              height: 600
            }),
          ],
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      state: {
        currentId: this.getDefaultCurrentId()
      },
      events: []
    }
  },
  watch: {
    'currentId': {
      handler(v) {
        this.state.currentId = v
      }
    },
    'state.currentId': {
      handler(v) {
        this.onCurrentIdChange(v)
      }
    }
  },
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`Tab-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`Tab-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`Tab-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`Tab-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`Tab-desktopY`)
      return classes
    }
  },
  beforeMount() {
    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    getDefaultCurrentId() {
      return this.getId(this.headers[0] || {})
    },
    onClick(item) {
      this.state.currentId = this.getId(item)
    }
  }
}
</script>