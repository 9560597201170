import cloneDeep from 'lodash/cloneDeep'

export function uniqBy<T>(values: T[], uniqAttr: string): T[] {
    let uniqs: any[] = []
    let results: any[] = []
    let clonedValues = cloneDeep(values)
    for (const clonedValue of clonedValues) {
        if (!uniqs.includes(clonedValue[uniqAttr])) {
            uniqs.push(clonedValue[uniqAttr])
            results.push(clonedValue)
        }
    }
    return results
}