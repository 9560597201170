<style scoped lang="scss">

@import "../../scss/color";

.HomeSpeach {
}

.HomeSpeach-desktop {
  padding: (225px - 100px) 0px (225px - 18px) 0px;
}

.HomeSpeach-tablet {
  padding: 100px 0px 200px 0px;
}

.HomeSpeach-mobile {
  padding: 50vw 20px 100px 20px;
}

.HomeSpeach--term {
  font-family: 'AmbroiseRegular', sans-serif;
  color: #31302F;
}

.HomeSpeach-desktop .HomeSpeach--term {
  font-size: 45px;
  line-height: 68px;
  text-align: center;
}

.HomeSpeach-tablet .HomeSpeach--term {
  font-size: 42px;
  line-height: 68px;
  text-align: center;
}

.HomeSpeach-mobile .HomeSpeach--term {
  font-size: 30px;
  line-height: 40px;
  text-align: center;
}

.HomeSpeach--animation ::v-deep .AnimationTitle {
  overflow: initial;
}

.HomeSpeach--animation ::v-deep .AnimationTitle--content {
  opacity: 0;
}

.HomeSpeach--animation ::v-deep .AnimationTitle-isVisible .AnimationTitle--content {
  transform: translateY(0);
  opacity: 1;
}

/**/

.HomeSpeach--top ::v-deep .AnimationTitle--content {
  transform: translateX(-100px);
}

.HomeSpeach--bottom ::v-deep .AnimationTitle--content {
  transform: translateX(100px);
}
</style>

<template>
  <div class="HomeSpeach" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <div class="HomeSpeach--term">
        <ScrollParallax :distance="50">
          <template v-if="[SCREEN.DESKTOP, SCREEN.TABLET].includes(props.Responsive.breakPoint.name)">
            <div class="HomeSpeach--animation HomeSpeach--top" :key="1">
              <AnimationTitle>
                <Term
                    :term="lang.term(677)"></Term>
              </AnimationTitle>
            </div>
            <div class="HomeSpeach--animation HomeSpeach--bottom" :key="2">
              <AnimationTitle>
                <Term
                    :term="lang.term(678)"></Term>
              </AnimationTitle>
            </div>
          </template>
          <template v-else>
            <div class="HomeSpeach--animation HomeSpeach--top" :key="3">
              <AnimationTitle>
                <Term
                    :term="lang.term(679)"></Term>
              </AnimationTitle>
            </div>
            <div class="HomeSpeach--animation HomeSpeach--bottom" :key="4">
              <AnimationTitle>
                <Term
                    :term="lang.term(680)"></Term>
              </AnimationTitle>
            </div>
            <div class="HomeSpeach--animation HomeSpeach--top" :key="5">
              <AnimationTitle>
                <Term
                    :term="lang.term(681)"></Term>
              </AnimationTitle>
            </div>
            <div class="HomeSpeach--animation HomeSpeach--bottom" :key="6">
              <AnimationTitle>
                <Term
                    :term="lang.term(682)"></Term>
              </AnimationTitle>
            </div>
            <div class="HomeSpeach--animation HomeSpeach--top" :key="7">
              <AnimationTitle>
                <Term
                    :term="lang.term(683)"></Term>
              </AnimationTitle>
            </div>
          </template>
        </ScrollParallax>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    },
    breakPoints: {
      type: Array,
      default: () => ([])
    },
    verticalBreakPoints: {
      type: Array,
      default: () => ([])
    },
  },
  data() {
    return {
      SCREEN,
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: this.breakPoints,
          verticalBreakPoints: this.verticalBreakPoints,
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`HomeSpeach-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`HomeSpeach-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`HomeSpeach-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`HomeSpeach-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`HomeSpeach-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
