"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.CATEGORY_IMG = void 0;
var CATEGORY_1 = require("./CATEGORY");
exports.CATEGORY_IMG = (_a = {},
    _a[CATEGORY_1.CATEGORY.LUMINAIRE] = require('../assets/database/category/png/1.png'),
    _a[CATEGORY_1.CATEGORY.TABLE] = require('../assets/database/category/png/2.png'),
    _a[CATEGORY_1.CATEGORY.CHAISE] = require('../assets/database/category/png/3.png'),
    _a[CATEGORY_1.CATEGORY.TABOURET] = require('../assets/database/category/png/4.png'),
    _a[CATEGORY_1.CATEGORY.FAUTEUIL] = require('../assets/database/category/png/5.png'),
    _a[CATEGORY_1.CATEGORY.CANAPE] = require('../assets/database/category/png/6.png'),
    _a[CATEGORY_1.CATEGORY.BAIN_DE_SOLEIL] = require('../assets/database/category/png/7.png'),
    _a[CATEGORY_1.CATEGORY.PARASOL] = require('../assets/database/category/png/8.png'),
    _a[CATEGORY_1.CATEGORY.ACCESSOIRE] = require('../assets/database/category/png/10.png'),
    _a[CATEGORY_1.CATEGORY.DOUCHE] = require('../assets/database/category/png/9.png'),
    _a);
