<style scoped>
.LoaderSpace {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  /*background: white;*/
  /*z-index: 9999;*/
}
</style>

<template>
  <div class="LoaderSpace" :class="class_root">
    <Loader/>
  </div>
</template>

<script>


export default {

  props: {},
  data() {
    return {
      props: {},
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
