"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getDomain = void 0;
var api_config_1 = require("../../../config/public/api.config");
function getDomain() {
    return "" + api_config_1.apiClientConfig.domain + ([
        443,
        80
    ].includes(api_config_1.apiClientConfig.port) ? '' : ":" + api_config_1.apiClientConfig.port);
}
exports.getDomain = getDomain;
