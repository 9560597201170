<style scoped>
.CatalogueHeader {
  height: 90px;
}

.CatalogueHeader--content {
  height: 100%;
  display: flex;
  justify-content: space-between;
}

.CatalogueHeader--left {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.CatalogueHeader-desktop .CatalogueHeader--left {
  padding-left: 50px;
}

.CatalogueHeader-tablet .CatalogueHeader--left {
  padding-left: 50px;
}

.CatalogueHeader-bigMobile .CatalogueHeader--left {
  padding-left: 50px;
}

.CatalogueHeader-mobile .CatalogueHeader--left {
  padding-left: 25px;
  flex: 1;
}

.CatalogueHeader--filter {
  border: solid 1px #8A6C59;
  cursor: pointer;
  height: 38px;
  justify-content: center;
  align-items: center;
  flex: 1;
  max-width: 234px;
}

.CatalogueHeader-desktop .CatalogueHeader--filter {
  display: none;
}

.CatalogueHeader-tablet .CatalogueHeader--filter {
  display: none;
}

.CatalogueHeader-bigMobile .CatalogueHeader--filter {
  display: none;
}

.CatalogueHeader-mobile .CatalogueHeader--filter {
  display: flex;
  border: solid 1px white;
}

/**/

.CatalogueHeader--filterVector {
  width: 18px;
  height: 18px;
  padding-right: 15px;
}

.CatalogueHeader-desktop .CatalogueHeader--filterVector {

}

.CatalogueHeader-tablet .CatalogueHeader--filterVector {

}

.CatalogueHeader-mobile .CatalogueHeader--filterVector * {
  fill: white;
}

/**/

.CatalogueHeader--filterTerm {
  font-family: 'RalewayBold', sans-serif;
  text-transform: uppercase;
  font-size: 14px;
}

.CatalogueHeader-desktop .CatalogueHeader--filterTerm {

}

.CatalogueHeader-tablet .CatalogueHeader--filterTerm {

}

.CatalogueHeader-mobile .CatalogueHeader--filterTerm * {
  color: white;
}

/**/

.CatalogueHeader--ariane {

}

.CatalogueHeader-desktop .CatalogueHeader--ariane {
  display: block;
}

.CatalogueHeader-tablet .CatalogueHeader--ariane {
  display: block;
}

.CatalogueHeader-bigMobile .CatalogueHeader--ariane {
  display: none;
}

.CatalogueHeader-mobile .CatalogueHeader--ariane {
  display: none;
}

/**/

.CatalogueHeader--ariane ::v-deep .Ariane--link p {
  color: rgba(255, 255, 255, 1);
}

.CatalogueHeader--ariane ::v-deep .Ariane--link h1 {
  color: rgba(255, 255, 255, 1);
}

.CatalogueHeader--ariane ::v-deep .Ariane--triangle svg {
  fill: rgba(255, 255, 255, 0.5);
}


/**/

.CatalogueHeader--right {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.CatalogueHeader-desktop .CatalogueHeader--right {
  padding-right: 25px;
}

.CatalogueHeader-tablet .CatalogueHeader--right {
  padding-right: 25px;
}

.CatalogueHeader-bigMobile .CatalogueHeader--right {
  padding-right: 25px;
}

.CatalogueHeader-mobile .CatalogueHeader--right {
  flex: 1;
  padding-right: 25px;
}

/**
 * * * * * * * * *
 *  COUNT
 * * * * * * * * *
 */

.CatalogueHeader--articleCount {
  align-items: center;
}

.CatalogueHeader-desktop .CatalogueHeader--articleCount {
  display: flex;
}

.CatalogueHeader-tablet .CatalogueHeader--articleCount {
  display: flex;
}

.CatalogueHeader-bigMobile .CatalogueHeader--articleCount {
  display: flex;
}

.CatalogueHeader-mobile .CatalogueHeader--articleCount {
  display: none;
}

.CatalogueHeader--articleCountNumber {
  border: solid 1px rgba(255, 255, 255, 0.25);
  color: white;
  font-family: 'ComfortaaBold', sans-serif;
  padding: 5px 25px;
  font-size: 14px;
  margin-right: 25px;
  border-radius: 4px;
}

.CatalogueHeader--articleCountTerm ::v-deep .Term {
  color: white;
  font-family: 'RalewayBold', sans-serif;
  letter-spacing: 1px;
  font-size: 10px;
  text-transform: uppercase;
}

.CatalogueHeader--divider {
  height: 25px;
  width: 1px;
  background: rgba(255, 255, 255, 0.25);
  margin: 0 50px;
}

/**
 * * * * * * * * *
 *  DISPLAY
 * * * * * * * * *
 */

.CatalogueHeader--display {
  align-items: center;
  height: 100%;
  padding-right: 10px;
}

.CatalogueHeader-desktop .CatalogueHeader--display {
  display: flex;
}

.CatalogueHeader-tablet .CatalogueHeader--display {
  display: none;
}

.CatalogueHeader-bigMobile .CatalogueHeader--display {
  display: none;
}

.CatalogueHeader-mobile .CatalogueHeader--display {
  display: none;
}

/**/

.CatalogueHeader--displayTerm {
  padding-right: 25px;
}

.CatalogueHeader--displayTerm ::v-deep .Term {
  color: white;
  font-family: 'RalewayBold', sans-serif;
  letter-spacing: 1px;
  font-size: 10px;
  text-transform: uppercase;
}

.CatalogueHeader--displayIcon {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  box-sizing: border-box;
  border-bottom: solid 1px transparent;
  padding: 0px 5px;
  transition: border 300ms, opacity 300ms;
  opacity: 0.5;
  cursor: pointer;
}

.CatalogueHeader--displayIcon:hover {
  opacity: 1;
}

.CatalogueHeader--displayIcon-selected {
  /*border-bottom: solid 1px white;*/
  opacity: 1;
}

.CatalogueHeader--displayIcon ::v-deep .Vector {
  position: relative;
  top: 2px;
  width: 24px;
  height: 24px;
  fill: white;
}

.CatalogueHeader--displayIcon-first ::v-deep .Vector {
  display: flex;
  justify-content: center;
  align-items: center;
}

/**
 * * * * * * * * *
 *  SORT
 * * * * * * * * *
 */

.CatalogueHeader--sort {
  align-items: center;
}

.CatalogueHeader-desktop .CatalogueHeader--sort {
  display: flex;
}

.CatalogueHeader-tablet .CatalogueHeader--sort {
  display: flex;
}

.CatalogueHeader-bigMobile .CatalogueHeader--sort {
  display: flex;
}

.CatalogueHeader-mobile .CatalogueHeader--sort {
  display: flex;
}

.CatalogueHeader--sortTerm {
  color: white;
  font-family: 'RalewayBold', sans-serif;
  letter-spacing: 1px;
  font-size: 10px;
  text-transform: uppercase;
  padding-right: 25px;
}

.CatalogueHeader-desktop .CatalogueHeader--sortTerm {
  display: block;
}

.CatalogueHeader-tablet .CatalogueHeader--sortTerm {
  display: block;
}

.CatalogueHeader-bigMobile .CatalogueHeader--sortTerm {
  display: none;
}

.CatalogueHeader-mobile .CatalogueHeader--sortTerm {
  display: none;
}

.CatalogueHeader--sortVector {
  width: 24px;
  position: relative;
  top: 2px;
  left: 40px;
}

.CatalogueHeader--sortVector * {
  fill: white;
}

.CatalogueHeader--sortInputSelect {

}

.CatalogueHeader-desktop .CatalogueHeader--sortInputSelect {
}

.CatalogueHeader-tablet .CatalogueHeader--sortInputSelect {
}

.CatalogueHeader-bigMobile .CatalogueHeader--sortInputSelect {
  width: 100%;
}

.CatalogueHeader-mobile .CatalogueHeader--sortInputSelect {
  width: 100%;
}

.CatalogueHeader--sortInputSelect ::v-deep .el-input input {
  background: rgba(0, 0, 0, 0.05);
  border-radius: 0px;
  border: none;
  font-family: 'RalewayBold', sans-serif;
  padding: 0 20px 0 60px;
  font-size: 14px;
  color: white;
}

/**/

.CatalogueHeader--sortInputSelect ::v-deep .el-input__icon {
  color: white !important;
}

.CatalogueHeader-desktop .CatalogueHeader--sortInputSelect ::v-deep .el-input__icon {

}

.CatalogueHeader-tablet .CatalogueHeader--sortInputSelect ::v-deep .el-input__icon {

}

.CatalogueHeader-mobile .CatalogueHeader--sortInputSelect ::v-deep .el-input__icon {
  display: none;
}

/**/


.CatalogueHeader--sortInputSelect ::v-deep div > div > div > span > span > i {
  color: #8A6C59 !important;
  position: relative;
  left: -10px;
}

.CatalogueHeader--divider-0 {
}

.CatalogueHeader-desktop .CatalogueHeader--divider-0 {

}

.CatalogueHeader-tablet .CatalogueHeader--divider-0 {
  display: none;
}

.CatalogueHeader-bigMobile .CatalogueHeader--divider-0 {
  display: none;
}

.CatalogueHeader-mobile .CatalogueHeader--divider-0 {
  display: none;
}

/**/

.CatalogueHeader--divider-1 {
}

.CatalogueHeader-desktop .CatalogueHeader--divider-1 {
  margin-left: 25px;
  display: block;
}

.CatalogueHeader-tablet .CatalogueHeader--divider-1 {
  display: block;
}

.CatalogueHeader-mobile .CatalogueHeader--divider-1 {
  display: none;
}

.CatalogueHeader-bigMobile .CatalogueHeader--divider-1 {
  display: none;
}

</style>

<template>
  <div class="CatalogueHeader" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="CatalogueHeader--content">
        <div class="CatalogueHeader--left">


          <div class="CatalogueHeader--filter" @click="() => onClickFilter()">
            <div class="CatalogueHeader--filterVector">
              <Vector :svg="icon.tune"/>
            </div>
            <div class="CatalogueHeader--filterTerm">
              <Term :term="lang.term(221)"/>
            </div>
          </div>


          <div class="CatalogueHeader--ariane">
            <Ariane :pages="arianePages" :truncate="state.truncate" :h1="true"/>
          </div>

        </div>
        <div class="CatalogueHeader--right">

          <div class="CatalogueHeader--articleCount">
            <div class="CatalogueHeader--articleCountNumber">{{ articleCount }}</div>
            <div class="CatalogueHeader--articleCountTerm">
              <Term :term="lang.term(215)"/>
            </div>
          </div>

          <div class="CatalogueHeader--divider CatalogueHeader--divider-0"></div>

          <div class="CatalogueHeader--display">
            <div class="CatalogueHeader--displayTerm">
              <Term :term="lang.term(216)"/>
            </div>
            <div class="CatalogueHeader--displayIcon CatalogueHeader--displayIcon-first"
                 :class="selected(FILTER_DISPLAY.BIG)" @click="() => onDisplayChange(FILTER_DISPLAY.BIG)">
              <Vector :svg="icon.view_module"/>
            </div>
            <div class="CatalogueHeader--displayIcon" :class="selected(FILTER_DISPLAY.SMALL)"
                 @click="() => onDisplayChange(FILTER_DISPLAY.SMALL)">
              <Vector :svg="icon.apps"/>
            </div>
          </div>

          <div class="CatalogueHeader--divider CatalogueHeader--divider-1"></div>


          <div class="CatalogueHeader--sort">
            <div class="CatalogueHeader--sortTerm">
              <Term :term="lang.term(217)"/>
            </div>
            <div class="CatalogueHeader--sortVector">
              <Vector :svg="icon.sort"/>
            </div>
            <div class="CatalogueHeader--sortInputSelect">
              <InputSelect :items="sorts" :values="selectedSorts" :onChangeValues="v => onChangeSorts(v)"/>
            </div>
          </div>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>
import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {PATHS} from "../../../@common/constant/PATHS";
import {FILTER_DISPLAY} from "../../../@common/constant/FILTER_DISPLAY";
import {Page} from "../../../@common/delta/database/Page";
import {convertPathToUrl, getPageByPathIds, searchPageByNuxProp} from "../../../@common/function/helperUrl";

const SCREEN_CUSTOM_1 = 'big-mobile'

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    },
    selectedSorts: {
      type: Array,
      default: () => ([])
    },
    sorts: {
      type: Array,
      default: () => ([])
    },
    onChangeSorts: {
      type: Function,
      default: () => () => {
      }
    },
    onClickFilter: {
      type: Function,
      default: () => () => {
      }
    },
    display: {
      type: String, // big | small
      default: () => FILTER_DISPLAY.BIG
    },
    articleCount: {
      type: Number,
      default: () => 0
    },
    onDisplayChange: {
      type: Function,
      default: () => (display) => {
      }
    }
  },
  data() {
    const lang = getLangServiceBrowser()
    return {
      FILTER_DISPLAY,
      lang,
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN_CUSTOM_1,
                width: 920
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 1200
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1600
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        view_module: require('../../../@common/assets/icon/view_module.svg'),
        apps: require('@bbx/vector~master/core/assets/svg/material/apps.svg'),
        sort: require('@bbx/vector~master/core/assets/svg/material/sort.svg'),
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      state: {
        truncate: false
      },
    }
  },
  watch: {},
  computed: {
    /**
     * @returns {Page[]}
     */
    arianePages() {

      /** @type NuxtProp */
      const nuxtProp = this.nuxtProp

      let pages = []

      let pageHome = getPageByPathIds(nuxtProp, [PATHS.HOME])
      let pageCatalog = getPageByPathIds(nuxtProp, [PATHS.CATALOG])
      let pageCurrent = nuxtProp.page

      pageHome = convertPathToUrl(pageHome)
      if (pageCatalog.id !== pageCurrent.id) pageCatalog = convertPathToUrl(pageCatalog)
      pageCurrent = convertPathToUrl(pageCurrent)

      pages.push(pageHome)
      if (pageCatalog.id !== pageCurrent.id) pages.push(pageCatalog)

      // -----

      const pathValues = nuxtProp.url.paths.map(path => path.value)
      pathValues.pop()
      const posibilities = []
      const pageAutos = []
      for (const i in pathValues) {
        posibilities.push(pathValues.slice(0, parseInt(i) + 1))
      }
      for (const pathValue of posibilities) {
        const page = searchPageByNuxProp(nuxtProp, pathValue)
        if (page.id && this.hasPathTerm(page)) {
          const pageUrl = convertPathToUrl(page)
          pageAutos.push(pageUrl)
        }
      }

      if (pageAutos.length) {
        this.state.truncate = true
        pages = pages.concat(pageAutos)
      }

      // -----

      pages.push(pageCurrent)

      return pages
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`CatalogueHeader-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN_CUSTOM_1) classes.push(`CatalogueHeader-bigMobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`CatalogueHeader-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`CatalogueHeader-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`CatalogueHeader-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`CatalogueHeader-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    selected(display) {
      const classes = []
      if (display === this.display) classes.push('CatalogueHeader--displayIcon-selected')
      return classes
    },
    /**
     *
     * @param {Page} page
     */
    hasPathTerm(page) {
      for (const url of page.urls) {
        for (const sort of url.sorts) {
          for (const path of sort.paths) {
            for (const term of path.terms) {
              return !!this.lang.translateTerm(term)
            }
          }
        }
      }
      return false
    }
  }
}
</script>
