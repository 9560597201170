<style scoped>
.ArticleList {
}

.ArticleList--item {
}

.ArticleList--item ::v-deep .Link {
  width: 100%;
  height: 100%;
  margin: 25px 0;
  display: flex;
  text-decoration: none;
}

.ArticleList--left {

}

.ArticleList--right {
  margin-left: 25px;
}

.ArticleList--image {
  width: 110px;
  height: 110px;
  min-width: 110px;
  min-height: 110px;
  border-radius: 4px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.03);
  box-sizing: border-box;
}

.ArticleList--image-auto {
  padding: 10px;
}

.ArticleList--name {
  font-family: 'RalewayBold', sans-serif;
  font-size: 14px;
  margin-bottom: 6px;
  line-height: 20px;
  color: black;
}

.ArticleList--price {
  font-family: 'DDinRegular', sans-serif;
  color: #8A6C59;
}

.ArticleList--price span {
  padding-right: 4px;
}

.ArticleList--ref {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.4);
}

.ArticleList--stock {
  font-family: 'ComfortaaRegular', sans-serif;
  color: white;
  background: #2B1D10;
  font-size: 10px;
  padding: 4px 8px;
  border-radius: 4px;
  margin-top: 10px;
  display: inline-block;
}
</style>

<template>
  <div class="ArticleList" :class="class_root" @click="() => onClick()">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="ArticleList--item">
        <Link :pathIds="pathIds">
          <div class="ArticleList--left">
            <div class="ArticleList--image" :class="class_image(display)">
              <Picture :file="findCoverPhoto(article).file" :display="display"></Picture>
            </div>
          </div>
          <div class="ArticleList--right">
            <template v-for="termName in article.termNames">
              <div class="ArticleList--name">
                <Term :term="termName"/>
              </div>
            </template>
            <div class="ArticleList--price">
              <CountryLocation>
                <span>{{ formatPrice(price) }} €</span>
              </CountryLocation>
              <template v-for="batch in article.batches">
                <template v-for="product in batch.products">
                  <span class="ArticleList--ref">#{{ product.reference }}</span>
                </template>
              </template>
            </div>
            <div class="ArticleList--stock" v-show="[ROLE.ROOT, ROLE.ADMIN, ROLE.CHR, ROLE.SELLER].includes(state.roleId)">
              {{ getArticleStockBrowser }}
              <Term :term="lang.term(408)"/>
            </div>
          </div>
        </Link>
      </div>
    </Responsive>
  </div>
</template>

<script>

import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {findCoverPhoto} from "../../function/findCoverPhoto";
import {PICTURE_DISPLAY} from "../../../@common/constant/PICTURE_DISPLAY";
import {Article} from "../../../@common/delta/database/Article";
import {formatPrice} from "../../../@common/function/formatPrice";
import {calculateTTC} from "../../../@common/function/calculateTTC";
import {changeRoute} from "../../function/changeRoute";
import {Basket} from "../../../@common/delta/database/Basket";
import {getBasketTotal} from "../../../@common/function/getBasketTotal";
import {Order} from "../../../@common/delta/database/Order";
import {getArticleStock} from "../../../@common/function/getArticleStock";
import {ROLE} from "../../../@common/constant/ROLE";
import {STATE_ROLE_ID, stateService} from "../../service/stateService";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {eventService} from "../../service/eventService";
import {EVENT} from "../../../@common/constant/EVENT";
import {getArticleStockBrowser} from "../../function/getArticleStockBrowwser";

export default {

  props: {
    article: {
      default: () => new Article()
    },
    index: {
      type: Number,
      default: () => false
    }
  },
  data() {
    return {
      ROLE,
      PICTURE_DISPLAY,
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      state: {
        /**
         * @type number
         */
        roleId: ROLE.CUSTOMER,
      },
    }
  },
  watch: {},
  computed: {
    lang() {
      return getLangServiceBrowser()
    },
    price() {
      /**
       * @type Article
       */
      const article = this.article

      if (this.state.roleId === ROLE.SELLER) {
        article.promotions = []
      }

      let basketTotal = getBasketTotal({
        order: new Order({
          baskets: [
            new Basket({
              quantity: 1,
              articles: [article]
            })
          ]
        }),
        roleId: this.state.roleId
      })
      return basketTotal.articleWithPromotionTTC
    },
    pathIds() {
      const paths = []
      for (const page of this.article.pages) {
        for (const path of page.paths) {
          paths.push(path.id)
        }
      }
      return paths
    },
    display() {
      return this.pictureDisplay(this.findCoverPhoto(this.article).photo)
    },
    getArticleStockBrowser() {
      return getArticleStockBrowser({article: this.article})
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`ArticleList-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`ArticleList-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`ArticleList-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`ArticleList-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`ArticleList-desktopY`)
      return classes
    }
  },
  beforeMount() {

    // -----

    this.state.roleId = stateService.get(STATE_ROLE_ID)
    stateService.watch(STATE_ROLE_ID, v => this.state.roleId = v)
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    class_image(display) {
      const classes = []
      if (display === PICTURE_DISPLAY.AUTO) classes.push(`ArticleList--image-auto`)
      return classes
    },
    calculateTTC,
    formatPrice,
    findCoverPhoto,
    pictureDisplay(photo) {
      return photo.full ? PICTURE_DISPLAY.COVER : PICTURE_DISPLAY.AUTO
    },
    toArticle() {
      changeRoute(this.pathIds)
    },
    onClick() {
      eventService.triggerByName(new ListenerTrigger({
        name: EVENT.CLICK_ARTICLE,
        payload: {
          article: this.article,
          source: 'speed-search',
          index: this.index
        }
      }))
    }
  }
}
</script>
