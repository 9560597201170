"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.mapBrowserToDefaultLang = void 0;
var Translation_1 = require("../../@common/delta/database/Translation");
var Lang_1 = require("../../@common/delta/database/Lang");
function mapBrowserToDefaultLang(browsers, browserIds, map, lang) {
    for (var key in map) {
        var keys = key.split('.').map(function (a) { return parseInt(a); });
        var browserId = keys[0];
        var termId = keys[1];
        var translationId = keys[2];
        var translationValue = map[key];
        // let langId = keys[3]
        var indexBrowser = browserIds.indexOf(browserId);
        var browser = browsers[indexBrowser];
        for (var _i = 0, _a = browser.terms; _i < _a.length; _i++) {
            var term = _a[_i];
            if (term.id === termId) {
                term.translations.push(new Translation_1.Translation({
                    id: translationId,
                    value: translationValue,
                    langs: [
                        new Lang_1.Lang(lang)
                    ]
                }));
            }
        }
    }
    return browsers;
}
exports.mapBrowserToDefaultLang = mapBrowserToDefaultLang;
