import ApiRegistery from '@bbx/api~master/core/delta/ApiRegistery';



import {File} from '../../delta/database/File'
import {ApiRequest_constructor_props as ApiRequest} from "../../delta/http/ApiRequest";
import {ApiResponse} from "../../delta/http/ApiResponse";

export interface Request extends File {
}

export interface Response extends File {
}

export default new ApiRegistery<Request & ApiRequest, ApiResponse<Response>>({
    name: 'createFile',
})
