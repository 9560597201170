<style scoped>
.Pages {
  padding: 50px;
}

.Pages--header {
  display: flex;
  align-items: center;
  padding-bottom: 50px;
}

.Pages--footer {
  display: flex;
  justify-content: flex-end;
}

.Pages--spacer {
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
}
</style>

<template>
  <div class="Pages">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="Pages--header">
        <ButtonAction :term="lang.term(207)" :onClick="() => {}" :loading="state.loading"/>
        <div class="Pages--spacer"></div>
        <InputSwitch :label="lang.term(206)"
                     :value="state.filter.indexed"
                     :onChangeValue="v => state.filter.indexed = v">
        </InputSwitch>
      </div>
      <div v-for="page in state.pages">
        <Link :link="getPath(page)">
          <Tag>{{ getPath(page) }}</Tag>
        </Link>
        <br>
        <br>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {apiClient} from "../../service/apiClientService";
import selectPages from "../../../@common/api/page/selectPages";
import {Page} from "../../../@common/delta/database/Page";
import {urlService} from "../../../@common/service/urlService";
import {sendError} from "../../function/sendError";

export default {

  props: {},
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        save: require('@bbx/vector~master/core/assets/svg/material/save.svg')
      },
      state: {
        opened: false,
        loading: false,
        /**
         * @type Page[]
         */
        pages: [],
        /**
         * @type Page
         */
        filter: new Page({
          indexed: true
        })
      },
    }
  },
  watch: {
    'state.filter.indexed': function () {
      this.initAsync().catch(sendError)
    }
  },
  computed: {},
  beforeMount() {
  },
  mounted() {
    this.initAsync().catch(sendError)
  },
  beforeDestroy() {
  },
  methods: {
    async initAsync() {
      const {data} = await apiClient.do(selectPages, selectPages.with(this.state.filter))
      this.state.pages = data;
    },
    /**
     * @param {Page} page
     */
    getPath(page) {
      return urlService.getUrl(this.lang.lang.iso6391, page.paths.map(path => path.id))
    }
  }
}
</script>
