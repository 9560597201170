"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.LangServiceBrowser = void 0;
var Browser_1 = require("../delta/database/Browser");
var Lang_1 = require("../delta/database/Lang");
var Term_1 = require("../delta/database/Term");
var Translation_1 = require("../delta/database/Translation");
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var LANG_1 = require("../constant/LANG");
var LangServiceBrowser = /** @class */ (function () {
    function LangServiceBrowser(props) {
        if (props === void 0) { props = {}; }
        this.lang = deltaService_1.deltaService.object(props.lang, Lang_1.Lang);
        this.langs = deltaService_1.deltaService.array(props.langs, Lang_1.Lang);
        this.browsers = deltaService_1.deltaService.array(props.browsers, Browser_1.Browser);
        this.index = props.index !== undefined ? Object.assign({}, props.index) : {};
    }
    LangServiceBrowser.prototype.start = function () {
        this.indexiate();
    };
    LangServiceBrowser.prototype.indexiate = function () {
        for (var i = 0; i < this.browsers.length; i++) {
            var browser = this.browsers[i];
            for (var _i = 0, _a = browser.terms; _i < _a.length; _i++) {
                var term = _a[_i];
                for (var j = 0; j < term.translations.length; j++) {
                    var translation = term.translations[j];
                    for (var _b = 0, _c = translation.langs; _b < _c.length; _b++) {
                        var lang = _c[_b];
                        var key = browser.id + "." + lang.id;
                        this.index[key] = {
                            browser: i,
                            translation: j,
                        };
                    }
                }
            }
        }
    };
    LangServiceBrowser.prototype.getDefaultKeyIfMissing = function (browserId, key) {
        var keys = Object.keys(this.index);
        for (var _i = 0, keys_1 = keys; _i < keys_1.length; _i++) {
            var key_ = keys_1[_i];
            var regex = new RegExp("^" + browserId + ".");
            if (regex.test(key_)) {
                return key_;
            }
        }
        return key;
    };
    LangServiceBrowser.prototype.translate = function (browserId) {
        var key = browserId + "." + this.lang.id;
        var index = this.index[key];
        if (!index) {
            key = this.getDefaultKeyIfMissing(browserId, key);
            index = this.index[key];
        }
        if (!index)
            return '';
        return this.browsers[index.browser].terms[0].translations[index.translation].value;
    };
    LangServiceBrowser.prototype.term = function (browserId) {
        var key = browserId + "." + this.lang.id;
        var index = this.index[key];
        if (!index) {
            key = this.getDefaultKeyIfMissing(browserId, key);
            index = this.index[key];
        }
        if (!index)
            return new Term_1.Term();
        return this.browsers[index.browser].terms[0];
    };
    LangServiceBrowser.prototype.fillEmptyTerm = function (term, copyValue) {
        if (copyValue === void 0) { copyValue = true; }
        var langIds = [];
        var defaultTranslation = new Translation_1.Translation();
        for (var i in term.translations) {
            var translation = term.translations[i];
            if (!translation.langs.length)
                translation.langs.push(new Lang_1.Lang(this.langs[i]));
            for (var _i = 0, _a = translation.langs; _i < _a.length; _i++) {
                var lang = _a[_i];
                langIds.push(lang.id);
                if (lang.iso6391 === LANG_1.LANG_ISO.FR) {
                    defaultTranslation = translation;
                }
            }
        }
        // if translation has empty value
        for (var _b = 0, _c = term.translations; _b < _c.length; _b++) {
            var translation = _c[_b];
            if (!translation.value && copyValue) {
                translation.value = defaultTranslation.value;
            }
        }
        // if lang is missing
        for (var _d = 0, _e = this.langs; _d < _e.length; _d++) {
            var lang = _e[_d];
            if (!langIds.includes(lang.id)) {
                term.translations.push(new Translation_1.Translation({
                    value: copyValue ? defaultTranslation.value : '',
                    langs: [new Lang_1.Lang(lang)]
                }));
            }
        }
        return term;
    };
    LangServiceBrowser.prototype.translateTerm = function (term) {
        var term_ = new Term_1.Term(term);
        term_ = this.fillEmptyTerm(term_);
        for (var j = 0; j < term_.translations.length; j++) {
            var translation = term_.translations[j];
            for (var _i = 0, _a = translation.langs; _i < _a.length; _i++) {
                var lang = _a[_i];
                if (lang.id === this.lang.id)
                    return translation.value;
            }
        }
        return '';
    };
    LangServiceBrowser.prototype.simulate = function (value, fakeId) {
        if (fakeId === void 0) { fakeId = -1; }
        return new Term_1.Term({
            id: fakeId,
            translations: [
                new Translation_1.Translation({
                    value: value,
                    langs: [
                        new Lang_1.Lang({
                            id: LANG_1.LANG.FR,
                            iso6391: LANG_1.LANG_ISO.FR
                        })
                    ]
                })
            ]
        });
    };
    LangServiceBrowser.prototype.simulateAndTranslate = function (value, fakeId) {
        if (fakeId === void 0) { fakeId = -1; }
        return this.translateTerm(this.simulate(value, fakeId));
    };
    LangServiceBrowser.prototype.onChangeLang = function (lang) {
        this.lang = lang;
    };
    return LangServiceBrowser;
}());
exports.LangServiceBrowser = LangServiceBrowser;
