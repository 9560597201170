<style scoped>
.Stock {
}

.Stock--content {
  display: flex;
  align-items: center;
}

.Stock--point {
  width: 5px;
  height: 5px;
  background: #90cc4a;
  border-radius: 5px;
  margin-right: 15px;
}

.Stock-zero .Stock--point {
  background: #ff0f0f;
}

.Stock-lessThanFive .Stock--point {
  background: #ffb527;
}

.Stock--label {
  font-size: 12px;
  font-family: 'ComfortaaRegular', sans-serif;
}
</style>

<template>
  <div class="Stock" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="Stock--content">
        <div class="Stock--point"></div>
        <div class="Stock--label" v-if="value === 0">En rupture de stock</div>
        <div class="Stock--label" v-else-if="value < 5">Plus que {{ value }} en stock</div>
        <div class="Stock--label" v-else-if="value >= 5">{{
            [
              ROLE.ROOT,
              ROLE.ADMIN,
              ROLE.CHR,
              ROLE.SELLER
            ].includes(state.roleId) ? value : ''
          }} En stock
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {STATE_ROLE_ID, stateService} from "../../service/stateService";
import {ROLE} from "../../../@common/constant/ROLE";

export default {

  props: {
    value: {
      type: Number,
      default: () => 6
    }
  },
  data() {
    return {
      ROLE,
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      state: {
        roleId: 0
      },
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.value === 0) classes.push('Stock-zero')
      else if (this.value < 5) classes.push('Stock-lessThanFive')
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
    this.state.roleId = stateService.get(STATE_ROLE_ID)
    stateService.watch(STATE_ROLE_ID, v => this.state.roleId = v)
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
