<style scoped>
.CollectionAdmin {
}

.CollectionAdmin--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 50px;
}

.CollectionAdmin--headerLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.CollectionAdmin--headerRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.CollectionAdmin--headerRight ::v-deep .InputText input {
  border-radius: 4px 0 0 4px;
  position: relative;
  left: 1px;
}

.CollectionAdmin--headerRight ::v-deep .InputSelect input {
  border-radius: 0 4px 4px 0;
}

.CollectionAdmin--spacer {
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
}

.CollectionAdmin--pagination {
  padding: 15px 5px 5px 5px;
}

.CollectionAdmin--loader {
  width: 100%;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CollectionAdmin--modal {
  padding: 0px 50px 50px 50px;
}

.CollectionAdmin--footer {
  display: flex;
  justify-content: flex-end;
}

.CollectionAdmin--empty {
  border: dotted 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  box-sizing: border-box;
  margin-top: 15px;
}

.CollectionAdmin--empty ::v-deep .Vector {
  width: 24px;
  height: 24px;
  position: relative;
  top: -1px;
  margin-right: 15px;
}

.CollectionAdmin--option {
  width: 24px;
  cursor: pointer;
}

.CollectionAdmin--body {
  display: flex;
  flex-wrap: wrap;
}

.CollectionAdmin--card {
  width: 400px;
  margin: 0px 30px 30px 0px;
  overflow: hidden;
  border-radius: 4px;
  transform: translateY(0px);
  transition: transform 300ms, color 300ms;
  color: rgba(0, 0, 0, 0.5);
}

.CollectionAdmin--card:hover {
  transform: translateY(-5px);
  color: rgba(0, 0, 0, 1);
}

.CollectionAdmin--cardPhoto {
  width: 100%;
  height: 200px;
  background: rgba(0, 0, 0, 0.06);
  display: block;
  text-decoration: none;
  cursor: pointer;
  transition: background-color 300ms;
}

.CollectionAdmin--card:hover .CollectionAdmin--cardPhoto {
  background: rgba(0, 0, 0, 0.12);
}

.CollectionAdmin--card ::v-deep .Picture {
  opacity: 1;
  transition: opacity 300ms;
}

.CollectionAdmin--card:hover ::v-deep .Picture {
  opacity: 0.9;
}

.CollectionAdmin--cardPhotoEmpty {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CollectionAdmin--cardPhotoEmpty ::v-deep .Vector {
  fill: rgba(0, 0, 0, 0.1);
  position: relative;
  top: 4px;
}

.CollectionAdmin--cardFooter {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.03);
  transition: background-color 300ms;
}

.CollectionAdmin--card:hover .CollectionAdmin--cardFooter {
  /*background: rgba(0, 0, 0, 0.06);*/
}

.CollectionAdmin--value {
  text-transform: capitalize;
}
</style>

<template>
  <div class="CollectionAdmin" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="CollectionAdmin--header">
        <div class="CollectionAdmin--headerLeft">
          <Popover position="bottom-start">
            <template slot="reference">
              <ButtonAction :term="lang.term(157)" :svg="icon.add"></ButtonAction>
            </template>
            <template slot="default">
              <List :svg="icon.add" :text="lang.translate(537)"
                    :onClick="() => openInsert()"></List>
            </template>
          </Popover>
          <div class="CollectionAdmin--spacer"></div>
          <InputSwitch :label="lang.term(160)"
                       :value="state.filterActive"
                       :onChangeValue="v => state.filterActive = v">
          </InputSwitch>
        </div>
        <div class="CollectionAdmin--headerRight">
          <div style="width: 24px;">
            <Vector :svg="icon.search"></Vector>
          </div>
          <div class="CollectionAdmin--spacer"></div>
          <InputText :value="state.filterSearch" :onChangeValue="v => state.filterSearch = v"></InputText>
          <InputSelect :items="state.searchTypes"
                       :values="state.selectedSearchTypes"
                       :max="1"
                       :onChangeValues="v => state.selectedSearchTypes = v">
          </InputSelect>
        </div>
      </div>

      <div class="CollectionAdmin--body" v-show="!state.loadingSelect">

        <div v-for="collection in state.collections" class="CollectionAdmin--card" :key="collection.id">
          <a class="CollectionAdmin--cardPhoto" target="_blank" :href="url(collection)">
            <Picture v-if="getFile(collection).id" :file="getFile(collection)"></Picture>
            <div class="CollectionAdmin--cardPhotoEmpty" v-if="!getFile(collection).id">
              <div style="width: 24px;">
                <Vector :svg="icon.panorama"></Vector>
              </div>
            </div>
          </a>
          <div class="CollectionAdmin--cardFooter">

            <span class="CollectionAdmin--value"><b>#{{ collection.id }}</b> {{ collection.value }}</span>

            <Popover position="bottom">
              <template slot="default">
                <List :svg="icon.edit" :text="`éditer`" :onClick="() => openUpdate(collection)"></List>
                <List :svg="collection.active ? icon.archive : icon.unarchive"
                      :text="collection.active ? 'désactiver' : 'activer'"
                      :onClick="() => openDelete(collection)"></List>
              </template>
              <template slot="reference">
                <div class="CollectionAdmin--option">
                  <Vector :svg="icon.more_horiz"></Vector>
                </div>
              </template>
            </Popover>

          </div>
        </div>

      </div>
      <div class="CollectionAdmin--empty" v-if="!state.collections.length && !state.loadingSelect">
        <Vector :svg="icon.search"></Vector>
        <Term :term="lang.term(536)"></Term>
      </div>
      <div class="CollectionAdmin--loader" v-show="state.loadingSelect">
        <Loader></Loader>
      </div>
      <div class="CollectionAdmin--pagination">
        <Pagination :limit="state.filterLimit"
                    :offset="state.filterOffset"
                    :total="state.count"
                    :range="10"
                    :onOffsetChange="v => state.filterOffset = v">
        </Pagination>
      </div>
      <Modal :opened="state.openedUpsert" :onOpenedChange="v => state.openedUpsert = v">
        <div class="CollectionAdmin--modal" v-if="state.openedUpsert">
          <Form>
            <CollectionForm :collection="state.collection"
                            :form="state.form"
                            :onChangeCollection="v => state.collection = v">
            </CollectionForm>
            <br>
            <Divider></Divider>
            <br>
            <div class="CollectionAdmin--footer">
              <ButtonAction :term="lang.term(158)" :onClick="() => upsert()"
                            :loading="state.loadingUpsert"></ButtonAction>
              <div class="CollectionAdmin--spacer"></div>
              <ButtonLink :term="lang.term(159)" :onClick="() => state.openedUpsert = false"></ButtonLink>
            </div>
          </Form>
        </div>
      </Modal>
      <ModalConfirm :opened="state.openedRemove"
                    :onOpenedChange="v => state.openedRemove = v"
                    :on-validated="() => remove()"
                    :loading="state.loadingUpsert">
      </ModalConfirm>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {Collection} from "../../../@common/delta/database/Collection";
import {COLLECTION_SEARCH_ID} from "../../../@common/constant/COLLECTION";
import {controlForm} from "../../function/controlForm";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {apiClient} from "../../service/apiClientService";
import createCollection from "../../../@common/api/collection/createCollections";
import selectCollections from "../../../@common/api/collection/selectCollections";
import {File} from "../../../@common/delta/database/File";
import {Debounce} from "../../../@common/ts/Debounce";
import {normalizeUrl} from "../../../@common/function/normalizeUrl";
import {sendError} from "../../function/sendError";

let debounce = new Debounce()

export default {
  props: {},
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        add: require('@bbx/vector~master/core/assets/svg/material/add.svg'),
        search: require('@bbx/vector~master/core/assets/svg/material/search.svg'),
        edit: require('@bbx/vector~master/core/assets/svg/material/edit.svg'),
        visibility: require('@bbx/vector~master/core/assets/svg/material/visibility.svg'),
        archive: require('@bbx/vector~master/core/assets/svg/material/archive.svg'),
        unarchive: require('@bbx/vector~master/core/assets/svg/material/unarchive.svg'),
        more_horiz: require('@bbx/vector~master/core/assets/svg/material/more_horiz.svg'),
        panorama: require('@bbx/vector~master/core/assets/svg/material/panorama.svg'),
      },
      state: {
        /**
         * @type {Collection}
         */
        collection: new Collection(),
        /**
         * @type {Collection[]}
         */
        collections: [],
        /**
         * @type ApiResponseForm
         */
        form: new ApiResponseForm(),
        /**
         * @type {boolean}
         */
        loadingUpsert: false,
        /**
         * @type {boolean}
         */
        loadingSelect: false,
        /**
         * @type {boolean}
         */
        openedUpsert: false,
        /**
         * @type {boolean}
         */
        openedRemove: false,
        /**
         * @type {boolean}
         */
        filterActive: true,
        /**
         * @type {number}
         */
        filterLimit: 8,
        /**
         * @type {number}
         */
        filterOffset: 0,
        /**
         * @type {number}
         */
        count: 0,
        /**
         * @type {string}
         */
        filterSearch: '',
        /**
         * @type {Array<{id:number,value:string}>}
         */
        searchTypes: [
          {
            id: COLLECTION_SEARCH_ID.ID,
            value: 'Id'
          },
          {
            id: COLLECTION_SEARCH_ID.VALUE,
            value: 'Nom'
          },
        ],
        /**
         * @type any[]
         */
        selectedSearchTypes: [
          {
            id: COLLECTION_SEARCH_ID.VALUE,
            value: 'Nom'
          },
        ],
      },
    }
  },
  watch: {
    'state.filterActive': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.filterOffset': function () {
      if (this.state.loadingSelect === false) {
        this.select().catch(sendError)
      }
    },
    'state.filterLimit': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.filterSearch': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.filterIsBatch': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.selectedSearchTypes': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    }
  },
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`CollectionAdmin-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`CollectionAdmin-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`CollectionAdmin-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`CollectionAdmin-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`CollectionAdmin-desktopY`)
      return classes
    },
  },
  beforeMount() {
  },
  mounted() {
    this.select().catch(sendError)
  },
  beforeDestroy() {
  },
  methods: {
    /**
     * @returns {Promise<void>}
     */
    async openInsert() {
      this.state.collection = this.defaultCollection(new Collection())
      this.state.openedUpsert = true
    },

    /**
     * @param {Collection} collection
     * @returns {Promise<void>}
     */
    async openUpdate(collection) {
      this.state.collection = this.defaultCollection(new Collection(collection))
      this.state.openedUpsert = true
    },

    /**
     * @param {Collection} collection
     * @returns {Promise<void>}
     */
    async openDelete(collection) {
      this.state.collection = this.defaultCollection(new Collection(collection))
      this.state.openedRemove = true
    },

    /**
     * @param {Collection} collection
     * @returns {Collection}
     */
    defaultCollection(collection = new Collection) {
      return collection
    },

    async select() {
      this.state.loadingSelect = true

      const filter = {
        active: this.state.filterActive,
        $search: this.state.filterSearch,
        $searchType: this.state.selectedSearchTypes.map(a => a.id).pop(),
        $limit: this.state.filterLimit,
        $offset: this.state.filterOffset,
        $scope: 2,
        $sortBy: 'id'
      }

      debounce.callback({
        job: () => apiClient.do(selectCollections, selectCollections.with(filter)),
        success: res => {
          this.state.collections = res.data;
          this.state.count = res.count;
          this.state.loadingSelect = false
        },
        error: err => {
          this.state.loadingSelect = false
          sendError(err)
        }
      })
    },

    /**
     * @returns {Promise<void>}
     */
    async remove() {
      this.state.collection.active = !this.state.collection.active;
      await this.upsert()
    },

    async upsert() {

      const payload = new Collection(this.state.collection)

      let res = await controlForm({
        apiAction: createCollection,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loadingUpsert',
        loaderState: this.state,
        payload: payload,
        vue: this,
      });

      if (res.success) {

        this.state.collection = this.defaultCollection()

        this.state.openedUpsert = false
        this.state.openedRemove = false

        await this.select();
      }
    },
    /**
     * @param {Collection} collection_
     * @return {File}
     */
    getFile(collection_) {

      const collection = new Collection(collection_)

      collection.sortPhotos = collection.sortPhotos.sort((a, b) => a.order - b.order)

      for (const sortPhoto of collection.sortPhotos) {
        for (const photo of sortPhoto.photos) {
          for (const file of photo.files) {
            return new File(file)
          }
        }
      }
      return new File()
    },
    /**
     * @param {Collection} collection
     * @return {string}
     */
    url(collection) {
      return `/fr/collection-${normalizeUrl(collection.value)}`
    }
  }
}
</script>
