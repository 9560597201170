<style scoped>
.AdminContextCarousel {

}

.AdminContextCarousel--loader {
  height: 100px;
  padding-bottom: 50px;
}

.AdminContextCarousel ::v-deep .InputSection--header {
  margin-top: 0;
}

.AdminContextCarousel ::v-deep .InputSection--slot {
  align-items: flex-start;
  padding-left: 50px;
  margin-bottom: 50px;
}

.AdminContextCarousel ::v-deep .BoardTask--fileDrop {
  height: 140px;
  border: dashed 2px rgba(0, 0, 0, 0.15);
}

.AdminContextCarousel ::v-deep .BoardTask--filesDropInfoText {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.5);
}

.AdminContextCarousel ::v-deep .InputSection--remove {
  margin-left: 25px;
}

.AdminContextCarousel--position {
  display: flex;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.03);
  padding: 25px;
  border-radius: 4px;
}

.AdminContextCarousel--empty {
  border: dashed 2px rgba(0, 0, 0, 0.15);
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.AdminContextCarousel--save {
}

</style>

<template>
  <div class="AdminContextCarousel" :class="class_root">
    <div class="AdminContextCarousel--loader" v-if="state.loadingSelect">
      <LoaderSpace></LoaderSpace>
    </div>
    <InputSection :svg="icon.view_carousel"
                  :term="lang.term(627)"
                  :multiple="true"
                  :cloned="true"
                  :addable="true"
                  :items="state.carousels"
                  :onFilter="onFilter"
                  :onClickAdd="() => add()"
                  :onClickRemove="i => remove(i)">
      <template v-slot:default="{item}">
        <PhotosUpload :photos="item.photos"
                      :cloned="true"
                      :onPhotoChange="v => item.photos = [v]">
        </PhotosUpload>
        <template v-for="photo in item.photos">
          <Photos :photos="[defaultCarouselPhoto(photo)]"
                  :updatable="false"
                  :onClickDelete="() => item.photos = []">
          </Photos>
          <br>
          <InputLabel :term="lang.term(628)"></InputLabel>
          <template v-for="position in photo.positions">
            <br>
            <div class="AdminContextCarousel--position">
              <PositionToggle :position="position" :onPositionChange="v => photo.positions = [v]"></PositionToggle>
            </div>
          </template>
          <br>
        </template>


        <template v-for="termTitle in item.termTitles">
          <InputTerm :label="lang.term(629)"
                     :term="termTitle"
                     :maxLength="25"
                     :showWordLimit="true"
                     :onChangeTerm="v => termName = v">
          </InputTerm>
        </template>
        <br>

        <template v-for="termDescription in item.termDescriptions">
          <InputTerm :label="lang.term(630)"
                     :term="termDescription"
                     :maxLength="100"
                     :showWordLimit="true"
                     :onChangeTerm="v => termDescription = v">
          </InputTerm>
        </template>
        <br>


        <InputText :label="lang.term(631)"
                   :value="item.url"
                   :onChangeValue="v => item.url = v">
        </InputText>
        <br>


        <InputNumber :value="item.opacityShadow"
                     :onChangeValue="v => item.opacityShadow = v"
                     :helper="lang.translate(637)"
                     :label="lang.term(632)">
        </InputNumber>
        <br>


        <InputLabel :term="lang.term(633)"></InputLabel>
        <br>
        <template v-for="sort in item.sorts">
          <el-input-number @change="v => sort.order = v"
                           :value="sort.order">
          </el-input-number>
        </template>
        <br>
        <br>


        <InputLabel :term="lang.term(634)"></InputLabel>
        <br>
        <el-color-picker v-model="item.color"></el-color-picker>
        <br>
        <br>


        <InputLabel :term="lang.term(635)"></InputLabel>
        <br>
        <InputSwitch :value="item.isNightMode" :onChangeValue="v => item.isNightMode = v"></InputSwitch>
      </template>
    </InputSection>
    <div class="AdminContextCarousel--empty" v-if="!state.carousels.filter(onFilter).length && !state.loadingSelect">
      <Term :term="lang.term(636)"></Term>
    </div>
    <div class="AdminContextCarousel--save">
      <ButtonAction :term="lang.term(207)" :onClick="() => save()" :loading="state.loadingCreate"></ButtonAction>
    </div>
  </div>
</template>

<script>

import {eventService} from '../../service/eventService'
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser.ts";
import {apiClient} from "../../service/apiClientService";

import selectCarousel from "../../../@common/api/carousel/selectCarousel";
import createCarousel from "../../../@common/api/carousel/createCarousel";

import {sendError} from "../../function/sendError";
import {displayMessage} from "../../function/displayMessage";

import {COLOR} from "../../../@common/constant/COLOR";

import {Term} from "../../../@common/delta/database/Term";
import {Carousel} from "../../../@common/delta/database/Carousel";
import {Position} from "../../../@common/delta/database/Position";
import {Sort} from "../../../@common/delta/database/Sort";

export default {
  data() {
    return {
      COLOR,
      lang: getLangServiceBrowser(),
      icon: {
        view_carousel: require('@bbx/vector~master/core/assets/svg/material/view_carousel.svg'),
      },
      state: {
        /**
         * @type {boolean}
         */
        loadingSelect: false,
        /**
         * @type {boolean}
         */
        loadingCreate: false,
        /**
         * @type {Carousel[]}
         */
        carousels: [],
      },
      events: []
    }
  },
  computed: {
    class_root() {
      const classes = [];
      return classes;
    }
  },
  beforeMount() {

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
    this.init().catch(sendError)
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    async init() {
      await this.select()
    },

    async select() {
      this.state.loadingSelect = true
      const {data} = await apiClient.do(selectCarousel, selectCarousel.with({
        active: true,
        $sort: 'ask'
      }))
      this.state.carousels = data.map(a => this.defaultCarousel(a))
      this.state.loadingSelect = false
    },
    /**
     *
     * @param {Carousel} carousel
     * @returns {Carousel}
     */
    defaultCarousel(carousel = new Carousel()) {
      if (!carousel.id && carousel.opacityShadow === 0) carousel.opacityShadow = 0.38
      if (!carousel.id && carousel.color === '') carousel.color = this.COLOR.PRIMARY
      if (!carousel.termTitles.length) carousel.termTitles.push(new Term())
      if (!carousel.termDescriptions.length) carousel.termDescriptions.push(new Term())
      if (!carousel.sorts.length) carousel.sorts.push(new Sort())
      return carousel
    },
    /**
     *
     * @param {Photo} photo
     * @returns {photo}
     */
    defaultCarouselPhoto(photo = new Photo()) {
      if (!photo.positions.length) photo.positions.push(new Position())
      for (const position of photo.positions) {
        const hasPosition = position.top || position.right || position.center || position.bottom || position.left
        if (!hasPosition) {
          position.center = true
        }
      }
      return photo
    },

    add() {
      this.state.carousels.push(this.defaultCarousel())
    },

    /**
     *
     * @param {Carousel} carousel
     * @param {number} carouselIndex
     */
    onFilter(carousel, carouselIndex) {
      return carousel.active
    },
    /**
     *
     * @param {number} index
     */
    remove(index) {
      /** @type {Carousel} */
      let carousel = this.state.carousels[index]
      if (carousel.id) carousel.active = false
      else this.state.carousels.splice(index, 1)
    },

    async save() {
      this.state.loadingCreate = true
      try {
        await Promise.all(this.state.carousels.map(async (carousel) => {
          await apiClient.do(createCarousel, createCarousel.with(carousel))
        }))
        displayMessage({
          vue: this,
          type: 'success',
          message: this.lang.translate(638)
        })
        await this.select()
      } catch (err) {
        sendError(err)
      }
      this.state.loadingCreate = false
    },
  }
}
</script>
