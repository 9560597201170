<style scoped>
.FileUpload {
}


.FileUpload--files {
  padding: 0 50px 40px 50px;
  background: rgba(0, 0, 0, 0.03);
}

.FileUpload--fileDrop {
  position: relative;
  height: 200px;
  width: 100%;
  border: dashed 2px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border 300ms;
  margin-bottom: 20px;
}

.FileUpload--fileDrop:hover {
  border: dashed 2px rgba(0, 0, 0, 1);
}

.FileUpload--fileDrop-dragged {
  border: dashed 2px rgba(0, 0, 0, 1);
}

.FileUpload--filesDropInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FileUpload--filesDropInfoIcon {
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
}

.FileUpload--filesDropInfoIcon ::v-deep .Vector svg {
  fill: #000000;
}

.FileUpload--filesDropInfoText {
  color: #000000;
}

.FileUpload--fileDrop input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
}

.FileUpload--file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  padding: 20px 0;
}

.FileUpload--file:last-child {
  border-bottom: none;
}

.FileUpload--fileLeft {
  display: flex;
  align-items: center;
}

.FileUpload--fileLeftFile {
}

.FileUpload--fileLeftFileImage {
  width: 50px;
  height: 50px;
  border-radius: 4px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.05);
}

.FileUpload--fileLeftFileDefault {
  background: rgba(0, 0, 0, 0.05);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.FileUpload--fileLeftFileDefaultIcon {
  width: 24px;
  height: 24px;
}

.FileUpload--fileLeftFileDefaultIcon ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 0.25);
  transition: fill 300ms;
}

.FileUpload--file:hover .FileUpload--fileLeftFileDefaultIcon ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 0.5);
}

.FileUpload--fileLeftInfo {
  margin-left: 20px;
}

.FileUpload--fileLeftInfoName {
  font-family: 'ComfortaaRegular', sans-serif;
  margin-bottom: 10px;
  font-size: 12px;
  word-break: break-all !important;
}

.FileUpload--fileLeftInfoName:first-letter {
  text-transform: capitalize;
}

.FileUpload--fileLeftInfoMeta {
  font-family: 'ComfortaaBold', sans-serif;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.75);
}

.FileUpload--fileRight {
  display: flex;
  align-items: center;
  position: relative;
}

.FileUpload--fileRightOption {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 10px;
  background: rgba(0, 0, 0, 0);
  border-radius: 50px;
  padding: 4px;
  transition: background 300ms;
}

.FileUpload--fileRightOption ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 0.5);
  transition: fill 300ms;
}

.FileUpload--fileRightOption:hover ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 1);
}

.FileUpload--fileRightOption:hover {
  background: rgba(0, 0, 0, 0.07);
}

.FileUpload--file:hover .FileUpload--fileRightOption {
  background: rgba(0, 0, 0, 0.07);
}

.FileUpload--file:hover .FileUpload--fileRightOption ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 1);
}

.FileUpload--fileRightOptionPourcent {
  font-family: 'ComfortaaBold', sans-serif;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
}

.FileUpload--fileRightOptionLoader {
  width: 30px;
  height: 30px;
}

.FileUpload--fileRightOptionLoader ::v-deep .el-progress-circle__path {
  stroke: #000000;
}

.BoardTask--fileDrop {
  position: relative;
  height: 200px;
  width: 100%;
  border: dashed 2px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border 300ms;
  margin-bottom: 20px;
}

.BoardTask--fileDrop:hover {
  border: dashed 2px rgba(0, 0, 0, 1);
}

.BoardTask--fileDrop input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
}

.BoardTask--filesDropInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.BoardTask--filesDropInfoIcon {
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
}

.BoardTask--filesDropInfoIcon ::v-deep .Vector svg {
  fill: #000;
}

.BoardTask--filesDropInfoText {
  color: #000;
}
</style>

<template>
  <div class="FileUpload">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">

      <div class="BoardTask--fileDrop">
        <div class="BoardTask--filesDropInfo">
          <div class="BoardTask--filesDropInfoIcon">
            <Vector :svg="icon.note_add"></Vector>
          </div>
          <div class="BoardTask--filesDropInfoText">Ajouter des fichiers (jpg, jpeg, png)</div>
        </div>
        <input type="file" ref="input" multiple @change="onFileNativeChange">
      </div>

      <template v-for="apiUpload in state.apiUploads">
        <div class="FileUpload--file">

          <div class="FileUpload--fileLeft">
            <div class="FileUpload--fileLeftFile">
              <div class="FileUpload--fileLeftFileDefault">
                <div class="FileUpload--fileLeftFileDefaultIcon">
                  <Vector :svg="icon.insert_drive_file"></Vector>
                </div>
              </div>
            </div>
            <div class="FileUpload--fileLeftInfo">
              <div class="FileUpload--fileLeftInfoName">{{ fileName(apiUpload) }}</div>
              <div class="FileUpload--fileLeftInfoMeta">{{ apiUpload.ext.toUpperCase() }} -
                {{ fileUnit(apiUpload.file.size) }}
              </div>
            </div>
          </div>
          <div class="FileUpload--fileRight">
            <div class="FileUpload--fileRightOptionPourcent">
              {{ Math.round(apiUpload.progress.current * 100 / apiUpload.progress.total) }}
            </div>
            <div class="FileUpload--fileRightOptionLoader">
              <el-progress :show-text="false"
                           :stroke-width="2"
                           :width="30"
                           type="circle"
                           :percentage="Math.round(apiUpload.progress.current * 100 / apiUpload.progress.total)">
              </el-progress>
            </div>
          </div>
        </div>
      </template>
    </Responsive>
  </div>
</template>

<script>

import ApiUpload from '@bbx/api~master/core/delta/ApiUpload'

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';

import createFile from "../../../@common/api/file/createFile";

import {apiClient} from "../../service/apiClientService";
import {File} from "../../../@common/delta/database/File";
import {Unit} from "@bbx/unit~master/core/ts/Unit";
import {displayMessage} from "../../function/displayMessage";
import {sendError} from "../../function/sendError";

export default {

  props: {
    files: {
      type: Array,
      default: () => ([])
    },
    onFileChange: {
      type: Function,
      default: () => () => {
      }
    },
    onNewFile: {
      type: Function,
      default: () => () => {
      }
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        insert_drive_file: require('@bbx/vector~master/core/assets/svg/material/insert_drive_file.svg'),
        note_add: require('@bbx/vector~master/core/assets/svg/material/note_add.svg'),
      },
      state: {
        /**
         * @type {File[]}
         **/
        files: [],
        /**
         * @type {Object.<string, ApiUpload>}
         **/
        apiUploads: {},
        /**
         * @type boolean
         **/
        uploading: false,
      },
    }
  },
  watch: {
    'files': function (v) {
      this.state.files = v
    },
    'state.files': function (v) {
      this.onFileChange(v)
    }
  },
  computed: {},
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {

    async onFileNativeChange(event) {

      /** @type string[] */
      const uploadIds = [];

      for (let file of event.target.files) {
        const apiUpload = new ApiUpload({
          file: file,
        });
        uploadIds.push(apiUpload.id);
        this.state.apiUploads = Object.assign({}, this.state.apiUploads, {[apiUpload.id]: apiUpload})
      }

      await this.uploads(uploadIds);

      this.$refs.input.value = '';
    },
    /**
     * @param {string[]} uploadIds
     * @returns {Promise<void>}
     */
    async uploads(uploadIds) {

      if (this.state.uploading) return;

      this.state.uploading = true;

      for (let id of uploadIds) {

        /** @type {ApiUpload} */
        const apiUpload = this.state.apiUploads[id];

        /** @type {ApiDownload} */
        const apiDownload = await apiClient.upload(apiUpload);

        let a = apiUpload.file.name.split('.');
        a.pop();
        const name = a.join('.');

        const file = new File({
          uuid: apiUpload.id,
          name: name,
          octet: apiUpload.file.size,
          ext: apiUpload.ext,
          url: apiDownload.url,
        })

        try {
          let {
            success,
            data
          } = await apiClient.do(createFile, createFile.with(file))
          if (!success) {
            displayMessage({
              vue: this,
              type: 'error',
              message: `il y a eu une erreur lors de l'upload du fichier ${apiUpload.file.name}`
            });
          } else {
            const file = new File(data)
            this.onNewFile(file)
            this.state.files.push(file)
          }
        } catch (err) {
          sendError(err);
          displayMessage({
            vue: this,
            type: 'error',
            message: `Le serveur n'est pas disponible pour le moment, réessayez plus tard`
          });
        }

        delete this.state.apiUploads[id];
        this.state.apiUploads = Object.assign({}, this.state.apiUploads)
      }

      uploadIds = [];
      for (let id in this.state.apiUploads) {
        uploadIds.push(id);
      }

      this.state.uploading = false;
      if (uploadIds.length) await this.uploads(uploadIds);
    },
    /**
     *
     * @param {ApiUpload} apiUpload
     * @returns {string}
     */
    fileName(apiUpload) {
      let names = apiUpload.file.name.split('.')
      names.pop()
      return names.join('.')
    },
    /**
     *
     * @param {number} octet
     * @returns {string}
     */
    fileUnit(octet) {
      const unit = new Unit()
      const {
        name,
        value
      } = unit.size({octet: octet})
      return `${value} ${name}`;
    },
  }
}
</script>
