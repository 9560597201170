"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Term = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Translation_1 = require("./Translation");
var Browser_1 = require("./Browser");
var Node_1 = require("./Node");
var Gender_1 = require("./Gender");
var Product_1 = require("./Product");
var Article_1 = require("./Article");
var Highlight_1 = require("./Highlight");
var Detail_1 = require("./Detail");
var Category_1 = require("./Category");
var Page_1 = require("./Page");
var Path_1 = require("./Path");
var Material_1 = require("./Material");
var Shape_1 = require("./Shape");
var Collection_1 = require("./Collection");
var Carousel_1 = require("./Carousel");
var Term = /** @class */ (function () {
    function Term(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.isHtml = deltaService_1.deltaService.boolean(props.isHtml, false);
        this.translations = deltaService_1.deltaService.array(props.translations, Translation_1.Translation);
        this.browsers = deltaService_1.deltaService.array(props.browsers, Browser_1.Browser);
        this.nodes = deltaService_1.deltaService.array(props.nodes, Node_1.Node);
        this.genders = deltaService_1.deltaService.array(props.genders, Gender_1.Gender);
        this.productMaintains = deltaService_1.deltaService.array(props.productMaintains, Product_1.Product);
        this.productDescriptions = deltaService_1.deltaService.array(props.productDescriptions, Product_1.Product);
        this.productNames = deltaService_1.deltaService.array(props.productNames, Product_1.Product);
        this.articleNames = deltaService_1.deltaService.array(props.articleNames, Article_1.Article);
        this.articleDescriptions = deltaService_1.deltaService.array(props.articleDescriptions, Article_1.Article);
        this.highlightValues = deltaService_1.deltaService.array(props.highlightValues, Highlight_1.Highlight);
        this.detailValues = deltaService_1.deltaService.array(props.detailValues, Detail_1.Detail);
        this.categories = deltaService_1.deltaService.array(props.categories, Category_1.Category);
        this.pageTitles = deltaService_1.deltaService.array(props.pageTitles, Page_1.Page);
        this.pageDescriptions = deltaService_1.deltaService.array(props.pageDescriptions, Page_1.Page);
        this.pageH1s = deltaService_1.deltaService.array(props.pageH1s, Page_1.Page);
        this.pageStories = deltaService_1.deltaService.array(props.pageStories, Page_1.Page);
        this.paths = deltaService_1.deltaService.array(props.paths, Path_1.Path);
        this.materials = deltaService_1.deltaService.array(props.materials, Material_1.Material);
        this.shapes = deltaService_1.deltaService.array(props.shapes, Shape_1.Shape);
        this.collectionDescriptions = deltaService_1.deltaService.array(props.collectionDescriptions, Collection_1.Collection);
        this.carouselTitles = deltaService_1.deltaService.array(props.carouselTitles, Carousel_1.Carousel);
        this.carouselDescriptions = deltaService_1.deltaService.array(props.carouselDescriptions, Carousel_1.Carousel);
    }
    return Term;
}());
exports.Term = Term;
