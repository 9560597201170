<style scoped>
.Async--loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <Visible :registerCallback="_registerCallback" :onVisibilityChanged="onVisibilityChanged">
    <slot v-if="isReady" :data="{item:state.item}"></slot>
    <div class="Async--loader" v-else>
      <Loader></Loader>
    </div>
  </Visible>
</template>

<script>

import {eventService} from "../../service/eventService";
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {EVENT} from "../../../@common/constant/EVENT";
import {sendError} from "../../function/sendError";
import {seoService} from "../../service/seoService";

export default {
  props: {
    registerCallback: {
      type: Function,
      default: () => () => {
      }
    },
    fetch: {
      type: Function,
      default: () => async () => {
      }
    }
  },
  data() {
    return {
      state: {
        isVisible: false,
        started: false,
        item: null
      },
      events: []
    }
  },
  watch: {
    'state.isVisible': function (isVisible) {
      if (isVisible) this.start().catch(sendError)
    }
  },
  computed: {
    isReady() {
      return this.state.started && (this.state.isVisible || seoService.isActive)
    }
  },
  beforeMount() {
    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
    if (seoService.isActive) this.start().catch(sendError) // if seo is activated we start the fetch even if it is not visible on page
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    async start() {
      if (this.state.started) return

      this.state.item = await this.fetch()

      this.state.started = true
    },
    onVisibilityChanged({isVisible}) {
      if (isVisible) this.state.isVisible = isVisible
    },
    _registerCallback({onScroll, onResize}) {

      this.registerCallback({onScroll, onResize})

      const event1 = new ListenerRegister({
        name: EVENT.SCROLL_CHANGE,
        callback: ({top, left}) => onScroll({top, left})
      })

      const event2 = new ListenerRegister({
        name: EVENT.SIZE_CHANGED,
        callback: () => onResize()
      })

      eventService.register(event1)
      eventService.register(event2)

      this.events.push(event1)
      this.events.push(event2)
    },
  }
}
</script>