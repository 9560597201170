"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getLangByNuxtProp = void 0;
var LANG_1 = require("../../@common/constant/LANG");
function getLangByNuxtProp(nuxtProp) {
    var langues = [
        LANG_1.LANG_ISO.FR,
        LANG_1.LANG_ISO.EN,
    ];
    return nuxtProp.url && nuxtProp.url.paths && nuxtProp.url.paths[0] && langues.includes(nuxtProp.url.paths[0].value) ? nuxtProp.url.paths[0].value : LANG_1.LANG_ISO.FR;
}
exports.getLangByNuxtProp = getLangByNuxtProp;
