<style scoped>
.InputNumber {
}

.InputNumber ::v-deep .InputLabel {
  padding-bottom: 15px;
}

.InputNumber ::v-deep .InputError {
  padding-top: 10px;
}
</style>

<template>
  <div class="InputNumber">
    <InputLabel v-if="label.id" :term="label" :svg="svg" :mandatory="mandatory" :helper="helper"/>
    <el-input :value="state.value" @input="v => state.value = v" :placeholder="state.placeholder" :disabled="disabled"/>
    <InputError v-if="error.id" :term="error"/>
  </div>
</template>

<script>

import {Term as TermDelta} from '../../../@common/delta/database/Term.ts'
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {defined} from "../../../@common/function/defined";
import {getDecimal} from "../../../@common/function/getDecimal";

export default {

  props: {
    svg: {
      type: String,
      default: () => ''
    },
    helper: {
      type: String,
      default: () => ''
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    mandatory: {
      type: Boolean,
      default: () => false
    },
    value: {
      type: Number,
      default: () => 0
    },
    onChangeValue: {
      type: Function,
      default: () => () => {
      }
    },
    decimal: {
      type: Number,
      default: () => 2
    },
    format: {
      type: Function,
      default: number => number.toString()
    },
    error: {
      default: () => new TermDelta()
    },
    label: {
      default: () => new TermDelta()
    }
  },
  data() {
    const lang = getLangServiceBrowser()
    return {
      props: {},
      state: {
        /**
         * @type number
         */
        value: this.value,
        /**
         * @type string
         */
        placeholder: lang.translate(104)
      },
    }
  },
  watch: {
    'value': function (v) {
      this.state.value = this.formatIn(v);
    },
    'state.value': function (v) {
      this.onChangeValue(this.formatOut(v))
    }
  },
  computed: {},
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    /**
     * @param {number} v
     * @returns {string}
     */
    formatIn(v) {
      /** @type {string} */
      let nv = this.format(v);
      if (!(defined(nv) && nv.constructor.name === "String")) throw new Error(`format should return string`)
      nv = nv.replace(/[^0-9.,-]/g, '')
      if (/[.]/g.test(nv)) {
        const numberSliteds = nv.split('.')
        if (numberSliteds[1].length > this.decimal) {
          nv = getDecimal(parseFloat(nv), this.decimal)
        }
      }
      return nv
    },
    /**
     * @param {string} v
     * @returns {number}
     */
    formatOut(v) {
      v = v.toString().replace(/[.,]/g, '.')
      const nv = parseFloat(v)
      return nv
    }
  }
}
</script>
