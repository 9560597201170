<style scoped lang="scss">

@import "../../scss/color";

.PostionToggle {
  display: flex;
}

.PostionToggle--buttons {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.PostionToggle--buttonRow {
  display: flex;
}

.PostionToggle--button {
  width: 40px;
  height: 40px;
  border-radius: 4px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 2px;
  cursor: pointer;
}

.PostionToggle--button:hover {
  border-color: $primary;
}

.PostionToggle--button ::v-deep .Vector {
  width: 18px;
  height: 18px;
}

.PostionToggle--button-selected {
  background: rgba(0, 0, 0, 0.05);
}
</style>

<template>
  <div class="PostionToggle" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <div class="PostionToggle--buttons">
        <div class="PostionToggle--buttonRow">
          <Tooltip :text="lang.translate(693)" position="top">
            <div class="PostionToggle--button" :class="class_selected(state.position.top)"
                 @click="() => onToggle('top')">
              <Vector :svg="icon.align_vertical_top"></Vector>
            </div>
          </Tooltip>
        </div>
        <div class="PostionToggle--buttonRow">
          <Tooltip :text="lang.translate(694)" position="left">
            <div class="PostionToggle--button" :class="class_selected(state.position.left)"
                 @click="() => onToggle('left')">
              <Vector :svg="icon.align_horizontal_left"></Vector>
            </div>
          </Tooltip>
          <Tooltip :text="lang.translate(695)">
            <div class="PostionToggle--button" :class="class_selected(state.position.center)"
                 @click="() => onToggle('center')">
              <Vector :svg="icon.align_horizontal_center"></Vector>
            </div>
          </Tooltip>
          <Tooltip :text="lang.translate(696)" position="right">
            <div class="PostionToggle--button" :class="class_selected(state.position.right)"
                 @click="() => onToggle('right')">
              <Vector :svg="icon.align_horizontal_right"></Vector>
            </div>
          </Tooltip>
        </div>
        <div class="PostionToggle--buttonRow">
          <Tooltip :text="lang.translate(697)" position="bottom">
            <div class="PostionToggle--button" :class="class_selected(state.position.bottom)"
                 @click="() => onToggle('bottom')">
              <Vector :svg="icon.align_vertical_bottom"></Vector>
            </div>
          </Tooltip>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>

// -----

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {eventService} from "../../service/eventService";
import {Position} from "../../../@common/delta/database/Position";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

// -----

export default {
  props: {
    position: {
      type: Position,
      default: () => new Position()
    },
    onPositionChange: {
      type: Function,
      default: () => () => {
      }
    }
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
        align_vertical_top: require('@bbx/vector~master/core/assets/svg/material/keyboard_arrow_up.svg'),
        align_horizontal_right: require('@bbx/vector~master/core/assets/svg/material/keyboard_arrow_right.svg'),
        align_horizontal_center: require('@bbx/vector~master/core/assets/svg/material/drag_handle.svg'),
        align_vertical_bottom: require('@bbx/vector~master/core/assets/svg/material/keyboard_arrow_down.svg'),
        align_horizontal_left: require('@bbx/vector~master/core/assets/svg/material/keyboard_arrow_left.svg'),
      },
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              width: 0
            }),
            new BreakPoint({
              name: SCREEN.TABLET,
              width: 1024
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              width: 1440
            }),
          ],
          verticalBreakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              height: 0
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              height: 600
            }),
          ],
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      state: {
        position: new Position(this.position)
      },
      events: []
    }
  },
  watch: {
    'position': {
      handler(v) {
        this.state.position = v
      },
      deep: true
    },
    'state.position': {
      handler(v) {
        this.onPositionChange(v)
      },
      deep: true
    }
  },
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`PostionToggle-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`PostionToggle-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`PostionToggle-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`PostionToggle-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`PostionToggle-desktopY`)
      return classes
    }
  },
  beforeMount() {
    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    class_selected(isToogle) {
      const classes = []
      if (isToogle) classes.push(`PostionToggle--button-selected`)
      return classes
    },
    /**
     * @param {string} direction
     */
    onToggle(direction) {

      const oldPosition = this.state.position
      const newPosition = new Position(oldPosition)

      newPosition[direction] = !newPosition[direction]

      if (oldPosition.right && newPosition.left) newPosition.right = false
      else if (oldPosition.top && newPosition.bottom) newPosition.top = false
      else if (oldPosition.left && newPosition.right) newPosition.left = false
      else if (oldPosition.bottom && newPosition.top) newPosition.bottom = false

      if (!oldPosition.center && newPosition.center) {
        newPosition.right = false
        newPosition.top = false
        newPosition.left = false
        newPosition.bottom = false
      }

      if ((newPosition.top || newPosition.bottom) && (newPosition.right || newPosition.left)) newPosition.center = false

      this.state.position = newPosition
    }
  }
}
</script>