<style scoped>
.Ariane {
}

.Ariane--line {
  display: flex;
  align-items: center;
}

.Ariane--link {
  font-family: 'DDinBold', sans-serif;
  letter-spacing: 1px;
  font-size: 12px;
  text-transform: uppercase;
  padding-right: 4px;
  text-align: center;
}

.Ariane--link ::v-deep .Link {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
  transition: color 250ms;
}

.Ariane--link:hover ::v-deep .Link {
  color: rgba(0, 0, 0, 1);
}

.Ariane--link ::v-deep h1 {
  font-size: 12px;
}

.Ariane--triangle {
  padding: 0px 10px;
  width: 24px;
  position: relative;
  top: 2px;
}

.Ariane--triangle ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 0.25);
}
</style>

<template>
  <div class="Ariane">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="Ariane--line">
        <template v-for="(computedPage, page_index) in computedPages">
          <template v-for="url in computedPage.urls">
            <template v-for="(sort, index_sort) in sorts(url)" v-if="truncate ? index_sort === sorts(url).length -1 : true">
              <template v-for="path in paths(sort)">
                <template v-for="term in path.terms">
                  <div class="Ariane--link" :key="`${computedPage.id}.${url.id}.${sort.id}.${path.id}.${term.id}`" v-show="onlyTheLast ? page_index === computedPages.length -1  : true">
                    <template v-if="page_index !== computedPages.length -1">
                      <Link :url="url">
                        <Term :term="term"/>
                      </Link>
                    </template>
                    <template v-else>
                      <Term :term="term" :tag="h1 ? 'h1' : 'p'"/>
                    </template>
                  </div>
                  <template v-if="page_index !== computedPages.length -1">
                    <div class="Ariane--triangle" v-show="onlyTheLast ? page_index === computedPages.length -1  : true">
                      <Vector :svg="icon.keyboard_arrow_right"/>
                    </div>
                  </template>
                </template>
              </template>
            </template>
          </template>
        </template>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {Page} from "../../../@common/delta/database/Page";

export default {
  props: {
    pages: {
      type: Array,
      default: () => ([])
    },
    h1: {
      type: Boolean,
      default: () => false
    },
    truncate: {
      type: Boolean,
      default: () => true
    },
    onlyTheLast: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      state: {},
      icon: {
        keyboard_arrow_right: require('@bbx/vector~master/core/assets/svg/material/keyboard_arrow_right.svg'),
      }
    }
  },
  watch: {},
  computed: {
    /**
     * @returns {Page[]}
     */
    computedPages() {
      let pages = this.pages.map(page => new Page(page))
      return pages
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    sorts(url) {
      url.sorts = url.sorts.sort((a, b) => a.order - b.order)
      // url.sorts = url.sorts.filter((sort, i) => i === url.sorts.length - 1)
      return url.sorts
    },
    paths(sort) {
      // sort.paths = sort.paths.filter((path, i) => i === sort.paths.length - 1)
      return sort.paths
    }
  }
}
</script>
