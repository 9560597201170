"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.MATERIAL_SEARCH_ID = exports.MATERIAL = void 0;
exports.MATERIAL = {
    TECK: 8,
    DURATECK: 44,
};
exports.MATERIAL_SEARCH_ID = {
    ID: 1,
    VALUE: 2,
};
