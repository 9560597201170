"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.History = void 0;
var stateService_1 = require("../service/stateService");
var History = /** @class */ (function () {
    function History() {
        this.max = 20;
    }
    History.prototype.get = function () {
        var links = stateService_1.stateService.get(stateService_1.STATE_NAVIGATIONS);
        if (!links)
            links = [];
        return links;
    };
    History.prototype.set = function (path) {
        var links = this.get();
        if (links.length > this.max)
            links.shift();
        links.push(path);
        stateService_1.stateService.set(stateService_1.STATE_NAVIGATIONS, links);
    };
    History.prototype.setUnique = function (path) {
        var links = this.get();
        if (links.pop() !== path)
            this.set(path);
    };
    return History;
}());
exports.History = History;
