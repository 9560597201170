<style scoped>
.ArticleSearch {
  width: 100%;
  height: 100%;
  display: flex;
}

.ArticleSearch--rows {
  height: inherit;
  max-height: 100%;
  overflow: auto;
  box-sizing: border-box;
}

.ArticleSearch-desktop .ArticleSearch--rows {
  display: flex;
  flex-direction: row;
  justify-content: center;
  padding: 50px 150px;
}

.ArticleSearch-tablet .ArticleSearch--rows {
  padding: 50px 150px;
}

.ArticleSearch-mobile .ArticleSearch--rows {
  padding: 25px;
}

.ArticleSearch--empty {
  width: 100%;
  min-width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ArticleSearch--row {
  box-sizing: border-box;
}

.ArticleSearch-desktop .ArticleSearch--row {
  min-width: 25%;
  width: 25%;
  padding: 0 25px;
}

.ArticleSearch-tablet .ArticleSearch--row {
  min-width: 100%;
  width: 100%;
}

.ArticleSearch-mobile .ArticleSearch--row {
  min-width: 100%;
  width: 100%;
}

.ArticleSearch--loader {
}

.ArticleSearch-desktop .ArticleSearch--loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ArticleSearch-tablet .ArticleSearch--loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ArticleSearch-mobile .ArticleSearch--loader {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  padding-top: 50px;
}
</style>

<template>
  <div class="ArticleSearch" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="ArticleSearch--loader" v-show="state.loadingSelect">
        <Loader></Loader>
      </div>
      <div v-show="!state.loadingSelect" class="ArticleSearch--rows">
        <div v-show="!state.loadingSelect && !state.articles.length && search" class="ArticleSearch--empty">Aucun
          résultat
        </div>
        <div class="ArticleSearch--row" v-show="column1.length">
          <ArticleList v-for="(article, index) in column1" :index="0 + index" :key="article.id" :article="article"></ArticleList>
        </div>
        <div class="ArticleSearch--row" v-show="column2.length">
          <ArticleList v-for="(article, index) in column2" :index="2 + index" :key="article.id" :article="article"></ArticleList>
        </div>
        <div class="ArticleSearch--row" v-show="column3.length">
          <ArticleList v-for="(article, index) in column3" :index="4 + index" :key="article.id" :article="article"></ArticleList>
        </div>
        <div class="ArticleSearch--row" v-show="column4.length">
          <ArticleList v-for="(article, index) in column4" :index="6 + index" :key="article.id" :article="article"></ArticleList>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {PATHS} from "../../../@common/constant/PATHS";
import {apiClient} from "../../service/apiClientService";
import select, {Request} from "../../../@common/api/article/selectArticles";
import {Order} from "../../../@common/delta/database/Order";
import {Basket} from "../../../@common/delta/database/Basket";
import {Debounce} from "../../../@common/ts/Debounce";
import {sendError} from "../../function/sendError";

let debounce = new Debounce()

export default {

  props: {
    search: {
      type: String,
      default: () => ''
    }
  },
  data() {
    return {
      PATHS,
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 920
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1500
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      state: {
        loadingSelect: false,
        articles: []
      },
    }
  },
  watch: {
    'search': {
      handler: function (v) {
        this.select().catch(sendError)
      }
    }
  },
  computed: {
    column1() {
      return this.state.articles.slice(0, 2)
    },
    column2() {
      return this.state.articles.slice(2, 4)
    },
    column3() {
      return this.state.articles.slice(4, 6)
    },
    column4() {
      return this.state.articles.slice(6, 8)
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`ArticleSearch-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`ArticleSearch-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`ArticleSearch-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`ArticleSearch-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`ArticleSearch-desktopY`)
      return classes
    },
    order() {
      return new Order({
        baskets: [
          {
            articles: this.state.articles
          }
        ]
      })
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    async select() {
      this.state.loadingSelect = true
      if (!this.search) {
        this.state.loadingSelect = false
        return this.state.articles = []
      }
      /** @type {Request} */
      const filter = {
        $scope: 5,
        active: true,
        visible: true,
        $search: this.search,
        $sortByBatchFirst: false,
        $sortByOutletFirst: false,
        $count: false,
        limit: 8,
        offset: 0,
      }
      debounce.callback({
        job: () => apiClient.do(select, select.with(filter)),
        success: res => {
          this.state.articles = res.data;
          this.state.loadingSelect = false
        },
        error: err => {
          this.state.loadingSelect = false
          sendError(err)
        }
      })
    },
  }
}
</script>
