"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFlatBasketArticle = void 0;
var langServiceBrowser_1 = require("../service/langServiceBrowser");
function getFlatBasketArticle(order, symbol) {
    if (symbol === void 0) { symbol = ', '; }
    var infos = {};
    var flats = [];
    order.baskets.forEach(function (basket) {
        basket.articles.forEach(function (article) {
            article.batches.forEach(function (batch) {
                batch.products.forEach(function (product) {
                    infos[product.id] = { quantity: 0, reference: '', name: '' };
                });
            });
        });
    });
    order.baskets.forEach(function (basket) {
        basket.articles.forEach(function (article) {
            article.batches.forEach(function (batch) {
                batch.products.forEach(function (product) {
                    infos[product.id].quantity += basket.quantity * batch.quantity;
                    infos[product.id].reference = product.reference;
                    infos[product.id].name = langServiceBrowser_1.getLangServiceBrowser().translateTerm(product.termNames[0]);
                });
            });
        });
    });
    for (var productId in infos) {
        var info = infos[productId];
        flats.push(("( " + info.reference + " x " + info.quantity + " ) " + info.name).replace(/,/g, ''));
    }
    return flats.join(symbol);
}
exports.getFlatBasketArticle = getFlatBasketArticle;
