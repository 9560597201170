<style scoped>
.AccountExportation {
  min-height: 100vh;
}

.AccountExportation--cards {
  display: flex;
  flex-wrap: wrap;
}

.AccountExportation-desktop .AccountExportation--cards {
}

.AccountExportation-tablet .AccountExportation--cards {
}

.AccountExportation-mobile .AccountExportation--cards {
  justify-content: center;
  flex-direction: column;
  align-items: center;
}

.AccountExportation--card {

}

.AccountExportation-desktop .AccountExportation--card {
  margin-left: 50px;
  margin-bottom: 50px;
}

.AccountExportation-tablet .AccountExportation--card {
  margin-left: 50px;
  margin-bottom: 50px;
}

.AccountExportation-mobile .AccountExportation--card {
  margin-bottom: 50px;
  width: 100%;
}

.AccountExportation-mobile .AccountExportation--card ::v-deep .Card {
  max-width: initial;
}

.AccountExportation--header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-bottom: 50px;
}

.AccountExportation-desktop .AccountExportation--header {
  min-width: 350px;
}

.AccountExportation-tablet .AccountExportation--header {
  min-width: 350px;
}

.AccountExportation-mobile .AccountExportation--header {
  width: 100%;
}

.AccountExportation--type {
  display: flex;
  align-items: center;
}

.AccountExportation--icon {
  width: 24px;
  position: relative;
  top: 2px;
}

.AccountExportation--title {
  font-family: 'RalewayRegular';
  font-size: 14px;
  color: #808080;
  padding-left: 8px;
}

.AccountExportation--count {
  font-family: 'ComfortaaRegular';
  font-size: 32px;
  color: #000000;
}

.AccountExportation--footer {
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}
</style>

<template>
  <div class="AccountExportation" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="AccountExportation--cards" v-show="!state.loadingSelect">
        <div class="AccountExportation--card">
          <Card>
            <div class="AccountExportation--header">
              <div class="AccountExportation--type">
                <div class="AccountExportation--icon">
                  <Vector :svg="icon.people"/>
                </div>
                <div class="AccountExportation--title">
                  <Term :term="lang.term(150)"/>
                </div>
              </div>
              <div class="AccountExportation--count">
                {{ state.count[CARD_TYPE.user] }}
              </div>
            </div>
            <div class="AccountExportation--footer">
              <ButtonAction :svg="icon.getApp"
                            :term="lang.term(423)"
                            :onClick="() => createExportUser()"
                            :loading="state.loadingCreateExportUser">
              </ButtonAction>
            </div>
          </Card>
        </div>
        <div class="AccountExportation--card">
          <Card>
            <div class="AccountExportation--header">
              <div class="AccountExportation--type">
                <div class="AccountExportation--icon">
                  <Vector :svg="icon.shoppingCart"/>
                </div>
                <div class="AccountExportation--title">
                  <Term :term="lang.term(151)"/>
                </div>
              </div>
              <div class="AccountExportation--count">
                {{ state.count[CARD_TYPE.product] }}
              </div>
            </div>
            <div class="AccountExportation--footer">
              <ButtonAction :term="lang.term(423)"
                            :svg="icon.getApp"
                            :onClick="() => createExportArticle()"
                            :loading="state.loadingCreateExportArticle">
              </ButtonAction>
            </div>
          </Card>
        </div>
      </div>
      <LoaderSpace v-show="state.loadingSelect"></LoaderSpace>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {Newsletter} from "../../../@common/delta/database/Newsletter";
import {STATE_TOKEN, stateService} from "../../service/stateService";
import {apiClient} from "../../service/apiClientService";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import selectUserCount from "../../../@common/api/user/selectUserCount";
import selectArticleCount from "../../../@common/api/article/selectArticleCount";
import {User} from "../../../@common/delta/database/User";

import {CARD_TYPE} from "../../../@common/constant/CARD_TYPE";
import {ROLE} from "../../../@common/constant/ROLE";
import axios from 'axios'
import {controlForm} from "../../function/controlForm";
import exportUser from "../../../@common/api/user/exportUser";
import exportArticle from "../../../@common/api/article/exportArticle";
import {documentMakerConfigPublic} from "../../../../config/public/document-maker.config";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {sendError} from "../../function/sendError";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    },
  },
  data() {
    return {
      CARD_TYPE,
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 920
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      state: {
        token: '',
        loadingSelect: false,
        loadingCreateExportUser: false,
        loadingCreateExportArticle: false,
        form: new ApiResponseForm(),
        newsletter: new Newsletter(),
        user: new User(),
        count: {
          [CARD_TYPE.user]: 0,
          [CARD_TYPE.product]: 1,
        },
        dataUsers: null,
        dataArticles: null
      },
      icon: {
        people: require('@bbx/vector~master/core/assets/svg/material/people.svg'),
        getApp: require('@bbx/vector~master/core/assets/svg/material/get_app.svg'),
        shoppingCart: require('@bbx/vector~master/core/assets/svg/material/shopping_cart.svg'),
      },
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`AccountExportation-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`AccountExportation-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`AccountExportation-desktop`)
      return classes
    },
  },
  beforeMount() {
  },
  mounted() {
    this.state.token = stateService.get(STATE_TOKEN)
    stateService.watch(STATE_TOKEN, v => this.state.token = v)
    this.init().catch(sendError)
  },
  beforeDestroy() {
  },
  methods: {
    async init() {
      this.state.loadingSelect = true
      await Promise.all([
        this.getCountType(CARD_TYPE.user),
        this.getCountType(CARD_TYPE.product),
      ])
      this.state.loadingSelect = false;
    },
    async getCountType(type) {
      if (type == 0) {
        const {data} = await apiClient.do(selectUserCount, selectUserCount.with(this.getFilterUser()));
        this.state.count[type] = data;
      } else if (type == 1) {
        const {data} = await apiClient.do(selectArticleCount, selectArticleCount.with(this.getFilterArticle()));
        this.state.count[type] = data;
      }
    },
    async createExportUser() {

      const payload = this.getFilterUser()

      const res = await controlForm({
        apiAction: exportUser,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loadingCreateExportUser',
        loaderState: this.state,
        payload: payload,
        vue: this,
      });

      this.downloadCSV(CARD_TYPE.user, res.data.$id).catch(() => alert('impossible de faire un export pour le moment.'))
    },
    async createExportArticle() {

      let payload = this.getFilterArticle()

      const res = await controlForm({
        apiAction: exportArticle,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loadingCreateExportArticle',
        loaderState: this.state,
        payload: payload,
        vue: this,
      });

      this.downloadCSV(CARD_TYPE.product, res.data.$id).catch(() => alert('impossible de faire un export pour le moment.'))
    },
    async downloadCSV(type, id) {
      let element = document.createElement('a');
      let now = new Date();
      let download, fileName;
      if (type == 0) {
        download = await axios.get(documentMakerConfigPublic.urlDownload, {params: {id}});
        fileName = "users"
      } else if (type == 1) {
        download = await axios.get(documentMakerConfigPublic.urlDownload, {params: {id}});
        fileName = "articles"
      }
      let csvData = new Blob(["\uFEFF" + download.data], {type: 'text/csv; charset=utf-8'});
      let csvUrl = URL.createObjectURL(csvData);
      element.setAttribute('href', csvUrl);
      element.setAttribute('download', 'les_jardins_export_' + fileName + '_' + now.toLocaleDateString("fr-FR") + '_' + now.toLocaleTimeString() + '.csv');
      element.style.display = 'none';
      document.body.appendChild(element);
      element.click();
    },

    getFilterUser() {
      return {
        active: true,
        roles: [
          {
            id: ROLE.CUSTOMER,
          },
          {
            id: ROLE.SELLER
          }
        ]
      }
    },
    getFilterArticle() {
      return {
        active: true,
        visible: true,
      }
    },
  }
}
</script>
