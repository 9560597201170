<style scoped>
.ScrollZone {
}

.ScrollZone--slotBox {
  overflow: hidden;
  position: relative;
}

.ScrollZone--slot {
  position: relative;
  width: 100%;
  height: 100%;
  overflow-y: hidden;
  overflow-x: scroll;
}

/*
 * * * * * * * * * *
 *
 *   OVERRIDE
 *
 * * * * * * * * * *
 */

.ScrollZone ::v-deep .scrollbar-track {
  background: rgba(0, 0, 0, 0);
}

.ScrollZone ::v-deep .scrollbar-track-x {
  height: 0px;
}

.ScrollZone ::v-deep .scrollbar-thumb {
  border-radius: 0;
  background: rgba(0, 0, 0, 0);
}

.ScrollZone ::v-deep .scrollbar-thumb-x {
  height: 0px;
}
</style>

<template>
  <div class="ScrollZone" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <div class="ScrollZone--slotBox" :style="style_slotBox">
        <div class="ScrollZone--slot" :style="style_slot" ref="slot">
          <slot></slot>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>

// -----

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import Scrollbar from '@bbx/scrollbar~master/core/env/browser/ts/Scrollbar';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {eventService} from "../../service/eventService";
import {EVENT} from "../../../@common/constant/EVENT";

// -----

export default {
  props: {
    onScroll: {
      type: Function,
      default: () => (props = {top: 0, left: 0}) => {
      }
    },
    registerCallback: {
      type: Function,
      default: () => ({scrollTo}) => {
      }
    },
  },
  data() {
    return {
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              width: 0
            }),
            new BreakPoint({
              name: SCREEN.TABLET,
              width: 1024
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              width: 1440
            }),
          ],
          verticalBreakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              height: 0
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              height: 600
            }),
          ],
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      state: {
        scrollbar: new Scrollbar(),
        scrollbarSize: 0,
      },
      events: []
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`ScrollZone-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`ScrollZone-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`ScrollZone-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`ScrollZone-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`ScrollZone-desktopY`)
      return classes
    },
    style_slotBox() {
      let style = {}
      style.marginTop = `-${this.state.scrollbarSize}px`
      return style
    },
    style_slot() {
      let style = {}
      style.top = `${this.state.scrollbarSize}px`
      return style
    }
  },
  beforeMount() {
    this.registerCallback({
      scrollTo: props => this.scrollTo(props),
    })

    // -----

    let sto = null
    this.events.push(new ListenerRegister({
      name: EVENT.SIZE_CHANGED,
      callback: () => {
        if (sto) clearTimeout(sto)
        sto = setTimeout(() => {
          this.state.scrollbarSize = this.state.scrollbar.getNativeSize()
        }, 250)
      }
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
    this.state.scrollbarSize = this.state.scrollbar.getNativeSize()

    // -----

    this.$refs.slot.addEventListener('scroll', this._onScroll)
  },
  beforeDestroy() {

    this.$refs.slot.removeEventListener('scroll', this._onScroll)

    // -----

    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    /**
     *
     * @param {Event} event
     * @private
     */
    _onScroll(event) {
      this.onScroll({left: event.target.scrollLeft, right: event.target.scrollTop})
    },
    /**
     *
     * @param {Event} event
     * @private
     */
    scrollTo(props = {left: 0, top: 0}) {
      this.$refs.slot.scrollLeft = props.left;
      this.$refs.slot.scrollTop = props.top;
    }
  }
}
</script>