"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getArticleTermNames = void 0;
var Term_1 = require("../../@common/delta/database/Term");
var Translation_1 = require("../../@common/delta/database/Translation");
var Lang_1 = require("../../@common/delta/database/Lang");
var langServiceBrowser_1 = require("../../@common/service/langServiceBrowser");
function getArticleTermNames(article) {
    var lang = langServiceBrowser_1.getLangServiceBrowser();
    var terms = [];
    var references = [];
    for (var _i = 0, _a = article.batches; _i < _a.length; _i++) {
        var batch = _a[_i];
        for (var _b = 0, _c = batch.products; _b < _c.length; _b++) {
            var product = _c[_b];
            references.push(product.reference);
        }
    }
    if (references.length)
        terms.push(new Term_1.Term({
            translations: [
                new Translation_1.Translation({
                    langs: [
                        new Lang_1.Lang({
                            iso6391: lang.lang.iso6391
                        })
                    ],
                    value: "( " + references.join(' + ') + " )"
                }),
            ]
        }));
    for (var _d = 0, _e = article.termNames; _d < _e.length; _d++) {
        var termName = _e[_d];
        terms.push(termName);
    }
    return terms;
}
exports.getArticleTermNames = getArticleTermNames;
