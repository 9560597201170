<style scoped lang="scss">

@import "../../scss/size";

.MarqueSolar {

}

/*
 * * * * * * * * * *
 *
 *   First
 *
 * * * * * * * * * *
 */

.MarqueSolar--first {

}

.MarqueSolar-desktop .MarqueSolar--first {
  max-width: 800px;
  margin: auto;
  text-align: center;
}

.MarqueSolar-tablet .MarqueSolar--first {
  max-width: 800px;
  margin: auto;
  text-align: center;
}

.MarqueSolar-mobile .MarqueSolar--first {
  padding: 0 20px;
  text-align: center;
}

/*
 * * * * * * * * * *
 *
 *   Second
 *
 * * * * * * * * * *
 */

.MarqueSolar--second {
  display: flex;
  align-items: center;
}

.MarqueSolar-desktop .MarqueSolar--second {

}

.MarqueSolar-tablet .MarqueSolar--second {

}

.MarqueSolar-mobile .MarqueSolar--second {
  flex-direction: column;
}

/**/

.MarqueSolar--secondRight {
  flex: 1;
  display: flex;
  align-items: center;
}

/**/

.MarqueSolar--block-1 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

/**/

.MarqueSolar--block-2 {

}

.MarqueSolar-desktop .MarqueSolar--block-2 {
  max-width: 400px;
}

.MarqueSolar-tablet .MarqueSolar--block-2 {
  max-width: 300px;
}

.MarqueSolar-mobile .MarqueSolar--block-2 {

}

/**/

.MarqueSolar--block-3 {

}

.MarqueSolar-desktop .MarqueSolar--block-3 {
  max-width: 400px;
  text-align: right;
}

.MarqueSolar-tablet .MarqueSolar--block-3 {
  max-width: 300px;
  text-align: right;
}

.MarqueSolar-mobile .MarqueSolar--block-3 {

}

/**/

.MarqueSolar--block-4 {

}

.MarqueSolar-desktop .MarqueSolar--block-4 {
  max-width: 500px;
}

.MarqueSolar-tablet .MarqueSolar--block-4 {
  max-width: 350px;
}

.MarqueSolar-mobile .MarqueSolar--block-4 {

}

/*
 * * * * * * * * * *
 *
 *   Third
 *
 * * * * * * * * * *
 */

.MarqueSolar--third {
  display: flex;
}

.MarqueSolar-desktop .MarqueSolar--third {
  align-items: center;
}

.MarqueSolar-tablet .MarqueSolar--third {
  align-items: center;
}

.MarqueSolar-mobile .MarqueSolar--third {
  flex-direction: column-reverse;
}

/**/

.MarqueSolar--thirdLeft {

}

.MarqueSolar-desktop .MarqueSolar--thirdLeft {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.MarqueSolar-tablet .MarqueSolar--thirdLeft {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.MarqueSolar-mobile .MarqueSolar--thirdLeft {
}

/**/

.MarqueSolar--thirdRight {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}

/*
 * * * * * * * * * *
 *
 *   Four
 *
 * * * * * * * * * *
 */

.MarqueSolar--four {
  display: flex;
}

.MarqueSolar-desktop .MarqueSolar--four {

}

.MarqueSolar-tablet .MarqueSolar--four {

}

.MarqueSolar-mobile .MarqueSolar--four {
  flex-direction: column;
}

/**/

.MarqueSolar--fourLeft {

}

.MarqueSolar--fourRight {
  flex: 1;
  background-color: rgba(236, 232, 228, 0.45);
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.MarqueSolar-desktop .MarqueSolar--fourRight {

}

.MarqueSolar-tablet .MarqueSolar--fourRight {

}

.MarqueSolar-mobile .MarqueSolar--fourRight {
  margin: 0 20px;
  padding: 40px 20px;
}

/*
 * * * * * * * * * *
 *
 *   Five
 *
 * * * * * * * * * *
 */

.MarqueSolar--five {
  text-align: center;
  display: flex;
  justify-content: center;
  font-family: 'AmbroiseRegular', sans-serif;
}

.MarqueSolar-desktop .MarqueSolar--five {
  font-size: 45px;
}

.MarqueSolar-desktop .MarqueSolar--five ::v-deep .Term {
  max-width: 600px;
}

.MarqueSolar-tablet .MarqueSolar--five {
  font-size: 35px;
}

.MarqueSolar-tablet .MarqueSolar--five ::v-deep .Term {
  max-width: 400px;
}

.MarqueSolar-mobile .MarqueSolar--five {
  font-size: 30px;
  padding: 0px 20px 50px 20px;
}

/*
 * * * * * * * * * *
 *
 *   Component
 *
 * * * * * * * * * *
 */

.MarqueSolar--photo-1 {

}

.MarqueSolar-desktop .MarqueSolar--photo-1 {
  height: 700px;
  width: 700px;
}

@media screen and (max-width: $menu-image-screen) {
  .MarqueSolar-desktop .MarqueSolar--photo-1 {
    height: 600px;
    width: 600px;
  }
}

.MarqueSolar-tablet .MarqueSolar--photo-1 {
  height: 500px;
  width: 500px;
}

.MarqueSolar-mobile .MarqueSolar--photo-1 {
  width: 100%;
  height: 500px;
  padding: 20px;
  box-sizing: border-box;
}

/**/

.MarqueSolar--photo-2 {
}

.MarqueSolar-desktop .MarqueSolar--photo-2 {
  width: 350px;
  height: 350px;
}

@media screen and (max-width: $menu-image-screen) {
  .MarqueSolar-desktop .MarqueSolar--photo-2 {
    width: 300px;
    height: 300px;
  }
}

.MarqueSolar-tablet .MarqueSolar--photo-2 {
  display: none;
}

.MarqueSolar-mobile .MarqueSolar--photo-2 {
  display: none;
}

/**/

.MarqueSolar--photo-3 {
}

.MarqueSolar-desktop .MarqueSolar--photo-3 {
  width: 500px;
  height: 500px;
}

.MarqueSolar-tablet .MarqueSolar--photo-3 {
  width: 400px;
  height: 400px;
}

.MarqueSolar-mobile .MarqueSolar--photo-3 {
  width: 100%;
  height: 400px;
  box-sizing: border-box;
  padding: 0 20px;
}


/**/

.MarqueSolar--photo-4 {
}

.MarqueSolar-desktop .MarqueSolar--photo-4 {
  width: 700px;
  height: 600px;
}

.MarqueSolar-tablet .MarqueSolar--photo-4 {
  width: 600px;
  height: 500px;
}

.MarqueSolar-mobile .MarqueSolar--photo-4 {
  width: 100%;
  height: 400px;
  box-sizing: border-box;
  padding: 0 20px;
}

/**/

.MarqueSolar--spacer {
}

.MarqueSolar-desktop .MarqueSolar--spacer {
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
}

.MarqueSolar-tablet .MarqueSolar--spacer {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.MarqueSolar-mobile .MarqueSolar--spacer {
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
}

/**/

.MarqueSolar--title {
  font-family: 'AmbroiseRegular', sans-serif;
  color: #1B1919;
}

.MarqueSolar-desktop .MarqueSolar--title {
  font-size: 45px;
}

.MarqueSolar-tablet .MarqueSolar--title {
  font-size: 35px;
}

.MarqueSolar-mobile .MarqueSolar--title {
  font-size: 30px;
}

/**/

.MarqueSolar--subTitle {
  font-family: 'AmbroiseRegular', sans-serif;
  color: #1B1919;
}

.MarqueSolar-desktop .MarqueSolar--subTitle {
  font-size: 50px;
}

.MarqueSolar-tablet .MarqueSolar--subTitle {
  font-size: 40px;
}

.MarqueSolar-mobile .MarqueSolar--subTitle {
  font-size: 30px;
  padding: 0 20px;
  text-align: center;
}

/**/

.MarqueSolar--paragraph {
  font-family: 'AeonikRegular', sans-serif;
  letter-spacing: 0.25px;
  line-height: 28px;
  color: #31302F;
}

.MarqueSolar-desktop .MarqueSolar--paragraph {
  font-size: 18px;
}

.MarqueSolar-tablet .MarqueSolar--paragraph {
  font-size: 17px;
}

.MarqueSolar-mobile .MarqueSolar--paragraph {
  font-size: 15px;
  text-align: center;
  padding: 0 20px;
}

</style>

<template>
  <div class="MarqueSolar" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <MarqueHeader :term="lang.term(824)" :imgSrc="img.header"></MarqueHeader>
      <div class="MarqueSolar--first">

        <div class="MarqueSolar--spacer"></div>
        <div class="MarqueSolar--spacer"></div>
        <div class="MarqueSolar--spacer"></div>

        <div class="MarqueSolar--title">
          <AnimationTitle :fully="false">
            <Term :term="lang.term(825)" tag="h2"></Term>
          </AnimationTitle>
        </div>

        <div class="MarqueSolar--spacer"></div>
        <div class="MarqueSolar--spacer"></div>

        <div class="MarqueSolar--paragraph">
          <AnimationTitle :fully="false">
            <Term type="textarea" :term="lang.term(826)"></Term>
          </AnimationTitle>
        </div>
      </div>

      <div class="MarqueSolar--spacer"></div>
      <div class="MarqueSolar--spacer"></div>
      <div class="MarqueSolar--spacer"></div>

      <div class="MarqueSolar--spacer"></div>
      <div class="MarqueSolar--spacer"></div>
      <div class="MarqueSolar--spacer"></div>

      <div class="MarqueSolar--second">
        <div class="MarqueSolar--photo-1">
          <ScrollParallax :distance="50" :reverse="true">
            <Picture :src="img.photo1"></Picture>
          </ScrollParallax>
        </div>
        <div class="MarqueSolar--secondRight">
          <div class="MarqueSolar--block-1">
            <div class="MarqueSolar--block-2">
              <div class="MarqueSolar--subTitle">
                <AnimationTitle :fully="false">
                  <Term :term="lang.term(827)" tag="h3"></Term>
                </AnimationTitle>
              </div>
              <div class="MarqueSolar--spacer"></div>
              <div class="MarqueSolar--paragraph">
                <AnimationTitle :fully="false">
                  <Term type="textarea" :term="lang.term(828)"></Term>
                </AnimationTitle>
              </div>
            </div>
          </div>
          <div class="MarqueSolar--photo-2">
            <ScrollParallax :distance="50" :reverse="false">
              <Picture :src="img.photo2"></Picture>
            </ScrollParallax>
          </div>
        </div>
      </div>

      <div class="MarqueSolar--spacer"></div>
      <div class="MarqueSolar--spacer"></div>
      <div class="MarqueSolar--spacer"></div>

      <div class="MarqueSolar--third">
        <div class="MarqueSolar--thirdLeft">
          <div class="MarqueSolar--block-3">
            <div class="MarqueSolar--subTitle">
              <AnimationTitle :fully="false">
                <Term :term="lang.term(829)" tag="h3"></Term>
              </AnimationTitle>
            </div>
            <div class="MarqueSolar--spacer"></div>
            <div class="MarqueSolar--paragraph">
              <AnimationTitle :fully="false">
                <Term type="textarea" :term="lang.term(830)"></Term>
              </AnimationTitle>
            </div>
          </div>
        </div>

        <div class="MarqueSolar--spacer"></div>
        <div class="MarqueSolar--spacer"></div>
        <div class="MarqueSolar--spacer"></div>

        <div class="MarqueSolar--spacer"></div>
        <div class="MarqueSolar--spacer"></div>
        <div class="MarqueSolar--spacer"></div>

        <div class="MarqueSolar--thirdRight">
          <div class="MarqueSolar--photo-3">
            <ScrollParallax :distance="50" :reverse="false">
              <Picture :src="img.photo3"></Picture>
            </ScrollParallax>
          </div>
        </div>
      </div>

      <div class="MarqueSolar--spacer"></div>
      <div class="MarqueSolar--spacer"></div>
      <div class="MarqueSolar--spacer"></div>

      <div class="MarqueSolar--spacer"></div>
      <div class="MarqueSolar--spacer"></div>
      <div class="MarqueSolar--spacer"></div>

      <ScrollParallax :distance="50" :reverse="true">
        <div class="MarqueSolar--four">
          <div class="MarqueSolar--fourLeft">
            <div class="MarqueSolar--photo-4">
              <Picture :src="img.photo4"></Picture>
            </div>
          </div>
          <div class="MarqueSolar--fourRight">
            <div class="MarqueSolar--block-4">
              <div class="MarqueSolar--subTitle">
                <AnimationTitle :fully="false">
                  <Term :term="lang.term(831)" tag="h3"></Term>
                </AnimationTitle>
              </div>
              <div class="MarqueSolar--spacer"></div>
              <div class="MarqueSolar--paragraph">
                <AnimationTitle :fully="false">
                  <Term type="textarea" :term="lang.term(832)"></Term>
                </AnimationTitle>
              </div>
            </div>
          </div>
        </div>
      </ScrollParallax>

      <div class="MarqueSolar--spacer"></div>
      <div class="MarqueSolar--spacer"></div>
      <div class="MarqueSolar--spacer"></div>

      <div class="MarqueSolar--spacer"></div>
      <div class="MarqueSolar--spacer"></div>
      <div class="MarqueSolar--spacer"></div>

      <div class="MarqueSolar--five">
        <AnimationTitle :fully="false">
          <Term :term="lang.term(833)"></Term>
        </AnimationTitle>
      </div>

      <CountryLocation :alternate="true">
        <template slot="default">
          <HomeSpot :nuxtProp="nuxtProp"
                    :breakPoints="props.Responsive.breakPoints"
                    :verticalBreakPoints="props.Responsive.verticalBreakPoints">
          </HomeSpot>
        </template>
        <template slot="alternate">
          <div class="MarqueSolar--spacer"></div>
          <div class="MarqueSolar--spacer"></div>
          <div class="MarqueSolar--spacer"></div>
          <div class="MarqueSolar--spacer"></div>
          <div class="MarqueSolar--spacer"></div>
          <div class="MarqueSolar--spacer"></div>
        </template>
      </CountryLocation>

    </Responsive>
  </div>
</template>

<script>

// -----

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {eventService} from "../../service/eventService";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";

// -----

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    const lang = getLangServiceBrowser();
    return {
      lang,
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      img: {
        header: require('../../../@common/assets/marque/solar/header.jpg'),
        photo1: require('../../../@common/assets/marque/solar/photo1.jpg'),
        photo2: require('../../../@common/assets/marque/solar/photo2.jpg'),
        photo3: require('../../../@common/assets/marque/solar/photo3.jpg'),
        photo4: require('../../../@common/assets/marque/solar/photo4.jpg'),
      },
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              width: 0
            }),
            new BreakPoint({
              name: SCREEN.TABLET,
              width: 1024
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              width: 1440
            }),
          ],
          verticalBreakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              height: 0
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              height: 600
            }),
          ],
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      state: {},
      events: []
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`MarqueSolar-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`MarqueSolar-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`MarqueSolar-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`MarqueSolar-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`MarqueSolar-desktopY`)
      return classes
    }
  },
  beforeMount() {
    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {}
}
</script>