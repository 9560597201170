"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.USER_SEARCH_ID = exports.USER_SOURCE = void 0;
exports.USER_SOURCE = {
    SAGE: 1,
    VENTE_PRIVEE: 2,
    WEBSITE: 3,
};
exports.USER_SEARCH_ID = {
    ID: 5,
    FIRST_NAME: 2,
    LAST_NAME: 3,
    EMAIL: 1,
    ROLE: 4,
};
