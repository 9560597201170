<style scoped>
.TermModal {
}

.TermModal--modal {
  padding: 50px;
}

.TermModal--modalFooter {
  display: flex;
  justify-content: flex-end;
}
</style>

<template>
  <div class="TermModal" :class="class_root">
    <Modal :opened="state.opened" :onOpenedChange="v => state.opened = v">
      <div v-show="state.loadingSelect">
        <LoaderSpace></LoaderSpace>
      </div>
      <div class="TermModal--modal">
        <h2>{{ langServiceBrowser.translate(4) }}</h2>
        <br>
        <Divider></Divider>

        <template v-if="disabled">
          <br>
          <InputError
              :term="langServiceBrowser.term(726)"></InputError>
        </template>

        <br>
        <InputTerm v-if="state.type"
                   :fullOption="state.fullOption"
                   :type="state.type"
                   :term="state.term"
                   :lang="state.lang"
                   :disabled="disabled"
                   :langs="state.langs"
                   :onChangeLang="v => state.lang = v">
        </InputTerm>
        <br>
        <Divider></Divider>
        <br>
        <div class="TermModal--modalFooter">
          <el-button @click="() => cancel()">{{ langServiceBrowser.translate(6) }}</el-button>
          <el-button :disabled="disabled" :loading="state.loading" :disable="state.loading" type="primary" @click="() => save()">
            {{ langServiceBrowser.translate(5) }}
          </el-button>
        </div>
      </div>
    </Modal>
  </div>
</template>

<script>

import {ListenerTrigger} from '@bbx/listener~master/core/delta/ListenerTrigger.ts'
import {ListenerRegister} from '@bbx/listener~master/core/delta/ListenerRegister.ts'

import {Term} from '../../../@common/delta/database/Term.ts';
import {Lang} from '../../../@common/delta/database/Lang.ts';

import {apiClient} from "../../service/apiClientService.ts";

import selectTerms from "../../../@common/api/term/selectTerms.ts";
import createTerm from "../../../@common/api/term/createTerm.ts";

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser.ts";

import {eventService} from '../../service/eventService'
import {controlForm} from "../../function/controlForm";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {EVENT} from "../../../@common/constant/EVENT";
import {sendError} from "../../function/sendError";

export default {
  data() {
    return {
      langServiceBrowser: getLangServiceBrowser(),
      events: [],
      state: {
        /**
         * @type string
         */
        type: 'text',

        /**
         * @type boolean
         */
        fullOption: false,

        /**
         * @type boolean
         */
        loading: false,

        /**
         * @type boolean
         */
        loadingSelect: false,

        /**
         * @type boolean
         */
        opened: false,

        /**
         * @type ApiResponseForm
         */
        form: new ApiResponseForm(),

        /**
         * @type Term
         */
        term: new Term(),

        /**
         * @type Lang
         */
        lang: new Lang(),

        /**
         * @type Lang[]
         */
        langs: [],
      }
    }
  },
  computed: {
    class_root() {
      const classes = [];
      return classes;
    },
    disabled() {
      let isEditable = this.state.term.browsers.length || this.state.term.pageStories.length
      return !isEditable
    }
  },
  beforeMount() {

    // -----

    this.setlang(this.langServiceBrowser.lang)
    this.setlangs(this.langServiceBrowser.langs)

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.TERM_MODAL_OPENED,
      callback: (props) => {
        this.onOpenModal(props).catch(sendError)
      }
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {

    /**
     *
     * @param {Lang} lang
     */
    setlang(lang) {
      this.state.lang = new Lang(lang)
    },

    /**
     *
     * @param {Lang[]} langs
     */
    setlangs(langs) {
      this.state.langs = langs.map(lang => new Lang(lang))
    },

    /**
     * @param {Object} props
     * @param {number} props.termId
     * @param {'text'|'textarea'} props.type
     * @param {Lang} props.lang
     * @param {boolean} props.fullOption
     */
    async onOpenModal(props) {

      this.state.type = ''

      this.$nextTick(() => {

        const asyncFunction = async () => {

          this.state.type = props.type
          this.state.fullOption = props.fullOption

          this.setlang(props.lang)

          this.state.opened = true
          this.loadingSelect = true

          await this.selectTerms(props.termId)

          this.loadingSelect = false
        }

        asyncFunction().catch(sendError)
      })
    },

    async save() {

      if (this.disabled) return

      if (this.type === 'textarea') {
        this.state.term.isHtml = true
      }

      const res = await controlForm({
        apiAction: createTerm,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loading',
        loaderState: this.state,
        payload: this.state.term,
        vue: this,
      });

      if (res.success) {
        this.state.opened = false
        eventService.triggerByName(new ListenerTrigger({
          name: EVENT.TERM_UPDATED,
          payload: {
            termId: this.state.term.id,
          }
        }))
      }
    },

    async selectTerms(termId) {
      const {data} = await apiClient.do(
          selectTerms,
          selectTerms.with({
            $ids: [termId],
            $scope: 3
          })
      );
      this.state.term = this.langServiceBrowser.fillEmptyTerm(new Term(data[0]))
    },

    cancel() {
      this.state.opened = false;
    },
  }
}
</script>
