<style scoped>
.Form {
}
</style>

<template>
  <div class="Form">

        <el-form @submit.native.prevent>
          <slot/>
        </el-form>
  </div>
</template>

<script>

export default {

  props: {},
  data() {
    return {
      props: {},
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {},
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
