<style scoped>

</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="content-wrapper none for-title">
      <div class="container_12">
        <div class="main-container main-main-container col1-layout">
          <div class="grid_12 col-main">
            <div class="page-title">
              <h1>Gagner de la place à l’extérieur grâce à un mobilier adapté</h1>
            </div>
            <div class="std">
              <p style="text-align: justify;">
                <img alt="" src="./media/wysiwyg/bandeau-gain-de-place.jpg">
              </p>
              <p>&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Les
                  <b>jardins</b>
                  et les terrasses sont, aujourd’hui, des pièces à part entière de nos habitations, une extension de
                  notre intérieur. Les petits extérieurs sont parfois difficiles à aménager. Quel
                  <b>mobilier</b>
                  choisir pour optimiser l’espace et en faire un lieu de détente, de partage et de loisirs&nbsp;? Nous
                  vous proposons différentes solutions pour gagner de la place grâce à un
                  <b>mobilier de jardin</b>
                  adapté.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Mobilier pliable</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Vous ne prenez vos repas qu’occasionnellement dans votre jardin&nbsp;?
                  Pourquoi ne pas opter pour une
                  <b>table</b>
                  et des
                  chaises
                  pliantes
                  &nbsp;? Une fois le déjeuner terminé, vous n’avez plus qu’à les plier et les ranger pour laisser
                  place à vos transats.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Une large gamme de mobiliers pliables existe&nbsp;: table, chaise,
                  transat, chilienne, etc. Une fois replié, votre mobilier devient peu encombrant et est facilement
                  stocké pour l’hiver ou en attendant sa prochaine utilisation. Transformer votre extérieur selon vos
                  envies et vos besoins devient un jeu d’enfant.
                </span>
              </p>
              <p>
                <span style="font-size: large;">
                  <img style="display: block; margin-left: auto; margin-right: auto;" alt="" src="./media/wysiwyg/chaisepliante.jpg">
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Meubles multifonctions
                <span style="font-size: large;">&nbsp;</span>
              </h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">On le sait, dans les petits espaces, il faut optimiser un maximum la
                  fonction de chaque meuble. C’est également valable pour votre mobilier d’extérieur. Un espace de
                  stockage supplémentaire est toujours le bienvenu, que ça soit pour ranger des outils ou des coussins
                  d’extérieur, par exemple. Ces meubles multifonctions peuvent se révéler très utiles pour allier
                  rangement, utilité et confort.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Ainsi, vous trouverez des coffres extérieurs qui ne servent pas
                  seulement au stockage, mais qui peuvent facilement être transformés en banc confortable à l’aide d’un
                  <b>coussin</b>
                  .
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Il existe également des chaises, des
                  <b>canapés d’angle</b>
                  ou des poufs, dont l’espace sous l’assise a été optimisé et sert de rangement.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Meubles encastrables</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Pour maximiser votre espace extérieur, vous pouvez aussi investir dans
                  un
                  <b>meuble encastrable</b>
                  . Le principe est celui des tables gigognes&nbsp;: les meubles s’imbriquent les uns dans les autres,
                  pour prendre le moins de place possible. Ce type de table basse est particulièrement adapté aux
                  dimensions des petites terrasses.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">On trouve, aujourd’hui, beaucoup de
                  <b>salons de jardin en résine tressée</b>
                  qui s’encastrent. Les fauteuils ou les poufs viennent se glisser sous la table et on obtient ainsi un
                  ensemble moins encombrant.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Mobilier polyvalent</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Vous pouvez obtenir un gain de place intéressant en choisissant un
                  mobilier qui s’adaptera aussi bien à la vie en extérieur, qu’aux couleurs de votre décoration
                  intérieure. Cela vous permettra aussi d’économiser de l’argent.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">De nombreuses possibilités s’offrent à vous. Une table en teck, par
                  exemple, pourra apporter une touche de chaleur à votre salle à manger, mais aussi être utilisée dans
                  votre jardin, grâce à ses propriétés imputrescibles. Sa couleur bois conviendra aussi bien dans un
                  environnement intérieur qu’extérieur. Une
                  <b>chaise</b>
                  en rotin ou des coussins de sols installés dans le salon peuvent vous servir à créer un espace détente
                  sur la terrasse.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">L’important est de toujours choisir du mobilier capable de résister aux
                  intempéries auxquelles il sera soumis lorsqu’il sera à l’extérieur&nbsp;: vent, pluie, soleil…
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>

              <h2 style="text-align: justify;">Mobilier empilable</h2>
              <img alt="" src="./media/wysiwyg/empilable.jpg" width="400">
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Pour minimiser l’encombrement de votre jardin, vous pouvez également
                  investir dans des meubles empilables. Vous pourrez facilement ranger votre mobilier pour gagner de
                  l’espace. Par exemple, vous pourrez rendre à vos enfants, en journée, le terrain de jeux dont ils ont
                  besoin, tout en retrouvant en quelques secondes ce
                  <b>salon de jardin</b>
                  si cosy, idéal pour le déjeuner ou à l’heure du dîner.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Vous trouverez notamment sur le marché des
                  chaises
                  qui se superposent pour prendre le moins de place possible. Préférez-les en
                  <b>aluminium</b>
                  , car ce matériau noble et léger rendra les manipulations plus faciles.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Grâce à ces solutions, il n’y a plus besoin de se priver d’un coin repas
                  ou de détente dans un petit extérieur. Il suffit de choisir un mobilier adapté pour maximiser l’espace
                  disponible et en faire un lieu agréable.
                </span>
              </p>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../../function/getH1";
import {Term} from "../../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../../@common/constant/PROJECT_URL";
import {PATHS} from "../../../../@common/constant/PATHS";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    },
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FAQ-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
