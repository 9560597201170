<style scoped>
.UserUpdatePassword {
  margin: auto;
}

.UserUpdatePassword-desktop {
  width: 400px;
  padding: 150px 50px;
}

.UserUpdatePassword-tablet {
  width: 400px;
  padding: 150px 50px;
}

.UserUpdatePassword-mobile {
  width: 100%;
  padding: 50px;
  box-sizing: border-box;
}
</style>

<template>
  <div class="UserUpdatePassword" :class="class_root">
    <AuthTitle :term="lang.term(147)"/>
    <br>
    <Divider></Divider>
    <br>
    <Form>
      <InputText :value="state.user.password"
                 :onChangeValue="v => state.user.password = v"
                 :label="lang.term(144)"
                 :password="true"
                 :svg="icon.fingerprint"
                 :error="state.form.display('password')"/>
      <br>
      <br>
      <ButtonAction :term="lang.term(145)" :onClick="() => request()" :loading="state.loading"/>
    </Form>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint.ts';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN.ts';

import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {User} from "../../../@common/delta/database/User";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {controlForm} from "../../function/controlForm";
import updateUserPassword from "../../../@common/api/user/updateUserPassword.ts";

import {changeRoute} from "../../function/changeRoute";
import {PATHS} from "../../../@common/constant/PATHS";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    },
    onComplete: {
      type: Function,
      default: () => () => {
      }
    }
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      icon: {
        fingerprint: require('@bbx/vector~master/core/assets/svg/material/fingerprint.svg'),
      },
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      state: {
        loading: false,
        form: new ApiResponseForm(),
        user: new User(),
      },
    }
  },
  watch: {},
  computed: {

    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`UserUpdatePassword-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`UserUpdatePassword-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`UserUpdatePassword-desktop`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    async request() {
      const res = await controlForm({
        apiAction: updateUserPassword,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loading',
        loaderState: this.state,
        payload: this.state.user,
        message: this.lang.translate(146),
        vue: this,
      });
      if (res.success) {
        changeRoute([PATHS.LOGIN]);
      }
    }
  }
}
</script>
