"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Country = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Address_1 = require("./Address");
var TransportCost_1 = require("./TransportCost");
var FreeTransport_1 = require("./FreeTransport");
var Country = /** @class */ (function () {
    function Country(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.name = deltaService_1.deltaService.string(props.name, '');
        this.addresses = deltaService_1.deltaService.array(props.addresses, Address_1.Address);
        this.transportCosts = deltaService_1.deltaService.array(props.transportCosts, TransportCost_1.TransportCost);
        this.freeTransports = deltaService_1.deltaService.array(props.freeTransports, FreeTransport_1.FreeTransport);
    }
    return Country;
}());
exports.Country = Country;
