<style scoped>
.AccountProfile {
  min-height: 100vh;
}

.AccountProfile-desktop {
  padding: 50px;
}

.AccountProfile-tablet {
  padding: 50px;
}

.AccountProfile-mobile {
  padding: 0px;
}

.AccountProfile-mobile ::v-deep .Responsive--root {
  align-items: center;
  display: flex;
  flex-direction: column;
}

.AccountProfile--content {
  max-width: 400px;
}
</style>

<template>
  <div class="AccountProfile" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="AccountProfile--content">
        <Form v-show="!state.loadingSelect">
          <InputSelectTerm :items="state.genders"
                           :values="state.user.genders"
                           :label="lang.term(135)"
                           :onChangeValues="v => state.user.genders = v"
                           :error="state.form.display('genders')">
          </InputSelectTerm>
          <br>
          <InputText :value="state.user.lastName"
                     :onChangeValue="v => state.user.lastName = v"
                     :label="lang.term(118)"
                     :mandatory="isMandatory"
                     :error="state.form.display('lastName')">
          </InputText>
          <br>
          <InputText :value="state.user.firstName"
                     :onChangeValue="v => state.user.firstName = v"
                     :label="lang.term(119)"
                     :mandatory="isMandatory"
                     :error="state.form.display('firstName')">
          </InputText>
          <br>
          <InputDatePicker :value="state.user.birthday"
                           :onChangeValue="v => state.user.birthday = v"
                           :label="lang.term(120)"
                           :error="state.form.display('birthday')">
          </InputDatePicker>
          <br>
          <Divider/>
          <br>
          <InputText :value="state.user.email"
                     :mandatory="true"
                     :onChangeValue="v => state.user.email = v"
                     :label="lang.term(102)"
                     :error="state.form.display('email')">
          </InputText>
          <br>
          <InputText :value="state.user.phoneNumber"
                     :onChangeValue="v => state.user.phoneNumber = v"
                     :label="lang.term(273)"
                     :error="state.form.display('phoneNumber')">
          </InputText>
          <br>
          <Divider/>
          <br>
          <ButtonAction :term="lang.term(274)" :onClick="() => save()" :loading="state.loadingCreate"/>
          <br/>
          <ButtonLink :svg="icon.vpn_key" :pathIds="[PATHS.FORGOTTEN_PASSWORD]" :term="lang.term(275)"></ButtonLink>
        </Form>
        <LoaderSpace v-show="state.loadingSelect"></LoaderSpace>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {PATHS} from "../../../@common/constant/PATHS";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {User} from "../../../@common/delta/database/User";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {apiClient} from "../../service/apiClientService";
import selectGenders from "../../../@common/api/gender/selectGenders";
import selectUsers from "../../../@common/api/user/selectUsers";
import {STATE_TOKEN, stateService} from "../../service/stateService";
import {controlForm} from "../../function/controlForm";
import createUser from "../../../@common/api/user/createUser";
import Carbon from "@bbx/carbon~master/core/ts/Carbon.ts";
import {ROLE} from "../../../@common/constant/ROLE";
import {sendError} from "../../function/sendError";

export default {

  props: {},
  data() {
    return {
      PATHS,
      carbon: new Carbon(),
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        vpn_key: require('@bbx/vector~master/core/assets/svg/material/vpn_key.svg'),
      },
      state: {
        form: new ApiResponseForm(),
        loadingSelect: true,
        loadingCreate: false,
        user: new User(),
        token: '',
        genders: []
      },
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`AccountProfile-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`AccountProfile-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`AccountProfile-desktop`)
      return classes
    },
    isMandatory() {
      return this.state.user.roles.some(role => role.id !== ROLE.SELLER)
    }
  },
  beforeMount() {
  },
  mounted() {
    this.state.token = stateService.get(STATE_TOKEN)
    stateService.watch(STATE_TOKEN, v => this.state.token = v)
    this.init().catch(sendError);
  },
  beforeDestroy() {
  },
  methods: {
    async init() {
      await Promise.all([
        this.selectGenders(),
        this.selectUser(),
      ])
    },
    async selectGenders() {
      const {data} = await apiClient.do(selectGenders, selectGenders.with())
      this.state.genders = data;
    },
    async selectUser() {
      this.state.loadingSelect = true
      const {data} = await apiClient.do(selectUsers, selectUsers.with({
        $token: this.state.token
      }))
      const user = new User(data[0]);
      user.birthday = this.carbon.toDate(user.birthday)
      this.state.user = user;
      this.state.loadingSelect = false;
    },
    async save() {
      const user = new User(this.state.user);
      if (user.birthday === this.carbon.toDate()) {
        user.birthday = ''
      } else {
        user.birthday = this.carbon.fromDate(user.birthday)
      }
      await controlForm({
        apiAction: createUser,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loadingCreate',
        loaderState: this.state,
        payload: user,
        message: this.lang.translate(320),
        vue: this,
      });
      await this.selectUser()
    },
  }
}
</script>
