<style scoped>
.Card {
  box-shadow: 0 3px 6px rgba(0, 0, 0, 0.15);
  padding: 35px;
  max-width: 350px;
}
</style>

<template>
  <div class="Card" :class="class_root">
    <slot></slot>
  </div>
</template>

<script>

export default {
  props: {},
  data() {
    return {
      state: {},
      icon: {},
    }
  },
  computed: {
    class_root() {
      const classes = []
      return classes
    }
  },
  methods: {}
}
</script>
