import 'core-js';
import 'regenerator-runtime';

/*
 * * * * * * * * * * * * * * *
 *
 *    Vue
 *
 * * * * * * * * * * * * * * *
 */

import './plugins/element-ui'
import './plugins/vue-quill-editor'
import './plugins/vue-beautiful-chat'

/*
 * * * * * * * * * * * * * * *
 *
 *    Vue
 *
 * * * * * * * * * * * * * * *
 */

import Vue from 'vue'
import VueRouter from 'vue-router'

// ----

import Index from './index.vue'
import {component} from '../../../core/@browser/component'

// ----

for (const componentName in component) {
    Vue.component(componentName, component[componentName])
}

// ----

Vue.config.productionTip = false

// ----

Vue.use(VueRouter)

const router = new VueRouter({
    mode: 'history',
    routes: []
})

// ----

const vue = new Vue({
    router: router,
    render: h => h(Index)
})

// ----

vue.$mount('#index')
