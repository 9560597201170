import {Translation} from "../../@common/delta/database/Translation";
import {Lang} from "../../@common/delta/database/Lang";
import {Page} from "../../@common/delta/database/Page";

export function mapPageToDefaultLang(pages: Page[], map, lang: Lang) {
    let indexPage = 0
    for (const key in map) {
        let compressed = map[key]

        // -----

        if (compressed[4]) {
            let translation = new Translation({
                id: compressed[3],
                value: compressed[4],
                langs: [
                    new Lang(lang)
                ]
            })
            pages[indexPage].termTitles[0].translations.push(translation)
        }

        // -----

        if (compressed[7]) {
            let translation = new Translation({
                id: compressed[6],
                value: compressed[7],
                langs: [
                    new Lang(lang)
                ]
            })
            pages[indexPage].termDescriptions[0].translations.push(translation)
        }

        // -----

        if (compressed[10]) {
            let translation = new Translation({
                id: compressed[9],
                value: compressed[10],
                langs: [
                    new Lang(lang)
                ]
            })
            pages[indexPage].termH1s[0].translations.push(translation)
        }

        // -----

        if (compressed[11].length) {
            let indexPath = 0
            for (const compressedPath of compressed[11]) {
                let indexTerm = 0
                for (const compressedTerm of compressedPath[1]) {
                    let translationId = compressedTerm[1]
                    let translationValue = compressedTerm[2]
                    let translation = new Translation({
                        id: translationId,
                        value: translationValue,
                        langs: [
                            new Lang(lang)
                        ]
                    })
                    pages[indexPage].paths[indexPath].terms[indexTerm].translations.push(translation)
                    indexTerm++
                }
                indexPath++
            }
        }

        // -----

        if (compressed[15].length) {
            let indexUrl = 0
            for (const compressedUrl of compressed[15]) {
                let indexSort = 0
                for (const compressedSort of compressedUrl[2]) {
                    let indexTerm = 0
                    for (const compressedTerm of compressedSort[2]) {
                        let translationId = compressedTerm[1]
                        let translationValue = compressedTerm[2]
                        let translation = new Translation({
                            id: translationId,
                            value: translationValue,
                            langs: [
                                new Lang(lang)
                            ]
                        })
                        pages[indexPage].urls[indexUrl].sorts[indexSort].paths[0].terms[indexTerm].translations.push(translation)
                        indexTerm++
                    }
                    indexSort++
                }
                indexUrl++
            }
        }
        indexPage++
    }
    return pages
}