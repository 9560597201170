<style scoped>
.AdminLang {
  padding: 0px 50px 50px 50px;
}
</style>

<template>
  <div class="AdminLang">
    <el-tabs type="card" v-model="state.tab">
      <el-tab-pane label="Traductions" name="translation">
        <LangAdmin v-if="state.tab === 'translation'"></LangAdmin>
      </el-tab-pane>
    </el-tabs>
  </div>
</template>

<script>

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

export default {

  props: {},
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {},
      state: {
        tab: 'translation'
      },
    }
  },
  watch: {},
  computed: {},
  beforeMount() {

  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
