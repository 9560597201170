<style scoped lang="scss">

@import "../../scss/color";
@import "../../scss/size";

/**/

.HomeApp {

}

/**/

.HomeApp--body {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.HomeApp-desktop .HomeApp--body {
  background-color: #F3EEE9;
  padding: 0 100px 0 100px;
  margin: 250px $homeCarouselDektopLeft 0px $homeCarouselDektopLeft;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeApp-desktop .HomeApp--body {
    padding: 0 50px 0 100px;
    margin: 250px 50px 0px 50px;
  }
}

.HomeApp-tablet .HomeApp--body {
  background-color: #F3EEE9;
  padding: 100px 40px;
  margin: 150px $tabletHomeCarouselLeft 0px $tabletHomeCarouselLeft;
  flex-direction: column;
}

.HomeApp-mobile .HomeApp--body {
  padding: 20px;
  padding-top: 250px;
  flex-direction: column;
}

/*
 * * * * * * * * * *
 *
 *   LEFT
 *
 * * * * * * * * * *
 */

.HomeApp--bodyLeft {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

/**/

.HomeApp--title {
  font-family: 'AmbroiseRegular', sans-serif;
  color: #31302F;
  text-align: center;
  margin-bottom: 25px;
}

.HomeApp-desktop .HomeApp--title {
  max-width: 600px;
  font-size: 45px;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeApp-desktop .HomeApp--title {
    font-size: 40px;
  }
}

.HomeApp-tablet .HomeApp--title {
  max-width: 500px;
  font-size: 40px;
}

.HomeApp-mobile .HomeApp--title {
  font-size: 34px;
  padding: 0 20px;
}

/**/

.HomeApp--description {
  max-width: 430px;
  font-family: 'AeonikRegular', sans-serif;
  color: #707070;
  line-height: 28px;
  letter-spacing: 0.25px;
  text-align: center;
  margin-bottom: 43px;
}

.HomeApp-desktop .HomeApp--description {
  font-size: 18px;
}

.HomeApp-tablet .HomeApp--description {
  font-size: 18px;
}

.HomeApp-mobile .HomeApp--description {
  font-size: 16px;
}

/**/

.HomeApp--links {
  text-align: center;
}

.HomeApp--links a {
  padding: 0 10px;
}

.HomeApp-desktop .HomeApp--links a {
  padding: 0 10px;
}

.HomeApp-tablet .HomeApp--links a {
  padding: 0 10px;
}

.HomeApp-mobile .HomeApp--links a {
  padding: 0 5px;
}

.HomeApp-mobile .HomeApp--links img {
  height: 30px;
}

/*
 * * * * * * * * * *
 *
 *   CENTER
 *
 * * * * * * * * * *
 */

.HomeApp--bodyCenter {
}

.HomeApp-desktop .HomeApp--bodyCenter {
  width: 500px;
  height: 500px;
  min-width: 500px;
  min-height: 500px;
}

.HomeApp-tablet .HomeApp--bodyCenter {
  margin-top: 70px;
  width: 100%;
  text-align: center;
}

.HomeApp-mobile .HomeApp--bodyCenter {
  position: relative;
  top: 100px;
}

/**/

.HomeApp--bodyCenter img {
}

.HomeApp-desktop .HomeApp--bodyCenter img {
  height: calc(100% + 200px);
  margin-top: -150px;
  position: relative;
  top: 30px;
  left: -70px;
}

.HomeApp-tablet .HomeApp--bodyCenter img {
  width: 75%;
  max-width: 500px;
}

.HomeApp-mobile .HomeApp--bodyCenter img {
  height: 100%;
}

/*
 * * * * * * * * * *
 *
 *   RIGHT
 *
 * * * * * * * * * *
 */


.HomeApp--bodyRight {

}

.HomeApp-desktop .HomeApp--bodyRight {

}

.HomeApp-tablet .HomeApp--bodyRight {
  display: flex;
  background-color: #F3EEE9;
}

.HomeApp-mobile .HomeApp--bodyRight {
  background-color: #F3EEE9;
  padding: 25px;
  width: 100%;
  box-sizing: border-box;
  position: relative;
}

/**/

.HomeApp--option {
  display: flex;
  align-items: center;
}

/**/

.HomeApp--optionSpace {
}

.HomeApp-desktop .HomeApp--optionSpace {
  height: 25px;
  min-height: 25px;
}

.HomeApp-tablet .HomeApp--optionSpace {
  width: 25px;
  min-width: 25px;
}

.HomeApp-mobile .HomeApp--optionSpace {
  height: 25px;
  min-height: 25px;
}

/**/

.HomeApp--bubble {
  background-color: #31302F;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 100px;
}


.HomeApp-desktop .HomeApp--bubble {
  width: 90px;
  height: 90px;
  min-width: 90px;
  min-height: 90px;
  margin-right: 25px;
}

@media screen and (max-width: $menu-image-screen) {
  .Menu-desktop .HomeApp--bubble {
    width: 70px;
    height: 70px;
    min-width: 70px;
    min-height: 70px;
  }
}

.HomeApp-tablet .HomeApp--bubble {
  width: 60px;
  height: 60px;
  min-width: 60px;
  min-height: 60px;
  margin-right: 20px;
}

.HomeApp-mobile .HomeApp--bubble {
  width: 50px;
  height: 50px;
  min-width: 50px;
  min-height: 50px;
  margin-right: 20px;
}

/**/

.HomeApp--bubble img {
  height: 50%;
}


/**/

.HomeApp--optionDescription {
  font-family: 'CommutersSansBold', sans-serif;
  text-transform: uppercase;
  color: #895B4F;
  line-height: 22px;
}

.HomeApp-desktop .HomeApp--optionDescription {
  font-size: 11px;
  width: 160px;
}

.HomeApp-tablet .HomeApp--optionDescription {
  font-size: 10px;
  width: 150px;
}

.HomeApp-mobile .HomeApp--optionDescription {
  font-size: 10px;
}

</style>

<template>
  <div class="HomeApp" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <div class="HomeApp--body">
        <div class="HomeApp--bodyLeft">
          <div class="HomeApp--title">
            <AnimationTitle :fully="false">
              <Term :term="lang.term(732)"></Term>
            </AnimationTitle>
          </div>
          <div class="HomeApp--description">
            <AnimationTitle :fully="false">
              <Term :term="lang.term(733)"></Term>
            </AnimationTitle>
          </div>
          <div class="HomeApp--links">
            <a href="https://apps.apple.com/fr/app/les-jardins-3d/id1528946540" target="_blank" rel="nofollow">
              <img :src="img.appleStore" alt="">
            </a>
            <a href="https://play.google.com/store/apps/details?id=com.innersense.osmose.android.lesjardins" target="_blank" rel="nofollow">
              <img :src="img.googlePlay" alt="">
            </a>
          </div>
        </div>
        <div class="HomeApp--bodyCenter">
          <ScrollParallax :distance="50" :reverse="false">
            <img :src="img.phone" alt="">
          </ScrollParallax>
        </div>
        <div class="HomeApp--bodyRight">
          <div class="HomeApp--option">
            <div class="HomeApp--bubble">
              <img :src="img.option1" alt=""></div>
            <div class="HomeApp--optionDescription">
              <AnimationTitle :fully="false">
                <Term :term="lang.term(734)"></Term>
              </AnimationTitle>
            </div>
          </div>
          <div class="HomeApp--optionSpace"></div>
          <div class="HomeApp--option">
            <div class="HomeApp--bubble">
              <img :src="img.option2" alt=""></div>
            <div class="HomeApp--optionDescription">
              <AnimationTitle :fully="false">
                <Term :term="lang.term(735)"></Term>
              </AnimationTitle>
            </div>
          </div>
          <div class="HomeApp--optionSpace"></div>
          <div class="HomeApp--option">
            <div class="HomeApp--bubble">
              <img :src="img.option3" alt=""></div>
            <div class="HomeApp--optionDescription">
              <AnimationTitle :fully="false">
                <Term :term="lang.term(736)"></Term>
              </AnimationTitle>
            </div>
          </div>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>

// -----

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {eventService} from "../../service/eventService";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

// -----

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    },
    breakPoints: {
      type: Array,
      default: () => ([])
    },
    verticalBreakPoints: {
      type: Array,
      default: () => ([])
    },
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      img: {
        appleStore: require('../../../@common/assets/home/app/apple-store.png'),
        googlePlay: require('../../../@common/assets/home/app/google-play.png'),
        phone: require('../../../@common/assets/home/app/phone.png'),
        option1: require('../../../@common/assets/home/app/option-1.png'),
        option2: require('../../../@common/assets/home/app/option-2.png'),
        option3: require('../../../@common/assets/home/app/option-3.png'),
      },
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: this.breakPoints,
          verticalBreakPoints: this.verticalBreakPoints,
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      state: {},
      events: []
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`HomeApp-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`HomeApp-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`HomeApp-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`HomeApp-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`HomeApp-desktopY`)
      return classes
    }
  },
  beforeMount() {
    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {}
}
</script>