import ApiRegistery from '@bbx/api~master/core/delta/ApiRegistery';


import {ApiResponse} from "../../delta/http/ApiResponse";
import {Auth} from "../../delta/database/Auth";

export interface Request extends Auth {
}

export interface Response extends Auth {
}

export default new ApiRegistery<Request, ApiResponse<Response>>({
    name: 'verifyAuth',
});
