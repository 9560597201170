import {Page} from "../delta/database/Page";

let pages: Page[] = []

export const pageService = {
    getPages(): Page[] {
        return pages
    },
    setPages(v: Page[]) {
        pages = v
    }
}
