<style scoped>

</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="content-wrapper none for-title">
      <div class="container_12">
        <div class="main-container main-main-container col1-layout">
          <div class="grid_12 col-main">
            <div class="page-title">
              <h1>Choisir des meubles de jardin qui résistent aux intempéries</h1>
            </div>
            <div class="std">
              <p style="text-align: left;">
                <span style="font-size: large;">
                  <img alt="" src="./media/wysiwyg/bandeau-resistant.jpg">
                </span>
              </p>
              <p>&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Lorsque l’on achète du mobilier d’extérieur, qu’il s’agisse d’une table,
                  de bains de soleil ou d’un salon de jardin, il est important de choisir les
                  <b>bons matériaux</b>
                  . Vos meubles vont devoir résister à toutes les conditions climatiques&nbsp;: soleil, pluie, écart de
                  température, etc. Des meubles en rotin, par exemple, ne supportent pas la pluie et le froid. Il est
                  donc important de faire le bon choix, pour investir dans du mobilier qui dure dans le temps. Voici une
                  présentation de quelques-uns des matériaux les plus adaptés à la vie en extérieur.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <h2 style="text-align: justify;">Meubles en bois</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">
                  <img style="margin: 20px; float: right;" alt="" src="./media/wysiwyg/ensembleenteck.jpg">
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le
                  <b>bois</b>
                  est un matériel apprécié pour le mobilier de jardin. Installé sur une terrasse, il donne tout de suite
                  une ambiance chaleureuse et relaxante. La traditionnelle table en bois semble indémodable, c’est donc
                  une valeur sûre. Elle conviendra parfaitement avec des fauteuils dotés de coussins de différentes
                  couleurs.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Différentes essences, de divers coloris, sont adaptées à la vie en
                  extérieur. Le prix variera en fonction du choix du bois, du niveau de finition et de la marque.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le
                  <b>teck</b>
                  est l’une des essences les plus appréciées. Bois exotique d’exception, de couleur beige brun, il
                  résiste à l’humidité, aux insectes et est conçu pour faire face aux UV. Grâce à ses fibres souples,
                  c’est un matériau résistant aux chocs et très solide. On trouve aujourd’hui une
                  <b>large gamme</b>
                  de
                  tables
                  en teck. Vous souhaitez faire un geste pour la planète&nbsp;?&nbsp; Privilégiez du teck issu du
                  recyclage ou de l’exploitation de forêts durables.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">
                  <span style="font-size: large;">D’autres essences sont aussi régulièrement utilisées dans la
                    fabrication de meubles de jardin comme l’acacia ou l’eucalyptus. Même si le bois est un matériel
                    très résistant, il est conseillé de prévoir une
                    <b>housse de protection</b>
                    pour l’hiver, lorsqu’il est soumis aux basses températures, à moins que vous ne disposiez d’un abri
                    dans lequel ranger les chaises pliantes par exemple.
                  </span>
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le
                  <b> mobilier de jardin en bois</b>
                  demande peu d’entretien. Il suffit de lui passer une huile adaptée, une à deux fois par an, pour lui
                  redonner une belle couleur et le protéger. Sachez que le teck fonce avec les années&nbsp;: la teinte
                  de votre mobilier de jardin neuf va donc varier légèrement avec le temps.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <h2 style="text-align: justify;">Meubles en aluminium et en acier</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">
                  <img style="float: left; margin: 20px;" alt="" src="./media/wysiwyg/alu.jpg">
                  <span style="font-size: large;">
                    <span>
                      <span>&nbsp;</span>
                    </span>
                  </span>
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le&nbsp;<b>mobilier de jardin en aluminium</b>&nbsp;ajoutera une touche
                  chic et épurée à votre extérieur.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">L’aluminium et l’acier sont des métaux inoxydables, c’est-à-dire qu’ils
                  ne rouillent pas. Ils sont souvent utilisés pour la structure des meubles de jardin ou pour les
                  fabriquer dans leur intégralité. Un bain de soleil en aluminium peut donc être installé sans crainte
                  au bord d’une piscine. Ces deux métaux supportent donc la pluie et les intempéries. Cela en fait un&nbsp;<b>
                    mobilier de jardin résistant
                  </b>&nbsp;et durable qui peut être conservé de nombreuses saisons.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le mobilier de jardin en aluminium est léger, il est particulièrement
                  apprécié pour des meubles, comme les&nbsp;
                  chaises
                  , qui doivent être déplacés régulièrement. Attention cependant, car cela en fait aussi un matériau qui
                  n’est pas adapté aux extérieurs soumis aux vents forts, comme le mistral, où il pourrait s’envoler.
                  L’aluminium est facilement recyclable, c’est donc un bon choix pour l’environnement.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">L’acier est plus lourd que l’aluminium, mais s’oxyde plus vite. Les
                  meubles fabriqués avec ce métal sont donc plus stables, mais aussi plus difficiles à déplacer.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">&nbsp;</p>
              <h2 style="text-align: justify;">Meubles en résine tressée</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Plus récente, la résine tressée s’installe peu à peu dans nos jardins.
                  Elle leur confère un aspect plus moderne et peut donner l’illusion d’une fibre naturelle comme le
                  rotin.&nbsp;
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le
                  <b>mobilier de jardin en résine tressée</b>
                  ne demande pas d’entretien particulier. C’est un matériau qui est à l’épreuve de l’humidité, du soleil
                  et des écarts de température. Il est donc
                  <b>résistant aux intempéries</b>
                  et aux chocs, à condition de le choisir de bonne qualité. Les salons de jardin en résine tressé à
                  petit prix et d’entrée de gamme seront fragilisés par le gel et peuvent devenir cassants.
                </span>
              </p>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../../function/getH1";
import {Term} from "../../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../../@common/constant/PROJECT_URL";
import {PATHS} from "../../../../@common/constant/PATHS";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    },
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FAQ-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
