<style lang="scss" scoped>
.Home {
}

.Home--carousel {
}

.Home--highlight {
}

.Home--seller {
}

.Home--ambiance {
}

.Home--category {
}

.Home--teck {

}

.Home--spot {
  min-height: 200px;
}

.Home--mobileApp {

}

.home-top-bar{
  position: fixed;
  background-color: white;
  top: 0;
  width: 100%;
  z-index: 1000;
  color: inherit;
  text-decoration: none;
  
  p {
    background-color: #CDCE37;
    font: 700 
    14px 'CommutersSansBold', sans-serif;;
    padding: 8px 0 5px;
    margin-bottom: 0;
    text-align: center;
    animation: blinker 1.5s linear infinite;
  }
  @keyframes blinker {
    50% {
      color: rgba(#afb593, .3);
    }
  }
}

</style>

<template>
  <main class="Home" :class="class_root">

    <Link class="home-top-bar" v-if="displayHeadband" :url="urlNewStore">
      <p>OUVERTURE DE NOTRE MAGASIN D'USINE</p>
    </Link>

    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <div class="Home--carousel">
        <HomeCarousel :nuxtProp="nuxtProp"
                      :breakPoints="props.Responsive.breakPoints"
                      :verticalBreakPoints="props.Responsive.verticalBreakPoints">
        </HomeCarousel>
      </div>
      <div class="Home--highlight">
        <HomeHighlight :nuxtProp="nuxtProp"
                       :breakPoints="props.Responsive.breakPoints"
                       :verticalBreakPoints="props.Responsive.verticalBreakPoints">
        </HomeHighlight>
      </div>
      <div class="Home--seller">
        <HomeSeller :nuxtProp="nuxtProp"
                    :breakPoints="props.Responsive.breakPoints"
                    :verticalBreakPoints="props.Responsive.verticalBreakPoints">
        </HomeSeller>
      </div>
      <div class="Home--ambiance">
        <HomeAmbiance :nuxtProp="nuxtProp"
                      :breakPoints="props.Responsive.breakPoints"
                      :verticalBreakPoints="props.Responsive.verticalBreakPoints">
        </HomeAmbiance>
      </div>
      <div class="Home--category">
        <HomeCategory :nuxtProp="nuxtProp"
                      :breakPoints="props.Responsive.breakPoints"
                      :verticalBreakPoints="props.Responsive.verticalBreakPoints">
        </HomeCategory>
      </div>
      <div class="Home--teck">
        <HomeTeck :nuxtProp="nuxtProp"
                  :breakPoints="props.Responsive.breakPoints"
                  :verticalBreakPoints="props.Responsive.verticalBreakPoints">
        </HomeTeck>
      </div>
      <div class="Home--speach">
        <HomeSpeach :nuxtProp="nuxtProp"
                    :breakPoints="props.Responsive.breakPoints"
                    :verticalBreakPoints="props.Responsive.verticalBreakPoints">
        </HomeSpeach>
      </div>
      <div class="Home--catalog">
        <HomeCatalog :nuxtProp="nuxtProp"
                     :breakPoints="props.Responsive.breakPoints"
                     :verticalBreakPoints="props.Responsive.verticalBreakPoints">
        </HomeCatalog>
      </div>
      <div class="Home--app">
        <HomeApp :nuxtProp="nuxtProp"
                 :breakPoints="props.Responsive.breakPoints"
                 :verticalBreakPoints="props.Responsive.verticalBreakPoints">
        </HomeApp>
      </div>
      <div class="Home--spot">
        <CountryLocation>
          <HomeSpot :nuxtProp="nuxtProp"
                    :breakPoints="props.Responsive.breakPoints"
                    :verticalBreakPoints="props.Responsive.verticalBreakPoints"></HomeSpot>
        </CountryLocation>
      </div>
      <!--      <div class="Home&#45;&#45;mobileApp">-->
      <!--        <MobileApp></MobileApp>-->
      <!--      </div>-->
    </Responsive>
  </main>
</template>

<script>

import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {canDisplay, getCountry} from "../../service/countryService";
import {EVENT} from "../../../@common/constant/EVENT";
import {eventService} from "../../service/eventService";
import {COUNTRY_ISO} from "../../../@common/constant/COUNTRY";
import {PROJECT_URL} from "../../../@common/constant/PROJECT_URL";
import {getUrlByProjectUrlId} from "../../../@common/function/helperUrl";

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              width: 0
            }),
            new BreakPoint({
              name: SCREEN.TABLET,
              width: 900
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              width: 1400
            }),
          ],
          verticalBreakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              height: 0
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              height: 600
            }),
          ],
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      icon: {},
      state: {
        country: '',
        canDisplay: false,
      },
      events: []
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`Home-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`Home-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`Home-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`Home-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`Home-desktopY`)
      if (this.state.canDisplay) classes.push(`Home-canDisplay`)
      return classes
    },
    displayHeadband() {
      const todayDate = new Date();
      const endDate = new Date(2023, 6, 30);
      return this.nuxtProp.url.paths[0].value === 'fr' && getCountry() === COUNTRY_ISO.FR && endDate > todayDate;
    },
    urlNewStore() {
      return this.projectUrl(PROJECT_URL.MARQUE_NEW_STORE)
    }
  },
  beforeMount() {

    // -----

    this.state.country = getCountry()
    this.state.canDisplay = canDisplay(this.state.country)

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.CHANGE_COUNTRY_LOCATION,
      callback: (country) => {
        this.state.country = country
        this.state.canDisplay = canDisplay(country)
      }
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    projectUrl(id) {
      return getUrlByProjectUrlId(this.nuxtProp, id)
    },
  }
}
</script>
