<style scoped>

</style>

<template>

</template>

<script>

import {googleConfigPublic} from "../../../../config/public/google.config";
import {STATE_COOKIE, STATE_USER_FIRST_NAME, stateService} from "../../service/stateService";
import {Cookie as CookieDelta} from "../../../@common/delta/cookie/Cookie";
import {eventService} from "../../service/eventService";

export default {

  props: {},
  data() {
    return {
      state: {
        cookie: new CookieDelta()
      },
    }
  },
  watch: {
    'state.cookie.analayze': {
      handler() {
        this.init()
      }
    }
  },
  computed: {},
  beforeMount() {
    this.state.cookie = stateService.get(STATE_COOKIE)
    stateService.watch(STATE_COOKIE, v => this.state.cookie = v)
  },
  mounted() {
    this.init()
  },
  beforeDestroy() {
  },
  methods: {
    init() {
      if (this.state.cookie.analayze) {
        const b = document.getElementById('google-analytics')
        if (!b) {
          let googleAnalytics = document.createElement('script');
          googleAnalytics.src = `${googleConfigPublic.analytics.url}${googleConfigPublic.analytics.id}`;
          googleAnalytics.id = 'google-analytics';
          googleAnalytics.addEventListener('load', () => {
            window.dataLayer = window.dataLayer || [];

            function gtag() {
              dataLayer.push(arguments);
            }

            gtag('js', new Date());
            gtag('config', googleConfigPublic.analytics.id);
          })
          document.body.appendChild(googleAnalytics);
        }
      } else {
        const b = document.getElementById('google-analytics')
        if (b) {
          document.body.removeChild(b)
        }
      }
    }
  }
}
</script>
