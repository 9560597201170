import {deltaService} from "@bbx/delta~master/core/service/deltaService"
import {Funding} from "./Funding";
import {DeepPartial} from "../../type/DeepPartial";


export type FundingOptionList_constructor_props = DeepPartial<FundingOptionList>

export class  FundingOptionList {

    id: number
    active: boolean
    createdAt: string
    updatedAt: string

    value: string

    fundings: Funding[]

    constructor(props: FundingOptionList_constructor_props = {}) {
                this.id = deltaService.number(props.id, 0)
        this.active = deltaService.boolean(props.active, true)
        this.createdAt = deltaService.string(props.createdAt, '')
        this.updatedAt = deltaService.string(props.updatedAt, '')

        this.value = deltaService.string(props.value, '')

        this.fundings = deltaService.array(props.fundings, Funding)
    }
}
