<style scoped>
.AdminDashboard {
  min-height: 100vh;
  justify-content: center;
  align-items: center;
  display: flex;
  text-align: center;
}
</style>

<template>
  <div class="AdminDashboard">
    <h2>En construction...</h2>
  </div>
</template>

<script>

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

export default {
  props: {},
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {},
      state: {},
    }
  },
  watch: {},
  computed: {},
  beforeMount() {

  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
