<style scoped>
.InputDatePicker {
}

.InputDatePicker ::v-deep .InputLabel {
  padding-bottom: 15px;
}

.InputDatePicker ::v-deep .InputError {
  padding-top: 10px;
}
</style>

<template>
  <div class="InputDatePicker">
    <InputLabel :term="label" :svg="svg" :helper="langHelper"/>

        <el-date-picker
            v-model="state.value"
            type="date"
            :clearable="false"
            value-format="yyyy-MM-dd"
            :format="langFormat"
            :placeholder="state.placeholder">
        </el-date-picker>
    <InputError v-if="error.id" :error="error"/>
  </div>
</template>

<script>

import {Term as TermDelta} from '../../../@common/delta/database/Term.ts'
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {LANG} from "../../../@common/constant/LANG";
import Carbon from "@bbx/carbon~master/core/ts/Carbon.ts";

const carbon = new Carbon()

export default {

  props: {
    svg: {
      type: String,
      default: () => ''
    },
    value: {
      type: String,
      default: () => ''
    },
    onChangeValue: {
      type: Function,
      default: () => () => {
      }
    },
    error: {
      default: () => new TermDelta()
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    label: {
      default: () => new TermDelta()
    }
  },
  data() {
    const lang = getLangServiceBrowser()
    return {
      lang,
      props: {},
      state: {
        value: this.value,
        placeholder: this.placeholder ? this.placeholder : lang.translate(104)
      },
    }
  },
  watch: {
    'value': function (v) {
      if (!v) v = carbon.toDate()
      this.state.value = v;
    },
    'state.value': function (v) {
      this.onChangeValue(v)
    }
  },
  computed: {
    langFormat() {
      return this.lang.lang.id === LANG.FR ? 'dd/MM/yyyy' : 'MM/dd/yyyy'
    },
    langHelper() {
      return this.lang.lang.id === LANG.FR ? 'JJ/MM/YYYY' : 'MM/DD/YYYY'
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
