<style scoped>
.CatalogueArticles {
}

.CatalogueArticles-items {
  display: flex;
  flex-flow: wrap;
}

.CatalogueArticles-item {
  position: relative;
}

.CatalogueArticles-desktop.CatalogueArticles-big .CatalogueArticles-item {
  width: 33.33%;
  max-height: 33%;
  padding-bottom: 33.33%;
}

.CatalogueArticles-desktop.CatalogueArticles-small .CatalogueArticles-item {
  width: 25%;
  padding-bottom: 25%;
}

.CatalogueArticles-tablet .CatalogueArticles-item {
  width: 50%;
  max-height: 50%;
  padding-bottom: 50%;
}

.CatalogueArticles-mobile .CatalogueArticles-item {
  width: 100%;
  padding-bottom: 100%;
}

.CatalogueArticles-itemContent {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  padding: 12.5px;
  box-sizing: border-box;
}

.CatalogueArticles--empty {
  border: solid 1px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 248px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 12.5px;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
  flex-direction: column;
}

.CatalogueArticles--empty ::v-deep .Vector {
  width: 38px;
  height: 38px;
  position: relative;
  top: -1px;
  margin-bottom: 15px;
}

.CatalogueArticles--empty ::v-deep .ButtonLink {
  min-width: 300px;
}

.CatalogueArticles--emptyZero {
  font-size: 50px;
}
</style>

<template>
  <div class="CatalogueArticles" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="CatalogueArticles-items">
        <div class="CatalogueArticles-item" v-if="catalogType && articles.length" :key="0">
          <div class="CatalogueArticles-itemContent">
            <CatalogueCustomSquare :catalogType="catalogType" :articles="articles" :nuxtProp="nuxtProp"></CatalogueCustomSquare>
          </div>
        </div>
        <div class="CatalogueArticles-item" v-for="(article, index) in articles" :key="article.id">
          <div class="CatalogueArticles-itemContent">
            <Async :fetch="async () => selectArticleAsync(article.id)">
              <template v-slot:default="{data}">
                <CatalogueArticle :article="data.item"
                                  :display="display"
                                  :index="index">
                </CatalogueArticle>
              </template>
            </Async>
          </div>
        </div>
        <div class="CatalogueArticles--empty" v-if="!articles.length">
          <div class="CatalogueArticles--emptyZero">0</div>
          <Term :term="lang.term(493)"></Term>
          <template v-if="nuxtProp.url.queries.length">
            <br>
            <br>
            <ButtonLink :term="lang.term(494)" :pathIds="[PATHS.CATALOG]"></ButtonLink>
          </template>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>
import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

import {FILTER_DISPLAY} from "../../../@common/constant/FILTER_DISPLAY";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {PATHS} from "../../../@common/constant/PATHS";
import {apiClient} from "../../service/apiClientService";
import selectArticles from "../../../@common/api/article/selectArticles";
import {Article} from "../../../@common/delta/database/Article";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    },
    articles: {
      type: Array,
      default: () => ([])
    },
    catalogType: {
      type: Number,
      default: () => 0
    },
    display: {
      type: String,
      default: () => FILTER_DISPLAY.BIG
    },
  },
  data() {
    const lang = getLangServiceBrowser()
    return {
      PATHS,
      lang,
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 1200
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1600
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        weekend: require('@bbx/vector~master/core/assets/svg/material/weekend.svg'),
      },
      state: {},
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`CatalogueArticles-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`CatalogueArticles-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`CatalogueArticles-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`CatalogueArticles-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`CatalogueArticles-desktopY`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP && this.display === FILTER_DISPLAY.BIG) classes.push('CatalogueArticles-big')
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP && this.display === FILTER_DISPLAY.SMALL) classes.push('CatalogueArticles-small')
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    async selectArticleAsync(articleId) {
      const {data} = await apiClient.do(selectArticles, selectArticles.with({
        $scope: 4,
        $count: false,
        $ids: [articleId],
      }))
      return new Article(data[0])
    },
  }
}
</script>
