<style scoped>
.Picture {
  width: 100%;
  position: relative;
}

.Picture.Picture-pan {
  height: 100%;
  min-height: 100%;
}

.Picture.Picture-adapt {
  height: 100%;
  min-height: 100%;
}

.Picture.Picture-native {
  height: 100%;
  min-height: 100%;
}

.Picture.Picture-loaded.Picture-native {
  min-height: auto;
  height: auto;
}


/**
 * * * * * * * * *
 *  BACKGROUND
 * * * * * * * * *
 */

.Picture--imgBackground {
}

.Picture-pan .Picture--imgBackground {
  opacity: 0;
  transition: opacity 0ms;
  background-size: cover;
  width: 100%;
  height: 100%;
}

.Picture-adapt .Picture--imgBackground {
  display: none;
}

.Picture-native .Picture--imgBackground {
  display: none;
}

.Picture-loaded .Picture--imgBackground {
  transition: opacity 500ms;
  opacity: 1;
}

/**
 * * * * * * * * *
 *  NATIVE
 * * * * * * * * *
 */

.Picture-pan .Picture--content {
  display: none;
}

.Picture-adapt {
  display: flex;
  align-items: center;
  justify-content: center;
}

.Picture-adapt .Picture--content {
  position: relative;
  width: 100%;
  height: 100%;
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
}

.Picture-adapt .Picture--imgNative {
  max-width: 100%;
  max-height: 100%;
  opacity: 0;
  transition: opacity 0ms;
  position: absolute;
}

.Picture-native .Picture--imgNative {
  width: 100%;
  height: auto;
  display: block;
  opacity: 0;
  transition: opacity 0ms;
}

.Picture-loaded .Picture--imgNative {
  transition: opacity 500ms;
  opacity: 1;
}

/**
 * * * * * * * * *
 *  LAODER
 * * * * * * * * *
 */

.Picture--loader {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Picture-native .Picture--loader {
  min-height: 100vh;
  position: relative;
}

.Picture-native.Picture-loaded .Picture--loader {
  position: absolute;
}

/**/

.Picture-native Picture--content {
  height: 0;
  overflow: hidden;
}

.Picture-native.Picture-loaded .Picture--content {
  height: initial;
  overflow: initial;
}
</style>

<template>
  <div class="Picture" :class="class_root" :id="state.rootId">
    <div class="Picture--imgBackground" :style="style_img"/>
    <div class="Picture--content">
      <img class="Picture--imgNative" :src="state.src" :alt="alt" :id="state.imgId"/>
    </div>
    <div v-show="!state.loaded" class="Picture--loader">
      <Loader :rgb="rgbLoader"/>
    </div>
  </div>
</template>

<script>

import {PICTURE_DISPLAY} from "../../../@common/constant/PICTURE_DISPLAY";
import {File} from "../../../@common/delta/database/File";
import {getS3PathFromFile} from "../../../@common/function/getS3Path";
import {waitElement} from "../../../@common/function/waitElement";
import Id from "@bbx/id~master/core/ts/Id";
import {sendError} from "../../function/sendError";
import {getImageMakerPath} from "../../../@common/function/getImageMakerPath";
import {imageMakerPublicConfig} from "../../../../config/public/image-maker.config";

const id = new Id()

const caches = []

export default {
  props: {
    rgbLoader: {
      type: Array,
      default: () => ([
        0,
        0,
        0
      ])
    },
    src: {
      type: String,
      default: () => ''
    },
    file: {
      default: () => new File()
    },
    id: {
      default: () => null
    },
    alt: {
      type: String,
      default: () => ''
    },
    display: {
      type: String,
      default: () => PICTURE_DISPLAY.COVER
    },
    onLoaded: {
      type: Function,
      default: () => (id) => {
      }
    },
    position: {
      type: String,
      default: () => 'center'
    },
    debug: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      state: {
        rootId: `_${id.uniq()}`,
        rootElement: null,
        rootWidth: 0,
        rootHeight: 0,
        vertical: false,

        imgId: `_${id.uniq()}`,
        imgElement: null,

        supportWebP: true,
        src: '',
        loaded: false,
        sizeIndex: -1,
        sizes: [
          256  /* Math.round(144 * 1920 / 1080)  */,
          427  /* Math.round(240 * 1920 / 1080)  */,
          640  /* Math.round(360 * 1920 / 1080)  */,
          853  /* Math.round(480 * 1920 / 1080)  */,
          1280 /* Math.round(720 * 1920 / 1080)  */,
          1920 /* Math.round(1080 * 1920 / 1080) */,
          2560 /* Math.round(1080 * 1920 / 1080) */,
        ],
        retryState: 'fit',
        retryStates: ['fit', 'higher', 'original', 's3'],
      },
    }
  },
  watch: {
    'src': function (v) {
      this.state.src = v;
    },
    'file.id': function () {
      this.download()
    },
  },
  computed: {
    class_root() {
      const classes = [];
      if (this.state.loaded) classes.push(`Picture-loaded`)
      if (this.display === PICTURE_DISPLAY.COVER) classes.push(`Picture-pan`)
      if (this.display === PICTURE_DISPLAY.AUTO) classes.push(`Picture-adapt`)
      if (this.display === PICTURE_DISPLAY.NATIVE) classes.push(`Picture-native`)
      return classes;
    },
    style_img() {
      const style = {}
      if (this.state.src && this.display === PICTURE_DISPLAY.COVER) {
        style.backgroundImage = `url('${this.state.src}')`
        style.backgroundPosition = this.position
      }
      return style;
    },
  },
  beforeMount() {
    window.Modernizr.on('webp', (result) => {
      this.state.supportWebP = result.alpha
      if (!this.state.supportWebP) this.download()
    });
  },
  mounted() {
    this.init().catch(sendError)
  },
  methods: {

    async init() {

      this.state.imgElement = await waitElement(async () => document.getElementById(this.state.imgId))
      this.state.rootElement = await waitElement(async () => document.getElementById(this.state.rootId))

      this.state.rootWidth = this.state.rootElement.clientWidth
      this.state.rootHeight = this.state.rootElement.clientHeight

      this.state.vertical = this.state.rootHeight > this.state.rootWidth

      this.calculSizeIndex()

      this.download()

      this.state.imgElement.onload = () => {
        if (!caches.includes(this.state.src)) caches.push(this.state.src)
        this.onLoad()
      }

      this.state.imgElement.onerror = (err) => {
        this.retryFit()
        sendError(err)
      }
    },

    onLoad() {
      this.state.loaded = true
      this.onLoaded(this.id)
    },

    download() {
      this.file.id ? imageMakerPublicConfig.active ? this.downloadFit() : this.downloadS3() : this.downloadNative()
      const hasCache = caches.includes(this.state.src)
      if (!hasCache) {
        this.state.loaded = false
      }
    },
    downloadNative() {
      this.state.src = this.src
    },
    downloadFit() {
      this.state.src = getImageMakerPath({
        file: this.file,
        size: this.state.sizes[this.state.sizeIndex],
        ext: this.state.supportWebP && this.file.ext.toLowerCase() === 'png' ? 'webp' : undefined
      })
    },
    downloadS3() {
      this.state.src = getS3PathFromFile(this.file)
    },

    retryFit() {

      if (this.state.retryState === 'fit') this.state.retryState = 'higher'
      if (this.state.retryState === 'higher' && this.state.sizeIndex === this.state.sizes.length - 1) this.state.retryState = 'original'
      if (this.state.retryState === 'original') this.state.retryState = 's3'

      if (this.state.retryState === 'higher') {
        this.state.sizeIndex++
        this.downloadFit()
      }

      if (this.state.retryState === 'original') {
        this.state.sizeIndex = -1
        this.downloadFit()
      }

      if (this.state.retryState === 's3') {
        this.downloadS3()
      }
    },

    calculSizeIndex() {
      let sizeIndex = this.state.sizes.findIndex(size => this.state.rootWidth <= size)

      // the cover fonction use the cut an higher file size so we take the higer size
      if (this.display === PICTURE_DISPLAY.COVER && this.state.vertical) sizeIndex += 3
      else if (this.display === PICTURE_DISPLAY.COVER) sizeIndex++

      if (sizeIndex > this.state.sizes.length - 1) sizeIndex = this.state.sizes.length - 1

      if (sizeIndex === -1 && this.state.rootWidth < this.state.sizes[0]) sizeIndex = 0
      if (sizeIndex === -1 && this.state.rootWidth > this.state.sizes[this.state.sizes.length - 1]) sizeIndex = this.state.sizes.length - 1

      this.state.sizeIndex = sizeIndex
    }
  }
}
</script>
