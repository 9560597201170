"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.spplusConfigPublic = void 0;
var ENV_1 = require("../../core/@common/constant/ENV");
var config_1 = require("../config");
if (config_1.config.env === ENV_1.ENV.PRODUCTION) {
    exports.spplusConfigPublic = {
        url: 'https://api.lyra.com/',
        urlCSS: 'https://api.lyra.com/static/js/krypton-client/V4.0/ext/classic-reset.css',
        urlJS: 'https://api.lyra.com/static/js/krypton-client/V4.0/ext/classic.js',
        publicKey: '28937498:testpublickey_gBL2OK8GyREgZTcGLj8o3b6vIjotczvkcyMcc4Iu2EVzT',
    };
}
