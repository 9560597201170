"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.langConfigPublic = void 0;
var ENV_1 = require("../../core/@common/constant/ENV");
var config_1 = require("../config");
if (config_1.config.env === ENV_1.ENV.PRODUCTION) {
    exports.langConfigPublic = {
        scopeWindowId: '16e94a4c-a629-4037-be46-b03f35277f22',
        ftpPath: 'lang',
        url: 'https://api.lesjardins-int.com',
    };
}
