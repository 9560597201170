<style scoped>
.AccountOrder {
}

.AccountOrder--content {
  padding: 25px;
  min-height: 500px;
}

.AccountOrder-mobile .AccountOrder--content {
  padding: 0;
}

.AccountOrder--loaderSpace {
  height: 200px;
  width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AccountOrder--empty {
  border: dotted 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AccountOrder--orders {

}

.AccountOrder--order {
  padding: 25px;
  background-color: rgba(0, 0, 0, 0);
  transition: background-color 300ms;
  border-radius: 4px;
}

.AccountOrder-mobile .AccountOrder--order {
  margin-bottom: 50px;
}

.AccountOrder-mobile .AccountOrder--order {
  padding: 0;
  background-color: rgba(0, 0, 0, 0.03);
}

.AccountOrder--orderBorderBottom {
  border-top: solid 1px rgba(0, 0, 0, 0.1);
}

.AccountOrder--order:hover {
  background-color: rgba(0, 0, 0, 0.03);
}

.AccountOrder--body {
  display: flex;
}

.AccountOrder-tablet .AccountOrder--body {
  flex-direction: column;
}

.AccountOrder-mobile .AccountOrder--body {
  flex-direction: column;
}


.AccountOrder--body ::v-deep .Link {
  display: block;
  height: inherit;
}

.AccountOrder--bigPicture {
  padding: 25px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.03);
  box-sizing: border-box;
  max-height: 170px;
  height: 170px;
}

.AccountOrder-desktop .AccountOrder--bigPicture {
  min-width: 270px;
  width: 270px;
}

.AccountOrder-tablet .AccountOrder--bigPicture {
  width: 100%;
  margin-bottom: 25px;
}

.AccountOrder-mobile .AccountOrder--bigPicture {
  width: 100%;
  margin-bottom: 25px;
}

.AccountOrder--info {
  flex: 1;
}

.AccountOrder-desktop .AccountOrder--info {
  padding-left: 25px;
}

.AccountOrder-tablet .AccountOrder--info {
  padding-left: 0px;
}

.AccountOrder-mobile .AccountOrder--info {
  padding-left: 0px;
}

.AccountOrder--infoHeader {
  display: flex;
  padding-bottom: 25px;
  align-items: center;
}

.AccountOrder-mobile .AccountOrder--infoHeader {
  flex-direction: column;
}

.AccountOrder--infoTitle {
  font-family: 'RalewayBold', sans-serif;
}

.AccountOrder-mobile .AccountOrder--infoTitle {
  padding-bottom: 15px;
}

.AccountOrder--divider {
  width: 1px;
  background-color: rgba(0, 0, 0, 0.25);
  margin: 0 25px;
  height: 15px;
}

.AccountOrder-mobile .AccountOrder--divider {
  display: none;
}


.AccountOrder--orderNumber {
  font-family: 'ComfortaaRegular', sans-serif;
  color: rgba(0, 0, 0, 0.5);
  font-size: 13px;
}

.AccountOrder--date {
  font-family: 'ComfortaaRegular', sans-serif;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
  padding-bottom: 12.5px;
  display: flex;
  align-items: center;
}

.AccountOrder-mobile .AccountOrder--date {
  flex-direction: column;
}

.AccountOrder--articleCount {
  display: flex;
  align-items: center;
  font-family: 'ComfortaaRegular', sans-serif;
}

.AccountOrder-mobile .AccountOrder--articleCount {
  padding-bottom: 25px;
}


.AccountOrder--articleCountNumber {
  border: solid 1px #8A6C59;
  padding: 4px 25px;
  font-size: 14px;
  border-radius: 2px;
}

.AccountOrder--articleCountText {
  padding-left: 12.5px;
  font-size: 12px;
}

.AccountOrder--hyphen {
  margin: 0 12.5px;
}

.AccountOrder-mobile .AccountOrder--hyphen {
  display: none;
}

.AccountOrder--price {
  font-family: 'DDinRegular', sans-serif;
  font-size: 30px;
  padding-right: 12.5px;
  flex: 1;
  text-align: right;
}

.AccountOrder-mobile .AccountOrder--price {
  padding-top: 15px;
  padding-right: 0;
}

.AccountOrder--footer {
  display: flex;
  justify-content: space-between;
  width: 100%;
  min-height: 80px;
}

.AccountOrder-desktop .AccountOrder--footer {
  flex-direction: row;
  align-items: flex-end;
}

.AccountOrder-tablet .AccountOrder--footer {
  flex-direction: column;
  align-items: flex-start;
}

.AccountOrder-mobile .AccountOrder--footer {
  flex-direction: column;
  align-items: center;
}

.AccountOrder--footerLeft {
  display: flex;
  flex: 1;
}

.AccountOrder--smallPictures {
  display: flex;
  flex-flow: wrap;
  width: 100%;
  flex: 1;
}

.AccountOrder-desktop .AccountOrder--smallPictures {

}

.AccountOrder-tablet .AccountOrder--smallPictures {
  padding-bottom: 25px;
}

.AccountOrder-mobile .AccountOrder--smallPictures {
  padding-bottom: 25px;
}

.AccountOrder--smallPicture {
  width: 50px;
  height: 50px;
  padding: 4px;
  border-radius: 4px;
  background: rgba(0, 0, 0, 0.03);
  margin-right: 12.5px;
  margin-top: 12.5px;
}

.AccountOrder--footerRight {
  display: flex;
  align-items: center;
}

.AccountOrder-mobile .AccountOrder--footerRight {
  padding-bottom: 25px;
  flex-direction: column;
}

.AccountOrder-mobile .AccountOrder--footerRight .AccountOrder--divider {
  display: none;
}

.AccountOrder--status {
  display: flex;
  align-items: center;
}

.AccountOrder-desktop .AccountOrder--status {
  flex-flow: row;
}

.AccountOrder-tablet .AccountOrder--status {
  flex-flow: row-reverse;
}

.AccountOrder-mobile .AccountOrder--status {
  flex-flow: row-reverse;
  flex-direction: column;
}

.AccountOrder--statusText {
  font-family: 'RalewayBold', sans-serif;
  font-size: 12px;
}

.AccountOrder-desktop .AccountOrder--statusText {
  padding-right: 12.5px;
}

.AccountOrder-tablet .AccountOrder--statusText {
  padding-left: 12.5px;
}

.AccountOrder-mobile .AccountOrder--statusText {
  padding-bottom: 12.5px;
}

.AccountOrder--statusCurrent {
  background: black;
  color: white;
  padding: 5px 25px;
  border-radius: 4px;
  font-family: 'ComfortaaRegular', sans-serif;
  font-size: 12px;
}

.AccountOrder-mobile .AccountOrder--statusCurrent {
  border-radius: 4px;
  margin-bottom: 25px;
}

.AccountOrder--pagination {
  display: flex;
  width: 100%;
  padding: 50px 0;
}

.AccountOrder-mobile .AccountOrder--pagination {
  justify-content: center;
  padding: 0 0 50px 0;
}

.AccountOrder--bill {
  display: flex;
  flex-direction: column;
}

.AccountOrder--billLoan {
  display: flex;
}

.AccountOrder--billLoanBarre {
  height: 2px;
  background: rgba(0, 0, 0, 0.25);
  margin: 0 2px 0 2px;
  position: relative;
  top: -8px;
}

.AccountOrder--billLoan .AccountOrder--billLoanBarre:first-child {
  margin-left: 0;
}

.AccountOrder--billLoan .AccountOrder--billLoanBarre:last-child {
  margin-right: 0;
}

.AccountOrder--billButton {
  display: flex;
  align-items: center;
  border-radius: 2px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 5px 25px;
  background: rgba(0, 0, 0, 0);
  cursor: pointer;
  transition: background-color 300ms;
}

.AccountOrder--billButton:hover {
  background: rgba(0, 0, 0, 0.1);
}

.AccountOrder--billText {
  font-family: 'RalewayBold', sans-serif;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-right: 12.5px;
}

.AccountOrder--billTextLoan {
  font-family: "Arial Black", sans-serif;
}

.AccountOrder--billIcon {
  position: relative;
  top: 2px;
  width: 18px;
}

/*
 * * * * * * * * * *
 *
 *   MODAL
 *
 * * * * * * * * * *
 */

.AccountOrder--billModal {
  padding: 50px;
}

.AccountOrder--billHeader {
  display: flex;
  align-items: center;
  padding-bottom: 25px;
}

.AccountOrder--billVector {
  width: 24px;
  height: 24px;
  padding-right: 25px;
  cursor: pointer;
}

.AccountOrder--billTerm {
  font-family: 'DDinRegular';
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  cursor: pointer;
  font-size: 14px;
}

.AccountOrder--billSection {
  background: rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-wrap: wrap;
  padding: 15px 30px;
  margin-bottom: 25px;
}

.AccountOrder--billSectionDate {
  font-family: 'DDinRegular', sans-serif;
  font-size: 14px;
}

.AccountOrder--billSectionInfo {
  display: flex;
  align-items: center;
}

.AccountOrder--billSectionPrice {
  font-family: 'ComfortaaBold', sans-serif;
  color: black;
  font-size: 12px;
  padding-right: 15px;
}

.AccountOrder--billSectionStatus {
  background: rgba(0, 0, 0, 0.03);
  min-width: 50px;
  text-align: center;
  border-radius: 4px;
  padding: 4px 16px;
  font-size: 10px;
  text-transform: uppercase;
}

.AccountOrder--billSectionStatus-paid {
  background: black;
  color: white;
}

.AccountOrder--billSectionStatus-error {
  color: rgba(255, 0, 0, 1);
  background: rgba(255, 0, 0, 0.1);
}

.AccountOrder--billAmount {
  border-left: solid 1px rgba(0, 0, 0, 1);
  padding: 50px 0 50px calc(25px + 11px);
  margin-left: 11px;
}

.AccountOrder--billAmountPrice {
  font-family: 'ComfortaaRegular', sans-serif;
  font-size: 32px;
  color: #8A6C59;
  padding-bottom: 12.5px;
}

.AccountOrder--billAmountText {
  color: #8A6C59;
  opacity: 0.5;
}

.AccountOrder--billHeaderDate {
  display: flex;
  align-items: center;
  padding: 25px 0;
}

.AccountOrder--buttonAction {
}

.AccountOrder--buttonAction ::v-deep .ButtonAction {
  margin: auto;
}
</style>

<template>
  <div class="AccountOrder" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="AccountOrder--content">
        <div class="AccountOrder--loaderSpace" v-show="state.loading">
          <LoaderSpace></LoaderSpace>
        </div>
        <div class="AccountOrder--empty" v-show="!state.orders.length && !state.loading">
          Aucune commande trouvé
        </div>
        <div class="AccountOrder--orders" v-show="!state.loading && state.orders.length">
          <template v-for="(order, index) in state.orders">
            <div class="AccountOrder--orderBorderBottom" v-show="index"></div>
            <div class="AccountOrder--order">
              <div class="AccountOrder--body">
                <div class="AccountOrder--bigPicture">
                  <Picture :file="findCover(order).file" :display="pictureDisplay(findCover(order).photo)"></Picture>
                </div>

                <div class="AccountOrder--info">
                  <div class="AccountOrder--infoHeader">
                    <div class="AccountOrder--infoTitle">
                      <Term :term="lang.term(276)"/>
                    </div>
                    <div class="AccountOrder--divider"></div>
                    <div class="AccountOrder--orderNumber">
                      N° {{ order.number }}
                    </div>
                    <div class="AccountOrder--price">
                      {{ price(order) }} €
                    </div>
                  </div>

                  <div class="AccountOrder--date">
                    <div class="AccountOrder--articleCount">
                      <div class="AccountOrder--articleCountNumber">{{ count(order) }}</div>
                      <div class="AccountOrder--articleCountText">
                        <Term :term="lang.term(278)"/>
                        {{ count(order) > 1 ? 's' : '' }}
                      </div>
                    </div>
                    <div class="AccountOrder--hyphen">-</div>
                    <div>
                      <Term :term="lang.term(277)"/>
                      <span>&nbsp;{{ toFrenchDate(order.createdAt) }}</span>
                    </div>
                  </div>

                  <div class="AccountOrder--footer">
                    <div class="AccountOrder--footerLeft">
                      <div class="AccountOrder--smallPictures">
                        <div class="AccountOrder--smallPicture" v-for="filePhoto in findCovers(order)">
                          <Picture :file="filePhoto.file" :display="pictureDisplay(filePhoto.photo)"></Picture>
                        </div>
                        <!--                        <div class="AccountOrder&#45;&#45;smallPicture" v-for="filePhoto in [1,2,3]">-->
                        <!--                          <Picture></Picture>-->
                        <!--                        </div>-->
                      </div>
                    </div>
                    <div class="AccountOrder--footerRight">
                      <div class="AccountOrder--status">
                        <div class="AccountOrder--statusText">
                          <Term :term="lang.term(279)"></Term>
                        </div>
                        <div class="AccountOrder--statusCurrent">
                          <template v-for="orderStateList in order.orderStateLists">
                            <span
                                v-if="ORDER_STATE_LIST.NEED_VALIDATION === orderStateList.id">{{
                                lang.translate(439)
                              }}</span>
                            <span
                                v-if="ORDER_STATE_LIST.IN_PREPARATION === orderStateList.id">{{
                                lang.translate(440)
                              }}</span>
                            <span
                                v-if="ORDER_STATE_LIST.IN_DELIVERING === orderStateList.id">{{
                                lang.translate(441)
                              }}</span>
                            <span
                                v-if="ORDER_STATE_LIST.CANCELED === orderStateList.id">{{
                                lang.translate(442)
                              }}</span>
                            <span
                                v-if="ORDER_STATE_LIST.DELIVERED === orderStateList.id">{{
                                lang.translate(443)
                              }}</span>
                          </template>
                        </div>
                      </div>
                      <div class="AccountOrder--divider"></div>
                      <div class="AccountOrder--bill" @click="() => onClickBillButton(order)">
                        <div class="AccountOrder--billLoan">
                          <template v-for="funding in order.fundings">
                            <template v-for="payment in funding.payments" v-if="funding.payments.length > 1">
                              <template v-for="paymentStateList in payment.paymentStateLists">
                                <div class="AccountOrder--billLoanBarre"
                                     :style="style_billLoanBarre(funding.payments.length, paymentStateList)"></div>
                              </template>
                            </template>
                          </template>
                        </div>
                        <div class="AccountOrder--billButton">
                          <div class="AccountOrder--billText">
                            <Term :term="lang.term(280)"></Term>
                            <template v-for="funding in order.fundings">
                              <span class="AccountOrder--billTextLoan" v-if="funding.payments.length > 1"> - 3X</span>
                            </template>
                          </div>
                          <div class="AccountOrder--billIcon">
                            <Vector :svg="icon.receipt"></Vector>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </div>
        <br>
        <div class="AccountOrder--pagination">
          <Pagination :limit="state.limit"
                      :offset="state.offset"
                      :total="state.orderCount"
                      :range="3"
                      :onOffsetChange="v => state.offset = v">
          </Pagination>
        </div>
      </div>
      <Modal :opened="state.billOpened" :onOpenedChange="v => state.billOpened = v">
        <div class="AccountOrder--billModal">
          <div class="AccountOrder--billHeader">
            <div class="AccountOrder--billVector" @click="() => state.billOpened = false">
              <Vector :svg="icon.clear"></Vector>
            </div>
            <div class="AccountOrder--billTerm" @click="() => state.billOpened = false">
              <Term :term="lang.term(433)"></Term>
            </div>
          </div>
          <div class="AccountOrder--billAmount">
            <div class="AccountOrder--billAmountPrice">
              {{ calculateAmount }} €
            </div>
            <div class="AccountOrder--billAmountText">
              <Term :term="lang.term(434)"></Term>
            </div>
          </div>
          <div class="AccountOrder--billHeaderDate">
            <div class="AccountOrder--billVector">
              <Vector :svg="icon.event"></Vector>
            </div>
            <div class="AccountOrder--billTerm">
              <Term :term="lang.term(435)"></Term>
            </div>
          </div>
          <template v-for="funding in state.order.fundings">
            <template v-for="payment in funding.payments">
              <template v-for="paymentStateList in payment.paymentStateLists">

                <div class="AccountOrder--billSection">
                  <div class="AccountOrder--billSectionDate">{{ formatDate(payment.dateDeadline) }}</div>
                  <div class="AccountOrder--billSectionInfo">
                    <div class="AccountOrder--billSectionPrice">{{ formatPrice(payment.amount) }} €</div>
                    <div class="AccountOrder--billSectionStatus" :class="class_billSectionStatus(paymentStateList)">
                      <template v-if="PAYMENT_STATE_LIST.PAID === paymentStateList.id">
                        <Term :term="lang.term(436)"></Term>
                      </template>
                      <template v-else-if="PAYMENT_STATE_LIST.ERROR === paymentStateList.id">
                        <Term :term="lang.term(437)"></Term>
                      </template>
                      <template v-else>
                        <Term :term="lang.term(438)"></Term>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </template>
          </template>
          <div class="AccountOrder--buttonAction">
            <ButtonAction :svg="icon.cloud_download"
                          :term="lang.term(600)"
                          :loading="state.loadingDownloadBill"
                          :onClick="() => onDownloadBill(state.order.id)">
            </ButtonAction>
          </div>
        </div>
      </Modal>
    </Responsive>
  </div>
</template>

<script>

import moment from 'moment'
import Carbon from '@bbx/carbon~master/core/ts/Carbon';
import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {apiClient} from "../../service/apiClientService";

import selectOrders from "../../../@common/api/order/selectOrders";
import {formatPrice} from "../../../@common/function/formatPrice";
import {findCoverPhoto} from "../../function/findCoverPhoto";
import {noDecimalPrice} from "../../../@common/function/noDecimalPrice";
import {PICTURE_DISPLAY} from "../../../@common/constant/PICTURE_DISPLAY";
import {ORDER_STATE_LIST} from "../../../@common/constant/ORDER_STATE_LIST";
import {PAYMENT_STATE_LIST} from "../../../@common/constant/PAYMENT_STATE_LIST";
import {ORDER_SORT, ORDER_SORT_DIRECTION} from "../../../@common/constant/ORDER";
import {sendError} from "../../function/sendError";
import {Order} from "../../../@common/delta/database/Order";
import {documentMakerConfigPublic} from "../../../../config/public/document-maker.config";
import customOrderPDF from "../../../@common/api/order/customOrderPDF";

const carbon = new Carbon()

export default {
  props: {},
  data() {
    return {
      ORDER_STATE_LIST,
      PAYMENT_STATE_LIST,
      carbon,
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        receipt: require('@bbx/vector~master/core/assets/svg/material/receipt.svg'),
        clear: require('@bbx/vector~master/core/assets/svg/material/clear.svg'),
        event: require('@bbx/vector~master/core/assets/svg/material/event.svg'),
        cloud_download: require('@bbx/vector~master/core/assets/svg/material/cloud_download.svg'),
      },
      state: {
        /**
         * @type number
         */
        limit: 5,
        /**
         * @type number
         */
        offset: 0,
        /**
         * @type boolean
         */
        loading: true,
        /**
         * @type boolean
         */
        loadingDownloadBill: false,
        /**
         * @type Order []
         */
        orders: [],
        /**
         * @type Order
         */
        order: new Order(),
        /**
         * @type number
         */
        orderCount: 0,
        /**
         * @type boolean
         */
        billOpened: false,
      },
    }
  },
  watch: {
    'state.offset': function () {
      this.selectOrder().catch(sendError)
    },
  },
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`AccountOrder-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`AccountOrder-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`AccountOrder-desktop`)
      return classes
    },
    calculateAmount() {
      let total = 0

      for (const funding of this.state.order.fundings) {
        for (const payment of funding.payments) {
          for (const paymentStateList of payment.paymentStateLists) {
            if (paymentStateList.id !== PAYMENT_STATE_LIST.PAID) {
              total += noDecimalPrice(payment.amount)
            }
          }
        }
      }

      if (total) total = total / 100

      return formatPrice(total)
    }
  },
  beforeMount() {
  },
  mounted() {
    this.selectOrder().catch(sendError)
  },
  beforeDestroy() {
  },
  methods: {
    formatPrice,
    toFrenchDate(timestamp) {
      const dateIso = carbon.toDate(timestamp)
      let a = dateIso.split('-')
      a = a.reverse()
      return a.join('/')
    },
    async selectOrder() {
      this.state.loading = true
      const res = await apiClient.do(selectOrders, selectOrders.with({
        $limit: this.state.limit,
        $offset: this.state.offset,
        $sort: ORDER_SORT.CREATED_AT,
        $sortDirection: ORDER_SORT_DIRECTION.DESC,
        $hasStatus: true,
        active: true,
      }))
      this.state.orders = res.data
      this.state.orderCount = res.count
      this.state.loading = false
    },

    /**
     * @param {Order} order
     * @return {number}
     */
    count(order) {
      let count = 0
      for (const basket of order.baskets) {
        count += basket.quantity
      }
      return count
    },

    /**
     * @param {Order} order
     * @return {number}
     */
    findCover(order) {
      return findCoverPhoto(order.baskets[0].articles[0])
    },

    /**
     * @param {Order} order
     * @return {number}
     */
    findCovers(order) {
      let filePhotos = []

      for (let i = 1; i < order.baskets.length; i++) {
        filePhotos.push(findCoverPhoto(order.baskets[i].articles[0]))
      }
      return filePhotos
    },

    /**
     * @param {Photo} photo
     * @returns {string}
     */
    pictureDisplay(photo) {
      return photo.full ? PICTURE_DISPLAY.COVER : PICTURE_DISPLAY.AUTO
    },

    /**
     * @param {Order} order
     * @return {number}
     */
    price(order) {
      let price = 0
      for (const funding of order.fundings) {
        for (const payment of funding.payments) {
          price += payment.amount
        }
      }
      return formatPrice(price)
    },

    /**
     * @param {Order} order
     */
    onClickBillButton(order) {
      this.display(order)
    },

    /**
     * @param {Order} order
     */
    display(order) {
      this.state.order = new Order(order)
      this.state.billOpened = true
    },

    /**
     * @param {number} length
     * @param {PaymentStateList} paymentStateList
     */
    style_billLoanBarre(length, paymentStateList) {
      let style = {
        width: `${100 / length}%`
      }
      if (paymentStateList.id === PAYMENT_STATE_LIST.PAID) style.backgroundColor = `#5db35d`
      if (paymentStateList.id === PAYMENT_STATE_LIST.ERROR) style.backgroundColor = `#e76565`
      return style
    },

    formatDate(timestamp) {
      let carbon = new Carbon()
      return moment(carbon.toDate(timestamp)).format('DD/MM/YYYY')
    },

    /**
     * @param {PaymentStateList} paymentStateList
     */
    class_billSectionStatus(paymentStateList) {
      const classes = []

      if (paymentStateList.id === PAYMENT_STATE_LIST.PAID) classes.push('AccountOrder--billSectionStatus-paid')
      if (paymentStateList.id === PAYMENT_STATE_LIST.ERROR) classes.push('AccountOrder--billSectionStatus-error')

      return classes
    },
    onDownloadBill(orderId) {
      this.downloadBill(orderId).catch(sendError)
    },
    async downloadBill(orderId) {

      this.state.loadingDownloadBill = true

      let msg = this.lang.translate(601)
      let id = ''

      try {
        const {data, success} = await apiClient.do(customOrderPDF, customOrderPDF.with({
          orderId: orderId
        }))
        if (!success) {
          this.state.loadingDownloadBill = false
          return alert(msg)
        }
        id = data.$id
      } catch (err) {
        this.state.loadingDownloadBill = false
        sendError(err)
        return alert(msg)
      }
      let element = document.createElement('a')
      let url = `${documentMakerConfigPublic.urlPdf}?id=${id}&template=bill`
      element.setAttribute('href', url);
      element.setAttribute('target', '_blank');
      element.style.display = 'none';
      document.body.appendChild(element)
      element.click();
      document.body.removeChild(element)
      this.state.loadingDownloadBill = false
    },
  }
}
</script>
