<style scoped lang="scss">

@import "../../scss/color";
@import "../../scss/size";

$square: 75px;
$height: 700px;
$pictureLeft: 280px;

.HomeCatalog {
}

.HomeCatalog--container {
  position: relative;
}

.HomeCatalog-desktop .HomeCatalog--container {
  margin: 0 $homeCarouselDektopLeft;
}

.HomeCatalog-tablet .HomeCatalog--container {

}

.HomeCatalog-mobile .HomeCatalog--container {
  flex-direction: column;
}

/**/

.HomeCatalog--left {
}


.HomeCatalog-desktop .HomeCatalog--left {
  height: $height;
  width: calc(50% + #{$square});
}

@media screen and (max-width: $menu-image-screen) {
  .HomeCatalog-desktop .HomeCatalog--left {

  }
}

.HomeCatalog-tablet .HomeCatalog--left {
  height: $height;
  width: 50%;
}

.HomeCatalog-mobile .HomeCatalog--left {

}

/**/

.HomeCatalog--leftContent {
  width: 100%;
  height: 100%;
  background-color: $primaryLight;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.HomeCatalog-desktop .HomeCatalog--leftContent {
  padding: 0 $square;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeCatalog-desktop .HomeCatalog--leftContent {
    padding: 0 50px;
  }
}

.HomeCatalog-tablet .HomeCatalog--leftContent {
  justify-content: center;
}

.HomeCatalog-mobile .HomeCatalog--leftContent {

}

/**/

.HomeCatalog--edition {
  font-family: 'CommutersSansBold', sans-serif;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  color: $primary;
  text-align: right;
}

.HomeCatalog-desktop .HomeCatalog--edition {
  padding-right: 110px + 5px;
  font-size: 15px;
  padding-top: 100px;
  padding-bottom: 50px;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeCatalog-desktop .HomeCatalog--edition {
    font-size: 14px;
    padding-top: 75px;
    padding-right: 111px;
  }
}

.HomeCatalog-tablet .HomeCatalog--edition {
  font-size: 12px;
  padding-bottom: 50px;
  padding-right: 80px;
}

.HomeCatalog-mobile .HomeCatalog--edition {
  font-size: 12px;
  text-align: center;
  padding-top: (40px - 3px);
  padding-bottom: (40px - 20px);
}

/**/

.HomeCatalog--intro {
  font-family: 'AmbroiseRegular', sans-serif;
  font-size: 30px;
  text-align: center;
}

.HomeCatalog-desktop .HomeCatalog--intro {
  display: none;
}

.HomeCatalog-tablet .HomeCatalog--intro {
  display: none;
}

.HomeCatalog-mobile .HomeCatalog--intro {
  display: block;
  padding-bottom: 2px;
}

/**/

.HomeCatalog--choose {
  display: flex;
}

.HomeCatalog-desktop .HomeCatalog--choose {

}

.HomeCatalog-tablet .HomeCatalog--choose {

}

.HomeCatalog-mobile .HomeCatalog--choose {
  flex-direction: row-reverse;
  align-items: flex-start;
  //padding: (40px - 13px) 20px 0 20px;
  padding: (40px - 12px) 20px 0 20px;
}

/**/

.HomeCatalog--download {
  transform: translateY(0px);
  transition: 500ms;
  cursor: pointer;
  display: block;
}

.HomeCatalog-desktop .HomeCatalog--download {
  position: relative;
  width: 100px;
  height: 100px;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeCatalog-desktop .HomeCatalog--download {
    width: 75px;
    height: 75px;
  }
}

.HomeCatalog-tablet .HomeCatalog--download {
  position: absolute;
  width: 75px;
  height: 75px;
  top: 40px;
  left: 40px;
}

.HomeCatalog-mobile .HomeCatalog--download {

}

.HomeCatalog-mobile .HomeCatalog--download > .Vector {
  width: 60px;
  height: 60px;
  padding-top: 16px;
  padding-left: 9px;
  padding-right: 6px;
}

/**/

.HomeCatalog-desktop.HomeCatalog-mobilier .HomeCatalog--download {
  transform: scale(0.5) translateY(-22px);
}

.HomeCatalog-desktop.HomeCatalog-luminaire .HomeCatalog--download {
  transform: scale(0.5) translateY(55px);
}

@media screen and (max-width: $menu-image-screen) {
  .HomeCatalog-desktop.HomeCatalog-mobilier .HomeCatalog--download {
    transform: scale(0.5) translateY(-15px);
  }

  .HomeCatalog-desktop.HomeCatalog-luminaire .HomeCatalog--download {
    transform: scale(0.5) translateY(58px);
  }
}

/**/

.HomeCatalog-desktop.HomeCatalog-changed.HomeCatalog-mobilier .HomeCatalog--download {
  transform: scale(1) translateY(-22px);
}

.HomeCatalog-desktop.HomeCatalog-changed.HomeCatalog-luminaire .HomeCatalog--download {
  transform: scale(1) translateY(55px);
}

@media screen and (max-width: $menu-image-screen) {
  .HomeCatalog-desktop.HomeCatalog-changed.HomeCatalog-mobilier .HomeCatalog--download {
    transform: scale(1) translateY(-15px);
  }

  .HomeCatalog-desktop.HomeCatalog-changed.HomeCatalog-luminaire .HomeCatalog--download {
    transform: scale(1) translateY(58px);
  }
}

/**/

.HomeCatalog-mobilier .HomeCatalog--download:hover {
  transform: scale(0.75) translateY(-19px);
}

.HomeCatalog-luminaire .HomeCatalog--download:hover {
  transform: scale(0.75) translateY(75px);
}

.HomeCatalog--download ::v-deep > .Vector {
  animation: 30s linear 0s infinite HomeCatalog--rotation;
}

@keyframes HomeCatalog--rotation {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}

.HomeCatalog--downloadArrow {
  position: absolute;
  top: 50%;
  left: 50%;
}

.HomeCatalog-desktop .HomeCatalog--downloadArrow {
  width: 20px;
  height: 20px;
  margin: (-20px /2) 0 0 (-20px / 2);
}

.HomeCatalog-tablet .HomeCatalog--downloadArrow {
  width: 20px;
  height: 20px;
  margin: (-20px /2) 0 0 (-20px / 2);
}

.HomeCatalog-mobile .HomeCatalog--downloadArrow {
  width: 14px;
  height: 14px;
  margin: -8px 0 0 -8px;
}

/**/

.HomeCatalog--titles {
  flex: 1;
}

.HomeCatalog--title {
  color: #31302F;
  font-family: 'AmbroiseRegular', sans-serif;
  opacity: 0.4;
  transition: 500ms;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.HomeCatalog-desktop .HomeCatalog--title {
  font-size: 40px;
  justify-content: flex-end;
  padding-bottom: 25px;
  text-align: right;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeCatalog-desktop .HomeCatalog--title {
    font-size: 35px;
  }
}

.HomeCatalog-tablet .HomeCatalog--title {
  text-align: right;
  font-size: 35px;
  justify-content: flex-end;
  padding-bottom: 25px;
}

.HomeCatalog-mobile .HomeCatalog--title {
  font-size: 22px;
  justify-content: flex-start;
  padding-bottom: 15px;
}

.HomeCatalog--title:hover {
  opacity: 0.75;
}

.HomeCatalog--title-selected {
  opacity: 1;
}

/**/

.HomeCatalog--barre {
  height: 1px;
}

.HomeCatalog-desktop .HomeCatalog--barre {
  width: 70px;
  min-width: 70px;
  margin-left: 40px;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeCatalog-desktop .HomeCatalog--barre {
    width: 70px;
    min-width: 70px;
  }
}

.HomeCatalog-tablet .HomeCatalog--barre {
  width: 40px;
  min-width: 40px;
  margin-left: 40px;
}

.HomeCatalog-mobile .HomeCatalog--barre {
  width: 5px;
  min-width: 5px;
  margin-right: 0px;
  position: relative;
  left: -20px;
}

/**/

.HomeCatalog--barreContent {
  height: 100%;
  width: 200%;
  background-color: #31302F;
  transform: scaleX(0);
  transition: 500ms;
}

/**/

.HomeCatalog-desktop.HomeCatalog-mobilier .HomeCatalog--barreContent {
  transform: scaleX(1) translateY(31px);
}

.HomeCatalog-desktop.HomeCatalog-luminaire .HomeCatalog--barreContent {
  transform: scaleX(1) translateY(108px);
}

/**/

@media screen and (max-width: $menu-image-screen) {
  .HomeCatalog-desktop.HomeCatalog-mobilier .HomeCatalog--barreContent {
    transform: scaleX(1) translateY(27px);
  }

  .HomeCatalog-desktop.HomeCatalog-luminaire .HomeCatalog--barreContent {
    transform: scaleX(1) translateY(97px);
  }
}

/**/
.HomeCatalog-tablet.HomeCatalog-mobilier .HomeCatalog--barreContent {
  transform: scaleX(1) translateY(27px);
}

.HomeCatalog-tablet.HomeCatalog-luminaire .HomeCatalog--barreContent {
  transform: scaleX(1) translateY(97px);
}

/**/
.HomeCatalog-mobile.HomeCatalog-mobilier .HomeCatalog--barreContent {
  transform: scaleX(1) translateY(15px);
}

.HomeCatalog-mobile.HomeCatalog-luminaire .HomeCatalog--barreContent {
  transform: scaleX(1) translateY(56px);
}

/**/

.HomeCatalog--description {
  color: #31302F;
  line-height: 28px;
  font-family: 'AeonikRegular', sans-serif;
  letter-spacing: 0.25px;
}

.HomeCatalog-desktop .HomeCatalog--description {
  padding-left: 52%;
  padding-bottom: 75px;
  padding-right: 150px;
  font-size: 16px;
  position: absolute;
  bottom: 0;
  right: 0;
  text-align: left;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeCatalog-desktop .HomeCatalog--description {
    font-size: 14px;
    padding-left: 360px;
    padding-right: 120px;
  }
}

.HomeCatalog-tablet .HomeCatalog--description {
  text-align: left;
  padding-left: 80px;
  padding-bottom: 50px;
  padding-right: 50px;
  font-size: 14px;
  position: absolute;
  bottom: 0;
  right: 0;
}

.HomeCatalog-mobile .HomeCatalog--description {
  text-align: center;
  font-size: 15px;
  padding: (20px - 3px) 20px (40px - 8px) 20px;
}

/**/

.HomeCatalog--pictureLeft {
  cursor: pointer;
  transform: scale(0.9);
  transition: 500ms;
}

.HomeCatalog-desktop .HomeCatalog--pictureLeft {
  position: absolute;
  width: $pictureLeft;
  height: 355px;
  bottom: -$square;
  left: $square;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeCatalog-desktop .HomeCatalog--pictureLeft {
    bottom: -50px;
    left: 50px;
  }
}

.HomeCatalog-tablet .HomeCatalog--pictureLeft {
  display: none;
}

.HomeCatalog-mobile .HomeCatalog--pictureLeft {
  width: 50%;
  max-height: 50%;
  padding-bottom: 50%;
}

.HomeCatalog-changed .HomeCatalog--pictureLeft {
  transform: scale(1);
}

/**/

.HomeCatalog--pictureLeft1 {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  overflow: hidden;
}

.HomeCatalog-desktop .HomeCatalog--pictureLeft1 {

}

.HomeCatalog-tablet .HomeCatalog--pictureLeft1 {

}

.HomeCatalog-mobile .HomeCatalog--pictureLeft1 {
  //top: 19px;
  top: 50%;
}

/**/

.HomeCatalog--pictureLeftImage {
  width: 100%;
  height: 100%;
  height: calc(100% + #{$square});
  min-height: calc(100% + #{$square});
  position: absolute;
  top: 0;
  left: 0;
  background-color: $primaryLight3;
}

.HomeCatalog--pictureLeftImage ::v-deep .Picture {
  opacity: 0.5;
  transition: 500ms;
}

.HomeCatalog-changed .HomeCatalog--pictureLeftImage ::v-deep .Picture {
  opacity: 1;
}

/**/

.HomeCatalog--right {
  flex: 1;
  width: 100%;
  transform: scale(0.9);
  transition: 500ms;
  z-index: 1;
}

.HomeCatalog-desktop .HomeCatalog--right {
  width: 100%;
  height: $height;
  position: absolute;
  top: - $square;
  left: 100%;
}

.HomeCatalog-tablet .HomeCatalog--right {
  width: 100%;
  height: $height;
  position: absolute;
  top: - $square;
  left: 100%;
}

.HomeCatalog-mobile .HomeCatalog--right {
  position: relative;
  height: 65vh;
  min-height: 65vh;
}

.HomeCatalog-changed .HomeCatalog--right {
  transform: scale(1);
}

/**/

.HomeCatalog--right1 {
  width: 100%;
  height: inherit;
}

.HomeCatalog-desktop .HomeCatalog--right1 {
  position: relative;
  left: -$square;
}

.HomeCatalog-tablet .HomeCatalog--right1 {

}

.HomeCatalog-mobile .HomeCatalog--right1 {
  padding: 0 20px;
  box-sizing: border-box;
}


/**/

.HomeCatalog--rightPicture {
  height: 100%;
  overflow: hidden;
  position: relative;
  display: block;
}

.HomeCatalog-desktop .HomeCatalog--rightPicture {
  width: calc(100% - #{$square});
}

.HomeCatalog-tablet .HomeCatalog--rightPicture {

}

.HomeCatalog-mobile .HomeCatalog--rightPicture {

}

/**/

.HomeCatalog--rightPictureImage {
  width: 100%;
  height: 100%;
  height: calc(100% + 100px);
  min-height: calc(100% + 100px);
  position: absolute;
  top: 0;
  left: 0;
  background-color: $primaryLight3;
}

/**/

.HomeCatalog--rightPictureImage ::v-deep .Picture {
  transition: 500ms;
  opacity: 0.5;
}

.HomeCatalog--rightPictureImagemobilier-mobilier ::v-deep .Picture--imgBackground {
  background-position: bottom center !important;
}

.HomeCatalog-changed .HomeCatalog--rightPictureImage ::v-deep .Picture {
  opacity: 1;
}

/**/

.HomeCatalog--logo {
  position: absolute;
  left: 50%;
}

.HomeCatalog--logo ::v-deep .Vector {
  width: 100%;
  height: 100%;
}

.HomeCatalog-desktop .HomeCatalog--logo {
  width: 340px;
  margin-left: -(340px / 2);
  bottom: 50px;
}

.HomeCatalog-tablet .HomeCatalog--logo {
  width: 300px;
  margin-left: -(300px / 2);
  bottom: 50px;
}

.HomeCatalog-mobile .HomeCatalog--logo {
  width: 75%;
  margin-left: -(75% / 2);
  bottom: 20px;
}
</style>

<template>
  <div class="HomeCatalog" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <div class="HomeCatalog--container">
        <div class="HomeCatalog--left">
          <ScrollParallax :distance="distance" :reverse="false">
            <div class="HomeCatalog--leftContent">
              <div class="HomeCatalog--edition">
                <AnimationTitle>
                  <Term :term="lang.term(666)"></Term>
                </AnimationTitle>
              </div>
              <div class="HomeCatalog--intro">
                <AnimationTitle>
                  <Term :term="lang.term(667)"></Term>
                </AnimationTitle>
              </div>
              <div class="HomeCatalog--description">
                <AnimationTitle>
                  <Term
                      :term="lang.term(668)"></Term>
                </AnimationTitle>
              </div>
              <div class="HomeCatalog--right">
                <div class="HomeCatalog--right1">
                  <ScrollParallax :distance="distance" :reverse="true">
                    <a class="HomeCatalog--rightPicture" :href="href" target="_blank">
                      <ScrollParallax :distance="50" :reverse="true">
                        <div class="HomeCatalog--rightPictureImage" :class="{'HomeCatalog--rightPictureImagemobilier-mobilier': state.type === TYPE.MOBILIER}">
                          <Picture :src="photoRight"></Picture>
                        </div>
                      </ScrollParallax>
                      <div class="HomeCatalog--logo">
                        <Vector :svg="icon.logo"></Vector>
                      </div>
                    </a>
                  </ScrollParallax>
                </div>
              </div>
              <div class="HomeCatalog--choose">
                <a class="HomeCatalog--download" :href="href" target="_blank">
                  <Vector :svg="downloadIcon"></Vector>
                  <div class="HomeCatalog--downloadArrow">
                    <Vector :svg="icon.arrowDown"></Vector>
                  </div>
                </a>
                <div class="HomeCatalog--titles">
                  <div class="HomeCatalog--title" :class="class_title(TYPE.MOBILIER)"
                       @click="() => changeType(TYPE.MOBILIER)">
                    <AnimationTitle>
                      <Term :term="lang.term(669)"></Term>
                    </AnimationTitle>
                  </div>
                  <div class="HomeCatalog--title" :class="class_title(TYPE.LUMINAIRE)"
                       @click="() => changeType(TYPE.LUMINAIRE)">
                    <AnimationTitle>
                      <Term :term="lang.term(670)"></Term>
                    </AnimationTitle>
                  </div>
                </div>
                <div class="HomeCatalog--barre">
                  <div class="HomeCatalog--barreContent"></div>
                </div>
              </div>
              <div class="HomeCatalog--pictureLeft" @click="() => toggleType()">
                <div class="HomeCatalog--pictureLeft1">
                  <ScrollParallax :distance="100" :reverse="true">
                    <div class="HomeCatalog--pictureLeftImage">
                      <Picture :src="photoLeft"></Picture>
                    </div>
                  </ScrollParallax>
                </div>
              </div>
            </div>
          </ScrollParallax>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import Vector from "@bbx/vector~master/core/env/browser/vue/Vector";

const TYPE = {
  MOBILIER: 'mobilier',
  LUMINAIRE: 'luminaire',
}

export default {
  components: {Vector},
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    },
    breakPoints: {
      type: Array,
      default: () => ([])
    },
    verticalBreakPoints: {
      type: Array,
      default: () => ([])
    },
  },
  data() {
    return {
      TYPE,
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: this.breakPoints,
          verticalBreakPoints: this.verticalBreakPoints,
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      icon: {
        logo: require('../../../@common/assets/logo-white.svg'),
        arrowDown: require('../../../@common/assets/home/catalog/arrow-down.svg'),
        dowload: require('../../../@common/assets/home/catalog/dowload.svg'),
        dowloadSmall: require('../../../@common/assets/home/catalog/small-download.svg'),
      },
      img: {
        mobilier: require('../../../@common/assets/home/catalog/mobilier.jpg'),
        luminaire: require('../../../@common/assets/home/catalog/luminaire6.jpg'),
      },
      state: {
        type: 'mobilier',
        changed: true,
        setTimeout: null
      },
      pdf: {
        mobilier: require('../../../@common/assets/home/catalog/mobilier.pdf'),
        luminaire: require('../../../@common/assets/home/catalog/luminaire.pdf'),
      },
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.state.changed) classes.push(`HomeCatalog-changed`)
      if (this.state.type === TYPE.MOBILIER) classes.push(`HomeCatalog-mobilier`)
      if (this.state.type === TYPE.LUMINAIRE) classes.push(`HomeCatalog-luminaire`)
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`HomeCatalog-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`HomeCatalog-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`HomeCatalog-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`HomeCatalog-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`HomeCatalog-desktopY`)
      return classes
    },
    href() {
      return this.state.type === TYPE.MOBILIER ? this.pdf.mobilier : this.pdf.luminaire
    },
    photoLeft() {
      return this.state.type === TYPE.MOBILIER ? this.img.luminaire : this.img.mobilier
    },
    photoRight() {
      return this.state.type === TYPE.MOBILIER ? this.img.mobilier : this.img.luminaire
    },
    distance() {
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) return 100
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) return 100
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) return 0
    },
    downloadIcon() {
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) return this.icon.dowloadSmall
      return this.icon.dowload
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    toggleType() {
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) return
      if (this.state.type === TYPE.MOBILIER) this.changeType(TYPE.LUMINAIRE)
      else if (this.state.type === TYPE.LUMINAIRE) this.changeType(TYPE.MOBILIER)
    },
    changeType(type) {
      this.state.type = type
      this.state.changed = false
      if (this.state.setTimeout) clearTimeout(this.state.setTimeout)
      this.state.setTimeout = setTimeout(() => {
        this.state.changed = true
      }, 300)
    },
    class_title(type) {
      const classes = []
      if (this.state.type === type) classes.push(`HomeCatalog--title-selected`)
      return classes
    },
  }
}
</script>
