"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Gender = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Term_1 = require("./Term");
var User_1 = require("./User");
var Gender = /** @class */ (function () {
    function Gender(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.value = deltaService_1.deltaService.string(props.value, '');
        this.terms = deltaService_1.deltaService.array(props.terms, Term_1.Term);
        this.users = deltaService_1.deltaService.array(props.users, User_1.User);
    }
    return Gender;
}());
exports.Gender = Gender;
