<style scoped>
.MaterialAdmin {
}

.MaterialAdmin--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 25px;
  padding-bottom: 50px;
}

.MaterialAdmin--headerLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.MaterialAdmin--headerRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.MaterialAdmin--headerRight ::v-deep .InputText input {
  border-radius: 4px 0 0 4px;
  position: relative;
  left: 1px;
}

.MaterialAdmin--headerRight ::v-deep .InputSelect input {
  border-radius: 0 4px 4px 0;
}

.MaterialAdmin--spacer {
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
}

.MaterialAdmin--pagination {
  padding: 15px 5px 5px 5px;
}

.MaterialAdmin--loader {
  width: 100%;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MaterialAdmin--modal {
  padding: 0px 50px 50px 50px;
}

.MaterialAdmin--footer {
  display: flex;
  justify-content: flex-end;
}

.MaterialAdmin--empty {
  border: dotted 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
  box-sizing: border-box;
  margin-top: 15px;
}

.MaterialAdmin--empty ::v-deep .Vector {
  width: 24px;
  height: 24px;
  position: relative;
  top: -1px;
  margin-right: 15px;
}

.MaterialAdmin--option {
  width: 24px;
  cursor: pointer;
}

.MaterialAdmin--body {
  display: flex;
  flex-wrap: wrap;
}

.MaterialAdmin--card {
  width: 400px;
  margin: 0px 30px 30px 0px;
  overflow: hidden;
  border-radius: 4px;
  transform: translateY(0px);
  transition: transform 300ms, color 300ms;
  color: rgba(0, 0, 0, 0.5);
}

.MaterialAdmin--card:hover {
  transform: translateY(-5px);
  color: rgba(0, 0, 0, 1);
}

.MaterialAdmin--cardPhoto {
  width: 100%;
  height: 200px;
  background: rgba(0, 0, 0, 0.06);
  display: block;
  text-decoration: none;
  transition: background-color 300ms;
  cursor: pointer;
}

.MaterialAdmin--card:hover .MaterialAdmin--cardPhoto {
  background: rgba(0, 0, 0, 0.12);
}

.MaterialAdmin--card ::v-deep .Picture {
  opacity: 1;
  transition: opacity 300ms;
}

.MaterialAdmin--card:hover ::v-deep .Picture {
  opacity: 0.9;
}

.MaterialAdmin--cardPhotoEmpty {
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.MaterialAdmin--cardPhotoEmpty ::v-deep .Vector {
  fill: rgba(0, 0, 0, 0.1);
  position: relative;
  top: 4px;
}

.MaterialAdmin--cardFooter {
  padding: 15px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.03);
  transition: background-color 300ms;
}

.MaterialAdmin--card:hover .MaterialAdmin--cardFooter {
  /*background: rgba(0, 0, 0, 0.06);*/
}

.MaterialAdmin--value {
  text-transform: capitalize;
}
</style>

<template>
  <div class="MaterialAdmin" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="MaterialAdmin--header">
        <div class="MaterialAdmin--headerLeft">
          <Popover position="bottom-start">
            <template slot="reference">
              <ButtonAction :term="lang.term(157)" :svg="icon.add"></ButtonAction>
            </template>
            <template slot="default">
              <List :svg="icon.add" :text="lang.translate(556)" :onClick="() => openInsert()"></List>
            </template>
          </Popover>
          <div class="MaterialAdmin--spacer"></div>
          <InputSwitch :label="lang.term(160)"
                       :value="state.filterActive"
                       :onChangeValue="v => state.filterActive = v">
          </InputSwitch>
        </div>
        <div class="MaterialAdmin--headerRight">
          <div style="width: 24px;">
            <Vector :svg="icon.search"></Vector>
          </div>
          <div class="MaterialAdmin--spacer"></div>
          <InputText :value="state.filterSearch" :onChangeValue="v => state.filterSearch = v"></InputText>
          <InputSelect :items="state.searchTypes"
                       :values="state.selectedSearchTypes"
                       :max="1"
                       :onChangeValues="v => state.selectedSearchTypes = v">
          </InputSelect>
        </div>
      </div>

      <div class="MaterialAdmin--body" v-if="!state.loadingSelect">

        <template v-for="material in state.materials">
          <div class="MaterialAdmin--card" :key="material.id">
            <a class="MaterialAdmin--cardPhoto" target="_blank" :href="url(material)">
              <Picture v-if="file(material).id" :file="file(material)"></Picture>
              <div class="MaterialAdmin--cardPhotoEmpty" v-if="!file(material).id">
                <div style="width: 24px;">
                  <Vector :svg="icon.panorama"></Vector>
                </div>
              </div>
            </a>
            <div class="MaterialAdmin--cardFooter">

              <template v-for="term in material.terms">
                <span class="MaterialAdmin--value">
                  <b>#{{ material.id }}</b> <Term :term="term"></Term>
                </span>
              </template>

              <Popover position="bottom">
                <template slot="default">
                  <List :svg="icon.edit" :text="`éditer`" :onClick="() => openUpdate(material)"></List>
                  <List :svg="material.active ? icon.archive : icon.unarchive"
                        :text="material.active ? 'désactiver' : 'activer'" :onClick="() => openDelete(material)"></List>
                </template>
                <template slot="reference">
                  <div class="MaterialAdmin--option">
                    <Vector :svg="icon.more_horiz"></Vector>
                  </div>
                </template>
              </Popover>

            </div>
          </div>
        </template>

      </div>
      <div class="MaterialAdmin--empty" v-if="!state.materials.length && !state.loadingSelect">
        <Vector :svg="icon.search"></Vector>
        <Term :term="lang.term(555)"></Term>
      </div>
      <div class="MaterialAdmin--loader" v-show="state.loadingSelect">
        <Loader></Loader>
      </div>
      <div class="MaterialAdmin--pagination">
        <Pagination :limit="state.filterLimit"
                    :offset="state.filterOffset"
                    :total="state.count"
                    :range="10"
                    :onOffsetChange="v => state.filterOffset = v">
        </Pagination>
      </div>
      <Modal :opened="state.openedUpsert" :onOpenedChange="v => state.openedUpsert = v">
        <div class="MaterialAdmin--modal">
          <Form>
            <MaterialForm :material="state.material"
                          :form="state.form"
                          :onChangeMaterial="v => state.material = v">
            </MaterialForm>
            <br>
            <Divider></Divider>
            <br>
            <div class="MaterialAdmin--footer">
              <ButtonAction :term="lang.term(158)" :onClick="() => upsert()"
                            :loading="state.loadingUpsert"></ButtonAction>
              <div class="MaterialAdmin--spacer"></div>
              <ButtonLink :term="lang.term(159)" :onClick="() => state.openedUpsert = false"></ButtonLink>
            </div>
          </Form>
        </div>
      </Modal>
      <ModalConfirm :opened="state.openedRemove"
                    :onOpenedChange="v => state.openedRemove = v"
                    :on-validated="() => remove()"
                    :loading="state.loadingUpsert">
      </ModalConfirm>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {Material} from "../../../@common/delta/database/Material";
import {MATERIAL_SEARCH_ID} from "../../../@common/constant/MATERIAL";
import {controlForm} from "../../function/controlForm";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {apiClient} from "../../service/apiClientService";
import createMaterial from "../../../@common/api/material/createMaterials";
import selectMaterials from "../../../@common/api/material/selectMaterials";
import {File} from "../../../@common/delta/database/File";
import {Debounce} from "../../../@common/ts/Debounce";
import {Term} from "../../../@common/delta/database/Term";
import {normalizeUrl} from "../../../@common/function/normalizeUrl";
import {urlService} from "../../../@common/service/urlService";
import {PATHS} from "../../../@common/constant/PATHS";
import {sendError} from "../../function/sendError";

let debounce = new Debounce()

export default {
  props: {},
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        add: require('@bbx/vector~master/core/assets/svg/material/add.svg'),
        search: require('@bbx/vector~master/core/assets/svg/material/search.svg'),
        edit: require('@bbx/vector~master/core/assets/svg/material/edit.svg'),
        visibility: require('@bbx/vector~master/core/assets/svg/material/visibility.svg'),
        archive: require('@bbx/vector~master/core/assets/svg/material/archive.svg'),
        unarchive: require('@bbx/vector~master/core/assets/svg/material/unarchive.svg'),
        more_horiz: require('@bbx/vector~master/core/assets/svg/material/more_horiz.svg'),
        panorama: require('@bbx/vector~master/core/assets/svg/material/panorama.svg'),
      },
      state: {
        /**
         * @type {Material}
         */
        material: new Material(),
        /**
         * @type {Material[]}
         */
        materials: [],
        /**
         * @type ApiResponseForm
         */
        form: new ApiResponseForm(),
        /**
         * @type {boolean}
         */
        loadingUpsert: false,
        /**
         * @type {boolean}
         */
        loadingSelect: false,
        /**
         * @type {boolean}
         */
        openedUpsert: false,
        /**
         * @type {boolean}
         */
        openedRemove: false,
        /**
         * @type {boolean}
         */
        filterActive: true,
        /**
         * @type {number}
         */
        filterLimit: 8,
        /**
         * @type {number}
         */
        filterOffset: 0,
        /**
         * @type {number}
         */
        count: 0,
        /**
         * @type {string}
         */
        filterSearch: '',
        /**
         * @type {Array<{id:number,value:string}>}
         */
        searchTypes: [
          {
            id: MATERIAL_SEARCH_ID.ID,
            value: 'Id'
          },
          {
            id: MATERIAL_SEARCH_ID.VALUE,
            value: 'Nom'
          },
        ],
        /**
         * @type any[]
         */
        selectedSearchTypes: [
          {
            id: MATERIAL_SEARCH_ID.VALUE,
            value: 'Nom'
          },
        ],
      },
    }
  },
  watch: {
    'state.filterActive': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.filterOffset': function () {
      if (this.state.loadingSelect === false) {
        this.select().catch(sendError)
      }
    },
    'state.filterLimit': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.filterSearch': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.filterIsBatch': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    },
    'state.selectedSearchTypes': function () {
      this.state.filterOffset = 0
      this.select().catch(sendError)
    }
  },
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`MaterialAdmin-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`MaterialAdmin-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`MaterialAdmin-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`MaterialAdmin-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`MaterialAdmin-desktopY`)
      return classes
    },
  },
  beforeMount() {
  },
  mounted() {
    this.select().catch(sendError)
  },
  beforeDestroy() {
  },
  methods: {
    /**
     * @returns {Promise<void>}
     */
    async openInsert() {
      this.state.material = this.defaultMaterial(new Material())
      this.state.openedUpsert = true
    },

    /**
     * @param {Material} material
     * @returns {Promise<void>}
     */
    async openUpdate(material) {
      this.state.material = this.defaultMaterial(new Material(material))
      this.state.openedUpsert = true
    },

    /**
     * @param {Material} material
     * @returns {Promise<void>}
     */
    async openDelete(material) {
      this.state.material = this.defaultMaterial(new Material(material))
      this.state.openedRemove = true
    },

    /**
     * @param {Material} material
     * @returns {Material}
     */
    defaultMaterial(material = new Material) {
      if (!material.terms.length) material.terms.push(new Term())
      for (const term of material.terms) {

      }

      return material
    },

    async select() {
      this.state.loadingSelect = true

      const filter = {
        active: this.state.filterActive,
        $search: this.state.filterSearch,
        $searchType: this.state.selectedSearchTypes.map(a => a.id).pop(),
        $limit: this.state.filterLimit,
        $offset: this.state.filterOffset,
        $sortBy: 'id'
      }

      debounce.callback({
        job: () => apiClient.do(selectMaterials, selectMaterials.with(filter)),
        success: res => {
          this.state.materials = res.data;
          this.state.count = res.count;
          this.state.loadingSelect = false
        },
        error: err => {
          this.state.loadingSelect = false
          sendError(err)
        }
      })
    },

    /**
     * @returns {Promise<void>}
     */
    async remove() {
      this.state.material.active = !this.state.material.active;
      await this.upsert()
    },

    async upsert() {

      const payload = new Material(this.state.material)

      let res = await controlForm({
        apiAction: createMaterial,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loadingUpsert',
        loaderState: this.state,
        payload: payload,
        vue: this,
      });

      if (res.success) {

        this.state.material = this.defaultMaterial()

        this.state.openedUpsert = false
        this.state.openedRemove = false

        await this.select();
      }
    },

    file(material) {
      for (const photo of material.photos) {
        for (const file of photo.files) {
          return new File(file)
        }
      }
      return new File()
    },
    /**
     * @param {Material} material
     * @return {string}
     */
    url(material) {
      let lang = this.lang.lang.iso6391 || LANG_ISO.FR
      return `/${lang}/${urlService.getPathValueById(PATHS.CATALOG, lang)}?materialIds=${material.id}`
    }
  }
}
</script>
