<style scoped>
.ArticleStockDetail {
  padding: 0px 50px 50px 50px;
}

.ArticleStockDetail-row {
  display: flex;
  align-items: center;
}

.ArticleStockDetail-row b {
  margin-right: 15px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 8px 16px;
  border-radius: 4px;
  font-size: 12px;
}

.ArticleStockDetail--name {
  color: rgba(0, 0, 0, 0.5);
  font-size: 10px;
  margin-left: 10px;
}

.ArticleStockDetail--empty {
  border: dotted 2px rgba(0, 0, 0, 0.1);
  width: 100%;
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: rgba(0, 0, 0, 0.5);
  font-size: 14px;
}

.ArticleStockDetail--empty ::v-deep .Vector {
  width: 24px;
  height: 24px;
  position: relative;
  top: -1px;
  margin-right: 15px;
}
</style>

<template>
  <div class="ArticleStockDetail" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <InputSection :svg="icon.archive" :term="lang.term(490)">
        <template v-if="isRestrictedArticleBrowser">
          <div class="ArticleStockDetail--empty">
            <Vector :svg="icon.archive"></Vector>
            <span>Information non disponible</span>
          </div>
        </template>
        <template v-else>
          <template v-for="batch in article.batches">
            <template v-for="product in batch.products">
              <div class="ArticleStockDetail-row">
                <b>{{ product.reference }}</b>
                <Stock :value="getStock(product)"/>
                <span class="ArticleStockDetail--name">
                  <Term v-for="termName in product.termNames" :term="termName" :key="termName.id"></Term>
                </span>
              </div>
              <Divider></Divider>
            </template>
          </template>
        </template>
      </InputSection>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {Article} from "../../../@common/delta/database/Article";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {getArticleStockBrowser} from "../../function/getArticleStockBrowwser";
import {Batch} from "../../../@common/delta/database/Batch";
import {isRestrictedArticleBrowser} from "../../function/isRestrictedArticleBrowser";

export default {
  props: {
    article: {
      type: Article,
      default: () => new Article
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        archive: require('@bbx/vector~master/core/assets/svg/material/archive.svg'),
      },
      state: {},
    }
  },
  watch: {},
  computed: {
    lang() {
      return getLangServiceBrowser()
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`ArticleStockDetail-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`ArticleStockDetail-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`ArticleStockDetail-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`ArticleStockDetail-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`ArticleStockDetail-desktopY`)
      return classes
    },
    isRestrictedArticleBrowser() {
      return isRestrictedArticleBrowser({
        article: this.article,
        stockFinal: getArticleStockBrowser({
          article: this.article,
          checkRestriction: false
        })
      })
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    /**
     * @param {Product} product
     * @return {number}
     */
    getStock(product) {

      let article = new Article()

      article.batches = [
        new Batch({
          quantity: 1,
          products: [product]
        })
      ]

      return getArticleStockBrowser({article})
    },
  }
}
</script>
