<style scoped lang="scss">
.MarqueTab {
  background-color: rgba(236, 232, 228, 0.25);
}

/**/

.MarqueTab--anchors {
  display: flex;
}

.MarqueTab-desktop .MarqueTab--anchors {
  justify-content: center;
}

.MarqueTab-tablet .MarqueTab--anchors {
  justify-content: center;
}

.MarqueTab-mobile .MarqueTab--anchors {

}

/**/

.MarqueTab--anchorContainer {
  display: flex;
  align-items: center;
  flex-wrap: nowrap;
}

.MarqueTab-desktop .MarqueTab--anchorContainer {
  justify-content: center;
  height: 100px;
}

.MarqueTab-tablet .MarqueTab--anchorContainer {
  justify-content: center;
  height: 100px;
}

.MarqueTab-mobile .MarqueTab--anchorContainer {
  height: 75px;
}

/**/

.MarqueTab--anchor {
  position: relative;
  text-decoration: none;
  font-family: 'AmbroiseRegular', sans-serif;
  color: #1B1919;
  white-space: nowrap;
  height: 100%;
  display: flex;
  align-items: center;
  cursor: pointer;
}

.MarqueTab-desktop .MarqueTab--anchor {
  font-size: 30px;
  padding: 0 50px;
}

.MarqueTab-tablet .MarqueTab--anchor {
  font-size: 30px;
  margin: 0 25px;
}

.MarqueTab-mobile .MarqueTab--anchor {
  font-size: 25px;
  margin: 0 15px;
}

/**/

.MarqueTab--underline {
  height: 1px;
  position: absolute;
  bottom: 0px;
  width: 25%;
  left: 37.5%;
}

/**/

.MarqueTab--underlineContent {
  width: 100%;
  height: 100%;
  background-color: #31302F;
  transform: scaleX(0);
  transition: transform 500ms;
}

.MarqueTab--anchor-selected .MarqueTab--underlineContent {
  transform: scaleX(1);
}

.MarqueTab--anchorContainer:hover .MarqueTab--underline .MarqueTab--underlineContent {
  transform: scaleX(0);
}

.MarqueTab--anchor:hover .MarqueTab--underline .MarqueTab--underlineContent {
  transform: scaleX(1);
}
</style>

<template>
  <div class="MarqueTab" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <ScrollZone>
        <div class="MarqueTab--anchors">
          <div class="MarqueTab--anchorContainer">
            <div v-for="tab in tabs" :key="tab.id" class="MarqueTab--anchor" :class="class_anchor(tab)" @click="() => onChange(tab)">
              <div>
                <AnimationTitle :fully="false">
                  <Term :term="tab.term"></Term>
                </AnimationTitle>
              </div>
              <div class="MarqueTab--underline">
                <div class="MarqueTab--underlineContent"></div>
              </div>
            </div>
          </div>
        </div>
      </ScrollZone>
    </Responsive>
  </div>
</template>

<script>

// -----

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {eventService} from "../../service/eventService";
import {Term} from "../../../@common/delta/database/Term";
import {EVENT} from "../../../@common/constant/EVENT";
import {urlService} from "../../../@common/service/urlService";

// -----

export default {
  props: {
    tab: {
      type: Object,
      default: () => ({
        anchor: '',
        term: new Term(),
      })
    },
    tabs: {
      type: Array,
      default: () => ([])
    },
    onChange: {
      type: Function,
      default: () => (tab) => {
      }
    }
  },
  data() {
    return {
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              width: 0
            }),
            new BreakPoint({
              name: SCREEN.TABLET,
              width: 1024
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              width: 1440
            }),
          ],
          verticalBreakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              height: 0
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              height: 600
            }),
          ],
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      state: {},
      events: []
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`MarqueTab-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`MarqueTab-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`MarqueTab-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`MarqueTab-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`MarqueTab-desktopY`)
      return classes
    },
  },
  beforeMount() {

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.ROUTE_HASH_CHANGED,
      callback: (props) => {
        const tab = this.tabs.find(tab => tab.id === props.new)
        if (tab) this.onChange(tab)
      }
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    class_anchor(tab) {
      const classes = []
      if (tab.id === this.tab.id) classes.push(`MarqueTab--anchor-selected`)
      return classes
    }
  }
}
</script>