"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.File = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Photo_1 = require("./Photo");
var Collection_1 = require("./Collection");
var File = /** @class */ (function () {
    function File(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.uuid = deltaService_1.deltaService.string(props.uuid, '');
        this.name = deltaService_1.deltaService.string(props.name, '');
        this.ext = deltaService_1.deltaService.string(props.ext, '');
        this.octet = deltaService_1.deltaService.number(props.octet, 0);
        this.url = deltaService_1.deltaService.string(props.url, '');
        this.photos = deltaService_1.deltaService.array(props.photos, Photo_1.Photo);
        this.collectionCatalogs = deltaService_1.deltaService.array(props.collectionCatalogs, Collection_1.Collection);
    }
    return File;
}());
exports.File = File;
