import {deltaService} from "@bbx/delta~master/core/service/deltaService"
import {Product} from "./Product"
import {Term} from "./Term";
import {DeepPartial} from "../../type/DeepPartial";


export type Highlight_constructor_props = DeepPartial<Highlight>

export class  Highlight {

    id: number
    active: boolean
    createdAt: string
    updatedAt: string

    termValues: Term[]
    products: Product[]

    constructor(props: Highlight_constructor_props = {}) {
                this.id = deltaService.number(props.id, 0)
        this.active = deltaService.boolean(props.active, true)
        this.createdAt = deltaService.string(props.createdAt, '')
        this.updatedAt = deltaService.string(props.updatedAt, '')

        this.termValues = deltaService.array(props.termValues, Term)
        this.products = deltaService.array(props.products, Product)
    }
}
