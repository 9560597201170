"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Newsletter = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Newsletter = /** @class */ (function () {
    function Newsletter(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.email = deltaService_1.deltaService.string(props.email, '');
    }
    return Newsletter;
}());
exports.Newsletter = Newsletter;
