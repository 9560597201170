<style lang="scss">

@import "../../scss/color";
@import "../../scss/size";

.HomeAmbiance--popover.el-popover {
  border: none;
  border: solid 1px white;
  word-break: normal;
  padding: 0 !important;
  line-height: normal;
  border-radius: 0;
  box-shadow: none;
  box-shadow: 0 0 30px rgba(0, 0, 0, 0.05);
}

/**/

.HomeAmbiance--popover .CatalogueArticle {
  background: $primaryLight2;
}

.HomeAmbiance--popover .CatalogueArticle:hover {
  background: $primaryLight3;
}

</style>

<style scoped lang="scss">

@import "../../scss/color";
@import "../../scss/size";

$info-width: 525px;
$square: 75px;
$tablet-square: 40px;

$desktop-point: 50px;
$tablet-point: $desktop-point;
$mobile-point: 20px;

$desktop-container-height: 820px;
$tablet-container-height: $desktop-container-height;
$desktop-titleContainer-height: 820px;
$tablet-titleContainer-height: 550px;

.HomeAmbiance {
}

.HomeAmbiance-desktop {
  padding: 0 $homeCarouselDektopLeft 0 $homeCarouselDektopLeft;
}

.HomeAmbiance-tablet {
  //padding: 0 $tabletHomeCarouselLeft 0 $tabletHomeCarouselLeft;
}

.HomeAmbiance-mobile {
  //padding: 0 $home-carousel-mobile-left 0 $home-carousel-mobile-left;
}

/**/

.HomeAmbiance--container {
  width: 100%;
  position: relative;
}

.HomeAmbiance-desktop .HomeAmbiance--container {
  box-sizing: border-box;
  padding-left: $info-width - $square;
  height: $desktop-container-height;
}

.HomeAmbiance-tablet .HomeAmbiance--container {
  height: $tablet-container-height;
  padding-top: $tablet-titleContainer-height - ($tablet-square * 2);
}

.HomeAmbiance-mobile .HomeAmbiance--container {

}

/**/

.HomeAmbiance--pictureContainer {
  width: 100%;
  height: 100%;
  position: relative;
}

.HomeAmbiance-mobile .HomeAmbiance--pictureContainer {
  height: 65vh;
}

/**/

.HomeAmbiance--picture {
  width: 100%;
  height: 100%;
  overflow: hidden;
  position: relative;
}

.HomeAmbiance--pictureImg {
  width: 100%;
  height: 100%;
  background-color: $primaryLight2;
  position: absolute;
  top: 0;
  left: 0;
}

.HomeAmbiance-desktop .HomeAmbiance--pictureImg {
  height: calc(100% + 100px);
  min-height: calc(100% + 100px);
}

.HomeAmbiance-tablet .HomeAmbiance--pictureImg {
  height: calc(100% + 100px);
  min-height: calc(100% + 100px);
}

.HomeAmbiance-mobile .HomeAmbiance--pictureImg {
  height: calc(100% + 200px);
  min-height: calc(100% + 200px);
}

/**/

.HomeAmbiance--mask {
  width: 100%;
  height: 100%;
  background-color: black;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0;
  transition: 1500ms;
}

.HomeAmbiance-pointerIsHovered .HomeAmbiance--mask {
  opacity: 0.15;
}

/**/

.HomeAmbiance--zone {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
}

.HomeAmbiance-mobile .HomeAmbiance--zone {
  display: none;
}

.HomeAmbiance--point {
  position: absolute;
  top: 50%;
  left: 50%;
  cursor: pointer;
}

.HomeAmbiance-desktop .HomeAmbiance--point {
  width: $desktop-point;
  height: $desktop-point;
  margin: (-$desktop-point / 2) 0 0 (-$desktop-point / 2);
}

.HomeAmbiance-tablet .HomeAmbiance--point {
  width: $tablet-point;
  height: $tablet-point;
  margin: (-$tablet-point / 2) 0 0 (-$tablet-point / 2);
}

.HomeAmbiance-mobile .HomeAmbiance--point {
  width: $mobile-point;
  height: $mobile-point;
  margin: (-$mobile-point / 2) 0 0 (-$mobile-point / 2);
}

.HomeAmbiance--pointBox {
  width: $desktop-point;
  height: $desktop-point;
  animation: 1s linear 0s infinite alternate bounce;
  transform: scale(1);
}

@keyframes bounce {
  from {
    transform: scale(1);
  }
  to {
    transform: scale(0.5);
  }
}

/**/

.HomeAmbiance--article {
  width: 230px;
  height: 310px;
}

/**/

.HomeAmbiance--bounceBarre {
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: solid 1px white;
  border-radius: 100%;
  padding: 20%;
}

.HomeAmbiance--bounceCircle {
  width: 100%;
  height: 100%;
  background: white;
  border-radius: 100%;
}

/**/

.HomeAmbiance--titleContainer {
}

.HomeAmbiance-desktop .HomeAmbiance--titleContainer {
  width: 525px;
  height: $desktop-titleContainer-height - ($square * 2);
  position: absolute;
  top: $square;
  left: 0;
}

.HomeAmbiance-tablet .HomeAmbiance--titleContainer {
  width: calc(100% - #{$tablet-square * 2});
  height: $tablet-titleContainer-height;
  position: absolute;
  top: 0;
  left: $tablet-square * 1;
}

.HomeAmbiance-mobile .HomeAmbiance--titleContainer {

}

/**/

.HomeAmbiance--titleContainer1 {

}

.HomeAmbiance-desktop .HomeAmbiance--titleContainer1 {
  width: 100%;
  height: 100%;
}

.HomeAmbiance-tablet .HomeAmbiance--titleContainer1 {
  max-width: 900px;
  height: 100%;
  margin: auto;
}

.HomeAmbiance-mobile .HomeAmbiance--titleContainer1 {
  width: 100%;
  height: 100%;
}

.HomeAmbiance--title {
  width: 100%;
  height: 100%;
  background-color: $primary;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
}

.HomeAmbiance-desktop .HomeAmbiance--title {
  padding: 75px 50px 50px 60px;
}

.HomeAmbiance-tablet .HomeAmbiance--title {
  padding: 75px 50px 50px 60px;
}

.HomeAmbiance-mobile .HomeAmbiance--title {
  padding: (40px - 3px) 40px 40px 40px;
}

/**/

.HomeAmbiance--collection {
  font-family: 'CommutersSansBold', sans-serif;
  color: #EEE9D8;
  text-transform: uppercase;
  letter-spacing: 0.25px;
}

.HomeAmbiance-desktop .HomeAmbiance--collection {
  font-size: $desktopTitle4FontSize;
  padding-bottom: 50px - 13px;
}

.HomeAmbiance-tablet .HomeAmbiance--collection {
  font-size: $tabletTitle4FontSize;
  padding-bottom: 50px - 13px;
}

.HomeAmbiance-mobile .HomeAmbiance--collection {
  font-size: $mobileTitle4FontSize;
  text-align: center;
  padding-bottom: 40px - 20px;
}

/**/

.HomeAmbiance--break {
  font-family: 'AmbroiseRegular', sans-serif;
  color: #fff;
}

.HomeAmbiance-desktop .HomeAmbiance--break {
  font-size: 45px;
  line-height: 58px;
  padding-bottom: 50px - 22px;
}


.HomeAmbiance-tablet .HomeAmbiance--break {
  font-size: 45px;
  line-height: 58px;
  padding-bottom: 50px - 22px;
}


.HomeAmbiance-mobile .HomeAmbiance--break {
  font-size: 30px;
  text-align: center;
  padding-bottom: 20px;
}

/**/

.HomeAmbiance--description {
  font-family: 'AeonikRegular', sans-serif;
  letter-spacing: 0.25px;
  color: #fff;
  flex: 1;
}

.HomeAmbiance-desktop .HomeAmbiance--description {
  padding-right: 50px;
  line-height: 30px;
  font-size: 16px;
}

.HomeAmbiance-tablet .HomeAmbiance--description {
  padding-right: 50px;
  max-width: 400px;
  line-height: 30px;
  font-size: 16px;
}

.HomeAmbiance-mobile .HomeAmbiance--description {
  text-align: center;
  font-size: 15px;
  line-height: 28px;
}

/**/

.HomeAmbiance--buttonText {
  display: none;
  color: white;
  border: solid 1px rgba(255, 255, 255, 0.5);
  border-radius: 100px;
  width: calc(100%);
  height: 48px;
  justify-content: center;
  align-items: center;
  font-family: 'MontserratSemiBold', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  margin-top: 32px;
  box-sizing: border-box;
}

.HomeAmbiance-mobile .HomeAmbiance--buttonText {
  display: flex;
}

.HomeAmbiance--buttonCircle {
  display: flex;
  justify-content: flex-end;
}

.HomeAmbiance-mobile .HomeAmbiance--buttonCircle {
  display: none;
}

.HomeAmbiance--buttonCircle ::v-deep .ButtonCircle {
  background-color: $primary;
  color: white;
}

.HomeAmbiance--buttonCircle ::v-deep .CircleProgress--progressBarre {
  stroke: white;
}

.HomeAmbiance--buttonCircle ::v-deep .ButtonCircle--vector {
  background-color: $primary;
}

.HomeAmbiance--buttonCircle ::v-deep .Vector {
  fill: white;
}
</style>

<template>
  <div class="HomeAmbiance" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <div class="HomeAmbiance--container">
        <div class="HomeAmbiance--pictureContainer">
          <div class="HomeAmbiance--picture"
               @mouseenter="() => showMask()"
               @mouseleave="() => hideMask()">
            <ScrollParallax :distance="pictureDistance" :reverse="true">
              <div class="HomeAmbiance--pictureImg" ref="pictureImg">
                <Picture :src="img.ambiance"></Picture>
                <div class="HomeAmbiance--mask">
                </div>
                <div class="HomeAmbiance--zone" :style="style_zone">
                  <div class="HomeAmbiance--point"
                       v-for="render in state.renders"
                       :key="render.articleId"
                       :style="style_point(render)">
                    <Popover position="bottom-start" :onOpenedChange="(opened) => fetch(opened, render.articleId)"
                             :width="230"
                             classOverride="HomeAmbiance--popover">
                      <template slot="default">
                        <div class="HomeAmbiance--article">
                          <CatalogueArticle :article="state.article"
                                            :display="FILTER_DISPLAY.SMALL">
                          </CatalogueArticle>
                        </div>
                      </template>
                      <template slot="reference">
                        <div class="HomeAmbiance--pointBox">
                          <div class="HomeAmbiance--bounceBarre">
                            <div class="HomeAmbiance--bounceCircle"></div>
                          </div>
                        </div>
                      </template>
                    </Popover>
                  </div>
                </div>
              </div>
            </ScrollParallax>
          </div>
        </div>
        <div class="HomeAmbiance--titleContainer">
          <div class="HomeAmbiance--titleContainer1">
            <ScrollParallax :distance="titleDistance">
              <div class="HomeAmbiance--title">
                <div class="HomeAmbiance--collection">
                  <AnimationTitle :fully="fully">
                    <Term :term="lang.term(659)"></Term>
                  </AnimationTitle>
                </div>
                <div class="HomeAmbiance--break">
                  <AnimationTitle :fully="fully">
                    <Term :term="lang.term(660)"></Term>
                  </AnimationTitle>
                </div>
                <div class="HomeAmbiance--description">
                  <AnimationTitle :fully="fully">
                    <Term
                        :term="lang.term(661)"></Term>
                  </AnimationTitle>
                </div>
                <div class="HomeAmbiance--buttonCircle" @click="goToCatalog">
                  <ButtonCircle :term="lang.term(662)">
                  </ButtonCircle>
                </div>
                <div class="HomeAmbiance--buttonText" @click="goToCatalog">
                  {{ lang.translate(663) }}
                </div>
              </div>
            </ScrollParallax>
          </div>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {FILTER_DISPLAY} from "../../../@common/constant/FILTER_DISPLAY";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import ButtonCircle from "../button/ButtonCircle";
import {COLOR} from "../../../@common/constant/COLOR";
import {Article} from "../../../@common/delta/database/Article";
import {eventService} from "../../service/eventService";
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {EVENT} from "../../../@common/constant/EVENT";
import {apiClient} from "../../service/apiClientService";
import selectArticles from "../../../@common/api/article/selectArticles";
import {PATHS} from "../../../@common/constant/PATHS";
import {changeRoute} from "../../function/changeRoute";


export default {
  components: {ButtonCircle},
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    },
    breakPoints: {
      type: Array,
      default: () => ([])
    },
    verticalBreakPoints: {
      type: Array,
      default: () => ([])
    },
  },
  data() {
    const lang = getLangServiceBrowser()
    return {
      FILTER_DISPLAY,
      COLOR,
      lang,
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: this.breakPoints,
          verticalBreakPoints: this.verticalBreakPoints,
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      img: {
        ambiance: require('../../../@common/assets/home/ambiance/ambiance.jpg')
      },
      icon: {
        north: require('@bbx/vector~master/core/assets/svg/material/north.svg'),
        south: require('@bbx/vector~master/core/assets/svg/material/south.svg'),
        east: require('@bbx/vector~master/core/assets/svg/material/east.svg'),
      },
      state: {
        /**
         * @type Article
         */
        article: new Article(),
        /**
         * @type boolean
         */
        pointerIsHovered: false,
        /**
         * @type any
         */
        square: {
          width: 640,
          height: 427,
        },
        /**
         * @type any
         */
        ratio: {
          width: 640,
          height: 427,
        },
        /**
         * @type any[]
         */
        coordinates: [
          {
            id: 1,
            x: 85,
            y: -10,
            articles: [{
              id: 120
            }]
          },
          {
            id: 2,
            x: -48,
            y: -42,
            articles: [{
              id: 813
            }]
          },
        ],
        /**
         * @type any[]
         */
        renders: []
      },
      events: []
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.state.pointerIsHovered) classes.push('HomeAmbiance-pointerIsHovered')
      if (this.state.display === FILTER_DISPLAY.BIG) classes.push('HomeAmbiance-big')
      if (this.state.display === FILTER_DISPLAY.SMALL) classes.push('HomeAmbiance-small')
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`HomeAmbiance-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`HomeAmbiance-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`HomeAmbiance-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`HomeAmbiance-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`HomeAmbiance-desktopY`)
      return classes
    },
    style_zone() {
      let style = {}
      style.width = `${this.state.ratio.width}px`
      style.height = `${this.state.ratio.height}px`
      style.margin = `-${this.state.ratio.height / 2}px 0 0 -${this.state.ratio.width / 2}px`
      return style
    },
    fully() {
      return this.props.Responsive.breakPoint.name === SCREEN.DESKTOP
    },
    pictureDistance() {
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) return 50
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) return 50
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) return 100
    },
    titleDistance() {
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) return 75
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) return 0
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) return 0
    }
  },
  beforeMount() {

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.SIZE_CHANGED,
      callback: () => {
        this.state.renders = this.render()
      }
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
    this.state.renders = this.render()
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    style_point(render) {
      let style = {}
      style.transform = `translate(${render.x}px,${render.y}px)`
      return style
    },
    render() {

      let {width, height} = this.$refs.pictureImg.getBoundingClientRect()

      let renders = []

      this.state.ratio = this.ratio({
        x1: this.state.square.width,
        y1: this.state.square.height,
        x2: width,
        y2: height
      })

      for (const coordinate of this.state.coordinates) {
        renders.push({
          x: (this.state.ratio.width / 2) * coordinate.x / (this.state.square.width / 2),
          y: (this.state.ratio.height / 2) * coordinate.y / (this.state.square.height / 2),
          articleId: new Article(coordinate.articles[0]).id
        })
      }

      return renders
    },
    ratio({x1, y1, x2, y2, debug = false}) {
      // let x1 = 400
      // let y1 = 800

      let direction1 = 'carré'

      if (x1 > y1) direction1 = 'horizontal'
      if (x1 < y1) direction1 = 'vertical'

      if (debug) console.log('-----------')

      if (debug) console.log({direction1, x1, y1})

      // let x2 = 100
      // let y2 = 150

      let direction2 = 'carré'

      if (x2 > y2) direction2 = 'horizontal'
      if (x2 < y2) direction2 = 'vertical'

      if (debug) console.log({direction2, x2, y2})

      let resize = 'width-height'

      if (direction1 === 'horizontal' && direction2 === 'vertical') resize = 'height'
      if (direction1 === 'vertical' && direction2 === 'horizontal') resize = 'width'

      if (direction1 === 'horizontal' && direction2 === 'horizontal') {

        let r1 = x1 / y1
        let r2 = x2 / y2

        if (r1 > r2) resize = 'height'
        if (r1 < r2) resize = 'width'

        if (debug) console.log({r1, r2})
      }

      if (direction1 === 'vertical' && direction2 === 'vertical') {

        let r1 = y1 / x1
        let r2 = y2 / x2

        if (r1 > r2) resize = 'width'
        if (r1 < r2) resize = 'height'

        if (debug) console.log({r1, r2})
      }

      if (debug) console.log({resize})

      let x3 = x2
      let y3 = y2

      if (resize === 'width') {
        x3 = x2
        y3 = x2 * y1 / x1
      }

      if (resize === 'height') {
        y3 = y2
        x3 = y2 * x1 / y1
      }

      if (debug) console.log({x3, y3})

      return {
        width: x3,
        height: y3
      }
    },
    showMask() {
      // this.state.pointerIsHovered = true
    },
    hideMask() {
      this.state.pointerIsHovered = false
    },
    async fetch(opened, articleId) {
      if (opened) this.state.article = await this.selectArticleAsync(articleId)
      else this.state.article = new Article()
    },
    async selectArticleAsync(articleId) {
      const {data} = await apiClient.do(selectArticles, selectArticles.with({
        $scope: 1,
        $ids: [articleId],
      }))
      return new Article(data[0])
    },
    goToCatalog() {
      changeRoute([PATHS.CATALOG], {ids: this.state.renders.map(a => a.articleId).join(',')})
    },
  }
}
</script>
