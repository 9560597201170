"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.noEmptyRelation = void 0;
function noEmptyRelation(data) {
    return JSON.parse(JSON.stringify(data, function (k, v) {
        if (Array.isArray(v) && v.length === 0)
            return undefined;
        return v;
    }));
}
exports.noEmptyRelation = noEmptyRelation;
