import {isRestrictedArticle} from "../../@common/function/isRestrictedArticle";
import {Article} from "../../@common/delta/database/Article";
import {STATE_ROLE_ID, STATE_USER_ID, stateService} from "../service/stateService";

export function isRestrictedArticleBrowser(props: { article: Article, roleIds?: number[], userIds?: number[], stockFinal: number }): boolean {

    // -----

    let roleId = stateService.get(STATE_ROLE_ID)
    let userId = stateService.get(STATE_USER_ID)

    // -----

    return isRestrictedArticle({
        article: props.article,
        roleIds: [roleId],
        userIds: [userId],
        stockFinal: props.stockFinal
    })
}

