"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Address = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Country_1 = require("./Country");
var Street_1 = require("./Street");
var Delivery_1 = require("./Delivery");
var User_1 = require("./User");
var GeographicalPosition_1 = require("./GeographicalPosition");
var Shop_1 = require("./Shop");
var Funding_1 = require("./Funding");
var Address = /** @class */ (function () {
    function Address(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.zipCode = deltaService_1.deltaService.string(props.zipCode, '');
        this.city = deltaService_1.deltaService.string(props.city, '');
        this.favorite = deltaService_1.deltaService.boolean(props.favorite, false);
        this.name = deltaService_1.deltaService.string(props.name, '');
        this.country = deltaService_1.deltaService.string(props.country, '');
        this.geoAuto = deltaService_1.deltaService.boolean(props.geoAuto, true);
        this.countries = deltaService_1.deltaService.array(props.countries, Country_1.Country);
        this.streets = deltaService_1.deltaService.array(props.streets, Street_1.Street);
        this.geographicalPositions = deltaService_1.deltaService.array(props.geographicalPositions, GeographicalPosition_1.GeographicalPosition);
        this.deliveries = deltaService_1.deltaService.array(props.deliveries, Delivery_1.Delivery);
        this.users = deltaService_1.deltaService.array(props.users, User_1.User);
        this.shops = deltaService_1.deltaService.array(props.shops, Shop_1.Shop);
        this.fundings = deltaService_1.deltaService.array(props.fundings, Funding_1.Funding);
    }
    return Address;
}());
exports.Address = Address;
