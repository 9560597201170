<template>
  <div class="magasin">
    <div>
      <p class="magasin-title">Magasin d’usine</p>
    </div>

    <div class="magasin-row">
      <div class="magasin-content-box magasin-content">
        <p class="magasin-content-title">
          Notre magasin d’usine ouvre ses portes à Aix-en-Provence.
        </p>

        <p class="magasin-content-subtitle">
          Fabricant et créateur de mobilier d’extérieur et de luminaires
          solaires depuis plus de 30 ans.
        </p>

        <p class="magasin-content-text">
          Nos produits proviennent de fins de série, de retours de salons,
          produits déballés ou prototypes. Certains produits peuvent parfois
          présenter des défauts d’aspect, justifiant une réduction pouvant
          s’élever jusqu’a -50% du prix de vente initial. À travers notre
          démarche, nous agissons contre le gaspillage et nous participons à
          notre niveau, à la préservation de l’environnement.
        </p>
      </div>
      <div class="magasin-content-box magasin-right-banner-box">
        <div class="magasin-right-banner"></div>
      </div>

      <div class="magasin-offer-box">
        <span class="magasin-offer-box-label"> Jusqu’à </span>
        <span class="magasin-offer-box-value"> -50% </span>
      </div>
    </div>

    <div class="magasin-row magasin-contact-row">
      <div class="magasin-content-box">
        <div class="magasin-map" id="map"></div>
      </div>
      <div class="magasin-content-box magasin-address">
        <h1 class="magasin-address-title">
          MAGASIN D’USINE LES JARDINS <span>®</span>
        </h1>

        <p>
          1115 Rue René Descartes <br />
          ZAC du Parc de la Duranne <br />
          13857 Aix-en-Provence
        </p>
        <p>magasin@lesjardins.com</p>
        <p>
          Ouvert du mercredi au samedi <br />
          de 10h à 19h
        </p>
        <p>Tél. : 04 42 90 45 30</p>
      </div>
    </div>

    <div class="magasin-bottom">
      <h1 class="magasin-bottom-title">Ce qui vous attend en magasin</h1>

      <div class="magasin-bottom-row">
        <div class="magasin-bottom-item">
          <img
            height="37px"
            :src="
              require('../../../../core/@common/assets/marque/new-store/choix.png')
            "
          />
          <p>le choix</p>
        </div>
        <div class="magasin-bottom-item">
          <img
            height="23px"
            :src="
              require('../../../../core/@common/assets/marque/new-store/livraison.png')
            "
            alt=""
          />
          <p>la livraison</p>
        </div>
        <div class="magasin-bottom-item">
          <img
            height="33px"
            :src="
              require('../../../../core/@common/assets/marque/new-store/conseil.png')
            "
            alt=""
          />
          <p>le conseil</p>
        </div>
      </div>
    </div>
  </div>
</template>
<style lang="scss" scoped>
$title-font: "AmbroiseRegular", sans-serif;
$subtitle-font: "CommutersSansBold", sans-serif;
$regular-font: "AeonikRegular", sans-serif;

* {
  box-sizing: border-box;
  font: normal 18px / 25px $regular-font;
}

.magasin-row {
  display: flex;
  flex-wrap: wrap;
  position: relative;
}

.magasin-content-box {
  width: 50%;
  flex: 0 0 auto;
}

.magasin {
  &-title {
    text-align: center;
    font: normal 30px $title-font;
    padding: 9px 0 5px;
  }

  &-content {
    padding: 83px 98px 46px 163px;
    background-color: rgba(#afb593, 0.3);
    position: relative;

    &-title {
      font: normal 50px/52px $title-font;
      margin-bottom: 39px;
    }

    &-subtitle {
      font: normal 15px / 23px $subtitle-font;
      text-transform: uppercase;
      margin-bottom: 40px;
      margin-right: 10%;
    }

    &-text {
      margin-right: 4%;
    }
  }

  &-offer-box {
    height: 258px;
    width: 258px;
    border-radius: 50%;
    background-color: black;
    color: white;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    position: absolute;
    // right: 0;
    left: 50%;
    top: 50%;
    transform: translateX(-50%) translateY(-50%);

    &-label {
      font: normal 15px / 24px HelveticaBold, $subtitle-font;
      text-transform: uppercase;
      margin-bottom: 10px;
    }
    &-value {
      font: 800 80px / 60px "DDinBold", $regular-font;
    }
  }

  &-right-banner {
    background-image: url("../../../../core/@common/assets/marque/new-store/pch-0953-ta-02340-amb-4@3x.png");
    background-size: cover;
    background-position: center;
    height: 100%;
  }

  &-contact-row {
    align-items: center;
  }

  &-map {
    background-color: #e5e5e5;
    width: 479px;
    height: 407px;
    margin: 70px auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &-address {
    h1 {
      font: normal 24px / 22px $title-font;
      text-transform: uppercase;
      margin-bottom: 25px;

      > span {
        font-size: inherit;
        margin-left: -4px;
      }
    }

    p {
      font: normal 20px / 24px $regular-font;
      margin-bottom: 20px;
    }
  }

  &-bottom {
    padding-bottom: 100px;

    &-title {
      font: normal 50px / 50px $title-font;
      margin: 50px 10px;
      text-align: center;
    }

    &-row {
      display: flex;
      // align-items: center;
      justify-content: center;
    }

    &-item {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;

      &:not(:first-child) {
        margin-left: 80px;
      }

      p {
        text-transform: uppercase;
        text-align: center;
        font: normal 15px / 24px $regular-font;
        // margin-top: 20px;
        margin-top: auto;
      }

      img {
        margin-bottom: 20px;
        margin-top: auto;
        width: auto;
      }
    }
  }
}

svg {
  display: none;
}

@media screen and (max-width: 768px) {
  .magasin-row {
    flex-direction: column;
  }

  .magasin-content-box {
    width: 100%;
  }

  .magasin {
    &-content {
      padding: 44px 53px 0;

      &-title {
        font-size: 40px;
        line-height: 45px;
        text-align: center;
        margin-bottom: 27px;
      }

      &-subtitle {
        text-align: center;
        margin-right: 0;
      }

      &-text {
        font-size: 16px;
        line-height: 22px;
        margin-right: 0;
      }
    }

    &-right-banner {
      height: 233px;
      margin: 40px auto 85px;
      max-width: 90vw;

      &-box {
        background-color: rgba(#afb593, 0.3);
      }
    }

    &-offer-box {
      right: unset;
      top: 100%;
      left: 50%;
    }

    &-map {
      max-width: 90vw;
      margin: 185px auto 50px;
    }

    &-address {
      text-align: center;
    }

    &-bottom {
      &-row {
        max-width: 90vw;
        margin: 0 auto;
        justify-content: space-around;
      }

      &-item {
        flex: 1 1 auto;
        &:not(:first-child) {
          margin-left: 0;
        }
      }
    }
  }
}
</style>
<script>
import {Loader} from "@googlemaps/js-api-loader"
import {googleConfigPublic} from "../../../../config/public/google.config";

export default {
  async mounted() {
    const loader = new Loader({
      apiKey: googleConfigPublic.map.apiKey,
      version: "weekly",
    });
    await loader.load()
    const map = new window.google.maps.Map(document.getElementById("map"), {
      center: { lat: 43.4945452, lng: 5.345517 },
      zoom: 15,
    });
    const marker = new window.google.maps.Marker({
      position: {
        lat: 43.4945452,
        lng: 5.345517,
      },
    });
    window.google.maps.event.addListener(marker, 'click', function () {
      window.open(`https://www.google.com/maps/place/MAGASIN+D'USINE+LES+JARDINS%C2%AE/@43.4943626,5.3467363,15z/data=!4m5!3m4!1s0x0:0x9be86ac16f402a48!8m2!3d43.4943626!4d5.3467363?hl=fr`, '_blank');
    });
    marker.setMap(map);
  }
}
</script>