<style scoped>

</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="content-wrapper none for-title">
      <div class="container_12">
        <div class="main-container main-main-container col1-layout">
          <div class="grid_12 col-main">
            <div class="page-title">
              <h1>Entretien teck : Rénover une table en teck - Les Jardins Vente Privée</h1>
            </div>
            <div class="std">
              <p style="text-align: justify;">
                <img alt="" src="./media/wysiwyg/10.jpg">&nbsp;
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le
                  <b>teck</b>
                  est un bois imputrescible très utilisé pour les
                  <b>meubles de jardin</b>
                  . Ce
                  <b>bois exotique</b>
                  de couleur dorée nécessite un entretien régulier pour préserver sa belle couleur et éviter qu’il ne
                  vire au gris. Il convient donc de l’entretenir régulièrement pour prolonger son aspect naturel et
                  retirer les tâches éventuelles ! Une fois que aurez choisi votre mobilier de jardin en teck
                  , il vous faudra en assurer le traitement.
                </span>
              </p>
              <h2 style="text-align: justify;">
                Comment nettoyer le bois ?
              </h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">
                  <b>Le teck ne craint pas l’eau</b>
                  (comme c’est indiqué sur la plupart des fiches produits de notre collection de&nbsp;
                  salons de jardin en teck
                  ), il peut donc être lavé à grande eau avec un chiffon. Pour retirer les taches légères, il est
                  possible d’utiliser une brosse et un savon tel qu’un produit vaisselle sur toute la surface du meuble.
                  Une recommandation importante doit toutefois être prise en compte : même s’il est solide, le teck ne
                  supporte pas les jets d’eau très puissants (type Kärcher).
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Dans le cas de taches plus importantes ou incrustées, le
                  <b>papier de verre</b>
                  est le moyen le plus sûr pour les déloger. Poncez doucement, puis lavez à grande eau.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Afin d’entretenir
                  votre table et vos
                  chaises
                  , utilisez une
                  <b>huile spécifique</b>
                  et un pinceau. Ces soins créent une pellicule sur le bois qui le rend plus imperméable aux taches.
                  Pour les taches les plus tenaces, il suffit de les brosser avec du vinaigre blanc et de l’eau une fois
                  les huiles appliquées.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <div id="articleproduct" style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </div>
              <h2 style="text-align: justify;">Comment lui rendre sa couleur ?
                <img style="float: right; padding-left: 20px;" alt="" src="./media/wysiwyg/img13.jpg">
              </h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le nettoyage régulier finit par faire pâlir le teck. L’utilisation d’une
                  huile de lin est recommandée pour lui rendre sa belle teinte. Cette huile est à appliquer une fois par
                  an.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Pour un entretien moins régulier, vous pouvez également appliquer des
                  produits spécifiques à l’entretien des bateaux. Composés d’un mélange d’huile et d’eau, ces produits
                  encore plus résistants assureront la protection de votre mobilier en teck pour trois ans. Optez de
                  préférence pour un produit très fluide afin que l’application soit plus facile.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">&nbsp;</p>
              <h2 style="text-align: justify;">Le protéger</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Après un bon nettoyage, la
                  <b>pose d’un vernis</b>
                  est incontournable. Choisissez toujours un vernis spécifique au bois de teck ou pour les bois
                  exotiques d’extérieur. Il permettra, en effet, de
                  <b>protéger le teck</b>
                  , vous n’aurez ainsi plus qu’à le nettoyer rapidement à l’éponge à chaque utilisation.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">À l’origine, le teck est un bois écologique. Une fois transformé, il
                  n’est pas nécessaire d’utiliser des produits chimiques pour le nettoyer. Avec un bon entretien, vous
                  pourrez prolonger la durée de vie de votre mobilier
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <h2 style="text-align: justify;">Des kits complets</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Il existe également des kits pour nettoyer, protéger et entretenir la
                  couleur naturelle du teck.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Composés d’un nettoyant, d’un rénovateur et d’un protecteur, ces kits
                  :
                </span>
              </p>
              <ul style="text-align: justify;">
                <li>
                  <span style="font-size: large;">éliminent salissures, poussières et autres pollutions</span>
                </li>
                <li>
                  <span style="font-size: large;">dégrisent et désoxydent le bois</span>
                </li>
                <li>
                  <span style="font-size: large;">protègent le bois des futurs tâches.</span>
                </li>
              </ul>
              <p style="text-align: justify;">
                <span style="font-size: large;">Votre
                  table
                  est propre et belle. Mais n’hésitez pas à l’entretenir très régulièrement. N’attendez pas que les
                  tâches pénètrent dans le bois et intervenez rapidement.
                </span>
              </p>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../../function/getH1";
import {Term} from "../../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../../@common/function/helperUrl";

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    },
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FAQ-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
