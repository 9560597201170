<style scoped>
.ForumAdmin {
  padding: 0px 50px 50px 50px;
}

.ForumAdmin--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding-top: 40px;
  padding-bottom: 32px;
}

.ForumAdmin--headerLeft {
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.ForumAdmin--headerRight {
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.ForumAdmin--headerRight ::v-deep .InputText input {
  border-radius: 4px 0 0 4px;
  position: relative;
  left: 1px;
}

.ForumAdmin--headerRight ::v-deep .InputSelect input {
  border-radius: 0 4px 4px 0;
}

.ForumAdmin--loader {
  width: 100%;
  height: 108px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.ForumAdmin--pagination {
  padding: 5px;
}

.ForumAdmin--modal {
  padding: 0px 50px 50px 50px;
}

.ForumAdmin--modalPhoto {
  padding: 50px;
}

.ForumAdmin--footer {
  display: flex;
  justify-content: flex-end;
}

.ForumAdmin--spacer {
  width: 15px;
  height: 15px;
  min-width: 15px;
  min-height: 15px;
}

.ForumAdmin--email {
}

.ForumAdmin--link {
  display: block;
  text-decoration: none;
  width: 100%;
}

.ForumAdmin--option {
  width: 24px;
  cursor: pointer;
}

.ForumAdmin--name {
  display: flex;
}

.ForumAdmin--name ::v-deep .Tag {
  margin-right: 10px;
}
</style>

<template>
  <div class="ForumAdmin" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="ForumAdmin--header">
        <div class="ForumAdmin--headerLeft">
          <InputSwitch :label="lang.term(160)"
                       :value="state.filterActive"
                       :onChangeValue="v => state.filterActive = v">
          </InputSwitch>
        </div>
        <div class="ForumAdmin--headerRight">
          <div style="width: 24px;">
            <Vector :svg="icon.search"></Vector>
          </div>
          <div class="ForumAdmin--spacer"></div>
          <InputText :value="state.filterSearch" :onChangeValue="v => state.filterSearch = v"></InputText>
          <InputSelect :items="state.searchTypes"
                       :values="state.selectedSearchTypes"
                       :max="1"
                       :onChangeValues="v => state.selectedSearchTypes = v">
          </InputSelect>
        </div>
      </div>
      <div class="ForumAdmin--body" v-show="!state.isLoading">
        <el-table
            :data="state.forums"
            style="width: 100%">
          <el-table-column
              width="50"
              label="Id"
              prop="id"
          >
          </el-table-column>
          <el-table-column width="200" :label="lang.translate(548)">
            <template slot-scope="scope">
              <Tag type="warning">{{ state.forums[scope.$index].comments.length }}
                réponse{{ state.forums[scope.$index].comments.length > 1 ? 's' : '' }}
              </Tag>
            </template>
          </el-table-column>
          <el-table-column width="250px" :label="lang.translate(549)">
            <template slot-scope="scope">
              <b class="ForumAdmin--email">{{ getClient(state.forums[scope.$index]) }}</b>
            </template>
          </el-table-column>
          <el-table-column width="150" :label="lang.translate(550)">
            <template slot-scope="scope">
              <Tag v-if="isAnwered(state.forums[scope.$index])" type="success">oui</Tag>
              <Tag v-else type="danger">non</Tag>
            </template>
          </el-table-column>
          <el-table-column min-width="300px" :label="lang.translate(551)">
            <template slot-scope="scope">
              {{ lastComment(state.forums[scope.$index]) }}
            </template>
          </el-table-column>
          <el-table-column
              width="100"
              :label="lang.translate(203)" fixed="right">
            <template slot-scope="scope">
              <Popover position="bottom-end">
                <template slot="default">
                  <List :svg="icon.message" :text="lang.translate(552)" :onClick="() => open(state.forums[scope.$index])"></List>
                  <List :svg="state.forums[scope.$index].active ? icon.archive : icon.unarchive" :text="state.forums[scope.$index].active ? 'cloturer' : 'restaurer'" :onClick="() => toggleActive(state.forums[scope.$index])"></List>
                </template>
                <template slot="reference">
                  <div class="ForumAdmin--option">
                    <Vector :svg="icon.more_horiz"></Vector>
                  </div>
                </template>
              </Popover>
            </template>
          </el-table-column>
        </el-table>
      </div>
      <div class="ForumAdmin--loader" v-show="state.isLoading">
        <Loader></Loader>
      </div>
      <br>
      <br>
      <div class="ForumAdmin--pagination">
        <Pagination :limit="state.filterLimit"
                    :offset="state.filterOffset"
                    :total="state.forumCount"
                    :range="10"
                    :onOffsetChange="v => state.filterOffset = v">
        </Pagination>
      </div>
    </Responsive>
    <ModalConfirm :opened="state.isOpenedConfirm"
                  :onOpenedChange="v => state.isOpenedConfirm = v"
                  :on-validated="() => save()"
                  :loading="state.isLoadingSave">
    </ModalConfirm>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {apiClient} from "../../service/apiClientService";
import selectForum from "../../../@common/api/forum/selectForum";
import createForum from "../../../@common/api/forum/createForum";
import {eventService} from "../../service/eventService";
import {EVENT} from "../../../@common/constant/EVENT";
import {ROLE} from "../../../@common/constant/ROLE";
import {Forum} from "../../../@common/delta/database/Forum";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {FORUM_SEARCH_ID} from "../../../@common/constant/FORUM";
import {sendError} from "../../function/sendError";

export default {

  props: {},
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        more_horiz: require('@bbx/vector~master/core/assets/svg/material/more_horiz.svg'),
        message: require('@bbx/vector~master/core/assets/svg/material/message.svg'),
        archive: require('@bbx/vector~master/core/assets/svg/material/archive.svg'),
        unarchive: require('@bbx/vector~master/core/assets/svg/material/unarchive.svg'),
        search: require('@bbx/vector~master/core/assets/svg/material/search.svg'),
      },
      state: {
        /**
         * @type Forum
         */
        forum: new Forum(),
        /**
         * @type Forum[]
         */
        forums: [],
        /**
         * @type number
         */
        forumCount: 0,
        /**
         * @type boolean
         */
        isOpenedConfirm: false,
        /**
         * @type boolean
         */
        isLoading: false,
        /**
         * @type boolean
         */
        isLoadingSave: false,
        /**
         * @type boolean
         */
        filterActive: true,
        /**
         * @type string
         */
        filterSearch: '',
        /**
         * @type number
         */
        filterOffset: 0,
        /**
         * @type number
         */
        filterLimit: 10,
        /**
         * @type any[]
         */
        searchTypes: [
          {
            id: FORUM_SEARCH_ID.ID,
            value: 'Id'
          },
          {
            id: FORUM_SEARCH_ID.EMAIL,
            value: 'Email Client'
          },
        ],
        /**
         * @type any[]
         */
        selectedSearchTypes: [
          {
            id: FORUM_SEARCH_ID.ID,
            value: 'Id'
          },
        ],
      },
    }
  },
  watch: {
    'state.filterActive': function () {
      this.state.filterOffset = 0
      this.init().catch(sendError)
    },
    'state.filterOffset': function () {
      if (this.state.isLoading === false) {
        this.init().catch(sendError)
      }
    },
    'state.filterLimit': function () {
      this.state.filterOffset = 0
      this.init().catch(sendError)
    },
    'state.filterSearch': function () {
      this.state.filterOffset = 0
      this.init().catch(sendError)
    },
    'state.selectedSearchTypes': function () {
      this.state.filterOffset = 0
      this.init().catch(sendError)
    }
  },
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`ForumAdmin-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`ForumAdmin-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`ForumAdmin-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`ForumAdmin-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`ForumAdmin-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
    this.init().catch(sendError)
  },
  beforeDestroy() {
    eventService.triggerByName(new ListenerTrigger({
      name: EVENT.CHAT_SET_FORUM,
      payload: new Forum()
    }))
  },
  methods: {

    /**
     * Init only on apened
     * @return {Promise<void>}
     */
    async init() {
      if (this.state.isLoading) return
      this.state.isLoading = true
      await Promise.all([
        this.selectForum()
      ])
      this.state.isLoading = false
    },
    /**
     * Get the current forum of the device browser
     * @return {Promise<void>}
     */
    async selectForum() {
      const res = await apiClient.do(selectForum, selectForum.with({
        active: this.state.filterActive,
        $limit: this.state.filterLimit,
        $offset: this.state.filterOffset,
        $search: this.state.filterSearch,
        $searchType: this.state.selectedSearchTypes.map(a => a.id).pop(),
      }))
      this.state.forums = res.data
      this.state.forumCount = res.count
    },

    /**
     * @param {Forum} forum
     */
    open(forum) {
      eventService.triggerByName(new ListenerTrigger({
        name: EVENT.CHAT_SET_FORUM,
        payload: forum
      }))
    },

    /**
     * @param {Forum} forum
     */
    toggleActive(forum) {
      this.state.isOpenedConfirm = true
      this.state.forum = new Forum({
        id: forum.id,
        active: !forum.active
      })
    },

    async save() {
      this.state.isLoadingSave = true
      await apiClient.do(createForum, createForum.with({
        id: this.state.forum.id,
        active: this.state.forum.active
      }))
      this.state.isLoadingSave = false
      this.state.isOpenedConfirm = false
      await this.init()
    },

    /**
     * @param {Forum} forum
     */
    isAnwered(forum) {
      let answered = false

      let lastComment = forum.comments[forum.comments.length - 1]

      if (!lastComment) return answered

      for (const user of lastComment.users) {
        for (const role of user.roles) {
          answered = ![
            ROLE.SELLER,
            ROLE.CUSTOMER
          ].includes(role.id)
        }
      }

      return answered
    },

    /**
     * @param {Forum} forum
     */
    lastComment(forum) {
      let last = forum.comments[forum.comments.length - 1]
      if (!last) return ''
      return last.value > 105 ? `${last.value.slice(0, 105)}...` : last.value
    },

    /**
     * @param {Forum} forum
     */
    getClient(forum) {
      let client = 'Anonyme'

      for (const device of forum.devices) {
        for (const user of device.users) {
          for (const role of user.roles) {
            if ([
              ROLE.SELLER,
              ROLE.CUSTOMER
            ].includes(role.id)) {
              client = user.email
            }
          }
        }
      }

      return client
    },
  }
}
</script>
