<style scoped lang="scss">

.CircleProgress {

}

.CircleProgress--svg {

}

.CircleProgress--progressBarre {
  transform: rotate(-90deg);
  transform-origin: 50% 50%;
  stroke: black;
  fill: transparent;
}

</style>

<template>
  <div class="CircleProgress">
    <svg class="CircleProgress--svg" :style="style_svg">
      <circle
          ref="circle"
          class="CircleProgress--progressBarre"
          :style="style_cercle"
          :r="radius"
          :cx="size / 2"
          :cy="size / 2">
      </circle>
    </svg>
  </div>
</template>

<script>
export default {
  props: {
    size: {
      type: Number,
      default: () => 100
    },
    percentage: {
      type: Number,
      default: () => 75
    },
    barreSize: {
      type: Number,
      default: () => 4
    }
  },
  computed: {
    style_svg() {
      const style = {}
      style.width = `${this.size}px`;
      style.height = `${this.size}px`;
      return style
    },
    style_cercle() {
      const style = {}
      let circumference = this.radius * 2 * Math.PI;
      style.strokeDasharray = `${circumference}, ${circumference}`;
      const offset = circumference - this.percentage / 100 * circumference;
      style.strokeDashoffset = offset;
      style.strokeWidth = `${this.barreSize}px`;
      return style
    },
    radius() {
      return (this.size / 2) - (this.barreSize / 2)
    }
  },
}
</script>