<style scoped>
.InputSelectTerm {
}

.InputSelectTerm ::v-deep .InputLabel {
  padding-bottom: 15px;
}

.InputSelectTerm ::v-deep .InputError {
  padding-top: 10px;
}
</style>

<template>
  <div class="InputSelectTerm" :class="class_root">
    <InputLabel :term="label" :svg="svg" :mandatory="mandatory"/>
    <el-select
        @change="v => setValues(v)"
        :loading="loading"
        :value="max > 1 ? state.values : state.values[0]"
        :value-key="id"
        :filterable="filterable || remotable"
        :filter-method="onFilterChange"
        :remote="remotable"
        :remote-method="onRemoteChange"
        :clearable="isClearable"
        :multiple="max > 1"
        :collapse-tags="collapseTags"
        :multiple-limit="max"
        :disabled="disabled"
        :placeholder="state.placeholder">
      <el-option v-for="item in state.items" :key="item[id]" :value="item" :label="translate(item)">
        <slot :item="{item, translated:translate(item)}"></slot>
      </el-option>
    </el-select>
    <InputError v-if="error.id" :term="error"/>
  </div>
</template>

<script>

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {Term} from "../../../@common/delta/database/Term";
import {cloneOrNot} from "../../../@nodejs/function/cloneOrNot";

export default {
  props: {
    disabled: {
      type: Boolean,
      default: () => false
    },
    cloned: {
      type: Boolean,
      default: () => false
    },
    svg: {
      type: String,
      default: () => ''
    },
    max: {
      type: Number,
      default: () => 1
    },
    collapseTags: {
      type: Boolean,
      default: () => false
    },
    mandatory: {
      type: Boolean,
      default: () => false
    },
    clearable: {
      // type: Boolean,
      // default: () => false
    },
    loading: {
      type: Boolean,
      default: () => false
    },
    id: {
      type: String,
      default: () => 'id'
    },
    error: {
      default: () => new Term()
    },
    label: {
      default: () => new Term()
    },
    termAttribute: {
      type: Function,
      default: (item) => item.terms ? item.terms : []
    },

    // -----

    filterable: {
      type: Boolean,
      default: () => false
    },
    onFilterChange: {
      type: Function,
      default: () => () => {
      }
    },

    // -----

    remotable: {
      type: Boolean,
      default: () => false
    },
    onRemoteChange: {
      type: Function,
      default: () => () => {
      }
    },

    // -----

    values: {
      type: Array,
      default: () => ([])
    },
    onChangeValues: {
      type: Function,
      default: () => () => {
      }
    },
    onChangeValue: {
      type: Function,
      default: () => () => {
      }
    },

    // -----

    items: {
      type: Array,
      default: () => ([])
    },
    onChangeItems: {
      type: Function,
      default: () => () => {
      }
    },
  },
  data() {
    const lang = getLangServiceBrowser()
    return {
      lang,
      props: {},
      state: {
        values: cloneOrNot(this.cloned, this.values),
        placeholder: this.remotable || this.filterable ? lang.translate(299) : lang.translate(134),
        items: cloneOrNot(this.cloned, this.items),
      },
    }
  },
  watch: {
    'values': {
      handler(newValues, oldValues) {
        const newIds = JSON.stringify(newValues.map(newValue => newValue[this.id]))
        const oldIds = JSON.stringify(oldValues.map(oldValue => oldValue[this.id]))
        if (oldIds !== newIds) this.state.values = cloneOrNot(this.cloned, newValues);
      },
      deep: true
    },
    'state.values': {
      handler(value) {
        this.onChangeValues(value)
      },
      deep: true
    },
    'items': {
      handler(newValues, oldValues) {
        const newIds = JSON.stringify(newValues.map(newValue => newValue[this.id]))
        const oldIds = JSON.stringify(oldValues.map(oldValue => oldValue[this.id]))
        if (oldIds !== newIds) this.state.items = cloneOrNot(this.cloned, newValues);
      },
      deep: true
    },
    'state.items': {
      handler(value) {
        this.onChangeItems(value)
      },
      deep: true
    }
  },
  computed: {
    class_root() {
      const classes = [];
      return classes;
    },
    isClearable() {
      if (this.clearable !== undefined) {
        return this.clearable
      }
      return this.max < 2
    },
  },
  methods: {
    translate(item) {
      const terms = this.termAttribute(item)
      const texts = []
      for (let term of terms) {
        texts.push(this.lang.translateTerm(term))
      }
      return texts.join(' ')
    },
    setValues(v) {
      if (!v && this.max < 2) {
        this.state.values = []
      } else if (this.max > 1) {
        this.onChangeValue(v)
        this.state.values = v
      } else {
        this.onChangeValue(v)
        this.state.values = [v]
      }
    }
  }
}
</script>
