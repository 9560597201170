<style scoped>
.FilterSize {
}

.ArticleForm ::v-deep .InputNumber {
  flex: 1;
}

.FilterSize--row {
  display: flex;
}

.FilterSize--inputLabel {
  background: black;
  color: white;
  border-radius: 3px;
  font-family: 'ComfortaaRegular', sans-serif;
  font-size: 12px;
  padding: 2px 4px;
  display: inline-block;
  margin-right: 10px;
  margin: 25px 0;
}

.FilterSize--space {
  width: 30px;
  min-width: 30px;
}
</style>

<template>
  <div class="FilterSize" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <FilterCollapse :title="title"
                      :svg="svg"
                      :opened="opened"
                      :onOpenedChange="onOpenedChange"
                      :withToggle="withToggle"
                      :withIcon="withIcon"
                      :count="count"
                      :onCancel="() => onCancel()">
        <div class="FilterSize--inputLabel">min</div>

        <div class="FilterSize--row">
          <InputNumber :value="state.minSize.length"
                       :onChangeValue="v => state.minSize.length = v"
                       helper="cm"
                       :label="lang.term(540)">
          </InputNumber>
          <div class="FilterSize--space"></div>
          <InputNumber :value="state.minSize.width"
                       helper="cm"
                       :onChangeValue="v => state.minSize.width = v"
                       :label="lang.term(541)">
          </InputNumber>
          <div class="FilterSize--space"></div>
          <InputNumber :value="state.minSize.height"
                       helper="cm"
                       :onChangeValue="v => state.minSize.height = v"
                       :label="lang.term(542)">
          </InputNumber>
        </div>

        <div class="FilterSize--inputLabel">max</div>

        <div class="FilterSize--row">
          <InputNumber :value="state.maxSize.length"
                       :onChangeValue="v => state.maxSize.length = v"
                       helper="cm"
                       :label="lang.term(543)">
          </InputNumber>
          <div class="FilterSize--space"></div>
          <InputNumber :value="state.maxSize.width"
                       helper="cm"
                       :onChangeValue="v => state.maxSize.width = v"
                       :label="lang.term(544)">
          </InputNumber>
          <div class="FilterSize--space"></div>
          <InputNumber :value="state.maxSize.height"
                       helper="cm"
                       :onChangeValue="v => state.maxSize.height = v"
                       :label="lang.term(545)">
          </InputNumber>
        </div>

      </FilterCollapse>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {Term} from "../../../@common/delta/database/Term";
import {PICTURE_DISPLAY} from "../../../@common/constant/PICTURE_DISPLAY";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {Size} from "../../../@common/delta/database/Size";
import {Debounce} from "../../../@common/ts/Debounce";

let debounce = new Debounce()

export default {

  props: {

    // -----
    // default
    // -----

    minSize: {
      default: () => new Size()
    },

    maxSize: {
      default: () => new Size()
    },
    onChange: {
      type: Function,
      default: () => () => {
      }
    },

    // -----
    // extends
    // -----

    title: {
      default: () => new Term()
    },
    svg: {
      type: String,
      default: () => ''
    },
    opened: {
      type: Boolean,
      default: () => false
    },
    onOpenedChange: {
      type: Function,
      default: () => () => {
      }
    },
    withToggle: {
      type: Boolean,
      default: () => true
    },
    withIcon: {
      type: Boolean,
      default: () => true
    },
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      PICTURE_DISPLAY,
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {
        opened: this.opened,
        minSize: new Size(this.minSize),
        maxSize: new Size(this.maxSize),
      },
    }
  },
  watch: {
    'opened': {
      handler(v) {
        this.state.opened = v
      },
      deep: true
    },
    'state.opened': {
      handler(v) {
        this.onOpenedChange(v)
      },
      deep: true
    },
    'minSize': {
      handler(v) {
        this.state.minSize = new Size(v)
      },
      deep: true
    },
    'state.minSize': {
      handler(v) {
        debounce.callback({
          time: 1000,
          job: async () => {
            this.onChange({
              minSize: new Size(this.state.minSize),
              maxSize: new Size(this.state.maxSize)
            })
          },
          success: res => {
          },
          error: err => {
          }
        })
      },
      deep: true
    },
    'maxSize': {
      handler(v) {
        this.state.maxSize = new Size(v)
      },
      deep: true
    },
    'state.maxSize': {
      handler(v) {
        debounce.callback({
          time: 1000,
          job: async () => {
            this.onChange({
              minSize: new Size(this.state.minSize),
              maxSize: new Size(this.state.maxSize)
            })
          },
          success: res => {
          },
          error: err => {
          }
        })
      },
      deep: true
    },
  },
  computed: {
    class_root() {
      const classes = []
      return classes;
    },
    count() {
      let count = 0
      if (this.state.minSize.length) count++
      if (this.state.minSize.width) count++
      if (this.state.minSize.height) count++
      if (this.state.maxSize.length) count++
      if (this.state.maxSize.width) count++
      if (this.state.maxSize.height) count++
      return count
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    onCancel() {
      this.state.minSize = new Size()
      this.state.maxSize = new Size()
    }
  }
}
</script>
