<style scoped>

</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="content-wrapper none for-title">
      <div class="container_12">
        <div class="main-container main-main-container col1-layout">
          <div class="grid_12 col-main">
            <div class="page-title">
              <h1>Mobilier de jardin aluminium : les avantages - Les Jardins Vente Privée</h1>
            </div>
            <div class="std">
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Tout mobilier de jardin a pour vocation l’aménagement et la décoration
                  d’un espace dédié à la détente et agréable à vivre. Le design, l’ergonomie, la résistance aux
                  contraintes climatiques et la longévité sont des critères primordiaux pour le choix du bon modèle et
                  du matériau. Un
                  <b>mobilier de jardin en aluminium</b>
                  présente de nombreux avantages. En effet, ce matériau est à la fois léger, solide et s’entretient
                  facilement. Découvrez nos 4 bonnes raisons de choisir un salon de jardin en aluminium.
                </span>
              </p>
              <h2 style="text-align: justify;">Un style moderne et intemporel
                <img style="float: right; padding-left: 20px;" alt="" src="./media/wysiwyg/img14.jpg">
              </h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">L’
                  <b>aluminium</b>
                  , qu’il soit poli, brossé ou structuré, permet des
                  <b>formes originales et contemporaines</b>
                  qui offrent généralement de bons supports pour la lumière naturelle et qui décorent avec élégance les
                  terrasses et jardins. L’aluminium est souvent associé à d’autres matières comme le verre ou encore la
                  résine tressée.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Les chaises en aluminium sont souvent très confortables et faciles à
                  empiler pour un rangement optimal. D’une manière générale la résine de synthèse et le polyester sont
                  associés à l’aluminium pour l’assise ou le revêtement.
                </span>
              </p>
              <h2 style="text-align: justify;">Une ergonomie sobre ou futuriste</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Des simples pieds de meubles aux formes complexes, l’aluminium se
                  travaille facilement et ouvre de nouveaux horizons à la conception en offrant souvent des coloris
                  ultras tendance (blanc, gris anthracite, taupe, noir…) pour un
                  <b>agencement personnalisé</b>
                  .
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Ce type de mobilier s’adaptera à merveille aux nouvelles constructions à
                  base d’inox et proposera une extension naturelle de la maison dans le jardin. Créez un espace
                  convivial et agréable, sur votre terrasse en bois, au bord de la piscine ou dans la pelouse, avec des
                  fauteuils et une table basse. Vous pourrez compléter l’ensemble avec des coussins de couleur ou à
                  motif pour une touche de déco.
                </span>
              </p>
              <h2 style="text-align: justify;">Légèreté et résistance</h2>

              <p style="text-align: justify;">
                <span style="font-size: large;">L’aluminium est un
                  <b>métal léger</b>
                  permettant de réaliser des
                  tables et chaises
                  faciles à déplacer, stables et épurées. Une table de jardin extensible est idéale pour pouvoir
                  accueillir, à l’occasion, une grande tablée pour un repas à l’ombre du parasol. Ces modèles proposent
                  des éléments de rallonge à installer ou déposer sur le plateau en quelques secondes pour offrir de la
                  modularité au niveau des dimensions. Le
                  <b>côté pratique</b>
                  de votre mobilier de jardin en aluminium est un élément essentiel pour profiter de votre espace
                  extérieur sans perdre de place. Les salons en alu peuvent durer jusqu’à 10 ans là où le fer forgé et
                  l’acier vont s’oxyder plus rapidement.
                </span>
              </p>
              <h2 style="text-align: justify;">Des prix pour tous les budgets</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">La grande
                  <b>diversité de l’offre</b>
                  permet à chacun de trouver son ensemble de jardin ou son équipement de terrasse en rapport avec son
                  budget. Ce mobilier se retrouve à tous les niveaux de la distribution, en grande surface comme chez
                  les commerçants de détail, le prix varie suivant la structure. Les éléments tubulaires sont moins
                  chers que ceux moulés d’une seule pièce.
                </span>
              </p>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../../function/getH1";
import {Term} from "../../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../../@common/function/helperUrl";

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    },
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FAQ-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
