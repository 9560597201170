<style scoped>

</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="content-wrapper none for-title">
      <div class="container_12">
        <div class="main-container main-main-container col1-layout">
          <div class="grid_12 col-main">
            <div class="page-title">
              <h1>Terrasse couverte : comment l’aménager ?</h1>
            </div>
            <div class="std">
              <p style="text-align: justify;">
                <img alt="" src="./media/wysiwyg/bandeau-terrasse-couverte.jpg">
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Une terrasse couverte est une solution esthétique, qui vous permet de
                  profiter plus longtemps de votre extérieur, en vous mettant à l’abri de la pluie ou du soleil aux
                  heures les plus chaudes.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Différents modèles existent, ils sont amovibles, polyvalents ou
                  définitifs. Mais quels sont les avantages et les inconvénients de ces différentes installations&nbsp;?
                  Nous vous proposons de découvrir plusieurs d’entre elles.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Structure définitive</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Renseignez-vous au préalable auprès de votre mairie pour savoir si vous
                  devez faire une
                  <b>déclaration de travaux</b>
                  pour la
                  <b>construction</b>
                  d’une structure définitive, car la réglementation varie en fonction des dimensions de celle-ci
                  (hauteur et largeur), mais aussi de votre lieu d’habitation. Si la
                  <b>couverture</b>
                  fixe modifie l’aspect de votre maison, une déclaration sera certainement demandée. Une terrasse
                  couverte dont la
                  <b>surface</b>
                  excède 20 mètres carrés nécessitera, elle, la demande d’un permis de construire.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">L’inconvénient majeur de ces structures définitives est que, si elles
                  vous protègent du soleil et de la chaleur en été, elles peuvent vous priver de luminosité en hiver.
                </span>
              </p>
              <h3 style="text-align: justify;">Structure maçonnée</h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">Cet aménagement s’adresse aux personnes désirant une structure étanche,
                  pour profiter du confort intérieur à l’extérieur : cuisine d’été entièrement équipée, télévision,
                  installation hi-fi, coin lecture avec un poêle de chauffage, etc. Demandant de vrais
                  <b>travaux</b>
                  , une structure maçonnée est coûteuse, mais sera plus pérenne dans le temps. Installée dans le
                  prolongement du
                  <b>toit</b>
                  de votre habitation, cette nouvelle pièce sera en harmonie avec le reste de votre maison et vous
                  permettra de gagner de l’espace.
                </span>
              </p>
              <h3 style="text-align: justify;">Auvent</h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">Accolée à l’habitation, cette
                  <b>structure</b>
                  non fermée permet de circuler plus librement et de garder votre panorama sur le jardin ou la piscine.
                  Sa charpente est souvent réalisée à partir de bois, de fer, de PVC ou d’aluminium. Un grand choix
                  s’offre à vous concernant la
                  <b>toiture</b>&nbsp;de votre auvent : verre ou matière plastique laissant passer la lumière, tuiles
                  identiques à celles de votre habitation, voile en tissu, etc.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Choisir la polyvalence</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Les structures polyvalentes vous permettent de jouer avec l’ombre et la
                  lumière, elles s’adapteront donc à vos besoins et au changement de saison. Adossée à votre habitation,
                  elle permettra à votre intérieur de rester au frais en été, tout en laissant le soleil le réchauffer
                  en hiver.
                </span>
              </p>
              <h3 style="text-align: justify;">Pergola</h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">La pergola s’adapte à toutes les configurations et on en trouve de tous
                  les styles. Sa structure en
                  <b>bois</b>
                  ou en métal en fait une solution durable. La toiture est constituée de poutres ou de lames laissant
                  filtrer la lumière tout en vous offrant de l’ombre. Elles peuvent être laissées nues ou être couvertes
                  d’une toile de couleur, par exemple. Il existe des modèles de
                  <b>pergolas</b>
                  bioclimatiques dont les lames sont orientables. Vous pouvez donc régler le degré de luminosité selon
                  vos besoins et vous protéger en cas de pluie.
                </span>
              </p>
              <h3 style="text-align: justify;">Vélarium</h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">Un vélum, aussi appelé vélarium, est une grande surface de
                  <b>toile suspendue</b>
                  horizontalement à une fine structure métallique, souvent fixée à la façade de votre maison ou
                  accrochée à des piliers indépendants. Selon les modèles, il est possible de faire glisser le tissu sur
                  des câbles tendus afin de créer de l’ombre ou de se protéger d’une pluie fine.
                </span>
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Vous pouvez facilement décrocher la toile afin de la laver ou de la
                  mettre à l’abri pour l’hiver. Grâce à ce système, vous avez la possibilité de changer de toile si
                  celle-ci est trop abîmée ou si vous désirez changer le style de votre vélarium.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Adopter une solution amovible</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">L’avantage des structures amovibles est qu’elles sont faciles à
                  installer et qu’elles peuvent vous servir d’abri dès que vous en avez besoin. Si vous optez pour cette
                  solution, investissez dans du mobilier de jardin résistant aux intempéries, comme une
                  table en
                  aluminium
                  , des chaises en teck ou un salon de jardin en résine tressée.
                </span>
              </p>
              <h3 style="text-align: justify;">Tonnelle</h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">Cette toile souple maintenue sur une charpente, en métal ou en PVC, est
                  facile à installer et se démonte aisément. Elle s’adapte aux caprices du climat&nbsp;: elle peut être
                  ouverte, s’il y a du soleil, partiellement fermée, pour s’
                  <b>abriter</b>
                  du vent ou totalement fermée en cas de pluie, par exemple. Elle est très appréciée lors des évènements
                  en plein air, comme des mariages, par exemple.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Oubliez la toile en plastique blanc tendu entre quatre piquets, il
                  existe maintenant des tonnelles modernes qui s’intègrent parfaitement dans une décoration
                  contemporaine. En plus de vous protéger des intempéries, elles vous offriront un peu d’intimité sur
                  votre
                  <b>terrasse</b>
                  et vous permettront de créer un coin cosy et chaleureux à l’abri des regards.
                </span>
              </p>
              <h3 style="text-align: justify;">Store banne</h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">Adossé à votre façade, le store banne est modulable, il vous permet de
                  jouer avec l’ombre et la lumière du soleil. Il se déroule, avec une grande facilité, à l’aide d’une
                  manivelle ou automatiquement, grâce à un petit moteur. Les nombreux modèles
                  <b>designs</b>
                  et modernes que l’on trouve aujourd’hui devraient permettre au store banne de perdre son image
                  vieillotte et de retrouver sa place sur nos terrasses.
                </span>
              </p>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../../function/getH1";
import {Term} from "../../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../../@common/constant/PROJECT_URL";
import {PATHS} from "../../../../@common/constant/PATHS";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    },
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FAQ-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
