"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.pageConfigPublic = void 0;
var ENV_1 = require("../../core/@common/constant/ENV");
var config_1 = require("../config");
var pageConfigPublic;
exports.pageConfigPublic = pageConfigPublic;
if (config_1.config.env === ENV_1.ENV.PRODUCTION) {
    exports.pageConfigPublic = pageConfigPublic = {
        scopeWindowId: '99bc9f93-22d7-43e6-ac4e-d86b5e72b66c',
        ftpPath: 'page',
        url: 'https://api.lesjardins-int.com',
    };
}
