"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SHOP_SEARCH_ID = exports.SHOP_TYPE_LIST = void 0;
exports.SHOP_TYPE_LIST = {
    MOBILIER: 1,
    LUMINAIRE: 2,
};
exports.SHOP_SEARCH_ID = {
    ID: 1,
    SAGE_UUID: 2,
    NAME: 3,
};
