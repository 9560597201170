<style scoped>
.PurchaseState {
}

.PurchaseState--navs {
  display: flex;
  background: rgba(0, 0, 0, 0.03);
  justify-content: center;
  margin-bottom: 100px;
}

.PurchaseState-desktop .PurchaseState--navs {
  padding-right: 500px;
}

.PurchaseState-tablet .PurchaseState--navs {
}

.PurchaseState-mobile .PurchaseState--navs {
}

.PurchaseState--navContent {
  justify-content: center;
  display: flex;
}

.PurchaseState-desktop .PurchaseState--navContent {
  width: 472px;
}

.PurchaseState-tablet .PurchaseState--navContent {
  width: 472px;
}

.PurchaseState-mobile .PurchaseState--navContent {
  width: 70%;
}

.PurchaseState--nav {
  border-bottom: solid 1px rgba(0, 0, 0, 0.15);
  position: relative;
  display: flex;
}

.PurchaseState-desktop .PurchaseState--nav {
  padding: 50px 0 50px 0;
}

.PurchaseState-tablet .PurchaseState--nav {
  padding: 50px 0 50px 0;
}

.PurchaseState-mobile .PurchaseState--nav {
  padding: 25px 0 25px 0;
}

.PurchaseState--nav-done {
  cursor: pointer;
  border-bottom: solid 1px rgba(0, 0, 0, 1);
}

.PurchaseState--nav-current {
  border-bottom: solid 1px rgba(0, 0, 0, 1);
}

.PurchaseState--nav-left {
  width: 25%;
  justify-content: flex-start;
}

.PurchaseState--nav-left .PurchaseState--icon {
  position: relative;
  left: -12px;
}

.PurchaseState--nav-center {
  width: 50%;
  justify-content: center;
}

.PurchaseState--nav-right {
  width: 25%;
  justify-content: flex-end;
}

.PurchaseState--nav-right .PurchaseState--icon {
  position: relative;
  left: 12px;
}

.PurchaseState--icon {
  width: 24px;
  height: 24px;
}

.PurchaseState--icon ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 0.25);
}

.PurchaseState--nav-done .PurchaseState--icon ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 1);
}

.PurchaseState--nav-current .PurchaseState--icon ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 1);
}

.PurchaseState--bubble {
  width: 2px;
  height: 2px;
  border: solid 4px rgba(0, 0, 0, 1);
  background-color: white;
  border-radius: 10px;
  position: absolute;
  bottom: -5px;
  display: none;
}

.PurchaseState--nav-done .PurchaseState--bubble {
  display: block;
}

.PurchaseState--nav-current .PurchaseState--bubble {
  display: block;
}

.PurchaseState--nav-left .PurchaseState--bubble {
  left: -5px;
}

.PurchaseState--nav-center .PurchaseState--bubble {
  left: 50%;
  margin-left: -5px;
}

.PurchaseState--nav-right .PurchaseState--bubble {
  right: -5px;
}

.PurchaseState--navState {
  height: 100px;
  position: absolute;
  bottom: -100px;
  display: flex;
  justify-content: center;
  font-family: 'RalewayBold', sans-serif;
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  color: rgba(0, 0, 0, 0.25);
  align-items: center;
}

.PurchaseState--nav-done .PurchaseState--navState {
  color: rgba(0, 0, 0, 1);
}

.PurchaseState--nav-current .PurchaseState--navState {
  color: rgba(0, 0, 0, 1);
}

.PurchaseState--nav-left .PurchaseState--navState {
  left: -50%;
  width: 100%;
}

.PurchaseState--nav-center .PurchaseState--navState {
  left: 0;
  width: 100%;
}

.PurchaseState--nav-right .PurchaseState--navState {
  right: -50%;
  width: 100%;
}

.PurchaseState--navStateText {

}

.PurchaseState--navStateIcon {
  width: 24px;
  padding-left: 10px;
  display: none;
}

.PurchaseState--nav-done .PurchaseState--navStateIcon {
  display: block;
}

.PurchaseState--navStateIcon ::v-deep .Vector svg {
  fill: #6DB93A;
}

.PurchaseState-desktop .PurchaseState--navStateIcon {

}

.PurchaseState-tablet .PurchaseState--navStateIcon {
}

.PurchaseState-mobile .PurchaseState--navStateIcon {
  display: none;
}
</style>

<template>
  <div class="PurchaseState" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="PurchaseState--navs">
        <div class="PurchaseState--navContent">
          <div class="PurchaseState--nav PurchaseState--nav-left" :class="class_nav(PATHS.BASKET)" @click="() => _onClickIcon(PATHS.BASKET)">
            <div class="PurchaseState--icon">
              <Vector :svg="icon.shopping_basket"></Vector>
            </div>
            <div class="PurchaseState--bubble"></div>
            <div class="PurchaseState--navState">
              <div class="PurchaseState--navStateText">
                <Term :term="lang.term(391)"/>
              </div>
              <div class="PurchaseState--navStateIcon">
                <Vector :svg="icon.done"></Vector>
              </div>
            </div>
          </div>
          <div class="PurchaseState--nav PurchaseState--nav-center" :class="class_nav(PATHS.DELIVERY)" @click="() => _onClickIcon(PATHS.DELIVERY)">
            <div class="PurchaseState--icon">
              <Vector :svg="icon.local_shipping"></Vector>
            </div>
            <div class="PurchaseState--bubble"></div>
            <div class="PurchaseState--navState">
              <div class="PurchaseState--navStateText">
                <Term :term="lang.term(392)"/>
              </div>
              <div class="PurchaseState--navStateIcon">
                <Vector :svg="icon.done"></Vector>
              </div>
            </div>
          </div>
          <div class="PurchaseState--nav PurchaseState--nav-right" :class="class_nav(PATHS.PAYMENT)" @click="() => _onClickIcon(PATHS.PAYMENT)">
            <div class="PurchaseState--icon">
              <Vector :svg="icon.credit_card"></Vector>
            </div>
            <div class="PurchaseState--bubble"></div>
            <div class="PurchaseState--navState">
              <div class="PurchaseState--navStateText">
                <Term :term="lang.term(393)"/>
              </div>
              <div class="PurchaseState--navStateIcon">
                <Vector :svg="icon.done"></Vector>
              </div>
            </div>
          </div>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {PATHS} from "../../../@common/constant/PATHS";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

export default {

  props: {
    onClickIcon: {
      type: Function,
      default: () => (pathId) => {
      }
    },
    currentPathIds: {
      type: Array,
      default: () => ([])
    },
    donePathIds: {
      type: Array,
      default: () => ([])
    }
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      PATHS,
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        local_shipping: require('@bbx/vector~master/core/assets/svg/material/local_shipping.svg'),
        shopping_basket: require('@bbx/vector~master/core/assets/svg/material/shopping_basket.svg'),
        credit_card: require('@bbx/vector~master/core/assets/svg/material/credit_card.svg'),
        done: require('@bbx/vector~master/core/assets/svg/material/done.svg'),
      },
      state: {},
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`PurchaseState-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`PurchaseState-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`PurchaseState-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`PurchaseState-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`PurchaseState-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    class_nav(pathId) {
      const classes = []
      if (this.donePathIds.includes(pathId)) classes.push('PurchaseState--nav-done')
      if (this.currentPathIds.includes(pathId)) classes.push('PurchaseState--nav-current')
      return classes
    },
    /**
     * @param {number} pathId
     * @private
     */
    _onClickIcon(pathId) {
      if (!this.donePathIds.includes(pathId)) return
      this.onClickIcon(pathId)
    }
  }
}
</script>
