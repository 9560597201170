<style scoped>
.Modal {
  position: fixed;
  top: 0;
  left: 0;
  height: 100vh;
  width: 100vw;
  overflow: hidden;
  display: none;
}

.Modal-opened {
  display: block;
}

.Modal--mask {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  transition: opacity 300ms;
  cursor: pointer;
  opacity: 0;
}

.Modal-opening .Modal--mask {
  opacity: 1;
}

.Modal--body {
  position: absolute;
  background: rgba(255, 255, 255, 1);
  transition: transform 300ms, opacity 300ms;
  overflow-y: scroll;
  overflow-x: hidden;
}

/* ----------------------------------- */

.Modal-desktop.Modal-position-right .Modal--body {
  top: 0;
  right: 0;
  transform: translate(100%, 0%);
  width: 40%;
  height: 100%;
}

.Modal-tablet.Modal-position-right .Modal--body {
  top: 0;
  right: 0;
  transform: translate(100%, 0%);
  width: 70%;
  height: 100%;
}

.Modal-mobile.Modal-position-right .Modal--body {
  Bottom: 0;
  left: 0;
  transform: translate(0%, 100%);
  width: 100%;
  height: calc(100% - 90px);
  border-radius: 8px 8px 0 0;
}

/* ----------------------------------- */

.Modal-desktop.Modal-position-left .Modal--body {
  top: 0;
  left: 0;
  transform: translate(-100%, 0%);
  width: 40%;
  height: 100%;
}

.Modal-tablet.Modal-position-left .Modal--body {
  top: 0;
  left: 0;
  transform: translate(-100%, 0%);
  width: 70%;
  height: 100%;
}

.Modal-mobile.Modal-position-left .Modal--body {
  Bottom: 0;
  left: 0;
  transform: translate(0%, 100%);
  width: 100%;
  height: calc(100% - 90px);
  border-radius: 8px 8px 0 0;
}

/* ----------------------------------- */

.Modal-desktop.Modal-position-bottom .Modal--body {
  Bottom: 0;
  left: 0;
  transform: translate(0%, 100%);
  width: 100%;
  height: 40%;
}

.Modal-tablet.Modal-position-bottom .Modal--body {
  Bottom: 0;
  left: 0;
  transform: translate(0%, 100%);
  width: 100%;
  height: 70%;
}

.Modal-mobile.Modal-position-bottom .Modal--body {
  Bottom: 0;
  left: 0;
  transform: translate(0%, 100%);
  width: 100%;
  height: calc(100% - 90px);
  border-radius: 8px 8px 0 0;
}

/* ----------------------------------- */

.Modal-desktop.Modal-position-top .Modal--body {
  top: 0;
  left: 0;
  transform: translate(0%, -100%);
  width: 100%;
  height: 40%;
}

.Modal-tablet.Modal-position-top .Modal--body {
  top: 0;
  left: 0;
  transform: translate(0%, -100%);
  width: 100%;
  height: 70%;
}

.Modal-mobile.Modal-position-top .Modal--body {
  Bottom: 0;
  left: 0;
  transform: translate(0%, 100%);
  width: 100%;
  height: calc(100% - 90px);
  border-radius: 8px 8px 0 0;
}

/* ----------------------------------- */

.Modal-desktop.Modal-opening .Modal--body {
  transform: translate(0%, 0%);
}

.Modal-tablet.Modal-opening .Modal--body {
  transform: translate(0%, 0%);
}

.Modal-mobile.Modal-opening .Modal--body {
  transform: translate(0%, 0%);
}
</style>

<template>
  <div class="Modal App--zindex" :class="class_root" :id="state.idRoot">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="onBreakPointChange"
                :onVerticalBreakPointChange="onVerticalBreakPointChange">
      <div class="Modal--mask" @click="() => close()"></div>
      <div class="Modal--body">
        <slot/>
      </div>
    </Responsive>
  </div>
</template>

<script>


import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {EVENT} from "../../../@common/constant/EVENT";
import {eventService} from "../../service/eventService";
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger.ts";
import {waitElement} from "../../../@common/function/waitElement";
import {onlyJSON} from "../../../@common/function/onlyJSON";
import Id from "@bbx/id~master/core/ts/Id";
import {sendError} from "../../function/sendError";

const id = new Id()

export default {

  props: {
    opened: {
      type: Boolean,
      default: () => false
    },
    onOpenedChange: {
      type: Function,
      default: () => (opened) => {
      }
    },
    position: {
      type: String,
      default: () => 'right'
    },
  },
  data() {
    return onlyJSON({
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
          },
        },
      },
      state: {
        idRoot: `_${id.uniq()}`,
        setTimeOutOpen: null,
        setTimeOutClose: null,
        position: this.position,
        opened: this.opened,
        opening: false,
        parent: null,
        ready: false,
      },
    })
  },
  async fetch() {
    this.state.idRoot = `_${id.uniq()}`
  },
  watch: {
    'opened': function (v) {
      v ? this.open() : this.close()
    },
    'state.opened': function (v) {
      this.onOpenedChange(v);
    }
  }
  ,
  computed: {
    class_root() {
      const classes = []
      if (this.state.position === 'right') classes.push('Modal-position-right')
      if (this.state.position === 'left') classes.push('Modal-position-left')
      if (this.state.position === 'bottom') classes.push('Modal-position-bottom')
      if (this.state.opened) classes.push('Modal-opened')
      if (this.state.opening) classes.push('Modal-opening')
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`Modal-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`Modal-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`Modal-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`Modal-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`Modal-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
    waitElement(async () => document.getElementById(this.state.idRoot)).then(modal => {
      this.state.parent = modal.parentNode
      this.state.ready = true;
      this.opened ? this.open() : this.close()
    }).catch(err => {
      sendError(err);
    });
  },
  beforeDestroy() {
    this.close()
  },
  methods: {
    open() {
      eventService.triggerByName(new ListenerTrigger({
        name: EVENT.DISPLAY_SCROLLBAR,
        payload: false
      }))
      if (!this.state.ready) return
      if (this.setTimeOutOpen) clearTimeout(this.setTimeOutOpen)
      this.state.opened = true
      waitElement(async () => document.getElementById(this.state.idRoot)).then(modal => {
        document.body.appendChild(modal)
        eventService.triggerByName(new ListenerTrigger({
          name: EVENT.MODAL_OPENED
        }))
        setTimeout(() => {
          this.state.opening = this.state.opened
        })
      }).catch(err => {
        sendError(err);
      });
    },
    onBreakPointChange(v) {
      this.props.Responsive['0'].breakPoint = v
    },
    onVerticalBreakPointChange(v) {
      this.props.Responsive['0'].verticalBreakPoint = v
    },
    close() {
      eventService.triggerByName(new ListenerTrigger({
        name: EVENT.DISPLAY_SCROLLBAR,
        payload: true
      }))
      const id = this.state.id
      if (!this.state.ready) return
      if (this.setTimeOutClose) clearTimeout(this.setTimeOutClose)
      this.state.opening = false
      this.setTimeOutClose = setTimeout(() => {
        this.state.opened = this.state.opening
        waitElement(async () => document.getElementById(this.state.idRoot)).then(modal => {
          try {
            this.state.parent.appendChild(modal)
          } catch (e) {
            let el = document.querySelector(`#${id}`)
            if (el) el.remove()
          }
        }).catch(err => {
          sendError(err);
        });
      }, 300)
    }
  }
}
</script>
