<style scoped>

</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="content-wrapper none for-title">
      <div class="container_12">
        <div class="main-container main-main-container col1-layout">
          <div class="grid_12 col-main">
            <div class="page-title">
              <h1>Mobilier de jardin en résine tressée : entretien et rénovation</h1>
            </div>
            <div class="std">
              <p style="text-align: justify;">
                <img alt="" src="./media/wysiwyg/bandeau-resine-tressee.jpg">
              </p>
              <p>&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Nos salons de jardin sont soumis à rude épreuve. Ils doivent faire face
                  aux intempéries et au changement de saison. Avec le temps, les
                  <b>meubles en résine tressée</b>
                  peuvent ternir ou s’abîmer. Découvrez comment faire pour leur redonner un aspect neuf.
                </span>&nbsp;
              </p>
              <h2 style="text-align: justify;">Entretien du mobilier de jardin en résine tressée</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Un bon entretien suffit généralement pour rénover un salon de jardin en
                  résine et le rafraîchir. Un traitement adapté permettra d’en préserver l’éclat.
                </span>
              </p>
              <h3 style="text-align: justify;">
                Nettoyer la résine
              </h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">Utiliser le bon
                  <b>produit</b>
                  est important. En effet, la résine étant un matériau synthétique, elle ne supporte pas les détergents
                  et les produits chimiques, comme l’eau de javel, l’anticalcaire et les solvants, qui sont corrosifs et
                  la rayent. Les nettoyeurs à haute pression sont eux aussi fortement déconseillés.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Pour nettoyer de la
                  <b>résine tressée</b>
                  , il suffit d’un peu d’eau savonneuse (le liquide vaisselle fait très bien l’affaire). Les éponges
                  abrasives ou en fer sont à proscrire, car elles abîmeront la surface de votre mobilier. Privilégiez
                  une éponge ou un
                  <b>chiffon imbibé</b>
                  le plus doux possible. Face à des tâches coriaces, utilisez de l’argile ou du vinaigre blanc dilué
                  dans un peu d’eau et frottez délicatement. Il est indispensable de bien rincer le mobilier après
                  chaque nettoyage et de le sécher.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Pour garder votre mobilier en résine tressée de nombreuses années,
                  couvrez-le avec une housse de protection pendant l’hiver ou lorsque vous ne l’utilisez pas.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <h3 style="text-align: justify;">
                Cirer son mobilier
              </h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">Comme pour le bois, appliquer une cire protectrice, en début et en fin
                  de saison, permet à vos
                  <b>meubles de jardin en résine tressée</b>
                  (table, chaises,fauteuils…)de retrouver leur brillance. La cire a également un effet protecteur, car elle préserve des UV, de
                  l’humidité et de la pluie. Elle peut rallonger la
                  <b>durée de vie</b>
                  de vos meubles grâce à un effet rénovateur et préventif.&nbsp;
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h3 style="text-align: justify;">
                Laver les coussins
              </h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">Les
                  <b>coussins</b>
                  de votre salon de jardin peuvent être lavés, afin d’en raviver les couleurs, à condition de suivre les
                  instructions inscrites sur l’étiquette.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Même s’ils sont imperméables, pensez à les protéger de l’humidité avec
                  une housse.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">
                  <img alt="" src="./media/wysiwyg/salonresinetressee.jpg">
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Repeindre ses meubles en résine tressée</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Si l’entretien ne suffit pas à rafraîchir votre mobilier de jardin, vous
                  pouvez également le
                  <b>repeindre</b>
                  . Plusieurs choix s’offrent alors à vous&nbsp;: retrouverez-vous la teinte d’origine, ou en
                  profiterez-vous pour donner un peu de
                  <b>couleur</b>
                  à votre extérieur dans un esprit cosy et tendance&nbsp;?
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Commencez par nettoyer votre meuble comme expliqué ci-dessus, puis
                  passez une sous-couche de résine pour permettre une meilleure adhérence de la peinture. Il vous faudra
                  choisir des peintures adaptées à la résine tressée pour optimiser l’adhérence du produit.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Choisissez une
                  <b>peinture</b>
                  pour plastique extérieur, qui résistera aux UV et aux intempéries. Préférez-la en bombe pour un rendu
                  plus lisse. Si vous la choisissez en pot, munissez-vous d’un pinceau, qui vous permettra d’atteindre
                  le moindre recoin, et d’un rouleau, pour lisser la surface.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Réparer des bandes cassées</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Si vous désirez réaliser des économies et privilégiez la dimension
                  fonctionnelle à l’esthétique, vous pouvez remplacer des bandes de résine tressée cassées. Vous
                  trouverez en magasin des rouleaux de résine tressée utilisés pour fabriquer des brise-vues.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Retirez la bande de résine cassée et remplacez-la par une nouvelle, en
                  prenant soin de suivre le tressage. Coupez le surplus proprement et fixez la bande avec un point de
                  colle.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Si vous ne trouvez pas de rouleau de résine de la même couleur que celle
                  de votre mobilier, il vous sera alors indispensable de le repeindre pour rendre le tout homogène.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Les
                  <b>salons de jardin en résine</b>
                  ont de plus en plus de succès et les prix se démocratisent. Nous vous conseillons de privilégier un
                  modèle moyen ou haut de gamme pour plusieurs raisons. Vous gagnerez déjà en niveau de finition, en
                  esthétique, et en confort. Enfin, un meuble de qualité supérieure sera plus résistant et vous pourrez
                  donc le conserver de nombreuses saisons. Et s’il venait à s’abîmer, vous savez maintenant comment le
                  rénover.
                </span>
              </p>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../../function/getH1";
import {Term} from "../../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../../@common/function/helperUrl";

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    },
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FAQ-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
