<style scoped>
.AdminMenu {
}

.AdminMenu--page {
  width: 100%;
  height: 100%;
  position: relative;
}

.AdminMenu--header {
  position: absolute;
  top: 0;
  left: 0;
  height: 100vh;
  display: flex;
  background: black;
  overflow: auto;
  box-sizing: border-box;
  transform: translateX(-250px);
  transition: transform 300ms;
}

.AdminMenu-menuIsHovered .AdminMenu--header {
  transform: translateX(0px);
}

.AdminMenu--title {
  width: 100%;
  height: 75px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.AdminMenu--title ::v-deep .Vector {
  width: 100%;
  height: auto;
}

.AdminMenu--title ::v-deep .Link {
  display: block;
  width: 60%;
}

.AdminMenu--links {
  width: 250px;
  min-width: 250px;
  height: 100%;
  /*transform: translateY(-46px);*/
  /*transition: transform 300ms;*/
}

.AdminMenu-menuIsHovered .AdminMenu--links {
  /*transform: translateY(0px);*/
}

.AdminMenu--links ::v-deep .Link {
  display: block;
}

.AdminMenu--link {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  position: relative;
  width: calc(100% + 50px);
  background: rgba(255, 255, 255, 0);
}

.AdminMenu--link:hover {
  background: rgba(255, 255, 255, 0.2);
}

.AdminMenu--link-selected.AdminMenu--link {
  background: rgba(255, 255, 255, 0.2);
}

.AdminMenu--term ::v-deep .Term {
  color: rgba(255, 255, 255, 0.5);
  padding-right: 50px;
  font-size: 12px;
  font-family: 'RalewayMedium', sans-serif;
  letter-spacing: 1px;
}

.AdminMenu--link:hover .AdminMenu--term ::v-deep .Term {
  color: rgba(255, 255, 255, 1);
}

.AdminMenu--link-selected .AdminMenu--term ::v-deep .Term {
  color: rgba(255, 255, 255, 1);
}

.AdminMenu--vector {
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  transition: border 300ms;
  border-left: solid 1px rgba(0, 0, 0, 0);
  border-right: solid 1px rgba(0, 0, 0, 0);
}

.AdminMenu--link:hover .AdminMenu--vector {
  border-left: solid 1px white;
}

.AdminMenu-menuIsHovered .AdminMenu--link:hover .AdminMenu--vector {
  border-right: solid 1px black;
}

.AdminMenu--link-selected .AdminMenu--vector {
  border-left: solid 1px white;
}

.AdminMenu-menuIsHovered .AdminMenu--link-selected .AdminMenu--vector {
  border-right: solid 1px black;
}

.AdminMenu--vector ::v-deep .Vector {
  opacity: 0.4;
  width: 18px;
  height: 18px;
  fill: white;
  transition: fill 300ms;
}

.AdminMenu-menuIsHovered .AdminMenu--vector ::v-deep .Vector {
  fill: black;
}

.AdminMenu--link:hover .AdminMenu--vector ::v-deep .Vector {
  opacity: 1;
}

.AdminMenu--link-selected .AdminMenu--vector ::v-deep .Vector {
  opacity: 1;
}

.AdminMenu--icons {
  height: 100%;
  width: 50px;
  min-width: 50px;
  background: black;
  transition: background 300ms;
}

.AdminMenu-menuIsHovered .AdminMenu--icons {
  background: #f2f2f2;
}

.AdminMenu--body {
  width: calc(100% - 50px);
  height: 100vh;
  margin-left: 50px;
  box-sizing: border-box;
  background: white;
  overflow: auto;
  transition: margin 300ms;
}

.AdminMenu-menuIsHovered .AdminMenu--body {
  width: calc(100% - 300px);
  margin-left: 300px;
}

.AdminMenu--tool {
  height: 80px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  margin: 0px 50px 0 50px;
  box-sizing: border-box;
  overflow: auto;
}

.AdminMenu--h1 {
  flex: 1;
  font-size: 20px;
  text-transform: capitalize;
}

.AdminMenu--siteLink {
  padding-right: 25px;
  min-width: 250px;
}

.AdminMenu--linkProfile {
  text-decoration: none;
  display: block;
  width: 100%;
}

.AdminMenu--user {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.AdminMenu--userDown {

}

.AdminMenu--userDown ::v-deep .Vector {
  width: 24px;
  height: 24px;
  fill: rgba(0, 0, 0, 0.5);
}

.AdminMenu--name {
  font-family: 'RalewayBold', sans-serif;
  font-size: 14px;
  padding: 0 10px;
  letter-spacing: 1px;
}

.AdminMenu--firstName {
  text-transform: capitalize;
}

.AdminMenu--lastName {
  text-transform: uppercase;
}

.AdminMenu--userIcon {
  width: 40px;
  height: 40px;
  background: #f2f2f2;
  border-radius: 40px;
  display: flex;
  align-items: center;
  justify-content: center;
}

.AdminMenu--userIcon ::v-deep .Vector {
  width: 24px;
  height: 24px;
}

.AdminMenu--content {
  height: calc(100% - 80px);
}
</style>

<template>
  <div class="AdminMenu" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="AdminMenu--page">
        <div class="AdminMenu--header" @mouseenter="() => onMouseEnterMenu()" @mouseleave="() => onMouseLeaveMenu()">
          <div class="AdminMenu--links">

            <div class="AdminMenu--title">
              <!--              <Link :pathIds="[PATHS.ADMIN]">-->
              <Link>
                <Vector :svg="vector.logo"/>
              </Link>
            </div>

            <!--            <template v-if="[ROLE.ROOT, ROLE.ADMIN, ROLE.CHR, ROLE.SEO].includes(state.roleId)">-->
            <!--              <Link :pathIds="[PATHS.ADMIN]">-->
            <!--                <div class="AdminMenu&#45;&#45;link">-->
            <!--                  <div class="AdminMenu&#45;&#45;term">-->
            <!--                    <Term :term="lang.term(149)"/>-->
            <!--                  </div>-->
            <!--                  <div class="AdminMenu&#45;&#45;vector">-->
            <!--                    <Vector :svg="vector.explore"/>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </Link>-->
            <!--            </template>-->

            <template v-if="[ROLE.ROOT, ROLE.ADMIN, ROLE.CHR].includes(state.roleId)">
              <Link :pathIds="[PATHS.ADMIN, PATHS.ORDER]">
                <div class="AdminMenu--link" :class="isSelected([PATHS.ADMIN, PATHS.ORDER], '')">
                  <div class="AdminMenu--term">
                    <Term :term="lang.term(152)"/>
                  </div>
                  <div class="AdminMenu--vector">
                    <Vector :svg="vector.local_shipping"/>
                  </div>
                </div>
              </Link>
            </template>

            <template v-if="[ROLE.ROOT, ROLE.ADMIN, ROLE.CHR].includes(state.roleId)">
              <Link :pathIds="[PATHS.ADMIN, PATHS.PRODUCT]">
                <div class="AdminMenu--link" :class="isSelected([PATHS.ADMIN, PATHS.PRODUCT], '')">
                  <div class="AdminMenu--term">
                    <Term :term="lang.term(151)"/>
                  </div>
                  <div class="AdminMenu--vector">
                    <Vector :svg="vector.local_grocery_store"/>
                  </div>
                </div>
              </Link>
            </template>

            <template v-if="[ROLE.ROOT, ROLE.ADMIN, ROLE.CHR].includes(state.roleId)">
              <Link :pathIds="[PATHS.ADMIN, PATHS.USER]">
                <div class="AdminMenu--link" :class="isSelected([PATHS.ADMIN, PATHS.USER], '')">
                  <div class="AdminMenu--term">
                    <Term :term="lang.term(150)"/>
                  </div>
                  <div class="AdminMenu--vector">
                    <Vector :svg="vector.people"/>
                  </div>
                </div>
              </Link>
            </template>

            <template v-if="[ROLE.ROOT, ROLE.ADMIN, ROLE.CHR].includes(state.roleId)">
              <Link :url="getUrlByProjectUrlId(PROJECT_URL.ADMIN_SELLER)">
                <div class="AdminMenu--link" :class="isSelected([], PROJECT_URL.ADMIN_SELLER)">
                  <div class="AdminMenu--term">
                    <Term :term="lang.term(410)"/>
                  </div>
                  <div class="AdminMenu--vector">
                    <Vector :svg="vector.store_mall_directory"/>
                  </div>
                </div>
              </Link>
            </template>

            <template v-if="[ROLE.ROOT, ROLE.ADMIN, ROLE.CHR].includes(state.roleId)">
              <Link :url="getUrlByProjectUrlId(PROJECT_URL.ADMIN_CHAT)">
                <div class="AdminMenu--link" :class="isSelected([], PROJECT_URL.ADMIN_CHAT)">
                  <div class="AdminMenu--term">
                    <Term :term="lang.term(444)"/>
                  </div>
                  <div class="AdminMenu--vector">
                    <Vector :svg="vector.message"/>
                  </div>
                </div>
              </Link>
            </template>

            <!--            <template v-if="[ROLE.ROOT, ROLE.ADMIN, ROLE.CHR].includes(state.roleId)">-->
            <!--              <Link :pathIds="[PATHS.ADMIN, PATHS.METRIC]">-->
            <!--                <div class="AdminMenu&#45;&#45;link">-->
            <!--                  <div class="AdminMenu&#45;&#45;term">-->
            <!--                    <Term :term="lang.term(153)"/>-->
            <!--                  </div>-->
            <!--                  <div class="AdminMenu&#45;&#45;vector">-->
            <!--                    <Vector :svg="vector.leaderboard"/>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </Link>-->
            <!--            </template>-->

            <template v-if="[ROLE.ROOT, ROLE.ADMIN, ROLE.CHR, ROLE.SEO].includes(state.roleId)">
              <Link :pathIds="[PATHS.ADMIN, PATHS.LANG]">
                <div class="AdminMenu--link" :class="isSelected([PATHS.ADMIN, PATHS.LANG], '')">
                  <div class="AdminMenu--term">
                    <Term :term="lang.term(154)"/>
                  </div>
                  <div class="AdminMenu--vector">
                    <Vector :svg="vector.language"/>
                  </div>
                </div>
              </Link>
            </template>

            <!--            <template v-if="[ROLE.ROOT, ROLE.ADMIN, ROLE.CHR].includes(state.roleId)">-->
            <!--              <Link :pathIds="[PATHS.ADMIN, PATHS.MARKETING]">-->
            <!--                <div class="AdminMenu&#45;&#45;link">-->
            <!--                  <div class="AdminMenu&#45;&#45;term">-->
            <!--                    <Term :term="lang.term(155)"/>-->
            <!--                  </div>-->
            <!--                  <div class="AdminMenu&#45;&#45;vector">-->
            <!--                    <Vector :svg="vector.stars"/>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </Link>-->
            <!--            </template>-->

            <!--            <template v-if="[ROLE.ROOT, ROLE.ADMIN, ROLE.CHR, ROLE.SEO].includes(state.roleId)">-->
            <!--              <Link :pathIds="[PATHS.ADMIN, PATHS.PAGE]">-->
            <!--                <div class="AdminMenu&#45;&#45;link">-->
            <!--                  <div class="AdminMenu&#45;&#45;term">-->
            <!--                    <Term :term="lang.term(205)"/>-->
            <!--                  </div>-->
            <!--                  <div class="AdminMenu&#45;&#45;vector">-->
            <!--                    <Vector :svg="vector.view_carousel"/>-->
            <!--                  </div>-->
            <!--                </div>-->
            <!--              </Link>-->
            <!--            </template>-->

          </div>
          <div class="AdminMenu--icons">
          </div>
        </div>
        <div class="AdminMenu--body">
          <div class="AdminMenu--tool">

            <!--            <h1 class="AdminMenu&#45;&#45;h1">-->
            <!--              {{ getTitle() }}-->
            <!--            </h1>-->

            <Link :pathIds="[PATHS.HOME]">
              <div class="AdminMenu--siteLink">
                <ButtonAction :term="lang.term(156)" :svg="vector.desktop_windows"/>
              </div>
            </Link>

            <Popover position="bottom-end">
              <template slot="default">
                <Link class="AdminMenu--linkProfile" :pathIds="[PATHS.ACCOUNT]">
                  <List :svg="vector.contacts" :text="lang.translate(319)"/>
                </Link>
                <List :svg="vector.exit_to_app" :text="lang.translate(138)" :onClick="() => disconnect()"/>
              </template>
              <template slot="reference">
                <div class="AdminMenu--user">
                  <div class="AdminMenu--userDown">
                    <Vector :svg="vector.expand_more"/>
                  </div>
                  <div class="AdminMenu--name">
                    <span class="AdminMenu--firstName">{{ state.user.firstName }}</span>
                  </div>
                  <div class="AdminMenu--userIcon">
                    <Vector :svg="vector.person"/>
                  </div>
                </div>
              </template>
            </Popover>
          </div>
          <div class="AdminMenu--content">
            <slot></slot>
          </div>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>

// -----

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint.ts';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN.ts';

// -----

import {User} from "../../../@common/delta/database/User";
import {Term} from "../../../@common/delta/database/Term";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";

// -----

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {stateService, STATE_USER_FIRST_NAME, STATE_USER_LAST_NAME, STATE_ROLE_ID} from "../../service/stateService";

// -----

import {getH1Term} from "../../function/getH1";
import {disconnect} from "../../function/disconnect";
import {getUrlByProjectUrlId, isPath, isProjectUrl} from "../../../@common/function/helperUrl";

// -----

import {ROLE} from "../../../@common/constant/ROLE";
import {PATHS} from "../../../@common/constant/PATHS";
import {PROJECT_URL} from "../../../@common/constant/PROJECT_URL";

// -----

export default {
  props: {
    bordered: {
      type: Boolean,
      default: () => true
    },
    onDiconnection: {
      type: Function,
      default: () => () => {
      }
    },
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {

    return {
      lang: getLangServiceBrowser(),
      ROLE,
      PATHS,
      PROJECT_URL,
      vector: {
        logo: require('../../../@common/assets/logo-white.svg'),
        explore: require('@bbx/vector~master/core/assets/svg/material/explore.svg'),
        people: require('@bbx/vector~master/core/assets/svg/material/people.svg'),
        local_grocery_store: require('@bbx/vector~master/core/assets/svg/material/local_grocery_store.svg'),
        store_mall_directory: require('@bbx/vector~master/core/assets/svg/material/store_mall_directory.svg'),
        local_shipping: require('@bbx/vector~master/core/assets/svg/material/local_shipping.svg'),
        leaderboard: require('@bbx/vector~master/core/assets/svg/material/leaderboard.svg'),
        language: require('@bbx/vector~master/core/assets/svg/material/language.svg'),
        stars: require('@bbx/vector~master/core/assets/svg/material/stars.svg'),
        expand_more: require('@bbx/vector~master/core/assets/svg/material/expand_more.svg'),
        person: require('@bbx/vector~master/core/assets/svg/material/person.svg'),
        desktop_windows: require('@bbx/vector~master/core/assets/svg/material/desktop_windows.svg'),
        exit_to_app: require('@bbx/vector~master/core/assets/svg/material/exit_to_app.svg'),
        contacts: require('@bbx/vector~master/core/assets/svg/material/contacts.svg'),
        view_carousel: require('@bbx/vector~master/core/assets/svg/material/view_carousel.svg'),
        speaker_notes: require('@bbx/vector~master/core/assets/svg/material/speaker_notes.svg'),
        message: require('@bbx/vector~master/core/assets/svg/material/message.svg'),
      },
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      state: {
        user: new User(),
        roleId: 0,
        h1: this.getH1(),
        menuIsHovered: false
      },
    }
  },
  watch: {
    'nuxtProp.page.id': {
      handler() {
        this.state.h1 = this.getH1()
      }
    }
  },
  computed: {
    class_root() {
      const classes = []
      if (this.state.menuIsHovered) classes.push(`AdminMenu-menuIsHovered`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
    this.state.user.lastName = stateService.get(STATE_USER_LAST_NAME)
    this.state.user.firstName = stateService.get(STATE_USER_FIRST_NAME)
    this.state.roleId = stateService.get(STATE_ROLE_ID)

    stateService.watch(STATE_USER_LAST_NAME, v => this.state.user.lastName = v)
    stateService.watch(STATE_USER_FIRST_NAME, v => this.state.user.firstName = v)
    stateService.watch(STATE_ROLE_ID, v => this.state.roleId = v)

  },
  beforeDestroy() {
  },
  methods: {
    getH1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    getTitle() {
      return this.nuxtProp.url.paths[2] ? this.nuxtProp.url.paths[2].value : this.nuxtProp.url.paths[1].value
    },
    disconnect() {
      disconnect()
    },
    /**
     * @param {string[]} paths
     * @param {string} projectUrls
     * @return {string}
     */
    isSelected(paths = [], projectUrl = '') {
      const classes = []

      if (paths.length && isPath(this.nuxtProp, paths)) classes.push('AdminMenu--link-selected')
      if (projectUrl && isProjectUrl(this.nuxtProp, projectUrl)) classes.push('AdminMenu--link-selected')

      return classes
    },
    /**
     * @param {number} projectUrlId
     * @return {string}
     */
    getUrlByProjectUrlId(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    },

    onMouseEnterMenu() {
      this.state.menuIsHovered = true
    },

    onMouseLeaveMenu() {
      this.state.menuIsHovered = false
    },
  }
}
</script>
