<style scoped>
.FilterDrag {
}

.FilterDrag--range {
  padding-top: 25px;
}

.FilterDrag--inputs {
  display: flex;
  justify-content: space-between;
  padding-top: 25px;
}

.FilterDrag--input {

}

.FilterDrag--input ::v-deep .el-input-number--mini {
  width: 100px !important;
}

.FilterDrag--inputLabel {
  background: black;
  color: white;
  border-radius: 3px;
  font-family: 'ComfortaaRegular', sans-serif;
  font-size: 12px;
  padding: 2px 4px;
  display: inline-block;
  margin-right: 10px;
}
</style>

<template>
  <div class="FilterDrag" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <FilterCollapse :title="title"
                      :svg="svg"
                      :opened="opened"
                      :onOpenedChange="onOpenedChange"
                      :withToggle="withToggle"
                      :withIcon="withIcon"
                      :count="(state.min !== state.minValue || state.max !== state.maxValue) ? 1 : 0"
                      :onCancel="() => onCancel()">
        <div class="FilterDrag--range">
          <el-slider
              v-if="state.visible"
              @input="v => onSliderChange(v)"
              :value="state.selected"
              :range="true"
              :min="state.min"
              :max="state.max">
          </el-slider>
          <div class="FilterDrag--inputs">
            <div class="FilterDrag--input">
              <div class="FilterDrag--inputLabel">min</div>
              <el-input-number @change="v => state.minValue = v"
                               :min="state.min"
                               :max="state.max"
                               :value="state.minValue"
                               size="mini">
              </el-input-number>
            </div>
            <div class="FilterDrag--input">
              <div class="FilterDrag--inputLabel">max</div>
              <el-input-number @change="v => state.maxValue = v"
                               :min="state.min"
                               :max="state.max"
                               :value="state.maxValue"
                               size="mini">
              </el-input-number>
            </div>
          </div>
        </div>
      </FilterCollapse>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {Term} from "../../../@common/delta/database/Term";
import {PICTURE_DISPLAY} from "../../../@common/constant/PICTURE_DISPLAY";
import {Debounce} from "../../../@common/ts/Debounce";

let debounce = new Debounce()

export default {

  props: {

    // -----
    // default
    // -----

    min: {
      type: Number,
      default: () => 0
    },
    minValue: {
      type: Number,
      default: () => 2
    },
    max: {
      type: Number,
      default: () => 10
    },
    maxValue: {
      type: Number,
      default: () => 8
    },
    onValuesChange: {
      type: Function,
      default: () => () => {
      }
    },

    // -----
    // extends
    // -----

    title: {
      default: () => new Term()
    },
    svg: {
      type: String,
      default: () => ''
    },
    opened: {
      type: Boolean,
      default: () => false
    },
    onOpenedChange: {
      type: Function,
      default: () => () => {
      }
    },
    withToggle: {
      type: Boolean,
      default: () => true
    },
    withIcon: {
      type: Boolean,
      default: () => true
    },
  },
  data() {
    return {
      PICTURE_DISPLAY,
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {
        opened: this.opened,
        visible: true,
        min: this.min,
        max: this.max,
        minValue: this.minValue,
        maxValue: this.maxValue,
        selected: [
          this.minValue,
          this.maxValue
        ],
      },
    }
  },
  watch: {
    'opened': function (v) {
      this.state.opened = v
    },
    'state.opened': function (v) {
      this.onOpenedChange(v)
    },
    'min': function (v) {
      this.state.min = v
    },
    'max': function (v) {
      this.state.max = v
    },
    'minValue': function (v) {
      this.state.minValue = v
    },
    'state.minValue': function (v) {
      this.state.visible = false
      this.state.selected[0] = v
      this.$nextTick(() => {
        this.state.visible = true
        this.onValuesChange({
          minValue: this.state.minValue,
          maxValue: this.state.maxValue,
        })
      })
    },
    'maxValue': function (v) {
      this.state.maxValue = v
    },
    'state.maxValue': function (v) {
      this.state.visible = false
      this.state.selected[1] = v
      this.$nextTick(() => {
        this.state.visible = true
        this.onValuesChange({
          minValue: this.state.minValue,
          maxValue: this.state.maxValue,
        })
      })
    },
  },
  computed: {
    class_root() {
      const classes = []
      return classes;
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    onSliderChange(value) {
      this.state.selected = value;
      debounce.callback({
        time: 1000,
        job: async () => {
          if (this.state.minValue !== value[0]) this.state.minValue = value[0];
          if (this.state.maxValue !== value[1]) this.state.maxValue = value[1] ? value[1] : this.state.max;
        },
        success: res => {
        },
        error: err => {
        }
      })
    },
    onCancel() {
      this.state.selected = [
        this.minValue,
        this.maxValue,
      ];
      this.state.minValue = this.min
      this.state.maxValue = this.max
    }
  }
}
</script>
