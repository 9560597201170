"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Browser = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Term_1 = require("./Term");
var Browser = /** @class */ (function () {
    function Browser(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.terms = deltaService_1.deltaService.array(props.terms, Term_1.Term);
    }
    return Browser;
}());
exports.Browser = Browser;
