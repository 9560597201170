<style scoped>
.Auth {
  height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Auth--form {
  width: 400px;
}
</style>

<template>
  <div class="Auth">
    <Form class="Auth--form">
      <InputText :value="state.auth.token"
                 :password="true"
                 :onChangeValue="v => state.auth.token = v"
                 :label="lang.term(132)"
                 :svg="icon.vpn_key"
                 :error="state.form.display('token')">
      </InputText>
      <br>
      <ButtonAction :term="lang.term(133)" :onClick="() => verify()" :loading="state.loading"/>
    </Form>
  </div>
</template>

<script>

import {stateService, STATE_TOKEN} from '../../service/stateService'

import verifyAuth from '../../../@common/api/auth/verifyAuth'

import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {Auth} from "../../../@common/delta/database/Auth.ts";
import {controlForm} from "../../function/controlForm";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

import {PAYLOAD} from "../../../@common/constant/PAYLOAD";
import {dispatchConnection} from "../../function/dispatchConnection";
import {changeRoute} from "../../function/changeRoute";
import {PATHS} from "../../../@common/constant/PATHS";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    // console.log(this.nuxtProp.url);
    let token = ''
    for (const query of this.nuxtProp.url.queries) {
      if (query.name === 'token') token = query.value
    }
    return {
      lang: getLangServiceBrowser(),
      icon: {
        vpn_key: require('@bbx/vector~master/core/assets/svg/material/vpn_key.svg')
      },
      state: {
        loading: false,
        auth: new Auth({
          token: token
        }),
        form: new ApiResponseForm(),
      },
    }
  },
  watch: {},
  computed: {},
  beforeMount() {
  },
  async mounted() {
    await this.verify()
  },
  beforeDestroy() {
  },
  methods: {
    async verify() {
      const res = await controlForm({
        apiAction: verifyAuth,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loading',
        loaderState: this.state,
        message: this.lang.translate(131),
        payload: this.state.auth,
        vue: this,
      });
      if (res.success) {
        this.dispatch(res.data);
      }
    },
    /**
     * @param {Auth} auth
     */
    dispatch(auth) {

      /** @type {PayloadInterface} */
      const payload = JSON.parse(auth.payload)

      // ...

      if (payload.type === PAYLOAD.CONNECTION) {
        dispatchConnection(auth)
        changeRoute([PATHS.ADMIN]);
      }

      // ...

      else if (payload.type === PAYLOAD.REQUEST_PASSWORD) {
        stateService.set(STATE_TOKEN, auth.token)
        changeRoute([PATHS.UPDATE_PASSWORD])
      }
    }
  }
}
</script>
