<style scoped lang="scss">

@import "../../scss/size";

.MarqueCommitment {
}


/* 
 * * * * * * * * * *
 *
 *   First
 *
 * * * * * * * * * *
 */

.MarqueCommitment--first {

}

.MarqueCommitment-desktop .MarqueCommitment--first {
  max-width: 800px;
  margin: auto;
  text-align: center;
}

.MarqueCommitment-tablet .MarqueCommitment--first {
  max-width: 800px;
  margin: auto;
  text-align: center;
}

.MarqueCommitment-mobile .MarqueCommitment--first {
}

/* 
 * * * * * * * * * *
 *
 *   Second
 *
 * * * * * * * * * *
 */

.MarqueCommitment--second {
  display: flex;
  align-items: center;
}

.MarqueCommitment-desktop .MarqueCommitment--second {

}

.MarqueCommitment-tablet .MarqueCommitment--second {

}

.MarqueCommitment-mobile .MarqueCommitment--second {
  flex-direction: column;
}

/**/

.MarqueCommitment--secondRight {
  flex: 1;
  display: flex;
  align-items: center;
}

/**/

.MarqueCommitment--block-1 {
  flex: 1;
  display: flex;
  align-items: center;
  flex-direction: column;
}

/*
 * * * * * * * * * *
 *
 *   Third
 *
 * * * * * * * * * *
 */

.MarqueCommitment--third {
  display: flex;
}

.MarqueCommitment-desktop .MarqueCommitment--third {
  align-items: center;
}

.MarqueCommitment-tablet .MarqueCommitment--third {
  align-items: center;
}

.MarqueCommitment-mobile .MarqueCommitment--third {
  flex-direction: column-reverse;
}

/**/

.MarqueCommitment--thirdLeft {

}

.MarqueCommitment-desktop .MarqueCommitment--thirdLeft {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.MarqueCommitment-tablet .MarqueCommitment--thirdLeft {
  flex: 1;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  flex-direction: column;
}

.MarqueCommitment-mobile .MarqueCommitment--thirdLeft {
}

/**/

.MarqueCommitment--thirdRight {
  flex: 1;
  display: flex;
  justify-content: flex-start;
}


/*
 * * * * * * * * * *
 *
 *   Four
 *
 * * * * * * * * * *
 */

.MarqueCommitment--four {
  text-align: center;
}

.MarqueCommitment-desktop .MarqueCommitment--four {

}

.MarqueCommitment-tablet .MarqueCommitment--four {

}

.MarqueCommitment-mobile .MarqueCommitment--four {

}

/**/

.MarqueCommitment--bubble {
  display: inline-block;
  margin: 10px;
  border: solid 1px #707070;
  border-radius: 500px;
  font-family: 'AeonikRegular', sans-serif;
  letter-spacing: 1px;
  cursor: pointer;
  opacity: 1;
  transition: opacity 300ms;
}

.MarqueCommitment-desktop .MarqueCommitment--bubble {
  padding: 20px 40px;
  font-size: 16px;
}

.MarqueCommitment-tablet .MarqueCommitment--bubble {
  padding: 20px 40px;
  font-size: 16px;
}

.MarqueCommitment-mobile .MarqueCommitment--bubble {
  padding: 15px 30px;
  font-size: 14px;
}

.MarqueCommitment--bubble:hover {
  opacity: 0.5;
}

/*
 * * * * * * * * * *
 *
 *   Five
 *
 * * * * * * * * * *
 */

.MarqueCommitment--five {
  position: relative;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  text-align: center;
}

.MarqueCommitment-desktop .MarqueCommitment--five {
  margin: 0 100px;
  height: 600px;
}

.MarqueCommitment-tablet .MarqueCommitment--five {
  height: 600px;
}

.MarqueCommitment-mobile .MarqueCommitment--five {
  height: 400px;
}

/**/

.MarqueCommitment--fiveTitle {
  font-family: 'AnittoRegular', sans-serif;
  color: #ffffff;
  position: relative;
}


.MarqueCommitment-desktop .MarqueCommitment--fiveTitle {
  font-size: 40px;
}

.MarqueCommitment-tablet .MarqueCommitment--fiveTitle {
  font-size: 30px;
}

.MarqueCommitment-mobile .MarqueCommitment--fiveTitle {
  font-size: 20px;
}

/**/

.MarqueCommitment--fiveBlock {
  position: relative;
  background-color: #ffffff;
  box-sizing: border-box;
}


.MarqueCommitment-desktop .MarqueCommitment--fiveBlock {
  width: calc(100% - 500px);
}

@media screen and (max-width: $menu-image-screen) {
  .MarqueCommitment-desktop .MarqueCommitment--fiveBlock {
    width: calc(100% - 400px);
  }
}

.MarqueCommitment-tablet .MarqueCommitment--fiveBlock {
  width: calc(100% - 300px);
}

.MarqueCommitment-mobile .MarqueCommitment--fiveBlock {
  width: calc(100% - 40px);
  padding: 0 20px;
}

/*
 * * * * * * * * * *
 *
 *   Six
 *
 * * * * * * * * * *
 */

.MarqueCommitment--six {
  margin: auto;
}

.MarqueCommitment--six * {
  text-align: left !important;
}

.MarqueCommitment-desktop .MarqueCommitment--six {
  width: calc(100% - 900px);
}

@media screen and (max-width: $menu-image-screen) {
  .MarqueCommitment-desktop .MarqueCommitment--six {
    width: calc(100% - 800px);
  }
}

.MarqueCommitment-tablet .MarqueCommitment--six {
  width: calc(100% - 500px);
}

.MarqueCommitment-mobile .MarqueCommitment--six {
  width: calc(100% - 40px);
}

/*
 * * * * * * * * * *
 *
 *   Seven
 *
 * * * * * * * * * *
 */

.MarqueCommitment--higlight {
  font-family: 'AmbroiseRegular', sans-serif;
}

.MarqueCommitment--higlight * {
  text-align: center !important;
}

.MarqueCommitment-desktop .MarqueCommitment--higlight {
  font-size: 40px;
  max-width: 800px;
  margin: auto;
}

.MarqueCommitment-tablet .MarqueCommitment--higlight {
  font-size: 30px;
  max-width: 600px;
  margin: auto;
}

.MarqueCommitment-mobile .MarqueCommitment--higlight {
  font-size: 25px;
  max-width: 400px;
  margin: auto;
  padding: 0px 20px 50px 20px;
}

/**/

.MarqueCommitment--higlightDivider {
  width: 100px;
  height: 1px;
  background-color: #31302F;
  margin: auto;
}

/*
 * * * * * * * * * *
 *
 *   Next
 *
 * * * * * * * * * *
 */

.MarqueCommitment--next {
  display: flex;
  justify-content: center;
}

.MarqueCommitment-desktop .MarqueCommitment--next {

}

.MarqueCommitment-tablet .MarqueCommitment--next {
  transform: scale(0.75);
}

.MarqueCommitment-mobile .MarqueCommitment--next {
  transform: scale(0.5);
}

/*
 * * * * * * * * * *
 *
 *   Component
 *
 * * * * * * * * * *
 */

.MarqueCommitment--photo-1 {

}

.MarqueCommitment-desktop .MarqueCommitment--photo-1 {
  width: 600px;
  height: 800px;
}

@media screen and (max-width: $menu-image-screen) {
  .MarqueCommitment-desktop .MarqueCommitment--photo-1 {
    width: 500px;
    height: 800px;
  }
}

.MarqueCommitment-tablet .MarqueCommitment--photo-1 {
  width: 500px;
  height: 600px;
}

.MarqueCommitment-mobile .MarqueCommitment--photo-1 {
  width: 100%;
  height: 500px;
  padding: 20px;
  box-sizing: border-box;
}

/**/

.MarqueCommitment--photo-2 {
}

.MarqueCommitment-desktop .MarqueCommitment--photo-2 {
  width: 400px;
  height: 400px;

}

@media screen and (max-width: $menu-image-screen) {
  .MarqueCommitment-desktop .MarqueCommitment--photo-2 {
    width: 300px;
    height: 300px;
  }
}

.MarqueCommitment-tablet .MarqueCommitment--photo-2 {
  display: none;
}

.MarqueCommitment-mobile .MarqueCommitment--photo-2 {
  display: none;
}

/**/

.MarqueCommitment--photo-3 {
}

.MarqueCommitment-desktop .MarqueCommitment--photo-3 {
  width: 700px;
  height: 700px;
  min-width: 700px;
  min-height: 700px;
}

.MarqueCommitment-tablet .MarqueCommitment--photo-3 {
  width: 600px;
  height: 600px;
  min-width: 600px;
  min-height: 600px;
}

.MarqueCommitment-mobile .MarqueCommitment--photo-3 {
  height: 50%;
}

/**/

.MarqueCommitment--photo-4 {
}

.MarqueCommitment-desktop .MarqueCommitment--photo-4 {
  width: 500px;
  height: 500px;
}

.MarqueCommitment-tablet .MarqueCommitment--photo-4 {
  width: 400px;
  height: 400px;
}

.MarqueCommitment-mobile .MarqueCommitment--photo-4 {
  width: 100%;
  height: 400px;
  box-sizing: border-box;
  padding: 0 20px;
}

/**/

.MarqueCommitment--spacer {
}

.MarqueCommitment-desktop .MarqueCommitment--spacer {
  width: 25px;
  height: 25px;
  min-width: 25px;
  min-height: 25px;
}

.MarqueCommitment-tablet .MarqueCommitment--spacer {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.MarqueCommitment-mobile .MarqueCommitment--spacer {
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
}

/**/

.MarqueCommitment--title {
  font-family: 'AmbroiseRegular', sans-serif;
  color: #1B1919;
}

.MarqueCommitment-desktop .MarqueCommitment--title {
  font-size: 50px;
}

.MarqueCommitment-tablet .MarqueCommitment--title {
  font-size: 40px;
}

.MarqueCommitment-mobile .MarqueCommitment--title {
  font-size: 30px;
  padding: 0 20px;
  text-align: center;
}

/**/

.MarqueCommitment--titleBrown {
  font-family: 'CommutersSansBold', sans-serif;
  color: #895B4F;
  text-transform: uppercase;
}

.MarqueCommitment-desktop .MarqueCommitment--titleBrown {
  font-size: 14px;
}

.MarqueCommitment-tablet .MarqueCommitment--titleBrown {
  font-size: 14px;
}

.MarqueCommitment-mobile .MarqueCommitment--titleBrown {
  font-size: 12px;
  text-align: center;
  padding: 0 20px;
}

/**/

.MarqueCommitment--subTitle {
  font-family: 'AmbroiseRegular', sans-serif;
  color: #1B1919;
}

.MarqueCommitment-desktop .MarqueCommitment--subTitle {
  font-size: 50px;
}

.MarqueCommitment-tablet .MarqueCommitment--subTitle {
  font-size: 40px;
}

.MarqueCommitment-mobile .MarqueCommitment--subTitle {
  font-size: 30px;
  padding: 0 20px;
  text-align: center;
}

/**/

.MarqueCommitment--subTitle-1 {

}

.MarqueCommitment-desktop .MarqueCommitment--subTitle-1 {
  max-width: 500px;
}

.MarqueCommitment-tablet .MarqueCommitment--subTitle-1 {
  max-width: 400px;
}

/**/

.MarqueCommitment--paragraph {
  font-family: 'AeonikRegular', sans-serif;
  letter-spacing: 0.25px;
  line-height: 28px;
  color: #31302F;
}

.MarqueCommitment-desktop .MarqueCommitment--paragraph {
  font-size: 18px;
}

.MarqueCommitment-tablet .MarqueCommitment--paragraph {
  font-size: 17px;
}

.MarqueCommitment-mobile .MarqueCommitment--paragraph {
  font-size: 15px;
  text-align: center;
  padding: 0 20px;
}

/**/

.MarqueCommitment--question {
  font-family: 'AeonikMedium', sans-serif;
  letter-spacing: 0.25px;
  line-height: 26px;
  color: #31302F;
  font-style: italic;
}

.MarqueCommitment-desktop .MarqueCommitment--question {
  font-size: 17px;
}

.MarqueCommitment-tablet .MarqueCommitment--question {
  font-size: 17px;
}

.MarqueCommitment-mobile .MarqueCommitment--question {
  font-size: 15px;
  text-align: center;
  padding: 0 20px;
}

/**/

.MarqueCommitment--author {
  font-family: 'AeonikRegular', sans-serif;
  letter-spacing: 0.25px;
  line-height: 26px;
  color: #31302F;
}

.MarqueCommitment-desktop .MarqueCommitment--author {
  font-size: 17px;
  display: flex;
  align-items: center;
}

.MarqueCommitment-tablet .MarqueCommitment--author {
  font-size: 17px;
  display: flex;
  align-items: center;
}

.MarqueCommitment-mobile .MarqueCommitment--author {
  font-size: 15px;
  text-align: center;
  padding: 0 20px;
}

.MarqueCommitment--author span:first-child {
  display: inline-block;
  width: 10px;
  height: 10px;
  border-radius: 20px;
  margin-right: 10px;
}

.MarqueCommitment--author-1 span:first-child {
  background-color: #895B4F;
}

.MarqueCommitment--author-2 span:first-child {
  background-color: #C9B086;
}

.MarqueCommitment--author span:last-child {

}

/**/

.MarqueCommitment--paragraph-1 {
}

.MarqueCommitment-desktop .MarqueCommitment--paragraph-1 {
  max-width: 500px;
}

.MarqueCommitment-tablet .MarqueCommitment--paragraph-1 {
  max-width: 400px;
}

.MarqueCommitment-mobile .MarqueCommitment--paragraph-1 {
}

/**/

.MarqueCommitment--paragraph-2 {
}

.MarqueCommitment-desktop .MarqueCommitment--paragraph-2 {
  max-width: 450px;
}

.MarqueCommitment-tablet .MarqueCommitment--paragraph-2 {
  max-width: 400px;
}

.MarqueCommitment-mobile .MarqueCommitment--paragraph-2 {
}

/**/

.MarqueCommitment--paragraph-3 {
}

.MarqueCommitment-desktop .MarqueCommitment--paragraph-3 {
  max-width: 500px;
}

.MarqueCommitment-tablet .MarqueCommitment--paragraph-3 {
  max-width: 400px;
}

/**/

.MarqueCommitment--list {
  display: flex;
  align-items: center;
}

.MarqueCommitment-desktop .MarqueCommitment--list {
  max-width: 500px;
}

.MarqueCommitment-tablet .MarqueCommitment--list {
  max-width: 400px;
}

.MarqueCommitment-mobile .MarqueCommitment--list {
  flex-direction: column;
}

.MarqueCommitment--listNumber {
  font-family: 'AmbroiseRegular', sans-serif;
  color: #895B4F;
  font-size: 45px;
}

.MarqueCommitment--listText {

}

/**/

.MarqueCommitment--section {
  flex: 1;
  display: flex;
}

.MarqueCommitment--section-1 {
  background-color: #ECE8E4;
}

.MarqueCommitment--section-right {
  background-color: rgba(236, 232, 228, 0.45);
  flex-direction: row-reverse;
}

.MarqueCommitment--section-last {
  background-color: rgba(236, 232, 228, 0.2);
}

.MarqueCommitment-desktop .MarqueCommitment--section {

}

.MarqueCommitment-tablet .MarqueCommitment--section {

}

.MarqueCommitment-mobile .MarqueCommitment--section {
  flex-direction: column;
  padding: 20px;
  margin-top: 40px;
}

.MarqueCommitment--sectionText {
  flex: 1;
  display: flex;
  align-items: center;
  justify-content: center;
}

.MarqueCommitment-desktop .MarqueCommitment--sectionText {

}

.MarqueCommitment-tablet .MarqueCommitment--sectionText {

}

.MarqueCommitment-mobile .MarqueCommitment--sectionText {
  padding: 20px;
}

/**/

.MarqueCommitment--blockText {

}

.MarqueCommitment-desktop .MarqueCommitment--blockText {
  max-width: 500px;
}

@media screen and (max-width: $menu-image-screen) {
  .MarqueCommitment-desktop .MarqueCommitment--blockText {
    max-width: 400px;
  }
}

.MarqueCommitment-tablet .MarqueCommitment--blockText {
  max-width: 350px;
}

.MarqueCommitment-mobile .MarqueCommitment--blockText {

}

/**/

.MarqueCommitment--sectionPhoto {
  position: relative;
}

.MarqueCommitment-desktop .MarqueCommitment--sectionPhoto {
  width: 700px;
  height: 700px;
  min-width: 700px;
  min-height: 700px;
}

.MarqueCommitment-tablet .MarqueCommitment--sectionPhoto {
  width: 600px;
  height: 600px;
  min-width: 600px;
  min-height: 600px;
}

.MarqueCommitment-mobile .MarqueCommitment--sectionPhoto {
  width: 100%;
  height: 400px;
}

/**/

.MarqueCommitment--cercle {
  background-color: #ffffff;
  padding: 20px;
  box-sizing: border-box;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.MarqueCommitment-desktop .MarqueCommitment--cercle {
  position: absolute;
  top: 50%;
  left: -175px;
  margin-top: -175px;
  width: 350px;
  height: 350px;
  border-radius: 400px;
}

@media screen and (max-width: $menu-image-screen) {
  .MarqueCommitment-desktop .MarqueCommitment--cercle {
    top: 50%;
    left: -150px;
    margin-top: -150px;
    width: 300px;
    height: 300px;
  }
}

.MarqueCommitment-tablet .MarqueCommitment--cercle {
  position: absolute;
  top: 50%;
  left: 50%;
  margin: -150px 0 0 -150px;
  width: 300px;
  height: 300px;
  border-radius: 400px;
}

.MarqueCommitment-mobile .MarqueCommitment--cercle {
  height: 50%;
  width: calc(100% - 40px);
  position: relative;
  top: -20px;
  left: 20px;
}

/**/

.MarqueCommitment--cercleNumber {
  font-family: 'AmbroiseRegular', sans-serif;
  color: #895B4F;
}

.MarqueCommitment-desktop .MarqueCommitment--cercleNumber {
  font-size: 80px;
}

@media screen and (max-width: $menu-image-screen) {
  .MarqueCommitment-desktop .MarqueCommitment--cercleNumber {
    font-size: 70px;
  }
}

.MarqueCommitment-tablet .MarqueCommitment--cercleNumber {
  font-size: 60px;
}

.MarqueCommitment-mobile .MarqueCommitment--cercleNumber {
  font-size: 40px;
}

/**/

.MarqueCommitment--cercleText {
  font-family: 'AeonikMedium', sans-serif;
  text-transform: uppercase;
}

.MarqueCommitment-desktop .MarqueCommitment--cercleText {
  font-size: 14px;
  line-height: 24px;
}

@media screen and (max-width: $menu-image-screen) {
  .MarqueCommitment-desktop .MarqueCommitment--cercleText {
    font-size: 13px;
    line-height: 23px;
  }
}

.MarqueCommitment-tablet .MarqueCommitment--cercleText {
  font-size: 12px;
  line-height: 22px;
}

.MarqueCommitment-mobile .MarqueCommitment--cercleText {
  font-size: 10px;
  line-height: 20px;
}

/**/

.MarqueCommitment--sigle {

}

.MarqueCommitment-desktop .MarqueCommitment--sigle {

}

.MarqueCommitment-tablet .MarqueCommitment--sigle {

}

.MarqueCommitment-mobile .MarqueCommitment--sigle {
  text-align: center;
}

.MarqueCommitment--sigle img {
  width: 125px;
}

</style>

<template>
  <div class="MarqueCommitment" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">

      <MarqueHeader :term="lang.term(762)" :imgSrc="img.header"></MarqueHeader>

      <MarqueTab :tab="state.tab" :tabs="state.tabs" :onChange="tab => onChangeTab(tab)"></MarqueTab>

      <div class="MarqueCommitment--first" data-id="commitment">

        <div class="MarqueCommitment--spacer"></div>
        <div class="MarqueCommitment--spacer"></div>
        <div class="MarqueCommitment--spacer"></div>

        <div class="MarqueCommitment--titleBrown">
          <AnimationTitle :fully="false">
            <Term :term="lang.term(763)"></Term>
          </AnimationTitle>
        </div>

        <div class="MarqueCommitment--spacer"></div>
        <div class="MarqueCommitment--spacer"></div>

        <div class="MarqueCommitment--title">
          <AnimationTitle :fully="false">
            <Term :term="lang.term(764)" tag="h2"></Term>
          </AnimationTitle>
        </div>

        <div class="MarqueCommitment--spacer"></div>
        <div class="MarqueCommitment--spacer"></div>

        <div class="MarqueCommitment--paragraph">
          <AnimationTitle :fully="false">
            <Term type="textarea" :term="lang.term(765)"></Term>
          </AnimationTitle>
        </div>
      </div>

      <div class="MarqueCommitment--spacer"></div>
      <div class="MarqueCommitment--spacer"></div>
      <div class="MarqueCommitment--spacer"></div>

      <div class="MarqueCommitment--spacer"></div>
      <div class="MarqueCommitment--spacer"></div>
      <div class="MarqueCommitment--spacer"></div>

      <div class="MarqueCommitment--second">
        <div class="MarqueCommitment--photo-1">
          <ScrollParallax :distance="50" :reverse="true">
            <Picture :src="img.photo1"></Picture>
          </ScrollParallax>
        </div>
        <div class="MarqueCommitment--secondRight">
          <div class="MarqueCommitment--block-1">
            <div>
              <div class="MarqueCommitment--subTitle MarqueCommitment--subTitle-1">
                <AnimationTitle :fully="false">
                  <Term :term="lang.term(766)" tag="h3"></Term>
                </AnimationTitle>
              </div>
              <div class="MarqueCommitment--spacer"></div>
              <div class="MarqueCommitment--paragraph MarqueCommitment--paragraph-1">
                <Term type="textarea" :term="lang.term(767)"></Term>
              </div>

              <div class="MarqueCommitment--spacer"></div>
              <div class="MarqueCommitment--spacer"></div>

              <div class="MarqueCommitment--list">
                <div class="MarqueCommitment--listNumber">
                  <AnimationTitle :fully="false">1</AnimationTitle>
                </div>
                <div class="MarqueCommitment--spacer"></div>
                <div class="MarqueCommitment--paragraph">
                  <Term type="textarea" :term="lang.term(768)"></Term>
                </div>
              </div>

              <div class="MarqueCommitment--spacer"></div>

              <div class="MarqueCommitment--list">
                <div class="MarqueCommitment--listNumber">
                  <AnimationTitle :fully="false">2</AnimationTitle>
                </div>
                <div class="MarqueCommitment--spacer"></div>
                <div class="MarqueCommitment--paragraph">
                  <Term type="textarea" :term="lang.term(769)"></Term>
                </div>
              </div>

              <div class="MarqueCommitment--spacer"></div>

              <div class="MarqueCommitment--list">
                <div class="MarqueCommitment--listNumber">
                  <AnimationTitle :fully="false">3</AnimationTitle>
                </div>
                <div class="MarqueCommitment--spacer"></div>
                <div class="MarqueCommitment--paragraph">
                  <Term type="textarea" :term="lang.term(770)"></Term>
                </div>
              </div>

            </div>
          </div>
          <div class="MarqueCommitment--photo-2">
            <ScrollParallax :distance="50" :reverse="false">
              <Picture :src="img.photo2"></Picture>
            </ScrollParallax>
          </div>
        </div>
      </div>

      <div class="MarqueCommitment--section MarqueCommitment--section-1">
        <div class="MarqueCommitment--sectionText">
          <div class="MarqueCommitment--blockText">
            <div class="MarqueCommitment--subTitle">
              <AnimationTitle :fully="false">
                <Term :term="lang.term(771)" tag="h3"></Term>
              </AnimationTitle>
            </div>
            <div class="MarqueCommitment--spacer"></div>
            <div class="MarqueCommitment--paragraph">
              <Term type="textarea" :term="lang.term(772)"></Term>
            </div>
          </div>
        </div>
        <div class="MarqueCommitment--sectionPhoto">
          <div class="MarqueCommitment--photo-3">
            <Picture :src="img.photo3"></Picture>
          </div>
          <div class="MarqueCommitment--cercle">
            <div class="MarqueCommitment--cercleNumber">
              <AnimationTitle :fully="false">
                <Term :term="lang.term(773)"></Term>
              </AnimationTitle>
            </div>
            <div class="MarqueCommitment--cercleText">
              <Term :term="lang.term(774)"></Term>
            </div>
          </div>
        </div>
      </div>


      <div class="MarqueCommitment--spacer"></div>
      <div class="MarqueCommitment--spacer"></div>
      <div class="MarqueCommitment--spacer"></div>

      <div class="MarqueCommitment--third" data-id="material">
        <div class="MarqueCommitment--thirdLeft">
          <div>

            <div class="MarqueCommitment--titleBrown">
              <AnimationTitle :fully="false">
                <Term :term="lang.term(775)"></Term>
              </AnimationTitle>
            </div>
            <div class="MarqueCommitment--spacer"></div>
            <div class="MarqueCommitment--subTitle MarqueCommitment--subTitle-1">
              <AnimationTitle :fully="false">
                <Term :term="lang.term(776)" tag="h3"></Term>
              </AnimationTitle>
            </div>
            <div class="MarqueCommitment--spacer"></div>
            <div class="MarqueCommitment--paragraph MarqueCommitment--paragraph-3">
              <AnimationTitle :fully="false">
                <Term type="textarea" :term="lang.term(777)"></Term>
              </AnimationTitle>
            </div>
          </div>
        </div>

        <div class="MarqueCommitment--spacer"></div>
        <div class="MarqueCommitment--spacer"></div>
        <div class="MarqueCommitment--spacer"></div>

        <div class="MarqueCommitment--thirdRight">
          <div class="MarqueCommitment--photo-4">
            <ScrollParallax :distance="50" :reverse="false">
              <Picture :src="img.photo4"></Picture>
            </ScrollParallax>
          </div>
        </div>
      </div>

      <div class="MarqueCommitment--spacer"></div>
      <div class="MarqueCommitment--spacer"></div>
      <div class="MarqueCommitment--spacer"></div>

      <div class="MarqueCommitment--spacer"></div>
      <div class="MarqueCommitment--spacer"></div>
      <div class="MarqueCommitment--spacer"></div>

      <div class="MarqueCommitment--four">
        <div class="MarqueCommitment--bubble" v-for="material in state.materials" :key="material.id" @click="() => toMaterial(material.id)">
          <Term :term="material.term"></Term>
        </div>
      </div>

      <div class="MarqueCommitment--spacer"></div>
      <div class="MarqueCommitment--spacer"></div>
      <div class="MarqueCommitment--spacer"></div>


      <div class="MarqueCommitment--section" :class="{'MarqueCommitment--section-right': !(index % 2),'MarqueCommitment--section-last': material.last}" v-for="(material, index) in state.materials" :key="material.id" :data-id="material.id">
        <div class="MarqueCommitment--sectionText">
          <div class="MarqueCommitment--blockText">
            <div class="MarqueCommitment--subTitle">
              <AnimationTitle :fully="false">
                <Term :term="material.termTitle" tag="h3"></Term>
              </AnimationTitle>
            </div>
            <div class="MarqueCommitment--spacer"></div>
            <div class="MarqueCommitment--paragraph">
              <AnimationTitle :fully="false">
                <Term type="textarea" :term="material.termDescription"></Term>
              </AnimationTitle>
            </div>
            <template v-if="material.sigle">
              <div class="MarqueCommitment--spacer"></div>
              <div class="MarqueCommitment--spacer"></div>
              <div class="MarqueCommitment--sigle">
                <img :src="material.sigle" alt="">
              </div>
            </template>
          </div>
        </div>
        <div class="MarqueCommitment--sectionPhoto">
          <Picture :src="material.img"></Picture>
        </div>
      </div>

      <div class="MarqueCommitment--spacer"></div>
      <div class="MarqueCommitment--spacer"></div>
      <div class="MarqueCommitment--spacer"></div>
      <div class="MarqueCommitment--spacer"></div>
      <div class="MarqueCommitment--spacer"></div>
      <div class="MarqueCommitment--spacer"></div>

      <div class="MarqueCommitment--next">
        <MarqueNext :term="lang.term(778)" :url="next"></MarqueNext>
      </div>

      <CountryLocation :alternate="true">
        <template slot="default">
          <HomeSpot :nuxtProp="nuxtProp"
                    :breakPoints="props.Responsive.breakPoints"
                    :verticalBreakPoints="props.Responsive.verticalBreakPoints">
          </HomeSpot>
        </template>
        <template slot="alternate">
          <div class="MarqueSolar--spacer"></div>
          <div class="MarqueSolar--spacer"></div>
          <div class="MarqueSolar--spacer"></div>
          <div class="MarqueSolar--spacer"></div>
          <div class="MarqueSolar--spacer"></div>
          <div class="MarqueSolar--spacer"></div>
        </template>
      </CountryLocation>

    </Responsive>
  </div>
</template>

<script>

// -----

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {eventService} from "../../service/eventService";
import {scrollerParentService} from "../../service/scrollerParentService";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {getUrlByProjectUrlId} from "../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../@common/constant/PROJECT_URL";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import Home from "../home/Home";

// -----

export default {
  components: {Home},
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    const lang = getLangServiceBrowser()
    return {
      lang,
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      img: {
        header: require('../../../@common/assets/marque/commitment/header.jpg'),
        photo1: require('../../../@common/assets/marque/commitment/photo1.jpg'),
        photo2: require('../../../@common/assets/marque/commitment/photo2.jpg'),
        photo3: require('../../../@common/assets/marque/commitment/photo3.jpg'),
        photo4: require('../../../@common/assets/marque/commitment/photo4.png'),
      },
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              width: 0
            }),
            new BreakPoint({
              name: SCREEN.TABLET,
              width: 1024
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              width: 1440
            }),
          ],
          verticalBreakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              height: 0
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              height: 600
            }),
          ],
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      state: {
        tab: {
          id: 'commitment',
          term: lang.term(779),
        },
        tabs: [
          {
            id: 'commitment',
            term: lang.term(780),
          },
          {
            id: 'material',
            term: lang.term(781),
          },
        ],
        materials: [
          {
            id: `material1`,
            term: lang.term(782),
            img: require('../../../@common/assets/marque/commitment/material1.jpg'),
            termTitle: lang.term(783),
            termDescription: lang.term(784),
            sigle: require('../../../@common/assets/marque/commitment/sigle1.png'),
          },
          {
            id: `material2`,
            img: require('../../../@common/assets/marque/commitment/material2.jpg'),
            term: lang.term(785),
            termTitle: lang.term(786),
            termDescription: lang.term(787),
          },
          {
            id: `material3`,
            img: require('../../../@common/assets/marque/commitment/material3.jpg'),
            term: lang.term(788),
            termTitle: lang.term(789),
            termDescription: lang.term(790),
            sigle: require('../../../@common/assets/marque/commitment/sigle2.png'),
          },
          {
            id: `material4`,
            img: require('../../../@common/assets/marque/commitment/material4.jpg'),
            term: lang.term(791),
            termTitle: lang.term(792),
            termDescription: lang.term(793),
            sigle: require('../../../@common/assets/marque/commitment/sigle3.png'),
          },
          {
            id: `material5`,
            img: require('../../../@common/assets/marque/commitment/material5.jpg'),
            term: lang.term(794),
            termTitle: lang.term(795),
            termDescription: lang.term(796),
          },
          {
            id: `material6`,
            img: require('../../../@common/assets/marque/commitment/material6.jpg'),
            term: lang.term(797),
            termTitle: lang.term(798),
            termDescription: lang.term(799),
            last: true
          },
        ]
      },
      events: []
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`MarqueCommitment-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`MarqueCommitment-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`MarqueCommitment-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`MarqueCommitment-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`MarqueCommitment-desktopY`)
      return classes
    },
    next() {
      return getUrlByProjectUrlId(this.nuxtProp, PROJECT_URL.MARQUE_LES_SOLAIRES)
    }
  },
  beforeMount() {
    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    onChangeTab(tab) {
      this.state.tab = tab
      scrollerParentService.scrollToElement(`div[data-id="${tab.id}"]`, v => v - 100)
    },
    toMaterial(id) {
      scrollerParentService.scrollToElement(`div[data-id="${id}"]`, v => v - 100)
    }
  }
}
</script>