import {deltaService} from "@bbx/delta~master/core/service/deltaService"
import {User} from "./User";
import {Shop} from "./Shop";
import {DeepPartial} from "../../type/DeepPartial";


export type Sage_constructor_props = DeepPartial<Sage>

export class  Sage {

    id: number
    active: boolean
    createdAt: string
    updatedAt: string

    uuid: string

    users: User[]
    shops: Shop[]

    constructor(props: Sage_constructor_props = {}) {
                this.id = deltaService.number(props.id, 0)
        this.active = deltaService.boolean(props.active, true)
        this.createdAt = deltaService.string(props.createdAt, '')
        this.updatedAt = deltaService.string(props.updatedAt, '')

        this.uuid = deltaService.string(props.uuid, '')

        this.users = deltaService.array(props.users, User)
        this.shops = deltaService.array(props.shops, Shop)
    }
}
