<style>
.PageInfo {
}

.PageInfo--body {
  box-sizing: border-box;
}

.PageInfo-desktop .PageInfo--body {
  margin: auto;
  padding: 100px 20%;
}

.PageInfo-tablet .PageInfo--body {
  margin: auto;
  padding: 50px 25%;
}

.PageInfo-mobile .PageInfo--body {
  padding: 50px;
}

.PageInfo--body h1 {
  font-family: 'AmbroiseRegular', sans-serif;
  padding: 50px 0;
}

.PageInfo--body h2 {
  font-family: 'AmbroiseRegular', sans-serif;
  padding: 25px 0;
}

.PageInfo--body h3 {
  font-family: 'AmbroiseRegular', sans-serif;
  padding: 25px 0;
}

.PageInfo--body h4 {
  font-family: 'AmbroiseRegular', sans-serif;
  padding: 25px 0;
}

.PageInfo--body h5 {
  font-family: 'AmbroiseRegular', sans-serif;
  padding: 25px 0;
}

.PageInfo--body b {
  font-family: 'RalewayBold', sans-serif;
}

.PageInfo--body p {
  font-family: 'RalewayRegular', sans-serif;
  line-height: 20px;
  font-size: 16px;
}

.PageInfo--body a {
  color: #8A6C59;
  text-decoration: underline;
  transition: color 300ms;
  font-weight: bold;
}

.PageInfo--body a:hover {
  color: rgba(0, 0, 0, 1);
}


.PageInfo--body img {
  display: inline-block;
  max-width: 100%;
}

.PageInfo--body li {
  list-style: initial !important;
  padding-top: 5px;
}

.PageInfo--body ul {
  margin: initial !important;
  padding-left: 25px !important;
}

.PageInfo--body li ::v-deep .Term p {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.PageInfo--body li ::v-deep .Term p:first-letter {
  text-transform: uppercase;
}
</style>

<template>
  <div class="PageInfo" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <ArianeHeader :nuxtProp="nuxtProp"/>
      <div class="PageInfo--body">
        <slot></slot>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../function/getH1";
import {Term} from "../../../@common/delta/database/Term";
import {
  convertPathToUrl,
  getPageByPathIds,
  getUrlByProjectUrlId,
  searchPageByNuxProp
} from "../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../@common/constant/PROJECT_URL";
import {PATHS} from "../../../@common/constant/PATHS";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`PageInfo-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`PageInfo-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`PageInfo-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`PageInfo-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`PageInfo-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
