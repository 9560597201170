<style scoped lang="scss">

@import "../../scss/color";
@import "../../scss/size";

.Article {
  position: relative;
}

/*
 * * * * * * * * * *
 *
 *   OVERIDE
 *
 * * * * * * * * * *
 */

.Article ::v-deep .ButtonAction--iconBox, .Article ::v-deep .ButtonAction--iconBox * {
  overflow: initial;
  width: 18px;
  height: 18px;
  position: relative;
}

.Article ::v-deep .ButtonAction--icon {
  top: 0;
  left: 0;
}

.Article ::v-deep .ButtonAction-svg .ButtonAction--iconBox {
  top: 0;
  left: 0;
}

.Article .Article--top ::v-deep .ButtonAction--iconBox, .Article .Article--top ::v-deep .ButtonAction--iconBox * {
  width: 24px;
  height: 24px;
}

.Article .Article--infoBox ::v-deep .ButtonAction--iconBox {
  position: relative;
  top: -2px;
}

.Article ::v-deep .Ariane--link {
  padding: 0 10px !important;
  line-height: 15px;
}

/**/

.Article--loaderSpace {
  height: 100vh;
}

.Article--notVisible {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 500px;
}

.Article--body {
  position: relative;
}

.Article-mobile ::v-deep .ButtonLink .Term p {
  font-size: 9px !important;
}

.Article-mobile ::v-deep .ButtonAction .Term p {
  font-size: 9px !important;
}

.Article-mobile ::v-deep .InputLabel {
  justify-content: center;
}

.Article--logo {
  position: fixed;
  top: 25px;
  left: 25px;
  height: 100px;
  width: 250px;
  transform: translate(-250px, 0);
  transition: transform 500ms, opacity 500ms;
  opacity: 0;
  z-index: 1900;
  fill: rgba(0, 0, 0, 1);
}

.Article--logo ::v-deep .Link {
  display: block;
  width: 100%;
  height: 100%;
}

.Article-photosIsVisible .Article--logo {
  transform: translate(0, 0);
  opacity: 1;
}

.Article-whiteLogo .Article--logo ::v-deep .Vector svg {
  fill: rgba(255, 255, 255, 1);
}

.Article-mobile .Article--logo {
  left: 50%;
  margin-left: -125px;
}

.Article--anchor {
  position: absolute;
  bottom: -75vh;
  left: 0;
  width: 50px;
  height: 50px;
}

.Article--core {
}

/**
* * * * * * * * *
*  CONTENT
* * * * * * * * *
*/

.Article--mask {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  background: rgba(0, 0, 0, 0.5);
  transform: translateY(-100vh);
  transition: transform 500ms;
  z-index: 3000;
}

.Article--bodyRow {
  display: flex;
  border-bottom: solid 1px rgba(0, 0, 0, 0.03);
  /*background: rgba(0, 0, 0, 0.03);*/
  /*border-radius: 0 300px 0 0;*/
}

.Article--content {
  background: rgba(0, 0, 0, 0.03);
}

.Article-desktop .Article--content {
  width: 60%;
}

.Article-tablet .Article--content {
  width: 50%;
}

.Article-mobile .Article--content {
  width: 100%;
}

/**/

.Article--header {
  display: flex;
  position: relative;
}

.Article-desktop .Article--header {
  z-index: 1900;
}

.Article-tablet .Article--header {
  z-index: 1900;
}

.Article-mobile .Article--header {

}

.Article--previous {
  height: 100px;
  padding: 0 50px;
  background: rgba(0, 0, 0, 0.06);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 300ms;
}

.Article-mobile .Article--previous {
  height: 75px;
  padding: 0;
  width: 75px;
  min-width: 75px;
  display: flex;
  justify-content: center;
}

.Article-mobile .Article--previous ::v-deep .Term {
  display: none;
}

.Article--previous:hover {
  background: rgba(0, 0, 0, 0.12);
}

.Article--previousContent {
  display: flex;
  align-items: center;
}

.Article--previousIcon {
  width: 24px;
  min-width: 24px;
  position: relative;
  top: 2px;
}

.Article--previousTerm {
  padding-left: 15px;
  text-transform: uppercase;
  font-size: 12px;
  font-family: 'RalewayBold', sans-serif;
}

.Article-mobile .Article--previousTerm {
  display: none;
}

.Article--ariane {
  padding-left: 50px;
  display: flex;
  align-items: center;
}

.Article-desktop .Article--ariane {
  height: 100px;
}

.Article-tablet .Article--ariane {
  height: 100px;
}

.Article-mobile .Article--ariane {
  height: 75px;
  width: 100%;
  padding: 0;
  justify-content: center;
}

.Article--photos {
  min-height: 100vh;
  position: relative;
}

.Article-desktop .Article--photos {
  border-radius: 0 50px 0 0;
}

.Article-tablet .Article--photos {
  border-radius: 0 50px 0 0;
}

.Article-mobile .Article--photos {

}

/**/

.Article--corner {
  position: absolute;
  top: 0;
  right: 0;
  width: 65px;
  height: 65px;
  z-index: 2;
  overflow: hidden;
  transform: rotate(0deg);
}

.Article-desktop .Article--corner {

}

.Article-tablet .Article--corner {

}

.Article-mobile .Article--corner {
  display: none;
}

.Article--cornerCircle {
  position: absolute;
  top: -100px;
  right: -100px;
  width: 200%;
  height: 200%;
  border: solid 100px white;
  border-radius: 500px;
}

/**/

.Article--photo {
  width: 100%;
  height: calc(100vh - 200px);
  box-sizing: border-box;
  position: relative;
  position: relative;
}

.Article-desktop .Article--photo {
  padding: 50px 150px;
}

.Article-tablet .Article--photo {
  padding: 50px 100px;
}

.Article-mobile .Article--photo {
  padding: 50px;
}

.Article-desktop .Article--photo-full.Article--photo {
  padding: 0;
}

.Article-tablet .Article--photo-full.Article--photo {
  padding: 0;
}

.Article-mobile .Article--photo-full.Article--photo {
  padding: 0;
}

/**/

.Article--photoCount {
  font-family: 'AeonikMedium', sans-serif;
  color: black;
  border: solid 1px rgba(0, 0, 0, 0.15);
  border-radius: 3px;
  letter-spacing: 1px;
}

.Article-desktop .Article--photoCount {
  position: absolute;
  top: 35px;
  right: 35px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 60px;
}

.Article-tablet .Article--photoCount {
  position: absolute;
  top: 35px;
  right: 35px;
  width: 60px;
  height: 60px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 18px;
  border-radius: 60px;
}

.Article-mobile .Article--photoCount {
  position: absolute;
  top: 25px;
  right: 25px;
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 14px;
  border-radius: 60px;
}

.Article--photo-full .Article--photoCount {
  color: white;
  border: solid 1px rgba(255, 255, 255, 0.25);
  background: rgba(0, 0, 0, 0.75);
  font-weight: normal;
}

.Article--photo-full {
  height: auto;
  padding: 0;
}

.Article--collection {
  width: 100%;
  height: calc(100vh - 100px);
  background: black;
}

.Article--related {
  width: 100%;
  height: calc(100vh - 100px);
  background: white;
}

/**
 * * * * * * * * *
 *  INFO
 * * * * * * * * *
 */


.Article--top {
  height: 75px;
  justify-content: center;
  align-items: center;
  background: rgba(0, 0, 0, 0.03);
}

.Article-desktop .Article--top {
  display: none;
}

.Article-tablet .Article--top {
  display: none;
}

.Article-mobile .Article--top {
  display: flex;
}

.Article--top ::v-deep .ButtonAction {
  height: 100%;
  flex: 1;
}

.Article--top ::v-deep .ButtonAction button {
  height: 100%;
  background: white;
  border-radius: 0;
  color: $primary;
}

.Article--top ::v-deep .Vector svg {
  fill: $primary;
}

.Article--topOption {
  display: flex;
  align-items: center;
}

.Article--topOption-brown {
  background: $primary;
  height: 75px;
  width: 75px;
  display: flex;
  justify-content: center;
}

.Article--topPrice {
  font-family: 'ComfortaaRegular', sans-serif;
  font-size: 12px;
  font-weight: bold;
  padding-left: 10px;
}

.Article--topIcon {
  position: relative;
  top: 2px;
}

.Article--topIcon ::v-deep .Vector svg {
  width: 24px;
  fill: rgba(0, 0, 0, 0.5);
}

.Article--topOption-brown .Article--topIcon ::v-deep .Vector svg {
  fill: rgba(255, 255, 255, 0.5);
}

.Article--topText {
  font-size: 12px;
  font-family: 'RalewayBold', sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  margin-left: 10px;
  color: black;
}

.Article--topOption-brown .Article--topText {
  color: white;
  margin-left: 0px;
}

.Article-opened.Article-mobile .Article--mask {
  transform: translateY(0);
}

.Article-searchOpened .Article--mask {
  display: none;
}

.Article--infoBox {
  background: #ffffff;
}

.Article-desktop .Article--infoBox {
  width: 40%;
  min-width: 750px;
}

.Article-tablet .Article--infoBox {
  width: 50%;
}

.Article-mobile .Article--infoBox {
  width: 100%; /* override */
  top: 0;
  bottom: 0;
  position: fixed;
  background: white;
  transform: translateY(calc(100vh - #{$mobile-menu-content-height}));
  z-index: 1900;
  box-shadow: 0 0 50px rgba(0, 0, 0, 0.15);
  transition: transform 500ms;
  height: calc(100vh - #{$mobile-menu-content-height});
}

.Article-opened.Article-mobile .Article--infoBox {
  transform: translateY($mobile-menu-content-height);
  z-index: 3000;
  overflow: auto;
}

.Article--infoContent {
}

.Article-desktop .Article--infoContent {
  padding-left: 100px;
  padding-right: 100px;
}

.Article-desktop.Article-moreInfoOpened .Article--infoContent {
  padding-bottom: 100px;
}

.Article-tablet .Article--infoContent {
  padding-left: 50px;
  padding-right: 50px;
}

.Article-tablet.Article-moreInfoOpened .Article--infoContent {
  padding-bottom: 50px;
}

.Article-mobile .Article--infoContent {
  padding: 40px 20px;
}

.Article--new {
  display: inline-block;
}

.Article-desktop .Article--new ::v-deep .ArticleNew {
  margin-right: 15px;
}

.Article-tablet .Article--new ::v-deep .ArticleNew {
  margin-right: 15px;
}

.Article-mobile .Article--new {
  transform: scale(0.7);
}

.Article--ref {
  font-family: 'ComfortaaRegular', sans-serif;
  color: rgba(0, 0, 0, .5);
  font-size: 12px;
}

.Article-desktop .Article--ref {
  font-size: 12px;
}

.Article-tablet .Article--ref {
  font-size: 12px;
}

.Article-mobile .Article--ref {
  font-size: 9px;
  text-align: center;
}

.Article--evolve {
}

.Article-desktop .Article--evolve {
  text-align: left;
}

.Article-tablet .Article--evolve {
  text-align: left;
}

.Article-mobile .Article--evolve {
  text-align: center;
}

.Article--name {
  font-family: 'AmbroiseRegular', sans-serif;
}

.Article-desktop .Article--name {
  font-size: 40px;
}

.Article-tablet .Article--name {
  font-size: 40px;
}

.Article-mobile .Article--name {
  font-size: 24px;
  text-align: center;
}

/*  */

.Article--similar {

}

.Article-desktop .Article--similar {

}

.Article-tablet .Article--similar {

}

.Article-mobile .Article--similar {
  display: flex;
  justify-content: center;
}

/*  */

.Article--outlet {

}

.Article-desktop .Article--outlet {
}

.Article-tablet .Article--outlet {
}

.Article-mobile .Article--outlet {
  display: flex;
  justify-content: center;
}

.Article--payment {
  display: flex;
  align-items: center;
}

.Article--payment * {
  white-space: nowrap;
}

.Article--payment ::v-deep * {
  white-space: nowrap;
}

.Article--payment .Article--divider {
  margin: 0 15px;
}

.Article-desktop .Article--payment {
  flex-direction: row;
}

.Article-tablet .Article--payment {
  flex-direction: row;
}

.Article-mobile .Article--payment {
  flex-direction: column;
  text-align: center;
}

.Article--paymentLeft {
}

.Article-desktop .Article--paymentLeft {
  display: flex;
  align-items: center;
}

.Article-tablet .Article--paymentLeft {
  display: flex;
  align-items: center;
}

.Article-mobile .Article--paymentLeft {

}

/**/

.Article--payment .Article--divider {

}

.Article-desktop .Article--payment .Article--divider {

}

.Article-tablet .Article--payment .Article--divider {

}

.Article-mobile .Article--payment .Article--divider {
  opacity: 0;
}

/**/

.Article--paymentMultiple {
  //font-family: 'DDinRegular', sans-serif;
  font-family: 'AeonikRegular', sans-serif;
  position: relative;
  top: -1px;
}

.Article-desktop .Article--paymentMultiple {
  font-size: 20px;
}

.Article-tablet .Article--paymentMultiple {
  font-size: 20px;
}

.Article-mobile .Article--paymentMultiple {
  font-size: 20px;
}

/**/

.Article-desktop ::v-deep .Price--content {
}

.Article-tablet ::v-deep .Price--content {
}

.Article-mobile ::v-deep .Price--content {
  justify-content: center;
}

/**/


.Article--paymentMultipleRed {
  color: $primary;
}

.Article--paymentMultipleInfo {
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
}

.Article-desktop .Article--paymentMultipleInfo {
  padding-left: 5px;
  display: flex;
  align-items: center;
}

.Article-tablet .Article--paymentMultipleInfo {
  padding-left: 5px;
  display: flex;
  align-items: center;
}

.Article-mobile .Article--paymentMultipleInfo {
  padding-top: 5px;
}

.Article--price {

}

.Article--price ::v-deep * {
  //font-family: 'DDinRegular', sans-serif !important;
}

.Article--price * {
  display: flex;
  justify-content: center;
  flex-direction: column;
}

.Article--price ::v-deep .Price--big {
  font-size: 30px;
  position: relative;
  top: -2px;
  //color: black;
}

.Article-desktop .Article--price ::v-deep .Price--big {
  font-size: 30px;
}

.Article-tablet .Article--price ::v-deep .Price--big {
  font-size: 30px;
}

.Article-mobile .Article--price ::v-deep .Price--big {
  font-size: 20px;
}

/**/

.Article--price ::v-deep .Price--small {
  font-size: 15px;
}

.Article-desktop .Article--price ::v-deep .Price--small {
  font-size: 15px;
}

.Article-tablet .Article--price ::v-deep .Price--small {
  font-size: 10px;
}

.Article-mobile .Article--price ::v-deep .Price--small {
  font-size: 10px;
}

.Article--row {
  display: flex;
}

.Article-desktop .Article--row {
  flex-direction: row;
  align-items: center;
}

.Article-tablet .Article--row {
  flex-direction: row;
  align-items: center;
}

.Article-mobile .Article--row {
  flex-direction: column;
  align-items: center;
}

/**/

.Article--unitPrice {
  display: flex;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  font-size: 10px;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
}

.Article-desktop .Article--unitPrice {
  margin-left: 15px;
}

.Article-tablet .Article--unitPrice {
  margin-left: 15px;
}

.Article-mobile .Article--unitPrice {
  margin-top: 15px;
}

.Article--unitPrice b {
  text-transform: uppercase;
  letter-spacing: 1px;
  padding-right: 5px;
}

.Article--unitPrice span {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.Article--ecoParticipation {
  display: flex;
  color: rgba(0, 0, 0, 0.4);
  font-size: 14px;
}

.Article-desktop .Article--ecoParticipation {
  padding-left: 5px;
  align-items: center;
}

.Article-tablet .Article--ecoParticipation {
  padding-left: 5px;
  align-items: center;
}

.Article-mobile .Article--ecoParticipation {
  padding-top: 5px;
}

.Article--ecoParticipationAmount {
  padding: 0 5px;
}

.Article--paymentRight {
}

.Article-desktop .Article--paymentRight {
  display: flex;
  align-items: center;
}

.Article-tablet .Article--paymentRight {
  display: flex;
  align-items: center;
}

.Article-mobile .Article--paymentRight {
}

.Article--divider {
  margin: 0 25px;
  width: 1px;
  background: rgba(0, 0, 0, 0.1);
  height: 25px;
}

.Article--quantity {
  display: flex;
  align-items: center;
}

.Article-desktop .Article--quantity {
  flex-direction: row;
}

.Article-tablet .Article--quantity {
  flex-direction: row;
}

.Article-mobile .Article--quantity {
  flex-direction: column;
}

/**/

.Article-desktop .Article--quantity ::v-deep .el-input-number {
  margin-right: 20px;
}

.Article-tablet .Article--quantity ::v-deep .el-input-number {
  margin-right: 20px;
}

.Article-mobile .Article--quantity ::v-deep .el-input-number {
  margin-bottom: 10px;
}

/**/

.Article--stock {
  display: flex;
  align-items: center;
  cursor: pointer;
}

.Article--stockIcon {
  width: 24px;
  height: 24px;
  margin-left: 10px;
}

.Article--stockSoon {
}

.Article--infoStockMessage {
  font-size: 12px;
  font-family: 'ComfortaaRegular', sans-serif;
  border: solid 1px #F56C6C;
  padding: 15px 30px;
  text-align: center;
  color: #F56C6C;
  border-radius: 4px;
  line-height: 24px;
}

.Article--stockInfo {

}

.Article--stockInfo * {
  display: inline-block;
}

.Article--button {
  display: flex;
}

.Article--button ::v-deep .CountryLocation {
  flex: 1;
}

.Article-mobile .Article--button {
  flex: 1;
  width: 100%;
}

.Article-mobile .Article--button ::v-deep .CountryLocation {
  flex: 1;
  width: 100%;
}

.Article-desktop .Article--button {
  /*flex-direction: column;*/
}

.Article-tablet .Article--button {
  /*flex-direction: column;*/
}

.Article-mobile .Article--button {
  flex-direction: column;
  align-items: center;
}

/**/

.Article--buttonDivider {
  width: 17px;
  height: 17px;
  min-width: 17px;
  min-height: 17px;
}

.Article-desktop .Article--buttonDivider {

}

.Article-tablet .Article--buttonDivider {

}

.Article-mobile .Article--buttonDivider {
  display: none;
}

/**/

.Article--buttonAddBasket {
  width: 100%;
  flex: 1;
  display: flex;
}

.Article-desktop .Article--buttonAddBasket {
}

.Article-tablet .Article--buttonAddBasket {
}

.Article-mobile .Article--buttonAddBasket {
  width: 100%;
  padding-bottom: 10px;
}

/**/

.Article--buttonAddBasket ::v-deep .ButtonAction {
  flex: 1;
}

.Article--buttonAddBasket ::v-deep .ButtonAction--icon {
  top: -2px;
}

/**/

.Article--findSeller {
  width: 100%;
  flex: 1;
}

.Article .Article--findSeller ::v-deep .ButtonAction--iconBox {
  padding-right: 15px;
}

.Article--findSeller ::v-deep .ButtonAction--term {

}

.Article-desktop .Article--findSeller {
}

.Article-tablet .Article--findSeller {
}

.Article-mobile .Article--findSeller {
  width: 100%;
}

.Article--findSellerVector {
  width: 24px;
  height: 24px;
}

.Article--findSellerTerm {
  font-family: 'RalewayBold', sans-serif;
  font-size: 10px;
  text-transform: uppercase;
}


/**/

.Article--lumen {
  width: 100%;
  margin: auto;
  display: block;
}

.Article--descriptions {
  max-height: 100px;
  overflow: hidden;
}

.Article-moreInfoOpened .Article--descriptions {
  max-height: none;
}

.Article--termMaintain {
  font-size: 14px;
  font-family: 'DDinRegular', sans-serif;
  line-height: 24px;
}

.Article--description {
}

.Article--descriptionLabel {
  display: flex;
}

.Article--descriptionLabel .Article--ref {
  margin-left: 5px;
  font-size: 10px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 4px 8px;
}

.Article--description ::v-deep .Term {
  font-size: 14px;
  font-family: 'DDinRegular', sans-serif;
  line-height: 24px;
}

.Article--description ::v-deep .InputLabel * {
  line-height: 14px;
  height: 14px;
}

.Article-mobile .Article--description {
  text-align: center;
}

.Article-mobile .Article--description ::v-deep .Term * {
  text-align: center;
}

.Article--moreInfoButton {
  font-size: 10px;
  font-family: 'RalewayBold', sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  flex: 1;
  width: 100%;
  height: 40px;
  display: flex;
  cursor: pointer;
  justify-content: center;
  align-items: center;
  border-radius: 50px;
  background: white;
}

.Article--moreInfoButton ::v-deep p {
  text-decoration: underline;
  color: $primary;
}

.Article--moreInfoButtonIcon {
  width: 24px;
  height: 24px;
  padding-left: 10px;
}

.Article--moreInfoButtonIcon ::v-deep .Vector {
  fill: $primary;
}

.Article--label {
  font-size: 12px;
  font-family: 'RalewayBold', sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
}

.Article--labelPictures {
  display: flex;
  flex-wrap: wrap;
}

.Article--labelPicture {
  padding-right: 20px;
  padding-bottom: 20px;
}

.Article-desktop .Article--labelPicture {
  height: 80px;
  width: 80px;
}

.Article-tablet .Article--labelPicture {
  height: 50px;
  width: 50px;
}

.Article-mobile .Article--labelPicture {
  height: 50px;
  width: 50px;
}

.Article--labelPicture img {
  height: 100%;
}

.Article--gencod {
  font-family: 'ComfortaaRegular', sans-serif;
  color: rgba(0, 0, 0, 0.5);
  font-size: 12px;
}

/* FOOTER, BARCODE, PRODUCT FILE */

.Article--footer {
  display: flex;
  padding-top: 50px;
  padding-bottom: 50px;
  width: 100%;
}

.Article--footerSpace {
  width: 20px;
  height: 20px;
  min-width: 20px;
  min-height: 20px;
}

.Article-mobile .Article--footer {
  display: flex;
  flex-direction: column;
}

.Article--barcode {
  height: 100px;
  max-height: 100px;
  min-height: 100px;
  flex: 1;
  border: solid 2px rgba(0, 0, 0, 0.05);
  padding: 10px;
  border-radius: 5px;
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  position: relative;
}

.Article--barcode ::v-deep .Term {
  position: absolute;
  bottom: -7px;
  left: 0;
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  text-transform: uppercase;
  font-size: 12px;
  font-family: 'DDinRegular';
}

.Article--barcode ::v-deep .Term--text {
  background: #ffffff;
  padding: 0 20px;
}

.Article--productFile {
  cursor: pointer;
  flex: 1;
  height: 100px;
  max-height: 100px;
  min-height: 100px;
  box-sizing: border-box;
  border: solid 2px $primary;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  opacity: 1;
  transition: opacity 500ms;
}

.Article--productFile:hover {
  opacity: 0.5;
}

.Article--productFileDownload {
  display: flex;
  justify-content: center;
  align-items: center;
}

.Article--productFileDownload ::v-deep .Vector {
  width: 24px;
  height: 24px;
  margin-left: 20px;
  fill: $primary;
}

.Article--productFileDownload ::v-deep .Term {
  color: $primary;
  font-family: 'RalewayBold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 2px;
  font-size: 12px;
}

.Article--productFileTitle {
  position: absolute;
  bottom: -7px;
  left: 0;
  width: 100%;
  text-align: center;
  color: rgba(0, 0, 0, 1);
  text-transform: uppercase;
  font-size: 12px;
  font-family: 'DDinRegular';
}

.Article--productFileTitle ::v-deep .Term--text {
  background: #ffffff;
  padding: 0 20px;
}

/* PACKAGE */

.Article--packageCount {
  font-family: 'ComfortaaRegular', sans-serif;
  margin-left: 10px;
  color: rgba(0, 0, 0, 0.5);
}

.Article--packages {
}


.Article--package {
  display: flex;
  margin-bottom: 17px;
  align-items: center;
  flex-wrap: wrap;
}

.Article--packageVectorBox {
  width: 46px;
  height: 46px;
  min-width: 46px;
  min-height: 46px;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 3px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 20px;
  margin-top: 10px;
  margin-bottom: 10px;
}

.Article-mobile .Article--packageVectorBox {
  display: none;
}

.Article--packageVector {
  width: 24px;
  height: 24px;
}

.Article--packageVector ::v-deep .Vector {
  fill: $primary;
}

.Article--packageSize {
  color: black;
  font-size: 9px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
}

.Article--packageSizeRow {
  display: flex;
  align-items: center;
}

.Article--packageSize span {
  display: inline-block;
  margin-right: 10px;
  margin-top: 5px;
  margin-bottom: 5px;
}

.Article--packageSizeNumber {
  width: 30px;
  text-align: center;
  padding: 4px 0;
  border-radius: 3px;
  font-size: 10px;
  border: solid 1px rgba(0, 0, 0, 0.1);
  font-family: 'ComfortaaRegular', sans-serif;
}

.Article--packageSizeUnit {
  color: $primary;
}

/*  */

.Article--details {
  display: flex;
  flex-wrap: wrap;
}

.Article--detail {
  background: $primaryLight;
  border-radius: 50px;
  padding: 8px 16px;
  color: #000000;
  font-size: 10px;
  text-transform: uppercase;
  margin: 0 17px 17px 0;
}

.Article--materials {
  display: flex;
  flex-wrap: wrap;
  padding: 0 0px 17px 0;
}

.Article--material {
  padding: 0 17px 17px 0;
}

.Article--moreInfos {
  transform: translateY(-34px);
  transition: transform 500ms;
}

.Article-moreInfoOpened .Article--moreInfos {
  transform: translateY(0px);
}

.Article--moreInfoGradiant {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 1) 100%);
  height: 34px;
}

.Article--moreInfo {
  background: white;
}

.Article--moreInfoName {
  font-family: 'AmbroiseRegular', sans-serif;
  color: #4B4B4B;
}

.Article--moreInfoName ::v-deep .Term {
  width: 100%;
}

.Article--moreInfoName .Article--ref {
  padding-top: 10px;
}

.Article-desktop .Article--moreInfoName {
  font-size: 30px;
}

.Article-tablet .Article--moreInfoName {
  font-size: 30px;
}

.Article-mobile .Article--moreInfoName {
  font-size: 20px;
}

.Article-mobile .Article--moreInfoName ::v-deep .Term {
  text-align: center;
}

.Article--moreInfo li {
  list-style: initial !important;
  padding-top: 5px;
}

.Article--moreInfo ul {
  margin: initial !important;
  padding-left: 25px !important;
}

.Article--moreInfo li ::v-deep .Term p {
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
}

.Article--moreInfo li ::v-deep .Term p:first-letter {
  text-transform: uppercase;
}

.Article--moreInfoOpened {

}


.Article--dimension {
  display: flex;
  flex-wrap: wrap;
}

.Article--dimensionBlock {
  padding-bottom: 17px;
}

.Article-desktop .Article--dimensionBlock {
  padding-right: 25px;
}

.Article-tablet .Article--dimensionBlock {
  padding-right: 25px;
}

.Article-mobile .Article--dimensionBlock {
  padding-right: 15px;
}

.Article--dimensionBlock-noPadding {
  padding-right: 0px !important;
}

/**/

.Article--dimensionNumber {
  border: solid 1px rgba(0, 0, 0, 0.1);
  padding: 10px 25px;
  font-family: 'ComfortaaRegular', sans-serif;
  font-size: 11px;
  border-radius: 4px;
  display: flex;
}

.Article--dimensionNumber span {
  display: flex;
  padding-left: 4px;
}

.Article-mobile .Article--dimensionNumber {
  font-size: 11px;
}

.Article--dimensionLabel {
  padding-top: 10px;
  font-size: 8px;
  font-family: 'RalewayBold', sans-serif;
  letter-spacing: 1px;
  text-transform: uppercase;
  text-align: center;
  padding-left: 4px;
}

.Article-mobile .Article--dimensionLabel {
  text-align: center;
}

.Article--articleRecommendation {
}

.Article--articleRecommendation1 {
  min-height: 432px;
}

.Article--articleRecommendation ::v-deep .ArticleRecommendation--next {
  background: $primary;
}

.Article--articleRecommendation ::v-deep .CatalogueArticle--footerRight {
  display: none;
}

.Article--articleRecommendation ::v-deep .ArticleRecommendation--article {
  margin-right: 20px;
}

.Article-mobile .Article--articleRecommendation ::v-deep .ArticleRecommendation--article {
  width: calc(100% - 115px);
  min-width: calc(100% - 115px);
}

.Article-desktop .Article--articleRecommendation {
  padding-left: 100px;
  padding-right: 100px;
}

.Article-tablet .Article--articleRecommendation {
  padding-left: 50px;
  padding-right: 50px;
}

.Article-mobile .Article--articleRecommendation {
  padding-left: 20px;
  //padding-right: 25px;
}

.Article-mobile ::v-deep .ArticleRecommendation--headerRightDesktop {
  margin-right: 20px;
}

.Article--catalogueCollection {

}

.Article-desktop .Article--catalogueCollection {
  padding-top: 50px;
  padding-left: 100px;
  padding-right: 100px;
  padding-bottom: 0px;
}

.Article-tablet .Article--catalogueCollection {
  padding: 50px;
  padding-bottom: 0px;
}

.Article-mobile .Article--catalogueCollection {
  padding-top: 25px;
}

.Article--mobileApp {
  padding-top: 100px;
  position: relative;
}

.Article-tablet .Article--mobileApp {
  padding-top: 50px;
}

.Article-mobile .Article--mobileApp {
  padding-top: 25px;
}
</style>

<template>
  <div class="Article" :class="class_root" ref="root"
       :id="state.id">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="onBreakPointChange"
                :onVerticalBreakPointChange="onVerticalBreakPointChange">
      <div class="Article--loaderSpace" v-if="nuxtProp.loading">
        <LoaderSpace></LoaderSpace>
      </div>
      <div class="Article--notVisible" v-if="!state.article.visible && state.article.id">
        <h2>
          <Term :term="lang.term(396)"/>
        </h2>
      </div>
      <div class="Article--body" v-if="state.article.visible && state.article.id && !nuxtProp.loading">
        <div class="Article--logo">
          <Link :pathIds="[PATHS.HOME]">
            <Vector :svg="icon.logo"></Vector>
          </Link>
        </div>
        <div class="Article--core">

          <div class="Article--header">

            <div class="Article--previous" @click="() => onPrevious()">
              <div class="Article--previousContent">
                <div class="Article--previousIcon">
                  <Vector :svg="icon.west"></Vector>
                </div>
                <div class="Article--previousTerm">
                  <Term :term="lang.term(239)"/>
                </div>
              </div>
            </div>

            <div class="Article--ariane">
              <Ariane :pages="pages"
                      :onlyTheLast="props.Responsive['0'].breakPoint.name === SCREEN.MOBILE">
              </Ariane>
            </div>
          </div>


          <div class="Article--mask" @click="() => close()"></div>

          <div class="Article--bodyRow">
            <div class="Article--content">

              <div class="Article--photos">

                <Visible :onVisibilityChanged="({isVisible}) => photosIsVisible(isVisible)">

                  <div class="Article--corner">
                    <div class="Article--cornerCircle">

                    </div>
                  </div>

                  <!-- ARTICLE -->

                  <div class="Article--photo" :class="class_photo(photo)" v-for="photo in articlePhotos" :key="photo.id">
                    <template v-for="file in photo.files">
                      <Picture v-if="PICTURE_DISPLAY.AUTO === pictureDisplay(photo)" :file="file"
                               :display="pictureDisplay(photo)"/>
                      <Picture v-if="PICTURE_DISPLAY.COVER === pictureDisplay(photo)" :file="file"
                               display="native"/>
                    </template>
                  </div>

                  <!-- PRODUCT -->

                  <div class="Article--photo" v-if="isProduct" :class="class_photo(photo)"
                       v-for="(photo, photo_index) in productPhotos" :key="photo.id">
                    <template v-for="file in photo.files">
                      <Picture v-if="PICTURE_DISPLAY.AUTO === pictureDisplay(photo)" :file="file"
                               :display="pictureDisplay(photo)"/>
                      <Picture v-if="PICTURE_DISPLAY.COVER === pictureDisplay(photo)" :file="file"
                               display="native"/>
                    </template>

                  </div>

                  <!-- SET -->

                  <template v-for="batch in state.article.batches" v-if="isSet">
                    <template v-for="product in batch.products">
                      <div class="Article--photo" :class="class_photo(photo)"
                           v-for="(photo, photo_index) in sortPhotos(product.photos)" :key="photo.id"
                           v-if="(hasCover && photo.cover) || photo_index === 0">
                        <template v-for="file in photo.files">
                          <Picture v-if="PICTURE_DISPLAY.AUTO === pictureDisplay(photo)" :file="file"
                                   :display="pictureDisplay(photo)"/>
                          <Picture v-if="PICTURE_DISPLAY.COVER === pictureDisplay(photo)" :file="file"
                                   display="native"/>
                        </template>
                        <div class="Article--photoCount" v-if="batch.quantity > 1">x{{
                            batch.quantity
                          }}
                        </div>
                      </div>
                    </template>
                  </template>

                  <!-- BATCH -->

                  <template v-for="batch in state.article.batches" v-if="isBatch">
                    <template v-for="product in batch.products">
                      <div class="Article--photo" :class="class_photo(photo)"
                           v-for="(photo) in sortPhotos(product.photos)"
                           :key="photo.id">
                        <template v-for="file in photo.files">
                          <Picture v-if="PICTURE_DISPLAY.AUTO === pictureDisplay(photo)" :file="file"
                                   :display="pictureDisplay(photo)"/>
                          <Picture v-if="PICTURE_DISPLAY.COVER === pictureDisplay(photo)" :file="file"
                                   display="native"/>
                        </template>
                        <div class="Article--photoCount" v-if="photo.cover && batch.quantity > 1">x{{
                            batch.quantity
                          }}
                        </div>
                      </div>
                    </template>
                  </template>

                </Visible>
              </div>
            </div>
            <div class="Article--infoBox" :style="style_infoBox">
              <ScrollFixe :registerCallback="registerCallback"
                          :activated="props.Responsive['0'].breakPoint.name !== SCREEN.MOBILE">
                <CountryLocation>
                  <template slot="default">
                    <div class="Article--top">
                      <ButtonAction v-if="!state.opened" :minWidth="false" :term="lang.term(234)"
                                    :svg="icon.shopping_basket" :onClick="() => add()">
                    <span class="Article--topPrice">{{
                        calculatePromotion(calculateTTC(state.article.price), percentage)
                      }} €
                    </span>
                      </ButtonAction>
                      <div @click="() => toggle()" v-if="state.opened" class="Article--topOption">
                        <div class="Article--topIcon">
                          <Vector :svg="icon.expand_more"></Vector>
                        </div>
                        <div class="Article--topText">
                          <Term :key="0" :term="lang.term(445)"/>
                        </div>
                      </div>
                      <div @click="() => toggle()" v-else-if="!state.opened"
                           class="Article--topOption Article--topOption-brown">
                        <div class="Article--topText">
                          <Term :key="1" :term="lang.term(446)"/>
                        </div>
                      </div>
                    </div>
                  </template>
                  <template slot="alternate">
                    <div class="Article--top">
                      <div @click="() => toggle()" v-if="state.opened" class="Article--topOption">
                        <div class="Article--topIcon">
                          <Vector :svg="icon.expand_more"></Vector>
                        </div>
                        <div class="Article--topText">
                          <Term :key="0" :term="lang.term(447)"/>
                        </div>
                      </div>
                      <div @click="() => toggle()" v-else-if="!state.opened" class="Article--topOption">
                        <div class="Article--topIcon">
                          <Vector :svg="icon.expand_less"></Vector>
                        </div>
                        <div class="Article--topText">
                          <Term :key="1" :term="lang.term(448)"/>
                        </div>
                      </div>
                    </div>
                  </template>
                </CountryLocation>
                <div class="Article--info">
                  <div class="Article--infoContent">

                    <div class="Article--row">

                      <div class="Article--ref">
                        <div class="Article--new">
                          <ArticleNew :article="state.article"></ArticleNew>
                        </div>
                        <span>#REF&nbsp;</span>
                        <template v-for="(batch, index_batch) in state.article.batches">
                          <template v-for="product in batch.products">
                            <span v-if="index_batch">+</span>
                            <span>{{ product.reference }}</span>
                          </template>
                        </template>
                      </div>

                      <CountryLocation>
                        <div class="Article--unitPrice"
                             v-if="state.article.isBatch && state.article.batches.length === 1">
                          <b>prix unitaire</b>
                          <template v-for="batch in state.article.batches">
                        <span>{{
                            formatPrice(calculatePromotion(calculateTTC(state.article.price), percentage) / batch.quantity)
                          }} €
                        </span>
                          </template>
                        </div>
                      </CountryLocation>

                    </div>


                    <div class="Article--evolve" v-for="evolveAncestor in state.article.evolveAncestors">
                      <br>
                      <template v-for="articleDescendant in evolveAncestor.articleDescendants">
                        <template v-for="page in articleDescendant.pages">
                          <Link :pathIds="page.paths.map(p => p.id)" :key="page.id">
                            <Tag type="warning">
                              <Term :term="lang.term(449)"></Term>
                            </Tag>
                          </Link>
                        </template>
                      </template>
                    </div>


                    <br>
                    <div class="Article--name" v-for="termName in findDefaultArticleTermNames" :key="termName.id">
                      <Term :term="termName" tag="h1"/>
                    </div>
                    <br>

                    <template v-for="batch in state.article.batches" v-if="isBatch || isProduct">
                      <template v-for="product in batch.products" v-if="product.similarUps.length">
                        <div class="Article--similar">
                          <ArticleSimilar :article="state.article"></ArticleSimilar>
                          <br>
                        </div>
                      </template>
                    </template>

                    <CountryLocation>
                      <div v-show="isOutlet">
                        <div class="Article--outlet">
                          <Outlet :percentage="percentage" :originalPrice="calculateTTC(state.article.price)"/>
                        </div>
                      </div>

                      <br>
                      <div class="Article--payment">
                        <div class="Article--paymentLeft">
                          <div class="Article--price">
                            <Price :amount="calculateTTC(state.article.price)"
                                   :outletAmount="calculatePromotion(calculateTTC(state.article.price), percentage)"
                                   :withSmallPrice="false"
                                   :isOutlet="isOutlet">
                            </Price>
                          </div>
                          <div class="Article--ecoParticipation" v-if="calculateTTC(state.article.ecoParticipation)">
                            <!--                        <Term :term="lang.term(235)"/>-->
                            <div class="Article--ecoParticipationAmount">
                              {{ formatPrice(calculateTTC(state.article.ecoParticipation)) }}€
                            </div>
                            <Term :term="lang.term(236)"/>
                          </div>
                        </div>
                        <div class="Article--divider"
                             v-if="calculatePromotion(calculateTTC(state.article.price), percentage) > PAYMENT.MIN_FOR_MULTIPLE"
                             v-show="!isSeller">
                        </div>
                        <div class="Article--paymentRight"
                             v-if="calculatePromotion(calculateTTC(state.article.price), percentage) > PAYMENT.MIN_FOR_MULTIPLE"
                             v-show="!isSeller">
                          <div class="Article--paymentMultiple">
                            <Term :term="lang.term(246)"/>
                            <span>&nbsp;3 x</span>
                            <span class="Article--paymentMultipleRed">
                          {{ formatPrice(calculatePromotion(calculateTTC(state.article.price), percentage) / 3) }} €
                        </span>
                          </div>
                          <div class="Article--paymentMultipleInfo">
                            <Term :term="lang.term(245)"/>
                          </div>
                        </div>
                      </div>


                      <br>
                      <br>
                    </CountryLocation>

                    <CountryLocation :displayAnyway="[ROLE.ROOT, ROLE.ADMIN, ROLE.CHR, ROLE.SELLER].includes(state.roleId)">
                      <div class="Article--quantity">
                        <CountryLocation>
                          <el-input-number :value="state.quantity" @change="v => state.quantity = v" :min="1" :max="getArticleStockBrowser"/>
                        </CountryLocation>
                        <Tooltip v-if="canShowDetailStock" position="top" :text="lang.translateTerm(lang.term(450))">
                          <div class="Article--stock" @click="() => state.openedStockDetail = true">
                            <Stock :value="getArticleStockBrowser"/>
                            <div class="Article--stockIcon">
                              <Vector :svg="icon.archive"></Vector>
                            </div>
                          </div>
                        </Tooltip>
                        <div v-else class="Article--stock">
                          <Stock :value="getArticleStockBrowser"/>
                        </div>
                      </div>

                      <br>
                      <br v-show="!getArticleStockBrowser && isAdminOrSellerOrCHR">
                      <div class="Article--infoStockMessage" v-show="!getArticleStockBrowser && isAdminOrSellerOrCHR && getArticleStockSoon">
                        <div class="Article--stockSoon">
                          <span>{{ getArticleStockSoon }}&nbsp;</span>
                          <Term :term="lang.term(409)"/>
                          <span v-if="getArticleStockSoonDeliveredAt">&ensp;{{ getArticleStockSoonDeliveredAt }}</span>
                        </div>
                      </div>
                      <br v-show="!getArticleStockBrowser && isAdminOrSellerOrCHR">
                      <div class="Article--infoStockMessage" v-show="!getArticleStockBrowser && isAdminOrSellerOrCHR">
                        <div class="Article--stockInfo">
                          <Term :term="lang.term(406)"></Term>
                        </div>
                      </div>
                      <br v-show="!getArticleStockBrowser && isAdminOrSellerOrCHR">
                      <br>
                    </CountryLocation>

                    <div class="Article--button">
                      <CountryLocation>
                        <div class="Article--buttonAddBasket">
                          <ButtonAction :minWidth="false"
                                        :term="lang.term(234)"
                                        :svg="icon.shopping_basket"
                                        :onClick="() => add()"></ButtonAction>
                          <div class="Article--buttonDivider"></div>
                        </div>
                      </CountryLocation>
                      <template v-if="!isSeller">
                        <div class="Article--findSeller">
                          <!--                                          <div class="Article&#45;&#45;findSellerVector">-->
                          <!--                                            <Vector :svg="icon.location_on"></Vector>-->
                          <!--                                          </div>-->
                          <!--                                          <div class="Article&#45;&#45;findSellerTerm">-->
                          <!--                                            <Term :term="lang.term(238)"></Term>-->
                          <!--                                          </div>-->
                          <ButtonLink :minWidth="false" :term="lang.term(451)"
                                      :svg="icon.location_on" :onClick="() => state.openedSeller = true"></ButtonLink>
                        </div>
                      </template>
                    </div>
                    <br>
                    <br>


                    <!--                <br>-->
                    <!--                <Notation></Notation>-->
                    <!--                <br>-->

                    <template v-for="(batch, index) in state.article.batches" v-if="index === 0">
                      <template v-for="product in batch.products">
                        <template v-for="category in product.categories" v-if="CATEGORY.LUMINAIRE === category.id">
                          <template v-for="lumen in product.lumens">
                            <br>
                            <img class="Article--lumen" v-if="lumen.value === 300" :src="img.lumne_300" alt="lumen">
                            <img class="Article--lumen" v-if="lumen.value === 500" :src="img.lumne_500" alt="lumen">
                            <br>
                            <br>
                          </template>
                        </template>
                      </template>
                    </template>


                    <div class="Article--descriptions">
                      <div v-for="termDescription in state.article.termDescriptions" :key="termDescription.id">
                        <div class="Article--label">
                          <InputLabel :term="lang.term(237)"/>
                        </div>
                        <template v-if="lang.translateTerm(termDescription).trim()">
                          <br>
                          <div class="Article--description">
                            <Term :term="termDescription" type="textarea"/>
                          </div>
                        </template>
                      </div>
                      <template v-for="batch in state.article.batches">
                        <template v-for="product in batch.products">
                          <template v-for="termDescription in product.termDescriptions"
                                    v-if="!lang.translateTerm(state.article.termDescriptions[0]).trim()">
                            <br>
                            <div class="Article--description" :key="termDescription.id">
                              <Term :term="termDescription" type="textarea"/>
                            </div>
                          </template>
                        </template>
                      </template>
                    </div>

                    <div class="Article--moreInfos">
                      <template v-if="!isMobile">
                        <div class="Article--moreInfoGradiant"></div>
                        <div class="Article--moreInfoButton" @click="() => toggleMoreInfo()">
                          <Term :key="0" v-if="moreInfoOpened" :term="lang.term(452)"/>
                          <Term :key="1" v-if="!moreInfoOpened" :term="lang.term(453)"/>
                          <div class="Article--moreInfoButtonIcon">
                            <Vector :key="0" v-if="moreInfoOpened" :svg="icon.keyboard_arrow_up"></Vector>
                            <Vector :key="1" v-if="!moreInfoOpened" :svg="icon.keyboard_arrow_down"></Vector>
                          </div>
                        </div>
                      </template>
                      <br>
                      <div class="Article--moreInfo" v-show="moreInfoOpened">
                        <br>
                        <template v-for="(batch, index_batch) in state.article.batches">
                          <template v-for="product in batch.products">

                            <!-- NAME -->
                            <div class="Article--moreInfoName" v-if="isSet && product.termNames.length">
                              <template v-for="termName in product.termNames">
                                <Term :term="termName"/>
                              </template>
                              <br>
                              <div class="Article--ref">
                                {{ product.reference }} x {{ batch.quantity }}
                              </div>
                              <br>
                            </div>

                            <!-- LABELS -->
                            <template v-if="product.photoLabels.length">
                              <div class="Article--label">
                                <InputLabel :term="lang.term(602)"/>
                              </div>
                              <br>
                              <div class="Article--labelPictures">
                                <template v-for="photoLabel in product.photoLabels">
                                  <template v-for="file in photoLabel.files">
                                    <div class="Article--labelPicture">
                                      <Picture :display="PICTURE_DISPLAY.AUTO" :key="`${photoLabel.id}.${file.id}`"
                                               :file="file"></Picture>
                                    </div>
                                  </template>
                                </template>
                              </div>
                              <br>
                              <br>
                            </template>

                            <!-- DESCRIPTION -->
                            <template v-if="isSet">
                              <div class="Article--label">
                                <InputLabel :term="lang.term(237)"/>
                              </div>
                              <br>
                              <div class="Article--description" v-for="termDescription in product.termDescriptions"
                                   :key="termDescription.id">
                                <Term :term="termDescription" type="textarea"/>
                              </div>
                              <br>
                              <br>
                            </template>


                            <!-- DIMENSSION -->
                            <template
                                v-if="(calculateDimension(product, 'width').join('-') && calculateDimension(product, 'width').join('-') != 0) || (calculateDimension(product, 'length').join('-') && calculateDimension(product, 'length').join('-') != 0) || (calculateDimension(product, 'height').join('-') && calculateDimension(product, 'height').join('-') != 0)">
                              <div class="Article--label">
                                <Term :term="lang.term(398)"/>
                              </div>
                              <br>
                              <div class="Article--dimension">

                                <div class="Article--dimensionBlock"
                                     v-if="calculateDimension(product, 'length').join('-') && calculateDimension(product, 'length').join('-') != 0">
                                  <div class="Article--dimensionNumber">
                                    {{ calculateDimension(product, 'length').join('-') }} cm
                                  </div>
                                  <div class="Article--dimensionLabel">
                                    <Term :term="lang.term(400)"/>
                                  </div>
                                </div>

                                <div class="Article--dimensionBlock"
                                     v-if="calculateDimension(product, 'width').join('-') && calculateDimension(product, 'width').join('-') != 0">
                                  <div class="Article--dimensionNumber">
                                    {{ calculateDimension(product, 'width').join('-') }} cm
                                  </div>
                                  <div class="Article--dimensionLabel">
                                    <Term :term="lang.term(399)"/>
                                  </div>
                                </div>

                                <div class="Article--dimensionBlock"
                                     v-if="calculateDimension(product, 'height').join('-') && calculateDimension(product, 'height').join('-') != 0">
                                  <div class="Article--dimensionNumber">
                                    {{ calculateDimension(product, 'height').join('-') }} cm
                                  </div>
                                  <div class="Article--dimensionLabel">
                                    <Term :term="lang.term(401)"/>
                                  </div>
                                </div>

                                <div class="Article--dimensionBlock Article--dimensionBlock-noPadding"
                                     v-if="calculateDimension(product, 'weight').join('-') && calculateDimension(product, 'weight').join('-') != 0">
                                  <div class="Article--dimensionNumber">
                                    {{ calculateDimension(product, 'weight').join('-') }} kg
                                  </div>
                                  <div class="Article--dimensionLabel">
                                    <Term :term="lang.term(454)"/>
                                  </div>
                                </div>
                              </div>
                              <br>
                            </template>

                            <!-- COLIS -->
                            <template v-if="isAdminOrSellerOrCHR">
                              <div class="Article--label">
                                <Term :term="lang.term(457)"/>
                                <span class="Article--packageCount"
                                      v-if="(product.packages.length * batch.quantity) > 1">x{{
                                    product.packages.length * batch.quantity
                                  }}</span>
                              </div>
                              <br>
                              <div class="Article--packages">
                                <template v-for="package_ in product.packages">
                                  <template v-for="size in package_.sizes">
                                    <div class="Article--package">
                                      <div class="Article--packageVectorBox">
                                        <div class="Article--packageVector">
                                          <Vector :svg="icon.unarchive"></Vector>
                                        </div>
                                      </div>
                                      <div class="Article--packageSize">
                                        <div class="Article--packageSizeRow">
                                          <div v-if="size.weight">
                                            <span>{{ lang.translate(460) }}</span>
                                            <span class="Article--packageSizeNumber">{{ size.weight }}</span>
                                            <span class="Article--packageSizeUnit">kg</span>
                                          </div>
                                          <div v-if="size.length">
                                            <span>{{ lang.translate(461) }}</span>
                                            <span class="Article--packageSizeNumber">{{ size.length }}</span>
                                            <span class="Article--packageSizeUnit">cm</span>
                                          </div>
                                        </div>
                                        <div class="Article--packageSizeRow">
                                          <div v-if="size.width">
                                            <span>{{ lang.translate(462) }}</span>
                                            <span class="Article--packageSizeNumber">{{ size.width }}</span>
                                            <span class="Article--packageSizeUnit">cm</span>
                                          </div>
                                          <div v-if="size.height">
                                            <span>{{ lang.translate(463) }}</span>
                                            <span class="Article--packageSizeNumber">{{ size.height }}</span>
                                            <span class="Article--packageSizeUnit">cm</span>
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                  </template>
                                </template>
                              </div>
                            </template>

                            <!-- PLACE -->
                            <template v-if="calculateSittingPlace(product).length">
                              <br>
                              <div class="Article--label">
                                <Term :term="lang.term(402)"/>
                              </div>
                              <br>
                              <div class="Article--dimension">
                                <div class="Article--dimensionBlock">
                                  <div class="Article--dimensionNumber">
                                    {{ calculateSittingPlace(product).join('-') }}
                                    <span>
                                  <Term :term="lang.term(403)"/>
                                  {{ calculateSittingPlace(product)[0] > 1 ? 's' : '' }}
                                </span>
                                  </div>
                                </div>
                              </div>
                              <br>
                              <br>
                            </template>

                            <!-- MATIERE -->
                            <template v-if="product.materials.length">
                              <div class="Article--label">
                                <Term :term="lang.term(404)"/>
                              </div>
                              <br>
                              <div class="Article--materials">
                                <template v-for="material in product.materials">
                                  <div class="Article--material">
                                    <MaterialArticle :material="material"></MaterialArticle>
                                  </div>
                                </template>
                              </div>
                            </template>

                            <!-- ENTRETIEN -->
                            <div class="Article--termMaintains" v-for="termMaintain in product.termMaintains"
                                 v-if="lang.translateTerm(termMaintain)" :key="termMaintain.id">
                              <div class="Article--label">
                                <Term :term="lang.term(456)"/>
                              </div>
                              <br>
                              <div class="Article--termMaintain">
                                <Term :term="termMaintain" type="textarea"/>
                              </div>
                              <br>
                              <br>
                            </div>

                            <!-- DETAILS -->
                            <template v-if="product.details.length">
                              <div class="Article--label">
                                <Term :term="lang.term(455)"/>
                              </div>
                              <br>
                              <div class="Article--details">
                                <template v-for="detail in product.details">
                                  <div class="Article--detail" v-for="termValue in detail.termValues"
                                       :key="termValue.id">
                                    <Term :term="termValue"/>
                                  </div>
                                </template>
                              </div>
                              <br>
                            </template>

                            <!-- LES PLUS PRODUITS -->
                            <template v-if="product.highlights.length">
                              <div class="Article--label">
                                <Term :term="lang.term(405)"/>
                              </div>
                              <br>
                              <ul>
                                <template v-for="highlight in product.highlights">
                                  <li v-for="termValue in highlight.termValues" :key="termValue.id">
                                    <Term :term="termValue"/>
                                  </li>
                                </template>
                              </ul>
                              <br>
                              <br>
                            </template>

                            <!-- GENCOD -->
                            <template v-if="isAdminOrSellerOrCHR">
                              <div class="Article--label">
                                <Term :term="lang.term(458)"/>
                              </div>
                              <br>
                              <div class="Article--gencod">
                                {{ product.gencod }}
                              </div>
                            </template>


                            <div class="Article--footer" v-if="isAdminOrSellerOrCHR">

                              <!-- BARCODE -->
                              <div class="Article--barcode" v-if="product.photoBarCodes.length">
                                <template v-for="photoBarCode in product.photoBarCodes">
                                  <template v-for="file in photoBarCode.files">
                                    <Picture :display="PICTURE_DISPLAY.AUTO" :key="`${photoBarCode.id}.${file.id}`"
                                             :file="file"></Picture>
                                  </template>
                                </template>
                                <Term :term="lang.term(603)"></Term>
                              </div>

                              <div class="Article--footerSpace" v-if="product.photoBarCodes.length"></div>

                              <!-- DOWNLOAD PRODCUT FILE -->
                              <div class="Article--productFile" @click="() => onDownloadForm(product.id)">
                                <div class="Article--productFileDownload">
                                  <template v-if="state.loadingDownloadForm">
                                    <Loader></Loader>
                                  </template>
                                  <template v-else>
                                    <Term :term="lang.term(604)"></Term>
                                    <Vector :svg="icon.picture_as_pdf"></Vector>
                                  </template>
                                </div>
                                <div class="Article--productFileTitle">
                                  <Term :term="lang.term(605)"></Term>
                                </div>
                              </div>
                            </div>


                            <template v-if="index_batch + 1 !== state.article.batches.length">
                              <Divider/>
                              <br>
                            </template>
                          </template>
                        </template>
                      </div>
                    </div>
                  </div>
                </div>
              </ScrollFixe>
            </div>
          </div>

        </div>
      </div>

      <div class="Article--articleRecommendation" v-if="allCollectionIds.length">
        <Visible :onVisibilityChanged="({isVisible}) => state.articleRecommendationIsVisible = isVisible">
          <div class="Article--articleRecommendation1">
            <ArticleRecommendation
                v-if="state.articleRecommendationIsVisible"
                :articleIds="state.articleIds"
                :fetch="fetchArticleAsync">
            </ArticleRecommendation>
          </div>
        </Visible>
      </div>

      <div class="Article--catalogueCollection" v-show="state.collectionDescription">
        <CatalogueCollection :collection="uniqCollection"
                             :onDescription="v => state.collectionDescription = v"
                             :withDownloadPDF="false"
                             :withMaterials="false"
                             :withLinkShowMore="true"
                             :termShowMore="lang.term(459)"></CatalogueCollection>
      </div>

      <div class="Article--mobileApp">
        <!--        <MobileApp-->
        <!--            :backgroundColor="state.collectionDescription && props.Responsive['0'].breakPoint.name !== SCREEN.MOBILE ? '#ffffff' : undefined"></MobileApp>-->
      </div>

      <Modal :opened="state.openedSeller" :onOpenedChange="v => state.openedSeller = v">
        <FindShop :activated="state.openedSeller"></FindShop>
      </Modal>
      <Modal :opened="state.openedStockDetail" :onOpenedChange="v => state.openedStockDetail = v">
        <ArticleStockDetail :article="state.article"></ArticleStockDetail>
      </Modal>
    </Responsive>
  </div>
</template>

<script>

import uniq from "lodash/uniq";
import moment from "moment";

import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import Scrollbar from '@bbx/scrollbar~master/core/env/browser/ts/Scrollbar';
import {deltaService} from "@bbx/delta~master/core/service/deltaService";
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import Id from "@bbx/id~master/core/ts/Id";

import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {eventService} from "../../service/eventService";
import {EVENT} from "../../../@common/constant/EVENT";
import {Article} from "../../../@common/delta/database/Article";
import {Photo} from "../../../@common/delta/database/Photo";
import {PICTURE_DISPLAY} from "../../../@common/constant/PICTURE_DISPLAY";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {calculateTTC} from "../../../@common/function/calculateTTC";
import {calculateHT} from "../../../@common/function/calculateHT";
import {calculatePromotion} from "../../../@common/function/calculatePromotion";
import {formatPrice} from "../../../@common/function/formatPrice";
import {PATHS} from "../../../@common/constant/PATHS";
import {Page} from "../../../@common/delta/database/Page";
import {findDefaultArticleTermNames} from "../../function/findDefaultArticleTermNames";
import {convertPathToUrl} from "../../../@common/function/helperUrl";
import {waitElement} from "../../../@common/function/waitElement";
import {CATEGORY} from "../../../@common/constant/CATEGORY";
import {PAYMENT} from "../../../@common/constant/PAYMENT";
import {getArticleStock} from "../../../@common/function/getArticleStock";
import {scrollerParentService} from "../../service/scrollerParentService";
import {ROLE} from "../../../@common/constant/ROLE";
import {STATE_ROLE_ID, STATE_USER_ID, stateService} from "../../service/stateService";
import {getArianeArticlePages} from "../../../@common/function/getArianeArticlePages";
import {apiClient} from "../../service/apiClientService";
import selectEvolve from "../../../@common/api/evolve/selectEvolve";
import {getArticleStockBrowser} from "../../function/getArticleStockBrowwser";
import {urlService} from "../../../@common/service/urlService";
import {Url} from "../../../@common/delta/database/Url";
import {canDisplay} from "../../service/countryService";
import {sendError} from "../../function/sendError";
import {getArticleStockDeliveredAt} from "../../../@common/function/getArticleStockDeliveredAt";
import {Collection} from "../../../@common/delta/database/Collection";
import selectArticles from "../../../@common/api/article/selectArticles";
import customProductPDF from "../../../@common/api/product/customProductPDF";
import {documentMakerConfigPublic} from "../../../../config/public/document-maker.config";
import {SORT} from "../../../@common/constant/SORT";

const id = new Id()

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  async fetch() {
    this.state.id = `_${id.uniq()}`
  },
  data() {
    return {
      SCREEN,
      PAYMENT,
      CATEGORY,
      ROLE,
      PATHS,
      PICTURE_DISPLAY,
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 1230
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1650
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
          },
        },
      },
      img: {
        lumne_500: require('../../../@common/assets/lumen/500.png'),
        lumne_300: require('../../../@common/assets/lumen/300.png'),
      },
      icon: {
        logo: require('../../../@common/assets/logo-black.svg'),
        // shopping_basket: require('@bbx/vector~master/core/assets/svg/material/shopping_basket.svg'),
        shopping_basket: require('../../../@common/assets/icon/menu/basket.svg'),
        // location_on: require('@bbx/vector~master/core/assets/svg/material/location_on.svg'),
        location_on: require('../../../@common/assets/home/pin.svg'),
        west: require('@bbx/vector~master/core/assets/svg/material/west.svg'),
        expand_less: require('@bbx/vector~master/core/assets/svg/material/expand_less.svg'),
        expand_more: require('@bbx/vector~master/core/assets/svg/material/expand_more.svg'),
        archive: require('@bbx/vector~master/core/assets/svg/material/archive.svg'),
        keyboard_arrow_down: require('@bbx/vector~master/core/assets/svg/material/keyboard_arrow_down.svg'),
        keyboard_arrow_up: require('@bbx/vector~master/core/assets/svg/material/keyboard_arrow_up.svg'),
        unarchive: require('@bbx/vector~master/core/assets/svg/material/unarchive.svg'),
        picture_as_pdf: require('@bbx/vector~master/core/assets/svg/material/picture_as_pdf.svg'),
      },
      state: {
        /**
         * @type string
         */
        id: `_${id.uniq()}`,
        /**
         * @type number
         */
        quantity: 1,
        /**
         * @type boolean
         */
        loadingEvolve: false,
        /**
         * @type number
         */
        roleId: ROLE.CUSTOMER,
        /**
         * @type number
         */
        userId: 0,
        /**
         * @type 'top'|'bottom'
         */
        scrollDirection: 'top',
        /**
         * @type 'top'|'bottom'
         */
        previousScrollDirection: 'top',
        /**
         * @type number
         */
        scrollTop: 0,
        /**
         * @type number
         */
        scrollSize: 0,
        /**
         * @type number
         */
        previousScrollTop: 0,
        /**
         * @type boolean
         */
        photosIsVisible: false,
        /**
         * @type boolean
         */
        showLogo: false,
        /**
         * @type boolean
         */
        whiteLogo: false,
        /**
         * @type boolean
         */
        opened: false,
        /**
         * @type boolean
         */
        openedSeller: false,
        /**
         * @type boolean
         */
        openedStockDetail: false,
        /**
         * @type boolean
         */
        showMenu: false,
        /**
         * @type Function
         */
        timeOut: null,
        /**
         * @type any
         */
        logoElement: null,
        /**
         * @type any
         */
        infoBoxElement: null,
        /**
         * @type boolean
         */
        moreInfoOpened: false,
        /**
         * @type boolean
         */
        searchOpened: false,
        /**
         * @type boolean
         */
        loadingDownloadForm: false,
        /**
         * @type boolean
         */
        articleRecommendationIsVisible: false,
        /**
         * @type any[]
         */
        photoElements: [],
        /**
         * @type number[]
         */
        articleIds: [],
        /**
         * @type string
         */
        collectionDescription: '',
        /**
         * @type Article
         */
        article: deltaService.object(this.nuxtProp.payload.article, Article),
      },
      events: []
    }
  },
  watch: {
    'isMobile': {
      handler: function (v) {
        if (v) {
          this.toTopInfo()
        } else {
          this.close()
        }
      }
    },
    'nuxtProp.payload': {
      handler: function () {
        this.state.article = deltaService.object(this.nuxtProp.payload.article, Article)
        this.state.photoElements = []
      },
    },
    'state.article.id': {
      handler: function () {
        this.init().catch(sendError)
      },
    },
    'state.openedSeller': {
      handler: function (v) {
        if (v) {
          this.close()
        }
      }
    },
    'state.opened': {
      handler: function (v) {
        if (!v) {
          this.toTopInfo()
        }
      }
    },
    'allCollectionIds': {
      handler: function () {
        this.fetchArticleIds().catch(sendError)
      }
    }
  },
  computed: {
    class_root() {
      const classes = []
      if (this.state.showLogo && this.state.photosIsVisible) classes.push('Article-photosIsVisible')
      if (this.state.whiteLogo) classes.push('Article-whiteLogo')
      if (this.state.showMenu) classes.push('Article-showMenu')
      if (this.state.opened && !this.state.openedStockDetail) classes.push('Article-opened')
      if (this.moreInfoOpened) classes.push('Article-moreInfoOpened')
      if (this.state.searchOpened) classes.push('Article-searchOpened')
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`Article-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`Article-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`Article-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`Article-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`Article-desktopY`)
      return classes
    },
    style_infoBox() {
      const style = {}
      if (this.isMobile && !this.state.opened) style.width = `calc(100vw - ${this.state.scrollSize}px)`
      return style
    },
    uniqCollection() {
      let collectionIds = []
      for (const batch of this.state.article.batches) {
        for (const product of batch.products) {
          for (const collection of product.collections) {
            collectionIds.push(collection.id)
          }
        }
      }
      const id = collectionIds.length > 1 ? 0 : collectionIds.shift()
      const collection = new Collection({id})
      return collection
    },
    allCollectionIds() {
      let collectionIds = []
      for (const batch of this.state.article.batches) {
        for (const product of batch.products) {
          for (const collection of product.collections) {
            collectionIds.push(collection.id)
          }
        }
      }
      collectionIds = uniq(collectionIds)
      collectionIds = collectionIds.filter(a => a)
      return collectionIds
    },
    moreInfoOpened() {
      return this.state.moreInfoOpened || this.state.opened
    },
    isBatch() {
      return this.state.article.isBatch && this.state.article.batches.length === 1
    },
    isSet() {
      return this.state.article.isBatch && this.state.article.batches.length > 1
    },
    isProduct() {
      return !this.isBatch && !this.isSet
    },
    isOutlet() {
      return this.percentage > 0 && this.percentage < 100 && this.state.roleId !== ROLE.SELLER
    },
    isAdmin() {
      return [ROLE.ADMIN, ROLE.ROOT].includes(this.state.roleId)
    },
    isSeller() {
      return this.state.roleId === ROLE.SELLER
    },
    isCHR() {
      return this.state.roleId === ROLE.CHR
    },
    isAdminOrSellerOrCHR() {
      return this.isAdmin || this.isSeller || this.isCHR
    },
    canShowDetailStock() {
      return this.state.article.isBatch && [
        ROLE.SELLER,
        ROLE.ADMIN,
        ROLE.ROOT
      ].includes(this.state.roleId)
    },
    lang() {
      return getLangServiceBrowser()
    },
    getArticleStockBrowser() {
      return getArticleStockBrowser({
        article: this.state.article,
      })
    },
    getArticleStockSoon() {
      return getArticleStock({
        article: this.state.article,
        attr: 'quantityOrdered'
      })
    },
    getArticleStockSoonDeliveredAt() {
      let date = getArticleStockDeliveredAt({
        article: this.state.article
      })
      if (!date) return date
      return moment(date, 'YYYY.MM.DD.HH.mm.ss.SSS').format('DD/MM/YYYY')
    },
    isMobile() {
      return this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE
    },
    findDefaultArticleTermNames() {
      return findDefaultArticleTermNames(this.state.article)
    },
    articlePhotos() {
      let photos = []
      for (const photo of this.state.article.photos) {
        photos.push(photo)
      }
      photos = photos.sort((a, b) => b.cover - a.cover)
      return photos
    },
    productPhotos() {
      let photos = []
      for (const batch of this.state.article.batches) {
        for (const product of batch.products) {
          for (const photo of product.photos) {
            photos.push(photo)
          }
        }
      }
      photos = photos.sort((a, b) => b.cover - a.cover)
      return photos
    },
    hasCover() {
      for (const batch of this.state.article.batches) {
        for (const product of batch.products) {
          for (const photo of product.photos) {
            if (photo.cover) return true
          }
        }
      }
      return false
    },
    percentage() {
      let percentage = 0
      for (const promotion of this.state.article.promotions) {
        percentage = promotion.percentage
      }
      return percentage
    },
    pages() {
      return getArianeArticlePages(this.nuxtProp, this.state.article)
    },
  },
  beforeMount() {

    // -----

    this.state.roleId = stateService.get(STATE_ROLE_ID)
    stateService.watch(STATE_ROLE_ID, v => this.state.roleId = v)

    this.state.userId = stateService.get(STATE_USER_ID)
    stateService.watch(STATE_USER_ID, v => this.state.userId = v)

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.CHANGE_COUNTRY_LOCATION,
      callback: v => {
        // this.state.moreInfoOpened = !this.isMobile && !canDisplay()
      }
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.SEARCH_OPENED_CHANGE,
      callback: v => {
        eventService.triggerByName(new ListenerTrigger({
          name: EVENT.SEND_DEBUG,
          payload: v
        }))
        this.state.searchOpened = v
      }
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.SCROLL_CHANGE,
      callback: () => {
        if (this.state.logoElement && this.state.photoElements.length) {
          this.calculateLogoAbovePicture(this.state.logoElement, this.state.photoElements)
        } else {
          this.calculateLogoAbovePictureAsync().catch(sendError)
        }

        this.calculateScrollDirection((direction) => {
          this.showOrHideTheMenu(direction === 'bottom')
        })
      }
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
    this.init().catch(sendError)
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    async init() {
      this.resetState()
      this.state.scrollSize = new Scrollbar().getNativeSize()
      await this.fetchArticleIds()
      await this.selectEvolve()
      // this.state.moreInfoOpened = !this.isMobile && !canDisplay()
    },
    canDisplay,
    calculateHT,
    calculateTTC,
    calculatePromotion,
    formatPrice,
    resetState() {
      this.state.quantity = 1
      this.state.loadingEvolve = false
      this.state.scrollDirection = 'top'
      this.state.previousScrollDirection = 'top'
      this.state.scrollTop = 0
      this.state.previousScrollTop = 0
      this.state.photosIsVisible = false
      this.state.opened = false
      this.state.openedSeller = false
      this.state.openedStockDetail = false
      this.state.showMenu = false
      this.state.infoBoxElement = null
      this.state.moreInfoOpened = false
      this.state.searchOpened = false
    },
    async selectEvolve() {
      this.state.loadingEvolve = true
      const ids = this.state.article.evolveAncestors.map(a => a.id)
      if (ids.length) {
        const {data} = await apiClient.do(selectEvolve, selectEvolve.with({
          $ids: ids
        }))
        this.state.article.evolveAncestors = data
      }
      this.state.loadingEvolve = false
    },
    /**
     * @param {Photo} file
     * @returns {string}
     */
    pictureDisplay(file) {
      return file.full ? PICTURE_DISPLAY.COVER : PICTURE_DISPLAY.AUTO
    },

    onPrevious() {
      eventService.triggerByName(new ListenerTrigger({
        name: EVENT.TO_PREVIOUS_URL
      }))
    },

    add() {
      eventService.triggerByName(new ListenerTrigger({
        name: EVENT.ADD_BASKET,
        payload: {
          article: this.state.article,
          quantity: this.state.quantity
        }
      }))
      this.state.quantity = 1
      this.close();
    },
    /**
     * @param {Photo} photo
     * @returns {[]}
     */
    class_photo(photo) {
      const classes = []
      if (photo.full) classes.push('Article--photo-full')
      return classes
    },
    onBreakPointChange(v) {
      this.props.Responsive['0'].breakPoint = v
    },
    onVerticalBreakPointChange(v) {
      this.props.Responsive['0'].verticalBreakPoint = v
    },
    /**
     *
     * @param {Function} cb
     */
    calculateScrollDirection(cb) {
      this.state.previousScrollTop = this.state.scrollTop
      this.state.previousScrollDirection = this.state.scrollDirection

      this.state.scrollTop = scrollerParentService.scrollTop

      if (this.state.scrollTop > 100) {
        if (this.state.scrollTop > this.state.previousScrollTop) {
          this.state.scrollDirection = 'bottom'
        } else if (this.state.scrollTop < this.state.previousScrollTop) {
          this.state.scrollDirection = 'top'
        }

        if (this.state.previousScrollDirection !== this.state.scrollDirection) {
          cb(this.state.scrollDirection)
        }
      }

      this.state.showLogo = this.state.scrollDirection === 'bottom' && this.state.scrollTop > 200;
    },
    showOrHideTheMenu(hideOrNot) {
      this.state.showMenu = hideOrNot;
      eventService.triggerByName(new ListenerTrigger({
        name: EVENT.HIDE_MENU,
        payload: hideOrNot
      }))
    },
    /**
     * @param {boolean} isVisible
     */
    photosIsVisible(isVisible) {
      this.state.photosIsVisible = isVisible
    },
    /**
     * @param {any} positionChild
     * @param {HTMLElement} elementParent
     * @return boolean
     */
    isInside(positionChild, elementParent) {
      const positionParent = elementParent.getBoundingClientRect()
      const isLeft = positionChild.left >= positionParent.left
      const isTop = positionChild.top >= positionParent.top
      const isRight = positionParent.right >= positionChild.right
      const isBottom = positionParent.bottom >= positionChild.bottom
      return isLeft && isTop && isRight && isBottom
    },
    /**
     * @return void
     */
    async calculateLogoAbovePictureAsync() {
      const logoElement = await waitElement(async () => {
        if (this.state.logoElement) return this.state.logoElement
        this.state.logoElement = document.querySelector(`#${this.state.id} .Article--logo`)
        return this.state.logoElement
      })
      const photoElements = await waitElement(async () => {
        if (this.state.photoElements.length) return this.state.photoElements
        this.state.photoElements = document.querySelectorAll(`#${this.state.id} .Article--photo`)
        return this.state.photoElements
      })
      this.calculateLogoAbovePicture(logoElement, photoElements)
    },
    /**
     * @return void
     */
    calculateLogoAbovePicture(logoElement, photoElements) {
      if (!logoElement) return
      const positionChild = logoElement.getBoundingClientRect()
      for (let i = 0; i < photoElements.length; i++) {
        const inside = this.isInside(positionChild, photoElements[i])
        if (inside) {
          if (photoElements[i].classList.contains('Article--photo-full')) {
            this.state.whiteLogo = true
          } else {
            this.state.whiteLogo = false
          }
        }
      }
    },
    toggleMoreInfo() {
      this.state.moreInfoOpened = !this.state.moreInfoOpened
      this.$nextTick(() => {
        setTimeout(() => {
          eventService.triggerByName(new ListenerTrigger({
            name: EVENT.ARTICLE_TOGGELED,
          }))
        })
      })
    },
    toggle() {
      this.state.opened ? this.close() : this.open()
    },
    open() {
      eventService.triggerByName(new ListenerTrigger({
        name: EVENT.DISPLAY_SCROLLBAR,
        payload: false
      }))
      this.state.opened = true
    },
    close() {
      eventService.triggerByName(new ListenerTrigger({
        name: EVENT.DISPLAY_SCROLLBAR,
        payload: true
      }))
      this.state.opened = false
    },
    toTopInfo() {
      if (this.state.infoBoxElement) {
        this.state.infoBoxElement.scrollTop = 0
      } else {
        waitElement(async () => document.querySelector(`#${this.state.id} .Article--infoBox`)).then(res => {
          this.state.infoBoxElement = res
          if (res) res.scrollTop = 0
        }).catch(err => {
          sendError(err)
        });
      }
    },
    /**
     * @param {Product} product
     * @param {'length'|'width'|'height'|'weight'} type
     */
    calculateDimension(product, type) {
      let dimensions = []

      for (const dimension of product.sizes) {
        if (type === 'length') {
          dimensions.push(dimension.length)
        } else if (type === 'width') {
          dimensions.push(dimension.width)
        } else if (type === 'height') {
          dimensions.push(dimension.height)
        } else if (type === 'weight') {
          dimensions.push(dimension.weight)
        }
      }

      dimensions = dimensions.sort()

      return uniq(dimensions)
    },
    /**
     * @param {Product} product
     */
    calculateSittingPlace(product) {
      let sittingPlaces = []

      for (const sittingPlace of product.sittingPlaces) {
        sittingPlaces.push(sittingPlace.number)
      }

      sittingPlaces = sittingPlaces.sort(function (a, b) {
        return a - b
      })

      return uniq(sittingPlaces)
    },
    sortPhotos(photos) {
      return photos.map(a => new Photo(a)).sort((a, b) => b.cover - a.cover)
    },
    /**
     * @param {Similar} similar
     */
    getHref(similar) {
      let article = new Article()
      for (const product of similar.productDowns) {
        for (const batch of product.batches) {
          for (const article_ of batch.articles) {
            article = article_
          }
        }
      }
      const page = convertPathToUrl(new Page(article.pages[0]))
      return urlService.getProjectUrl(this.lang.lang.iso6391, new Url(page.urls[0]))
    },
    /**
     * Usefull for ScrollFixe component
     * @param onScroll
     */
    registerCallback({onScroll, onResize}) {

      const event1 = new ListenerRegister({
        name: EVENT.SCROLL_CHANGE,
        callback: ({top, left}) => onScroll({top, left})
      })

      const event2 = new ListenerRegister({
        name: EVENT.ARTICLE_TOGGELED,
        callback: () => {
          onResize()
        }
      })

      const event3 = new ListenerRegister({
        name: EVENT.SIZE_CHANGED,
        callback: () => onResize()
      })

      eventService.register(event1)
      eventService.register(event2)
      eventService.register(event3)

      this.events.push(event1)
      this.events.push(event2)
      this.events.push(event3)
    },
    async fetchArticleIds() {

      if (!this.allCollectionIds.length) return

      let ids = []

      // specific recomendation

      ids = ids.concat(this.state.article.articleChildren.map(a => a.id))

      // all collection
      const {data} = await apiClient.do(selectArticles, selectArticles.with({
        $scope: 2,
        active: true,
        visible: true,
        $count: false,
        $sortByInStockFirst: true,
        sortId: SORT.NEW_DESC,
        collectionIds: this.allCollectionIds
      }))

      ids = ids.concat(data.map(a => a.id))

      this.state.articleIds = uniq(ids)
    },
    async fetchArticleAsync(articleId) {

      const {data} = await apiClient.do(selectArticles, selectArticles.with({
        $scope: 4,
        $count: false,
        $ids: [articleId],
      }))

      return new Article(data[0])
    },
    onDownloadForm(productId) {
      this.downloadForm(productId).catch(sendError)
    },
    async downloadForm(productId) {

      this.state.loadingDownloadForm = true

      let msg = this.lang.translate(606)
      let id = ''

      try {
        const {data, success} = await apiClient.do(customProductPDF, customProductPDF.with({
          productId: productId
        }))
        if (!success) {
          this.state.loadingDownloadForm = false
          return alert(msg)
        }
        id = data.$id
      } catch (err) {
        this.state.loadingDownloadForm = false
        sendError(err)
        return alert(msg)
      }
      let element = document.createElement('a')
      let url = `${documentMakerConfigPublic.urlPdf}?id=${id}&template=product`
      element.setAttribute('href', url);
      element.setAttribute('target', '_blank');
      element.style.display = 'none';
      document.body.appendChild(element)
      element.click();
      document.body.removeChild(element)
      this.state.loadingDownloadForm = false
    },
  }
}
</script>
