<style scoped>
.InputLabel {
  display: flex;
  align-items: center;
}

.InputLabel--icon {
  padding-right: 8px;
}

.InputLabel--icon ::v-deep .Vector {
  width: 24px;
  height: 24px;
}

.InputLabel--text {
  display: flex;
  align-items: center;
}

.InputLabel--text ::v-deep .Term {
  font-family: 'RalewayBold', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
}

.InputLabel--mandatory {
  color: red;
  padding-left: 10px;
  height: 10px;
  overflow: hidden;
}

.InputLabel--helper {
  padding-left: 10px;
  color: rgba(0, 0, 0, 0.5);
  font-family: 'ComfortaaRegular', sans-serif;
  font-size: 10px;
}
</style>

<template>
  <div class="InputLabel">
    <div class="InputLabel--icon" v-if="svg">
      <Vector :svg="svg"/>
    </div>
    <div class="InputLabel--text">
      <Term :term="term"/>
      <div v-if="mandatory" class="InputLabel--mandatory">*</div>
      <div v-if="helper" class="InputLabel--helper">
        {{ helper }}
      </div>
    </div>
  </div>
</template>

<script>

import {Term} from '../../../@common/delta/database/Term.ts'

export default {

  props: {
    svg: {
      type: String,
      default: () => ''
    },
    helper: {
      type: String,
      default: () => ''
    },
    mandatory: {
      type: Boolean,
      default: () => false
    },
    term: {
      default: () => new Term()
    }
  },
  data() {
    return {
      props: {},
      state: {},
    }
  },
  watch: {},
  computed: {},
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
