import {Article} from "../delta/database/Article"

export function getArticleStockDeliveredAt(props: { article: Article}): string {

    // -----

    let article = new Article(props.article)

    // -----

    let date = ''

    // -----

    if (article.batches) {
        for (const batch of article.batches) {
            for (const product of batch.products) {
                for (const stock of product.stocks) {
                    if(stock.deliveredAt > date) {
                        date = stock.deliveredAt
                    }
                }
            }
        }
    }

    // -----

    return date
}

