<style scoped>
.ArticleSimilar {
}

.ArticleSimilar--material {
  display: flex;
  align-items: center;
}

.ArticleSimilar--materialText {
  font-size: 10px;
  text-transform: uppercase;
  letter-spacing: 1px;
  border-radius: 3px;
  align-items: center;
  justify-content: center;
  display: flex;
  padding: 0px 16px;
  height: 30px;
  margin-right: 8px;
  box-sizing: border-box;
}

.ArticleSimilar-themeLight .ArticleSimilar--materialText {
  color: white;
  border: solid 1px white;
}

.ArticleSimilar-themeDark .ArticleSimilar--materialText {
  color: black;
  border: solid 1px rgba(0, 0, 0, 0.1);
}

.ArticleSimilar-mobile .ArticleSimilar--materialText {
  display: none;
}

.ArticleSimilar--materialImage {
  background: rgba(0, 0, 0, 0.05);
  margin-right: 4px;
  padding: 2px;
  border: solid 2px rgba(0, 0, 0, 0);
  border-radius: 50px;
  width: 20px;
  height: 20px;
  transition: border 300ms;
}

.ArticleSimilar--materialImage:hover {
  border: solid 2px rgba(0, 0, 0, 0.25);
}

.ArticleSimilar--materialImage ::v-deep .Picture {
  width: 20px;
  height: 20px;
  overflow: hidden;
  border-radius: 50px;
}

.ArticleSimilar-mobile .ArticleSimilar--materialImage {
  width: 20px;
  height: 20px;
}

.ArticleSimilar--materialImage-selected {
  border: solid 2px rgba(0, 0, 0, 0.5);
}

.ArticleSimilar--materialImage-forbiden {
  cursor: not-allowed;
}
</style>

<template>
  <div class="ArticleSimilar" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="ArticleSimilar--material">
        <template v-for="batch in article.batches">
          <template v-for="product in batch.products">
            <template v-for="(similarUp, similarUp_index) in product.similarUps">
<!--              <div class="ArticleSimilar&#45;&#45;materialText" v-if="similarUp_index === 0">-->
<!--                <Term :term="lang.term(489)"></Term>-->
<!--              </div>-->
              <Link :link="getHref(similarUp)">
                <template v-for="material in similarUp.materials">
                  <template v-for="term in material.terms">
                    <Tooltip :text="lang.translateTerm(term)">
                      <div class="ArticleSimilar--materialImage" :class="class_materialImage(product, similarUp)">
                        <template v-for="photo in material.photos">
                          <template v-for="file in photo.files">
                            <Picture :file="file"></Picture>
                          </template>
                        </template>
                      </div>
                    </Tooltip>
                  </template>
                </template>
              </Link>
            </template>
          </template>
        </template>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {Article} from "../../../@common/delta/database/Article";
import {convertPathToUrl} from "../../../@common/function/helperUrl";
import {Page} from "../../../@common/delta/database/Page";
import {urlService} from "../../../@common/service/urlService";
import {Url} from "../../../@common/delta/database/Url";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

export default {
  props: {
    theme: {
      type: String,
      default: () => 'dark'
    },
    article: {
      type: Article,
      default: () => new Article()
    },
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 1070
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1500
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      state: {},
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`ArticleSimilar-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`ArticleSimilar-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`ArticleSimilar-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`ArticleSimilar-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`ArticleSimilar-desktopY`)
      if (this.theme === 'dark') classes.push(`ArticleSimilar-themeDark`)
      if (this.theme === 'light') classes.push(`ArticleSimilar-themeLight`)
      if (this.selected) classes.push(`ArticleSimilar-selected`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    /**
     * @param {Similar} similar
     */
    getHref(similar) {

      let batchQuantity = 0

      for (const batch of this.article.batches) {
        batchQuantity = batch.quantity
      }

      let article = new Article()
      for (const productDown of similar.productDowns) {
        for (const batch of productDown.batches) {
          for (const article_ of batch.articles) {
            if (article_.active && article_.visible && batch.quantity === batchQuantity && article_.batches.length === 1) {
              article = article_
            }
          }
        }
      }
      if (!article.id) return ''
      const page = convertPathToUrl(new Page(article.pages[0]))
      return urlService.getProjectUrl(this.lang.lang.iso6391, new Url(page.urls[0]))
    },

    /**
     * @param {Product} product
     * @param {Similar} similar
     */
    class_materialImage(product, similar) {
      const classes = []
      if (similar.productDowns.map(a => a.id).includes(product.id)) classes.push(`ArticleSimilar--materialImage-selected`)
      if (!this.getHref(similar)) classes.push(`ArticleSimilar--materialImage-forbiden`)
      return classes
    }
  }
}
</script>
