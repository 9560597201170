"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.NuxtUrl = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var NuxtPath_1 = require("./NuxtPath");
var NuxtQuery_1 = require("./NuxtQuery");
var NuxtUrl = /** @class */ (function () {
    function NuxtUrl(props) {
        if (props === void 0) { props = {}; }
        this.paths = deltaService_1.deltaService.array(props.paths, NuxtPath_1.NuxtPath);
        this.queries = deltaService_1.deltaService.array(props.queries, NuxtQuery_1.NuxtQuery);
    }
    return NuxtUrl;
}());
exports.NuxtUrl = NuxtUrl;
