"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.freeTransportCostService = void 0;
var FreeTransportCost_1 = require("../ts/FreeTransportCost");
exports.freeTransportCostService = new FreeTransportCost_1.FreeTransportCost({
    dates: {
        start: '2021.04.26.00.00.00.000',
        end: '2021.08.31.23.59.59.999',
    }
});
