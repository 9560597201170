<style scoped>
.Notation {
  display: flex;
  align-items: center;
}

.Notation--amount {
  font-family: 'ComfortaaRegular', sans-serif;
  font-size: 25px;
}

.Notation--label {
  font-size: 10px;
  font-family: 'ComfortaaRegular', sans-serif;
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  padding-left: 10px;
}

.Notation--stars {
  padding-left: 20px;
  display: flex;
  align-items: center;
}

.Notation--star {
  width: 24px;
}

.Notation--star ::v-deep .Vector svg {
  fill: #FFBE26;
}
</style>

<template>
  <div class="Notation" :class="class_root">
    <div class="Notation--amount">4.5</div>
    <div class="Notation--label">avis client</div>
    <div class="Notation--stars">
      <div class="Notation--star" v-for="star in starIcons">
        <Vector :svg="icon.star" v-if="star.complete"/>
        <Vector :svg="icon.star_border" v-else-if="!star.complete"/>
      </div>
    </div>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';

export default {

  props: {},
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        star: require('@bbx/vector~master/core/assets/svg/material/star.svg'),
        star_border: require('@bbx/vector~master/core/assets/svg/material/star_border.svg'),
      },
      state: {
        note: 4,
        stars: []
      },
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      return classes
    },
    starIcons() {
      const starts = []
      const max = 5;
      for (let i = 0; i < this.state.note; i++) {
        starts.push({
          complete: true,
        })
      }
      const a = max - starts.length
      for (let i = 0; i < a; i++) {
        starts.push({
          complete: false,
        })
      }
      return starts
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
