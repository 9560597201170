"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PaymentEngineList = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Payment_1 = require("./Payment");
var PaymentEngineList = /** @class */ (function () {
    function PaymentEngineList(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.value = deltaService_1.deltaService.string(props.value, '');
        this.payments = deltaService_1.deltaService.array(props.payments, Payment_1.Payment);
    }
    return PaymentEngineList;
}());
exports.PaymentEngineList = PaymentEngineList;
