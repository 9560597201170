"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ORDER_SORT_DIRECTION = exports.ORDER_SORT = exports.ORDER_SEARCH_ID = void 0;
exports.ORDER_SEARCH_ID = {
    ID: 1,
    ALIAS: 2,
    USER_EMAIL: 3,
    PRODUCT_REFERENCE: 4,
};
exports.ORDER_SORT = {
    CREATED_AT: 1,
};
exports.ORDER_SORT_DIRECTION = {
    ASC: 1,
    DESC: 2,
};
