"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Selection = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var ShopTypeList_1 = require("./ShopTypeList");
var Article_1 = require("./Article");
var Sort_1 = require("./Sort");
var Selection = /** @class */ (function () {
    function Selection(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.isNightMode = deltaService_1.deltaService.boolean(props.isNightMode, false);
        this.shopTypeLists = deltaService_1.deltaService.array(props.shopTypeLists, ShopTypeList_1.ShopTypeList);
        this.articles = deltaService_1.deltaService.array(props.articles, Article_1.Article);
        this.sorts = deltaService_1.deltaService.array(props.sorts, Sort_1.Sort);
    }
    return Selection;
}());
exports.Selection = Selection;
