<style scoped>

</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="content-wrapper none for-title">
      <div class="container_12">
        <div class="main-container main-main-container col1-layout">
          <div class="grid_12 col-main">
            <div class="page-title">
              <h1>Comment aménager son extérieur pour en faire un endroit agréable ?</h1>
            </div>
            <div class="std">
              <p style="text-align: justify;">
                <img alt="" src="./media/wysiwyg/coinsalonjardin.jpg">
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Si aménager son intérieur semble évident, cela paraît plus compliqué
                  quand il s’agit d’un
                  <b>jardin</b>
                  ou d’une
                  <b>terrasse</b>
                  . Nos extérieurs deviennent pourtant de véritables extensions de notre
                  <b>maison</b>
                  , une pièce de vie à part entière, qui mérite d’être décorée, meublée confortablement et aménagée avec
                  attention. Nous vous expliquons, dans cet article, comment les agencer pour en profiter pleinement.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Définir les espaces</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Quand on souhaite
                  <b>aménager</b>
                  son jardin, la première étape est de se demander ce dont on a besoin. Un
                  <b>coin salon</b>
                  pour se détendre, un
                  <b>espace pour manger</b>
                  et partager un repas entre amis ou un endroit pour bronzer confortablement au
                  <b>bord de la piscine</b>
                  , etc.&nbsp;
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Il vous faudra ensuite déterminer la place de chacun de ces espaces en
                  fonction de l’agencement de votre terrain. Un coin détente sera plus agréable s’il donne sur une jolie
                  vue plutôt que sur la route. Un espace repas situé près de la cuisine ou à proximité du barbecue sera
                  plus pratique.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Structurez, avec des arrangements pertinents, vos différents espaces.
                  Sur Internet, de nombreux plans de
                  <b>jardin</b>
                  sont disponibles et vous donneront une multitude d’
                  <b>idées</b>
                  desquelles vous inspirer.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Choisir le mobilier adapté</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Votre
                  <b>mobilier</b>
                  aura une place centrale dans l’aménagement de votre extérieur. Vous pourrez choisir l’ambiance et la
                  personnalité que vous souhaitez donner à votre jardin ou à votre terrasse grâce aux nombreux
                  <b>designs</b>
                  disponibles. Des
                  <b>meubles</b>
                  <b>en bois</b>
                  apporteront de la chaleur et un côté nature.
                  Une table et des chaises
                  en aluminium contribueront à une atmosphère chic et épurée. Du mobilier en rotin s’inscrira dans une
                  décoration bohème, et un salon de jardin en résine tressée apportera une ambiance cosy et moderne.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Réfléchir aux ombrages</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Pour éviter les coups de
                  <b>soleil</b>
                  aux heures les plus chaudes, il est important d’aménager des coins d’ombre dans votre jardin. Il
                  existe différents moyens de créer de l’ombre.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">L’avantage du parasol est qu’il peut facilement être déplacé pour
                  apporter de l’ombre là où vous en avez besoin. Vous trouverez de nombreux modèles aux diverses
                  fonctionnalités et aux designs variés. Pour les pieds de parasol, privilégiez ceux à roulettes, qui
                  sont plus pratiques à déplacer.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">
                  <img alt="" src="./media/wysiwyg/parasol.jpg">
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Les toiles tendues ont fait leur apparition il y a quelques années dans
                  nos jardins. Esthétiques et faciles à installer, ces voiles d’ombrage, au
                  <b>style</b>
                  contemporain, ont un grand succès. Vous trouverez des modèles imperméables qui pourront vous protéger
                  de la pluie et du soleil. Il en existe de différents coloris.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">L’installation d’un store banne, plus coûteuse, peut aussi être
                  envisagée. Quel que soit votre choix, privilégiez une toile qui vous protègera des rayons UV.&nbsp;
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Si vous avez de la patience, vous pouvez planter des arbres dans votre
                  jardin, ils vous apporteront, dans quelques années, des coins d’ombre naturels.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Donner du cachet grâce à la décoration</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Même si on a souvent tendance à l’oublier, il ne faut pas négliger
                  l’ajout d’
                  accessoires de
                  <b>décoration</b>
                  dans nos extérieurs. Ils viennent ajouter du charme à nos jardins et nos terrasses, en apportant la
                  touche finale pour une ambiance réussie. Il existe de nombreux objets pour
                  <b>décorer son jardin</b>
                  .
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Un objet
                  <b>design</b>
                  posé sur une table donnera de la personnalité à votre extérieur et le modernisera. Des coussins posés
                  ici et là sur votre
                  <b>terrasse en bois</b>
                  apporteront une touche de couleur et un côté cosy. Des plantes dans de jolis pots donneront le côté
                  nature à votre balcon et rendront l’endroit paisible et agréable.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Travailler l’éclairage</h2>
              <p style="text-align: justify;">
                <img style="" alt="" src="./media/wysiwyg/lampe.jpg" height="400">
                <br>
                <br>
                Rien de mieux qu’une guirlande lumineuse, sur une terrasse, pour rendre votre
                <b>salon de jardin</b>
                <b>convivial</b>
                lors d’une soirée. L’éclairage est un point important de l’ambiance apportée à un extérieur. Quand le
                soleil se couche, votre jardin se part de ses lumières et celles-ci donnent un aspect totalement
                différent à votre extérieur.
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">N’hésitez pas à disposer de petits luminaires, fonctionnant à l’énergie
                  solaire, dans vos pots de fleurs ou le long d’un sentier pour les mettre en valeur la nuit.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Grâce à toutes ses astuces, vous avez les clés pour faire de votre
                  extérieur un endroit où il fait bon vivre et où l’on se sent bien.
                </span>
              </p>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../../function/getH1";
import {Term} from "../../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../../@common/constant/PROJECT_URL";
import {PATHS} from "../../../../@common/constant/PATHS";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    },
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FAQ-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
