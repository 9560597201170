"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.GeographicalPosition = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Address_1 = require("./Address");
var GeographicalPosition = /** @class */ (function () {
    function GeographicalPosition(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.latitude = deltaService_1.deltaService.number(props.latitude, 0);
        this.longitude = deltaService_1.deltaService.number(props.longitude, 0);
        this.addresses = deltaService_1.deltaService.array(props.addresses, Address_1.Address);
    }
    return GeographicalPosition;
}());
exports.GeographicalPosition = GeographicalPosition;
