"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.setNuxtUrlWithFullPath = exports.setNuxtUrlWithWindowLocation = exports.convertPathToUrl = exports.setNuxtUrlWithChangeRouteProps = exports.setNuxtUrlWithNuxtRoute = exports.getQueryValueByName = exports.getQueryByName = exports.addPathQuery = exports.nuxtUrlToRouteProps = exports.nuxtQueriesToQueryObject = exports.getNuxtPropPathIds = exports.getPageByNuxtProp = exports.searchPageByPathIds = exports.searchPageByNuxProp = exports.extractUrlInfoFromPage = exports.flattenUrl = exports.flattenPath = exports.getPageByPathIds = exports.getPageByUrls = exports.getPageByProjectUrls = exports.getPageByProjectUrlId = exports.getUrlByCollectionId = exports.getPathIdsByPage = exports.getPathIdsByCategoryId = exports.getUrlByProjectUrlId = exports.isProjectUrl = exports.cleanPath = exports.isPath = exports.isCollection = void 0;
var urlService_1 = require("../service/urlService");
var NuxtQuery_1 = require("../delta/nuxt/NuxtQuery");
var toJsonType_1 = require("./toJsonType");
var NuxtPath_1 = require("../delta/nuxt/NuxtPath");
var EventChangeRoute_1 = require("../delta/event/EventChangeRoute");
var Page_1 = require("../delta/database/Page");
var PATHS_1 = require("../constant/PATHS");
var Url_1 = require("../delta/database/Url");
var Sort_1 = require("../delta/database/Sort");
var langServiceBrowser_1 = require("../service/langServiceBrowser");
var normalizeUrl_1 = require("./normalizeUrl");
var LANG_1 = require("../constant/LANG");
var pageService_1 = require("../service/pageService");
function isCollection(nuxtProp) {
    var langService = langServiceBrowser_1.getLangServiceBrowser();
    if (!nuxtProp.page.collections.length)
        return false;
    var pathPageRequires = nuxtProp.url.paths.map(function (path) { return path.value; });
    var urlCurrent = "/" + pathPageRequires.join('/');
    var urlRequire = urlService_1.urlService.getProjectUrl(langService.lang.iso6391, new Url_1.Url(nuxtProp.page.urls[0]));
    for (var i in LANG_1.LANG_ISO) {
        urlCurrent = urlCurrent.replace("/" + LANG_1.LANG_ISO[i], '').replace(/\/$/, '');
        urlRequire = urlRequire.replace("/" + LANG_1.LANG_ISO[i], '').replace(/\/$/, '');
    }
    urlCurrent = cleanPath(urlCurrent);
    urlRequire = cleanPath(urlRequire);
    var foundOut = urlCurrent === urlRequire;
    return foundOut;
}
exports.isCollection = isCollection;
function isPath(nuxtProp, pathRequires) {
    var pathIds_ = [];
    for (var _i = 0, _a = nuxtProp.page.paths; _i < _a.length; _i++) {
        var path = _a[_i];
        pathIds_.push(path.id);
    }
    var found = pathIds_.join('/') === pathRequires.join('/');
    return found;
}
exports.isPath = isPath;
function cleanPath(path) {
    for (var i in LANG_1.LANG_ISO) {
        path = path.replace("/" + LANG_1.LANG_ISO[i], '').replace(/\/$/, '');
    }
    return path;
}
exports.cleanPath = cleanPath;
function isProjectUrl(nuxtProp, projectUrlId) {
    var langService = langServiceBrowser_1.getLangServiceBrowser();
    var pathPageRequires = nuxtProp.url.paths.map(function (path) { return path.value; });
    var urlCurrent = "/" + pathPageRequires.join('/');
    var found = new Url_1.Url();
    var pages = pageService_1.pageService.getPages();
    for (var _i = 0, pages_1 = pages; _i < pages_1.length; _i++) {
        var page = pages_1[_i];
        for (var _a = 0, _b = page.urls; _a < _b.length; _a++) {
            var url = _b[_a];
            for (var _c = 0, _d = url.projectUrls; _c < _d.length; _c++) {
                var projectUrl = _d[_c];
                if (projectUrl.id === projectUrlId)
                    found = new Url_1.Url(url);
            }
        }
    }
    var urlRequire = urlService_1.urlService.getProjectUrl(langService.lang.iso6391, found);
    urlCurrent = cleanPath(urlCurrent);
    urlRequire = cleanPath(urlRequire);
    var foundOut = urlCurrent === urlRequire;
    return foundOut;
}
exports.isProjectUrl = isProjectUrl;
function getUrlByProjectUrlId(nuxtProp, projectUrlId) {
    var pages = pageService_1.pageService.getPages();
    for (var _i = 0, pages_2 = pages; _i < pages_2.length; _i++) {
        var page = pages_2[_i];
        for (var _a = 0, _b = page.urls; _a < _b.length; _a++) {
            var url = _b[_a];
            for (var _c = 0, _d = url.projectUrls; _c < _d.length; _c++) {
                var projectUrl = _d[_c];
                if (projectUrlId === projectUrl.id)
                    return new Url_1.Url(page.urls[0]);
            }
        }
    }
    return new Url_1.Url();
}
exports.getUrlByProjectUrlId = getUrlByProjectUrlId;
function getPathIdsByCategoryId(nuxtProp, categoryId) {
    var pages = pageService_1.pageService.getPages();
    for (var _i = 0, pages_3 = pages; _i < pages_3.length; _i++) {
        var page = pages_3[_i];
        for (var _a = 0, _b = page.categories; _a < _b.length; _a++) {
            var category = _b[_a];
            if (categoryId === category.id)
                return page.paths.map(function (path) { return path.id; });
        }
    }
    return [];
}
exports.getPathIdsByCategoryId = getPathIdsByCategoryId;
function getPathIdsByPage(page) {
    var pathIds = [];
    for (var _i = 0, _a = page.urls; _i < _a.length; _i++) {
        var url = _a[_i];
        for (var _b = 0, _c = url.sorts; _b < _c.length; _b++) {
            var sort = _c[_b];
            for (var _d = 0, _e = sort.paths; _d < _e.length; _d++) {
                var path = _e[_d];
                pathIds.push(path.id);
            }
        }
    }
    return pathIds;
}
exports.getPathIdsByPage = getPathIdsByPage;
function getUrlByCollectionId(nuxtProp, collectionId) {
    var pages = pageService_1.pageService.getPages();
    for (var _i = 0, pages_4 = pages; _i < pages_4.length; _i++) {
        var page = pages_4[_i];
        for (var _a = 0, _b = page.collections; _a < _b.length; _a++) {
            var collection = _b[_a];
            if (collectionId === collection.id)
                return new Url_1.Url(page.urls[0]);
        }
    }
    return new Url_1.Url();
}
exports.getUrlByCollectionId = getUrlByCollectionId;
function getPageByProjectUrlId(nuxtProp, projectUrlId) {
    var pages = pageService_1.pageService.getPages();
    for (var _i = 0, pages_5 = pages; _i < pages_5.length; _i++) {
        var page = pages_5[_i];
        for (var _a = 0, _b = page.urls; _a < _b.length; _a++) {
            var url = _b[_a];
            for (var _c = 0, _d = url.projectUrls; _c < _d.length; _c++) {
                var projectUrl = _d[_c];
                if (projectUrlId === projectUrl.id)
                    return page;
            }
        }
    }
    return new Page_1.Page();
}
exports.getPageByProjectUrlId = getPageByProjectUrlId;
function getPageByProjectUrls(nuxtProp) {
    var langService = langServiceBrowser_1.getLangServiceBrowser();
    var pathPageRequires = nuxtProp.url.paths.map(function (path) { return path.value; });
    var urlCurrent = "/" + pathPageRequires.join('/');
    var pages = pageService_1.pageService.getPages();
    for (var _i = 0, pages_6 = pages; _i < pages_6.length; _i++) {
        var page = pages_6[_i];
        for (var _a = 0, _b = page.urls; _a < _b.length; _a++) {
            var url = _b[_a];
            for (var _c = 0, _d = url.projectUrls; _c < _d.length; _c++) {
                var projectUrl = _d[_c];
                var urlRequire = urlService_1.urlService.getProjectUrl(langService.lang.iso6391, url);
                urlCurrent = cleanPath(urlCurrent);
                urlRequire = cleanPath(urlRequire);
                var found = urlCurrent === urlRequire;
                if (found)
                    return page;
            }
        }
    }
    return new Page_1.Page();
}
exports.getPageByProjectUrls = getPageByProjectUrls;
function getPageByUrls(nuxtProp) {
    var langService = langServiceBrowser_1.getLangServiceBrowser();
    var pathPageRequires = nuxtProp.url.paths.map(function (path) { return path.value; });
    var urlCurrent = "/" + pathPageRequires.join('/');
    var pages = pageService_1.pageService.getPages();
    for (var _i = 0, pages_7 = pages; _i < pages_7.length; _i++) {
        var page = pages_7[_i];
        for (var _a = 0, _b = page.urls; _a < _b.length; _a++) {
            var url = _b[_a];
            var urlRequire = urlService_1.urlService.getProjectUrl(langService.lang.iso6391, url);
            urlCurrent = cleanPath(urlCurrent);
            urlRequire = cleanPath(urlRequire);
            var found = urlCurrent === urlRequire;
            // console.log('TOTO.getPageByUrls', found, urlCurrent, urlRequire);
            if (found)
                return page;
        }
    }
    return new Page_1.Page();
}
exports.getPageByUrls = getPageByUrls;
function getPageByPathIds(nuxtProp, pathIds) {
    return searchPageByPathIds(nuxtProp, pathIds);
}
exports.getPageByPathIds = getPageByPathIds;
// -----
function flattenPath(path) {
    // {'fr': 'accueil-genial', 'en': 'home-super'}
    var canonical = {};
    // {'fr': ['accueil', 'genial'], 'en': ['home', 'super']}
    var currents = {};
    // -----
    for (var iso6391 in LANG_1.LANG_ISO) {
        currents[LANG_1.LANG_ISO[iso6391]] = [];
        canonical[LANG_1.LANG_ISO[iso6391]] = '';
    }
    // -----
    for (var i in path.terms) {
        var term = path.terms[i];
        for (var _i = 0, _a = term.translations; _i < _a.length; _i++) {
            var translation = _a[_i];
            for (var _b = 0, _c = translation.langs; _b < _c.length; _b++) {
                var lang = _c[_b];
                var normalized = normalizeUrl_1.normalizeUrl(translation.value);
                if (!normalized) {
                    normalized = currents[LANG_1.LANG_ISO.FR][i];
                }
                if (normalized) {
                    currents[lang.iso6391].push(normalized);
                }
            }
        }
    }
    for (var iso6391 in LANG_1.LANG_ISO) {
        canonical[LANG_1.LANG_ISO[iso6391]] = currents[LANG_1.LANG_ISO[iso6391]].join('-');
    }
    return canonical;
}
exports.flattenPath = flattenPath;
// -----
function flattenUrl(url) {
    // -----
    // {'fr': 'accueil-genial/avis-1', 'en': 'home-super/advice-1'}
    var canonical = {};
    // {'fr': ['accueil-genial', 'avis-1'], 'en': ['home-super', 'advice-1']}
    var currents = {};
    // -----
    for (var iso6391 in LANG_1.LANG_ISO) {
        currents[LANG_1.LANG_ISO[iso6391]] = [];
        canonical[LANG_1.LANG_ISO[iso6391]] = '';
    }
    // -----
    url.sorts = url.sorts.sort(function (a, b) { return a.order - b.order; });
    // -----
    for (var _i = 0, _a = url.sorts; _i < _a.length; _i++) {
        var sort = _a[_i];
        for (var _b = 0, _c = sort.paths; _b < _c.length; _b++) {
            var path = _c[_b];
            var flattenedPath = flattenPath(path);
            for (var iso6391 in flattenedPath) {
                if (!flattenedPath[iso6391]) {
                    flattenedPath[iso6391] = flattenedPath[LANG_1.LANG_ISO.FR];
                }
                if (flattenedPath[iso6391]) {
                    currents[iso6391].push(flattenedPath[iso6391]);
                }
            }
        }
    }
    // -----
    for (var iso6391 in LANG_1.LANG_ISO) {
        canonical[LANG_1.LANG_ISO[iso6391]] = currents[LANG_1.LANG_ISO[iso6391]].join('/');
    }
    // -----
    return canonical;
}
exports.flattenUrl = flattenUrl;
function extractUrlInfoFromPage(nuxtProp, page, pathValues, debug) {
    // -----
    if (pathValues === void 0) { pathValues = []; }
    if (debug === void 0) { debug = false; }
    var pageSearchInfo = {
        found: false,
        page: new Page_1.Page(),
        canonical: {},
        types: []
    };
    // -----
    pathValues = pathValues.length ? pathValues : nuxtProp.url.paths.map(function (path) { return path.value; });
    // -----
    var currents = {};
    for (var iso6391 in LANG_1.LANG_ISO) {
        currents[LANG_1.LANG_ISO[iso6391]] = [LANG_1.LANG_ISO[iso6391]];
        pageSearchInfo.canonical[LANG_1.LANG_ISO[iso6391]] = '';
    }
    // -----
    page = convertPathToUrl(page);
    // -----
    // we define here the type of the page/url
    // -----
    if (page.categories.length)
        pageSearchInfo.types.push('category');
    if (page.collections.length)
        pageSearchInfo.types.push('collection');
    if (page.articles.length)
        pageSearchInfo.types.push('article');
    for (var _i = 0, _a = page.urls; _i < _a.length; _i++) {
        var url = _a[_i];
        // if project url
        if (url.projectUrls.length && !pageSearchInfo.types.includes('projectUrl')) {
            pageSearchInfo.types.push('projectUrl');
        }
        // if old mapped paths by constant -> considered like project url
        for (var _b = 0, _c = url.sorts; _b < _c.length; _b++) {
            var sort = _c[_b];
            for (var _d = 0, _e = sort.paths; _d < _e.length; _d++) {
                var path = _e[_d];
                if (Object.values(PATHS_1.PATHS).includes(path.id) && !pageSearchInfo.types.includes('projectUrl')) {
                    pageSearchInfo.types.push('projectUrl');
                }
            }
        }
    }
    // -----
    for (var _f = 0, _g = page.urls; _f < _g.length; _f++) {
        var url = _g[_f];
        var flattenedUrl = flattenUrl(url);
        for (var iso6391 in flattenedUrl) {
            if (!flattenedUrl[iso6391]) {
                flattenedUrl[iso6391] = flattenedUrl[LANG_1.LANG_ISO.FR];
            }
            if (flattenedUrl[iso6391])
                currents[iso6391].push(flattenedUrl[iso6391]);
        }
    }
    // -----
    for (var iso6391 in LANG_1.LANG_ISO) {
        pageSearchInfo.canonical[LANG_1.LANG_ISO[iso6391]] = currents[LANG_1.LANG_ISO[iso6391]].join('/');
        var pathValue = pathValues.join('/');
        var isRoot = false;
        if (pathValue === '')
            isRoot = true;
        if (Object.keys(LANG_1.LANG_ISO).map(function (a) { return a.toLowerCase(); }).includes(pathValue))
            isRoot = true;
        if (!pageSearchInfo.types.length)
            isRoot = true;
        if (pageSearchInfo.canonical[LANG_1.LANG_ISO[iso6391]] === pathValue && !isRoot) {
            pageSearchInfo.found = true;
            pageSearchInfo.page = page;
        }
    }
    // -----
    return pageSearchInfo;
}
exports.extractUrlInfoFromPage = extractUrlInfoFromPage;
function searchPageByNuxProp(nuxtProp, pathValues) {
    if (pathValues === void 0) { pathValues = []; }
    var pages = pageService_1.pageService.getPages();
    for (var _i = 0, pages_8 = pages; _i < pages_8.length; _i++) {
        var page = pages_8[_i];
        var urlInfo = extractUrlInfoFromPage(nuxtProp, page, pathValues);
        if (urlInfo.found)
            return page;
    }
    return new Page_1.Page();
}
exports.searchPageByNuxProp = searchPageByNuxProp;
function searchPageByPathIds(nuxtProp, pathIds) {
    var pages = pageService_1.pageService.getPages();
    for (var _i = 0, pages_9 = pages; _i < pages_9.length; _i++) {
        var page = pages_9[_i];
        var pathIds_ = [];
        for (var _a = 0, _b = page.paths; _a < _b.length; _a++) {
            var path = _b[_a];
            pathIds_.push(path.id);
        }
        if (pathIds_.join('/') === pathIds.join('/'))
            return page;
    }
    return new Page_1.Page();
}
exports.searchPageByPathIds = searchPageByPathIds;
function getPageByNuxtProp(nuxtProp, is404) {
    if (is404 === void 0) { is404 = function () { }; }
    var page = new Page_1.Page();
    // ------
    var pageMatch = searchPageByNuxProp(nuxtProp);
    if (pageMatch.id) {
        return pageMatch;
    }
    // ------
    var pageDefault = searchPageByPathIds(nuxtProp, [PATHS_1.PATHS.HOME]); // we get the default home page
    if (pageDefault.id) {
        is404();
        return pageMatch;
    }
    // ------
    return page;
}
exports.getPageByNuxtProp = getPageByNuxtProp;
function getNuxtPropPathIds(nuxtProp) {
    var pathIds = [];
    // ------
    for (var _i = 0, _a = nuxtProp.page.paths; _i < _a.length; _i++) {
        var path = _a[_i];
        if (!pathIds.includes(path.id))
            pathIds.push(path.id);
    }
    // -----
    for (var _b = 0, _c = nuxtProp.page.urls; _b < _c.length; _b++) {
        var url = _c[_b];
        for (var _d = 0, _e = url.sorts; _d < _e.length; _d++) {
            var sort = _e[_d];
            for (var _f = 0, _g = sort.paths; _f < _g.length; _f++) {
                var path = _g[_f];
                if (!pathIds.includes(path.id))
                    pathIds.push(path.id);
            }
        }
    }
    // ------
    return pathIds;
}
exports.getNuxtPropPathIds = getNuxtPropPathIds;
function nuxtQueriesToQueryObject(nuxtQueries) {
    var query = {};
    for (var _i = 0, nuxtQueries_1 = nuxtQueries; _i < nuxtQueries_1.length; _i++) {
        var nuxtQuery = nuxtQueries_1[_i];
        query[nuxtQuery.name] = nuxtQuery.value;
    }
    return query;
}
exports.nuxtQueriesToQueryObject = nuxtQueriesToQueryObject;
function nuxtUrlToRouteProps(nuxtUrl, page) {
    page = convertPathToUrl(page);
    var pathIds = getPathIdsByPage(page);
    var query = nuxtQueriesToQueryObject(nuxtUrl.queries);
    return new EventChangeRoute_1.EventChangeRoute({
        pathIds: pathIds,
        query: query
    });
}
exports.nuxtUrlToRouteProps = nuxtUrlToRouteProps;
function addPathQuery(nuxtQueries, name, value) {
    var found = false;
    for (var _i = 0, nuxtQueries_2 = nuxtQueries; _i < nuxtQueries_2.length; _i++) {
        var nuxtQuery = nuxtQueries_2[_i];
        if (nuxtQuery.name === name) {
            found = true;
            nuxtQuery.value = toJsonType_1.toJsonType(value);
        }
    }
    if (!found)
        nuxtQueries.push(new NuxtQuery_1.NuxtQuery({
            name: name,
            value: toJsonType_1.toJsonType(value)
        }));
    return nuxtQueries;
}
exports.addPathQuery = addPathQuery;
function getQueryByName(nuxtProp, name) {
    for (var _i = 0, _a = nuxtProp.url.queries; _i < _a.length; _i++) {
        var query = _a[_i];
        if (query.name === name)
            return query;
    }
}
exports.getQueryByName = getQueryByName;
function getQueryValueByName(nuxtProp, name, defaultValue) {
    var query = getQueryByName(nuxtProp, name);
    return query ? query.value : defaultValue;
}
exports.getQueryValueByName = getQueryValueByName;
function setNuxtUrlWithNuxtRoute(nuxtProp, nuxtRoute) {
    // -----
    var nuxtPaths = [];
    for (var i in nuxtRoute.params) {
        nuxtPaths.push(new NuxtPath_1.NuxtPath({
            value: toJsonType_1.toJsonType(nuxtRoute.params[i])
        }));
    }
    nuxtProp.url.paths = nuxtPaths;
    // -----
    var nuxtQueries = [];
    for (var i in nuxtRoute.query) {
        nuxtQueries.push(new NuxtQuery_1.NuxtQuery({
            name: i,
            value: toJsonType_1.toJsonType(nuxtRoute.query[i])
        }));
    }
    nuxtProp.url.queries = nuxtQueries;
    // -----
    return nuxtProp;
}
exports.setNuxtUrlWithNuxtRoute = setNuxtUrlWithNuxtRoute;
function setNuxtUrlWithChangeRouteProps(nuxtProp, changeRouteProps) {
    var _a;
    var langService = langServiceBrowser_1.getLangServiceBrowser();
    // -----
    var nuxtRoute = {
        params: (_a = {},
            _a[langService.lang.iso6391] = langService.lang.iso6391,
            _a),
        query: changeRouteProps.query
    };
    // -----
    if (changeRouteProps.pathIds && changeRouteProps.pathIds.length) {
        for (var _i = 0, _b = changeRouteProps.pathIds; _i < _b.length; _i++) {
            var pathId = _b[_i];
            var pathValue = urlService_1.urlService.getPathValueById(pathId, langService.lang.iso6391);
            nuxtRoute.params[pathValue] = pathValue;
        }
    }
    // -----
    if (changeRouteProps.nuxUrl) {
        nuxtProp.url = changeRouteProps.nuxUrl;
    }
    else {
        nuxtProp = setNuxtUrlWithNuxtRoute(nuxtProp, nuxtRoute);
    }
    // -----
    return nuxtProp;
}
exports.setNuxtUrlWithChangeRouteProps = setNuxtUrlWithChangeRouteProps;
function convertPathToUrl(pageOriginal) {
    var page = new Page_1.Page(pageOriginal);
    if (!page.urls.length) {
        var sorts_1 = [];
        page.paths.forEach(function (path, i) {
            sorts_1.push(new Sort_1.Sort({
                id: -1,
                order: i + 1,
                paths: [path],
            }));
        });
        page.urls = [
            new Url_1.Url({
                id: -1,
                sorts: sorts_1
            })
        ];
    }
    return page;
}
exports.convertPathToUrl = convertPathToUrl;
function setNuxtUrlWithWindowLocation(nuxtProp, windowLocation) {
    // -----
    nuxtProp = setNuxtUrlWithFullPath(nuxtProp, "" + windowLocation.pathname + windowLocation.search);
    // -----
    return nuxtProp;
}
exports.setNuxtUrlWithWindowLocation = setNuxtUrlWithWindowLocation;
function setNuxtUrlWithFullPath(nuxtProp, fullPath) {
    // -----
    var paths_ = fullPath.split('?');
    var pathname = paths_[0] || '';
    var search = paths_[1] || '';
    // -----
    pathname = pathname.replace(/^\//g, '').replace(/\/$/g, '');
    var paths = pathname.split('/');
    var nuxtPaths = [];
    for (var _i = 0, paths_1 = paths; _i < paths_1.length; _i++) {
        var path = paths_1[_i];
        nuxtPaths.push(new NuxtPath_1.NuxtPath({
            value: toJsonType_1.toJsonType(path)
        }));
    }
    nuxtProp.url.paths = nuxtPaths;
    // -----
    search = search.replace(/^\?/g, '');
    var searchs = search.split('&');
    searchs = searchs.filter(function (a) { return a; });
    var nuxtQueries = [];
    for (var _a = 0, searchs_1 = searchs; _a < searchs_1.length; _a++) {
        var search_1 = searchs_1[_a];
        var searchSplits = search_1.split('=');
        var name = searchSplits.shift();
        var value = searchSplits.shift();
        nuxtQueries.push(new NuxtQuery_1.NuxtQuery({
            name: name,
            value: toJsonType_1.toJsonType(value)
        }));
    }
    nuxtProp.url.queries = nuxtQueries;
    // -----
    return nuxtProp;
}
exports.setNuxtUrlWithFullPath = setNuxtUrlWithFullPath;
