import {deltaService} from "@bbx/delta~master/core/service/deltaService"
import {Order} from "./Order"
import {Address} from "./Address"
import {TransportCost} from "./TransportCost";
import {DeepPartial} from "../../type/DeepPartial";


export type Delivery_constructor_props = DeepPartial<Delivery>

export class  Delivery {

    id: number
    active: boolean
    createdAt: string
    updatedAt: string
    
    contactName: string
    contactPhone: string
    deliveryDate: string

    addresses: Address[]
    transportCosts: TransportCost[]
    orders: Order[]

    constructor(props: Delivery_constructor_props = {}) {
                this.id = deltaService.number(props.id, 0)
        this.active = deltaService.boolean(props.active, true)
        this.createdAt = deltaService.string(props.createdAt, '')
        this.updatedAt = deltaService.string(props.updatedAt, '')

        this.contactName = deltaService.string(props.contactName, '')
        this.contactPhone = deltaService.string(props.contactPhone, '')
        this.deliveryDate = deltaService.string(props.deliveryDate, '')

        this.addresses = deltaService.array(props.addresses, Address)
        this.transportCosts = deltaService.array(props.transportCosts, TransportCost)
        this.orders = deltaService.array(props.orders, Order)
    }
}
