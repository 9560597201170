<style scoped>
.InputTerm {
  position: relative;
}

.InputTerm ::v-deep .ql-toolbar.ql-snow {
  border-radius: 4px 4px 0px 0px;
}

.InputTerm ::v-deep .ql-container.ql-snow {
  border-radius: 0px 0px 4px 4px;
}

.InputTerm ::v-deep .el-input__inner {
  padding-right: 100px;
}

.InputTerm-showWordLimit ::v-deep .el-input__inner {
  padding-right: 150px;
}

.InputTerm ::v-deep .InputLabel {
  padding-bottom: 15px;
}

.InputTerm ::v-deep .InputError {
  padding-top: 10px;
}

.InputTerm--input {
  position: relative;
}

.InputTerm--flagLang {
  position: absolute;
  top: 10px;
  right: 0;
  display: flex;
  align-items: center;
}

.InputTerm-text .InputTerm--flagLang {
  top: 9px;
}

.InputTerm-showWordLimit .InputTerm--flagLang {
  right: 50px;
}

.InputTerm-textarea .InputTerm--flagLang {
  top: 10px;
}
</style>

<template>
  <div class="InputTerm" :class="class_root">
    <template v-if="label.id">
      <InputLabel :term="label" :svg="svg" :mandatory="mandatory"/>
    </template>
    <div class="InputTerm--input">
      <template v-for="translation in state.term.translations">
        <template v-if="selectLang(translation)">
          <template v-if="type === 'text'">
            <el-input v-model="translation.value" :placeholder="editorOption.placeholder" :maxlength="maxLength"
                      :disabled="disabled"
                      :show-word-limit="showWordLimit"></el-input>
          </template>
          <template v-if="type === 'textarea'">
            <quill-editor
                ref="editor"
                v-model="translation.value"
                :options="editorOption"
            ></quill-editor>
          </template>
        </template>
      </template>
      <div class="InputTerm--flagLang">
        <FlagLang :lang="state.lang" :langs="state.langs" :theme="THEME.DARK" :withHref="false"
                  :onChangeLang="v => state.lang = v"/>
      </div>
    </div>
    <template v-if="error.id">
      <InputError :term="error"/>
    </template>
  </div>
</template>

<script>

import {Term} from '../../../@common/delta/database/Term';
import {Lang} from "../../../@common/delta/database/Lang";

import {THEME} from "../../../@common/constant/THEME";

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

export default {
  name: 'InputTerm',

  props: {
    term: {
      default: () => new Term()
    },
    onChangeTerm: {
      type: Function,
      default: () => (term) => {
      }
    },
    svg: {
      type: String,
      default: () => ''
    },
    mandatory: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    maxLength: {
      type: Number,
      default: () => undefined
    },
    showWordLimit: {
      type: Boolean,
      default: () => false
    },
    fillEmptyTerm: {
      type: Boolean,
      default: () => true
    },
    lang: {
      default: () => new Lang()
    },
    onChangeLang: {
      type: Function,
      default: () => (lang) => {
      }
    },
    langs: {
      type: Array,
      default: () => ([])
    },
    type: {
      type: String,
      default: () => 'text'
    },
    error: {
      default: () => new Term()
    },
    label: {
      default: () => new Term()
    },
    fullOption: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    const langService = getLangServiceBrowser();

    let toolbar = []

    if (this.fullOption) {
      toolbar.push([
        {
          'header': [
            1,
            2,
            3,
            4,
            5,
            6,
            false
          ]
        }
      ])
    }

    toolbar.push([
      'bold',
      'italic',
      'underline'
    ])

    if (this.fullOption) {
      toolbar.push([
        {'list': 'ordered'},
        {'list': 'bullet'}
      ])
      toolbar.push([
        {'color': []},
        {'background': []}
      ])
    }

    return {
      THEME,
      langService: langService,
      editorOption: {
        theme: 'snow',
        placeholder: langService.translate(3),
        modules: {
          toolbar: toolbar
        }
      },
      state: {
        term: langService.fillEmptyTerm(this.term, false),
        lang: this.lang.id ? this.lang : new Lang(langService.lang),
        langs: this.langs.length ? this.langs : langService.langs.map(lang => new Lang(lang)),
      }
    }
  },
  watch: {
    'term': {
      handler: function (v) {
        this.state.term = this.langService.fillEmptyTerm(v, false)
      },
      deep: true
    },
    'state.term': {
      handler: function (v) {
        v.isHtml = this.type === 'textarea'
        this.onChangeTerm(v)
      },
      deep: true
    },
    'lang': {
      handler: function (v) {
        this.state.lang = v
      },
      deep: true
    },
    'state.lang': {
      handler: function (v) {
        this.onChangeLang(v)
      },
      deep: true
    }
  },
  computed: {
    class_root() {
      const classes = [];
      if (this.type === 'text') classes.push(`InputTerm-text`)
      if (this.type === 'textarea') classes.push(`InputTerm-textarea`)
      if (this.showWordLimit) classes.push(`InputTerm-showWordLimit`)
      return classes;
    }
  },
  beforeMount() {
  },
  methods: {
    /**
     * @param {Translation} translation
     * @returns {boolean}
     */
    selectLang(translation) {
      for (const lang of translation.langs) {
        if (lang.iso6391 === this.state.lang.iso6391) return true
      }
      return false
    },
  }
}
</script>
