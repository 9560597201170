import ApiRegistery from '@bbx/api~master/core/delta/ApiRegistery';

import {Forum_constructor_props} from '../../delta/database/Forum'
import {ApiRequest_constructor_props as ApiRequest} from "../../delta/http/ApiRequest";
import {ApiResponse} from "../../delta/http/ApiResponse";

export interface Request extends Forum_constructor_props {
    $deviceMac?: string
}

export interface Response extends Forum_constructor_props {
}

export default new ApiRegistery<Request & ApiRequest, ApiResponse<Response>>({
    name: 'createForum',
})
