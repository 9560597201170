<style>
.CountryLocation {
  all: unset;
  display: none;
}

.CountryLocation-alternate {
  display: initial;
}

.CountryLocation-canDisplay {
  display: initial;
}
</style>

<template>
  <div class="CountryLocation" :class="class_root">
    <slot v-if="canDisplay" name="default"></slot>
    <slot v-else name="alternate"></slot>
  </div>
</template>

<script>

import {eventService} from "../../service/eventService";
import {EVENT} from "../../../@common/constant/EVENT";
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {canDisplay, getCountry} from "../../service/countryService";
import SCREEN from "@bbx/responsive~master/core/constant/SCREEN";
import {seoService} from "../../service/seoService";

export default {
  props: {
    alternate: {
      type: Boolean,
      default: () => false
    },
    displayAnyway: {
      type: Boolean,
      default: () => false
    }
  },
  data() {
    return {
      state: {
        country: ''
      },
      events: []
    }
  },
  beforeMount() {

    // -----

    this.state.country = getCountry()

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.CHANGE_COUNTRY_LOCATION,
      callback: (country) => {
        this.state.country = country
      }
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  computed: {
    class_root() {
      const classes = []
      if (this.canDisplay) classes.push('CountryLocation-canDisplay')
      if (this.alternate) classes.push('CountryLocation-alternate')
      return classes
    },
    canDisplay() {
      if (seoService.isActive) return true
      return canDisplay(this.state.country) || this.displayAnyway
    }
  },
  methods: {}
}
</script>
