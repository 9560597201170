<style scoped>
.OrderForm {
}
</style>

<template>
  <div class="OrderForm" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">

      <InputSection :svg="icon.subject" :term="lang.term(417)">
        <InputText :value="state.order.number"
                   :mandatory="true"
                   :onChangeValue="v => state.order.number = v"
                   :label="lang.term(582)"
                   :error="form.display(`number`)">
        </InputText>
      </InputSection>

    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {Order} from "../../../@common/delta/database/Order";

export default {

  props: {
    order: {
      default: () => new Order()
    },
    onOrderChange: {
      type: Function,
      default: () => (order) => {
      }
    },
    form: {
      default: () => new ApiResponseForm()
    },
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
        style: require('@bbx/vector~master/core/assets/svg/material/style.svg'),
        subject: require('@bbx/vector~master/core/assets/svg/material/subject.svg'),
        phone: require('@bbx/vector~master/core/assets/svg/material/phone.svg'),
        people: require('@bbx/vector~master/core/assets/svg/material/people.svg'),
      },
      state: {
        /**
         * @type Order
         */
        order: new Order(this.order),
      },
    }
  },
  watch: {
    'order': {
      handler(v) {
        this.state.order = v
      },
      deep: true
    },
    'state.order': {
      handler(v) {
        this.onOrderChange(v)
      },
      deep: true
    },
  },
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`OrderForm-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`OrderForm-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`OrderForm-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`OrderForm-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`OrderForm-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {

  }
}
</script>
