"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Promotion = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Article_1 = require("./Article");
var PromotionCode_1 = require("./PromotionCode");
var Shop_1 = require("./Shop");
var Promotion = /** @class */ (function () {
    function Promotion(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.percentage = deltaService_1.deltaService.number(props.percentage, 0);
        this.articles = deltaService_1.deltaService.array(props.articles, Article_1.Article);
        this.promotionCodes = deltaService_1.deltaService.array(props.promotionCodes, PromotionCode_1.PromotionCode);
        this.shops = deltaService_1.deltaService.array(props.shops, Shop_1.Shop);
    }
    return Promotion;
}());
exports.Promotion = Promotion;
