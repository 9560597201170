"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Funding = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Order_1 = require("./Order");
var Address_1 = require("./Address");
var Loan_1 = require("./Loan");
var Deposit_1 = require("./Deposit");
var FundingOptionList_1 = require("./FundingOptionList");
var Payment_1 = require("./Payment");
var Funding = /** @class */ (function () {
    function Funding(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.totalArticleHT = deltaService_1.deltaService.number(props.totalArticleHT, 0);
        this.totalEcoParticipationHT = deltaService_1.deltaService.number(props.totalEcoParticipationHT, 0);
        this.totalTransportCostHT = deltaService_1.deltaService.number(props.totalTransportCostHT, 0);
        this.addresses = deltaService_1.deltaService.array(props.addresses, Address_1.Address);
        this.loans = deltaService_1.deltaService.array(props.loans, Loan_1.Loan);
        this.deposits = deltaService_1.deltaService.array(props.deposits, Deposit_1.Deposit);
        this.fundingOptionLists = deltaService_1.deltaService.array(props.fundingOptionLists, FundingOptionList_1.FundingOptionList);
        this.payments = deltaService_1.deltaService.array(props.payments, Payment_1.Payment);
        this.orders = deltaService_1.deltaService.array(props.orders, Order_1.Order);
    }
    return Funding;
}());
exports.Funding = Funding;
