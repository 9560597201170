import {deltaService} from "@bbx/delta~master/core/service/deltaService"
import {Product} from "./Product"
import {Package} from "./Package"
import {DeepPartial} from "../../type/DeepPartial";


export type Size_constructor_props = DeepPartial<Size>

export class  Size {

    id: number
    active: boolean
    createdAt: string
    updatedAt: string
    
    length: number // longueur
    width: number // largeur
    height: number // hauteur
    weight: number // poids

    products: Product[]
    packages: Package[]

    constructor(props: Size_constructor_props = {}) {
                this.id = deltaService.number(props.id, 0)
        this.active = deltaService.boolean(props.active, true)
        this.createdAt = deltaService.string(props.createdAt, '')
        this.updatedAt = deltaService.string(props.updatedAt, '')

        this.length = deltaService.number(props.length, 0)
        this.width = deltaService.number(props.width, 0)
        this.height = deltaService.number(props.height, 0)
        this.weight = deltaService.number(props.weight, 0)

        this.products = deltaService.array(props.products, Product)
        this.packages = deltaService.array(props.packages, Package)
    }
}
