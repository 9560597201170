<style scoped>
.AccountNotification {
  min-height: 100vh;
}

.AccountNotification--content {
  padding: 50px;
  max-width: 500px;
}

.AccountNotification--footer {
  display: flex;
}
</style>

<template>
  <div class="AccountNotification" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="AccountNotification--content">
        <div v-show="!state.loadingSelect">
          <InputSwitch :label="lang.term(123)"
                       :value="state.newsletter.active"
                       :onChangeValue="v => state.newsletter.active = v">
          </InputSwitch>
          <br>
          <Divider/>
          <br>
          <div class="AccountNotification--footer">
            <ButtonAction :term="lang.term(274)" :onClick="() => save()" :loading="state.loadingCreate"/>
          </div>
        </div>
        <LoaderSpace v-show="state.loadingSelect"></LoaderSpace>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {Newsletter} from "../../../@common/delta/database/Newsletter";
import {controlForm} from "../../function/controlForm";
import createNewsletter from "../../../@common/api/newsletter/createNewsletter";
import {STATE_TOKEN, stateService} from "../../service/stateService";
import selectNewsletters from "../../../@common/api/newsletter/selectNewsletters";
import {apiClient} from "../../service/apiClientService";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import selectUsers from "../../../@common/api/user/selectUsers";
import {User} from "../../../@common/delta/database/User";
import {sendError} from "../../function/sendError";

export default {

  props: {},
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      state: {
        token: '',
        loadingSelect: false,
        loadingCreate: false,
        form: new ApiResponseForm(),
        newsletter: new Newsletter(),
        user: new User(),
      },
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
    this.state.token = stateService.get(STATE_TOKEN)
    stateService.watch(STATE_TOKEN, v => this.state.token = v)
    this.init().catch(err => {
      sendError(err);
    });
  },
  beforeDestroy() {
  },
  methods: {
    async init() {
      this.state.loadingSelect = true
      await this.selectUser()
      await this.selectNewsletter()
      this.state.loadingSelect = false;
    },
    async selectUser() {
      const {data} = await apiClient.do(selectUsers, selectUsers.with({
        $token: this.state.token
      }))
      this.state.user = new User(data[0]);
    },
    async selectNewsletter() {
      const {data} = await apiClient.do(selectNewsletters, selectNewsletters.with({
        email: this.state.user.email
      }))
      const newsletter = new Newsletter(data[0])
      if (!newsletter.id) newsletter.active = false
      newsletter.email = this.state.user.email
      this.state.newsletter = newsletter
    },
    async save() {
      await controlForm({
        apiAction: createNewsletter,
        formAttribute: 'form',
        formState: this.state,
        loaderAttribute: 'loadingCreate',
        loaderState: this.state,
        payload: this.state.newsletter,
        message: this.lang.translate(322),
        vue: this,
      });
      await this.init()
    }
  }
}
</script>
