<style scoped>
.Visible {
  width: 100%;
  height: 100%;
}

.Visible--animation {
  width: inherit;
  height: inherit;
}
</style>

<template>
  <div class="Visible" :class="class_root" ref="root">
    <pre v-if="debug">{{ state }}</pre>
    <div class="Visible--animation">
      <slot></slot>
    </div>
  </div>
</template>

<script>

import {deltaService} from "@bbx/delta~master/core/service/deltaService";
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {EVENT} from "../../../@common/constant/EVENT";
import {eventService} from "../../service/eventService";
import {seoService} from "../../service/seoService";

export default {
  props: {
    debug: {
      type: Boolean,
      default: () => false
    },
    onVisibilityChanged: {
      type: Function,
      default: () => ({isVisible, sVisibleFully}) => {
      }
    },
    registerCallback: {
      type: Function,
      default: () => ({onScroll, onResize}) => {
      }
    },
  },
  data() {
    return {
      state: {
        isVisible: false,
        isVisibleFully: false,
        timeOut: null,
      },
      events: []
    }
  },
  computed: {
    class_root() {
      const classes = []
      if (this.state.isVisible) classes.push(`Visible-isVisible`)
      if (this.state.isVisibleFully) classes.push(`Visible-isVisibleFully`)
      return classes
    }
  },
  beforeMount() {

    // -----

    this.registerCallback({
      onScroll: props => this.onScroll(props),
      onResize: props => this.onResize(props),
    })

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.SCROLL_CHANGE,
      callback: ({top, left}) => this.onScroll({top, left}),
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.SIZE_CHANGED,
      callback: () => this.onResize()
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
    this.checkVisibility()
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  watch: {
    'state.isVisible': function () {
      this.onVisibilityChanged({
        isVisible: this.state.isVisible,
        isVisibleFully: this.state.isVisibleFully
      })
    },
    'state.isVisibleFully': function () {
      this.onVisibilityChanged({
        isVisible: this.state.isVisible,
        isVisibleFully: this.state.isVisibleFully
      })
    }
  },
  methods: {
    checkVisibility(props = {top: 0, left: 0}) {

      // -----
      // if we are in SEO mode all is visible
      // -----

      if (seoService.isActive) {
        this.state.isVisibleFully = true
        this.state.isVisible = true
        return
      }

      // -----

      const top = deltaService.number(props.top, 0)
      const left = deltaService.number(props.left, 0)

      if (!this.$refs.root) return

      const rect = this.$refs.root.getBoundingClientRect()

      const screenLeft = left
      const screenTop = top
      const screenRight = left + window.innerWidth
      const screenBottom = top + window.innerHeight

      const elementLeft = rect.left + screenLeft
      const elementTop = rect.top + screenTop
      const elementRight = elementLeft + rect.width
      const elementBottom = elementTop + rect.height

      const inLineLeft = elementLeft >= screenLeft && elementLeft <= screenRight
      const inLineTop = elementTop >= screenTop && elementTop <= screenBottom
      const inLineRight = elementRight >= screenLeft && elementRight <= screenRight
      const inLineBottom = elementBottom >= screenTop && elementBottom <= screenBottom

      const inOverSizeHeight = elementTop < screenTop && elementBottom > screenBottom
      const inOverSizeWidth = elementLeft < screenLeft && elementRight > screenRight

      this.state.isVisibleFully = inLineLeft && inLineTop && inLineRight && inLineBottom
      this.state.isVisible = (inLineTop && inLineLeft) || (inLineTop && inLineRight) || (inLineBottom && inLineRight) || (inLineBottom && inLineLeft) || inOverSizeHeight || inOverSizeWidth
    },
    onScroll({top, left} = {top: 0, left: 0}) {
      if (this.state.timeOut) clearTimeout(this.state.timeOut)
      this.state.timeOut = setTimeout(() => this.checkVisibility({top, left}))
    },
    onResize({top, left} = {top: 0, left: 0}) {
      if (this.state.timeOut) clearTimeout(this.state.timeOut)
      this.state.timeOut = setTimeout(() => this.checkVisibility({top, left}))
    }
  }
}
</script>