"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.scrollerParentService = void 0;
var ScrollerParent = /** @class */ (function () {
    function ScrollerParent() {
        this.scrollLeft = 0;
        this.scrollTop = 0;
        this.elementHTML = null;
        this.isTactile = false;
    }
    ScrollerParent.prototype.onScrollLeft = function (scrollLeft) {
    };
    ScrollerParent.prototype.onScrollTop = function (scrollTop) {
    };
    ScrollerParent.prototype.setScrollLeft = function (scrollLeft) {
        this.scrollLeft = scrollLeft;
        this.onScrollLeft(scrollLeft);
    };
    ScrollerParent.prototype.setScrollTop = function (scrollTop) {
        this.scrollTop = scrollTop;
        this.onScrollTop(scrollTop);
    };
    ScrollerParent.prototype.scrollTo = function (scrollLeft, scrollTop) {
        this.setScrollLeft(scrollLeft);
        this.setScrollTop(scrollTop);
    };
    ScrollerParent.prototype.scrollToElement = function (selector, adjust) {
        if (adjust === void 0) { adjust = function (v) { return v; }; }
        var el = document.querySelector(selector);
        if (el)
            this.scrollTo(0, adjust(el.offsetTop));
    };
    return ScrollerParent;
}());
exports.scrollerParentService = new ScrollerParent();
