<style scoped>
.InputSection {
}

.InputSection--header {
  background: rgba(0, 0, 0, 0.03);
  border-left: solid 2px black;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 15px;
  margin: 50px 0;
}

.InputSection--vector {
  width: 24px;
  height: 24px;
  padding-right: 15px;
}

.InputSection--term {
  font-family: 'RalewayBold', sans-serif;
  text-transform: uppercase;
  letter-spacing: 1px;
  font-size: 12px;
}

.InputSection--left {
  display: flex;
  align-items: center;
}

.InputSection--right {
  display: flex;
  align-items: center;
}

.InputSection--count {
  font-family: sans-serif;
  border: solid 1px rgba(0, 0, 0, 0.1);
  width: 28px;
  height: 28px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  justify-content: center;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.75);
  transition: all 300ms;
}

.InputSection:hover .InputSection--count {
  border: solid 1px rgba(0, 0, 0, 0.25);
  color: rgba(0, 0, 0, 1);
}

.InputSection--add {
  width: 18px;
  height: 18px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  fill: black;
  transition: all 300ms;
  margin-left: 15px;
}

.InputSection:hover .InputSection--add ::v-deep .Vector svg {
  transition: all 300ms;
}

.InputSection:hover .InputSection--add {
  background: rgba(0, 0, 0, 1);
}

.InputSection:hover .InputSection--add ::v-deep .Vector svg {
  fill: white;
}

.InputSection--remove {
  width: 18px;
  height: 18px;
  background: rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 5px;
  cursor: pointer;
  fill: black;
  transition: all 300ms;
  margin: 0 15px 0 15px;
}

.InputSection--remove:hover ::v-deep .Vector svg {
  transition: all 300ms;
}

.InputSection--remove:hover {
  background: rgba(0, 0, 0, 1);
}

.InputSection--remove:hover ::v-deep .Vector svg {
  fill: white;
}

.InputSection--slot {
  display: flex;
  align-items: center;
  position: relative;
  margin-bottom: 15px;
  padding-left: 20px;
}

.InputSection--increment {
  border-radius: 2px;
  width: 2px;
  height: 100%;
  background: rgba(0, 0, 0, 1);
  position: absolute;
  top: 0;
  left: 0;
}

.InputSection--item {
  flex: 1;
}

.InputSection--index {
  position: absolute;
  top: 50%;
  margin-top: -9px;
  left: -8px;
  width: 18px;
  height: 18px;
  box-sizing: border-box;
  border: solid 2px black;
  font-size: 8px;
  border-radius: 18px;
  background: black;
  color: white;
  font-family: sans-serif;
  display: flex;
  justify-content: center;
  align-items: center;
}
</style>

<template>
  <div class="InputSection">
    <div class="InputSection--header">
      <div class="InputSection--left">
        <div class="InputSection--vector">
          <Vector :svg="svg"/>
        </div>
        <div class="InputSection--term">
          <Term :term="term"/>
        </div>
      </div>
      <div class="InputSection--right">
        <div class="InputSection--count" v-show="multiple && items.filter(onFilter).length">
          {{ items.filter(onFilter).length }}
        </div>
        <template v-if="multiple && addable">
          <Tooltip :text="lang.translate(194)">
            <div class="InputSection--add" @click="() => _onClickAdd()">
              <Vector :svg="icon.add"/>
            </div>
          </Tooltip>
        </template>
      </div>
    </div>
    <div class="InputSection--body">
      <slot name="header"></slot>
      <template v-if="multiple">
        <template v-for="(item, index_item) in items">
          <div class="InputSection--slot" v-show="onFilter(item, index_item)" :key="`${item.id}.${index_item}`">
            <div class="InputSection--increment">
              <div class="InputSection--index">{{ index_item + 1 }}</div>
            </div>
            <div class="InputSection--item">
              <slot :item="item" :index="index_item"/>
            </div>
            <div class="InputSection--remove" @click="() => _onClickRemove(index_item)">
              <Vector :svg="icon.delete_forever"/>
            </div>
          </div>
        </template>
      </template>
      <template v-if="!multiple">
        <slot/>
      </template>
    </div>
  </div>
</template>

<script>

import cloneDeep from 'lodash/cloneDeep'
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {Term} from "../../../@common/delta/database/Term";

export default {

  props: {
    svg: {
      type: String,
      default: () => ''
    },
    term: {
      default: () => new Term()
    },
    onClickAdd: {
      type: Function,
      default: () => () => {
      }
    },
    onClickRemove: {
      type: Function,
      default: () => () => {
      }
    },
    onItemChange: {
      type: Function,
      default: () => () => {
      }
    },
    onFilter: {
      type: Function,
      default: () => () => true
    },
    multiple: {
      type: Boolean,
      default: () => false
    },
    addable: {
      type: Boolean,
      default: () => false
    },
    cloned: {
      type: Boolean,
      default: () => false
    },
    items: {
      type: Array,
      default: () => ([])
    },
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {},
      icon: {
        add: require('@bbx/vector~master/core/assets/svg/material/add.svg'),
        delete_forever: require('@bbx/vector~master/core/assets/svg/material/delete_forever.svg'),
      },
      state: {
        items: this.cloned ? cloneDeep(this.items) : this.items, // use or not he default pointer of js
        userFirstName: ''
      },
    }
  },
  watch: {
    'items': function (v) {
      this.state.items = v
    },
    'state.items': function (v) {
      this.onItemChange(v);
    }
  },
  computed: {},
  beforeMount() {

  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    _onClickAdd() {
      this.onClickAdd();
    },
    _onClickRemove(index) {
      if (!this.cloned) this.state.items.splice(index, 1)
      this.onClickRemove(index);
    }
  }
}
</script>
