"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Payment = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Paypal_1 = require("./Paypal");
var Funding_1 = require("./Funding");
var SystemPay_1 = require("./SystemPay");
var PaymentEngineList_1 = require("./PaymentEngineList");
var PaymentStateList_1 = require("./PaymentStateList");
var Payment = /** @class */ (function () {
    function Payment(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.amount = deltaService_1.deltaService.number(props.amount, 0);
        this.dateDeadline = deltaService_1.deltaService.string(props.dateDeadline, '');
        this.paypals = deltaService_1.deltaService.array(props.paypals, Paypal_1.Paypal);
        this.systemPays = deltaService_1.deltaService.array(props.systemPays, SystemPay_1.SystemPay);
        this.paymentEngineLists = deltaService_1.deltaService.array(props.paymentEngineLists, PaymentEngineList_1.PaymentEngineList);
        this.paymentStateLists = deltaService_1.deltaService.array(props.paymentStateLists, PaymentStateList_1.PaymentStateList);
        this.fundings = deltaService_1.deltaService.array(props.fundings, Funding_1.Funding);
    }
    return Payment;
}());
exports.Payment = Payment;
