"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.normalizeUrl = void 0;
function normalizeUrl(str) {
    str = str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
    str = str.trim();
    str = str.replace(/[\s]+/g, "-");
    str = str.replace(/[^a-zA-Z0-9-]+/g, "");
    str = str.replace(/[-]+/g, "-");
    str = str.replace(/^-/g, "");
    str = str.replace(/-$/g, "");
    str = str.toLowerCase();
    return str;
}
exports.normalizeUrl = normalizeUrl;
