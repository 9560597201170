import {Shop} from "../delta/database/Shop";
import {getFlatAddress} from "./getFlatAddress";

export function getFlatAddressByShop(shop: Shop, symbol = ', ') {

    const addresses = [
        shop.name,
    ]

    for (const address of shop.addresses) {
        addresses.push(getFlatAddress(address))
    }

    return addresses.join(symbol)
}
