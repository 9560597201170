<style scoped>
.MaterialArticle {
}

.MaterialArticle--body {
  display: flex;
}

.MaterialArticle--picture {
  width: 46px;
  height: 46px;
  min-width: 46px;
  min-height: 46px;
  background: rgba(0, 0, 0, 0.03);
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 300ms;
}

.MaterialArticle:hover .MaterialArticle--picture {
  background: rgba(0, 0, 0, 0.06);
}

.MaterialArticle--cercle {
  width: 40%;
  height: 40%;
  border: solid 2px rgba(0, 0, 0, 1);
  border-radius: 100%;
  overflow: hidden;
}

.MaterialArticle--term {
  font-family: 'DDinBold', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  padding-left: 15px;
  transition: color 300ms;
}

.MaterialArticle:hover .MaterialArticle--term {
  color: rgba(0, 0, 0, 1);
}
</style>

<template>
  <div class="MaterialArticle" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="MaterialArticle--body">
        <div class="MaterialArticle--picture">
          <div class="MaterialArticle--cercle">
            <template v-for="photo in material.photos">
              <template v-for="file in photo.files">
                <Picture :file="file"></Picture>
              </template>
            </template>
          </div>
        </div>
        <div class="MaterialArticle--term">
          <template v-for="term in material.terms">
            <Term :term="term"></Term>
          </template>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {Material} from "../../../@common/delta/database/Material";

export default {
  props: {
    material: {
      type: Material,
      default: () => new Material
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      state: {},
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`MaterialArticle-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`MaterialArticle-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`MaterialArticle-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`MaterialArticle-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`MaterialArticle-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
