<style scoped>
img.intext {
  max-width: 150px;
}

img.intext-right {
  float: right;
  margin-left: 20px;
}

img.intext-left {
  float: left;
  margin-right: 20px;
}
</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="content-wrapper none for-title">
      <div class="container_12">
        <div class="main-container main-main-container col1-layout">
          <div class="grid_12 col-main">
            <div class="page-title">
              <h1>Comment choisir son salon de jardin ? - Les Jardins Vente Privée</h1>
            </div>
            <div class="std">
              <p style="text-align: justify;">
                <img alt="" src="./media/wysiwyg/8.jpg">
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Les salons en bois de teck ont beaucoup de succès depuis de nombreuses
                  années, leur style intemporel, la résistance et la qualité du matériau ayant fait leurs preuves.
                  Solide et imputrescible, le
                  mobilier en teck
                  fait le bonheur des amateurs de beaux meubles. Les salons de jardin en teck se déclinent à l’infini,
                  de l’entrée de gamme aux modèles de luxe. Voici donc quelques conseils pour bien
                  <b>choisir votre mobilier de jardin en teck</b>
                  .
                </span>
              </p>
              <h2 style="text-align: justify;">Les dimensions
                <img style="float: right; padding-left: 20px;" alt="" src="./media/wysiwyg/img19.jpg">
              </h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Si votre espace est réduit, comme un balcon par exemple, optez pour du
                  <b style="font-size: 12px;">mobilier pliable</b>
                  à l’image des&nbsp;fauteuils pliables en teck&nbsp;ou des&nbsp;tabourets de bar pliants en teck&nbsp;également.
                  Si au contraire, votre jardin est vaste, vous pouvez choisir une table de grandes dimensions avec du
                  <b style="font-size: 12px;">mobilier fixe</b>
                  , et pourquoi pas un banc ou un salon de jardin pour profiter au mieux du soleil. Le mobilier ne
                  craignant pas les intempéries, vous pourrez laisser le tout en place en hors-saison sans-soucis.&nbsp;
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <h2 style="text-align: justify;">L’usage</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Vos meubles doivent correspondre à l’emploi que vous voulez en faire.
                  Pour inviter de nombreuses personnes à dîner, favorisez
                  des assises confortables
                  et une large table
                  .
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Si votre salon de jardin est plutôt là pour la détente, des
                  <b>transats et autres chaises longues</b>
                  seront à préférer. Complétez ensuite votre déco avec des coussins de couleur.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Créez un espace cosy pour recevoir vos invités à l’ombre d’un parasol.
                  Vous aurez ainsi le choix entre une&nbsp;petite table basse de 60x60 cm, une&nbsp;table basse 110x110
                  cm en teck, en allant jusqu’à des tables pouvant accueillir jusqu’à 12 personnes (dimensions
                  2017/320x106 cm par exemple).
                </span>
              </p>
              <h2 style="text-align: justify;">L’environnement
                <img style="float: right; padding-left: 20px;" alt="" src="./media/wysiwyg/img20.jpg">
              </h2>
              <p style="text-align: justify;">&nbsp;</p>
              <p>
                <span style="font-size: large;">Adaptez votre salon en fonction du style de votre jardin. Pour les
                  <b>jardins contemporains</b>
                  , optez pour des meubles en teck avec une finition moderne associée à des textiles unis. Pour mettre
                  en valeur vos meubles, optez pour des tons chauds comme le violet ou l’orange.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p>
                <span style="font-size: large;">Les
                  <b>jardins romantiques</b>
                  seront mis en valeur par des meubles aux lignes courbes et des textiles fleuris. Les tons pastel
                  s’accordent bien avec la couleur miel du teck.
                </span>
              </p>
              <h2 style="text-align: justify;">Le choix du bois</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le
                  <b>teck</b>
                  est une matière très prisée pour sa beauté, sa noblesse, le fait qu’il permette de concevoir un
                  mobilier résistant aux intempéries et puisqu’il ne nécessite que peu d’entretien. Il rencontre depuis
                  plusieurs années un franc succès pour le mobilier d’extérieur. De ce fait, les forêts sont
                  surexploitées. Pour les protéger, deux solutions s’offrent à vous : achetez du teck provenant de
                  <b>forêts gérées durablement</b>
                  ou du
                  <b>teck recyclé</b>
                  .
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le teck recyclé provient des anciens navires, de vieilles charpentes ou
                  encore de vieux entrepôts. C’est un bois qui a une histoire et une beauté unique. Le teck qui provient
                  d’une forêt dont la gestion est durable est quant à lui issu d’exploitations s’engageant à replanter
                  des arbres pour assurer la sauvegarde de ces forêts de bois exotique.
                </span>
              </p>
              <h3 style="text-align: justify;">Les grades du teck</h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">Pour bien choisir son teck, il est nécessaire de connaître les
                  différents grades. Trois types de qualité sont ainsi définis :
                </span>
              </p>
              <ul style="text-align: justify;">
                <li>
                  <span style="font-size: large;">Le
                    <strong>grade C</strong>
                    est la plus basse qualité. Chimiquement traité, c'est le bois le plus fragile et le moins beau.
                  </span>
                </li>
                <li>
                  <span style="font-size: large;">Le
                    <strong>grade B</strong>
                    possède une couleur plus homogène. Toutefois, un nombre de nœuds (environ 5%) est toléré.
                  </span>
                </li>
                <li>
                  <span style="font-size: large;">Le
                    <strong>grade A</strong>
                    est naturellement plus beau, réalisé à partir de bois de plus de 30 ans, sa couleur est uniforme.
                    C'est un bois solide qui provient du cœur de l'arbre.
                  </span>
                </li>
              </ul>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../../function/getH1";
import {Term} from "../../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../../@common/constant/PROJECT_URL";
import {PATHS} from "../../../../@common/constant/PATHS";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    },
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FAQ-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
