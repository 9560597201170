"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.findDefaultArticleTermNames = void 0;
var Article_1 = require("../../@common/delta/database/Article");
var Term_1 = require("../../@common/delta/database/Term");
function findDefaultArticleTermNames(articleRaw) {
    var article = new Article_1.Article(articleRaw);
    if (article.isBatch)
        return article.termNames;
    var terms = [];
    for (var _i = 0, _a = article.batches; _i < _a.length; _i++) {
        var batch = _a[_i];
        for (var _b = 0, _c = batch.products; _b < _c.length; _b++) {
            var product = _c[_b];
            for (var _d = 0, _e = product.termNames; _d < _e.length; _d++) {
                var term = _e[_d];
                terms.push(new Term_1.Term(term));
            }
        }
    }
    return terms;
}
exports.findDefaultArticleTermNames = findDefaultArticleTermNames;
