<style scoped>
.InputText {
}

.InputText-svgSuffix ::v-deep .el-input input {
  padding-right: 55px;
}

.InputText ::v-deep .InputLabel {
  padding-bottom: 15px;
}

.InputText ::v-deep .InputError {
  padding-top: 10px;
}

.InputText--iconSuffix {
  width: 40px;
  height: 40px;
  position: absolute;
  top: 0;
  right: 0;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.InputText--iconSuffix ::v-deep .Vector {
  width: 18px;
  height: 18px;
}

.InputText .InputText--iconSuffix ::v-deep .Vector svg {
  opacity: 0.25;
  transition: opacity 300ms;
}

.InputText:hover .InputText--iconSuffix ::v-deep .Vector svg {
  opacity: 1;
}

.InputText--input {
  position: relative;
}
</style>

<template>
  <div class="InputText" :class="class_root">
    <InputLabel v-if="label.id" :term="label" :svg="svg" :mandatory="mandatory"/>
    <div class="InputText--input" :class="class_root">
          <el-input v-model="state.value"
                   :placeholder="state.placeholder"
                   :show-password="password"
                   :clearable="clearable"
                   :type="type"
                   :rows="rows"
                   :disabled="disabled">
          </el-input>
      <div class="InputText--iconSuffix" @click="() => onClickSuffix()" v-if="svgSuffix">
        <Vector :svg="svgSuffix"/>
      </div>
    </div>
    <InputError v-if="error.id" :term="error"/>
  </div>
</template>

<script>
import {Term as TermDelta} from '../../../@common/delta/database/Term.ts'
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

export default {

  props: {
    svg: {
      type: String,
      default: () => ''
    },
    svgSuffix: {
      type: String,
      default: () => ''
    },
    mandatory: {
      type: Boolean,
      default: () => false
    },
    clearable: {
      type: Boolean,
      default: () => false
    },
    disabled: {
      type: Boolean,
      default: () => false
    },
    password: {
      type: Boolean,
      default: () => false
    },
    value: {
      type: String,
      default: () => ''
    },
    rows: {
      type: Number,
      default: () => 1
    },
    onChangeValue: {
      type: Function,
      default: () => () => {
      }
    },
    onClickSuffix: {
      type: Function,
      default: () => () => {
      }
    },
    error: {
      default: () => new TermDelta()
    },
    label: {
      default: () => new TermDelta()
    },
    placeholder: {
      type: String,
      default: () => ''
    },
    type: {
      type: String,
      default: () => 'text'
    }
  },
  data() {
    const lang = getLangServiceBrowser()
    return {
      props: {},
      state: {
        value: this.value,
        placeholder: this.placeholder ? this.placeholder : lang.translate(104)
      },
    }
  },
  watch: {
    'value': function (v) {
      this.state.value = v;
    },
    'state.value': function (v) {
      this.onChangeValue(v)
    }
  },
  computed: {
    class_root() {
      const classes = []
      if (this.svgSuffix) classes.push('InputText-svgSuffix')
      return classes;
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
