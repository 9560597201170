<style scoped>
.LegalCguCgv {

}

.LegalCguCgv--body {
}
</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="LegalCguCgv--body">
      <h1>Informations légales</h1>
      <p>
        <b>Dernière modification le :</b>
        <span>15/03/2021</span>
      </p>
      <br>
      <br>
      <br>
      <Term :term="lang.term(333)" type="textarea" :fullOption="true"/>
    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../function/getH1";
import {Term} from "../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../@common/constant/PROJECT_URL";
import {PATHS} from "../../../@common/constant/PATHS";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      PROJECT_URL,
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    lang() {
      return getLangServiceBrowser()
    },
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`LegalCguCgv-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`LegalCguCgv-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`LegalCguCgv-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`LegalCguCgv-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`LegalCguCgv-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    }
  }
}
</script>
