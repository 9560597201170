"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.changeRoute = void 0;
var eventService_1 = require("../service/eventService");
var ListenerTrigger_1 = require("@bbx/listener~master/core/delta/ListenerTrigger");
var EVENT_1 = require("../../@common/constant/EVENT");
var EventChangeRoute_1 = require("../../@common/delta/event/EventChangeRoute");
function changeRoute(pathIds, query, raw, nuxUrl) {
    if (query === void 0) { query = {}; }
    eventService_1.eventService.triggerByName(new ListenerTrigger_1.ListenerTrigger({
        name: EVENT_1.EVENT.CHANGE_ROUTE,
        payload: new EventChangeRoute_1.EventChangeRoute({
            pathIds: pathIds,
            query: query,
            raw: raw,
            nuxUrl: nuxUrl
        })
    }));
}
exports.changeRoute = changeRoute;
