<style scoped lang="scss">

@import "../../scss/color";
@import "../../scss/size";

$left-width-desktop: 370px;
$left-width-tablet: 340px;

.MenuContent {
  width: 100%;
  height: 100%;
  position: relative;
}

.MenuContent-desktop {
  background-color: rgba(247, 247, 247, 1);
}

.MenuContent-tablet {
  background-color: rgba(247, 247, 247, 1);
}

.MenuContent-mobile {
  background-color: white;
}

.MenuContent ::v-deep .el-menu {
  border-right: none;
  background-color: rgba(0, 0, 0, 0.02);
}

.MenuContent--tab {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  transition: transform 200ms;
  transition-delay: 200ms;
  background: #ffffff;
  overflow: auto;
}

.MenuContent-desktop .MenuContent--tab {
  width: $left-width-desktop;
  transform: translateX(-$left-width-desktop);
}

.MenuContent-tablet .MenuContent--tab {
  width: $left-width-tablet;
  transform: translateX(-$left-width-tablet);
}

.MenuContent-desktop .MenuContent--tab-selected {
  transform: translateX(0px);
}

.MenuContent-tablet .MenuContent--tab-selected {
  transform: translateX(0px);
}

.MenuContent--title {
  text-align: right;
  padding-top: 50px;
  padding-right: 50px;
  padding-bottom: 50px;
  transform: translateY(-200px);
  opacity: 0;
  transition: transform 500ms, opacity 500ms;
  transition-delay: 300ms;
}

.MenuContent--tab-selected .MenuContent--title {
  transform: translateY(0px);
  opacity: 1;
}

.MenuContent--title1 ::v-deep .Term {
  font-family: 'AmbroiseRegular', sans-serif;
  font-size: 40px;
  text-transform: uppercase;
  padding-bottom: 10px;
}

.MenuContent--title2 ::v-deep .Term {
  font-family: 'RalewayBold', sans-serif;
  font-size: 16px;
  text-transform: uppercase;
  color: $primary;
  opacity: 0.5;
}

.MenuContent--links {
}

.MenuContent--link {
  line-height: 50px;
  text-align: right;
  padding-right: 50px;
  transform: translateY(200px);
  opacity: 0;
  transition: transform 500ms, opacity 500ms;
  background: rgba(0, 0, 0, 0);
  border-right: solid 1px #ffffff;
  cursor: pointer;
}

.MenuContent--tab-selected .MenuContent--link {
  transform: translateY(0px);
  opacity: 1;
}

.MenuContent--link1 {
  transition-delay: 300ms;
}

.MenuContent--link2 {
  transition-delay: 350ms;
}

.MenuContent--link3 {
  transition-delay: 400ms;
}

.MenuContent--link4 {
  transition-delay: 450ms;
}

.MenuContent--link5 {
  transition-delay: 450ms;
}

.MenuContent--link-selected {
  background: rgba(0, 0, 0, 0.03);
  border-right: solid 1px $primary;
  color: rgba(0, 0, 0, 1);
}

.MenuContent--link ::v-deep .Term {
  font-family: 'RalewayBold', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
}

.MenuContent--link:hover ::v-deep .Term {
  color: rgba(0, 0, 0, 1);
}

.MenuContent--body {
  position: absolute;
  top: 0;
  right: 0;
  height: 100%;
  overflow: auto;
}

.MenuContent-desktop .MenuContent--body {
  width: calc(100% - #{$left-width-desktop});
}

.MenuContent-tablet .MenuContent--body {
  width: calc(100% - #{$left-width-tablet});
}

.MenuContent--title-mobile ::v-deep .Term {
  font-family: 'RalewayBold', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
}

.MenuContent--header {
  padding: 25px;
  text-align: center;
  position: relative;
}

.MenuContent--back {
  display: flex;
  align-items: center;
  padding: 0px 0px 25px 0px;
  cursor: pointer;
}

.MenuContent--back ::v-deep .Vector svg {
  width: 18px;
  fill: rgba(0, 0, 0, 0.25);
  position: relative;
  top: 2px;
  padding-right: 10px;
}

.MenuContent--backText ::v-deep .Term {
  font-family: 'RalewayBold', sans-serif;
  font-size: 10px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.25);
}

.MenuContent--href ::v-deep .Term {
  color: rgba(0, 0, 0, 0.5);
}

.MenuContent--close {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  width: 100%;
}

.MenuContent--close ::v-deep .Vector svg {
  width: 18px;
  fill: rgba(0, 0, 0, 0.5);
  position: relative;
  top: 2px;
  padding-left: 10px;
}

.MenuContent--close-1 {
  display: flex;
  align-items: center;
  padding: 25px 0px;
  cursor: pointer;
}

.MenuContent-tablet .MenuContent--close-1 {
  opacity: 0;
}

.MenuContent--closeText {
  font-family: 'RalewayBold', sans-serif;
  font-size: 12px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
}

.MenuContent--1 {
  flex: 1;
  padding: 25px 25px 50px 97px;
  opacity: 0;
  transition: opacity 500ms;
}

.MenuContent-animated .MenuContent--1 {
  opacity: 1;
}

.MenuContent--2 {
  opacity: 0;
  transition: opacity 500ms;
}

.MenuContent-animated .MenuContent--2 {
  opacity: 1;
}

.MenuContent--3 {
  background: rgba(0, 0, 0, 0.03);
  min-width: 490px;
  min-height: 450px;
  width: 490px;
  height: 450px;
  position: relative;
  overflow: hidden;
  border-radius: 4px;
  margin-right: 25px;
  margin-top: 25px;
}

@media screen and (max-width: $menu-image-screen) {
  .MenuContent-desktop .MenuContent--3 {
    min-width: 450px;
    width: 450px;
  }
}

.MenuContent--4 {
  display: flex;
  height: 100%;
}


.MenuContent--5 {
  background: rgba(0, 0, 0, 0.25);
  width: 100%;
  height: 100%;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 0;
  left: 0;
}

.MenuContent--photo {
  height: 100%;
  width: 100%;
  position: absolute;
  top: 0;
  left: 0;
  filter: grayscale(0%);
}

.MenuContent--hrefs {
}

.MenuContent-desktop .MenuContent--hrefs {
  height: 353px;
  overflow: auto;
}

.MenuContent--href ::v-deep p:first-letter {
  text-transform: uppercase;
}

.MenuContent--href-1 {
  cursor: pointer;
  transition: background-color 500ms;
}

.MenuContent--href-1 a {
  display: flex;
  align-items: center;
  padding: 10px 20px;
}

.MenuContent--href-1 img {
  width: 23px;
}

.MenuContent--href-hovered {
  background: rgba(0, 0, 0, 0.03);
}

.MenuContent--href-1 ::v-deep .Link {
  text-decoration: none;
}

.MenuContent--href-1 ::v-deep .Term {
  text-decoration: none;
  color: rgba(0, 0, 0, 0.5);
  transition: color 500ms;
  padding-left: 20px;
  line-height: 23px;
}

.MenuContent--href-1 ::v-deep .Term--text > p:first-letter {
  text-transform: uppercase;
}

.MenuContent--href-hovered ::v-deep .Term {
  color: rgba(0, 0, 0, 1);
}

.MenuContent--logo {
  width: 250px;
  transform: translateY(200px);
  opacity: 0;
  transition: transform 500ms, opacity 500ms;
  transition-delay: 500ms;
}

.MenuContent--logo ::v-deep svg * {
  fill: $primaryLight;
}

.MenuContent-animated .MenuContent--logo {
  transform: translateY(0px);
  opacity: 1;
}

</style>

<template>
  <div class="MenuContent" :class="class_root">
    <template v-if="!vertical">
      <template v-for="tab in state.tabs">
        <div class="MenuContent--tab" :class="class_tab(tab.id)">
          <div class="MenuContent--title">
            <div class="MenuContent--title1">
              <Term :term="tab.title"/>
            </div>
            <div class="MenuContent--title2">
              <Term :term="tab.subtitle"/>
            </div>
          </div>
          <div class="MenuContent--links">
            <template v-for="(link, index) in tab.links">
              <div class="MenuContent--link" :class="class_link(link.id, index)" @click="() => onClickLink(link.id)">
                <Term :term="link.value"/>
              </div>
            </template>
          </div>
        </div>
      </template>
      <div class="MenuContent--body">
        <template v-for="tab in state.tabs">
          <template v-for="link in tab.links">
            <div v-show="tab.id === tabId && link.id === linkId" :key="`${tab.id}.${link.id}`">
              <div class="MenuContent--4">
                <div class="MenuContent--1">
                  <div class="MenuContent--close">
                    <div class="MenuContent--close-1" @click="() => onClickBack()">
                      <div class="MenuContent--closeText">
                        <Term :term="lang.term(92)"/>
                      </div>
                      <Vector :svg="vector.clear"/>
                    </div>
                  </div>
                  <div class="MenuContent--hrefs" @mouseleave="() => leave(link)">
                    <template v-for="href in link.hrefs">
                      <div class="MenuContent--href-1" :class="class_href(href.id)" @mouseenter="() => enter(href.id)"
                           :key="`${tab.id}.${link.id}.${href.id}`">
                        <Link v-if="href.url" :url="href.url">
                          <img v-if="href.img" :src="href.img"/>
                          <Term :term="href.name"/>
                        </Link>
                        <Link v-if="href.paths" :pathIds="href.paths">
                          <img v-if="href.img" :src="href.img"/>
                          <Term :term="href.name"/>
                        </Link>
                        <Link v-if="href.href" :href="href.href">
                          <img v-if="href.img" :src="href.img"/>
                          <Term :term="href.name"/>
                        </Link>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="MenuContent--2" v-show="[SCREEN.DESKTOP].includes(breakPoint.name)">
                  <div class="MenuContent--3">
                    <div class="MenuContent--photo">
                      <Picture :src="link.img"/>
                    </div>
                    <div class="MenuContent--5">
                      <div class="MenuContent--logo">
                        <Vector :svg="vector.logo"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </template>
        </template>
      </div>
    </template>
    <div v-show="tab.id === tabId" v-if="vertical" v-for="tab in state.tabs" :key="tab.id">
      <div class="MenuContent--header">
        <div class="MenuContent--back" @click="() => onClickBack()">
          <Vector :svg="vector.west"/>
          <div class="MenuContent--backText">
            <Term :term="lang.term(93)"/>
          </div>
        </div>
        <div class="MenuContent--title1">
          <Term :term="tab.title"/>
        </div>
        <div class="MenuContent--title2">
          <Term :term="tab.subtitle"/>
        </div>
      </div>
      <el-menu v-for="link in tab.links" :key="`${tab.id}.${link.id}`">
        <el-submenu :index="link.id.toString()">
            <span class="MenuContent--title-mobile" slot="title">
              <Term :term="link.value"/>
            </span>
          <el-menu-item :index="href.id.toString()" v-for="href in link.hrefs"
                        :key="`${tab.id}.${link.id}.${href.id}`">
              <span class="MenuContent--href">
                <Link v-if="href.url" :url="href.url">
                  <Term :term="href.name"/>
                </Link>
                <Link v-if="href.paths" :pathIds="href.paths">
                  <Term :term="href.name"/>
                </Link>
                <Link v-if="href.href" :href="href.href">
                  <Term :term="href.name"/>
                </Link>
              </span>
          </el-menu-item>
        </el-submenu>
      </el-menu>
    </div>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {menus} from "../../data/menus";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {apiClient} from "../../service/apiClientService";
import selectCollections from "../../../@common/api/collection/selectCollections";
import {Term} from "../../../@common/delta/database/Term";
import Id from "@bbx/id~master/core/ts/Id";
import {SHOP_TYPE_LIST} from "../../../@common/constant/SHOP";
import {urlService} from "../../../@common/service/urlService";
import {CATEGORY} from "../../../@common/constant/CATEGORY";
import {STATE_ROLE_ID, stateService} from "../../service/stateService";
import {ROLE} from "../../../@common/constant/ROLE";
import {sendError} from "../../function/sendError";
import {seoService} from "../../service/seoService";

const id = new Id()

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    },
    animated: {
      type: Boolean,
      default: () => false
    },
    tabId: {
      type: Number,
      default: () => 0
    },
    linkId: {
      type: Number,
      default: () => 0
    },
    onClickLink: {
      type: Function,
      default: () => (linkId) => {
      }
    },
    onClickBack: {
      type: Function,
      default: () => () => {
      }
    },
    vertical: {
      type: Boolean,
      default: () => false
    },
    breakPoint: {
      type: BreakPoint,
      default: () => new BreakPoint()
    },
    verticalBreakPoint: {
      type: BreakPoint,
      default: () => new BreakPoint()
    },
  },
  data() {
    const lang = getLangServiceBrowser()
    return {
      lang,
      SCREEN,
      vector: {
        logo: require('../../../@common/assets/logo-white.svg'),
        clear: require('@bbx/vector~master/core/assets/svg/material/clear.svg'),
        west: require('@bbx/vector~master/core/assets/svg/material/west.svg'),
      },
      state: {
        /**
         * @type boolean
         */
        animated: this.animated,
        /**
         * @type number
         */
        hrefId: -1,
        /**
         * @type boolean
         */
        scrollable: false,
        /**
         * @type any
         */
        tabs: menus(this.nuxtProp),
        /**
         * @type number
         */
        roleId: 0,
        /**
         * @type boolean
         */
        collectionFetched: false,
        /**
         * @type Collection[]
         */
        collections: [],
        /**
         * @type Collection[]
         */
        collectionMobiliers: [],
        /**
         * @type Collection[]
         */
        collectionLuminaires: [],
        /**
         * @type Collection[]
         */
        collectionOutlets: []
      }
    }
  },
  watch: {
    'tabId': function () {
      this.state.animated = false;
      setTimeout(() => this.state.animated = true, 500)
      // we fetch the collections infos on the first opened menu
      if (!this.state.collectionFetched) {
        this.select().catch(sendError)
        this.state.collectionFetched = true
      }
    },
    'linkId': function () {
      for (const tab of this.state.tabs) {
        for (const link of tab.links) {
          if (tab.id === this.tabId && link.id === this.linkId) {
            if (link.hrefs[0]) this.state.hrefId = link.hrefs[0].id;
          }
        }
      }
    },
    'animated': function (animated) {
      setTimeout(() => this.state.animated = animated, 500)
    },
  },
  computed: {
    class_root() {
      const classes = [];
      if (this.state.animated) classes.push(`MenuContent-animated`)
      if (this.breakPoint.name === SCREEN.MOBILE) classes.push(`MenuContent-mobile`)
      if (this.breakPoint.name === SCREEN.TABLET) classes.push(`MenuContent-tablet`)
      if (this.breakPoint.name === SCREEN.DESKTOP) classes.push(`MenuContent-desktop`)
      if (this.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`MenuContent-mobileY`)
      if (this.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`MenuContent-desktopY`)
      return classes;
    }
  },
  beforeMount() {
    this.state.roleId = stateService.get(STATE_ROLE_ID)
    stateService.watch(STATE_ROLE_ID, v => this.state.roleId = v)
  },
  mounted() {
    if (seoService.isActive) this.select().catch(sendError)
  },
  methods: {
    class_tab(tabId) {
      const classes = [];
      if (this.tabId === tabId) classes.push(`MenuContent--tab-selected`)
      return classes;
    },
    class_link(linkId, index) {
      const classes = [`MenuContent--link${index + 1}`];
      if (this.linkId === linkId) classes.push(`MenuContent--link-selected`)
      return classes;
    },
    class_href(hrefId) {
      const classes = [];
      if (this.state.hrefId === hrefId) {
        classes.push(`MenuContent--href-hovered`)
      }
      return classes;
    },
    enter(hrefId) {
      this.state.hrefId = hrefId;
    },
    leave(link) {
      if (link.hrefs[0]) this.state.hrefId = link.hrefs[0].id;
    },
    async select() {

      let promises = []

      promises.push(this.selectCollections())
      if (this.state.roleId !== ROLE.SELLER) promises.push(this.selectCollectionOutlets())

      await Promise.all(promises)

      for (const collection of this.state.collections) {
        if (collection.shopTypeLists.map(a => a.id).includes(SHOP_TYPE_LIST.MOBILIER)) {
          this.state.collectionMobiliers.push(collection)
        }
        if (collection.shopTypeLists.map(a => a.id).includes(SHOP_TYPE_LIST.LUMINAIRE)) {
          this.state.collectionLuminaires.push(collection)
        }
      }

      this.changeTabs()
    },
    async selectCollections() {

      let filter = {
        active: true
      }

      if (this.state.roleId !== ROLE.SELLER) filter.$hasOnlyOutlet = false

      const {data} = await apiClient.do(selectCollections, selectCollections.with(filter))
      this.state.collections = data
    },
    async selectCollectionOutlets() {
      const {data} = await apiClient.do(selectCollections, selectCollections.with({
        active: true,
        isOutlet: true
      }))
      this.state.collectionOutlets = data
    },
    changeTabs() {
      this.state.tabs[0].links[2].hrefs = [] // mobilier
      this.state.tabs[1].links[1].hrefs = [] // luminaire
      this.state.tabs[2].links[1].hrefs = [] // outlet

      if (this.state.collectionMobiliers.length) {
        for (const collection of this.state.collectionMobiliers) {
          for (const page of collection.pages) {
            for (const url of page.urls) {
              for (const sort of url.sorts) {
                for (const path of sort.paths) {
                  for (const path of sort.paths) {
                    this.state.tabs[0].links[2].hrefs.push({
                      id: id.uniq(),
                      name: new Term(path.terms[1]),
                      href: urlService.getProjectUrl(this.lang.lang.iso6391, url, {
                        isOutlet: '0',
                        collectionTypeIds: SHOP_TYPE_LIST.MOBILIER.toString(),
                        categoryIds: [
                          CATEGORY.TABLE,
                          CATEGORY.CHAISE,
                          CATEGORY.TABOURET,
                          CATEGORY.FAUTEUIL,
                          CATEGORY.CANAPE,
                          CATEGORY.BAIN_DE_SOLEIL,
                          CATEGORY.PARASOL,
                          CATEGORY.ACCESSOIRE,
                          CATEGORY.DOUCHE,
                        ].join(',')
                      }),
                    })
                  }
                }
              }
            }
          }
        }
      }

      if (this.state.collectionLuminaires.length) {
        for (const collection of this.state.collectionLuminaires) {
          for (const page of collection.pages) {
            for (const url of page.urls) {
              for (const sort of url.sorts) {
                for (const path of sort.paths) {
                  for (const path of sort.paths) {
                    this.state.tabs[1].links[1].hrefs.push({
                      id: id.uniq(),
                      name: new Term(path.terms[1]),
                      href: urlService.getProjectUrl(this.lang.lang.iso6391, url, {
                        isOutlet: '0',
                        collectionTypeIds: SHOP_TYPE_LIST.LUMINAIRE.toString(),
                        categoryIds: CATEGORY.LUMINAIRE.toString()
                      }),
                    })
                  }
                }
              }
            }
          }
        }
      }

      if (this.state.collectionOutlets.length) {
        for (const collection of this.state.collectionOutlets) {
          for (const page of collection.pages) {
            for (const url of page.urls) {
              for (const sort of url.sorts) {
                for (const path of sort.paths) {
                  for (const path of sort.paths) {
                    this.state.tabs[2].links[1].hrefs.push({
                      id: id.uniq(),
                      name: new Term(path.terms[1]),
                      href: urlService.getProjectUrl(this.lang.lang.iso6391, url, {
                        isOutlet: '1',
                      }),
                    })
                  }
                }
              }
            }
          }
        }
      }
    }
  }
}
</script>
