<style scoped lang="scss">

@import '../../scss/color';

.MenuList {
  height: inherit;
}

.MenuList--links {
  display: flex;
  align-items: center;
  height: 100%;
  opacity: 0;
}

.MenuList-animated .MenuList--links {
  opacity: 1;
}

.MenuList-vertical .MenuList--links {
  flex-direction: column;
}

.MenuList--link {
  cursor: pointer;
  height: 100%;
  display: flex;
  align-items: center;
  text-align: center;
  position: relative;
}

.MenuList-dark .MenuList--link:hover {
  color: $primary;
}

.MenuList-desktop .MenuList--link {
  padding-left: 32px;
}

.MenuList-tablet .MenuList--link {
  padding-left: 24px;
}

.MenuList-vertical .MenuList--link {
  padding: 15px;
}

.MenuList--link ::v-deep .Term {
  text-decoration: none;
  font-family: 'AeonikRegular', sans-serif;
  transition: color 300ms;
}

.MenuList-desktop .MenuList--link ::v-deep .Term {
  font-size: 16px;
}

.MenuList-tablet .MenuList--link ::v-deep .Term {
  font-size: 15px;
}

.MenuList--link ::v-deep p:first-letter {
  text-transform: uppercase;
}

.MenuList-white .MenuList--link ::v-deep .Term {
  color: white;
}

.MenuList-dark.MenuList-tablet .MenuList--link ::v-deep .Term {
  color: black;
}

/**/

.MenuList--barre {
  height: 1px;
  position: absolute;
  transform: scaleX(0);
  transition: transform 300ms;
}

.MenuList-white .MenuList--link .MenuList--barre {
  background-color: rgba(255, 255, 255, 0.5);
  top: 50%;
  margin-top: 20px;
  width: calc(100% - 32px);
  left: 32px;
}

.MenuList-white .MenuList--link:hover .MenuList--barre {
  transform: scaleX(1);
}

.MenuList-dark .MenuList--link .MenuList--barre {
  margin-left: 0;
  width: 100%;
  background-color: $primary;
  bottom: 0;
}

.MenuList-dark .MenuList--link:hover .MenuList--barre {
}

.MenuList--promo {
  opacity: 0;
  transform: scale(2);
  transition: opacity 300ms, transform 300ms, background-color 300ms;
  padding: 7px 14px;
  border-radius: 3px;
  letter-spacing: 0.15px;
}

.MenuList-white .MenuList--promo {
  background: $primary;
}

.MenuList-dark .MenuList--promo {
  background: $primary;
}

.MenuList-tablet .MenuList--promo {
  background: black;
}

.MenuList-animated .MenuList--promo {
  opacity: 1;
  transform: scale(1);
}

.MenuList-animated .MenuList--promo:hover {
  opacity: 0.6;
}

.MenuList--promo ::v-deep .Term {
  display: block;
  font-family: 'CommutersSansBold', sans-serif;
  text-transform: uppercase;
  color: white;
}

.MenuList-dark .MenuList--promo ::v-deep .Term {
  color: white;
}

.MenuList-dark.MenuList-tablet .MenuList--promo ::v-deep .Term {
  color: white;
}

.MenuList-desktop .MenuList--promo ::v-deep .Term {
  font-size: 9px;
}

.MenuList-tablet .MenuList--promo ::v-deep .Term {
  font-size: 9px;
}

.MenuList-mobile .MenuList--promo ::v-deep .Term {
  font-size: 9px;
}

</style>

<template>
  <nav class="MenuList" :class="class_root">
    <div class="MenuList--links">

      <div class="MenuList--link" @click="() => onClick(0, 0)">
        <Term :term="lang.term(2)"/>
        <div class="MenuList--barre"></div>
      </div>

      <div class="MenuList--link" @click="() => onClick(1, 3)">
        <Term :term="lang.term(7)"/>
        <div class="MenuList--barre"></div>
      </div>

      <div class="MenuList--link" @click="() => onClick(3, 9)">
        <Term :term="lang.term(10)"/>
        <div class="MenuList--barre"></div>
      </div>

      <CountryLocation>
        <div class="MenuList--link" @click="() => onClick(2, 6)" v-show="roleId !== ROLE.SELLER">
          <div class="MenuList--promo" :style="style_promo">
            <Term :term="lang.term(8)"/>
          </div>
        </div>
      </CountryLocation>

      <div v-if="vertical" class="MenuList--link" @click="() => toAccount()">
        <Term :term="lang.term(95)"/>
      </div>
    </div>
  </nav>
</template>

<script>

import {THEME} from "../../../@common/constant/THEME";
import {ROLE} from "../../../@common/constant/ROLE";

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {changeRoute} from "../../function/changeRoute";
import BreakPoint from "@bbx/responsive~master/core/delta/BreakPoint";
import SCREEN from "@bbx/responsive~master/core/constant/SCREEN";
import {COLOR} from "../../../@common/constant/COLOR";
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {EVENT} from "../../../@common/constant/EVENT";
import {eventService} from "../../service/eventService";

export default {

  props: {
    onClick: {
      type: Function,
      default: () => (index1, index2) => {
      }
    },
    theme: {
      type: String,
      default: () => THEME.LIGHT
    },
    animated: {
      type: Boolean,
      default: () => false
    },
    vertical: {
      type: Boolean,
      default: () => false
    },
    linkAccount: {
      type: Array,
      default: () => ([])
    },
    roleId: {
      type: Number,
      default: () => ROLE.SELLER
    },
    breakPoint: {
      type: BreakPoint,
      default: () => new BreakPoint()
    },
    verticalBreakPoint: {
      type: BreakPoint,
      default: () => new BreakPoint()
    }
  },
  data() {
    return {
      ROLE,
      lang: getLangServiceBrowser(),
      vector: {},
      state: {
        color: COLOR.PRIMARY,
        animated: false,
      },
      events: []
    }
  },
  watch: {
    "animated": {
      handler: function (v) {
        setTimeout(() => {
          this.state.animated = v
        })
      }
    }
  },
  computed: {
    class_root() {
      const classes = [];
      if (this.theme === THEME.DARK) classes.push(`MenuList-dark`)
      if (this.theme === THEME.LIGHT) classes.push(`MenuList-white`)
      if (this.state.animated) classes.push(`MenuList-animated`)
      if (this.vertical) classes.push(`MenuList-vertical`)
      if (this.breakPoint.name === SCREEN.MOBILE) classes.push(`MenuList-mobile`)
      if (this.breakPoint.name === SCREEN.TABLET) classes.push(`MenuList-tablet`)
      if (this.breakPoint.name === SCREEN.DESKTOP) classes.push(`MenuList-desktop`)
      if (this.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`MenuList-mobileY`)
      if (this.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`MenuList-desktopY`)
      return classes;
    },
    style_promo() {
      let style = {}
      // if (this.theme === THEME.LIGHT) style.background = this.state.color
      if (this.theme === THEME.LIGHT) style.background = '#A32841'
      return style
    }
  },
  beforeMount() {

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.HOME_COLOR_CHANGED,
      callback: (color) => {
        this.state.color = color
      }
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
    setTimeout(() => {
      this.state.animated = this.animated
    })
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    toAccount() {
      changeRoute(this.linkAccount)
    }
  }
}
</script>
