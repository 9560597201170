import {NuxtUrl} from "../nuxt/NuxtUrl";

export interface EventChangeRoute_constructor_props {
    pathIds: number[]
    query: any
    raw?: any
    nuxUrl?: any
}

export class EventChangeRoute implements EventChangeRoute_constructor_props {

    pathIds: number[]
    query: any
    raw: any
    nuxUrl: NuxtUrl

    constructor(props: EventChangeRoute_constructor_props) {
        this.pathIds = props.pathIds
        this.query = props.query
        this.raw = props.raw
        this.nuxUrl = props.nuxUrl
    }
}
