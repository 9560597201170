"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PROJECT_URL = void 0;
exports.PROJECT_URL = {
    MOBILIER_DE_JARDIN_PRODUITS_ENSEMBLE_SALON_DE_JARDIN: 1,
    MOBILIER_DE_JARDIN_PRODUITS_TABLES_DE_JARDIN: 2,
    MOBILIER_DE_JARDIN_PRODUITS_CHAISES_TABOURETS_BANCS_DE_JARDIN: 3,
    MOBILIER_DE_JARDIN_PRODUITS_CANAPES_FAUTEUILS_DE_JARDIN: 4,
    MOBILIER_DE_JARDIN_PRODUITS_BAINS_DE_SOLEIL: 5,
    MOBILIER_DE_JARDIN_PRODUITS_PARASOLS: 6,
    MOBILIER_DE_JARDIN_ACCESSOIRES_DE_JARDIN_TAPIS_EXTERIEUR: 7,
    MOBILIER_DE_JARDIN_ACCESSOIRES_DE_JARDIN_COUSSINS_CHAISES_LONGUES: 8,
    MOBILIER_DE_JARDIN_ACCESSOIRES_DE_JARDIN_PIECES_DETACHEES: 9,
    MOBILIER_DE_JARDIN_ACCESSOIRES_DE_JARDIN_ENTRETIEN: 10,
    MOBILIER_DE_JARDIN_LES_COLLECTIONS_BASTINGAGE: 11,
    MOBILIER_DE_JARDIN_LES_COLLECTIONS_SKAAL: 12,
    MOBILIER_DE_JARDIN_LES_COLLECTIONS_COPENHAGUE: 13,
    MOBILIER_DE_JARDIN_LES_COLLECTIONS_KOTON: 14,
    MOBILIER_DE_JARDIN_LES_COLLECTIONS_TEKURA: 15,
    MOBILIER_DE_JARDIN_LES_COLLECTIONS_SELVA: 16,
    MOBILIER_DE_JARDIN_LES_COLLECTIONS_JET_STREAM: 17,
    MOBILIER_DE_JARDIN_LES_COLLECTIONS_SAGAMORE: 18,
    MOBILIER_DE_JARDIN_LES_COLLECTIONS_AMAKA: 19,
    MOBILIER_DE_JARDIN_LES_COLLECTIONS_YOLO: 20,
    MOBILIER_DE_JARDIN_LES_COLLECTIONS_TOUT_LE_TECK: 21,
    MOBILIER_DE_JARDIN_LES_COLLECTIONS_VALTECK: 22,
    MOBILIER_DE_JARDIN_LES_COLLECTIONS_BISTRO_ET_TICAO: 23,
    MOBILIER_DE_JARDIN_LES_COLLECTIONS_PARASOLS: 24,
    MOBILIER_DE_JARDIN_LES_COLLECTIONS_DOUCHES: 25,
    MOBILIER_DE_JARDIN_LES_COLLECTIONS_ZAYO: 26,
    LUMINAIRES_SOLAIRES_LANTERNES_SOLAIRES_NOMADES: 27,
    LUMINAIRES_SOLAIRES_LAMPES_SOLAIRES_A_POSER: 28,
    LUMINAIRES_SOLAIRES_LAMPADAIRES_SOLAIRES: 29,
    LUMINAIRES_SOLAIRES_TORCHES_BORNES_ET_LAMPADAIRES_SOLAIRES: 30,
    LUMINAIRES_SOLAIRES_APPLIQUES_SOLAIRES: 31,
    LUMINAIRES_SOLAIRES_ACCESSOIRES: 32,
    OUTLET_PRODUITS_ENSEMBLE_SALON_DE_JARDIN: 33,
    OUTLET_PRODUITS_TABLES_DE_JARDIN: 34,
    OUTLET_PRODUITS_CHAISES_TABOURETS_BANCS_DE_JARDIN: 35,
    OUTLET_PRODUITS_CANAPES_FAUTEUILS_DE_JARDIN: 36,
    OUTLET_PRODUITS_BAINS_DE_SOLEIL: 37,
    OUTLET_LES_COLLECTIONS_KOTON: 38,
    OUTLET_LES_COLLECTIONS_FILACIEL: 39,
    OUTLET_LES_COLLECTIONS_TWIG: 40,
    OUTLET_LES_COLLECTIONS_TECK: 41,
    OUTLET_LES_COLLECTIONS_XENAH: 42,
    OUTLET_LES_COLLECTIONS_DRIPPER: 43,
    OUTLET_LES_COLLECTIONS_PARALLELE: 44,
    OUTLET_LES_COLLECTIONS_BRITON: 45,
    OUTLET_LES_COLLECTIONS_DRAKAR: 46,
    OUTLET_LES_COLLECTIONS_JET_STREAM: 47,
    LA_MARQUE_STORY_LES_JARDINS_DECOUVRIR_LES_JARDINS: 48,
    LA_MARQUE_ENGAGEMENTS_FABRICATION_DE_MOBILIER_DE_JARDIN: 49,
    LA_MARQUE_ENGAGEMENTS_LES_MATERIAUX: 50,
    LA_MARQUE_AIDE_SUPPORT_FAQ: 51,
    OUTLET_PRODUITS_LUMINAIRE_SOLAIRE: 52,
    LEGAL_CGU_CGV: 53,
    LEGAL_INFORMATIONS_LÉGALES: 54,
    LEGAL_CONFIDENTIALITÉ: 55,
    LEGAL_COOKIES: 56,
    LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_ENTRETENIR_SON_JARDIN_EN_ETE: 57,
    LA_MARQUE_AIDE_SUPPORT_FAQ_BIEN_CHOISIR_SON_BAIN_DE_SOLEIL_EN_RESINE_TRESSEE: 58,
    LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_AMENAGER_SA_TERRASSE_EN_BOIS_POUR_PASSER_UN_SUPERBE_ETE: 59,
    LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_BIEN_DECORER_SON_JARDIN_EN_ETE: 60,
    LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_CHOISIR_SON_MOBILIER_DE_JARDIN_SI_VOUS_AVEZ_UN_BALCON: 61,
    LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_BIEN_ASSOCIER_SON_MOBILIER_DE_JARDIN_AVEC_SES_PLANTES: 62,
    LA_MARQUE_AIDE_SUPPORT_FAQ_CHAISE_DE_JARDIN_ET_BAIN_DE_SOLEIL_EN_ALUMINIUM_AVANTAGES_ET_INCONVENIENTS: 63,
    LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_BIEN_CHOISIR_SA_TABLE_DE_JARDIN_POUR_LETE: 64,
    LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_BIEN_CHOISIR_SON_SALON_DE_JARDIN_EN_TECK: 65,
    LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_NETTOYER_SA_TABLE_DE_JARDIN_EN_ALUMINIUM_ET_COMPOSITE: 66,
    LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_NETTOYER_UNE_TABLE_DE_SALON_DE_JARDIN_EN_TECK: 67,
    LA_MARQUE_AIDE_SUPPORT_FAQ_MOBILIER_DE_JARDIN_EN_ALUMINIUM_4_BONNES_RAISONS_DE_CHOISIR_CE_MATERIAU_POUR_LEXTERIEUR: 68,
    LA_MARQUE_AIDE_SUPPORT_FAQ_RENOVATION_SALON_DE_JARDIN_EN_RESINE_TRESSEE: 69,
    LA_MARQUE_AIDE_SUPPORT_FAQ_MOBILIER_DE_JARDIN_RESISTANT_AUX_INTEMPERIES: 70,
    LA_MARQUE_AIDE_SUPPORT_FAQ_GAIN_DE_PLACE_POUR_PETITS_ESPACES: 71,
    LA_MARQUE_AIDE_SUPPORT_FAQ_AMENAGER_UN_COIN_SALON_DE_JARDIN: 72,
    LA_MARQUE_AIDE_SUPPORT_FAQ_AMENAGER_UN_COIN_PISCINE_EN_TERASSE_OU_JARDIN: 73,
    LA_MARQUE_AIDE_SUPPORT_FAQ_PEINDRE_SON_SALON_DE_JARDIN: 74,
    LA_MARQUE_AIDE_SUPPORT_FAQ_AMENAGER_UNE_TERRASSE_COUVERTE: 75,
    LA_MARQUE_AIDE_SUPPORT_FAQ_RENOVER_SA_TABLE_ET_SES_CHAISES_EN_BOIS: 76,
    MOBILIER_DE_JARDIN_TOUT_LE_TECK: 77,
    ADMIN_SELLER: 78,
    ACCOUNT_EXPORTATION: 79,
    ADMIN_CHAT: 80,
    MOBILIER_DE_JARDIN_INSPIRATION_MOBILIER_DE_JARDIN: 81,
    MOBILIER_DE_JARDIN_INSPIRATION_ENSEMBLE_DE_JARDIN: 82,
    MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN: 83,
    MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_EN_ALUMINIUM: 84,
    // 1. Copenhague et Bastingage
    MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_DE_LUXE: 85,
    // 2. Skaal
    MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_DESIGN: 86,
    MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_EN_BOIS: 87,
    MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_EN_TECK: 88,
    // 3. Jet stream et Amaka
    MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_HAUT_DE_GAMME: 89,
    MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_ROND: 90,
    MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_8_PERSONNES: 91,
    MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_10_PERSONNES: 92,
    MOBILIER_DE_JARDIN_INSPIRATION_CHAISE_DE_JARDIN: 93,
    MOBILIER_DE_JARDIN_INSPIRATION_CHAISE_DE_JARDIN_PLIANTE: 94,
    LUMINAIRE_SOLAIRE_INSPIRATION_LAMPE_SOLAIRE_DE_JARDIN: 95,
    // 4. Applique exterieur : BLADE
    LUMINAIRE_SOLAIRE_INSPIRATION_APPLIQUE_EXTERIEUR: 96,
    // 5. Applique jardin : METRO
    LUMINAIRE_SOLAIRE_INSPIRATION_APPLIQUE_DE_JARDIN: 97,
    LUMINAIRE_SOLAIRE_INSPIRATION_LAMPADAIRE_SOLAIRE_DE_JARDIN: 98,
    // 6. Salon de balcon : reprendre Bastingage, Skaal et Copenhague en enssemble
    MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_BALCON: 99,
    MARQUE_DECOUVRIR_LES_JARDINS: 100,
    MARQUE_CONCEPTION_ET_FABRICATION: 101,
    MARQUE_NOS_ENGAGEMENT: 102,
    MARQUE_LES_SOLAIRES: 103,
    MARQUE_NEW_STORE: 104,
};
