<style scoped>
.ArianeHeader {
}


.ArianeHeader--header {
  display: flex;
  position: relative;
  background: rgba(0, 0, 0, 0.03);
}

.ArianeHeader-desktop .ArianeHeader--header {
  z-index: 1900;
}

.ArianeHeader-tablet .ArianeHeader--header {
  z-index: 1900;
}

.ArianeHeader-mobile .ArianeHeader--header {

}

.ArianeHeader--previous {
  height: 100px;
  padding: 0 50px;
  background: rgba(0, 0, 0, 0.03);
  display: flex;
  align-items: center;
  cursor: pointer;
  transition: background-color 300ms;
}

.ArianeHeader-mobile .ArianeHeader--previous {
  height: 75px;
  padding: 0;
  width: 75px;
  min-width: 75px;
  display: flex;
  justify-content: center;
}

.ArianeHeader-mobile .ArianeHeader--previous ::v-deep .Term {
  display: none;
}

.ArianeHeader--previous:hover {
  background: rgba(0, 0, 0, 0.12);
}

.ArianeHeader--previousContent {
  display: flex;
  align-items: center;
}

.ArianeHeader--previousIcon {
  width: 24px;
  min-width: 24px;
  position: relative;
  top: 2px;
}

.ArianeHeader--previousTerm {
  padding-left: 15px;
  text-transform: uppercase;
  font-size: 12px;
  font-family: 'RalewayBold', sans-serif;
}

.ArianeHeader--ariane {
  padding-left: 50px;
  display: flex;
  align-items: center;
}

.ArianeHeader-desktop .ArianeHeader--ariane {
  height: 100px;
}

.ArianeHeader-tablet .ArianeHeader--ariane {
  height: 100px;
}

.ArianeHeader-mobile .ArianeHeader--ariane {
  height: 75px;
  width: 100%;
  padding: 0;
  justify-content: center;
}
</style>

<template>
  <div class="ArianeHeader" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">

      <div class="ArianeHeader--header">

        <div class="ArianeHeader--previous" @click="() => onPrevious()">
          <div class="ArianeHeader--previousContent">
            <div class="ArianeHeader--previousIcon">
              <Vector :svg="icon.west"></Vector>
            </div>
            <div class="ArianeHeader--previousTerm">
              <Term :term="lang().term(239)"/>
            </div>
          </div>
        </div>

        <div class="ArianeHeader--ariane">
          <Ariane :pages="pages"
                  :onlyTheLast="props.Responsive['0'].breakPoint.name === SCREEN.MOBILE">
          </Ariane>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {convertPathToUrl, getPageByPathIds, searchPageByNuxProp} from "../../../@common/function/helperUrl";
import {PATHS} from "../../../@common/constant/PATHS";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {eventService} from "../../service/eventService";
import {EVENT} from "../../../@common/constant/EVENT";

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      SCREEN,
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        west: require('@bbx/vector~master/core/assets/svg/material/west.svg'),
      },
      state: {},
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`ArianeHeader-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`ArianeHeader-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`ArianeHeader-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`ArianeHeader-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`ArianeHeader-desktopY`)
      return classes
    },
    /**
     * @returns {Page[]}
     */
    pages() {

      /** @type NuxtProp */
      const nuxtProp = this.nuxtProp

      let pages = []

      let pageHome = getPageByPathIds(nuxtProp, [PATHS.HOME])
      let pageCurrent = nuxtProp.page

      pageHome = convertPathToUrl(pageHome)
      pageCurrent = convertPathToUrl(pageCurrent)

      pages.push(pageHome)

      // -----

      const pathValues = nuxtProp.url.paths.map(path => path.value)
      pathValues.pop()
      const posibilities = []
      const pageAutos = []
      for (const i in pathValues) {
        posibilities.push(pathValues.slice(0, parseInt(i) + 1))
      }
      for (const pathValue of posibilities) {
        const page = searchPageByNuxProp(nuxtProp, pathValue)
        if (page.id && this.hasPathTerm(page)) {
          const pageUrl = convertPathToUrl(page)
          pageAutos.push(pageUrl)
        }
      }

      if (pageAutos.length) {
        pages = pages.concat(pageAutos)
      }

      // -----

      pages.push(pageCurrent)

      return pages
    },
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    onPrevious() {
      eventService.triggerByName(new ListenerTrigger({
        name: EVENT.TO_PREVIOUS_URL
      }))
    },
    lang() {
      return getLangServiceBrowser()
    },
    /**
     *
     * @param {Page} page
     */
    hasPathTerm(page) {
      for (const url of page.urls) {
        for (const sort of url.sorts) {
          for (const path of sort.paths) {
            for (const term of path.terms) {
              return !!this.lang.translateTerm(term)
            }
          }
        }
      }
      return false
    }
  }
}
</script>
