<style scoped>
.Account {
  width: 100%;
}

.Account--content {
  width: 100%;
  padding: 50px;
  box-sizing: border-box;
}

.Account-mobile .Account--content {
  padding: 25px;
}

.Account--header {
  padding-bottom: 50px;
}

.Account--menu {
  display: flex;
}

.Account-desktop .Account--menu {
  flex-direction: row;
}

.Account-tablet .Account--menu {
  flex-direction: row;
}

.Account-mobile .Account--menu {
  flex-direction: column;
}

.Account--tabs {
  background: rgba(0, 0, 0, 0.03);
  height: inherit;
  display: flex;
}

.Account-desktop .Account--tabs {
  padding-top: 50px;
  flex-direction: column;
  width: 350px;
  min-width: 350px;
}

.Account-tablet .Account--tabs {
  padding-top: 50px;
  flex-direction: column;
  width: 350px;
  min-width: 350px;
}

.Account-mobile .Account--tabs {
  justify-content: center;
  padding-bottom: 50px;
  flex-direction: row;
  background: rgba(0, 0, 0, 0);
  width: 100%;
  min-width: 100%;
}

.Account--tab {
  background: rgba(0, 0, 0, 0);
  border-right: solid 1px rgba(0, 0, 0, 0);
  display: flex;
  justify-content: flex-end;
  align-items: center;
  cursor: pointer;
  font-family: 'RalewayBold', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.5);
  transition: background 300ms, color 300ms;
}

.Account-desktop .Account--tab {
  padding: 20px 40px 20px 20px;
}

.Account-tablet .Account--tab {
  padding: 20px 40px 20px 20px;
}

.Account-mobile .Account--tab {
  padding: 20px;
}

.Account-desktop .Account--tab ::v-deep .Term {
}

.Account-tablet .Account--tab ::v-deep .Term {
}

.Account-mobile .Account--tab ::v-deep .Term {
  padding: 20px;
  display: none;
}

.Account--tab:hover {
  background: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 1);
}

.Account-desktop .Account--tab:hover {
  border-right: solid 1px rgba(0, 0, 0, 1);
}

.Account-tablet .Account--tab:hover {
  border-right: solid 1px rgba(0, 0, 0, 1);
}

.Account-mobile .Account--tab:hover {
  border-bottom: solid 1px rgba(0, 0, 0, 1);
  background: rgba(0, 0, 0, 0);
}

.Account--tab-selected {
  background: rgba(0, 0, 0, 0.03);
  color: rgba(0, 0, 0, 1);
}

.Account-desktop .Account--tab-selected {
  border-right: solid 1px rgba(0, 0, 0, 1);
}

.Account-tablet .Account--tab-selected {
  border-right: solid 1px rgba(0, 0, 0, 1);
}

.Account-mobile .Account--tab-selected {
  background: rgba(0, 0, 0, 0);
  border-bottom: solid 1px rgba(0, 0, 0, 1);
}

.Account--tab ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 0.25);
  transition: fill 300ms;
}

.Account--tab:hover ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 1);
}

.Account--tab-selected ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 1);
}

.Account--tabIcon {
  width: 24px;
  position: relative;
  top: 2px;
}

.Account-desktop .Account--tabIcon {
  padding-left: 25px;
}

.Account-tablet .Account--tabIcon {
  padding-left: 25px;
}

.Account-mobile .Account--tabIcon {
  padding-left: 0px;
}

.Account--body {
  flex: 1;
}
</style>

<template>
  <div class="Account" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="Account--content">
        <div class="Account--header">
          <AccountHeader></AccountHeader>
        </div>
        <div class="Account--menu">

          <div class="Account--tabs">
            <div class="Account--tab" :class="class_tab(PATHS.PROFILE)" @click="() => changeTab(PATHS.PROFILE)">
              <Term :term="lang.term(269)"/>
              <div class="Account--tabIcon">
                <Vector :svg="icon.contacts"></Vector>
              </div>
            </div>
            <CountryLocation>
              <div class="Account--tab" :class="class_tab(PATHS.ORDER)" @click="() => changeTab(PATHS.ORDER)">
                <Term :term="lang.term(270)"/>
                <div class="Account--tabIcon">
                  <Vector :svg="icon.local_shipping"></Vector>
                </div>
              </div>
              <div class="Account--tab" :class="class_tab(PATHS.ADDRESS)" @click="() => changeTab(PATHS.ADDRESS)" v-if="state.roleId !== ROLE.SELLER">
                <Term :term="lang.term(271)"/>
                <div class="Account--tabIcon">
                  <Vector :svg="icon.location_on"></Vector>
                </div>
              </div>
            </CountryLocation>
            <div class="Account--tab" :class="class_tab(PATHS.NOTIFICATION)" @click="() => changeTab(PATHS.NOTIFICATION)">
              <Term :term="lang.term(272)"/>
              <div class="Account--tabIcon">
                <Vector :svg="icon.mail"></Vector>
              </div>
            </div>
            <div v-if="[ROLE.SEO, ROLE.ROOT, ROLE.ADMIN].includes(state.roleId)" class="Account--tab" :class="class_tab(0, PROJECT_URL.ACCOUNT_EXPORTATION)" @click="() => changeTab(0, PROJECT_URL.ACCOUNT_EXPORTATION)">
              <Term :term="lang.term(424)"/>
              <div class="Account--tabIcon">
                <Vector :svg="icon.archive"></Vector>
              </div>
            </div>
          </div>

          <div class="Account--body">
            <div class="Account--address" v-if="isProjectUrl(nuxtProp, projectUrlId)">
              <AccountExportation :nuxtProp="nuxtProp"></AccountExportation>
            </div>
            <div class="Account--profile" v-else-if="pathTab === PATHS.PROFILE">
              <AccountProfile></AccountProfile>
            </div>
            <div class="Account--order" v-else-if="pathTab === PATHS.ORDER">
              <AccountOrder></AccountOrder>
            </div>
            <div class="Account--address" v-else-if="pathTab === PATHS.ADDRESS">
              <AccountAddress></AccountAddress>
            </div>
            <div class="Account--notification" v-else-if="pathTab === PATHS.NOTIFICATION">
              <AccountNotification></AccountNotification>
            </div>
          </div>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>


import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {PATHS} from "../../../@common/constant/PATHS";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {changeRoute} from "../../function/changeRoute";
import {ROLE} from "../../../@common/constant/ROLE";
import {STATE_ROLE_ID, stateService} from "../../service/stateService";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";

import {isProjectUrl, getUrlByProjectUrlId} from "../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../@common/constant/PROJECT_URL";
import {urlService} from "../../../@common/service/urlService"

export default {
  props: {
    pathTab: {
      type: Number,
      default: () => PATHS.PROFILE
    },
    nuxtProp: {
      default: () => new NuxtProp()
    },
    projectUrlId: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      PATHS,
      PROJECT_URL,
      ROLE,
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 920
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        contacts: require('@bbx/vector~master/core/assets/svg/material/contacts.svg'),
        local_shipping: require('@bbx/vector~master/core/assets/svg/material/local_shipping.svg'),
        location_on: require('@bbx/vector~master/core/assets/svg/material/location_on.svg'),
        mail: require('@bbx/vector~master/core/assets/svg/material/mail.svg'),
        archive: require('@bbx/vector~master/core/assets/svg/material/archive.svg'),
      },
      state: {
        roleId: ROLE.SELLER,
      },
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`Account-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`Account-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`Account-desktop`)
      return classes
    }
  },
  beforeMount() {
    this.state.roleId = stateService.get(STATE_ROLE_ID)
    stateService.watch(STATE_ROLE_ID, v => this.state.roleId = v)
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    isProjectUrl,
    changeTab(pathId, projectUrlId) {
      if (projectUrlId) {
        let url = getUrlByProjectUrlId(this.nuxtProp, projectUrlId);
        let row = urlService.getProjectUrl(this.lang.lang.iso6391, url);
        changeRoute(
            undefined,
            undefined,
            row
        )
      } else {
        changeRoute([
          PATHS.ACCOUNT,
          pathId
        ])
      }
    },
    class_tab(pathId, projectUrlId) {

      const classes = []

      if (this.projectUrlId) {
        if (this.projectUrlId === projectUrlId) classes.push('Account--tab-selected')
      } else {
        if (this.pathTab === pathId) classes.push('Account--tab-selected')
      }
      return classes
    }
  }
}
</script>
