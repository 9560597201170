"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SHAPE = void 0;
exports.SHAPE = {
    TRIANGULAIRE: 1,
    RECTANGULAIRE: 2,
    RONDE: 4,
    CARRÉE: 6,
    OVALE: 7,
};
