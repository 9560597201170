<style scoped lang="scss">

@import "../../scss/color";

.CatalogueCustomSquareCustomSquare {
  width: 100%;
  height: 100%;
}

/* ------------------------------------------------ */

.CatalogueCustomSquareCustomSquare--teck {
  width: 100%;
  height: 100%;
  position: relative;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 0.5);
}

.CatalogueCustomSquareCustomSquare--teckVignette {
  width: 100%;
  height: 100%;
  display: block;
}

.CatalogueCustomSquareCustomSquare--teckInfo {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.CatalogueCustomSquareCustomSquare--teckInfoIcon {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0.2);
}

.CatalogueCustomSquareCustomSquare--teckInfoIconLogo {
  text-align: center;
  padding-top: 50px;
}

.CatalogueCustomSquareCustomSquare--teckInfoLabel {
  font-family: 'DDinRegular', sans-serif;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 24px;
  letter-spacing: 1px;
  flex: 1;
}

.CatalogueCustomSquareCustomSquare--teckInfoLabel ::v-deep .Term {
  padding-top: 10px;
  position: relative;
  top: -50px;
}

/* ------------------------------------------------ */

.CatalogueCustomSquareCustomSquare--solarLight {
  width: 100%;
  height: 100%;
  position: relative;
  text-shadow: 0 2px 0 rgba(0, 0, 0, 1);
  background: #49494A;
}

.CatalogueCustomSquareCustomSquare--solarLightVignette {
  width: 100%;
  height: 100%;
  display: block;
}

.CatalogueCustomSquareCustomSquare--solarLightInfo {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.CatalogueCustomSquareCustomSquare--solarLightInfoIcon {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  background: rgba(0, 0, 0, 0);
}

.CatalogueCustomSquareCustomSquare--solarLightInfoIconLogo {
  text-align: center;
  padding-top: 50px;
}

.CatalogueCustomSquareCustomSquare--solarLightInfoLabel {
  font-family: 'DDinRegular', sans-serif;
  color: white;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 25px;
  letter-spacing: 1px;
  flex: 1;
}

.CatalogueCustomSquareCustomSquare--solarLightInfoLabel ::v-deep .Term {
  padding-top: 10px;
  position: relative;
  top: -50px;
}

.CatalogueCustomSquareCustomSquare--solarLightInfoIconLogoText {
  color: #EDCF96;
  font-family: 'DDinRegular', sans-serif;
  font-size: 30px;
}

/* ------------------------------------------------ */

.CatalogueCustomSquareCustomSquare--outlet {
  width: 100%;
  height: 100%;
  position: relative;
}

.CatalogueCustomSquareCustomSquare--outletVignette {
  width: 100%;
  height: 100%;
  display: block;
}

.CatalogueCustomSquareCustomSquare--outletInfo {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
  box-sizing: border-box;
  border: solid 1px $primary;
}

.CatalogueCustomSquareCustomSquare--outletInfoIcon {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
}

.CatalogueCustomSquareCustomSquare--outletInfoIconLogo {
  text-align: center;
  padding-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.CatalogueCustomSquareCustomSquare--outletInfoIconLogo ::v-deep .Vector {
  width: 36px;
  height: 36px;
  fill: $primary;
  padding-bottom: 15px;
}

.CatalogueCustomSquareCustomSquare--outletInfoIconLogoText {
  font-family: 'DDinRegular', sans-serif;
  font-size: 30px;
}

.CatalogueCustomSquareCustomSquare--outletInfoLabel {
  font-family: 'DDinRegular', sans-serif;
  color: $primary;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  font-size: 20px;
  letter-spacing: 1px;
}

.CatalogueCustomSquareCustomSquare--outletInfoLabel ::v-deep .Term {
  padding-top: 10px;
  position: relative;
}

.CatalogueCustomSquare--collections {
  width: 100%;
  height: 100%;
  font-family: 'DDinRegular', sans-serif;
}

.CatalogueCustomSquare--collection {
  width: 100%;
  height: 100%;
  border: solid 1px rgba(0, 0, 0, 0.1);
  box-sizing: border-box;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  text-transform: uppercase;
}

.CatalogueCustomSquare--collection h5 {
  text-transform: uppercase;
  color: rgba(0, 0, 0, 0.4);
  letter-spacing: 1px;
  padding: 5px 0;
}
</style>

<template>
  <div class="CatalogueCustomSquareCustomSquare" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">


      <div class="CatalogueCustomSquareCustomSquare--teck" v-if="catalogType === CATALOG_TYPE.TECK">
        <img class="CatalogueCustomSquareCustomSquare--teckVignette" :src="img.teck.wood"></img>
        <div class="CatalogueCustomSquareCustomSquare--teckInfo">
          <div class="CatalogueCustomSquareCustomSquare--teckInfoIcon">
            <div class="CatalogueCustomSquareCustomSquare--teckInfoIconLogo">
              <img :src="img.teck.duracteck">
            </div>
            <div class="CatalogueCustomSquareCustomSquare--teckInfoLabel">
              <Term :term="lang.term(495)"></Term>
              <Term :term="lang.term(496)"></Term>
              <Term :term="lang.term(497)"></Term>
            </div>
          </div>
        </div>
      </div>


      <div class="CatalogueCustomSquareCustomSquare--solarLight" v-if="catalogType === CATALOG_TYPE.SOLAR_LIGHT">
<!--        <img class="CatalogueCustomSquareCustomSquare&#45;&#45;solarLightVignette" :src="img.solar_light.img4"></img>-->
        <div class="CatalogueCustomSquareCustomSquare--solarLightInfo">
          <div class="CatalogueCustomSquareCustomSquare--solarLightInfoIcon">
            <div class="CatalogueCustomSquareCustomSquare--solarLightInfoIconLogo">
              <img :src="img.solar_light.logo">
              <div class="CatalogueCustomSquareCustomSquare--solarLightInfoIconLogoText">Le solaire intelligent</div>
            </div>
            <div class="CatalogueCustomSquareCustomSquare--solarLightInfoLabel">
              <Term :term="lang.term(498)"></Term>
              <Term :term="lang.term(499)"></Term>
              <Term :term="lang.term(500)"></Term>
            </div>
          </div>
        </div>
      </div>


      <div class="CatalogueCustomSquareCustomSquare--outlet" v-if="catalogType === CATALOG_TYPE.OUTLET">
        <div class="CatalogueCustomSquareCustomSquare--outletInfo">
          <div class="CatalogueCustomSquareCustomSquare--outletInfoIcon">
            <div class="CatalogueCustomSquareCustomSquare--outletInfoIconLogo">
              <Vector :svg="icon.verified"></Vector>
              <div class="CatalogueCustomSquareCustomSquare--outletInfoIconLogoText">Destockage</div>
              <div class="CatalogueCustomSquareCustomSquare--outletInfoIconLogoText">direct fabriquant</div>
            </div>
            <div class="CatalogueCustomSquareCustomSquare--outletInfoLabel">
              <Term :term="lang.term(501)"></Term>
              <Term :term="lang.term(502)"></Term>
            </div>
          </div>
        </div>
      </div>

<!--      <template v-if="catalogType === CATALOG_TYPE.COLLECTION">-->
<!--        <div class="CatalogueCustomSquare&#45;&#45;collections">-->
<!--          <template v-for="article in articles.slice(0,1)">-->
<!--            <template v-for="bacth in article.batches">-->
<!--              <template v-for="product in bacth.products">-->
<!--                <template v-for="collection in product.collections" v-if="isCollection(collection)">-->
<!--                  <div class="CatalogueCustomSquare&#45;&#45;collection">-->
<!--                    <h1>{{ collection.value }}</h1>-->
<!--                    <h5>collection</h5>-->
<!--                  </div>-->
<!--                </template>-->
<!--              </template>-->
<!--            </template>-->
<!--          </template>-->
<!--        </div>-->
<!--      </template>-->
    </Responsive>
  </div>
</template>

<script>

import {deltaService} from "@bbx/delta~master/core/service/deltaService"
import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {CATALOG_TYPE} from "../../../@common/constant/CATALOG";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {FILTER_OPTION} from "../../../@common/constant/FILTER_OPTION";

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    },
    articles: {
      type: Array,
      default: () => ([])
    },
    catalogType: {
      type: Number,
      default: () => 0
    },
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      CATALOG_TYPE,
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      img: {
        teck: {
          wood: require('./img/teck/wood.jpg'),
          duracteck: require('./img/teck/duracteck.png'),
        },
        solar_light: {
          logo: require('./img/solar_light/logo.png'),
          img1: require('./img/solar_light/1.jpg'),
          img2: require('./img/solar_light/2.jpg'),
          img3: require('./img/solar_light/3.jpg'),
          img4: require('./img/solar_light/4.jpg'),
          img5: require('./img/solar_light/5.jpg'),
        }
      },
      icon: {
        verified: require('@bbx/vector~master/core/assets/svg/material/verified.svg'),
      },
      state: {},
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`CatalogueCustomSquareCustomSquare-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`CatalogueCustomSquareCustomSquare-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`CatalogueCustomSquareCustomSquare-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`CatalogueCustomSquareCustomSquare-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`CatalogueCustomSquareCustomSquare-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    /**
     *
     * @param {Collection} collection
     */
    isCollection(collection) {
      for (const collection_ of this.nuxtProp.page.collections) {
        if (collection.id === collection_.id) return true
      }
      for (const query of this.nuxtProp.url.queries) {
        if (query.name === FILTER_OPTION.COLLECTION_IDS) {
          let collectionIds = query.value.split ? query.value.split(',').map(a => deltaService.number(parseInt(a), 0)) : [deltaService.number(parseInt(query.value), 0)]
          if (collectionIds.includes(collection.id)) return true
        }
      }
    }
  }
}
</script>
