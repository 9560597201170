<style scoped>
.CatalogueFilter {
  width: 100%;
  height: 100%;
}

.CatalogueFilter--content {
  height: 100%;
}

.CatalogueFilter--header {
  align-items: center;
  border: solid 1px rgba(138, 108, 89, 0.5);
  justify-content: center;
  padding: 15px;
  margin-bottom: 25px;
  border-radius: 3px;
}

.CatalogueFilter-desktop .CatalogueFilter--header {
  display: flex;
}

.CatalogueFilter-tablet .CatalogueFilter--header {
  display: flex;
}

.CatalogueFilter-mobile .CatalogueFilter--header {
  display: none;
}

.CatalogueFilter--headerVector {
  width: 24px;
  position: relative;
  top: 2px;
  margin-right: 15px;
}

.CatalogueFilter--headerVector ::v-deep .Vector svg {
  fill: #8A6C59;
}

.CatalogueFilter--headerTerm ::v-deep .Term {
  font-family: 'RalewayBold', sans-serif;
  text-transform: uppercase;
}

.CatalogueFilter--body {
  background: rgba(0, 0, 0, 0.03);
  min-height: calc(100% - 73px);
  /*padding: 25px;*/
  box-sizing: border-box;
}

.CatalogueFilter--outlet {
  display: flex;
  justify-content: flex-start;
}

.CatalogueFilter--outletLabel {
  display: flex;
  align-items: center;
}

.CatalogueFilter--outletLabel1 {

}

.CatalogueFilter--outletLabel2 {
  font-size: 10px;
  font-family: 'DDinBold', sans-serif;
  color: #F56C6C;
  background: #ffefef;
  border-radius: 3px;
  padding: 4px 8px;
  letter-spacing: 1px;
  margin-left: 8px;
}

.CatalogueFilter--divider {
  height: 25px;
  background: white;
}


.CatalogueFilter--shape {
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CatalogueFilter--shape ::v-deep .Vector {
  width: 24px;
  height: 24px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.CatalogueFilter--shape ::v-deep .Vector svg {
  height: 12px;
  fill: black;
}


.CatalogueFilter--material {
  background: rgba(0, 0, 0, 0.1);
  border: solid 2px black;
  border-radius: 50px;
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  overflow: hidden;
}

</style>

<template>
  <div class="CatalogueFilter" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="CatalogueFilter--content">
        <div class="CatalogueFilter--header">
          <div class="CatalogueFilter--headerVector">
            <Vector :svg="icon.tune"/>
          </div>
          <div class="CatalogueFilter--headerTerm">
            <Term :term="lang.term(221)"/>
          </div>
        </div>
        <div class="CatalogueFilter--body">

          <FilterSection :title="lang.term(224)"
                         :svg="icon.local_offer"
                         :opened="true"
                         :selected="state.filterDeltaSelectedCategories"
                         :onSelectedAdd="v => onSelectedCategoryChange(v, true)"
                         :onSelectedChange="onCancelCategory"
                         :onSelectedRemove="v => onSelectedCategoryChange(v, false)"
                         :id="item => id(item)"
                         :items="state.filterDeltaCategories">
          </FilterSection>
          <div v-show="state.filterDeltaSubCategories.length">
            <div class="CatalogueFilter--divider"></div>
            <FilterCheckbox :title="lang.term(225)"
                            :svg="icon.gps_fixed"
                            :opened="state.filterDeltaSubCategories.length > 1 || !!state.filterDeltaSelectedSubCategories.length"
                            :selected="state.filterDeltaSelectedSubCategories"
                            :id="item => id(item)"
                            :onSelectedChange="v => onSelectedSubCategoryChange(v)"
                            :items="state.filterDeltaSubCategories">
              <template v-slot:term="{item}">
                <template v-if="!isSeller && canDisplay && item && item.extra[FILTER_OPTION.IS_OUTLET] !== undefined">
                  <div class="CatalogueFilter--outlet">
                    <div class="CatalogueFilter--outletLabel">
                      <div class="CatalogueFilter--outletLabel1">
                        <Term :term="lang.term(8)"/>
                      </div>
                      <div class="CatalogueFilter--outletLabel2">
                        <Term :term="lang.term(9)"/>
                      </div>
                    </div>
                  </div>
                </template>
                <template v-else>
                  <Term :term="item.term"/>
                </template>
              </template>
            </FilterCheckbox>
          </div>
          <div class="CatalogueFilter--divider"></div>
          <FilterRange :title="lang.term(505)"
                       :svg="icon.people"
                       :opened="state.filterDeltaSelectedSittingPlace.min !== state.filterDeltaSelectedSittingPlace.minValue || state.filterDeltaSelectedSittingPlace.max !== state.filterDeltaSelectedSittingPlace.maxValue"
                       :min="state.filterDeltaSelectedSittingPlace.min"
                       :minValue="state.filterDeltaSelectedSittingPlace.minValue"
                       :max="state.filterDeltaSelectedSittingPlace.max"
                       :maxValue="state.filterDeltaSelectedSittingPlace.maxValue"
                       :onValuesChange="v => onSelectedSittingPlace(v)"></FilterRange>
          <div class="CatalogueFilter--divider"></div>
          <FilterCheckbox :title="lang.term(506)"
                          :svg="icon.highlight"
                          :opened="!!state.filterDeltaSelectedLumens.length"
                          :selected="state.filterDeltaSelectedLumens"
                          :id="item => id(item)"
                          :onSelectedChange="v => onSelectedLumensChange(v)"
                          :items="state.filterDeltaLumens">
          </FilterCheckbox>
          <div class="CatalogueFilter--divider"></div>
          <FilterCheckbox :title="lang.term(507)"
                          :svg="icon.style"
                          :opened="!!state.filterDeltaSelectedCollections.length"
                          :selected="state.filterDeltaSelectedCollections"
                          :id="item => id(item)"
                          :onSelectedChange="v => onSelectedCollectionsChange(v)"
                          :items="state.filterDeltaCollections">
          </FilterCheckbox>

          <CountryLocation>
            <div class="CatalogueFilter--divider"></div>
            <FilterRange :title="lang.term(508)"
                         :svg="icon.euro_symbol"
                         :opened="state.filterDeltaSelectedPrice.min !== state.filterDeltaSelectedPrice.minValue || state.filterDeltaSelectedPrice.max !== state.filterDeltaSelectedPrice.maxValue"
                         :min="state.filterDeltaSelectedPrice.min"
                         :minValue="state.filterDeltaSelectedPrice.minValue"
                         :max="state.filterDeltaSelectedPrice.max"
                         :maxValue="state.filterDeltaSelectedPrice.maxValue"
                         :onValuesChange="v => onSelectedPriceChange(v)">
            </FilterRange>
          </CountryLocation>
          <div class="CatalogueFilter--divider"></div>
          <FilterSection :title="lang.term(509)"
                         :svg="icon.texture"
                         :opened="!!state.filterDeltaSelectedMaterials.length"
                         :selected="state.filterDeltaSelectedMaterials"
                         :id="item => id(item)"
                         :onSelectedChange="v => onSelectedMaterialsChange(v)"
                         :items="state.filterDeltaMaterials">
            <template v-slot:img="{item}">
              <div class="CatalogueFilter--material">
                <Picture v-if="item.img" :src="item.img"></Picture>
              </div>
            </template>
          </FilterSection>
          <div class="CatalogueFilter--divider"></div>
          <FilterSize :title="lang.term(510)"
                      :svg="icon.zoom_out_map"
                      :opened="sizeIsOpened"
                      :onChange="v => onSelecteSizeChange(v)"
                      :minSize="state.filterDeltaSelectedSize.minSize"
                      :maxSize="state.filterDeltaSelectedSize.maxSize">
          </FilterSize>
          <div class="CatalogueFilter--divider"></div>
          <FilterSection :title="lang.term(511)"
                         :svg="icon.widgets"
                         :opened="!!state.filterDeltaSelectedShapes.length"
                         :selected="state.filterDeltaSelectedShapes"
                         :id="item => id(item)"
                         :onSelectedChange="v => onSelectedShapesChange(v)"
                         :items="state.filterDeltaShapes">
            <template v-slot:img="{item}">
              <div class="CatalogueFilter--shape">
                <Vector :svg="getSvgShape(item)"></Vector>
              </div>
            </template>
          </FilterSection>
          <div class="CatalogueFilter--divider"></div>
          <FilterCheckbox :title="lang.term(512)"
                          :svg="icon.filter_none"
                          :opened="!!state.filterDeltaSelectedBatches.length"
                          :selected="state.filterDeltaSelectedBatches"
                          :id="item => id(item)"
                          :onSelectedChange="v => onSelectedBatchesChange(v)"
                          :items="state.filterDeltaBatches">
          </FilterCheckbox>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>
import Id from "@bbx/id~master/core/ts/Id";
import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {deltaService} from "@bbx/delta~master/core/service/deltaService";
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

import {Category} from "../../../@common/delta/database/Category";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {Term} from "../../../@common/delta/database/Term";
import {Url} from "../../../@common/delta/database/Url";
import {ProjectUrl} from "../../../@common/delta/database/ProjectUrl";

import {PATHS} from "../../../@common/constant/PATHS";
import {CATEGORY} from "../../../@common/constant/CATEGORY.ts";
import {CATEGORY_IMG} from "../../../@common/constant/CATEGORY_IMG.ts";
import {convertPathToUrl, nuxtQueriesToQueryObject} from "../../../@common/function/helperUrl";
import {Page} from "../../../@common/delta/database/Page";
import {urlService} from "../../../@common/service/urlService";
import {FILTER_OPTION} from "../../../@common/constant/FILTER_OPTION";
import {defined} from "../../../@common/function/defined";
import {catalogArticleFilterExtract} from "../../../@common/filter/catalogArticleFilterExtract";
import {changeRoute} from "../../function/changeRoute";
import {FilterDelta} from "../../../@common/delta/filter/FilterDelta";
import {STATE_ROLE_ID, stateService} from "../../service/stateService";
import {ROLE} from "../../../@common/constant/ROLE";
import {Size} from "../../../@common/delta/database/Size";
import {apiClient} from "../../service/apiClientService";
import selectShapes from "../../../@common/api/shape/selectShapes";
import selectMaterials from "../../../@common/api/material/selectMaterials";
import selectCollections from "../../../@common/api/collection/selectCollections";
import {SHAPE} from "../../../@common/constant/SHAPE";
import {getS3PathFromFile} from "../../../@common/function/getS3Path";
import {EVENT} from "../../../@common/constant/EVENT";
import {eventService} from "../../service/eventService";
import {canDisplay, getCountry} from "../../service/countryService";
import {sendError} from "../../function/sendError";
import selectCategories from "../../../@common/api/category/selectCategories";
import selectProjectUrls from "../../../@common/api/project_url/selectProjectUrls";
import {PROJECT_URL} from "../../../@common/constant/PROJECT_URL";

const id = new Id()

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    },
  },
  data() {
    const lang = getLangServiceBrowser()
    return {
      FILTER_OPTION,
      ROLE,
      PATHS,
      CATEGORY,
      lang,
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 920
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
        local_offer: require('@bbx/vector~master/core/assets/svg/material/local_offer.svg'),
        bookmark: require('@bbx/vector~master/core/assets/svg/material/bookmark.svg'),
        gps_fixed: require('@bbx/vector~master/core/assets/svg/material/gps_fixed.svg'),
        style: require('@bbx/vector~master/core/assets/svg/material/style.svg'),
        euro_symbol: require('@bbx/vector~master/core/assets/svg/material/euro_symbol.svg'),
        texture: require('@bbx/vector~master/core/assets/svg/material/texture.svg'),
        people: require('@bbx/vector~master/core/assets/svg/material/people.svg'),
        zoom_out_map: require('@bbx/vector~master/core/assets/svg/material/zoom_out_map.svg'),
        filter_none: require('@bbx/vector~master/core/assets/svg/material/filter_none.svg'),
        widgets: require('@bbx/vector~master/core/assets/svg/material/widgets.svg'),
        highlight: require('@bbx/vector~master/core/assets/svg/material/highlight.svg'),

        // -----
        // shape
        // -----

        carre: require('../../../@common/assets/shape/carre.svg'),
        ovale: require('../../../@common/assets/shape/ovale.svg'),
        rectangle: require('../../../@common/assets/shape/rectangle.svg'),
        ronde: require('../../../@common/assets/shape/ronde.svg'),
        triangle: require('../../../@common/assets/shape/triangle.svg'),
      },
      state: {
        /**
         * @type string
         */
        country: '',
        /**
         * @type {Category[]}
         */
        categories: [],
        /**
         * @type {ProjectUrl[]}
         */
        projectUrls: [],
        /**
         * @type {Shape[]}
         */
        shapes: [],
        /**
         * @type {Material[]}
         */
        materials: [],
        /**
         * @type {Collection[]}
         */
        collections: [],

        // -----

        /**
         * @type {FilterDelta[]}
         */
        filterDeltaCategories: [],
        /**
         * @type {FilterDelta[]}
         */
        filterDeltaSubCategories: [],
        /**
         * @type {FilterDelta[]}
         */
        filterDeltaBatches: [
          new FilterDelta({
            id: id.uniq(),
            term: lang.term(513),
            extra: {
              [FILTER_OPTION.IS_BATCH_BIS]: true
            }
          }),
          new FilterDelta({
            id: id.uniq(),
            term: lang.term(514),
            extra: {
              [FILTER_OPTION.IS_SET]: true
            }
          }),
          new FilterDelta({
            id: id.uniq(),
            term: lang.term(515),
            extra: {
              [FILTER_OPTION.IS_BATCH]: false
            }
          })
        ],
        /**
         * @type {FilterDelta[]}
         */
        filterDeltaLumens: [
          new FilterDelta({
            id: id.uniq(),
            term: lang.term(516),
            extra: {
              [FILTER_OPTION.LUMEN_SEARCH]: 300
            }
          }),
          new FilterDelta({
            id: id.uniq(),
            term: lang.term(517),
            extra: {
              [FILTER_OPTION.LUMEN_SEARCH]: 500
            }
          })
        ],
        /**
         * @type {FilterDelta[]}
         */
        filterDeltaShapes: [],
        /**
         * @type {FilterDelta[]}
         */
        filterDeltaMaterials: [],
        /**
         * @type {FilterDelta[]}
         */
        filterDeltaCollections: [],

        // -----

        /**
         * @type {}
         */
        filterDeltaSelectedPrice: {
          min: 0,
          minValue: 0,
          max: 8000,
          maxValue: 8000,
        },

        /**
         * @type {}
         */
        filterDeltaSelectedSittingPlace: {
          min: 0,
          minValue: 0,
          max: 20,
          maxValue: 20,
        },
        /**
         * @type {FilterDelta[]}
         */
        filterDeltaSelectedCategories: [],
        /**
         * @type {FilterDelta[]}
         */
        filterDeltaSelectedSubCategories: [],
        /**
         * @type {FilterDelta[]}
         */
        filterDeltaSelectedBatches: [],
        /**
         * @type {FilterDelta[]}
         */
        filterDeltaSelectedLumens: [],

        /**
         * @type {}
         */
        filterDeltaSelectedSize: {
          minSize: new Size(),
          maxSize: new Size(),
        },

        /**
         * @type {FilterDelta[]}
         */
        filterDeltaSelectedShapes: [],

        /**
         * @type {FilterDelta[]}
         */
        filterDeltaSelectedMaterials: [],

        /**
         * @type {FilterDelta[]}
         */
        filterDeltaSelectedCollections: [],

        // -----

        /**
         * @type {string}
         */
        link: '',
        /**
         * @type {number}
         */
        roleId: 0,
        /**
         * @type {string}
         */
        loadingShape: false,
        /**
         * @type {string}
         */
        loadingMaterial: false,
        /**
         * @type {string}
         */
        loadingCollection: false,
      },
      events: []
    }
  },
  watch: {
    'nuxtProp.payload': {
      handler: function () {
        this.init()
      },
      deep: true
    },
    'state.country': {
      handler: function () {
        this.generateSelectFromQueries()
        this.initFilterDelta()
      },
      deep: true
    },
    'state.link': {
      handler: function (v) {
        changeRoute([], {}, v)
      },
      deep: true
    }
  },
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`CatalogueFilter-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`CatalogueFilter-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`CatalogueFilter-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`CatalogueFilter-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`CatalogueFilter-desktopY`)
      return classes
    },
    sizeIsOpened() {
      return JSON.stringify(this.state.filterDeltaSelectedSize.minSize) !== JSON.stringify(new Size()) || JSON.stringify(this.state.filterDeltaSelectedSize.maxSize) !== JSON.stringify(new Size())
    },
    isSeller() {
      return this.state.roleId === ROLE.SELLER
    },
    canDisplay() {
      return canDisplay(this.state.country)
    },
  },
  beforeMount() {

    // -----

    this.state.roleId = stateService.get(STATE_ROLE_ID)
    stateService.watch(STATE_ROLE_ID, v => this.state.roleId = v)

    // -----

    this.init()

    // -----

    this.state.country = getCountry()

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.CHANGE_COUNTRY_LOCATION,
      callback: (country) => {
        this.state.country = country
      }
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {

    init() {
      this.iniAsync().catch(sendError)
    },

    async iniAsync() {
      await this.initAsyncEntities()
      await this.initAsyncFilterDelta()
      this.generateSelectFromQueries() // we check again the url query for auto fill
    },

    async initAsyncEntities() {
      this.state.categories = await this.selectCategories()
      this.state.projectUrls = await this.selectProjectUrls()
      this.initFilterDelta()
      this.generateSelectFromQueries()
      this.triggerSubCategories()
      this.state.shapes = await this.selectShapes()
      this.state.materials = await this.selectMaterials()
      this.state.collections = await this.selectCollections()
    },

    triggerSubCategories() {
      this.state.filterDeltaSubCategories = this.state.filterDeltaSelectedCategories.length < 2 ? this.subCategoriesToFilterSections(this.state.categories).filter(this.filterSubCategories) : this.subCategoriesToFilterSections(this.state.categories).filter(a => a.extra[FILTER_OPTION.IS_OUTLET] !== undefined)
      if (this.state.filterDeltaSelectedCategories.length > 1) this.state.filterDeltaSelectedSubCategories = this.state.filterDeltaSelectedSubCategories.filter(a => a.extra[FILTER_OPTION.IS_OUTLET] !== undefined)
    },

    async selectShapes() {

      if (this.state.shapes.length) return this.state.shapes

      this.state.loadingShape = true

      const {data} = await apiClient.do(selectShapes, selectShapes.with({
        active: true
      }))

      this.state.loadingShape = false

      return data
    },

    async selectMaterials() {

      if (this.state.materials.length) return this.state.materials

      this.state.loadingMaterial = true

      const {data} = await apiClient.do(selectMaterials, selectMaterials.with({
        active: true,
        $mostUsedFirst: true,
        $withPhotoFirst: true,
        // $limit: 10
      }))

      this.state.loadingMaterial = false

      return data
    },
    async selectCategories() {

      if (this.state.categories.length) return this.state.categories

      const {data} = await apiClient.do(selectCategories, selectCategories.with({
        active: true,
        $cache: true
      }))
      return data
    },
    async selectProjectUrls() {

      if (this.state.projectUrls.length) return this.state.projectUrls

      const {data} = await apiClient.do(selectProjectUrls, selectProjectUrls.with({
        active: true,
        $cache: true,
        $ids: [
          PROJECT_URL.MOBILIER_DE_JARDIN_PRODUITS_ENSEMBLE_SALON_DE_JARDIN
        ]
      }))
      return data
    },

    async selectCollections() {

      if (this.state.collections.length) return this.state.collections

      this.state.loadingCollection = true

      const {data} = await apiClient.do(selectCollections, selectCollections.with({
        active: true,
        $popularFirst: true,
        // $limit: 12
      }))

      this.state.loadingCollection = false

      return data.sort((a, b) => a.value.localeCompare(b.value))
    },

    initFilterDelta() {
      this.state.filterDeltaCategories = this.categoriesToFilterSections(this.state.categories, this.state.projectUrls)
      this.state.filterDeltaSubCategories = this.subCategoriesToFilterSections(this.state.categories)
    },

    async initAsyncFilterDelta() {
      this.state.filterDeltaShapes = this.shapesToFilterSections(this.state.shapes)
      this.state.filterDeltaMaterials = this.materialsToFilterSections(this.state.materials)
      this.state.filterDeltaCollections = this.collectionsToFilterSections(this.state.collections)
    },

    generateSelectFromQueries() {

      // -----

      let request = catalogArticleFilterExtract({
        request: {},
        nuxtProp: this.nuxtProp
      })

      // -----

      let attr = ''

      // -----

      if (request[FILTER_OPTION.CATEGORY_IDS] === undefined) this.state.filterDeltaSelectedCategories = []
      if (request[`$${FILTER_OPTION.CATEGORY_CHILDREN_IDS}`] === undefined) this.state.filterDeltaSelectedSubCategories = []
      if (request[`$${FILTER_OPTION.MIN_PRICE}`] === undefined) this.state.filterDeltaSelectedPrice.minValue = this.state.filterDeltaSelectedPrice.min
      if (request[`$${FILTER_OPTION.MAX_PRICE}`] === undefined) this.state.filterDeltaSelectedPrice.maxValue = this.state.filterDeltaSelectedPrice.max
      if (request[`$${FILTER_OPTION.MAX_SITTING_PLACE}`] === undefined) this.state.filterDeltaSelectedSittingPlace.minValue = this.state.filterDeltaSelectedSittingPlace.min
      if (request[`$${FILTER_OPTION.MIN_SITTING_PLACE}`] === undefined) this.state.filterDeltaSelectedSittingPlace.maxValue = this.state.filterDeltaSelectedSittingPlace.max
      if (request[`$${FILTER_OPTION.IS_BATCH_BIS}`] === undefined && request[`$${FILTER_OPTION.IS_SET}`] === undefined && request[`$${FILTER_OPTION.IS_BATCH}`] === undefined) this.state.filterDeltaSelectedBatches = []
      if (request[`$${FILTER_OPTION.LUMEN_SEARCH}`] === undefined) this.state.filterDeltaSelectedLumens = []
      if (request[`$${FILTER_OPTION.SHAPE_IDS}`] === undefined) this.state.filterDeltaSelectedShapes = []
      if (request[`$${FILTER_OPTION.MATERIAL_IDS}`] === undefined) this.state.filterDeltaSelectedMaterials = []
      if (request[FILTER_OPTION.COLLECTION_IDS] === undefined) this.state.filterDeltaSelectedCollections = []

      if (request[`$${FILTER_OPTION.MIN_SIZE_LENGTH}`] === undefined) this.state.filterDeltaSelectedSize.minSize.length = 0
      if (request[`$${FILTER_OPTION.MIN_SIZE_WIDTH}`] === undefined) this.state.filterDeltaSelectedSize.minSize.width = 0
      if (request[`$${FILTER_OPTION.MIN_SIZE_HEIGHT}`] === undefined) this.state.filterDeltaSelectedSize.minSize.height = 0
      if (request[`$${FILTER_OPTION.MAX_SIZE_LENGTH}`] === undefined) this.state.filterDeltaSelectedSize.maxSize.length = 0
      if (request[`$${FILTER_OPTION.MAX_SIZE_WIDTH}`] === undefined) this.state.filterDeltaSelectedSize.maxSize.width = 0
      if (request[`$${FILTER_OPTION.MAX_SIZE_HEIGHT}`] === undefined) this.state.filterDeltaSelectedSize.maxSize.height = 0

      // console.log('QUERY IN', request);
      // console.log('REQUEST IN 0.filterDeltaCategories', this.state.filterDeltaCategories);
      // console.log('REQUEST IN 0.filterDeltaSubCategories', this.state.filterDeltaSubCategories);

      // -----

      let filterDeltaSelectedCategoryIds = this.state.filterDeltaSelectedCategories.map(a => this.id(a))
      let filterDeltaSelectedSubCategoryIds = this.state.filterDeltaSelectedSubCategories.map(a => this.id(a))
      let filterDeltaSelectedBatches = this.state.filterDeltaSelectedBatches.map(a => this.id(a))
      let filterDeltaSelectedLumens = this.state.filterDeltaSelectedLumens.map(a => this.id(a))
      let filterDeltaSelectedShapes = this.state.filterDeltaSelectedShapes.map(a => this.id(a))
      let filterDeltaSelectedMaterials = this.state.filterDeltaSelectedMaterials.map(a => this.id(a))
      let filterDeltaSelectedCollections = this.state.filterDeltaSelectedCollections.map(a => this.id(a))
      // console.log('REQUEST IN 1.filterDeltaSelectedCategoryIds', filterDeltaSelectedCategoryIds);
      // console.log('REQUEST IN 1.filterDeltaSelectedSubCategoryIds', filterDeltaSelectedSubCategoryIds);

      // -----

      for (const category of this.nuxtProp.page.categories) {
        let attr = FILTER_OPTION.CATEGORY_IDS
        if (!request[attr]) request[attr] = []
        if (!request[attr].includes(category.id) && category.level === 0) {
          request[attr].push(category.id)
        }
      }
      for (const filterDelta of this.state.filterDeltaCategories) {
        let attr = FILTER_OPTION.CATEGORY_IDS
        if (request[attr] && request[attr].includes(filterDelta.extra[attr])) {
          if (!filterDeltaSelectedCategoryIds.includes(this.id(filterDelta))) {
            this.state.filterDeltaSelectedCategories.push(filterDelta)
          }
        }
        attr = FILTER_OPTION.IS_SET
        if (request[`$${attr}`] && request[`$${attr}`] === filterDelta.extra[attr]) {
          if (!filterDeltaSelectedCategoryIds.includes(this.id(filterDelta))) {
            this.state.filterDeltaSelectedCategories.push(filterDelta)
          }
        }
      }

      // -----

      for (const category of this.nuxtProp.page.categories) {
        let attr = FILTER_OPTION.CATEGORY_CHILDREN_IDS
        if (!request[attr]) request[attr] = []
        if (!request[attr].includes(category.id) && category.level === -1) {
          request[attr].push(category.id)
        }
      }
      for (const filterDelta of this.state.filterDeltaSubCategories) {
        let attr = FILTER_OPTION.CATEGORY_CHILDREN_IDS
        let isSelected = !!request[`$${attr}`] && request[`$${attr}`].includes(filterDelta.extra[attr])
        if (isSelected) {
          if (!filterDeltaSelectedSubCategoryIds.includes(this.id(filterDelta))) {
            this.state.filterDeltaSelectedSubCategories.push(filterDelta)
          }
        }

        attr = FILTER_OPTION.IS_OUTLET
        isSelected = !!request[attr] && filterDelta.extra[attr] === request[attr]
        if (isSelected) {
          if (!filterDeltaSelectedSubCategoryIds.includes(this.id(filterDelta))) {
            this.state.filterDeltaSelectedSubCategories.push(filterDelta)
          }
        }
      }

      // -----

      attr = `$${FILTER_OPTION.MIN_PRICE}`
      if (request[attr] && this.state.filterDeltaSelectedPrice.min !== request[attr]) {
        this.state.filterDeltaSelectedPrice.minValue = request[attr]
      }
      attr = `$${FILTER_OPTION.MAX_PRICE}`
      if (request[attr] && this.state.filterDeltaSelectedPrice.max !== request[attr]) {
        this.state.filterDeltaSelectedPrice.maxValue = request[attr]
      }

      // -----

      attr = `$${FILTER_OPTION.MIN_SITTING_PLACE}`
      if (request[attr] && this.state.filterDeltaSelectedSittingPlace.min !== request[attr]) {
        this.state.filterDeltaSelectedSittingPlace.minValue = request[attr]
      }
      attr = `$${FILTER_OPTION.MAX_SITTING_PLACE}`
      if (request[attr] && this.state.filterDeltaSelectedSittingPlace.max !== request[attr]) {
        this.state.filterDeltaSelectedSittingPlace.maxValue = request[attr]
      }

      // -----

      for (const filterDelta of this.state.filterDeltaBatches) {
        let attr = FILTER_OPTION.IS_SET
        let isSelected = request[`$${attr}`] !== undefined && request[`$${attr}`] === filterDelta.extra[attr]
        if (isSelected) {
          if (!filterDeltaSelectedBatches.includes(this.id(filterDelta))) {
            this.state.filterDeltaSelectedBatches.push(filterDelta)
          }
        }

        attr = FILTER_OPTION.IS_BATCH_BIS
        isSelected = request[`$${attr}`] !== undefined && request[`$${attr}`] === filterDelta.extra[attr]
        if (isSelected) {
          if (!filterDeltaSelectedBatches.includes(this.id(filterDelta))) {
            this.state.filterDeltaSelectedBatches.push(filterDelta)
          }
        }

        attr = FILTER_OPTION.IS_BATCH
        isSelected = request[attr] !== undefined && request[attr] === filterDelta.extra[attr]
        if (isSelected) {
          if (!filterDeltaSelectedBatches.includes(this.id(filterDelta))) {
            this.state.filterDeltaSelectedBatches.push(filterDelta)
          }
        }
      }

      // -----

      for (const filterDelta of this.state.filterDeltaLumens) {
        let attr = FILTER_OPTION.LUMEN_SEARCH
        let isSelected = request[`$${attr}`] && request[`$${attr}`] === filterDelta.extra[attr]
        if (isSelected) {
          if (!filterDeltaSelectedLumens.includes(this.id(filterDelta))) {
            this.state.filterDeltaSelectedLumens.push(filterDelta)
          }
        }
      }

      // -----

      attr = `$${FILTER_OPTION.MIN_SIZE_LENGTH}`;
      if (request[attr] && this.state.filterDeltaSelectedSize.minSize.length !== request[attr]) {
        this.state.filterDeltaSelectedSize.minSize.length = request[attr]
      }
      attr = `$${FILTER_OPTION.MIN_SIZE_WIDTH}`;
      if (request[attr] && this.state.filterDeltaSelectedSize.minSize.width !== request[attr]) {
        this.state.filterDeltaSelectedSize.minSize.width = request[attr]
      }
      attr = `$${FILTER_OPTION.MIN_SIZE_HEIGHT}`;
      if (request[attr] && this.state.filterDeltaSelectedSize.minSize.height !== request[attr]) {
        this.state.filterDeltaSelectedSize.minSize.height = request[attr]
      }
      attr = `$${FILTER_OPTION.MAX_SIZE_LENGTH}`;
      if (request[attr] && this.state.filterDeltaSelectedSize.maxSize.length !== request[attr]) {
        this.state.filterDeltaSelectedSize.maxSize.length = request[attr]
      }
      attr = `$${FILTER_OPTION.MAX_SIZE_WIDTH}`;
      if (request[attr] && this.state.filterDeltaSelectedSize.maxSize.width !== request[attr]) {
        this.state.filterDeltaSelectedSize.maxSize.width = request[attr]
      }
      attr = `$${FILTER_OPTION.MAX_SIZE_HEIGHT}`;
      if (request[attr] && this.state.filterDeltaSelectedSize.maxSize.height !== request[attr]) {
        this.state.filterDeltaSelectedSize.maxSize.height = request[attr]
      }

      // -----

      for (const filterDelta of this.state.filterDeltaShapes) {
        let attr = FILTER_OPTION.SHAPE_IDS
        if (request[`$${attr}`] && request[`$${attr}`].includes(filterDelta.extra[attr])) {
          if (!filterDeltaSelectedShapes.includes(this.id(filterDelta))) {
            this.state.filterDeltaSelectedShapes.push(filterDelta)
          }
        }
      }

      // -----

      for (const filterDelta of this.state.filterDeltaMaterials) {
        let attr = FILTER_OPTION.MATERIAL_IDS
        if (request[`$${attr}`] && request[`$${attr}`].includes(filterDelta.extra[attr])) {
          if (!filterDeltaSelectedMaterials.includes(this.id(filterDelta))) {
            this.state.filterDeltaSelectedMaterials.push(filterDelta)
          }
        }
      }

      // -----

      for (const collection of this.nuxtProp.page.collections) {
        let attr = FILTER_OPTION.COLLECTION_IDS
        if (!request[attr]) request[attr] = []
        if (!request[attr].includes(collection.id)) {
          request[attr].push(collection.id)
        }
      }
      for (const filterDelta of this.state.filterDeltaCollections) {
        let attr = FILTER_OPTION.COLLECTION_IDS
        if (request[attr] && request[attr].includes(filterDelta.extra[attr])) {
          if (!filterDeltaSelectedCollections.includes(this.id(filterDelta))) {
            this.state.filterDeltaSelectedCollections.push(filterDelta)
          }
        }
      }

      // -----

      // console.log('REQUEST IN 2.filterDeltaSelectedCategories', this.state.filterDeltaSelectedCategories.map(a => this.id(a)))
      // console.log('REQUEST IN 2.filterDeltaSelectedSubCategorie', this.state.filterDeltaSelectedSubCategories.map(a => this.id(a)))
    },

    /**
     * @param {FilterDelta[]} categories
     */
    filterSubCategories(deltaFilter) {

      let filterDeltaParent = new FilterDelta()
      for (const filterDeltaSelectedCategory of this.state.filterDeltaSelectedCategories) {
        filterDeltaParent = filterDeltaSelectedCategory
      }

      return filterDeltaParent.extra[FILTER_OPTION.CATEGORY_IDS] === deltaFilter.extra.categoryParentId || deltaFilter.extra[FILTER_OPTION.IS_OUTLET] !== undefined
    },
    /**
     * @param {Category[]} categories
     * @param {ProjectUrl[]} projectUrls
     */
    categoriesToFilterSections(categories, projectUrls) {
      const items = []

      // -----

      for (const category of categories) {
        if (category.level === 0 && this.onFilterCategory(category)) {

          let indexed = false

          for (const page of category.pages) {
            indexed = page.indexed
          }

          let url = convertPathToUrl(new Page(category.pages[0]))

          items.push(new FilterDelta({
            id: id.uniq(),
            indexed: indexed,
            url: url,
            img: CATEGORY_IMG[category.id],
            term: new Term(category.terms[0]),
            extra: {
              [FILTER_OPTION.CATEGORY_IDS]: category.id,
            }
          }))
        }
      }

      // -----

      for (const projectUrl of projectUrls) {
        let indexed = false
        let term = new Term()
        for (const url of projectUrl.urls) {
          for (const page of url.pages) {
            indexed = page.indexed
          }
          url.sorts = url.sorts.sort((a, b) => a.order - b.order)
          for (const sort of url.sorts) {
            for (const path of sort.paths) {
              term = new Term(path.terms[0])
            }
          }
        }

        items.push(new FilterDelta({
          id: id.uniq(),
          indexed: indexed,
          url: new Url(projectUrl.urls[0]),
          img: require('../../../@common/assets/database/category/png/11.png'),
          term: term,
          extra: {
            [FILTER_OPTION.IS_SET]: true
          }
        }))
      }

      // -----

      return items
    },

    /**
     * @param {Category[]} categories
     */
    subCategoriesToFilterSections(categories) {
      const items = []

      // -----
      // if we are seeler we dont push default outlet sub category
      // -----

      if (!this.isSeller && this.canDisplay) {
        items.push(new FilterDelta({
          id: id.uniq(),
          url: new Url(),
          extra: {
            [FILTER_OPTION.IS_OUTLET]: true
          }
        }))
      }

      // -----

      for (const category of categories) {

        if (category.level === -1) {

          let indexed = false

          for (const page of category.pages) {
            indexed = page.indexed
          }

          let url = convertPathToUrl(new Page(category.pages[0]))

          items.push(new FilterDelta({
            id: id.uniq(),
            indexed: indexed,
            parentTerm: new Term(category.terms[0]),
            url: url,
            term: new Term(category.terms[0]),
            extra: {
              [FILTER_OPTION.CATEGORY_CHILDREN_IDS]: category.id,
              categoryParentId: category.categoryParents[0].id
            }
          }))
        }
      }

      // -----

      return items
    },

    /**
     * @param {Shape[]} shapes
     */
    shapesToFilterSections(shapes) {

      // -----

      const items = []

      // -----

      for (const shape of shapes) {
        items.push(new FilterDelta({
          id: id.uniq(),
          term: new Term(shape.terms[0]),
          extra: {
            [FILTER_OPTION.SHAPE_IDS]: shape.id
          }
        }))
      }

      // -----

      return items
    },

    /**
     * @param {Material[]} materials
     */
    materialsToFilterSections(materials) {

      // -----

      const items = []

      // -----

      for (const material of materials) {

        let img = ''

        for (const photo of material.photos) {
          for (const file of photo.files) {
            img = getS3PathFromFile(file)
          }
        }

        items.push(new FilterDelta({
          id: id.uniq(),
          term: new Term(material.terms[0]),
          img: img,
          extra: {
            [FILTER_OPTION.MATERIAL_IDS]: material.id
          }
        }))
      }

      // -----

      return items
    },

    /**
     * @param {Collection[]} collections
     */
    collectionsToFilterSections(collections) {

      // -----

      const items = []

      // -----

      for (const collection of collections) {
        items.push(new FilterDelta({
          id: id.uniq(),
          term: this.lang.simulate(collection.value),
          extra: {
            [FILTER_OPTION.COLLECTION_IDS]: collection.id
          }
        }))
      }

      // -----

      return items
    },

    // generateLink(queries) {
    //   const queryString = urlService.objectToQueryString(queries)
    //   const paths = this.nuxtProp.url.paths.map(a => a.value)
    //
    //   return `/${paths.join('/')}${queryString ? `?${queryString}` : ``}`
    // },

    generateLink(queries) {
      const queryString = urlService.objectToQueryString(queries)
      const paths = [
        this.lang.lang.iso6391,
        urlService.getPathValueById(PATHS.CATALOG, this.lang.lang.iso6391 || LANG_ISO.FR)
      ]
      return `/${paths.join('/')}${queryString ? `?${queryString}` : ``}`
    },

    /**
     * @param {FilterDelta} item
     * @param {boolean} add
     */
    onSelectedCategoryChange(item, add) {

      let items = []
      if (add || this.state.filterDeltaSelectedCategories.length > 1) items = [item]

      let isDifferent = JSON.stringify(items.map(a => this.id(a))) !== JSON.stringify(this.state.filterDeltaSelectedCategories.map(a => this.id(a)))
      if (isDifferent) {
        this.state.filterDeltaSelectedCategories = items
        this.state.filterDeltaSelectedSubCategories = this.state.filterDeltaSelectedSubCategories.filter(a => a.extra[FILTER_OPTION.IS_OUTLET])

        // si oin choisi clean les batches
        this.state.filterDeltaSelectedBatches = []

        // si on a une catégories qui ne peut pas avoir de lumen
        if (items[items.length - 1]) {
          let item = items[items.length - 1]
          if (item.extra[FILTER_OPTION.CATEGORY_IDS] !== CATEGORY.LUMINAIRE) {
            this.state.filterDeltaSelectedLumens = []
          }
        }

        // si on une catégories qui ne peut pas avoir de pace assise
        if (items[items.length - 1]) {
          let item = items[items.length - 1]
          if ([
            CATEGORY.LUMINAIRE,
            CATEGORY.DOUCHE,
            CATEGORY.PARASOL,
            CATEGORY.ACCESSOIRE
          ].includes(item.extra[FILTER_OPTION.CATEGORY_IDS])) {
            this.state.filterDeltaSelectedSittingPlace = {
              min: 0,
              minValue: 0,
              max: 20,
              maxValue: 20,
            }
          }
        }

        this.triggerSubCategories()
        this.triggerQuery()
      }
    },

    /**
     * @param {FilterDelta[]} items
     */
    onSelectedSubCategoryChange(items) {
      let isDifferent = JSON.stringify(items.map(a => this.id(a))) !== JSON.stringify(this.state.filterDeltaSelectedSubCategories.map(a => this.id(a)))
      if (isDifferent) {
        this.state.filterDeltaSelectedSubCategories = items
        this.triggerQuery()
      }
    },

    /**
     * @param {FilterDelta[]} items
     */
    onSelectedPriceChange(items) {
      let isDifferent = JSON.stringify(items) !== JSON.stringify(this.state.filterDeltaSelectedPrice)
      if (isDifferent) {
        this.state.filterDeltaSelectedPrice.minValue = items.minValue
        this.state.filterDeltaSelectedPrice.maxValue = items.maxValue
        this.triggerQuery()
      }
    },

    /**
     * @param {FilterDelta[]} items
     */
    onSelecteSizeChange(items) {
      let isDifferent = JSON.stringify(items) !== JSON.stringify(this.state.filterDeltaSelectedSize)
      if (isDifferent) {
        this.state.filterDeltaSelectedSize.minSize = items.minSize
        this.state.filterDeltaSelectedSize.maxSize = items.maxSize
        this.triggerQuery()
      }
    },

    /**
     * @param {FilterDelta[]} items
     */
    onSelectedSittingPlace(items) {
      let isDifferent = JSON.stringify(items) !== JSON.stringify(this.state.filterDeltaSelectedSittingPlace)
      if (isDifferent) {
        this.state.filterDeltaSelectedSittingPlace.minValue = items.minValue
        this.state.filterDeltaSelectedSittingPlace.maxValue = items.maxValue
        this.triggerQuery()
      }
    },

    /**
     * @param {FilterDelta[]} items
     */
    onSelectedBatchesChange(items) {
      let isDifferent = JSON.stringify(items.map(a => this.id(a))) !== JSON.stringify(this.state.filterDeltaSelectedBatches.map(a => this.id(a)))
      if (isDifferent) {
        this.state.filterDeltaSelectedBatches = items[items.length - 1] ? [items[items.length - 1]] : []
        this.state.filterDeltaSelectedCategories = this.state.filterDeltaSelectedCategories.filter(a => !a.extra[FILTER_OPTION.IS_SET])
        this.triggerQuery()
      }
    },

    /**
     * @param {FilterDelta[]} items
     */
    onSelectedLumensChange(items) {
      let isDifferent = JSON.stringify(items.map(a => this.id(a))) !== JSON.stringify(this.state.filterDeltaSelectedLumens.map(a => this.id(a)))
      if (isDifferent) {
        this.state.filterDeltaSelectedLumens = items[items.length - 1] ? [items[items.length - 1]] : []
        this.triggerQuery()
      }
    },

    /**
     * @param {FilterDelta[]} items
     */
    onSelectedCollectionsChange(items) {
      let isDifferent = JSON.stringify(items.map(a => this.id(a))) !== JSON.stringify(this.state.filterDeltaSelectedCollections.map(a => this.id(a)))
      if (isDifferent) {
        this.state.filterDeltaSelectedCollections = items
        this.triggerQuery()
      }
    },

    /**
     * @param {FilterDelta[]} items
     */
    onSelectedShapesChange(items) {
      let isDifferent = JSON.stringify(items.map(a => this.id(a))) !== JSON.stringify(this.state.filterDeltaSelectedShapes.map(a => this.id(a)))
      if (isDifferent) {
        this.state.filterDeltaSelectedShapes = items
        this.triggerQuery()
      }
    },

    /**
     * @param {FilterDelta[]} items
     */
    onSelectedMaterialsChange(items) {
      let isDifferent = JSON.stringify(items.map(a => this.id(a))) !== JSON.stringify(this.state.filterDeltaSelectedMaterials.map(a => this.id(a)))
      if (isDifferent) {
        this.state.filterDeltaSelectedMaterials = items
        this.triggerQuery()
      }
    },

    triggerQuery() {
      this.$nextTick(() => {
        let query = this.generateQuery()
        console.log('QUERY OUT', query);
        this.state.link = this.generateLink(query)
      })
    },

    generateQuery() {

      // -----

      let query = nuxtQueriesToQueryObject(this.nuxtProp.url.queries)

      // -----

      let scopes = [
        FILTER_OPTION.NO_SCROLL,
        FILTER_OPTION.IS_SET,
        FILTER_OPTION.IS_BATCH,
        FILTER_OPTION.IS_BATCH_BIS,
        FILTER_OPTION.CATEGORY_IDS,
        FILTER_OPTION.COLLECTION_IDS,
        FILTER_OPTION.COLLECTION_TYPE_IDS, // on filter always reset
        FILTER_OPTION.SHAPE_IDS,
        FILTER_OPTION.CATEGORY_CHILDREN_IDS,
        FILTER_OPTION.IS_OUTLET,
        FILTER_OPTION.MIN_PRICE,
        FILTER_OPTION.MAX_PRICE,
        FILTER_OPTION.TAXED_PRICE,
        FILTER_OPTION.MIN_SITTING_PLACE,
        FILTER_OPTION.MAX_SITTING_PLACE,
        FILTER_OPTION.PAGE, // on filter always reset
        FILTER_OPTION.LUMEN_SEARCH,
        FILTER_OPTION.MIN_SIZE_LENGTH,
        FILTER_OPTION.MIN_SIZE_WIDTH,
        FILTER_OPTION.MIN_SIZE_HEIGHT,
        FILTER_OPTION.MAX_SIZE_LENGTH,
        FILTER_OPTION.MAX_SIZE_WIDTH,
        FILTER_OPTION.MAX_SIZE_HEIGHT,
        FILTER_OPTION.MATERIAL_IDS,
      ]

      // -----

      let previousQuery = Object.assign({}, query)

      // -----

      for (const scope of scopes) {
        delete query[scope]
      }

      // -----

      for (const filterDeltaSelectedCategory of this.state.filterDeltaSelectedCategories) {

        let attr = FILTER_OPTION.IS_SET
        let value = filterDeltaSelectedCategory.extra[attr]
        if (defined(value)) {
          if (filterDeltaSelectedCategory.extra[attr]) {
            query[attr] = value ? 1 : 0
          }
        }

        attr = FILTER_OPTION.CATEGORY_IDS
        value = filterDeltaSelectedCategory.extra[attr]
        if (defined(value)) {
          if (!query[attr]) query[attr] = ''
          let categoryIds = query[attr].split(',').filter(a => a).map(a => parseInt(a))
          if (!categoryIds.includes(value)) {
            categoryIds.push(value)
          }
          query[attr] = categoryIds.join(',')
        }
      }

      // -----

      for (const filterDeltaSelectedSubCategory of this.state.filterDeltaSelectedSubCategories) {

        let attr = FILTER_OPTION.CATEGORY_CHILDREN_IDS
        let value = filterDeltaSelectedSubCategory.extra[attr]
        if (defined(value)) {
          if (!query[attr]) query[attr] = ''
          let categoryIds = query[attr].split(',').filter(a => a).map(a => parseInt(a))
          if (!categoryIds.includes(value)) {
            categoryIds.push(value)
          }
          query[attr] = categoryIds.join(',')
        }

        attr = FILTER_OPTION.IS_OUTLET
        value = filterDeltaSelectedSubCategory.extra[attr]
        if (defined(value)) {
          if (filterDeltaSelectedSubCategory.extra[attr]) {
            query[attr] = value ? 1 : 0
          }
        }
      }

      // -----

      for (const filterDeltaSelectedBatch of this.state.filterDeltaSelectedBatches) {

        let attr = FILTER_OPTION.IS_SET
        let value = filterDeltaSelectedBatch.extra[attr]
        if (defined(value)) query[attr] = value ? 1 : 0

        attr = FILTER_OPTION.IS_BATCH_BIS
        value = filterDeltaSelectedBatch.extra[attr]
        if (defined(value)) query[attr] = value ? 1 : 0

        attr = FILTER_OPTION.IS_BATCH
        value = filterDeltaSelectedBatch.extra[attr]
        if (defined(value)) query[attr] = value ? 1 : 0
      }

      // -----

      if (defined(this.state.filterDeltaSelectedPrice.minValue) && this.state.filterDeltaSelectedPrice.min !== this.state.filterDeltaSelectedPrice.minValue) {
        query[FILTER_OPTION.MIN_PRICE] = this.state.filterDeltaSelectedPrice.minValue
      }
      if (defined(this.state.filterDeltaSelectedPrice.maxValue) && this.state.filterDeltaSelectedPrice.max !== this.state.filterDeltaSelectedPrice.maxValue) {
        query[FILTER_OPTION.MAX_PRICE] = this.state.filterDeltaSelectedPrice.maxValue
      }

      // -----

      if (defined(this.state.filterDeltaSelectedSittingPlace.minValue) && this.state.filterDeltaSelectedSittingPlace.min !== this.state.filterDeltaSelectedSittingPlace.minValue) {
        query[FILTER_OPTION.MIN_SITTING_PLACE] = this.state.filterDeltaSelectedSittingPlace.minValue
      }
      if (defined(this.state.filterDeltaSelectedSittingPlace.maxValue) && this.state.filterDeltaSelectedSittingPlace.max !== this.state.filterDeltaSelectedSittingPlace.maxValue) {
        query[FILTER_OPTION.MAX_SITTING_PLACE] = this.state.filterDeltaSelectedSittingPlace.maxValue
      }

      // -----

      for (const filterDeltaSelectedLumen of this.state.filterDeltaSelectedLumens) {
        let attr = FILTER_OPTION.LUMEN_SEARCH
        let value = filterDeltaSelectedLumen.extra[attr]
        if (defined(value)) query[attr] = value
      }

      // -----

      if (this.state.filterDeltaSelectedSize.minSize.length) {
        query[FILTER_OPTION.MIN_SIZE_LENGTH] = this.state.filterDeltaSelectedSize.minSize.length
      }
      if (this.state.filterDeltaSelectedSize.minSize.width) {
        query[FILTER_OPTION.MIN_SIZE_WIDTH] = this.state.filterDeltaSelectedSize.minSize.width
      }
      if (this.state.filterDeltaSelectedSize.minSize.height) {
        query[FILTER_OPTION.MIN_SIZE_HEIGHT] = this.state.filterDeltaSelectedSize.minSize.height
      }
      if (this.state.filterDeltaSelectedSize.maxSize.length) {
        query[FILTER_OPTION.MAX_SIZE_LENGTH] = this.state.filterDeltaSelectedSize.maxSize.length
      }
      if (this.state.filterDeltaSelectedSize.maxSize.width) {
        query[FILTER_OPTION.MAX_SIZE_WIDTH] = this.state.filterDeltaSelectedSize.maxSize.width
      }
      if (this.state.filterDeltaSelectedSize.maxSize.height) {
        query[FILTER_OPTION.MAX_SIZE_HEIGHT] = this.state.filterDeltaSelectedSize.maxSize.height
      }

      // -----

      for (const filterDelta of this.state.filterDeltaSelectedShapes) {
        let attr = FILTER_OPTION.SHAPE_IDS
        let value = filterDelta.extra[attr]
        if (defined(value)) {
          if (!query[attr]) query[attr] = ''
          let ids = query[attr].split(',').filter(a => a).map(a => parseInt(a))
          if (!ids.includes(value)) {
            ids.push(value)
          }
          query[attr] = ids.join(',')
        }
      }

      // -----

      for (const filterDelta of this.state.filterDeltaSelectedMaterials) {
        let attr = FILTER_OPTION.MATERIAL_IDS
        let value = filterDelta.extra[attr]
        if (defined(value)) {
          if (!query[attr]) query[attr] = ''
          let ids = query[attr].split(',').filter(a => a).map(a => parseInt(a))
          if (!ids.includes(value)) {
            ids.push(value)
          }
          query[attr] = ids.join(',')
        }
      }

      // -----

      for (const filterDelta of this.state.filterDeltaSelectedCollections) {
        let attr = FILTER_OPTION.COLLECTION_IDS
        let value = filterDelta.extra[attr]
        if (defined(value)) {
          if (!query[attr]) query[attr] = ''
          let ids = query[attr].split(',').filter(a => a).map(a => parseInt(a))
          if (!ids.includes(value)) {
            ids.push(value)
          }
          query[attr] = ids.join(',')
        }
      }

      // -----
      // if we click on some filter we destroy the search filter
      // -----

      let destroySearchOn = [
        FILTER_OPTION.CATEGORY_IDS,
        FILTER_OPTION.CATEGORY_CHILDREN_IDS,
      ]

      if (Object.keys(query).some(a => destroySearchOn.includes(a))) {
        delete query[FILTER_OPTION.SEARCH]
      }

      // -----
      // we create a change map
      // -----

      let hasChange = {}
      for (const scope of scopes) {
        hasChange[scope] = query[scope] !== previousQuery[scope]
      }

      // -----
      // sometime we dont wanna scroll top on every change query
      // -----

      let noScrolls = [
        FILTER_OPTION.MIN_PRICE,
        FILTER_OPTION.MAX_PRICE,
        FILTER_OPTION.MIN_SITTING_PLACE,
        FILTER_OPTION.MAX_SITTING_PLACE,
        FILTER_OPTION.MIN_SIZE_LENGTH,
        FILTER_OPTION.MIN_SIZE_WIDTH,
        FILTER_OPTION.MIN_SIZE_HEIGHT,
        FILTER_OPTION.MAX_SIZE_LENGTH,
        FILTER_OPTION.MAX_SIZE_WIDTH,
        FILTER_OPTION.MAX_SIZE_HEIGHT,
      ]
      let shouldScroll = false
      for (const scope of scopes) {
        if (noScrolls.includes(scope) && hasChange[scope]) {
          shouldScroll = true
        }
      }
      if (shouldScroll) {
        query[FILTER_OPTION.NO_SCROLL] = 1
      }

      // -----
      // if we need price with taxe
      // -----

      let withTaxes = [
        FILTER_OPTION.MIN_PRICE,
        FILTER_OPTION.MAX_PRICE,
      ]
      if (Object.keys(query).some(a => withTaxes.includes(a))) {
        query[FILTER_OPTION.TAXED_PRICE] = 1
      } else {
        delete query[FILTER_OPTION.TAXED_PRICE]
      }

      // -----
      // for parameters in paths and not queries we need to reset to catalogue url
      // -----

      // -----

      return query
    },

    /**
     * @param {Category} category
     */
    onFilterCategory(category) {
      return category.id !== CATEGORY.DOUCHE
    },

    id(item) {
      return JSON.stringify(item.extra)
    },

    /**
     * @param {FilterDelta} filterDelta
     */
    getSvgShape(filterDelta) {
      let map = {
        [SHAPE.TRIANGULAIRE]: this.icon.triangle,
        [SHAPE.RECTANGULAIRE]: this.icon.rectangle,
        [SHAPE.RONDE]: this.icon.ronde,
        [SHAPE.CARRÉE]: this.icon.carre,
        [SHAPE.OVALE]: this.icon.ovale,
      }
      return map[filterDelta.extra[FILTER_OPTION.SHAPE_IDS]]
    },

    /**
     * @param {Category[]} categories
     */
    onCancelCategory(categories) {
      if (categories.length === 0) {
        let isDifferent = JSON.stringify(categories.map(a => this.id(a))) !== JSON.stringify(this.state.filterDeltaSelectedCategories.map(a => this.id(a)))
        if (isDifferent) {
          this.state.filterDeltaSelectedCategories = []
          this.triggerSubCategories()
          this.triggerQuery()
        }
      }
    }
  }
}
</script>
