"use strict";
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (_) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.setupCache = void 0;
var stateService_1 = require("../service/stateService");
var Lang_1 = require("../../@common/delta/database/Lang");
var LANG_1 = require("../../@common/constant/LANG");
var getLangByNuxtProp_1 = require("../function/getLangByNuxtProp");
var CACHE_1 = require("../../@common/constant/CACHE");
var seoService_1 = require("../service/seoService");
var pageService_1 = require("../../@common/service/pageService");
var getCacheConfig_1 = require("./getCacheConfig");
var getPageMap_1 = require("./getPageMap");
var convertToPages_1 = require("./convertToPages");
var mapPageToDefaultLang_1 = require("./mapPageToDefaultLang");
var getBrowserMap_1 = require("./getBrowserMap");
var convertToBrowers_1 = require("./convertToBrowers");
var mapBrowserToDefaultLang_1 = require("./mapBrowserToDefaultLang");
function setupCache(config) {
    return __awaiter(this, void 0, Promise, function () {
        var remoteCaches, localCaches, langs, langIso6391, _i, langs_1, lang, pageHasChange, langHasChange, cacheHasChange, asyncPage, asyncLang, _a, remoteCaches_1, remoteCache, exist, _b, localCaches_1, localCache, exist, _c, localCaches_2, localCache, exist, _d, localCaches_3, localCache, exist, _e, localCaches_4, localCache;
        var _f, _g;
        var _this = this;
        return __generator(this, function (_h) {
            switch (_h.label) {
                case 0: return [4 /*yield*/, getCacheConfig_1.getCacheConfig()];
                case 1:
                    remoteCaches = _h.sent();
                    localCaches = stateService_1.stateService.get(stateService_1.STATE_CACHES);
                    langs = [
                        new Lang_1.Lang({
                            id: LANG_1.LANG.FR,
                            name: 'Français',
                            iso6391: LANG_1.LANG_ISO.FR,
                        }),
                        new Lang_1.Lang({
                            id: LANG_1.LANG.EN,
                            name: 'English',
                            iso6391: LANG_1.LANG_ISO.EN,
                        })
                    ];
                    config.langServiceBrowser.langs = langs;
                    config.langServiceBrowser.lang = langs[0];
                    langIso6391 = getLangByNuxtProp_1.getLangByNuxtProp(config.nuxtProp);
                    for (_i = 0, langs_1 = langs; _i < langs_1.length; _i++) {
                        lang = langs_1[_i];
                        if (lang.iso6391 === langIso6391) {
                            config.langServiceBrowser.lang = lang;
                            break;
                        }
                    }
                    pageHasChange = (_f = {},
                        _f[LANG_1.LANG.FR] = false,
                        _f[LANG_1.LANG.EN] = false,
                        _f);
                    langHasChange = (_g = {},
                        _g[LANG_1.LANG.FR] = false,
                        _g[LANG_1.LANG.EN] = false,
                        _g);
                    cacheHasChange = function (CACHE_kEY) {
                        var remoteCache = remoteCaches.find(function (remoteCache) { return remoteCache.id === CACHE_kEY; });
                        var localCache = localCaches.find(function (remoteCache) { return remoteCache.id === CACHE_kEY; });
                        var hasChange = true;
                        if (remoteCache && localCache) {
                            hasChange = remoteCache.key !== localCache.key;
                        }
                        return hasChange;
                    };
                    asyncPage = function () { return __awaiter(_this, void 0, void 0, function () {
                        var a1, CACHE_kEY, STATE_kEY, pages, map, res, hasChange, langIsNotDefault, otherLangs, _i, otherLangs_1, otherLang, map_1;
                        var _a, _b;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    a1 = new Date().getTime();
                                    CACHE_kEY = (_a = {},
                                        _a[LANG_1.LANG.FR] = CACHE_1.CACHE.FR_PAGES,
                                        _a[LANG_1.LANG.EN] = CACHE_1.CACHE.EN_PAGES,
                                        _a);
                                    STATE_kEY = (_b = {},
                                        _b[LANG_1.LANG.FR] = stateService_1.STATE_CACHE_PAGE_FR,
                                        _b[LANG_1.LANG.EN] = stateService_1.STATE_CACHE_PAGE_EN,
                                        _b);
                                    pages = [];
                                    if (!(cacheHasChange(CACHE_1.CACHE.FR_PAGES) || seoService_1.seoService.isActive)) return [3 /*break*/, 2];
                                    console.log("[log] remotes pages infos has changed for lang " + langs[0].name);
                                    return [4 /*yield*/, getPageMap_1.getPageMap(langs[0])];
                                case 1:
                                    map = _c.sent();
                                    stateService_1.stateService.set(stateService_1.STATE_CACHE_PAGE_FR, map);
                                    return [3 /*break*/, 3];
                                case 2:
                                    console.log("[log] no change for pages infos, we use the cache for lang " + langs[0].name);
                                    map = stateService_1.stateService.get(stateService_1.STATE_CACHE_PAGE_FR);
                                    _c.label = 3;
                                case 3:
                                    res = convertToPages_1.convertToPages(map, langs[0]);
                                    pages = res.pages;
                                    hasChange = cacheHasChange(CACHE_kEY[config.langServiceBrowser.lang.id]) || seoService_1.seoService.isActive;
                                    langIsNotDefault = langIso6391 !== langs[0].iso6391;
                                    otherLangs = [];
                                    // push the current lang
                                    if (langIsNotDefault)
                                        otherLangs.push(config.langServiceBrowser.lang);
                                    // push all the langs
                                    if (seoService_1.seoService.isActive)
                                        otherLangs = langs.filter(function (lang) { return lang.id !== LANG_1.LANG.FR; }).map(function (lang) { return new Lang_1.Lang(lang); });
                                    _i = 0, otherLangs_1 = otherLangs;
                                    _c.label = 4;
                                case 4:
                                    if (!(_i < otherLangs_1.length)) return [3 /*break*/, 9];
                                    otherLang = otherLangs_1[_i];
                                    map_1 = void 0;
                                    if (!hasChange) return [3 /*break*/, 6];
                                    console.log("[log] remotes pages infos has changed for lang " + otherLang.name);
                                    return [4 /*yield*/, getPageMap_1.getPageMap(otherLang)];
                                case 5:
                                    map_1 = _c.sent();
                                    stateService_1.stateService.set(STATE_kEY[otherLang.id], map_1);
                                    return [3 /*break*/, 7];
                                case 6:
                                    console.log("[log] no change for pages infos, we use the cache for lang " + otherLang.name);
                                    map_1 = stateService_1.stateService.get(STATE_kEY[otherLang.id]);
                                    _c.label = 7;
                                case 7:
                                    pages = mapPageToDefaultLang_1.mapPageToDefaultLang(pages, map_1, otherLang);
                                    _c.label = 8;
                                case 8:
                                    _i++;
                                    return [3 /*break*/, 4];
                                case 9:
                                    pageService_1.pageService.setPages(pages);
                                    pageHasChange[config.langServiceBrowser.lang.id] = hasChange;
                                    console.log('[log] time to get pages infos', new Date().getTime() - a1, 'ms');
                                    return [2 /*return*/];
                            }
                        });
                    }); };
                    asyncLang = function () { return __awaiter(_this, void 0, void 0, function () {
                        var a1, CACHE_kEY, STATE_kEY, browsers, map, res, hasChange, langIsNotDefault, otherLangs, _i, otherLangs_2, otherLang, map_2;
                        var _a, _b;
                        return __generator(this, function (_c) {
                            switch (_c.label) {
                                case 0:
                                    a1 = new Date().getTime();
                                    CACHE_kEY = (_a = {},
                                        _a[LANG_1.LANG.FR] = CACHE_1.CACHE.FR_BROWSERS,
                                        _a[LANG_1.LANG.EN] = CACHE_1.CACHE.EN_BROWSERS,
                                        _a);
                                    STATE_kEY = (_b = {},
                                        _b[LANG_1.LANG.FR] = stateService_1.STATE_CACHE_BROWSER_FR,
                                        _b[LANG_1.LANG.EN] = stateService_1.STATE_CACHE_BROWSER_EN,
                                        _b);
                                    browsers = [];
                                    if (!(cacheHasChange(CACHE_1.CACHE.FR_BROWSERS) || seoService_1.seoService.isActive)) return [3 /*break*/, 2];
                                    console.log("[log] remotes traductions infos has changed for lang " + langs[0].name);
                                    return [4 /*yield*/, getBrowserMap_1.getBrowserMap(langs[0])];
                                case 1:
                                    map = _c.sent();
                                    stateService_1.stateService.set(stateService_1.STATE_CACHE_BROWSER_FR, map);
                                    return [3 /*break*/, 3];
                                case 2:
                                    console.log("[log] no change for traductions infos, we use the cache for lang " + langs[0].name);
                                    map = stateService_1.stateService.get(stateService_1.STATE_CACHE_BROWSER_FR);
                                    _c.label = 3;
                                case 3:
                                    res = convertToBrowers_1.convertToBrowers(map, langs[0]);
                                    browsers = res.browsers;
                                    hasChange = cacheHasChange(CACHE_kEY[config.langServiceBrowser.lang.id]) || seoService_1.seoService.isActive;
                                    langIsNotDefault = langIso6391 !== langs[0].iso6391;
                                    otherLangs = [];
                                    // push the current lang
                                    if (langIsNotDefault)
                                        otherLangs.push(config.langServiceBrowser.lang);
                                    // push all the langs
                                    if (seoService_1.seoService.isActive)
                                        otherLangs = langs.filter(function (lang) { return lang.id !== LANG_1.LANG.FR; }).map(function (lang) { return new Lang_1.Lang(lang); });
                                    _i = 0, otherLangs_2 = otherLangs;
                                    _c.label = 4;
                                case 4:
                                    if (!(_i < otherLangs_2.length)) return [3 /*break*/, 9];
                                    otherLang = otherLangs_2[_i];
                                    map_2 = void 0;
                                    if (!hasChange) return [3 /*break*/, 6];
                                    console.log("[log] remotes traductions infos has changed for lang " + otherLang.name);
                                    return [4 /*yield*/, getBrowserMap_1.getBrowserMap(otherLang)];
                                case 5:
                                    map_2 = _c.sent();
                                    stateService_1.stateService.set(STATE_kEY[otherLang.id], map_2);
                                    return [3 /*break*/, 7];
                                case 6:
                                    console.log("[log] no change for traductions infos, we use the cache for lang " + otherLang.name);
                                    map_2 = stateService_1.stateService.get(STATE_kEY[otherLang.id]);
                                    _c.label = 7;
                                case 7:
                                    browsers = mapBrowserToDefaultLang_1.mapBrowserToDefaultLang(browsers, res.browserIds, map_2, otherLang);
                                    _c.label = 8;
                                case 8:
                                    _i++;
                                    return [3 /*break*/, 4];
                                case 9:
                                    config.langServiceBrowser.browsers = browsers;
                                    langHasChange[config.langServiceBrowser.lang.id] = hasChange;
                                    console.log('[log] time to get traductions infos', new Date().getTime() - a1, 'ms');
                                    return [2 /*return*/];
                            }
                        });
                    }); };
                    // -----
                    return [4 /*yield*/, Promise.all([
                            asyncPage(),
                            asyncLang()
                        ])
                        // -----
                    ];
                case 2:
                    // -----
                    _h.sent();
                    // -----
                    for (_a = 0, remoteCaches_1 = remoteCaches; _a < remoteCaches_1.length; _a++) {
                        remoteCache = remoteCaches_1[_a];
                        if (pageHasChange[LANG_1.LANG.FR] && remoteCache.id === CACHE_1.CACHE.FR_PAGES) {
                            exist = false;
                            for (_b = 0, localCaches_1 = localCaches; _b < localCaches_1.length; _b++) {
                                localCache = localCaches_1[_b];
                                if (localCache.id === remoteCache.id) {
                                    localCache.key = remoteCache.key;
                                    exist = true;
                                }
                            }
                            if (!exist)
                                localCaches.push(remoteCache);
                        }
                        if (pageHasChange[LANG_1.LANG.EN] && remoteCache.id === CACHE_1.CACHE.EN_PAGES) {
                            exist = false;
                            for (_c = 0, localCaches_2 = localCaches; _c < localCaches_2.length; _c++) {
                                localCache = localCaches_2[_c];
                                if (localCache.id === remoteCache.id) {
                                    localCache.key = remoteCache.key;
                                    exist = true;
                                }
                            }
                            if (!exist)
                                localCaches.push(remoteCache);
                        }
                        if (langHasChange[LANG_1.LANG.FR] && remoteCache.id === CACHE_1.CACHE.FR_BROWSERS) {
                            exist = false;
                            for (_d = 0, localCaches_3 = localCaches; _d < localCaches_3.length; _d++) {
                                localCache = localCaches_3[_d];
                                if (localCache.id === remoteCache.id) {
                                    localCache.key = remoteCache.key;
                                    exist = true;
                                }
                            }
                            if (!exist)
                                localCaches.push(remoteCache);
                        }
                        if (langHasChange[LANG_1.LANG.EN] && remoteCache.id === CACHE_1.CACHE.EN_BROWSERS) {
                            exist = false;
                            for (_e = 0, localCaches_4 = localCaches; _e < localCaches_4.length; _e++) {
                                localCache = localCaches_4[_e];
                                if (localCache.id === remoteCache.id) {
                                    localCache.key = remoteCache.key;
                                    exist = true;
                                }
                            }
                            if (!exist)
                                localCaches.push(remoteCache);
                        }
                    }
                    return [2 /*return*/, config.langServiceBrowser];
            }
        });
    });
}
exports.setupCache = setupCache;
