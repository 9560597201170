<style scoped lang="scss">

@import "../../scss/color";

.AdminContextHighLight {

}

.AdminContextHighLight--loader {
  height: 100px;
  padding-bottom: 50px;
}

.AdminContextHighLight ::v-deep .InputSection--header {
  margin-top: 0;
}

.AdminContextHighLight ::v-deep .InputSection--slot {
  align-items: flex-start;
  padding-left: 50px;
  margin-bottom: 50px;
}

.AdminContextHighLight ::v-deep .InputSection--remove {
  margin-left: 25px;
  margin-top: 34px;
}

.AdminContextHighLight--tabs {

}

.AdminContextHighLight--tabHeaders {
  display: flex;
  align-items: center;
  height: 50px;
  margin-bottom: 50px;
}

.AdminContextHighLight--tabHeader {
  cursor: pointer;
  height: 100%;
  flex: 1;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: 300ms;
  font-family: 'CommutersSansBold', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  background: rgba(0, 0, 0, 0.03);
  border-bottom: solid 1px white;
}

.AdminContextHighLight--tabHeader-selected {
  background: rgba(0, 0, 0, 0.06);
}

.AdminContextHighLight--tabHeader:hover {
  border-bottom: solid 1px black;
}

.AdminContextHighLight--tabBody {

}

/**/

.AdminContextHighLight--empty {
  border: dashed 2px rgba(0, 0, 0, 0.15);
  height: 200px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 50px;
}

.AdminContextHighLight--save {
}

</style>

<template>
  <div class="AdminContextHighLight" :class="class_root">
    <div class="AdminContextHighLight--loader" v-if="state.loadingSelect">
      <LoaderSpace></LoaderSpace>
    </div>
    <InputSection :svg="icon.view_selection"
                  :term="lang.term(617)"
                  :multiple="true"
                  :cloned="true"
                  :addable="true"
                  :items="state.selections"
                  :onFilter="(v, i) => onFilter(v, i)"
                  :onClickAdd="() => add()"
                  :onClickRemove="i => remove(i)">
      <template slot="header">
        <div class="AdminContextHighLight--tabHeaders">
          <div class="AdminContextHighLight--tabHeader" :class="class_tabHeader(0)" @click="changTab(0)">
            <Term :term="lang.term(618)"></Term>
          </div>
          <div class="AdminContextHighLight--tabHeader" :class="class_tabHeader(1)" @click="changTab(1)">
            <Term :term="lang.term(619)"></Term>
          </div>
          <div class="AdminContextHighLight--tabHeader" :class="class_tabHeader(2)" @click="changTab(2)">
            <Term :term="lang.term(620)"></Term>
          </div>
        </div>
      </template>
      <template v-slot:default="{item}">
        <div class="AdminContextHighLight--tabBody">
          <InputSelectTerm :remotable="true"
                           :cloned="true"
                           :onRemoteChange="selectArticles"
                           :label="lang.term(621)"
                           :items="state.articles.length ? state.articles : item.articles"
                           :values="item.articles"
                           :onChangeValues="v => item.articles = v"
                           :termAttribute="v => getArticleTermNames(v)"
                           :loading="state.loadingSelectArticle">
          </InputSelectTerm>
          <br>
          <br>

          <InputSelect :cloned="true"
                       :label="lang.term(622)"
                       :items="state.shopTypeLists"
                       :values="item.shopTypeLists"
                       :onChangeValues="v => item.shopTypeLists = v"
                       :loading="state.loadingSelectShopTypeList">
          </InputSelect>
          <br>
          <br>

          <InputLabel :term="lang.term(623)"></InputLabel>
          <br>
          <template v-for="sort in item.sorts">
            <el-input-number @change="v => sort.order = v"
                             :value="sort.order">
            </el-input-number>
          </template>
          <br>
          <br>


          <InputLabel :term="lang.term(624)"></InputLabel>
          <br>
          <InputSwitch :value="item.isNightMode" :onChangeValue="v => item.isNightMode = v"></InputSwitch>
        </div>
      </template>
    </InputSection>
    <div class="AdminContextHighLight--empty"
         v-if="!state.selections.filter((v, i) => onFilter(v, i)).length && !state.loadingSelect">
      <Term :term="lang.term(625)"></Term>
    </div>
    <div class="AdminContextHighLight--save">
      <ButtonAction :term="lang.term(207)" :onClick="() => save()" :loading="state.loadingCreate"></ButtonAction>
    </div>
  </div>
</template>

<script>

import {eventService} from '../../service/eventService'
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser.ts";
import {apiClient} from "../../service/apiClientService";

import selectSelection from "../../../@common/api/selection/selectSelection";
import selectShopTypeList from "../../../@common/api/shop_type_list/selectShopTypeList";
import selectArticles from "../../../@common/api/article/selectArticles";
import createSelection from "../../../@common/api/selection/createSelection";

import {sendError} from "../../function/sendError";
import {displayMessage} from "../../function/displayMessage";

import {COLOR} from "../../../@common/constant/COLOR";

import {Selection} from "../../../@common/delta/database/Selection";
import {Sort} from "../../../@common/delta/database/Sort";
import {Debounce} from "../../../@common/ts/Debounce";
import {getArticleTermNames} from "../../../@nodejs/function/getArticleTermNames";
import {ARTICLE_SEARCH_ID} from "../../../@common/constant/ARTICLE";
import {ShopTypeList} from "../../../@common/delta/database/ShopTypeList";

// -----

const debounce = new Debounce()

// -----

export default {
  data() {
    return {
      COLOR,
      lang: getLangServiceBrowser(),
      icon: {
        view_selection: require('@bbx/vector~master/core/assets/svg/material/shopping_basket.svg'),
      },
      state: {
        /**
         * @type {number}
         */
        tabId: 0,
        /**
         * @type {boolean}
         */
        loadingSelect: false,
        /**
         * @type {boolean}
         */
        loadingSelectArticle: false,
        /**
         * @type {boolean}
         */
        loadingSelectShopTypeList: false,
        /**
         * @type {boolean}
         */
        loadingCreate: false,
        /**
         * @type {Selection[]}
         */
        selections: [],
        /**
         * @type {ShopTypeList[]}
         */
        shopTypeLists: [],
        /**
         * @type {Article[]}
         */
        articles: [],
      },
      events: []
    }
  },
  computed: {
    class_root() {
      const classes = [];
      return classes;
    }
  },
  beforeMount() {

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
    this.init().catch(sendError)
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    async init() {
      await Promise.all([
        this.select(),
        this.selectShopTypeList(),
      ])
    },
    async select() {
      this.state.loadingSelect = true
      const {data} = await apiClient.do(selectSelection, selectSelection.with({
        active: true,
        $sort: 'ask'
      }))
      this.state.selections = data.map(a => this.defaultSelection(a))
      this.state.loadingSelect = false
    },
    async selectShopTypeList() {
      this.state.loadingSelectShopTypeList = true
      const {data} = await apiClient.do(selectShopTypeList, selectShopTypeList.with({
        active: true,
      }))
      this.state.shopTypeLists = data;
      this.state.loadingSelectShopTypeList = false
    },

    selectArticles(query) {
      this.state.loadingSelectArticle = true

      if (!query) {
        this.state.loadingSelectArticle = false
        this.state.articles = []
        return
      }

      debounce.callback({
        job: async () => {
          return apiClient.do(selectArticles, selectArticles.with({
            $scope: 3,
            active: true,
            visible: true,
            $search: query,
            $searchType: ARTICLE_SEARCH_ID.REFERENCE
          }))
        },
        success: res => {
          this.state.articles = res.data
          this.state.loadingSelectArticle = false
        },
        error: err => {
          this.state.loadingSelectArticle = false
          sendError(err);
        }
      })
    },
    /**
     *
     * @param {Selection} selection
     * @returns {Selection}
     */
    defaultSelection(selection = new Selection()) {
      if (!selection.sorts.length) selection.sorts.push(new Sort({
        order: 1
      }))
      return selection
    },

    add() {
      this.state.selections.push(this.defaultSelection(new Selection({
        shopTypeLists: this.state.tabId ? [new ShopTypeList({
          id: this.state.tabId
        })] : []
      })))
    },

    /**
     *
     * @param {Selection} selection
     * @param {number} selectionIndex
     */
    onFilter(selection, selectionIndex) {
      return selection.active && (selection.shopTypeLists.map(a => a.id).pop() || 0) === this.state.tabId
    },
    /**
     *
     * @param {number} index
     */
    remove(index) {
      /** @type {Selection} */
      let selection = this.state.selections[index]
      if (selection.id) selection.active = false
      else this.state.selections.splice(index, 1)
    },

    async save() {
      this.state.loadingCreate = true
      try {
        await Promise.all(this.state.selections.map(async (selection) => {
          await apiClient.do(createSelection, createSelection.with(selection))
        }))
        displayMessage({
          vue: this,
          type: 'success',
          message: this.lang.translate(626)
        })
        await this.select()
      } catch (err) {
        sendError(err)
      }
      this.state.loadingCreate = false
    },
    /**
     * @param {Article} article
     * @returns {Term[]}
     */
    getArticleTermNames(article) {
      return getArticleTermNames(article)
    },
    /**
     * @param {number} tabId
     * @returns {}
     */
    changTab(tabId) {
      return this.state.tabId = tabId
    },
    /**
     * @param {number} tabId
     * @returns {}
     */
    class_tabHeader(tabId) {
      const classes = [];
      if (tabId === this.state.tabId) classes.push('AdminContextHighLight--tabHeader-selected')
      return classes;
    },
  }
}
</script>
