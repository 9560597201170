import Vue from 'vue'
import VueI18n from 'vue-i18n'
import Element from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css';
import '../scss/element-ui.scss'
import fr from 'element-ui/lib/locale/lang/fr'
import en from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale';

import {ListenerRegister} from '@bbx/listener~master/core/delta/ListenerRegister'
import {eventService} from '../../../../core/@browser/service/eventService'
import {LANG_ISO} from "../../../../core/@common/constant/LANG";
import {EVENT} from "../../../../core/@common/constant/EVENT";

eventService.register(new ListenerRegister({
  name: EVENT.CHANGE_LANG,
  callback: (langCode) => {
    if (langCode === LANG_ISO.FR) locale.use(fr);
    if (langCode === LANG_ISO.EN) locale.use(en);
  }
}))

locale.use(fr);

Vue.use(VueI18n)
Vue.use(Element)


