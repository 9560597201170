"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Paypal = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Payment_1 = require("./Payment");
var Paypal = /** @class */ (function () {
    function Paypal(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.version = deltaService_1.deltaService.number(props.version, 0);
        this.infoJson = deltaService_1.deltaService.string(props.infoJson, '');
        this.payments = deltaService_1.deltaService.array(props.payments, Payment_1.Payment);
    }
    return Paypal;
}());
exports.Paypal = Paypal;
