"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Basket = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Order_1 = require("./Order");
var Article_1 = require("./Article");
var Basket = /** @class */ (function () {
    function Basket(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.quantity = deltaService_1.deltaService.number(props.quantity, 0);
        this.articles = deltaService_1.deltaService.array(props.articles, Article_1.Article);
        this.orders = deltaService_1.deltaService.array(props.orders, Order_1.Order);
    }
    return Basket;
}());
exports.Basket = Basket;
