<style scoped>
.PurchaseDelivery {
}

.PurchaseDelivery h2 {
  font-family: 'AmbroiseRegular', sans-serif;
}

.PurchaseDelivery ::v-deep .InputNumber {
  flex: 1;
}

.PurchaseDelivery--row {
  display: flex;
}

.PurchaseDelivery--space {
  width: 30px;
  min-width: 30px;
}

.PurchaseDelivery--row ::v-deep .InputText {
  flex: 1;
}

.PurchaseDelivery--transportCost {
  border: solid 1px rgba(0, 0, 0, 0.1);
  border-radius: 4px;
  padding: 15px 30px;
  display: flex;
  align-items: center;
}

.PurchaseDelivery--transportCostIcon {
  width: 38px;
  height: 38px;
}

.PurchaseDelivery--transportCostText {
  font-family: 'DDinRegular', sans-serif;
  padding-left: 15px;
  font-size: 20px;
}

.PurchaseDelivery--transportCostText-free {
  color: rgba(0, 0, 0, 0.5);
  text-transform: uppercase;
  letter-spacing: 1px;
  font-family: 'RalewayBold', sans-serif;
  font-size: 12px;
}

</style>

<template>
  <div class="PurchaseDelivery">
    <h2>
      <Term :term="lang.term(379)"/>
    </h2>


    <div class="PurchaseDelivery--registered" v-show="state.roleId === ROLE.SELLER">
      <br>
      <br>
      <InputSelect :items="state.shops"
                   :loading="state.loadingShop"
                   :display="displayShop"
                   :mandatory="true"
                   :label="lang.term(583)"
                   :onChangeValues="v => onChangeRegisteredShop(v)"
                   :values="state.order.shops"
                   :error="form.display('order.shops')">
      </InputSelect>
    </div>

    <!--    -->

    <div class="PurchaseDelivery--registered" v-show="state.addresses.length && state.roleId !== ROLE.SELLER">
      <br>
      <br>
      <template v-for="delivery in state.order.deliveries">
        <InputSelect :items="state.addresses"
                     :loading="state.loadingAddress"
                     :display="displayAddress"
                     :label="lang.term(352)"
                     :onChangeValues="v => onChangeRegisteredValues(v)"
                     :values="state.addressesSelected">
        </InputSelect>
      </template>
    </div>

    <!--    -->

    <template v-if="displayError(form.display(`order`)).id">
      <br>
      <InputError :term="displayError(form.display(`order`))"></InputError>
    </template>

    <!--    -->

    <div class="PurchaseDelivery--newOne">
      <template v-for="delivery in state.order.deliveries">
        <template v-for="address in delivery.addresses">
          <AddressForm prefixError="order.deliveries.addresses."
                       :address="address"
                       :onAddressChange="v => address = v"
                       :form="form"
                       :input-hiddens="['country', 'favorite', 'name', 'country', 'geographicalPositions']">
          </AddressForm>
        </template>
      </template>
    </div>

    <!--    -->
    <InputSection :svg="icon.person" :term="lang.term(185)">
      <div class="PurchaseDelivery--row">
        <template v-for="delivery in state.order.deliveries">
          <InputText :value="delivery.contactPhone"
                     :mandatory="true"
                     :onChangeValue="v => delivery.contactPhone = v"
                     :label="lang.term(184)"
                     :error="form.display('order.deliveries.contactPhone')">
          </InputText>
          <div class="PurchaseDelivery--space"></div>
          <InputText :value="delivery.contactName"
                     :onChangeValue="v => delivery.contactName = v"
                     :label="lang.term(422)"
                     :error="form.display(`order.deliveries.contactName`)">
          </InputText>
        </template>
      </div>
      <br>
      <InputText type="textarea"
                 :value="state.order.detail"
                 :onChangeValue="v => state.order.detail = v"
                 :label="lang.term(421)"
                 :error="form.display(`order.detail`)"
                 :rows="4">
      </InputText>
    </InputSection>

    <!--    -->

    <br>
    <br>
    <InputSwitch :value="state.order.sameFundingAddress"
                 :onChangeValue="v => onChangeValueSameAddress(v)"
                 :label="lang.term(186)">
    </InputSwitch>

    <!--    -->

    <div v-show="!state.order.sameFundingAddress" class="PurchaseDelivery--notTheSame">
      <br>
      <Divider></Divider>
      <br>
      <h2>
        <Term :term="lang.term(380)"/>
      </h2>

      <div class="PurchaseDelivery--registered" v-show="state.addresses.length && state.roleId !== ROLE.SELLER">
        <br>
        <br>
        <template v-for="delivery in state.order.deliveries">
          <InputSelect :items="state.addresses"
                       :loading="state.loadingAddress"
                       :display="displayAddress"
                       :label="lang.term(352)"
                       :onChangeValues="v => onChangeRegisteredBillValues(v)"
                       :values="state.addressesBillSelected">
          </InputSelect>
        </template>
      </div>


      <template v-for="funding in state.order.fundings">
        <AddressForm v-for="(address, index_address) in funding.addresses" :key="index_address"
                     prefixError="order.fundings.addresses."
                     :address="address"
                     :form="form"
                     :input-hiddens="['country', 'favorite', 'name', 'country', 'geographicalPositions']"
                     :onAddressChange="v => address = v">
        </AddressForm>
      </template>
    </div>

    <!--    -->

    <div class="PurchaseDelivery--transport" v-show="canDisplayTransportCost">
      <br>
      <Divider></Divider>
      <br>
      <h2>
        <Term :term="lang.term(381)"/>
      </h2>
      <br>
      <br>
      <div class="PurchaseDelivery--transportCost">
        <div class="PurchaseDelivery--transportCostIcon">
          <Vector :svg="icon.local_shipping"></Vector>
        </div>
        <div class="PurchaseDelivery--transportCostText" v-show="getBasketTotal.transportCostTTC">
          {{ formatPrice(getBasketTotal.transportCostTTC) }} €
        </div>
        <div class="PurchaseDelivery--transportCostText PurchaseDelivery--transportCostText-free" v-show="!getBasketTotal.transportCostTTC">
          <Term :term="lang.term(382)"/>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";

import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {Order} from "../../../@common/delta/database/Order";
import {Street} from "../../../@common/delta/database/Street";
import {Address} from "../../../@common/delta/database/Address";
import {Funding} from "../../../@common/delta/database/Funding";
import {User} from "../../../@common/delta/database/User";
import {Country} from "../../../@common/delta/database/Country";

import {STATE_ROLE_ID, STATE_TOKEN, stateService} from "../../service/stateService";
import {apiClient} from "../../service/apiClientService";

import selectUsers from "../../../@common/api/user/selectUsers";

import {getFlatAddress} from "../../../@common/function/getFlatAddress";
import {calculateTTC} from "../../../@common/function/calculateTTC";
import {formatPrice} from "../../../@common/function/formatPrice";
import {canDisplayTransportCost} from "../../../@common/function/canDisplayTransportCost";
import {Term} from "../../../@common/delta/database/Term";
import {getBasketTotal} from "../../../@common/function/getBasketTotal";
import {ROLE} from "../../../@common/constant/ROLE";
import {COUNTRY_ID} from "../../../@common/constant/COUNTRY";
import {Shop} from "../../../@common/delta/database/Shop";
import {sendError} from "../../function/sendError";

export default {

  props: {
    order: {
      default: () => new Order()
    },
    transports: {
      default: () => ([])
    },
    onOrderChange: {
      type: Function,
      default: () => (order) => {
      }
    },
    loadingTransport: {
      type: Boolean,
      default: () => false
    },
    form: {
      default: () => new ApiResponseForm()
    }
  },
  data() {
    return {
      ROLE,
      lang: getLangServiceBrowser(),
      icon: {
        add: require('@bbx/vector~master/core/assets/svg/material/add.svg'),
        person: require('@bbx/vector~master/core/assets/svg/material/person.svg'),
        delete_forever: require('@bbx/vector~master/core/assets/svg/material/delete_forever.svg'),
        local_shipping: require('@bbx/vector~master/core/assets/svg/material/local_shipping.svg'),
      },
      props: {},
      state: {
        /**
         * @type Order
         **/
        order: this.order,
        /**
         * @type User
         **/
        user: new User(),
        /**
         * @type Tax[]
         **/
        countries: [],
        /**
         * @type Address
         */
        addressesSelected: [],
        /**
         * @type Address
         */
        addressesBillSelected: [],
        /**
         * @type Address[]
         */
        addresses: [],
        /**
         * @type Shop[]
         */
        shops: [],
        /**
         * @type boolean
         **/
        sameAddress: true,
        /**
         * @type boolean
         **/
        useOtherAddress: false,
        /**
         * @type string
         */
        token: '',
        /**
         * @type number
         */
        roleId: 0,
        /**
         * @type boolean
         */
        loadingAddress: false,
        /**
         * @type boolean
         */
        loadingShop: false,
      },
    }
  },
  watch: {
    'order': {
      handler(v) {
        this.state.order = v
      },
      deep: true
    },
    'state.order': {
      handler(v) {
        this.onOrderChange(v)
      },
      deep: true
    },
    'state.order.deliveries': {
      handler() {
        this.bindBillAddress()
      },
      deep: true
    },
  },
  computed: {
    canDisplayTransportCost() {
      return canDisplayTransportCost(this.state.order.deliveries)
    },
    getBasketTotal() {
      return getBasketTotal({
        order: this.state.order,
        transports: this.transports,
        roleId: this.state.roleId
      })
    }
  },
  beforeMount() {
  },
  mounted() {

    // -----

    this.state.token = stateService.get(STATE_TOKEN)
    stateService.watch(STATE_TOKEN, v => this.state.token = v)

    this.state.roleId = stateService.get(STATE_ROLE_ID)
    stateService.watch(STATE_ROLE_ID, v => this.state.roleId = v)

    // -----

    this.select().catch(sendError)
  },
  beforeDestroy() {
  },
  methods: {

    /**
     * * * * * * * * *
     *
     *    IMPORT
     *
     * * * * * * * * *
     */

    calculateTTC,
    formatPrice,

    /**
     * * * * * * * * *
     *
     *    SELECT
     *
     * * * * * * * * *
     */
    async select() {
      await Promise.all([
        this.selectAddresses(),
      ])
    },

    async selectAddresses() {
      this.state.loadingAddress = true

      // -----

      const {data} = await apiClient.do(selectUsers, selectUsers.with({
        $token: this.state.token
      }))

      // -----

      const user = new User(data[0])
      this.state.user = user

      // -----

      const addresses = this.getAddresses(user)
      this.state.addresses = addresses

      // -----

      this.state.order.shops = []
      this.state.shops = user.shops

      if (this.state.roleId === ROLE.SELLER) this.state.order.shops = [new Shop(user.shops[0])]

      // -----

      let defaultAddress = this.getFavoriteAddress(addresses)
      const contactNames = []
      if (user.firstName) contactNames.push(user.firstName)
      if (user.lastName) contactNames.push(user.lastName)
      const contactName = contactNames.join(' ')

      // -----

      let isEmpty = true

      for (const delivery of this.state.order.deliveries) {
        for (const address of delivery.addresses) {
          address = this.mapAddresses(address)
          isEmpty = this.addressIsEmpty(address)
        }
        if (!delivery.contactName) delivery.contactName = contactName
        if (!delivery.contactPhone) delivery.contactPhone = this.getPhoneNumber(user)
      }

      if (isEmpty) {
        for (const delivery of this.state.order.deliveries) {
          delivery.addresses = [this.cloneAddress(defaultAddress)]
        }
      }

      // -----

      let isEmptyBill = true

      this.checkDefaultFundingAddress()
      for (const funding of this.state.order.fundings) {
        for (const address of funding.addresses) {
          address = this.mapAddresses(address)
          isEmptyBill = this.addressIsEmpty(address)
        }
      }

      if (isEmptyBill) {
        this.checkDefaultFundingAddress()
        for (const funding of this.state.order.fundings) {
          funding.addresses = [this.cloneAddress(defaultAddress)]
        }
      }

      // -----

      this.state.loadingAddress = false
    },

    /**
     * @param {User} user
     * @return {Address[]}
     */
    getAddresses(user) {

      let roleId = 0
      let addresses = []

      for (const role of user.roles || []) {
        roleId = role.id
      }

      // -----

      if (roleId === ROLE.SELLER) {
        for (const shop of user.shops || []) {
          for (const address of shop.addresses || []) {
            address.name = shop.name
            address = this.mapAddresses(address)
            addresses.push(address)
          }
        }
      }

      // -----

      else {
        addresses = user.addresses || []
      }

      // -----

      return addresses
    },

    /**
     // this map should not exist, a migration should be made to have only one table
     // and a attribute who determine whitch country can be used
     * @param {Address} address
     * @return {Address}
     */
    mapAddresses(address) {
      if (!address.countries.length) {
        if (address.country.toLowerCase() === 'france') address.countries.push(new Country({id: COUNTRY_ID.FR}))
        else if (address.country.toLowerCase() === 'pays bas') address.countries.push(new Country({id: COUNTRY_ID.NL}))
        else if (address.country.toLowerCase() === 'belgique') address.countries.push(new Country({id: COUNTRY_ID.BE}))
        else if (address.country.toLowerCase() === 'luxembourg') address.countries.push(new Country({id: COUNTRY_ID.LU}))
        else if (address.country.toLowerCase() === 'suisse') address.countries.push(new Country({id: COUNTRY_ID.CH}))
      }
      return address
    },

    /**
     * @param {User} user
     * @return {string}
     */
    getPhoneNumber(user) {

      let phoneNumber = ''
      let roleId = 0

      for (const role of user.roles || []) {
        roleId = role.id
      }

      // -----

      if (roleId === ROLE.SELLER) {
        for (const shop of user.shops || []) {
          for (const {number} of shop.phoneNumbers) {
            phoneNumber = number
          }
        }
      }

      // -----

      else {
        phoneNumber = user.phoneNumber
      }

      // -----

      return phoneNumber
    },

    /**
     * * * * * * * * *
     *
     *    OTHER
     *
     * * * * * * * * *
     */

    /**
     *
     * @param {Address[]} addresses
     */
    getFavoriteAddress(addresses) {
      for (const address of addresses) {
        if (address.favorite) {
          return new Address(address)
        }
      }
      if (addresses[0]) return new Address(addresses[0])
      return new Address({
        streets: [
          new Street(),
          new Street(),
        ],
      })
    },

    bindBillAddress() {
      if (this.order.sameFundingAddress) {
        for (const delivery of this.state.order.deliveries) {
          this.checkDefaultFundingAddress()
          for (const funding of this.state.order.fundings) {
            funding.addresses = delivery.addresses.map(a => this.cloneAddress(a))
          }
        }
      }
    },
    /**
     *
     * @param {Address} address
     */
    displayAddress(address) {
      const t = []
      if (address.name) t.push(address.name.toUpperCase())
      t.push(getFlatAddress(address))
      return t.join(' - ')
    },
    /**
     *
     * @param {Shop} shop
     */
    displayShop(shop) {
      const t = []
      for (const address of shop.addresses) {
        if (shop.name) t.push(shop.name.toUpperCase())
        t.push(getFlatAddress(address))
      }
      return t.join(' - ')
    },

    /**
     *
     * @param {boolean} value
     */
    // onChangeValueOtherAddress(value) {
    //   this.order.useCustomAddress = value
    //   if (value) {
    //     for (const delivery of this.state.order.deliveries) {
    //       delivery.addresses = [
    //         new Address({
    //           streets: [
    //             new Street(),
    //             new Street(),
    //           ],
    //         })
    //       ]
    //     }
    //   }
    // },

    /**
     *
     * @param {Address} address
     */
    cloneAddress(address) {

      address = new Address(address)

      let countryId = 0

      for (const country of address.countries) {
        countryId = country.id
      }

      address = new Address(JSON.parse(JSON.stringify(address, (k, v) => {
        if (k === 'id') return 0
        return v
      })))

      for (const country of address.countries) {
        country.id = countryId
      }

      return address
    },

    /**
     * @param {Address[]} addresses
     */
    onChangeRegisteredValues(addresses) {
      this.state.addressesSelected = addresses
      if (addresses.length) {
        for (const delivery of this.state.order.deliveries) {
          delivery.addresses = addresses.map(a => this.cloneAddress(a))
        }
      }
    },

    /**
     * @param {Shop[]} shops
     */
    onChangeRegisteredShop(shops) {
      this.state.order.shops = shops
      for (const shop of shops) {
        this.onChangeRegisteredValues(shop.addresses)
      }
    },

    /**
     *
     * @param {Address[]} addresses
     */
    onChangeRegisteredBillValues(addresses) {
      this.state.addressesBillSelected = addresses
      if (addresses.length) {
        this.checkDefaultFundingAddress()
        for (const funding of this.state.order.fundings) {
          funding.addresses = addresses.map(a => this.cloneAddress(a))
        }
      }
    },

    /**
     *
     * @param {Address} address
     */
    addressIsEmpty(address) {
      return !address.city && !address.zipCode && !address.countries.length && !address.streets.map(s => s.value).join('')
    },

    /**
     *
     * @param {Term} term
     */
    displayError(term) {
      return new Term(term)
    },

    /**
     *
     * @param {Term} term
     */
    checkDefaultFundingAddress() {
      if (!this.state.order.fundings.length) {
        this.state.order.fundings = [
          new Funding({
            addresses: [
              new Address({
                streets: [
                  new Street(),
                  new Street(),
                ],
              })
            ]
          })
        ]
      }

    },

    /**
     *
     * @param {boolean} value
     */
    onChangeValueSameAddress(value) {
      this.order.sameFundingAddress = value
      if (!value) {
        this.state.order.fundings = [
          new Funding({
            addresses: [
              new Address({
                streets: [
                  new Street(),
                  new Street(),
                ],
              })
            ]
          })
        ]
      } else {
        for (const delivery of this.state.order.deliveries) {
          this.checkDefaultFundingAddress()
          for (const funding of this.state.order.fundings) {
            funding.addresses = delivery.addresses.map(a => this.cloneAddress(a))
          }
        }
      }
    },
  }
}
</script>
