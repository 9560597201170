<style scoped>
.AnimationTitle {
  overflow: hidden;
}

.AnimationTitle--content {
  transform: translateY(100%);
  transition: 1000ms;
}

.AnimationTitle-isVisible .AnimationTitle--content {
  transform: translateY(0);
}
</style>

<template>
  <Visible :debug="debug" :onVisibilityChanged="onVisibilityChanged">
    <div class="AnimationTitle" :class="class_root">
      <div class="AnimationTitle--content" :class="class_root">
        <slot></slot>
      </div>
    </div>
  </Visible>
</template>

<script>

// -----

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {eventService} from "../../service/eventService";

// -----

export default {
  props: {
    debug: {
      type: Boolean,
      default: () => false
    },
    id: {}
  },
  data() {
    return {
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      props: {},
      state: {
        isVisible: false
      },
      events: []
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.state.isVisible) classes.push('AnimationTitle-isVisible')
      return classes
    }
  },
  beforeMount() {
    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    onVisibilityChanged({isVisible}) {
      this.state.isVisible = isVisible
    },
  }
}
</script>