<style scoped>
.FilterSection {
}

.FilterSection--header {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.FilterSection--headerLeft {
  display: flex;
  align-items: center;
}

.FilterSection--icon {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  background: rgba(0, 0, 0, 0.06);
  padding: 10px;
  border-radius: 4px;
}

.FilterSection--headerRight {
  display: flex;
  align-items: center;
}

.FilterSection--title {
  font-family: 'DDinBold', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  padding-left: 25px;
  color: rgba(0, 0, 0, 1);
}

.FilterSection--toggle {
  width: 24px;
  cursor: pointer;
}

.FilterSection--toggle ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 0.25);
  transition: fill 300ms;
}

.FilterSection--toggle:hover ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 0.5);
}

.FilterSection--items {
  display: flex;
  flex-flow: wrap;
  padding: 25px 0;
}

.FilterSection--item {
  padding-top: 25px;
  padding-right: 25px;
  box-sizing: border-box;
  cursor: pointer;
}

.FilterSection--item ::v-deep .Link {
}

.FilterSection-column-1 .FilterSection--item {
  width: 100%;
}

.FilterSection-column-2 .FilterSection--item {
  width: 50%;
}

.FilterSection--item ::v-deep .Link {
  display: flex;
  align-items: center;
}

.FilterSection--item ::v-deep .Link {
  display: flex;
  align-items: center;
}

.FilterSection--img {
  width: 24px;
  height: 24px;
  min-width: 24px;
  min-height: 24px;
  padding: 10px;
  border-radius: 4px;
  transition: background 300ms;
  background: rgba(0, 0, 0, 0);
  opacity: 0.5;
  border: solid 1px rgba(0, 0, 0, 0.25);
}

.FilterSection--item:hover .FilterSection--img {
  background: rgba(0, 0, 0, 0.06);
  opacity: 1;
  border: solid 1px rgba(0, 0, 0, 0);
}

.FilterSection--item-selected .FilterSection--img {
  background: rgba(0, 0, 0, 0.06);
  opacity: 1;
  border: solid 1px rgba(0, 0, 0, 0);
}

.FilterSection--term {
  font-family: 'DDinBold', sans-serif;
  text-transform: uppercase;
  font-size: 12px;
  padding-left: 25px;
  color: rgba(0, 0, 0, 0.5);
  transition: color 300ms;
}

.FilterSection--item:hover .FilterSection--term {
  color: rgba(0, 0, 0, 1);
}

.FilterSection--item-selected .FilterSection--term {
  color: rgba(0, 0, 0, 1);
}

.FilterCollapse--items {
  display: flex;
  flex-flow: wrap;
}
</style>

<template>
  <div class="FilterSection" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <FilterCollapse :title="title"
                      :svg="svg"
                      :opened="opened"
                      :onOpenedChange="onOpenedChange"
                      :withToggle="withToggle"
                      :withIcon="withIcon"
                      :count="selected.length"
                      :onCancel="() => state.selected = []">
        <div class="FilterCollapse--items">
          <template v-for="item in items">
            <template v-if="onFilter(item)">
              <div class="FilterSection--item" :class="class_item(item)" @click="() => toggleSelected(item)">
                <Link :link="withLink && !isSelected(item) ? item.link : undefined" :indexed="item.indexed">
                  <div class="FilterSection--img">
                    <slot name="img" :item="item">
                      <Picture :display="PICTURE_DISPLAY.AUTO" :src="item.img"/>
                    </slot>
                  </div>
                  <div class="FilterSection--term">
                    <Term :term="item.term"/>
                  </div>
                </Link>
              </div>
            </template>
          </template>
        </div>
      </FilterCollapse>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {Term} from "../../../@common/delta/database/Term";
import {PICTURE_DISPLAY} from "../../../@common/constant/PICTURE_DISPLAY";
import {FilterDelta} from "../../../@common/delta/filter/FilterDelta";

export default {

  props: {
    svg: {
      type: String,
      default: () => ''
    },
    opened: {
      type: Boolean,
      default: () => false
    },
    onOpenedChange: {
      type: Function,
      default: () => () => {
      }
    },
    title: {
      default: () => new Term()
    },

    // -----

    id: {
      type: Function,
      default: () => item => item.id
    },
    selected: {
      type: Array,
      default: () => ([])
    },
    onSelectedChange: {
      type: Function,
      default: () => () => {
      }
    },
    onSelectedAdd: {
      type: Function,
      default: () => () => {
      }
    },
    onSelectedRemove: {
      type: Function,
      default: () => () => {
      }
    },
    items: {
      type: Array,
      default: () => ([])
    },
    onItemsChange: {
      type: Function,
      default: () => () => {
      }
    },
    onFilter: {
      type: Function,
      default: () => (item) => true
    },
    column: {
      type: Number,
      default: () => 2
    },
    defaultSelectedPaths: {
      type: Function,
      default: () => () => ([])
    },
    withLink: {
      type: Boolean,
      default: () => false
    },
    withIcon: {
      type: Boolean,
      default: () => true
    },
    withToggle: {
      type: Boolean,
      default: () => true
    },
  },
  data() {
    return {
      PICTURE_DISPLAY,
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        browser_not_supported: require('@bbx/vector~master/core/assets/svg/material/browser_not_supported.svg'),
      },
      state: {
        opened: this.opened,
        selected: this.selected.map(a => new FilterDelta(a)),
      },
    }
  },
  watch: {
    'opened': function (v) {
      this.state.opened = v
    },
    'state.opened': function (v) {
      this.onOpenedChange(v)
    },
    'selected': {
      handler(v) {
        this.state.selected = v.map(a => new FilterDelta(a))
      },
      deep: true
    },
    'state.selected': {
      handler(v) {
        this.onSelectedChange(v.map(a => new FilterDelta(a)))
      },
      deep: true
    },
    'items': {
      handler(v) {
        this.state.items = v.map(a => new FilterDelta(a))
      },
      deep: true
    },
    'state.items': {
      handler(v) {
        this.onItemsChange(v.map(a => new FilterDelta(a)))
      },
      deep: true
    }
  },
  computed: {
    class_root() {
      const classes = []
      if (this.column === 1) classes.push('FilterSection-column-1')
      if (this.column === 2) classes.push('FilterSection-column-2')
      return classes;
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    class_item(item) {
      const classes = []
      if (this.isSelected(item)) classes.push('FilterSection--item-selected')
      return classes;
    },
    isSelected(item) {
      return this.getIndex(item) !== -1
    },
    getIndex(item) {
      let found = -1
      for (const i in this.state.selected) {
        const selected = this.state.selected[i]
        if (this.id(selected) === this.id(item)) {
          found = parseInt(i)
          break;
        }
      }
      return found
    },
    toggleSelected(item) {
      const index = this.getIndex(item)
      if (index === -1) {
        this.onSelectedAdd(item)
        this.state.selected.push(item)
      }
      else {
        this.onSelectedRemove(item)
        this.state.selected.splice(index, 1)
      }
    }
  }
}
</script>
