"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isHome = void 0;
var PATHS_1 = require("../constant/PATHS");
var helperUrl_1 = require("./helperUrl");
function isHome(nuxtProp) {
    var includes = [
        [PATHS_1.PATHS.HOME].join('.'),
    ];
    var isInIncludeList = includes.includes(helperUrl_1.getNuxtPropPathIds(nuxtProp).join('.'));
    var hasNoPath = nuxtProp.url.paths.length < 2;
    var isHome = isInIncludeList || hasNoPath;
    return isHome;
}
exports.isHome = isHome;
