<style scoped>
.CollectionForm {
}

.CollectionForm--photo {
  width: 100%;
  box-sizing: border-box;
  height: 200px;
  border-radius: 8px;
  background: rgba(0, 0, 0, 0.05);
  overflow: hidden;
  padding-bottom: 100%;
  position: relative;
}

.CollectionForm--photoContent {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.CollectionForm--modal {
  height: 100%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  box-sizing: border-box;
}
</style>

<template>
  <div class="CollectionForm" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">


      <InputSection :svg="icon.panorama" :term="lang.term(523)">
        <div class="CollectionForm--photo">
          <div class="CollectionForm--photoContent">
            <Picture v-if="fileCover.id" :file="fileCover"/>
          </div>
        </div>
        <br>
        <br>
        <PhotosUpload :photos="photos" :onPhotoChange="photo => onPhotoChange(photo)"/>
        <Photos :photos="photos"
                :updatable="true"
                :onClickUpdate="(v ,i) => onClickUpdatePhoto(i)"
                :onClickDelete="(i) => state.collection.sortPhotos.splice(i, 1)"/>
      </InputSection>


      <InputSection :svg="icon.subject" :term="lang.term(524)">
        <InputText :value="state.collection.value"
                   :mandatory="true"
                   :onChangeValue="v => state.collection.value = v"
                   :label="lang.term(525)"
                   :error="form.display(`collection.value`)">
        </InputText>
        <br>
        <template v-for="termDescription of state.collection.termDescriptions">
          <InputTerm :term="termDescription"
                     type="textarea"
                     :onChangeValue="v => termDescription = v"
                     :label="lang.term(526)"
                     :error="form.display(`collection.termDescriptions`)">
          </InputTerm>
        </template>
        <br>
        <div>
          <InputSelectTerm :label="lang.term(527)"
                           :items="state.materials"
                           :max="3"
                           :cloned="true"
                           :values="uniqBy(state.collection.materials, 'id')"
                           :onChangeValues="v => state.collection.materials = v">
          </InputSelectTerm>
        </div>
        <br>
        <div>
          <InputSelect :items="state.shopTypeLists"
                       :values="uniqBy(state.collection.shopTypeLists, 'id')"
                       :max="2"
                       :cloned="true"
                       :label="lang.term(528)"
                       :onChangeValues="v => state.collection.shopTypeLists = v">
          </InputSelect>
        </div>
        <br>
        <InputSwitch :value="state.collection.isOutlet"
                     :onChangeValue="v => state.collection.isOutlet = v"
                     :label="lang.term(529)"></InputSwitch>
      </InputSection>

      <InputSection :svg="icon.colorize" :term="lang.term(530)">
        <InputLabel :term="lang.term(531)"></InputLabel>
        <br>
        <template v-for="colorDark in state.collection.colorDarks">
          <el-color-picker v-model="colorDark.hexadecimal"></el-color-picker>
        </template>
        <br>
        <br>
        <InputLabel :term="lang.term(532)"></InputLabel>
        <br>
        <template v-for="colorLight in state.collection.colorLights">
          <el-color-picker v-model="colorLight.hexadecimal"></el-color-picker>
        </template>
      </InputSection>

      <InputSection :svg="icon.picture_as_pdf" :term="lang.term(533)">
        <FileUpload :files="state.collection.fileCatalogs"
                    :onFileChange="v => state.collection.fileCatalogs = v"/>
        <FileDownload :files="state.collection.fileCatalogs"
                      :updatable="false"
                      :onClickDelete="() => state.collection.fileCatalogs.splice(i, 1)"/>
      </InputSection>

      <Modal :opened="props.Modal['0'].opened" :onOpenedChange="v => onOpenedChangeModal(v)">
        <div class="CollectionForm--modal">
          <div class="CollectionForm--modalBody">
            <InputNumber :value="state.sort.order"
                         :onChangeValue="v => state.sort.order = v"
                         :label="lang.term(534)">
            </InputNumber>
          </div>
          <div class="CollectionForm--modalFooter">
            <ButtonLink :term="lang.term(535)" :onClick="() => props.Modal['0'].opened = false"></ButtonLink>
          </div>
        </div>
      </Modal>

    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {Collection} from "../../../@common/delta/database/Collection";
import {File} from "../../../@common/delta/database/File";
import {apiClient} from "../../service/apiClientService";
import selectShopTypeLists from "../../../@common/api/shop_type_list/selectShopTypeList";
import {sendError} from "../../function/sendError";
import {Sort} from "../../../@common/delta/database/Sort";
import {Photo} from "../../../@common/delta/database/Photo";
import {Term} from "../../../@common/delta/database/Term";
import selectMaterials from "../../../@common/api/material/selectMaterials";
import {Color} from "../../../@common/delta/database/Color";
import {uniqBy} from "../../../@common/function/uniqBy";

export default {
  props: {
    collection: {
      type: Collection,
      default: () => new Collection()
    },
    onChangeCollection: {
      type: Function,
      default: () => (collection) => {
      }
    },
    form: {
      default: () => new ApiResponseForm()
    },
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
        Modal: {
          '0': {
            opened: false
          }
        }
      },
      icon: {
        panorama: require('@bbx/vector~master/core/assets/svg/material/panorama.svg'),
        subject: require('@bbx/vector~master/core/assets/svg/material/subject.svg'),
        picture_as_pdf: require('@bbx/vector~master/core/assets/svg/material/picture_as_pdf.svg'),
        colorize: require('@bbx/vector~master/core/assets/svg/material/colorize.svg'),
      },
      state: {
        /**
         * @type Collection
         */
        collection: new Collection(this.collection),
        /**
         * @type Sort
         */
        sort: new Sort(),
        /**
         * @type number
         */
        indexPhoto: 0,
        /**
         * @type boolean
         */
        loadingShopTypeList: false,
        /**
         * @type boolean
         */
        loadingMaterial: false,
        /**
         * @type ShopTypeList[]
         */
        shopTypeLists: [],
        /**
         * @type Material[]
         */
        materials: [],
      },
    }
  },
  watch: {
    'collection': {
      handler(v) {
        this.state.collection = v
      },
      deep: true
    },
    'state.collection': {
      handler(v) {
        this.onChangeCollection(v)
        this.defaultValue()
        this.sortCollectionPhotos(v)
      },
      deep: true
    },
  },
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`CollectionForm-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`CollectionForm-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`CollectionForm-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`CollectionForm-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`CollectionForm-desktopY`)
      return classes
    },
    /**
     * @return {File}
     */
    fileCover() {
      const collection = new Collection(this.collection)
      for (const sortPhoto of collection.sortPhotos) {
        for (const photo of sortPhoto.photos) {
          for (const file of photo.files) {
            return new File(file)
          }
        }
      }
      return new File()
    },
    /**
     * @return {Photo[]}
     */
    photos() {
      let photos = []
      const collection = new Collection(this.collection)
      for (const sortPhoto of collection.sortPhotos) {
        for (const photo of sortPhoto.photos) {
          photos.push(photo)
        }
      }
      return photos
    }
  },
  beforeMount() {
  },
  mounted() {
    this.select().catch(sendError)
    this.defaultValue()
    this.sortCollectionPhotos(this.state.collection)
  },
  beforeDestroy() {
  },
  methods: {
    uniqBy,

    defaultValue() {
      if (!this.state.collection.termDescriptions.length) {
        this.state.collection.termDescriptions.push(new Term())
      }
      if (!this.state.collection.colorDarks.length) {
        this.state.collection.colorDarks.push(new Color())
      }
      if (!this.state.collection.colorLights.length) {
        this.state.collection.colorLights.push(new Color())
      }
    },

    async select() {
      await Promise.all([
        this.selectShopTypeLists(),
        this.selectMaterials(),
      ])
    },

    async selectShopTypeLists() {
      this.state.loadingShopTypeList = true
      const filter = {
        active: true,
      }
      const {data} = await apiClient.do(selectShopTypeLists, selectShopTypeLists.with(filter))
      this.state.shopTypeLists = data;
      this.state.loadingShopTypeList = false
    },

    async selectMaterials() {
      this.state.loadingMaterial = true
      const filter = {
        active: true,
      }
      const {data} = await apiClient.do(selectMaterials, selectMaterials.with(filter))
      this.state.materials = data;
      this.state.loadingMaterial = false
    },

    /**
     * @param {Photo} photo
     * @return {void}
     */
    onPhotoChange(photo) {
      let sortPhoto = new Sort({
        photos: [photo]
      })
      this.collection.sortPhotos.push(sortPhoto)
    },

    /**
     * @param {number} indexPhoto
     * @return {void}
     */
    onClickUpdatePhoto(indexPhoto) {
      this.state.indexPhoto = indexPhoto
      if (this.state.collection.sortPhotos.length) {
        this.state.sort.order = this.state.collection.sortPhotos[this.state.indexPhoto].order
      }
      this.props.Modal['0'].opened = true
      // console.log('onClickUpdatePhoto', indexPhoto, this.state.sort.order)
    },

    /**
     * @param {boolean} opened
     * @return {void}
     */
    onOpenedChangeModal(opened) {
      this.props.Modal['0'].opened = opened
      if (!opened && this.state.collection.sortPhotos.length) {
        this.state.collection.sortPhotos[this.state.indexPhoto].order = this.state.sort.order
      }
      // console.log('onOpenedChangeModal', this.state.sort.order)
    },

    /**
     * @param {Collection} collection
     * @return {void}
     */
    sortCollectionPhotos(collection) {

      const defaultSortPhotos = collection.sortPhotos
      const sortedSortPhotos = [...this.state.collection.sortPhotos].sort((a, b) => a.order - b.order)

      const newData = JSON.stringify(defaultSortPhotos.map(a => a.id))
      const oldData = JSON.stringify(sortedSortPhotos.map(a => a.id))

      if (newData === oldData) return

      this.state.collection.sortPhotos = sortedSortPhotos
    }
  }
}
</script>
