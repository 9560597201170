"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Product = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Detail_1 = require("./Detail");
var Highlight_1 = require("./Highlight");
var Collection_1 = require("./Collection");
var Material_1 = require("./Material");
var Package_1 = require("./Package");
var Size_1 = require("./Size");
var Term_1 = require("./Term");
var Photo_1 = require("./Photo");
var Category_1 = require("./Category");
var Batch_1 = require("./Batch");
var SittingPlace_1 = require("./SittingPlace");
var Battery_1 = require("./Battery");
var Lumen_1 = require("./Lumen");
var Shape_1 = require("./Shape");
var Stock_1 = require("./Stock");
var Component_1 = require("./Component");
var Similar_1 = require("./Similar");
var Product = /** @class */ (function () {
    function Product(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.reference = deltaService_1.deltaService.string(props.reference, '');
        this.gencod = deltaService_1.deltaService.string(props.gencod, '');
        this.termNames = deltaService_1.deltaService.array(props.termNames, Term_1.Term);
        this.termDescriptions = deltaService_1.deltaService.array(props.termDescriptions, Term_1.Term);
        this.termMaintains = deltaService_1.deltaService.array(props.termMaintains, Term_1.Term);
        this.details = deltaService_1.deltaService.array(props.details, Detail_1.Detail);
        this.highlights = deltaService_1.deltaService.array(props.highlights, Highlight_1.Highlight);
        this.collections = deltaService_1.deltaService.array(props.collections, Collection_1.Collection);
        this.materials = deltaService_1.deltaService.array(props.materials, Material_1.Material);
        this.packages = deltaService_1.deltaService.array(props.packages, Package_1.Package);
        this.sizes = deltaService_1.deltaService.array(props.sizes, Size_1.Size);
        this.photos = deltaService_1.deltaService.array(props.photos, Photo_1.Photo);
        this.photoBarCodes = deltaService_1.deltaService.array(props.photoBarCodes, Photo_1.Photo);
        this.photoLabels = deltaService_1.deltaService.array(props.photoLabels, Photo_1.Photo);
        this.categories = deltaService_1.deltaService.array(props.categories, Category_1.Category);
        this.sittingPlaces = deltaService_1.deltaService.array(props.sittingPlaces, SittingPlace_1.SittingPlace);
        this.batteries = deltaService_1.deltaService.array(props.batteries, Battery_1.Battery);
        this.lumens = deltaService_1.deltaService.array(props.lumens, Lumen_1.Lumen);
        this.shapes = deltaService_1.deltaService.array(props.shapes, Shape_1.Shape);
        this.stocks = deltaService_1.deltaService.array(props.stocks, Stock_1.Stock);
        this.components = deltaService_1.deltaService.array(props.components, Component_1.Component);
        this.similarUps = deltaService_1.deltaService.array(props.similarUps, Similar_1.Similar);
        this.batches = deltaService_1.deltaService.array(props.batches, Batch_1.Batch);
        this.componentComponents = deltaService_1.deltaService.array(props.componentComponents, Component_1.Component);
        this.similarDowns = deltaService_1.deltaService.array(props.similarDowns, Similar_1.Similar);
    }
    return Product;
}());
exports.Product = Product;
