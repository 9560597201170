<style scoped>

</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="content-wrapper none for-title">
      <div class="container_12">
        <div class="main-container main-main-container col1-layout">
          <div class="grid_12 col-main">
            <div class="page-title">
              <h1>Comment créer un coin piscine agréable ?</h1>
            </div>
            <div class="std">
              <p style="text-align: left;">
                <img alt="" src="./media/wysiwyg/bandeauterrassepiscine.jpg">
              </p>
              <p style="text-align: left;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Avoir une
                  <b>piscine</b>
                  c’est bien, mais pour en profiter pleinement et en faire un endroit propice au bien-être, un
                  <b>aménagement</b>
                  bien pensé autour de celle-ci semble indispensable. Découvrez toutes nos idées pour aménager un coin
                  piscine accueillant.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Choisir sa plage de piscine</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Plusieurs matériaux sont utilisés pour construire les
                  <b>plages des piscines</b>
                  . Sélectionnés pour leur esthétisme et leur praticité, ils ont également des propriétés
                  antidérapantes, indispensables pour éviter les accidents et les chutes après la baignade.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le choix du matériau utilisé pour le sol déterminera en partie
                  l’ambiance et le style général de votre extérieur. Le
                  <b>bois</b>
                  est chaleureux et résistant, mais il demande un entretien régulier, sans quoi il perdra de sa couleur.
                  Le
                  <b>béton</b>
                  est un revêtement tendance et moderne et a l’avantage de rester frais, même en été. Le
                  <b>composite</b>
                  est accessible, économique et facile à poser, mais les entrées de gamme sont peu résistantes sur le
                  long terme. Le
                  <b>carrelage</b>
                  est facile d’entretien, il est esthétique et on trouve un large choix de modèles. Pour finir, la
                  <b>pierre naturelle</b>
                  est très résistante, mais plus chère.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Installer une douche solaire</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Installée près de votre piscine, une
                  <b>douche solaire</b>
                  vous permettra de vous laver, après votre baignade, sans avoir à rentrer chez vous. On évitera ainsi
                  les traces de pieds mouillés et les trainées d’eau dans la maison.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Grâce à un système fonctionnant à l’énergie solaire, cette douche vous
                  fournira de l’eau chaude dont vous pourrez régler la température avec un mitigeur. Cette installation
                  ajoutera luxe et confort à votre coin piscine.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Sélectionner le mobilier</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Votre mobilier devra être adapté à un usage au bord d’une piscine. Tous
                  les
                  <b>matériaux</b>
                  ne sont pas compatibles avec cet environnement. Par exemple, on privilégiera des meubles en aluminium,
                  qui sont inoxydables, ou en bois imputrescible, mais on évitera ceux en rotin qui ne supporte pas
                  l’humidité. De même, vous serez attentif aux tissus ou matériaux pouvant être abîmés ou tachés par les
                  produits de traitement de l’eau (brome, chlore, etc.).
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Réfléchissez à la fonctionnalité que vous voulez donner à votre
                  <b>espace piscine</b>
                  . Voulez-vous en faire un coin farniente pour vous reposer ou plutôt un endroit convivial pour
                  recevoir vos amis&nbsp;? Votre choix de mobilier de jardin variera en fonction de l’utilité que vous
                  aurez de votre coin piscine.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h3 style="text-align: justify;">Un coin détente</h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le
                  transat
                  semble être le mobilier inévitable pour aménager un coin piscine. Il existe cependant diverses
                  alternatives pour paresser au
                  <b>bord de la piscine</b>
                  . Pourquoi ne pas installer un hamac ou des coussins géants de différentes couleurs pour sortir des
                  classiques&nbsp;?
                </span>
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <p>
                <span style="font-size: large;">
                  <img style="margin: 20px;" alt="" src="./media/wysiwyg/litexterieur.jpg">
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Pour un petit extérieur, on privilégiera une chilienne au bain de soleil
                  pour se prélasser. Il en existe en plusieurs coloris. Cela vous permettra de libérer facilement
                  l’espace autour de votre bassin, afin d’installer une table et des chaises et accueillir vos convives.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Ajoutez une touche d’originalité à votre
                  <b>décoration</b>
                  grâce à un tapis d’extérieur. Installé sous vos bains de soleil, il délimitera votre espace détente et
                  vous évitera de glisser si vous avez les pieds mouillés.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h3 style="text-align: justify;">Un lieu convivial</h3>
              <p style="text-align: justify;">
                <span style="font-size: large;">Disposez, autour de votre bassin, un
                  <b>salon de jardin</b>
                  confortable, un canapé d’angle, une table basse et des fauteuils pour créer un lieu accueillant et
                  sympathique. Si vous aimez recevoir ou organiser de temps en temps une pool party, c’est l’idéal. Et
                  pourquoi ne pas installer une petite cuisine d’été dans votre
                  <b>jardin</b>
                  ou sur votre
                  <b>terrasse</b>&nbsp;? Vous pourrez ainsi siroter un jus de fruits frais ou prendre l’apéritif avec
                  vos amis au bord de votre bassin et piquer une tête si l’envie vous prend.
                </span>
              </p>
              <p style="text-align: left;">
                <span style="font-size: large;">&nbsp;<img style="margin: 20px;" alt="" src="./media/wysiwyg/canap.jpg">
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <h2 style="text-align: justify;">Végétaliser les abords de sa piscine</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Installer des pots de fleurs près de votre piscine vous permettra de
                  gagner en intimité, et d’ajouter une touche végétale autour de votre bassin, pour une déco plus
                  nature.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Évitez les plantes qui perdent leurs feuilles, car elles finiront dans
                  l’eau et ne faciliteront pas l’entretien. Préférez les palmiers, l’agave, les cactus ou le bambou, par
                  exemple.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Créer des coins d’ombre</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Vous pouvez délimiter l’espace et créer une nouvelle pièce en installant
                  une pergola. Elle vous permettra de vous protéger du
                  <b>soleil</b>
                  et d’ajouter de la personnalité à votre extérieur. Si vous n’avez pas la place pour une pergola ou si
                  vous souhaitez ajouter une allure plus moderne à votre piscine, choisissez un voile d’ombrage.
                  Disposez-le de façon à avoir de l’ombre dans votre bassin, ainsi, vous pourrez vous baigner, sans
                  risque de coup de soleil, même aux les heures les plus chaudes.
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">&nbsp;</span>
              </p>
              <h2 style="text-align: justify;">Ajouter des touches de lumière
                <span style="font-size: large;">&nbsp;</span>
              </h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Le soir, les spots de votre piscine ne suffisent pas à en faire un
                  endroit agréable. Transformez votre extérieur en lieu chaleureux grâce aux
                  <b>luminaires mobiles</b>
                  fonctionnant à l’énergie solaire. Posez des lanternes sur les meubles ou installez une guirlande
                  au-dessus du bassin, par exemple. Vous pourrez ainsi profiter pleinement de votre piscine durant les
                  soirées estivales.
                </span>
              </p>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../../function/getH1";
import {Term} from "../../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../../@common/constant/PROJECT_URL";
import {PATHS} from "../../../../@common/constant/PATHS";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    },
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FAQ-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
