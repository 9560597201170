"use strict";
var __spreadArrays = (this && this.__spreadArrays) || function () {
    for (var s = 0, i = 0, il = arguments.length; i < il; i++) s += arguments[i].length;
    for (var r = Array(s), k = 0, i = 0; i < il; i++)
        for (var a = arguments[i], j = 0, jl = a.length; j < jl; j++, k++)
            r[k] = a[j];
    return r;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.getFlatAddress = void 0;
function getFlatAddress(address, symbol) {
    if (symbol === void 0) { symbol = ', '; }
    var countryName = address.country || 'France';
    for (var _i = 0, _a = address.countries; _i < _a.length; _i++) {
        var country = _a[_i];
        if (country.name)
            countryName = country.name;
    }
    var addresses = __spreadArrays(address.streets.filter(function (s) { return s.value; }).map(function (s) { return s.value; }), [
        address.zipCode + " " + address.city,
        countryName,
    ]);
    return addresses.join(symbol);
}
exports.getFlatAddress = getFlatAddress;
