import {deltaService} from "@bbx/delta~master/core/service/deltaService"
import {Product} from "./Product"
import {Page} from "./Page";
import {ShopTypeList} from "./ShopTypeList";
import {Sort} from "./Sort";
import {Term} from "./Term";
import {File} from "./File";
import {Material} from "./Material";
import {Color} from "./Color";
import {DeepPartial} from "../../type/DeepPartial";


export type Collection_constructor_props = DeepPartial<Collection>

export class  Collection {

    id: number
    active: boolean
    createdAt: string
    updatedAt: string

    value: string
    isOutlet: boolean

    sortPhotos: Sort[]
    shopTypeLists: ShopTypeList[]
    termDescriptions: Term[]
    fileCatalogs: File[]
    materials: Material[]
    colorDarks: Color[]
    colorLights: Color[]
    products: Product[]
    pages: Page[]

    constructor(props: Collection_constructor_props = {}) {
                this.id = deltaService.number(props.id, 0)
        this.active = deltaService.boolean(props.active, true)
        this.createdAt = deltaService.string(props.createdAt, '')
        this.updatedAt = deltaService.string(props.updatedAt, '')

        this.value = deltaService.string(props.value, '')
        this.isOutlet = deltaService.boolean(props.isOutlet, false)

        this.sortPhotos = deltaService.array(props.sortPhotos, Sort)
        this.shopTypeLists = deltaService.array(props.shopTypeLists, ShopTypeList)
        this.termDescriptions = deltaService.array(props.termDescriptions, Term)
        this.fileCatalogs = deltaService.array(props.fileCatalogs, File)
        this.materials = deltaService.array(props.materials, Material)
        this.colorDarks = deltaService.array(props.colorDarks, Color)
        this.colorLights = deltaService.array(props.colorLights, Color)
        this.products = deltaService.array(props.products, Product)
        this.pages = deltaService.array(props.pages, Page)
    }
}
