import ApiRegistery from '@bbx/api~master/core/delta/ApiRegistery';

import {ApiRequest_constructor_props as ApiRequest} from "../../delta/http/ApiRequest";
import {ApiResponse} from "../../delta/http/ApiResponse";
import {Shop, Shop_constructor_props} from "../../delta/database/Shop";

export interface Request extends Shop_constructor_props {
    $selectAll?: boolean
    $limit?: number
    $offset?: number
    $ids?: number[]
    $withTvaNumber?: boolean
    $sageUuid?: string
    $search?: string
    $searchType?: number
    $searchNearestPosition?: { latitude: number, longitude: number }
    $sortById?: boolean
}

export interface Response extends Shop {
}

export default new ApiRegistery<Request & ApiRequest, ApiResponse<Response[]>>({
    hydrate: (apiResponse) => Object.assign(apiResponse, {data: apiResponse.data.map(data => new Shop(data))}),
    name: 'selectShops',
})
