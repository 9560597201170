"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.urlService = void 0;
var normalizeUrl_1 = require("../function/normalizeUrl");
var LANG_1 = require("../constant/LANG");
var Url_1 = require("../delta/database/Url");
var helperUrl_1 = require("../function/helperUrl");
var urlIndex = {
    pathValuePathId: {},
    pathIdPathValue: {},
    pathIdsPageId: {},
    pageIdPageIndex: {},
    pageIdPath: {},
};
function getIndex() {
    return urlIndex;
}
function getPathValueById(pathId, langIso6391) {
    var key = pathId + "." + langIso6391;
    var keyDefault = pathId + "." + LANG_1.LANG_ISO.FR;
    return urlIndex.pathIdPathValue[key] || urlIndex.pathIdPathValue[keyDefault];
}
function getPageIndexById(pageId) {
    return urlIndex.pageIdPageIndex[pageId];
}
function getPageByPathIds(pathIds) {
    var key = pathIds.join('.');
    return urlIndex.pathIdsPageId[key];
}
function createValueIndex(pages) {
    var index = {};
    for (var _i = 0, pages_1 = pages; _i < pages_1.length; _i++) {
        var page = pages_1[_i];
        for (var _a = 0, _b = page.paths; _a < _b.length; _a++) {
            var path = _b[_a];
            var key = {};
            for (var _c = 0, _d = path.terms; _c < _d.length; _c++) {
                var term = _d[_c];
                for (var _e = 0, _f = term.translations; _e < _f.length; _e++) {
                    var translation = _f[_e];
                    for (var _g = 0, _h = translation.langs; _g < _h.length; _g++) {
                        var lang = _h[_g];
                        if (!key[lang.iso6391])
                            key[lang.iso6391] = [];
                        key[lang.iso6391].push(normalizeUrl_1.normalizeUrl(translation.value));
                    }
                }
            }
            for (var iso6391 in key) {
                var value = key[iso6391].join('-');
                if (value)
                    index[value] = path.id;
            }
        }
    }
    return index;
}
function createIdIndex(pages) {
    var index = {};
    for (var _i = 0, pages_2 = pages; _i < pages_2.length; _i++) {
        var page = pages_2[_i];
        for (var _a = 0, _b = page.paths; _a < _b.length; _a++) {
            var path = _b[_a];
            var isNew = false;
            for (var _c = 0, _d = path.terms; _c < _d.length; _c++) {
                var term = _d[_c];
                var defaultTranslationValue = '';
                for (var _e = 0, _f = term.translations; _e < _f.length; _e++) {
                    var translation = _f[_e];
                    for (var _g = 0, _h = translation.langs; _g < _h.length; _g++) {
                        var lang = _h[_g];
                        if (lang.iso6391 === LANG_1.LANG_ISO.FR) {
                            defaultTranslationValue = translation.value;
                        }
                        if (!translation.value) {
                            translation.value = defaultTranslationValue;
                        }
                        var key = path.id + "." + lang.iso6391;
                        if (!index[key]) {
                            isNew = true;
                            index[key] = '';
                        }
                        if (!index[key] || isNew) {
                            index[key] += "" + (index[key] ? '-' : '') + normalizeUrl_1.normalizeUrl(translation.value);
                        }
                    }
                }
            }
        }
    }
    return index;
}
function createPageIdPageIndex(pages) {
    var index = {};
    pages.map(function (page, i) { return index[page.id] = i; });
    return index;
}
function createPathIdPageIdIndex(pages) {
    var index = {};
    for (var _i = 0, pages_3 = pages; _i < pages_3.length; _i++) {
        var page = pages_3[_i];
        var keys = [];
        for (var _a = 0, _b = page.paths; _a < _b.length; _a++) {
            var path = _b[_a];
            keys.push(path.id);
        }
        var key = keys.join('.');
        index[key] = page.id;
    }
    return index;
}
function createPathIdPath(pages) {
    var index = {};
    for (var _i = 0, pages_4 = pages; _i < pages_4.length; _i++) {
        var page = pages_4[_i];
        for (var _a = 0, _b = page.paths; _a < _b.length; _a++) {
            var path = _b[_a];
            index[path.id] = path;
        }
    }
    return index;
}
function createIndex(pages) {
    urlIndex.pathValuePathId = createValueIndex(pages);
    urlIndex.pathIdPathValue = createIdIndex(pages);
    urlIndex.pathIdsPageId = createPathIdPageIdIndex(pages);
    urlIndex.pageIdPageIndex = createPageIdPageIndex(pages);
    urlIndex.pageIdPath = createPathIdPath(pages);
}
function getUrl(langIso6391, pathIds, query) {
    if (query === void 0) { query = {}; }
    var paths = [langIso6391];
    for (var _i = 0, pathIds_1 = pathIds; _i < pathIds_1.length; _i++) {
        var pathId = pathIds_1[_i];
        paths.push(getPathValueById(pathId, langIso6391));
    }
    var queries = [];
    for (var name in query) {
        queries.push([name, '=', query[name]].join(''));
    }
    var url = "/" + paths.join('/') + (queries.length ? "?" + queries.join('&') : "");
    return url;
}
function objectToQueryString(query) {
    var queries = [];
    for (var name in query) {
        queries.push([name, '=', query[name]].join(''));
    }
    return queries.join('&');
}
function getProjectUrl(langIso6391, urlOriginal, query) {
    if (query === void 0) { query = {}; }
    var url = new Url_1.Url(urlOriginal);
    var flattenedUrl = helperUrl_1.flattenUrl(url);
    var queryString = objectToQueryString(query);
    var urlString = "/" + langIso6391 + "/" + flattenedUrl[langIso6391] + (queryString ? "?" + queryString : "");
    return urlString;
}
function getUrlByNuxtUrl(nuxtUrl) {
    var paths = [];
    for (var _i = 0, _a = nuxtUrl.paths; _i < _a.length; _i++) {
        var path = _a[_i];
        paths.push(path.value);
    }
    var queries = [];
    for (var _b = 0, _c = nuxtUrl.queries; _b < _c.length; _b++) {
        var query = _c[_b];
        queries.push([query.name, '=', query.value].join(''));
    }
    var url = "/" + paths.join('/') + (queries.length ? "?" + queries.join('&') : "");
    return url;
}
exports.urlService = {
    getIndex: getIndex,
    createIndex: createIndex,
    getPathValueById: getPathValueById,
    getPageByPathIds: getPageByPathIds,
    getPageById: getPageIndexById,
    objectToQueryString: objectToQueryString,
    getUrl: getUrl,
    getProjectUrl: getProjectUrl,
    getUrlByNuxtUrl: getUrlByNuxtUrl,
};
