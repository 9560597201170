import { render, staticRenderFns } from "./CatalogueArticles.vue?vue&type=template&id=5df319e4&scoped=true&"
import script from "./CatalogueArticles.vue?vue&type=script&lang=js&"
export * from "./CatalogueArticles.vue?vue&type=script&lang=js&"
import style0 from "./CatalogueArticles.vue?vue&type=style&index=0&id=5df319e4&prod&scoped=true&lang=css&"


/* normalize component */
import normalizer from "!../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "5df319e4",
  null
  
)

export default component.exports