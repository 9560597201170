<style scoped>

</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="content-wrapper none for-title">
      <div class="container_12">
        <div class="main-container main-main-container col1-layout">
          <div class="grid_12 col-main">
            <div class="page-title">
              <h1>Nettoyer aluminium : entretien table de jardin - Les Jardins Vente Privée</h1>
            </div>
            <div class="std">
              <img alt="" src="./media/wysiwyg/9.jpg">
              <p style="text-align: justify;">&nbsp;</p>
              <h2>Comment nettoyer de l'aluminium ?&nbsp;</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Une
                  <b>table de jardin en</b>
                  <b>aluminium</b>
                  et composite est fabriquée pour durer des années. Une éponge, de l’eau savonneuse et un bon coup de
                  chiffon suffisent pour l’entretien d’usage et le nettoyage des petites taches !
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">L’aluminium, comme le composite, résiste aux différentes contraintes du
                  climat. Il est à la fois résistant et inoxydable. Le composite, conçu avec des fibres de bois et de
                  résines plastiques, est imputrescible. Cette alliance facilite grandement l’entretien de votre table
                  de jardin. Découvrez toutes nos astuces pour nettoyer et entretenir votre table.
                </span>
              </p>
              <h2 style="text-align: justify;">En cas de taches de gras&nbsp;<img style="float: right; margin-left: 30px;" alt="" src="./media/wysiwyg/9a.jpg">
              </h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">De l’huile s’est renversée sur votre plateau de table composite&nbsp;?
                  N’attendez pas que la tache de gras s’y imprègne,
                  <b>enlevez directement l’excédent</b>
                  avec du papier absorbant. Prenez ensuite une éponge avec de l’
                  <b>eau chaude et du savon</b>
                  et nettoyez soigneusement toute la surface de la table. Rincez à l’eau claire, et passez un coup de
                  chiffon pour sécher et enlever les traces de gouttes d’eau.
                </span>
              </p>
              <h2 style="text-align: justify;">En cas de rayures</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Vous avez remarqué des rayures sur le composite. Prenez du
                  <b>papier de verre à grains fins</b>
                  et poncez légèrement dans le sens de la longueur de la lame. Si vous craignez les effets du ponçage,
                  faites un essai sur une partie discrète de votre table. Passez une éponge humide sur la partie poncée
                  pour enlever la poussière et séchez au chiffon.
                </span>
              </p>
              <h2 style="text-align: justify;">L’aluminium a terni</h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Pour
                  <b>faire briller l’alu</b>
                  , imprégnez un chiffon doux d’un
                  <b>mélange de</b>
                  <b>liquide vaisselle et de vinaigre blanc</b>
                  . Frottez les parties ternies et le tour est joué. Si vous avez des taches blanches sur l’aluminium,
                  prenez un
                  <b>tampon de laine et du jus de citron</b>
                  . Évitez surtout les produits à base de chlore ou d’hydrocarbure qui pourraient le détériorer et rayez
                  de votre liste les produits abrasifs qui le rayeraient.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <h2>Les précautions à prendre</h2>
              <p>
                <span style="font-size: large;">Quand vous nettoyez votre
                  table de jardin et vos
                  chaises
                  , faites-le à des heures où le soleil ne tape pas trop fort, en début de matinée ou en fin
                  d’après-midi. Si vous deviez le faire à un moment de grand soleil, choisissez un coin ombragé.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p>
                <span style="font-size: large;">Équipez-vous d’une brosse spécifique dédiée uniquement à votre table
                  extérieure pour la frotter.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <img alt="" src="./media/wysiwyg/9c.jpg">&nbsp; &nbsp; &nbsp; &nbsp;
                &nbsp; &nbsp;<span style="font-size: large;">
                <img alt="" src="./media/wysiwyg/9b.jpg">
              </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">En hiver quand vous n’utilisez pas vos meubles de jardin, pensez à les
                  <b>protéger</b>
                  . Si vous avez de la place dans votre maison, rangez-les à l’abri dans un endroit sec et aéré, sinon
                  recouvrez-les d’une bâche protectrice à l’abri du vent, dans l’attente des beaux jours.
                </span>
              </p>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../../function/getH1";
import {Term} from "../../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../../@common/constant/PROJECT_URL";
import {PATHS} from "../../../../@common/constant/PATHS";

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    },
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FAQ-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
