"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.apiClientConfig = exports.apiServerConfig = void 0;
var ENV_1 = require("../../core/@common/constant/ENV");
var config_1 = require("../config");
if (config_1.config.env === ENV_1.ENV.PRODUCTION) {
    exports.apiServerConfig = {
        domain: 'api.lesjardins-int.com',
        port: 443,
        protocol: 'https',
        scope: 'shop@1.0.0',
    };
    exports.apiClientConfig = {
        domain: 'lesjardins-int.com',
        port: 443,
        protocol: 'https',
    };
}
