<style scoped lang="scss">

@import "../../scss/color";
@import "../../scss/size";

.HomeTeck {
}

.HomeTeck-desktop {
  padding-top: 245px - 35px;
}

.HomeTeck-tablet {
  padding-top: 80px;
}

.HomeTeck-mobile {

}

.HomeTeck--container {
  background-color: $primaryLight4;
  display: flex;
}

.HomeTeck-desktop .HomeTeck--container {
  height: 800px;
}

.HomeTeck-tablet .HomeTeck--container {
  height: 800px;
}

.HomeTeck-mobile .HomeTeck--container {
  flex-direction: column;
  align-items: center;
  text-align: center;
}


/**/

$teckLeftTop: 100px;

.HomeTeck--left {
  position: relative;
}

$left: 45%;

.HomeTeck-desktop .HomeTeck--left {
  height: calc(100% + #{$teckLeftTop});
  width: $left;
  min-width: $left;
  flex: $left;
  top: -$teckLeftTop;
}

.HomeTeck-tablet .HomeTeck--left {
  height: 100%;
  width: 50%;
  max-width: 50%;
  min-width: 50%;
  flex: 0.5;
}

.HomeTeck-mobile .HomeTeck--left {
  height: 65vh;
  width: 100%;
}

/**/

$square: 100px;

.HomeTeck--teckLeft {
  height: 100%;
  overflow: hidden;
  position: relative;
}

.HomeTeck-desktop .HomeTeck--teckLeft {
  width: 100%;
}

.HomeTeck-tablet .HomeTeck--teckLeft {
  width: 100%;
}

.HomeTeck-mobile .HomeTeck--teckLeft {
  width: 100%;
}

/**/

.HomeTeck--teckLeftImg {
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.HomeTeck-desktop .HomeTeck--teckLeftImg {
  height: calc(100% + 100px);
  min-height: calc(100% + 100px);
}

.HomeTeck-tablet .HomeTeck--teckLeftImg {
  height: calc(100% + 100px);
  min-height: calc(100% + 100px);
}

.HomeTeck-mobile .HomeTeck--teckLeftImg {
  height: calc(100% + 100px);
  min-height: calc(100% + 100px);
}

/**/

.HomeTeck--wood {
  position: absolute;
}

.HomeTeck-desktop .HomeTeck--wood {
  bottom: - 50px;
  right: - (250px / 2);
  width: 250px;
  height: 320px;
}

@media screen and (max-width: $menu-image-screen) {
  .HomeTeck-desktop .HomeTeck--wood {
    right: - (250px / 2);
    width: 250px;
    height: 300px;
  }
}

.HomeTeck-tablet .HomeTeck--wood {
  bottom: -50px;
  right: - (200px / 2);
  width: 200px;
  height: 250px;
}

.HomeTeck-mobile .HomeTeck--wood {
  bottom: 0px;
  right: 20px;
  width: 80px;
  height: 80px;
}

/**/

.HomeTeck--right {
}

.HomeTeck-desktop .HomeTeck--right {
  padding-top: 100px;
  flex: 100% - $left;
  width: 100% - $left;
  min-width: 100% - $left;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.HomeTeck-tablet .HomeTeck--right {
  padding-top: 100px;
  width: 50%;
  max-width: 50%;
  min-width: 50%;
  flex: 0.50;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.HomeTeck-mobile .HomeTeck--right {
  width: 100%;
}

/**/

.HomeTeck--infos {

}

.HomeTeck-desktop .HomeTeck--infos {
  flex: 0.7;
  width: 70%;
  min-width: 70%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-direction: column;
}

.HomeTeck-tablet .HomeTeck--infos {

}

.HomeTeck-mobile .HomeTeck--infos {
  padding: 0 20px;
}

/**/

$infoLeft: 180px;

.HomeTeck--info {
}

.HomeTeck-desktop .HomeTeck--info {

}

.HomeTeck-tablet .HomeTeck--info {
  padding: 0 $infoLeft / 2;
}

.HomeTeck-mobile .HomeTeck--info {

}

/**/

.HomeTeck--subTitle {
  font-family: 'CommutersSansBold', sans-serif;
  letter-spacing: 0.25px;
  color: $primary;
  text-transform: uppercase;
}

.HomeTeck-desktop .HomeTeck--subTitle {
  font-size: $desktopTitle4FontSize;
  padding-bottom: 65px - 17px;
}

.HomeTeck-tablet .HomeTeck--subTitle {
  font-size: $tabletTitle4FontSize;
  padding-bottom: 65px - 17px;
}

.HomeTeck-mobile .HomeTeck--subTitle {
  font-size: $mobileTitle4FontSize;
  padding-top: 100px;
  padding-bottom: 20px;
}

/**/

.HomeTeck--title {
  font-family: 'AmbroiseRegular', sans-serif;
  color: #31302F;
}

.HomeTeck-desktop .HomeTeck--title {
  font-size: 45px;
  padding-bottom: 50px - 22px;
}

.HomeTeck-tablet .HomeTeck--title {
  font-size: 45px;
  padding-bottom: 50px - 22px;
}

.HomeTeck-mobile .HomeTeck--title {
  font-size: 30px;
  padding-bottom: 50px - 31px;
}

/**/

.HomeTeck--description {
  font-family: 'AeonikRegular', sans-serif;
  letter-spacing: 0.25px;
  color: #31302F;
}

.HomeTeck-desktop .HomeTeck--description {
  max-width: 400px;
  min-width: 400px;
  font-size: 16px;
  line-height: 28px;
}

.HomeTeck-tablet .HomeTeck--description {
  font-size: 16px;
  line-height: 28px;
}

.HomeTeck-mobile .HomeTeck--description {
  font-size: 15px;
  line-height: 28px;
}

/**/

.HomeTeck--url {
  display: flex;
  width: 100%;
  box-sizing: border-box;
}

.HomeTeck-desktop .HomeTeck--url {
  padding-top: 127px;
  padding-right: 75px;
  justify-content: flex-end;
}

.HomeTeck-tablet .HomeTeck--url {
  padding-top: 127px;
  padding-right: 75px;
  justify-content: flex-end;
}

.HomeTeck-mobile .HomeTeck--url {
  padding: 32px 20px 0px 20px;
}

.HomeTeck-mobile .HomeTeck--url ::v-deep .Link {
  width: 100%;
  display: block;
}

/**/

.HomeTeck--buttonCircle {
  justify-content: flex-end;
}

.HomeTeck-desktop .HomeTeck--buttonCircle {
  display: flex;
}

.HomeTeck-tablet .HomeTeck--buttonCircle {
  display: flex;
}

.HomeTeck-mobile .HomeTeck--buttonCircle {
  display: none;
}

.HomeTeck--buttonCircle ::v-deep .ButtonCircle {
  background-color: $primaryLight4;
  color: #31302F;
}

.HomeTeck--buttonCircle ::v-deep .CircleProgress--progressBarre {
  stroke: #31302F;
}

.HomeTeck--buttonCircle ::v-deep .ButtonCircle--vector {
  background-color: $primaryLight4;
}

.HomeTeck--buttonCircle ::v-deep .Vector {
  fill: #31302F;
}

/**/

.HomeTeck--buttonText {
  color: #31302F;
  border: solid 1px #31302F;
  border-radius: 100px;
  width: calc(100%);
  height: 48px;
  justify-content: center;
  align-items: center;
  font-family: 'MontserratSemiBold', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  letter-spacing: 0.25px;
  box-sizing: border-box;
  width: 100%;
  white-space: nowrap;
  flex: 1;
}

.HomeTeck-desktop .HomeTeck--buttonText {
  display: none;
}

.HomeTeck-tablet .HomeTeck--buttonText {
  display: none;
}

.HomeTeck-mobile .HomeTeck--buttonText {
  display: flex;
}

/**/

.HomeTeck--woodRight {
  position: relative;
}

.HomeTeck-desktop .HomeTeck--woodRight {
  flex: 0.3;
  width: 30%;
  max-height: 30%;
  padding-bottom: 30%;
}

.HomeTeck-tablet .HomeTeck--woodRight {
  display: none;
}

.HomeTeck-mobile .HomeTeck--woodRight {
  width: 50%;
  max-height: 50%;
  padding-bottom: 50%;
}

.HomeTeck--woodRight1 {
  overflow: hidden;
  position: absolute;
}

.HomeTeck-desktop .HomeTeck--woodRight1 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.HomeTeck-tablet .HomeTeck--woodRight1 {
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.HomeTeck-mobile .HomeTeck--woodRight1 {
  top: 50%;
  left: 0;
  width: 100%;
  height: 100%;
}

.HomeTeck--teckRightImg {
  width: 100%;
  height: 100%;
  height: calc(100% + #{$square});
  min-height: calc(100% + #{$square});
  position: absolute;
  top: 0;
  left: 0;
}
</style>

<template>
  <div class="HomeTeck" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <div class="HomeTeck--container">
        <div class="HomeTeck--left">
          <div class="HomeTeck--teckLeft">
            <ScrollParallax :distance="50" :reverse="true">
              <div class="HomeTeck--teckLeftImg">
                <Picture :src="img.teckLeft"></Picture>
              </div>
            </ScrollParallax>
          </div>
          <div class="HomeTeck--wood">
            <ScrollParallax :distance="woodLeftDistance" :reverse="false">
              <Picture :src="img.wood"></Picture>
            </ScrollParallax>
          </div>
        </div>
        <div class="HomeTeck--right">
          <div class="HomeTeck--infos">
            <div class="HomeTeck--info">
              <div class="HomeTeck--subTitle">
                <AnimationTitle :fully="fully">
                  <Term :term="lang.term(687)"></Term>
                </AnimationTitle>
              </div>
              <div class="HomeTeck--title">
                <AnimationTitle :fully="fully">
                  <Term :term="lang.term(688)"></Term>
                </AnimationTitle>
              </div>
              <div class="HomeTeck--description">
                <AnimationTitle :fully="fully">
                  <Term
                      :term="lang.term(689)"></Term>
                </AnimationTitle>
              </div>
            </div>
            <div class="HomeTeck--url">
              <Link :href="url()">
                <div class="HomeTeck--buttonCircle">
                  <ButtonCircle :term="lang.term(690)">
                  </ButtonCircle>
                </div>

                <div class="HomeTeck--buttonText">
                  <Term :term="lang.term(691)"></Term>
                </div>
              </Link>
            </div>
          </div>
          <div class="HomeTeck--woodRight">
            <div class="HomeTeck--woodRight1">
              <ScrollParallax :distance="woodRightDistance" :reverse="true">
                <div class="HomeTeck--teckRightImg">
                  <Picture :src="img.woodRight"></Picture>
                </div>
              </ScrollParallax>
            </div>
          </div>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {urlService} from "../../../@common/service/urlService";
import {FILTER_OPTION} from "../../../@common/constant/FILTER_OPTION";
import {PATHS} from "../../../@common/constant/PATHS";

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    },
    breakPoints: {
      type: Array,
      default: () => ([])
    },
    verticalBreakPoints: {
      type: Array,
      default: () => ([])
    },
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint(),
          breakPoints: this.breakPoints,
          verticalBreakPoints: this.verticalBreakPoints,
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      img: {
        teckLeft: require('../../../@common/assets/home/teck/teck-left-4.jpg'),
        wood: require('../../../@common/assets/home/teck/wood.jpg'),
        woodRight: require('../../../@common/assets/home/teck/wood-right.jpg'),
      },
      state: {},
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      if (this.state.animated) classes.push(`HomeTeck-animated`)
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`HomeTeck-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`HomeTeck-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`HomeTeck-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`HomeTeck-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`HomeTeck-desktopY`)
      return classes
    },
    woodLeftDistance() {
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) return 100
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) return 100
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) return 80
    },
    woodRightDistance() {
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) return 100
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) return 100
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) return 100
    },
    fully() {
      return this.props.Responsive.breakPoint.name === SCREEN.DESKTOP
    },
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    url() {
      return urlService.getUrl(this.lang.lang.iso6391, [PATHS.CATALOG], {[FILTER_OPTION.MATERIAL_IDS]: 8})
    }
  }
}
</script>
