"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.googleConfigPublic = void 0;
var ENV_1 = require("../../core/@common/constant/ENV");
var config_1 = require("../config");
if (config_1.config.env === ENV_1.ENV.PRODUCTION) {
    exports.googleConfigPublic = {
        map: {
            apiKey: 'AIzaSyAnPFQR2WkDUkT-cxOV-noc07XyfQ6d77k',
        },
        analytics: {
            id: 'G-2X30TD0RDH',
            url: "https://www.googletagmanager.com/gtag/js?id="
        },
        recaptcha: {
            url: "https://www.google.com/recaptcha/api.js",
            publicKey: "6LfmFqQkAAAAACr5XkbIuRTu44T3FXWk81atgkiH"
        },
        gtm: {
            id: 'GTM-M3FT277'
        }
    };
}
