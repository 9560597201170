<style scoped>
.FileDownload {
}


.FileDownload--files {
  padding: 0 50px 40px 50px;
  background: rgba(0, 0, 0, 0.03);
}

.FileDownload--fileDrop {
  position: relative;
  height: 200px;
  width: 100%;
  border: dashed 2px rgba(0, 0, 0, 0.25);
  display: flex;
  justify-content: center;
  align-items: center;
  transition: border 300ms;
  margin-bottom: 20px;
}

.FileDownload--fileDrop:hover {
  border: dashed 2px rgba(0, 0, 0, 1);
}

.FileDownload--fileDrop-dragged {
  border: dashed 2px rgba(0, 0, 0, 1);
}

.FileDownload--filesDropInfo {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.FileDownload--filesDropInfoIcon {
  width: 24px;
  height: 24px;
  margin-bottom: 10px;
}

.FileDownload--filesDropInfoIcon ::v-deep .Vector svg {
  fill: #000000;
}

.FileDownload--filesDropInfoText {
  color: #000000;
}

.FileDownload--fileDrop input {
  opacity: 0;
  position: absolute;
  top: 0;
  left: 0;
  cursor: pointer;
  display: block;
  width: 100%;
  height: 100%;
}

.FileDownload--file {
  display: flex;
  align-items: center;
  justify-content: space-between;
  border-bottom: solid 1px rgba(0, 0, 0, 0.05);
  padding: 20px 0;
}

.FileDownload--file:last-child {
  border-bottom: none;
}

.FileDownload--fileLeft {
  display: flex;
  align-items: center;
}

.FileDownload--fileLeftFile {
}

.FileDownload--fileLeftFileImage {
  width: 50px;
  height: 50px;
  box-sizing: border-box;
  padding: 4px;
  border-radius: 4px;
  overflow: hidden;
  background: rgba(0, 0, 0, 0.05);
}

.FileDownload--fileLeftFileDefault {
  background: rgba(0, 0, 0, 0.05);
  width: 50px;
  height: 50px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 4px;
}

.FileDownload--fileLeftFileDefaultIcon {
  width: 24px;
  height: 24px;
}

.FileDownload--fileLeftFileDefaultIcon ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 0.25);
  transition: fill 300ms;
}

.FileDownload--file:hover .FileDownload--fileLeftFileDefaultIcon ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 0.5);
}

.FileDownload--fileLeftInfo {
  margin-left: 20px;
}

.FileDownload--fileLeftInfoName {
  font-family: 'ComfortaaRegular', sans-serif;
  margin-bottom: 10px;
  font-size: 12px;
  word-break: break-all !important;
}

.FileDownload--fileLeftInfoName:first-letter {
  text-transform: capitalize;
}

.FileDownload--fileLeftInfoMeta {
  font-family: 'ComfortaaBold', sans-serif;
  font-size: 10px;
  color: rgba(0, 0, 0, 0.75);
}

.FileDownload--fileRight {
  display: flex;
  align-items: center;
  position: relative;
}

.FileDownload--fileRightOption {
  width: 24px;
  height: 24px;
  cursor: pointer;
  margin-left: 10px;
  background: rgba(0, 0, 0, 0);
  border-radius: 50px;
  padding: 4px;
  transition: background 300ms;
}

.FileDownload--fileRightOption ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 0.5);
  transition: fill 300ms;
}

.FileDownload--fileRightOption:hover ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 1);
}

.FileDownload--fileRightOption:hover {
  background: rgba(0, 0, 0, 0.07);
}

.FileDownload--file:hover .FileDownload--fileRightOption {
  background: rgba(0, 0, 0, 0.07);
}

.FileDownload--file:hover .FileDownload--fileRightOption ::v-deep .Vector svg {
  fill: rgba(0, 0, 0, 1);
}

.FileDownload--fileRightOptionPourcent {
  font-family: 'ComfortaaBold', sans-serif;
  font-size: 9px;
  color: rgba(0, 0, 0, 1);
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: absolute;
  right: 0;
  top: 0;
}

.FileDownload--fileRightOptionLoader {
  width: 30px;
  height: 30px;
}

.FileDownload--fileRightOptionLoader ::v-deep .el-progress-circle__path {
  stroke: #000000;
}
</style>

<template>
  <div class="FileDownload">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <template v-for="(file, index_file) in state.files">
        <div class="FileDownload--file">
          <div class="FileDownload--fileLeft">
            <div class="FileDownload--fileLeftFile">
              <template v-if="isImage(file)">
                <a :href="file.url" target="_blank">
                  <div class="FileDownload--fileLeftFileImage">
                    <Picture
                        :display="PICTURE_DISPLAY.AUTO"
                        :file="file">
                    </Picture>
                  </div>
                </a>
              </template>
              <template v-else>
                <a :href="file.url" target="_blank">
                  <div class="FileDownload--fileLeftFileDefault">
                    <div class="FileDownload--fileLeftFileDefaultIcon">
                      <Vector :svg="icon.insert_drive_file"></Vector>
                    </div>
                  </div>
                </a>
              </template>
            </div>
            <div class="FileDownload--fileLeftInfo">
              <div class="FileDownload--fileLeftInfoName">{{ file.name }}</div>
              <div class="FileDownload--fileLeftInfoMeta">{{ file.ext.toUpperCase() }} - {{
                  fileUnit(file.octet)
                }}
              </div>
            </div>
          </div>
          <div class="FileDownload--fileRight">
            <Popover position="bottom-end">
              <template slot="default">
                <a @click="() => download(file)" style="text-decoration: none;">
                  <List :svg="icon.cloud_download" text="télécharger"/>
                </a>
                <List v-if="updatable" :svg="icon.edit" text="modifier" :onClick="() => onClickUpdate(file)"/>
                <List :svg="icon.delete_forever" text="supprimer" :onClick="() => onClickDelete(index_file)"/>
              </template>
              <template slot="reference">
                <div class="FileDownload--fileRightOption">
                  <Tooltip text="options" position="left">
                    <Vector :svg="icon.more_horiz"></Vector>
                  </Tooltip>
                </div>
              </template>
            </Popover>
          </div>
        </div>
      </template>

    </Responsive>
  </div>
</template>

<script>

import ApiDownload from '@bbx/api~master/core/delta/ApiDownload'

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {Unit} from "@bbx/unit~master/core/ts/Unit";
import SIZE from '@bbx/unit~master/core/constant/SIZE'

import {apiClient} from "../../service/apiClientService";
import {PICTURE_DISPLAY} from "../../../@common/constant/PICTURE_DISPLAY";
import {getS3PathFromFile} from "../../../@common/function/getS3Path";
import {sendError} from "../../function/sendError";

export default {

  props: {
    files: {
      type: Array,
      default: () => ([])
    },
    onClickDelete: {
      type: Function,
      default: () => (index_file) => {
      }
    },
    updatable: {
      type: Boolean,
      default: () => true
    },
    onClickUpdate: {
      type: Function,
      default: () => (file) => {
      }
    },
  },
  data() {
    return {
      PICTURE_DISPLAY,
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        insert_drive_file: require('@bbx/vector~master/core/assets/svg/material/insert_drive_file.svg'),
        cloud_download: require('@bbx/vector~master/core/assets/svg/material/cloud_download.svg'),
        edit: require('@bbx/vector~master/core/assets/svg/material/edit.svg'),
        delete_forever: require('@bbx/vector~master/core/assets/svg/material/delete_forever.svg'),
        more_horiz: require('@bbx/vector~master/core/assets/svg/material/more_horiz.svg'),
      },
      state: {
        files: []
      },
    }
  },
  watch: {
    'files': {
      handler: function () {
        this.selectFiles().catch(sendError)
      },
      deep: true
    },
  },
  computed: {},
  beforeMount() {
  },
  mounted() {
    this.selectFiles().catch(sendError)
  },
  beforeDestroy() {
  },
  methods: {

    async selectFiles() {
      this.state.files = [];
      // await Promise.all(this.files.map(file => (async () => {
      //   if (!file.url) {
      //     const apiDownload = await apiClient.download(new ApiDownload({
      //       ext: file.ext,
      //       id: file.uuid,
      //       name: file.name
      //     }));
      //     file.url = apiDownload.url;
      //   }
      // })()));
      this.state.files = this.files;
    },

    /**
     * @param {File} file
     * @returns {boolean}
     */
    isImage(file) {

      // const unit = new Unit();
      //
      // const {
      //   name,
      //   value
      // } = unit.size({octet: file.octet})

      // if (![
      //   SIZE.OCTET,
      //   SIZE.KILO_OCTET
      // ].includes(name) || (name === SIZE.MEGA_OCTET && value > 5)) return false;

      return [
        "jpg",
        "jpeg",
        "png",
        "webp",
        "gif",
        "jfif"
      ].includes(file.ext.toLowerCase());
    },
    /**
     *
     * @param {File} file
     * @returns string
     */
    async download(file) {

      // const apiDownload = await apiClient.download(new ApiDownload({
      //   ext: file.ext,
      //   id: file.uuid,
      //   name: file.name,
      //   attachment: true
      // }));

      const link = document.createElement('a');
      link.setAttribute('href', getS3PathFromFile(file))
      // link.setAttribute('target', '_blank')
      link.click();
    },
    /**
     *
     * @param {number} octet
     * @returns {string}
     */
    fileUnit(octet) {
      const unit = new Unit()
      const {
        name,
        value
      } = unit.size({octet: octet})
      return `${value} ${name}`;
    },
  }
}
</script>
