<style scoped>
.ShopForm {
}

.ShopForm--row {
  display: flex;
}

.ShopForm--space {
  width: 30px;
  min-width: 30px;
}

.ShopForm--row ::v-deep .InputText {
  flex: 1;
}

.ShopForm--row ::v-deep .InputNumber {
  flex: 1;
}

.ShopForm--row ::v-deep .InputSelect {
  flex: 1;
}
</style>

<template>
  <div class="ShopForm" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">

      <InputSection :svg="icon.subject" :term="lang.term(417)">
        <InputText :value="state.shop.name"
                   :mandatory="true"
                   :onChangeValue="v => state.shop.name = v"
                   :label="lang.term(420)"
                   :error="form.display(`name`)">
        </InputText>
        <br>
        <InputText v-for="sage in state.shop.sages"
                   :key="sage.id"
                   :value="sage.uuid"
                   :onChangeValue="v => sage.uuid = v"
                   :label="lang.term(418)"
                   :error="form.display(`sages[0].uuid`)">
        </InputText>
        <br>
        <InputSelect :items="state.shopTypeLists"
                     :values="state.shop.shopTypeLists"
                     :max="2"
                     :label="lang.term(411)"
                     :onChangeValues="v => state.shop.shopTypeLists = v"
                     :error="form.display(`shopTypeLists`)">
        </InputSelect>

      </InputSection>


      <AddressForm v-for="(address, index_address) in state.shop.addresses"
                   :key="address.id"
                   :form="form"
                   :address="address"
                   :prefixError="`addresses[${index_address}].`"
                   :onAddressChange="v => state.shop.addresses[index_address] = v"
                   :inputHiddens="['favorite', 'countries', 'name']">
      </AddressForm>
      <br>
      <br>
      <InputSwitch :value="state.shop.visibleOnMap"
                   :label="lang.term(587)"
                   :onChangeValue="v => state.shop.visibleOnMap = v">
      </InputSwitch>


      <InputSection :svg="icon.phone"
                    :term="lang.term(419)"
                    :multiple="true"
                    :addable="true"
                    :items="state.shop.phoneNumbers"
                    :onClickAdd="() => addPhoneNumber(state.shop.phoneNumbers)"
                    :onItemChange="v => state.shop.phoneNumbers = v">
        <template v-slot:default="{item, index}">
          <InputText :value="item.number"
                     :onChangeValue="v => item.number = v"
                     :error="form.display(`phoneNumbers[${index}].number`)">
          </InputText>
        </template>
      </InputSection>


      <InputSection :svg="icon.people"
                    :term="lang.term(588)"
                    :multiple="true"
                    :addable="true"
                    :items="state.shop.users"
                    :onClickAdd="() => addUser(state.shop.users)">
        <template v-slot:default="{item, index}">
          <InputSelect :remotable="true"
                       :onRemoteChange="remoteUsers"
                       :items="state.users.length ? state.users : [item]"
                       :values="[item]"
                       :display="displayUser"
                       :onChangeValue="user => onChangeValuesUser(index, user)"
                       :loading="state.loadingUser">
          </InputSelect>
        </template>
      </InputSection>


      <InputSection :svg="icon.verified"
                    :term="lang.term(589)">
        <InputSwitch :value="state.shop.autoSagePromo"
                     :label="lang.term(590)"
                     tag="sage"
                     :onChangeValue="v => state.shop.autoSagePromo = v">
        </InputSwitch>
        <br>
        <br>
        <template v-for="(promotion, index_promotion) in state.shop.promotions">
          <div class="ShopForm--row">
            <InputNumber :value="promotion.percentage"
                         :disabled="!promotion.active || state.shop.autoSagePromo"
                         :mandatory="!state.shop.autoSagePromo && promotion.active"
                         :onChangeValue="v => promotion.percentage = v"
                         :label="lang.term(591)"
                         :decimal="10"
                         :error="form.display(`promotions[${index_promotion}].percentage`)">
            </InputNumber>
            <div class="ShopForm--space"></div>
            <template v-for="(promotionCode, index_promotionCode) in promotion.promotionCodes">
              <InputText :value="promotionCode.code"
                         :disabled="!promotion.active || state.shop.autoSagePromo"
                         :mandatory="!state.shop.autoSagePromo && promotion.active"
                         :onChangeValue="v => promotionCode.code = v"
                         :label="lang.term(592)"
                         :error="form.display(`promotions[${index_promotion}].promotionCodes[${index_promotionCode}].code`)">
              </InputText>
            </template>
          </div>
          <br>
          <br>
          <InputSwitch :value="promotion.active"
                       :onChangeValue="v => promotion.active = v"
                       :label="lang.term(593)">
          </InputSwitch>
        </template>
      </InputSection>


      <InputSection :svg="icon.credit_card"
                    :term="lang.term(594)">
        <template v-for="(deposit, index_deposit) in state.shop.deposits">
          <InputNumber :value="deposit.percentage"
                       :disabled="!deposit.active"
                       :mandatory="deposit.active"
                       :onChangeValue="v => deposit.percentage = v"
                       :label="lang.term(595)"
                       :decimal="10"
                       :error="form.display(`deposits[${index_deposit}].percentage`)">
          </InputNumber>
          <br>
          <InputLabel :mandatory="deposit.active" :term="lang.term(596)"></InputLabel>
          <br>
          <div class="ShopForm--row">
            <InputNumber :value="deposit.timeLimit"
                         :onChangeValue="v => deposit.timeLimit = v"
                         :decimal="10"
                         :disabled="!deposit.active"
                         :error="form.display(`deposits[${index_deposit}].timeLimit`)">
            </InputNumber>
            <div class="ShopForm--space"></div>
            <InputSelect :items="periods"
                         :values="deposit.periods"
                         :disabled="!deposit.active"
                         :onChangeValues="v => deposit.periods = v"
                         :loading="state.loadingPeriod"
                         :error="form.display(`deposits[${index_deposit}].periods`)">
            </InputSelect>
          </div>
          <br>
          <br>
          <InputSwitch :value="deposit.active"
                       :label="lang.term(597)"
                       :onChangeValue="v => deposit.active = v">
          </InputSwitch>
        </template>
      </InputSection>

    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {Shop} from "../../../@common/delta/database/Shop";
import {apiClient} from "../../service/apiClientService";
import {PhoneNumber} from "../../../@common/delta/database/PhoneNumber";
import {User} from "../../../@common/delta/database/User";
import {USER_SEARCH_ID} from "../../../@common/constant/USER";
import {toCapitalyze} from "../../../@common/function/toCapitalyze";

import selectShopTypeList from "../../../@common/api/shop_type_list/selectShopTypeList";
import selectUsers from "../../../@common/api/user/selectUsers";
import selectPeriods from "../../../@common/api/period/selectPeriods";
import {ROLE} from "../../../@common/constant/ROLE";
import {sendError} from "../../function/sendError";

export default {

  props: {
    shop: {
      default: () => new Shop()
    },
    onShopChange: {
      type: Function,
      default: () => (shop) => {
      }
    },
    form: {
      default: () => new ApiResponseForm()
    },
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
        style: require('@bbx/vector~master/core/assets/svg/material/style.svg'),
        subject: require('@bbx/vector~master/core/assets/svg/material/subject.svg'),
        phone: require('@bbx/vector~master/core/assets/svg/material/phone.svg'),
        people: require('@bbx/vector~master/core/assets/svg/material/people.svg'),
        verified: require('@bbx/vector~master/core/assets/svg/material/verified.svg'),
        credit_card: require('@bbx/vector~master/core/assets/svg/material/credit_card.svg'),
      },
      state: {
        /**
         * @type boolean
         */
        loadingShopTypeList: false,
        /**
         * @type boolean
         **/
        loadingUser: false,
        /**
         * @type boolean
         **/
        loadingPeriod: false,
        /**
         * @type User[]
         */
        users: [],
        /**
         * @type ShopTypeList[]
         */
        shopTypeLists: [],
        /**
         * @type Period[]
         */
        periods: [],
        /**
         * @type Shop
         */
        shop: new Shop(this.shop),
      },
    }
  },
  watch: {
    'shop': {
      handler(v) {
        this.state.shop = v
      },
      deep: true
    },
    'state.shop': {
      handler(v) {
        this.onShopChange(v)
      },
      deep: true
    },
  },
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`ShopForm-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`ShopForm-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`ShopForm-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`ShopForm-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`ShopForm-desktopY`)
      return classes
    },
    periods() {
      let map = {
        1: 'Jour',
        2: 'Semaine',
        3: 'Mois',
        4: 'Année',
      }
      let periods = []
      this.state.periods.forEach(period => {
        periods.push({
          id: period.id,
          value: map[period.id]
        })
      })
      return periods
    }
  },
  beforeMount() {
    this.selectShopTypeList().catch(sendError)
    this.selectPeriod().catch(sendError)
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    async selectShopTypeList() {
      this.state.loadingShopTypeList = true
      const filter = {
        active: true,
      }
      apiClient.do(selectShopTypeList, selectShopTypeList.with(filter)).then((res) => {
        this.state.shopTypeLists = res.data;
        this.state.loadingShopTypeList = false
      }).catch(err => {
        this.state.loadingShopTypeList = false
        sendError(err)
      });
    },
    async selectPeriod() {
      this.state.loadingPeriod = true
      apiClient.do(selectPeriods, selectPeriods.with()).then((res) => {
        this.state.periods = res.data;
        this.state.loadingPeriod = false
      }).catch(err => {
        this.state.loadingPeriod = false
        sendError(err)
      });
    },
    /**
     * @param {PhoneNumber[]} phoneNumbers
     */
    addPhoneNumber(phoneNumbers) {
      const phoneNumber = new PhoneNumber()
      phoneNumbers.push(phoneNumber)
    },
    /**
     * @param {User[]} users
     */
    addUser(users) {
      const user = new User()
      users.push(user)
    },

    remoteUsers(query) {
      if (!query) return
      if (this.state.loadingUser) return
      this.state.loadingUser = true
      apiClient.do(selectUsers, selectUsers.with({
        active: true,
        $search: query,
        $searchType: USER_SEARCH_ID.EMAIL,
        roles: [
          {
            id: ROLE.SELLER
          }
        ]
      })).then(res => {
        this.state.users = res.data
        this.state.loadingUser = false
      }).catch(err => {
        this.state.loadingUser = false
        sendError(err);
      });
    },

    onChangeValuesUser(index, user) {
      this.state.shop.users[index] = new User(user)
      this.state.users = []
    },

    /**
     * @param {User} user
     */
    displayUser(user) {
      let names = []
      if (user.firstName.trim()) names.push(toCapitalyze(user.firstName.trim()))
      if (user.lastName.trim().toUpperCase()) names.push(user.lastName.trim().toUpperCase())
      let name = names.join(' ')
      let displays = []
      if (name) displays.push(name)
      displays.push(user.email)
      return displays.join(' - ')
    }
  }
}
</script>
