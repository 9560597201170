<style scoped>
.Tooltip {
}

</style>

<template>
  <div class="Tooltip">
    <el-tooltip :style="style_root" :content="text" :placement="position">
      <slot/>
    </el-tooltip>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';

export default {

  props: {
    text: {
      type: String,
      default: () => ''
    },
    position: {
      type: String,
      default: () => 'bottom'
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      state: {
        /**
         * @type boolean
         */
        ssr: true,
      },
    }
  },
  watch: {},
  computed: {
    style_root() {
      let style = {
        // width: '100%',
        // height: '100%',
        // display: 'inline-block',
        // fontFamily: 'ComfortaaRegular',
        // letterSpacing: '1px',
        // textTransform: 'uppercase',
        // fontSize: '10px',
      }
      return style
    },
  },
  beforeMount() {
  },
  mounted() {

  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
