<style scoped>

</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="content-wrapper none for-title">
      <div class="container_12">
        <div class="main-container main-main-container col1-layout">
          <div class="grid_12 col-main">
            <div class="page-title">
              <h1>5 astuces faciles, pratiques et qui rendront votre jardin ultra-irrésistible</h1>
            </div>
            <div class="std">
              <p style="text-align: justify;">
                <img alt="" src="./media/wysiwyg/3.jpg">
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">Espace de vie à part entière, le jardin est un trésor à mettre en
                  valeur. Quand l’été frappe à sa porte, il est temps de bichonner et de prendre soin de cet espace
                  privilégié. Décorer son jardin en été est indispensable non seulement pour bien accueillir ses invités
                  mais surtout pour s’y sentir à son aise. Voici 5 astuces faciles, pratiques et qui rendront votre
                  jardin ultra-irrésistible.
                </span>
              </p>
              <h2 style="margin-top: 22px;   clear: left; text-align: justify;">
                Bien préparer tout l'espace
                <img style="float: right; margin-left: 30px;" alt="" src="./media/wysiwyg/3a.jpg">
              </h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Pour valoriser l’ambiance que vous souhaitez créer, chaque détail
                  compte. En premier lieu, il est essentiel de préparer tout l’espace, du gazon en passant par la
                  clôture jusqu’au point d’eau. L’idée est de redonner un nouveau décor au jardin, surtout si vous
                  souhaitez installer un
                  salon de
                  jardin
                  . Dans ce cas, se débarrasser des vieux objets parait une bien meilleure idée. Une fois prêt, l’espace
                  peut accueillir tous les détails servant à la nouvelle décoration.
                </span>
              </p>
              <h2 style="margin-top: 22px;   clear: left; text-align: justify;">
                Enjoliver les espaces végétaux
              </h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Pour bien décorer son jardin en été, il est primordial de soigner les
                  espaces végétaux. Pas de jardin sans espace vert. Pour ce faire, quelques opérations sont obligatoires
                  : tondre les gazons, arroser les fleurs, enlever les feuilles mortes et planter de nouvelles
                  végétations.Puisque l’été rime avec les couleurs, préférez les arbustes, le petit potager et les
                  arbres à fleurs colorées et panachées.Pour un jardin harmonieux et agréable à regarder, vous pouvez
                  compartimenter les végétaux : les fleurs dans leur coin, le potager dans son propre espace et les
                  arbustes dans un autre espace limité.
                  <br>
                </span>
              </p>
              <h2 style="margin-top: 22px;   clear: left;">Jouer avec
                l'éclairage extérieur&nbsp;<br>
                <img style="margin-left: 30px; float: right;" alt="" src="./media/wysiwyg/3b.jpg">
              </h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Quand il fait chaud, on aimerait rester à l’extérieur même en début de
                  soirée. Pour cela, votre jardin aura besoin d’éclairages. Quelle que soit votre ambiance préférée, les
                  luminaires constituent les meilleurs outils pour conférer du charme et pour éclairer tout l’espace.
                  Guirlandes lumineuses, appliques murales, arcs lumineux, bougeoirs et bougies ou lampadaires, tout est
                  possible. Avec ce large éventail de luminaires, votre jardin se transformera en un véritable lieu
                  confortable et chaleureux. Attention quand même à ne pas tous les utiliser. L’effet minimaliste est la
                  tendance actuellement.
                </span>
              </p>
              <h2 style="margin-top: 22px;   clear: left; text-align: justify;">
                Choisir le bon revêtement de sol
              </h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">Pour rehausser une atmosphère typiquement zen et relaxante, deux types
                  de revêtement sont les plus appréciés : en bois et en pierre. Faciles à entretenir et résistants, ils
                  arborent un aspect chic et champêtre à l’endroit. Le rendu visuel est encore plus exceptionnel si vous
                  rajoutez une mini-clôture en bois autour de votre jardin. Bien que le bois et la pierre soient des
                  matériaux standards, très ravissants avec une touche de peinture, rien ne peut remplacer leur couleur
                  naturelle. Pour le bois, une couche de vernis suffit pour le rendre encore plus design.
                  <br>
                  <br>
                </span>
              </p>
              <h2 style="margin-top: 22px;   clear: left; text-align: justify;">
                Et le mobilier ?
              </h2>
              <p style="text-align: justify;">
                <span style="font-size: large;">À part tous ces détails, le mobilier constitue la touche finale et non
                  des moindres. Chez nous vous trouverez toutes les matières depuis la résine tressée, très raffinée,
                </span>
              </p>
              <p style="text-align: justify;">
                <span style="font-size: large;">résistante et polyvalente, au bois et à l’acier zingué.
                  Mais
                  également des salons de jardin haut de gamme
                  . L’essentiel est de se faire plaisir et redonner une nouvelle apparence à votre jardin en été.
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">&nbsp;</p>
              <p style="text-align: justify;">
                <span style="font-size: large;">
                  <img alt="" src="./media/wysiwyg/3c.jpg">
                </span>
              </p>
              <p style="text-align: justify;">&nbsp;</p>
            </div>
          </div>
          <div class="clear"></div>
        </div>
      </div>
    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../../function/getH1";
import {Term} from "../../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../../@common/constant/PROJECT_URL";
import {PATHS} from "../../../../@common/constant/PATHS";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    },
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FAQ-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
