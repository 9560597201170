<style scoped lang="scss">

@import '../../scss/color';
@import '../../scss/size';

$scrollSize: 8px;

.Menu {
  width: 100%;
  height: 100%;
  position: relative;
  background: #f7f7f7;
  overflow: hidden;
}

/*
 * * * * * * * * * *
 *
 *   CLASS
 *
 * * * * * * * * * *
 */

/* SEARCH */

.Menu--menuSearch {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
}

.Menu-desktop .Menu--menuSearch ::v-deep .MenuSearch {
  height: 500px;
}

.Menu-tablet .Menu--menuSearch ::v-deep .MenuSearch {
  height: 100vh;
}

.Menu-mobile .Menu--menuSearch ::v-deep .MenuSearch {
  height: 100vh;
}

.Menu--content {
  transition: margin 500ms, transform 500ms;
  box-sizing: border-box;
  position: relative;
}

.Menu-desktop .Menu--content {
  padding-top: $menu-height;
}

.Menu-tablet .Menu--content {
  padding-top: $menu-height;
}

.Menu-mobile .Menu--content {
  padding-top: $mobile-menu-content-height;
}

.Menu-searchActivated .Menu--content {
}

.Menu-desktop.Menu-searchActivated .Menu--content {
  transform: translate(0px, 550px);
  margin: 0 100px;
}

.Menu-tablet.Menu-searchActivated .Menu--content {
  transform: translate(0px, 100vh);
}

.Menu-mobile.Menu-searchActivated .Menu--content {
  transform: translate(0px, 100vh);
}

.Menu-fixed .Menu--content {
  padding-top: 0;
}

.Menu--searchMask {
  cursor: pointer;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: rgba(0, 0, 0, 0.5);
  display: none;
}

.Menu-searchActivated .Menu--searchMask {
  display: block;
}

/* OTHER */

.Menu--header {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  transform: translateY(-100%);
  transition: width 300ms;
  border-bottom: solid 1px rgba(0, 0, 0, 0);
}
.Menu--header--home {
  top: 30px;
}

.Menu-desktop.Menu-light .Menu--header {
  height: $menu-height + 50px;
}

.Menu-tablet.Menu-light .Menu--header {
  height: $menu-height + 30px;
}

.Menu-mobile.Menu-light .Menu--header {
  height: $mobile-menu-content-height + 45px;
}

.Menu-desktop.Menu-dark .Menu--header {
  height: $menu-height;
}

.Menu-tablet.Menu-dark .Menu--header {
  height: $menu-height;
}

.Menu-mobile.Menu-dark .Menu--header {
  height: $mobile-menu-content-height;
}

.Menu-visible .Menu--header {
  transform: translateY(0px);
}

.Menu-visible.Menu-searchActivated .Menu--header {
  transform: translateY(-100%);
}

.Menu-dark .Menu--header {
  border-bottom: solid 1px white;
  transition: transform 300ms, height 300ms;
}

.Menu-light .Menu--header {
  transition: transform 1000ms, height 300ms;
}

.Menu-light.Menu-searchActivated .Menu--header {
  transition: transform 300ms, height 300ms;
}

.Menu-opened.Menu-dark .Menu--header {
  box-shadow: none;
  border: none;
}

/**/

.Menu--header header {
  width: 100%;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  position: relative;
  transition: background 300ms, padding 300ms;
  background: rgba(0, 0, 0, 0);
  box-sizing: border-box;
}

.Menu-dark .Menu--header header {
  background: $primaryLight;
}

.Menu-dark .Menu--header header {
  background: $primaryLight;
}

.Menu-dark.Menu-openedMobile .Menu--header header {
  background: $primaryLight;
}

/**/

.Menu--logo {
  transition: opacity 300ms, padding 300ms;
  opacity: 1;
}

.Menu-desktop .Menu--logo {
}

.Menu-desktop .Menu--logo:hover {
  opacity: 0.6;
}

.Menu-desktop.Menu-light .Menu--logo {
  padding-left: 55px;
  padding-right: 35px;
}

.Menu-desktop.Menu-dark .Menu--logo {
  padding-left: 32px;
  padding-right: 10px;
}

.Menu-tablet .Menu--logo {
}

.Menu-tablet.Menu-light .Menu--logo {
  padding-left: 47px;
  padding-right: 54px;
}

.Menu-tablet.Menu-dark .Menu--logo {
  padding-left: 32px;
  padding-right: 39px;
}

.Menu-mobile .Menu--logo {
  position: relative;
  top: 5px;
}

.Menu-dark .Menu--logo ::v-deep .Vector svg * {
  fill: $primary;
  transition: fill 300ms;
}

.Menu-dark .Menu--logo ::v-deep .b17ab4e2-2923-41ae-bda4-e3f5ff038127 {
  fill: white !important;
}

.Menu-light .Menu--logo ::v-deep .Vector svg * {
  fill: white;
}

.Menu-light .Menu--logo ::v-deep .b17ab4e2-2923-41ae-bda4-e3f5ff038127 {
  fill: black !important;
}

.Menu-desktop .Menu--logo ::v-deep .Vector svg {
  height: 50px;
}

.Menu-tablet .Menu--logo ::v-deep .Vector svg {
  height: 45px;
}

.Menu-mobile .Menu--logo ::v-deep .Vector svg {
  height: 35px;
}

.Menu--body {
  background: white;
}

.Menu--footer {
  background: white;
}

.Menu--mask {
  width: 100%;
  height: calc(100% - #{$menu-height});
  position: absolute;
  top: $menu-height;
  left: 0;
  background: rgba(0, 0, 0, 1);
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  cursor: pointer;
  transform: translateY(100%);
}

.Menu-opened .Menu--mask {
  opacity: 0.6;
  transform: translateY(0);
}

.Menu--menuContent {
  width: 100%;
  position: fixed;
  top: 0;
  left: 0;
  transform: translateY(-100%);
  transition: transform 300ms;
}

.Menu-desktop.Menu-desktopY .Menu--menuContent {
  height: 500px;
}

.Menu-desktop.Menu-mobileY .Menu--menuContent {
  height: calc(100vh - #{$menu-height});
}

.Menu-tablet .Menu--menuContent {
  height: calc(100vh - #{$menu-height + 51px});
}

.Menu-desktop.Menu-opened .Menu--menuContent {
  transform: translateY($menu-height);
}

.Menu-tablet.Menu-opened .Menu--menuContent {
  transform: translateY($menu-height + 51px);
}

/**/

.Menu--icons {
  display: flex;
}

/**/

.Menu--icon {
  cursor: pointer;
  opacity: 1;
  transition: opacity 300ms;
}

.Menu--icon:hover {
  opacity: 0.6;
}

.Menu--icon ::v-deep .Vector {
  height: 32px;
}

.Menu--icon ::v-deep .Vector svg {
  height: 32px;
  transition: fill 300ms;
}

.Menu-light .Menu--icon ::v-deep .Vector svg {
  fill: white;
}

.Menu-dark .Menu--icon ::v-deep .Vector svg {
  fill: black;
}

.Menu-desktop .Menu--icon {
  margin-left: 25px;
}

.Menu-tablet .Menu--icon {
  margin-left: 25px;
}

.Menu-mobile .Menu--icon {
  padding: 20px;
}

.Menu--icon-user {
  margin-right: 30px;
}

.Menu--icon-user {
  margin-right: 30px;
}

/**/

.Menu--icon ::v-deep .Link {
  text-decoration: none;
}

/**/

.Menu--icon-search {
  margin-left: 20px;
}

/**/

.Menu--icon-menu {
}

.Menu-desktop .Menu--icon-menu {
}

.Menu-tablet .Menu--icon-menu {
  margin-left: 0;
}

.Menu-tablet .Menu--icon-menu ::v-deep .Vector {
  height: 20px;
}

.Menu-tablet .Menu--icon-menu ::v-deep .Vector svg {
  height: 20px;
}

.Menu-light .Menu--icon-menu ::v-deep .Vector svg {
  stroke: white;
}

.Menu-dark .Menu--icon-menu ::v-deep .Vector svg {
  stroke: black;
}

.Menu-mobile .Menu--icon-menu {
  //position: relative;
  //top: -5px;
}

.Menu-mobile .Menu--icon-menu ::v-deep .Vector {
  height: 16px;
}

.Menu-mobile .Menu--icon-menu ::v-deep .Vector svg {
  height: 16px;
}

.Menu-tablet.Menu-dark.Menu-opened .Menu--icon-menu ::v-deep .Vector {
  height: 24px;
}

.Menu-mobile.Menu-dark.Menu-openedMobile .Menu--icon-menu ::v-deep .Vector {
  height: 24px;
}

.Menu-tablet.Menu-dark.Menu-opened .Menu--icon-menu ::v-deep .Vector svg {
  height: 24px;
  stroke: none;
}

.Menu-mobile.Menu-dark.Menu-openedMobile .Menu--icon-menu ::v-deep .Vector svg {
  height: 24px;
  stroke: none;
}

/**/

.Menu--icon-basket {
  position: relative;
}

.Menu-desktop .Menu--icon-basket {
  margin-left: 0;
  margin-right: 30px;
}

.Menu-tablet .Menu--icon-basket {
  margin-left: 0;
  margin-right: 30px;
}

.Menu-mobile .Menu--icon-basket ::v-deep .Vector {
  height: 26px;
}

.Menu-mobile .Menu--icon-basket ::v-deep .Vector svg {
  height: 26px;
}

.Menu--linkProfil {
  text-decoration: none;
  display: block;
  width: 100%;
}

.Menu--count {
  position: absolute;
  padding: 2px 8px;
  color: white;
  background: #F56C6C;
  border-radius: 15px;
  font-family: sans-serif;
  font-weight: bold;
  font-size: 12px;
  align-items: center;
  justify-content: center;
  display: flex;
}

.Menu-desktop .Menu--count {
  top: -5px;
  right: -15px;
}

.Menu-tablet .Menu--count {
  top: -5px;
  right: -15px;
}

.Menu-mobile .Menu--count {
  top: 35px;
  right: 30px;
}

.Menu--left {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
}

.Menu--searchButton {
  display: flex;
  cursor: text;
}

.Menu--searchButtonInput {
  display: flex;
  align-items: center;
  position: relative;
}

.Menu--searchButtonInput ::v-deep .Term {
  font-family: 'CommutersSansBold', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  transition: border 300ms, color 300ms;
}

.Menu--searchButtonInput ::v-deep .Term .Term-text:first-letter {
  text-transform: uppercase;
}

.Menu-tablet .Menu--searchButtonInput {
  display: none;
}

@media screen and (max-width: $menu-image-screen) {
  .Menu-desktop .Menu--searchButtonInput {
    display: none;
  }
}

.Menu-light .Menu--searchButtonInput ::v-deep .Term {
  color: white;
}

.Menu-dark .Menu--searchButtonInput ::v-deep .Term {
  color: black;
}

.Menu--barre {
  height: 1px;
  width: 100%;
  position: absolute;
  transform: scaleX(0);
  transition: transform 300ms;
  bottom: 0;
}

.Menu--searchButton:hover .Menu--barre {
  transform: scaleX(1);
}

.Menu-light .Menu--barre {
  background: rgba(255, 255, 255, 0.5);
}

.Menu-dark .Menu--barre {
  background: $primary;
}

.Menu--right {
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}

.Menu-desktop .Menu--right {
}

.Menu-tablet .Menu--right {
}

.Menu-mobile .Menu--right {
}

.Menu--smallLink {
  display: flex;
  align-items: center;
  cursor: pointer;
  height: 100%;
}

.Menu--smallLink2 {
  padding-left: 20px;
  font-family: 'CommutersSansBold', sans-serif;
  font-size: 13px;
  transition: color 300ms;
  text-transform: uppercase;
}

.Menu-dark .Menu--smallLink2 {
  color: black;
}

.Menu-light .Menu--smallLink2 {
  color: white;
}

.Menu--menuList {
  height: 100%;
}

.Menu--menuList-tablet {
  width: 100%;
  position: absolute;
  top: $menu-height;
  left: 0;
  height: 50px + 1px;
  display: flex;
  justify-content: flex-end;
  box-sizing: border-box;
  padding-right: 68px;
  align-items: center;
  background: rgba(255, 255, 255, 0);
  border-bottom: solid 1px rgba(0, 0, 0, 0);
  transform: translateY(-50px);
  transition: transform 300ms, background 300ms;
}

.Menu-opened .Menu--menuList-tablet {
  transform: translateY(0px);
  background: rgba(255, 255, 255, 1);
  border-bottom: solid 1px rgba(0, 0, 0, 0.1);
}

.Menu--menuContent-mobile
 {
  position: fixed;
  top: $mobile-menu-content-height;
  left: 0;
  width: 100%;
  height: calc(100vh - #{$mobile-menu-content-height});
  transform: translateY(-100vh);
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  transition: transform 300ms, background 300ms;
  background: white;
}

.Menu--menuContent-mobile-home{
  top: $mobile-menu-content-height + 30px;
  height: calc(100vh - #{$mobile-menu-content-height + 30px});
}

.Menu-openedMobile .Menu--menuContent-mobile {
  transform: translateY(0);
}

.Menu--menuSearch-mobile {
  display: flex;
  padding-bottom: 25px;
}

.Menu--menuSearch-mobileInput {
  flex: 1;
  background-color: $primary;
  color: white;
  padding: 25px;
  text-align: center;
  font-family: 'CommutersSansBold', sans-serif;
  font-size: 13px;
  text-transform: uppercase;
  display: flex;
  align-items: center;
  justify-content: center;
}

.Menu--menuSearch-mobileInput ::v-deep .Vector {
  width: 24px;
  fill: white;
  margin-right: 10px;
}

.Menu--menuList-mobile {
  flex: 1;
  overflow: auto;
}

.Menu-openedMobile .Menu--menuList-mobile {
  overflow: hidden;
}

.Menu--menuFooter-mobile {
  display: flex;
  transition: opacity 300ms;
  justify-content: flex-end;
  align-items: center;
}

.Menu--modal-mobile {
  background: #ffffff;
  position: absolute;
  top: $mobile-menu-content-height + 25px;
  left: 25px;
  width: calc(100% - 50px);
  height: calc(100% - #{$mobile-menu-content-height + 25px});
  border-radius: 4px 4px 0px 0px;
  opacity: 0;
  transform: scale(2) translateY(150vh);
  transition: opacity 300ms, transform 300ms;
  overflow: auto;
  border: solid 1px rgba(0, 0, 0, 0.05);
}

.Menu-opened .Menu--modal-mobile {
  opacity: 1;
  transform: scale(1) translateY(0);
}

.Menu--proSpace {
  font-family: 'CommutersSansBold', sans-serif;
  font-size: 11px;
  text-transform: uppercase;
  border-radius: 3px;
  padding: 17px 32px 18px 32px;
  cursor: pointer;
  opacity: 1;
  transition: background 300ms, color 300ms, margin 300ms, opacity 300ms;
  color: #31302f;
}

.Menu-desktop .Menu--proSpace:hover {
  opacity: 0.6;
}

.Menu-dark .Menu--proSpace {
  background: $primary;
  color: white;
}

.Menu-light .Menu--proSpace {
  background: white;
  color: black;
}

.Menu-desktop.Menu-light .Menu--proSpace {
  margin-right: 50px;
}

.Menu-desktop.Menu-dark .Menu--proSpace {
  margin-right: 25px;
}

.Menu-tablet.Menu-light .Menu--proSpace {
  margin-right: 40px;
}

.Menu-tablet.Menu-dark .Menu--proSpace {
  margin-right: 25px;
}

.Menu-mobile .Menu--proSpace {
  display: none;
}
</style>

<template>
  <div class="Menu" :class="class_root">
    <Responsive :breakPoint="props.Responsive.breakPoint"
                :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                :breakPoints="props.Responsive.breakPoints"
                :verticalBreakPoints="props.Responsive.verticalBreakPoints"
                :onBreakPointChange="props.Responsive.onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive.onVerticalBreakPointChange">
      <div class="Menu--menuSearch">
        <MenuSearch :focused="this.state.searchActivated"
                    :onClickClose="() => state.searchActivated = false">
        </MenuSearch>
      </div>
      <div class="Menu--content" :id="getMenuId()">
        <div class="Menu--body">
          <slot/>
        </div>
        <div class="Menu--footer">
          <Footer :nuxtProp="nuxtProp"/>
        </div>
        <div class="Menu--searchMask App--zindex" @click="state.searchActivated = false"></div>
      </div>
      <div :style="style_scroll" class="Menu--mask App--zindex" @click="() => close()" v-if="[SCREEN.DESKTOP, SCREEN.TABLET].includes(props.Responsive.breakPoint.name)"></div>
      <div :style="style_scroll" class="Menu--menuContent App--zindex" v-if="[SCREEN.DESKTOP, SCREEN.TABLET].includes(props.Responsive.breakPoint.name)">
        <MenuContent
            :animated="state.opened"
            :onClickBack="() => close()"
            :tabId="props.MenuContent.tab"
            :linkId="props.MenuContent.link"
            :nuxtProp="nuxtProp"
            :breakPoint="props.Responsive.breakPoint"
            :verticalBreakPoint="props.Responsive.verticalBreakPoint"
            :onClickLink="props.MenuContent.onClickLink">
        </MenuContent>
      </div>
      <div class="Menu--menuContent-mobile App--zindex" :class="{ 'Menu--menuContent-mobile-home': displayHeadband}" v-if="[SCREEN.MOBILE].includes(props.Responsive.breakPoint.name)">
        <div class="Menu--menuSearch-mobile" @click="() => onClickSearch()">
          <div class="Menu--menuSearch-mobileInput">
            <Vector :svg="vector.search"></Vector>
            <Term :term="lang.term(94)"/>
          </div>
        </div>
        <div class="Menu--menuList-mobile">
          <MenuList :roleId="state.roleId"
                    :linkAccount="linkAccount" :onClick="(...v) => open(...v)"
                    :animated="state.openedMobile"
                    :theme="theme"
                    :breakPoint="props.Responsive.breakPoint"
                    :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                    :vertical="true"></MenuList>
        </div>
        <div class="Menu--modal-mobile">
          <MenuContent
              :onClickBack="() => closeMobile()"
              :vertical="true"
              :tabId="props.MenuContent.tab"
              :linkId="props.MenuContent.link"
              :nuxtProp="nuxtProp"
              :breakPoint="props.Responsive.breakPoint"
              :verticalBreakPoint="props.Responsive.verticalBreakPoint"
              :onClickLink="props.MenuContent.onClickLink">
          </MenuContent>
        </div>
      </div>
      <div :style="style_scroll" class="Menu--header App--zindex" :class="{'Menu--header--home': displayHeadband}">
        <CountryLocation>
          <TransportCostFree :opened="openedFreeTransportCost"
                             :theme="theme"
                             :nuxtProp="nuxtProp">
          </TransportCostFree>
        </CountryLocation>
        <nav class="Menu--menuList-tablet" v-if="props.Responsive.breakPoint.name === SCREEN.TABLET">
          <MenuList :roleId="state.roleId"
                    :onClick="(...v) => open(...v)"
                    :animated="state.opened"
                    :breakPoint="props.Responsive.breakPoint"
                    :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                    :theme="theme">
          </MenuList>
        </nav>
        <header>
          <div class="Menu--icon Menu--icon-menu" v-if="[SCREEN.MOBILE].includes(props.Responsive.breakPoint.name)"
               @click="() => toggleMobile()">
            <Vector :svg="state.openedMobile ? vector.clear : vector.menu"/>
          </div>
          <div class="Menu--left">
            <div class="Menu--logo">
              <Link :pathIds="[PATHS.HOME]">
                <Vector :svg="vector.logo"/>
              </Link>
            </div>
            <nav class="Menu--menuList" v-if="props.Responsive.breakPoint.name === SCREEN.DESKTOP">
              <MenuList :roleId="state.roleId"
                        :onClick="(...v) => open(...v)"
                        :animated="state.visible"
                        :breakPoint="props.Responsive.breakPoint"
                        :verticalBreakPoint="props.Responsive.verticalBreakPoint"
                        :theme="theme">
              </MenuList>
            </nav>
            <div class="Menu--smallLink" v-if="props.Responsive.breakPoint.name === SCREEN.TABLET"
                 @click="() => openTablet()">
              <div class="Menu--icon Menu--icon-menu">
                <Vector :svg="state.opened ? vector.clear : vector.menu"/>
              </div>
              <div class="Menu--smallLink2">
                <Term :term="lang.term(100)"/>
              </div>
            </div>
          </div>
          <div class="Menu--right">
            <div class="Menu--searchButton"
                 v-if="[SCREEN.DESKTOP, SCREEN.TABLET].includes(props.Responsive.breakPoint.name)"
                 @click="() => onClickSearch()">
              <div class="Menu--searchButtonInput">
                <Term :term="lang.term(94)"/>
                <div class="Menu--barre"></div>
              </div>
              <div class="Menu--icon Menu--icon-search">
                <Vector :svg="vector.search"></Vector>
              </div>
            </div>
            <div class="Menu--icons">
              <div class="Menu--icon Menu--icon-user"
                   v-if="[SCREEN.DESKTOP, SCREEN.TABLET].includes(props.Responsive.breakPoint.name)">
                <Popover :opened="state.openedAccount" :onOpenedChange="v => state.openedAccount = v"
                         position="bottom-end">
                  <template slot="default">
                    <Link class="Menu--linkProfil" :pathIds="linkAccount"
                          v-if="[ROLE.ROOT, ROLE.ADMIN, ROLE.CHR, ROLE.SEO].includes(state.roleId)">
                      <List :svg="vector.explore" :text="lang.translate(560)"
                            :onClick="() => state.openedAccount = false"/>
                    </Link>
                    <Link class="Menu--linkProfil" :pathIds="[PATHS.ACCOUNT]">
                      <List v-if="state.token" :svg="vector.contacts"
                            :text="lang.translate(561)"
                            :onClick="() => state.openedAccount = false"/>
                      <List v-if="!state.token" :svg="vector.contacts" :text="lang.translate(562)"
                            :onClick="() => state.openedAccount = false"/>
                    </Link>
                    <List v-if="state.token" :svg="vector.exit_to_app" :text="lang.translate(138)"
                          :onClick="() => disconnect()"/>
                  </template>
                  <template slot="reference">
                    <Vector :svg="vector.account"></Vector>
                  </template>
                </Popover>
              </div>
              <CountryLocation>
                <div class="Menu--icon Menu--icon-basket" @click="() => toggleOpenBasket()">
                  <Vector :svg="vector.shopping_basket"></Vector>
                  <div v-if="countBasket" class="Menu--count">{{ countBasket }}</div>
                </div>
              </CountryLocation>
            </div>
            <Link :pathIds="linkAccount">
              <div class="Menu--proSpace">
                <Term :term="lang.term(692)"></Term>
              </div>
            </Link>
          </div>
        </header>
      </div>
    </Responsive>
    <Modal :opened="state.openedBasket" :onOpenedChange="v => state.openedBasket = v">
      <Basket/>
    </Modal>
  </div>
</template>

<script>

import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import Scrollbar from '@bbx/scrollbar~master/core/env/browser/ts/Scrollbar';

import {PATHS} from "../../../@common/constant/PATHS";
import {THEME} from "../../../@common/constant/THEME";
import {ROLE} from "../../../@common/constant/ROLE";

import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {ROLE_BUYER, ROLE_STAFF} from "../../../@common/constant/ROLE";
import {stateService, STATE_ORDER, STATE_ROLE_ID, STATE_TOKEN, STATE_USER_FIRST_NAME} from "../../service/stateService";
import {Order} from "../../../@common/delta/database/Order";
import {getBasketCount} from "../../function/getBasketCount";
import {EVENT} from "../../../@common/constant/EVENT";
import {eventService} from "../../service/eventService";
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {getNuxtPropPathIds} from "../../../@common/function/helperUrl";
import {scrollerParentService} from "../../service/scrollerParentService";
import {freeTransportCostService} from "../../../@common/service/freeTransportCostService";
import {disconnect} from "../../function/disconnect";
import {displayMessage} from "../../function/displayMessage";
import {isHome} from "../../../@common/function/isHome";
import {getMenuId} from "../../function/getMenuId";
import {getCountry} from "../../service/countryService";
import {COUNTRY_ISO} from "../../../@common/constant/COUNTRY";

export default {

  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    },
    fixed: {
      type: Boolean,
      default: () => true
    },
    onFixedChange: {
      type: Function,
      default: () => () => {
      }
    },
    showBackground: {
      type: Boolean,
      default: () => true
    },
    onShowBackgroundChange: {
      type: Function,
      default: () => () => {
      }
    },
  },
  data() {
    return {
      freeTransportCostService: freeTransportCostService,
      lang: getLangServiceBrowser(),
      THEME,
      SCREEN,
      PATHS,
      ROLE,
      vector: {
        logo: require('../../../@common/assets/logo-white.svg'),

        menu: require('../../../@common/assets/icon/menu/menu.svg'),
        account: require('../../../@common/assets/icon/menu/user.svg'),
        search: require('../../../@common/assets/icon/menu/search.svg'),
        shopping_basket: require('../../../@common/assets/icon/menu/basket.svg'),

        expand_more: require('@bbx/vector~master/core/assets/svg/material/expand_more.svg'),
        explore: require('@bbx/vector~master/core/assets/svg/material/explore.svg'),
        contacts: require('@bbx/vector~master/core/assets/svg/material/contacts.svg'),
        exit_to_app: require('@bbx/vector~master/core/assets/svg/material/exit_to_app.svg'),
        clear: require('@bbx/vector~master/core/assets/svg/material/clear.svg'),
        faq: require('../../../@common/assets/icon/broken/Iconly-Broken-Chat.svg'),
        box: require('../../../@common/assets/icon/broken/Iconly-Broken-Buy.svg'),
      },
      props: {
        MenuContent: {
          tab: -1,
          link: -1,
          onClickLink: (link) => {
            this.props.MenuContent.link = link;
          },
        },
        Responsive: {
          breakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          verticalBreakPoint: new BreakPoint({
            name: SCREEN.DESKTOP
          }),
          breakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              width: 0
            }),
            new BreakPoint({
              name: SCREEN.TABLET,
              width: 900
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              width: 1400
            }),
          ],
          verticalBreakPoints: [
            new BreakPoint({
              name: SCREEN.MOBILE,
              height: 0
            }),
            new BreakPoint({
              name: SCREEN.DESKTOP,
              height: 600
            }),
          ],
          onBreakPointChange: (v) => this.props.Responsive.breakPoint = v,
          onVerticalBreakPointChange: (v) => this.props.Responsive.verticalBreakPoint = v,
        },
      },
      state: {
        showBackground: this.showBackground,
        mounted: false,
        fixed: this.fixed,
        search: '',
        searchActivated: false,
        opened: false,
        openedMobile: false,
        openedBasket: false,
        openedAccount: false,
        visible: false,
        roleId: ROLE.SELLER,
        firstName: '',
        token: '',
        scrollbarSize: 0,
        order: new Order()
      },
      events: []
    }
  },
  watch: {
    'fixed': {
      handler(v) {
        this.state.fixed = v
      }
    },
    'showBackground': {
      handler(v) {
        this.state.showBackground = v
      }
    },
    'state.fixed': {
      handler(v) {
        this.onFixedChange(v)
      }
    },
    'state.openedAccount': {
      handler(v) {
        eventService.triggerByName(new ListenerTrigger({
          name: v ? EVENT.DISABLE_CAROUSEL_LINK : EVENT.UNABLE_CAROUSEL_LINK
        }))
      }
    },
    'state.showBackground': {
      handler(v) {
        this.onShowBackgroundChange(v)
      }
    },
    'state.searchActivated': {
      handler(v) {
        eventService.triggerByName(new ListenerTrigger({
          name: EVENT.SEARCH_OPENED_CHANGE,
          payload: v,
        }))
        eventService.triggerByName(new ListenerTrigger({
          name: EVENT.DISPLAY_SCROLLBAR,
          payload: !v,
        }))
      }
    },
    'theme': {
      handler(v) {
        eventService.triggerByName(new ListenerTrigger({
          name: EVENT.MENU_THEME_CHANGED,
          payload: v,
        }))
      }
    }
  },
  computed: {
    class_root() {
      const classes = [];
      if (this.theme === THEME.DARK) classes.push(`Menu-dark`)
      if (this.theme === THEME.LIGHT) classes.push(`Menu-light`)
      if (this.state.mounted) classes.push(`Menu-mounted`)
      if (this.state.fixed) classes.push(`Menu-fixed`)
      if (this.state.opened) classes.push(`Menu-opened`)
      if (this.state.visible) classes.push(`Menu-visible`)
      if (this.state.scrollbarSize) classes.push(`Menu-scrollbarSize`)
      if (this.state.openedMobile) classes.push(`Menu-openedMobile`)
      if (this.state.searchActivated) classes.push(`Menu-searchActivated`)
      if (this.props.Responsive.breakPoint.name === SCREEN.MOBILE) classes.push(`Menu-mobile`)
      if (this.props.Responsive.breakPoint.name === SCREEN.TABLET) classes.push(`Menu-tablet`)
      if (this.props.Responsive.breakPoint.name === SCREEN.DESKTOP) classes.push(`Menu-desktop`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`Menu-mobileY`)
      if (this.props.Responsive.verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`Menu-desktopY`)
      return classes;
    },
    style_scroll() {
      let style = {}
      // style.width = `calc(100% - ${this.state.scrollbarSize}px)`
      return style
    },
    linkAccount() {
      if (!this.state.roleId) return [PATHS.LOGIN]
      else {
        if (ROLE_STAFF.includes(this.state.roleId)) return [
          PATHS.ADMIN,
          PATHS.ORDER
        ]
        if ([ROLE.SEO].includes(this.state.roleId)) return [
          PATHS.ADMIN,
          PATHS.LANG
        ]
        if (ROLE_BUYER.includes(this.state.roleId)) return [PATHS.ACCOUNT]
      }
    },
    countBasket() {
      return getBasketCount(this.state.order.baskets)
    },
    openedFreeTransportCost() {

      // -----

      let display = false

      // -----

      const includes = [
        [PATHS.HOME].join('.'),
      ]
      let ok1 = this.freeTransportCostService.isActivated()
      let ok2 = includes.includes(getNuxtPropPathIds(this.nuxtProp).join('.'))
      if (ok1 && ok2) {
        display = true
      }

      // -----

      if (this.state.showBackground === true) {
        display = false
      }

      // -----

      if (this.state.opened === true) {
        display = false
      }

      if (this.state.openedMobile === true) {
        display = false
      }

      if (this.state.searchActivated === true) {
        display = false
      }

      // -----

      return display
    },
    theme() {
      if (this.state.showBackground) return THEME.DARK
      if (this.state.opened) return THEME.DARK
      // if (this.state.openedBasket) return THEME.DARK
      if (this.state.openedMobile) return THEME.DARK
      else return THEME.LIGHT
    },
    displayHeadband(){
      const todayDate = new Date();
      const endDate = new Date(2023, 6, 30);
      return isHome(this.nuxtProp) && this.nuxtProp.url.paths[0].value === 'fr' && getCountry() === COUNTRY_ISO.FR && endDate > todayDate;
    }
  },
  beforeMount() {

    // -----

    this.state.scrollbarSize = new Scrollbar().getNativeSize()

    // -----

    this.state.token = stateService.get(STATE_TOKEN)
    stateService.watch(STATE_TOKEN, v => this.state.token = v)

    this.state.order = stateService.get(STATE_ORDER)
    stateService.watch(STATE_ORDER, v => this.state.order = v)

    this.state.roleId = stateService.get(STATE_ROLE_ID)
    stateService.watch(STATE_ROLE_ID, v => this.state.roleId = v)

    this.state.firstName = stateService.get(STATE_USER_FIRST_NAME)
    stateService.watch(STATE_USER_FIRST_NAME, v => this.state.firstName = v)

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.SCROLL_CHANGE,
      callback: ({top}) => {
        if (isHome(this.nuxtProp)) {
          this.state.showBackground = top > 0;
        }
      }
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.HIDE_MENU,
      callback: (v) => {
        if (v && (this.state.opened || this.state.openedMobile)) return
        this.state.visible = !v
      }
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.ROUTE_QUERY_CHANGED,
      callback: (nuxtUrl) => {
        this.state.opened = false
        this.state.openedMobile = false
        this.state.openedBasket = false
        this.state.searchActivated = false
        this.state.visible = true
      }
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
    setTimeout(() => {
      this.state.mounted = true
      this.state.visible = true
    })
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    open(tab, link, opened = true) {
      this.state.opened = opened;
      this.props.MenuContent.tab = tab;
      this.props.MenuContent.link = link;
    },
    openTablet() {
      this.open(0, 0, !this.state.opened)
    },
    toggleMobile() {
      if (this.state.openedMobile) {
        this.state.openedMobile = false;
        this.state.opened = false;
      } else {
        this.state.openedMobile = true;
      }
    },
    closeMobile() {
      this.state.opened = false;
      this.props.MenuContent.tab = -1;
      this.props.MenuContent.link = -1;
    },
    close() {
      this.state.opened = false;
      this.state.openedMobile = false;
      this.props.MenuContent.tab = -1;
      this.props.MenuContent.link = -1;
    },
    toggleOpenBasket() {
      this.state.opened = false;
      this.state.openedMobile = false;
      this.state.openedBasket = !this.state.openedBasket

      if (this.state.openedBasket) {
        eventService.triggerByName(new ListenerTrigger({
          name: EVENT.BASKET_WAS_OPENED,
          payload: this.state.order.baskets,
        }))
      }
    },
    onClickSearch() {
      this.state.searchActivated = true
      this.state.openedMobile = false
      this.state.opened = false
      scrollerParentService.scrollTo(0, 0)
    },
    disconnect() {
      this.state.openedAccount = false
      disconnect()
      displayMessage({
        vue: this,
        type: 'success',
        message: this.lang.translate(563)
      })
    },
    getMenuId
  }
}
</script>
