<style scoped lang="scss">

.CircleLink {
  transition: opacity 300ms;
  opacity: 0;
}

.CircleLink-activated {
  opacity: 1;
}

.CircleLink--text {
  font-family: 'MontserratSemiBold', sans-serif;
  font-size: 14px;
  letter-spacing: 0.25px;
  text-transform: uppercase;
  fill: white;
  opacity: 0;
}

.CircleLink-activated .CircleLink--text {
  opacity: 1;
}

.cursor {
  display: none;
}

.cursor__inner {
  fill: none;
  stroke: #ffffff;
  stroke-width: 1px;
}

@media (any-pointer: fine) {
  .cursor {
    position: fixed;
    top: 0;
    left: 0;
    display: block;
    pointer-events: none;
    opacity: 0;
  }
  .cursor__inner {
    fill: none;
    stroke: #ffffff;
    stroke-width: 1px;
  }
}

</style>

<template>
  <div class="CircleLink" :class="class_root">
    <svg class="cursor" :width="size" :height="size" :viewBox="`0 0 ${size} ${size}`">
      <text class="CircleLink--text" :x="(size / 2) - 46.5" :y="(size / 2) + 4">
        {{ lang.translate(640) }}
      </text>
      <defs>
        <filter id="filter-1" x="-50%" y="-50%" width="200%" height="200%" filterUnits="objectBoundingBox">
          <feTurbulence type="fractalNoise" baseFrequency="0" numOctaves="0" result="warp"/>
          <feDisplacementMap xChannelSelector="R" yChannelSelector="G" scale="0" in="SourceGraphic" in2="warp"/>
        </filter>
      </defs>
      <circle class="cursor__inner" :cx="state.bigRadius + 1" :cy="state.bigRadius + 1" :r="state.smallRadius"/>
    </svg>
  </div>
</template>

<script>

import {gsap} from 'gsap';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {EVENT} from "../../../@common/constant/EVENT";
import {eventService} from "../../service/eventService";

// Map number x from range [a, b] to [c, d]
const map = (x, a, b, c, d) => (x - a) * (d - c) / (b - a) + c;

// Linear interpolation
const lerp = (a, b, n) => (1 - n) * a + n * b;

const calcWinsize = () => {
  return {width: window.innerWidth, height: window.innerHeight};
};

// Gets the mouse position
const getMousePos = e => {
  return {
    x: e.clientX,
    y: e.clientY
  };
};


// Calculate the viewport size
let winsize = calcWinsize();
window.addEventListener('resize', () => {
  winsize = calcWinsize();
});

// Track the mouse position
let mouse = {x: 0, y: 0};
window.addEventListener('mousemove', ev => mouse = getMousePos(ev));

class Cursor {
  constructor({el, size, barreSize, defaultX = 0, defaultY = 0, defaultPosition = false}) {

    this.size = size;
    this.barreSize = barreSize;
    this.smallRadius = this.size / 2 / 2
    this.bigRadius = (this.size / 2) - (barreSize * 2)
    this.DOM = {el: el};
    this.DOM.el.style.opacity = 0;
    this.DOM.circleInner = this.DOM.el.querySelector('.cursor__inner');

    this.filterId = '#filter-1';
    this.DOM.feDisplacementMap = document.querySelector(`${this.filterId} > feDisplacementMap`);

    this.primitiveValues = {scale: 0};

    this.createTimeline();

    this.bounds = this.DOM.el.getBoundingClientRect();

    this.renderedStyles = {
      tx: {previous: 0, current: 0, amt: 0.14},
      ty: {previous: 0, current: 0, amt: 0.14},
      radius: {previous: this.bigRadius, current: this.bigRadius, amt: 0.14}
    };

    this.play = () => {
      this.renderedStyles.tx.previous = this.renderedStyles.tx.current = mouse.x - this.bounds.width / 2;
      this.renderedStyles.ty.previous = this.renderedStyles.ty.current = mouse.y - this.bounds.height / 2;
      gsap.to(this.DOM.el, {duration: 0.9, ease: 'Power3.easeOut', opacity: 1});
      requestAnimationFrame(() => this.render());
    };

    if (defaultPosition) this.setDefaultPosition(defaultX, defaultY)

    this.play()
  }

  setDefaultPosition(x, y) {
    mouse.x = x
    mouse.y = y
  }

  render() {
    this.move()
    requestAnimationFrame(() => this.render());
  }

  move() {

    this.renderedStyles['tx'].current = mouse.x - this.bounds.width / 2;
    this.renderedStyles['ty'].current = mouse.y - this.bounds.height / 2;

    for (const key in this.renderedStyles) {
      this.renderedStyles[key].previous = lerp(this.renderedStyles[key].previous, this.renderedStyles[key].current, this.renderedStyles[key].amt);
    }

    this.DOM.el.style.transform = `translateX(${(this.renderedStyles['tx'].previous)}px) translateY(${this.renderedStyles['ty'].previous}px)`;
    this.DOM.circleInner.setAttribute('r', this.renderedStyles['radius'].previous);
  }

  createTimeline() {
    // init timeline
    this.tl = gsap.timeline({
      paused: true,
      onStart: () => {
        this.DOM.circleInner.style.filter = `url(${this.filterId}`;
      },
      onUpdate: () => {
        this.DOM.feDisplacementMap.scale.baseVal = this.primitiveValues.scale;
      },
      onComplete: () => {
        this.DOM.circleInner.style.filter = 'none';
      }
    })
        .to(this.primitiveValues, {
          duration: 0.1,
          ease: 'Expo.easeOut',
          startAt: {scale: 0},
          scale: 60
        })
        .to(this.primitiveValues, {
          duration: 0.6,
          ease: 'Power3.easeOut',
          scale: 0
        });
  }

  activate() {
    this.renderedStyles['radius'].current = 0;
    this.tl.restart();
    this.onActivated(true)
  }

  unactivate() {
    this.renderedStyles['radius'].current = this.bigRadius;
    this.tl.progress(1).kill();
    this.onActivated(false)
  }

  /**
   * @override
   */
  onActivated() {

  }
}

export default {
  props: {
    activated: {
      type: Boolean,
      default: () => false
    },
    onActivatedChange: {
      type: Function,
      default: () => () => {

      }
    },
    size: {
      type: Number,
      default: () => 185
    },
    barreSize: {
      type: Number,
      default: () => 1
    },
    defaultX: {
      type: Number,
      default: () => 185
    },
    defaultY: {
      type: Number,
      default: () => 185
    }
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      state: {
        smallRadius: 0,
        bigRadius: 0,
        activated: this.activated,
      },
      events: []
    }
  },
  computed: {
    class_root() {
      const classes = []
      if (this.state.activated) classes.push('CircleLink-activated')
      return classes
    },
    style_svg() {
      const style = {}
      style.width = `${this.size}px`;
      style.height = `${this.size}px`;
      return style
    },
  },
  watch: {
    'activated': function (v) {
      this.state.activated = v
    },
    'state.activated': function (v) {
      this.toggleActivated()
      this.onActivatedChange(v)
    }
  },
  beforeMount() {

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.CIRCLE_LINK_ACTIVATED,
      callback: (activated) => {
        this.state.activated = activated
      }
    }))

    // -----

    this.events.push(new ListenerRegister({
      name: EVENT.CIRCLE_LINK_RESET_POSITION,
      callback: () => {
        this.setDefaultPosition(this.defaultX, this.defaultY)
      }
    }))

    // -----

    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
    const el = document.querySelector('.cursor')
    const cursor = new Cursor({
      el: el,
      size: this.size,
      barreSize: this.barreSize,
      defaultPosition: true,
      defaultX: this.defaultX,
      defaultY: this.defaultY,
    });

    this.activate = () => cursor.unactivate() // yes...
    this.unactivate = () => cursor.activate() // yes...
    this.setDefaultPosition = (x, y) => cursor.setDefaultPosition(x, y)

    // cursor.onActivated = (activated) => {
    //   this.state.activated = activated
    // }

    this.state.smallRadius = cursor.smallRadius;
    this.state.bigRadius = cursor.bigRadius;
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    /**
     * @override
     */
    activate() {

    },
    /**
     * @override
     */
    setDefaultPosition() {

    },
    /**
     * @override
     */
    unactivate() {

    },
    toggleActivated() {
      this.state.activated ? this.activate() : this.unactivate()
    }
  }
}
</script>