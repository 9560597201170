"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Article = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Tax_1 = require("./Tax");
var Basket_1 = require("./Basket");
var Term_1 = require("./Term");
var Photo_1 = require("./Photo");
var Page_1 = require("./Page");
var Promotion_1 = require("./Promotion");
var Batch_1 = require("./Batch");
var Transport_1 = require("./Transport");
var Evolve_1 = require("./Evolve");
var Role_1 = require("./Role");
var User_1 = require("./User");
var Selection_1 = require("./Selection");
var Article = /** @class */ (function () {
    function Article(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.price = deltaService_1.deltaService.number(props.price, 0);
        this.ecoParticipation = deltaService_1.deltaService.number(props.ecoParticipation, 0);
        this.visible = deltaService_1.deltaService.boolean(props.visible, true);
        this.isBatch = deltaService_1.deltaService.boolean(props.isBatch, false);
        this.priorityDisplay = deltaService_1.deltaService.number(props.priorityDisplay, 4);
        this.restrictionStockActif = deltaService_1.deltaService.boolean(props.restrictionStockActif, false);
        this.restrictionStockNumber = deltaService_1.deltaService.number(props.restrictionStockNumber, 0);
        this.batches = deltaService_1.deltaService.array(props.batches, Batch_1.Batch);
        this.taxes = deltaService_1.deltaService.array(props.taxes, Tax_1.Tax);
        this.termNames = deltaService_1.deltaService.array(props.termNames, Term_1.Term);
        this.termDescriptions = deltaService_1.deltaService.array(props.termDescriptions, Term_1.Term);
        this.photos = deltaService_1.deltaService.array(props.photos, Photo_1.Photo);
        this.promotions = deltaService_1.deltaService.array(props.promotions, Promotion_1.Promotion);
        this.transports = deltaService_1.deltaService.array(props.transports, Transport_1.Transport);
        this.evolveAncestors = deltaService_1.deltaService.array(props.evolveAncestors, Evolve_1.Evolve);
        this.roleRestrictions = deltaService_1.deltaService.array(props.roleRestrictions, Role_1.Role);
        this.userRestrictions = deltaService_1.deltaService.array(props.userRestrictions, User_1.User);
        this.articleChildren = deltaService_1.deltaService.array(props.articleChildren, Article);
        this.articleParents = deltaService_1.deltaService.array(props.articleParents, Article);
        this.baskets = deltaService_1.deltaService.array(props.baskets, Basket_1.Basket);
        this.pages = deltaService_1.deltaService.array(props.pages, Page_1.Page);
        this.evolveDescendants = deltaService_1.deltaService.array(props.evolveDescendants, Evolve_1.Evolve);
        this.selections = deltaService_1.deltaService.array(props.selections, Selection_1.Selection);
    }
    return Article;
}());
exports.Article = Article;
