<style scoped lang="scss">

$right-space: 27px;

.MarqueNext {
  position: relative;
  cursor: pointer;
  background-color: white;
  display: flex;
  align-items: center;
}

.MarqueNext--terms {

}

.MarqueNext--term {
  opacity: 1;
  transition: opacity 500ms;
}

.MarqueNext--term-1 {
  font-family: 'CommutersSansBold', sans-serif;
  font-size: 15px;
  text-transform: uppercase;
  color: #9F5C4D;
}

.MarqueNext--term-2 {
  font-family: 'AmbroiseRegular', sans-serif;
  font-size: 45px;
  color: #1B1919;
}

.MarqueNext-hide .MarqueNext--term {
  opacity: 0.5;
}

.MarqueNext--vector {
  width: 90px;
  transform: translateX(0px) scaleX(1.2);
  transition: opacity 500ms, transform 500ms;
  position: relative;
  right: -50px;
  opacity: 0.4;
}

.MarqueNext-hide .MarqueNext--vector {
  opacity: 0;
  transform: translateX(-25px) scaleX(1.2);
}

.MarqueNext--cicrle {
  width: 170px;
  height: 170px;
  transform: rotate(-62deg);
  position: relative;
  left: -40px;
}

.MarqueNext--cicrle ::v-deep .CircleProgress--progressBarre {
  transition: stroke-dashoffset 500ms linear;
}
</style>

<template>
  <Link :url="url">
    <div class="MarqueNext" :class="class_root" :style="style_root" @mouseenter="onMouseEnter" @mouseleave="onMouseLeave">
      <div class="MarqueNext--terms">
        <div class="MarqueNext--term MarqueNext--term-1" :style="style_term">
          <Term :term="lang.term(823)"></Term>
        </div>
        <div class="MarqueNext--term MarqueNext--term-2" :style="style_term">
          <Term :term="term"></Term>
        </div>
      </div>
      <div class="MarqueNext--vector" :style="style_vector">
        <Vector :svg="icon.arrowBig"></Vector>
      </div>
      <div class="MarqueNext--cicrle">
        <CircleProgress :size="170" :percentage="this.state.hide ? 0 : 85" :barreSize="1"></CircleProgress>
      </div>
    </div>
  </Link>
</template>

<script>

// -----

import {ListenerRegister} from "@bbx/listener~master/core/delta/ListenerRegister";
import {ListenerTrigger} from "@bbx/listener~master/core/delta/ListenerTrigger";
import {eventService} from "../../service/eventService";
import {Term} from "../../../@common/delta/database/Term";
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {Url} from "../../../@common/delta/database/Url";

// -----

export default {
  props: {
    term: {
      type: Term,
      default: () => new Term()
    },
    url: {
      type: Url,
      default: () => new Url()
    }
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      icon: {
        arrowBig: require('../../../@common/assets/home/arrow-big.svg'),
      },
      state: {
        hovered: false,
        hide: false,
        timeout: null,
      },
      events: []
    }
  },
  watch: {
    'state.hovered': {
      handler(hovered) {
        if (hovered) {
          this.state.hide = true
          this.state.timeout = setTimeout(() => {
            this.state.hide = false
          }, 500)
        } else {
          this.state.hide = false
        }
      }
    }
  },
  computed: {
    class_root() {
      const classes = []
      if (this.state.hide) classes.push('MarqueNext-hide')
      return classes
    },
    style_root() {
      const style = {}
      return style
    },
    style_vector() {
      const style = {}
      return style
    },
    style_term() {
      const style = {}
      return style
    }
  },
  beforeMount() {
    for (const event of this.events) {
      eventService.register(event)
    }
  },
  mounted() {
  },
  beforeDestroy() {
    for (const event of this.events) {
      eventService.unregister(event)
    }
  },
  methods: {
    onMouseEnter() {
      this.state.hovered = true
    },
    onMouseLeave() {
      this.state.hovered = false
    },
  }
}
</script>