"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.checkStateUpdate = void 0;
var stateService_1 = require("../service/stateService");
var state_config_1 = require("../../../config/public/state.config");
function checkStateUpdate() {
    var version = stateService_1.stateService.get(stateService_1.STATE_VERSION);
    if (version !== state_config_1.stateConfig.version) {
        console.log('NEW STATE DETECKTED', version, state_config_1.stateConfig.version);
        stateService_1.stateService.clear();
        stateService_1.stateService.set(stateService_1.STATE_VERSION, state_config_1.stateConfig.version);
    }
}
exports.checkStateUpdate = checkStateUpdate;
