"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.imageMakerPublicConfig = void 0;
var config_1 = require("../config");
var ENV_1 = require("../../core/@common/constant/ENV");
if (config_1.config.env === ENV_1.ENV.PRODUCTION) {
    exports.imageMakerPublicConfig = {
        active: false,
        protocol: 'http',
        domain: 'localhost',
        port: 5003
    };
}
