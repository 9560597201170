<template>
  <App :admin="state.admin"
       :menuFixed="state.menuFixed"
       :onFixedChange="onFixedChange"
       :showBackgroundMenu="state.showBackgroundMenu"
       :onShowBackgroundChange="onShowBackgroundChange"
       :nuxtProp="nuxtProp">

    <!------------------------->
    <!-- Auth -->
    <!------------------------->

    <Auth v-if="isPath(nuxtProp, [PATHS.AUTH])" :nuxtProp="nuxtProp"/>
    <Connexion v-else-if="isPath(nuxtProp, [PATHS.LOGIN, PATHS.REDIRECT])" :nuxtProp="nuxtProp" :redirect="true"/>
    <Connexion v-else-if="isPath(nuxtProp, [PATHS.LOGIN])" :nuxtProp="nuxtProp"/>
    <Inscription v-else-if="isPath(nuxtProp, [PATHS.REGISTRATION])" :nuxtProp="nuxtProp"/>

    <!------------------------->
    <!-- Purchase -->
    <!------------------------->

    <Purchase v-else-if="isPath(nuxtProp, [PATHS.PURCHASE, PATHS.BASKET])" :nuxtProp="nuxtProp" :purchaseTab="PATHS.BASKET"></Purchase>
    <Purchase v-else-if="isPath(nuxtProp, [PATHS.PURCHASE, PATHS.DELIVERY])" :nuxtProp="nuxtProp" :purchaseTab="PATHS.DELIVERY"></Purchase>
    <Purchase v-else-if="isPath(nuxtProp, [PATHS.PURCHASE, PATHS.PAYMENT])" :nuxtProp="nuxtProp" :purchaseTab="PATHS.PAYMENT"></Purchase>
    <Purchase v-else-if="isPath(nuxtProp, [PATHS.PURCHASE, PATHS.COMPLETE])" :nuxtProp="nuxtProp" :purchaseTab="PATHS.COMPLETE"></Purchase>
    <Purchase v-else-if="isPath(nuxtProp, [PATHS.PURCHASE])" :nuxtProp="nuxtProp"></Purchase>

    <!------------------------->
    <!-- Admin -->
    <!------------------------->

    <AdminShop v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.ADMIN_SELLER)" :nuxtProp="nuxtProp"/>
    <ForumAdmin v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.ADMIN_CHAT)" :nuxtProp="nuxtProp"/>
    <AdminUser v-else-if="isPath(nuxtProp, [PATHS.ADMIN, PATHS.USER])" :nuxtProp="nuxtProp"/>
    <AdminProduct v-else-if="isPath(nuxtProp, [PATHS.ADMIN, PATHS.PRODUCT])" :nuxtProp="nuxtProp"/>
    <AdminOrder v-else-if="isPath(nuxtProp, [PATHS.ADMIN, PATHS.ORDER])" :nuxtProp="nuxtProp"/>
    <AdminMetric v-else-if="isPath(nuxtProp, [PATHS.ADMIN, PATHS.METRIC])" :nuxtProp="nuxtProp"/>
    <AdminLang v-else-if="isPath(nuxtProp, [PATHS.ADMIN, PATHS.LANG])" :nuxtProp="nuxtProp"/>
    <AdminMarketing v-else-if="isPath(nuxtProp, [PATHS.ADMIN, PATHS.MARKETING])" :nuxtProp="nuxtProp"/>
    <AdminPage v-else-if="isPath(nuxtProp, [PATHS.ADMIN, PATHS.PAGE])" :nuxtProp="nuxtProp"/>
    <AdminDashboard v-else-if="isPath(nuxtProp, [PATHS.ADMIN])" :nuxtProp="nuxtProp"/>

    <!------------------------->
    <!-- Account -->
    <!------------------------->

    <Account v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.ACCOUNT_EXPORTATION)" :nuxtProp="nuxtProp" :projectUrlId="PROJECT_URL.ACCOUNT_EXPORTATION"/>
    <Account v-else-if="isPath(nuxtProp, [PATHS.ACCOUNT, PATHS.PROFILE])" :nuxtProp="nuxtProp" :pathTab="PATHS.PROFILE"/>
    <Account v-else-if="isPath(nuxtProp, [PATHS.ACCOUNT, PATHS.ORDER])" :nuxtProp="nuxtProp" :pathTab="PATHS.ORDER"/>
    <Account v-else-if="isPath(nuxtProp, [PATHS.ACCOUNT, PATHS.ADDRESS])" :nuxtProp="nuxtProp" :pathTab="PATHS.ADDRESS"/>
    <Account v-else-if="isPath(nuxtProp, [PATHS.ACCOUNT, PATHS.NOTIFICATION])" :nuxtProp="nuxtProp" :pathTab="PATHS.NOTIFICATION"/>
    <Account v-else-if="isPath(nuxtProp, [PATHS.ACCOUNT, PATHS.COMPLETE])" :nuxtProp="nuxtProp" :pathTab="PATHS.COMPLETE"/>
    <Account v-else-if="isPath(nuxtProp, [PATHS.ACCOUNT])" :nuxtProp="nuxtProp" :pathTab="PATHS.PROFILE"/>

    <!------------------------->
    <!-- PASSWORD -->
    <!------------------------->

    <UserUpdatePassword v-else-if="isPath(nuxtProp, [PATHS.UPDATE_PASSWORD])" :nuxtProp="nuxtProp"/>
    <UserRequestPassword v-else-if="isPath(nuxtProp, [PATHS.FORGOTTEN_PASSWORD])" :nuxtProp="nuxtProp"/>

    <!------------------------->
    <!-- HOME -->
    <!------------------------->

    <Home v-else-if="isPath(nuxtProp, [PATHS.HOME]) || nuxtProp.url.paths.length < 2" :nuxtProp="nuxtProp"/>

    <!------------------------->
    <!-- CATALOGUE -->
    <!------------------------->

    <Catalogue v-else-if="isPath(nuxtProp, [PATHS.CATALOG])" :nuxtProp="nuxtProp"/>

    <template v-else-if="nuxtProp.page.categories.length && isPath(nuxtProp, nuxtProp.page.paths.map(path => path.id))">
      <Catalogue :nuxtProp="nuxtProp"/>
    </template>

    <template v-else-if="isCollection(nuxtProp)">
      <Catalogue :nuxtProp="nuxtProp"/>
    </template>

    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_PRODUITS_ENSEMBLE_SALON_DE_JARDIN)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_PRODUITS_CHAISES_TABOURETS_BANCS_DE_JARDIN)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_PRODUITS_CANAPES_FAUTEUILS_DE_JARDIN)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_TOUT_LE_TECK)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_ACCESSOIRES_DE_JARDIN_ENTRETIEN)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_ACCESSOIRES_DE_JARDIN_PIECES_DETACHEES)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LUMINAIRES_SOLAIRES_TORCHES_BORNES_ET_LAMPADAIRES_SOLAIRES)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LUMINAIRES_SOLAIRES_ACCESSOIRES)" :nuxtProp="nuxtProp"/>

    <!------------------------->
    <!-- PAGE STORIES -->
    <!------------------------->

    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_MOBILIER_DE_JARDIN)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_ENSEMBLE_DE_JARDIN)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_EN_ALUMINIUM)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_DE_LUXE)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_DESIGN)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_EN_BOIS)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_EN_TECK)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_HAUT_DE_GAMME)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_ROND)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_8_PERSONNES)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_JARDIN_10_PERSONNES)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_CHAISE_DE_JARDIN)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_CHAISE_DE_JARDIN_PLIANTE)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LUMINAIRE_SOLAIRE_INSPIRATION_LAMPE_SOLAIRE_DE_JARDIN)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LUMINAIRE_SOLAIRE_INSPIRATION_APPLIQUE_EXTERIEUR)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LUMINAIRE_SOLAIRE_INSPIRATION_APPLIQUE_DE_JARDIN)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LUMINAIRE_SOLAIRE_INSPIRATION_LAMPADAIRE_SOLAIRE_DE_JARDIN)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MOBILIER_DE_JARDIN_INSPIRATION_SALON_DE_BALCON)" :nuxtProp="nuxtProp"/>

    <!------------------------->
    <!-- OUTLET -->
    <!------------------------->

    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.OUTLET_PRODUITS_ENSEMBLE_SALON_DE_JARDIN)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.OUTLET_PRODUITS_TABLES_DE_JARDIN)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.OUTLET_PRODUITS_CHAISES_TABOURETS_BANCS_DE_JARDIN)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.OUTLET_PRODUITS_CANAPES_FAUTEUILS_DE_JARDIN)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.OUTLET_PRODUITS_BAINS_DE_SOLEIL)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.OUTLET_PRODUITS_LUMINAIRE_SOLAIRE)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.OUTLET_LES_COLLECTIONS_BRITON)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.OUTLET_LES_COLLECTIONS_DRAKAR)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.OUTLET_LES_COLLECTIONS_DRIPPER)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.OUTLET_LES_COLLECTIONS_FILACIEL)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.OUTLET_LES_COLLECTIONS_JET_STREAM)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.OUTLET_LES_COLLECTIONS_KOTON)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.OUTLET_LES_COLLECTIONS_PARALLELE)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.OUTLET_LES_COLLECTIONS_TECK)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.OUTLET_LES_COLLECTIONS_TWIG)" :nuxtProp="nuxtProp"/>
    <Catalogue v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.OUTLET_LES_COLLECTIONS_XENAH)" :nuxtProp="nuxtProp"/>

    <!------------------------->
    <!-- MARQUE -->
    <!------------------------->

    <MarqueDiscover v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MARQUE_DECOUVRIR_LES_JARDINS)" :nuxtProp="nuxtProp"/>
    <MarqueConception v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MARQUE_CONCEPTION_ET_FABRICATION)" :nuxtProp="nuxtProp"/>
    <MarqueCommitment v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MARQUE_NOS_ENGAGEMENT)" :nuxtProp="nuxtProp"/>
    <MarqueSolar v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MARQUE_LES_SOLAIRES)" :nuxtProp="nuxtProp"/>

    <!------------------------->
    <!-- FAQ -->
    <!------------------------->

    <FAQ v-else-if="isPath(nuxtProp, [PATHS.FAQ])" :nuxtProp="nuxtProp"/>
    <FAQ_1 v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_ENTRETENIR_SON_JARDIN_EN_ETE)" :nuxtProp="nuxtProp"/>
    <FAQ_2 v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_BIEN_CHOISIR_SON_BAIN_DE_SOLEIL_EN_RESINE_TRESSEE)" :nuxtProp="nuxtProp"/>
    <FAQ_3 v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_AMENAGER_SA_TERRASSE_EN_BOIS_POUR_PASSER_UN_SUPERBE_ETE)" :nuxtProp="nuxtProp"/>
    <FAQ_4 v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_BIEN_DECORER_SON_JARDIN_EN_ETE)" :nuxtProp="nuxtProp"/>
    <FAQ_5 v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_CHOISIR_SON_MOBILIER_DE_JARDIN_SI_VOUS_AVEZ_UN_BALCON)" :nuxtProp="nuxtProp"/>
    <FAQ_6 v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_BIEN_ASSOCIER_SON_MOBILIER_DE_JARDIN_AVEC_SES_PLANTES)" :nuxtProp="nuxtProp"/>
    <FAQ_7 v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_CHAISE_DE_JARDIN_ET_BAIN_DE_SOLEIL_EN_ALUMINIUM_AVANTAGES_ET_INCONVENIENTS)" :nuxtProp="nuxtProp"/>
    <FAQ_8 v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_BIEN_CHOISIR_SA_TABLE_DE_JARDIN_POUR_LETE)" :nuxtProp="nuxtProp"/>
    <FAQ_9 v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_BIEN_CHOISIR_SON_SALON_DE_JARDIN_EN_TECK)" :nuxtProp="nuxtProp"/>
    <FAQ_10 v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_NETTOYER_SA_TABLE_DE_JARDIN_EN_ALUMINIUM_ET_COMPOSITE)" :nuxtProp="nuxtProp"/>
    <FAQ_11 v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_NETTOYER_UNE_TABLE_DE_SALON_DE_JARDIN_EN_TECK)" :nuxtProp="nuxtProp"/>
    <FAQ_12 v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_MOBILIER_DE_JARDIN_EN_ALUMINIUM_4_BONNES_RAISONS_DE_CHOISIR_CE_MATERIAU_POUR_LEXTERIEUR)" :nuxtProp="nuxtProp"/>
    <FAQ_13 v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_RENOVATION_SALON_DE_JARDIN_EN_RESINE_TRESSEE)" :nuxtProp="nuxtProp"/>
    <FAQ_14 v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_MOBILIER_DE_JARDIN_RESISTANT_AUX_INTEMPERIES)" :nuxtProp="nuxtProp"/>
    <FAQ_15 v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_GAIN_DE_PLACE_POUR_PETITS_ESPACES)" :nuxtProp="nuxtProp"/>
    <FAQ_16 v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_AMENAGER_UN_COIN_SALON_DE_JARDIN)" :nuxtProp="nuxtProp"/>
    <FAQ_17 v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_AMENAGER_UN_COIN_PISCINE_EN_TERASSE_OU_JARDIN)" :nuxtProp="nuxtProp"/>
    <FAQ_18 v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_PEINDRE_SON_SALON_DE_JARDIN)" :nuxtProp="nuxtProp"/>
    <FAQ_19 v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_AMENAGER_UNE_TERRASSE_COUVERTE)" :nuxtProp="nuxtProp"/>
    <FAQ_20 v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_RENOVER_SA_TABLE_ET_SES_CHAISES_EN_BOIS)" :nuxtProp="nuxtProp"/>

    <!------------------------->
    <!-- Legal -->
    <!------------------------->

    <LegalCguCgv v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LEGAL_CGU_CGV)" :nuxtProp="nuxtProp"></LegalCguCgv>
    <LegalInformationsLegales v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LEGAL_INFORMATIONS_LÉGALES)" :nuxtProp="nuxtProp"></LegalInformationsLegales>
    <LegalConfidentialite v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LEGAL_CONFIDENTIALITÉ)" :nuxtProp="nuxtProp"></LegalConfidentialite>
    <LegalCookies v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.LEGAL_COOKIES)" :nuxtProp="nuxtProp"></LegalCookies>

    <!------------------------->
    <!-- Article -->
    <!------------------------->

    <template v-else-if="nuxtProp.page.articles.length && isPath(nuxtProp, nuxtProp.page.paths.map(path => path.id))">
      <Article :nuxtProp="nuxtProp"/>
    </template>

    <MarqueNewStore v-else-if="isProjectUrl(nuxtProp, PROJECT_URL.MARQUE_NEW_STORE)" :nuxtProp="nuxtProp"></MarqueNewStore>

  </App>
</template>

<script>

// -----

import {isHome} from "../../@common/function/isHome";

const TIME = new Date().getTime()

import {PATHS} from "../../@common/constant/PATHS";
import {PROJECT_URL} from "../../@common/constant/PROJECT_URL";

import {NuxtProp} from "../../@common/delta/nuxt/NuxtProp";
import {getNuxtPropPathIds, isPath, isProjectUrl, isCollection} from "../../@common/function/helperUrl";

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      state: {
        admin: this.isAdmin(this.nuxtProp),
        menuFixed: this.hasMenuFixed(this.nuxtProp),
        showBackgroundMenu: this.hasMenuBackground(this.nuxtProp),
      },
      PATHS,
      PROJECT_URL,
    }
  },
  watch: {
    'nuxtProp.page': {
      handler: function (v) {
        this.state.admin = this.isAdmin(this.nuxtProp)
        this.state.menuFixed = this.hasMenuFixed(this.nuxtProp)
        this.state.showBackgroundMenu = this.hasMenuBackground(this.nuxtProp)
      },
    }
  },
  beforeMount() {
    this.state.admin = this.isAdmin(this.nuxtProp)
    this.state.menuFixed = this.hasMenuFixed(this.nuxtProp)
    this.state.showBackgroundMenu = this.hasMenuBackground(this.nuxtProp)
  },
  computed: {},
  methods: {
    isPath,
    isCollection,
    isProjectUrl,
    isAdmin(nuxtProp) {
      const includePaths = [
        [
          PATHS.ADMIN,
        ].join('.'),
        [
          PATHS.ADMIN,
          PATHS.USER
        ].join('.'),
        [
          PATHS.ADMIN,
          PATHS.PRODUCT
        ].join('.'),
        [
          PATHS.ADMIN,
          PATHS.ORDER
        ].join('.'),
        [
          PATHS.ADMIN,
          PATHS.METRIC
        ].join('.'),
        [
          PATHS.ADMIN,
          PATHS.LANG
        ].join('.'),
        [
          PATHS.ADMIN,
          PATHS.MARKETING
        ].join('.'),
        [
          PATHS.ADMIN,
          PATHS.PAGE
        ].join('.'),
      ]
      const includeProjectUrls = [
        isProjectUrl(nuxtProp, PROJECT_URL.ADMIN_SELLER),
        isProjectUrl(nuxtProp, PROJECT_URL.ADMIN_CHAT),
      ]
      return includePaths.includes(getNuxtPropPathIds(nuxtProp).join('.')) || includeProjectUrls.some(a => a)
    },
    /**
     *
     * @param {NuxtProp} nuxtProp
     * @returns {boolean}
     */
    hasMenuFixed(nuxtProp) {
      return isHome(nuxtProp)
    },
    /**
     *
     * @param {NuxtProp} nuxtProp
     * @returns {boolean}
     */
    hasMenuBackground(nuxtProp) {
      return !isHome(nuxtProp)
    },
    onFixedChange(v) {
      this.state.menuFixed = v
    },
    onShowBackgroundChange(v) {
      this.state.showBackgroundMenu = v
    },
  },
  mounted() {
    console.log('[log] time for vue app to be mounted', new Date().getTime() - TIME, 'ms');
  },
  created() {
  }
}
</script>
