<style scoped>
.Tag {
}
</style>

<template>
  <div class="Tag" :class="class_root">
        <el-tag size="mini" :type="type">
          <slot></slot>
        </el-tag>
  </div>
</template>

<script>

export default {

  props: {
    type: {
      default: () => ''
    }
  },
  data() {
    return {
      props: {},
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    class_root() {
      const classes = []
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
