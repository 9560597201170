"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.getArticleStock = void 0;
var Article_1 = require("../delta/database/Article");
var isRestrictedArticle_1 = require("./isRestrictedArticle");
function getArticleStock(props) {
    // -----
    var attr = props.attr !== undefined ? props.attr : 'quantity';
    var article = new Article_1.Article(props.article);
    var roleIds = props.roleIds !== undefined ? props.roleIds : [];
    var userIds = props.userIds !== undefined ? props.userIds : [];
    var checkRestriction = props.checkRestriction !== undefined ? props.checkRestriction : false;
    var debug = false;
    if (debug)
        console.log('TOTO.checkRestriction', roleIds);
    // -----
    var stockFinal = 0;
    // -----
    if (article.batches) {
        var stocks = [];
        for (var _i = 0, _a = article.batches; _i < _a.length; _i++) {
            var batch = _a[_i];
            for (var _b = 0, _c = batch.products; _b < _c.length; _b++) {
                var product = _c[_b];
                for (var _d = 0, _e = product.stocks; _d < _e.length; _d++) {
                    var stock = _e[_d];
                    stocks.push(Math.floor(stock[attr] / batch.quantity));
                }
            }
        }
        stockFinal = stocks.sort(function (a, b) { return a - b; }).shift() || 0;
    }
    // -----
    if (checkRestriction && isRestrictedArticle_1.isRestrictedArticle({ article: article, userIds: userIds, roleIds: roleIds, stockFinal: stockFinal }))
        stockFinal = 0;
    // -----
    // when sub components is out of stock we set the article as no stock available
    // -----
    for (var _f = 0, _g = article.batches; _f < _g.length; _f++) {
        var batch = _g[_f];
        for (var _h = 0, _j = batch.products; _h < _j.length; _h++) {
            var product = _j[_h];
            for (var _k = 0, _l = product.components; _k < _l.length; _k++) {
                var component = _l[_k];
                for (var _m = 0, _o = component.productComponents; _m < _o.length; _m++) {
                    var productComponent = _o[_m];
                    for (var _p = 0, _q = productComponent.stocks; _p < _q.length; _p++) {
                        var stock = _q[_p];
                        if (stock.quantity < 1)
                            stockFinal = 0;
                    }
                }
            }
        }
    }
    // -----
    return stockFinal;
}
exports.getArticleStock = getArticleStock;
