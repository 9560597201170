"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Unit = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Tax_1 = require("./Tax");
var Unit = /** @class */ (function () {
    function Unit(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.symbol = deltaService_1.deltaService.string(props.symbol, '');
        this.taxes = deltaService_1.deltaService.array(props.taxes, Tax_1.Tax);
    }
    return Unit;
}());
exports.Unit = Unit;
