<style scoped>
.InputError {
}
</style>

<template>
  <div class="InputError">

        <el-alert
            :closable="false"
            type="error">
          <Term :term="term"/>
        </el-alert>
  </div>
</template>

<script>

import {Term} from "../../../@common/delta/database/Term";

export default {

  props: {
    term: {
      default: () => new Term()
    },
  },
  data() {
    return {
      props: {},
      state: {},
    }
  },
  watch: {},
  computed: {},
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
