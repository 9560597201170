<style scoped>
.PhotoUpload {
}
</style>

<template>
  <div class="PhotoUpload">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <FileUpload :files="state.files" :onNewFile="v => onNewFile(v)"/>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {File} from "../../../@common/delta/database/File";
import {Photo} from "../../../@common/delta/database/Photo";
import cloneDeep from "lodash/cloneDeep";

export default {

  props: {
    photos: {
      type: Array,
      default: () => ([])
    },
    onPhotosChange: {
      type: Function,
      default: () => (photos) => {
      }
    },
    onPhotoChange: {
      type: Function,
      default: () => (photo) => {
      }
    },
    cloned: {
      type: Boolean,
      default: () => false
    },
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      state: {
        /**
         * @type {Photo[]}
         */
        photos: this.cloned ? cloneDeep(this.photos) : this.photos,
        files: []
      },
    }
  },
  watch: {
    'photos': {
      handler(v) {
        this.state.photos = v
      },
      deep: true
    },
    'state.photos': {
      handler(v) {
        this.onPhotosChange(v)
      },
      deep: true
    }
  },
  computed: {},
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    /**
     * @param {File} file
     */
    onNewFile(file) {
      let photo = new Photo({
        files: [
          new File(file)
        ]
      })
      if (!this.cloned) this.photos.push(photo)
      else this.state.photos.push(photo)
      this.onPhotoChange(photo)
    }
  }
}
</script>
