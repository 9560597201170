export class Color {

    public primary: string
    public dark: string

    constructor() {
        this.primary = `#CEA481`
        this.dark = `#2B1D10`
    }
}
