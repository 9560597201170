"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.PAYMENT_STATE_LIST = void 0;
exports.PAYMENT_STATE_LIST = {
    PENDING: 1,
    PAID: 2,
    DECLINED: 3,
    REFOUND: 4,
    ERROR: 5,
};
