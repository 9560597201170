"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.noDecimalPrice = void 0;
var getDecimal_1 = require("./getDecimal");
function noDecimalPrice(amount) {
    amount = getDecimal_1.getDecimal(amount);
    amount = getDecimal_1.getDecimal(amount * 100, 0);
    var amountString = amount.toString().split('.').shift();
    amount = parseInt(amountString || '0');
    return amount;
}
exports.noDecimalPrice = noDecimalPrice;
