"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Cookie = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Cookie = /** @class */ (function () {
    function Cookie(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.configured = deltaService_1.deltaService.boolean(props.configured, false);
        this.mandatory = deltaService_1.deltaService.boolean(props.mandatory, true);
        this.analayze = deltaService_1.deltaService.boolean(props.analayze, false);
        this.marketing = deltaService_1.deltaService.boolean(props.marketing, false);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        // >>>>>
        // <<<<<
    }
    return Cookie;
}());
exports.Cookie = Cookie;
