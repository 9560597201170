"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Position = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Photo_1 = require("./Photo");
var Position = /** @class */ (function () {
    function Position(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.top = deltaService_1.deltaService.boolean(props.top, false);
        this.right = deltaService_1.deltaService.boolean(props.right, false);
        this.center = deltaService_1.deltaService.boolean(props.center, false);
        this.bottom = deltaService_1.deltaService.boolean(props.bottom, false);
        this.left = deltaService_1.deltaService.boolean(props.left, false);
        this.photos = deltaService_1.deltaService.array(props.photos, Photo_1.Photo);
    }
    return Position;
}());
exports.Position = Position;
