// -----

import { ListenerTrigger } from "@bbx/listener~master/core/delta/ListenerTrigger";

// -----

import { NuxtProp } from "../@common/delta/nuxt/NuxtProp";
import { NuxtPath } from "../@common/delta/nuxt/NuxtPath";

// -----

import { STATE_LANG, STATE_TOKEN, stateService } from "./service/stateService";
import { apiClient } from "./service/apiClientService";
import { envService } from "./service/envService";
import { urlService } from "../@common/service/urlService";
import { historyService } from "./service/historyService.ts";
import { eventService } from "./service/eventService";
import { LangServiceBrowser } from "../@common/ts/LangServiceBrowser.ts"
import { setLangServiceBrowser } from "../@common/service/langServiceBrowser.ts"
import { pageService } from "../@common/service/pageService";
import { countryService } from "./service/countryService";
import { headService } from "./service/headService";
import { seoService } from "./service/seoService";
import { scrollerParentService } from "./service/scrollerParentService";

// -----

import { EVENT } from "../@common/constant/EVENT";
import { PATHS } from "../@common/constant/PATHS";
import { LANG_ISO } from "../@common/constant/LANG";
import { FILTER_OPTION } from "../@common/constant/FILTER_OPTION";

// -----

import { getLangByNuxtProp } from "./function/getLangByNuxtProp";
import { sendError } from "./function/sendError";
import { changeRoute } from "./function/changeRoute";
import { getPageByNuxtProp, nuxtQueriesToQueryObject, setNuxtUrlWithFullPath, setNuxtUrlWithWindowLocation } from "../@common/function/helperUrl";
import { changeLocalLang } from "./function/changeLocalLang.ts"

// -----

import { config } from "../../config/config";

// -----

import middleware from "../@common/api/middleware";
import { setupCache } from "./cache/setupCache";
import { createNewDevice } from "./function/createDevice";
import { setupEvent } from "./event/setupEvent";
import { checkStateUpdate } from "./function/checkStateUpdate";

// -----

envService.setEngine('browser')

// -----

let langServiceBrowser_ = new LangServiceBrowser()

// -----

export const index = {
    data() {
        return {
            svg: {
                logo: require('../@common/assets/logo-black.svg'),
            },
            nuxtProp: new NuxtProp({
                payload: {}
            })
        }
    },
    watch: {
        $route(to, from) {

            // -----
            // we whatching the hash change
            // -----

            if (to.hash !== from.hash) eventService.triggerByName(new ListenerTrigger({
                name: EVENT.ROUTE_HASH_CHANGED,
                payload: {
                    new: to.hash,
                    old: from.hash
                }
            }))

            // -----
            // if only the hash changed, we don't pass here
            // -----

            if (to.path === from.path && JSON.stringify(to.query) === JSON.stringify(to.from)) return

            // -----
            // event before route change
            // -----

            eventService.triggerByName(new ListenerTrigger({
                name: EVENT.ROUTE_WILL_CHANGE,
                payload: this.nuxtProp
            }))

            // -----
            // change the entire url prop
            // -----

            this.nuxtProp = setNuxtUrlWithFullPath(this.nuxtProp, to.fullPath)
            this.nuxtProp.page = getPageByNuxtProp(this.nuxtProp)
            eventService.triggerByName(new ListenerTrigger({
                name: EVENT.ROUTE_QUERY_CHANGED,
                payload: this.nuxtProp.url
            }))

            // -----
            // change the entire url prop
            // -----

            let query = nuxtQueriesToQueryObject(this.nuxtProp.url.queries)
            if (!query[FILTER_OPTION.NO_SCROLL]) {
                scrollerParentService.setScrollTop(0)
            }

            // -----
            // update the seo information
            // -----

            headService.update({ nuxtProp: this.nuxtProp }).catch(sendError);

            // -----
            // fetch data for the current page
            // -----

            this.middleware().then(() => {
                eventService.triggerByName(new ListenerTrigger({
                    name: EVENT.ROUTE_HAVE_CHANGED,
                    payload: this.nuxtProp
                }))
            }).catch(sendError);
        }
    },
    async beforeMount() {
        console.log('[log] core', config.env);

        // -----

        checkStateUpdate()

        // -----

        countryService.start().catch(sendError)

        // -----

        this.nuxtProp = setNuxtUrlWithWindowLocation(this.nuxtProp, window.location)

        // -----

        if (this.nuxtProp.url.paths.length && this.nuxtProp.url.paths[0].value === "") {
            this.nuxtProp.url.paths = [
                new NuxtPath({
                    value: LANG_ISO.FR
                }),
            ]
        }
        if (!this.nuxtProp.url.paths.length) {
            this.nuxtProp.url.paths = [
                new NuxtPath({
                    value: LANG_ISO.FR
                }),
            ]
        }

        // ----------
        // we setup here all the cache of the app
        // ----------

        langServiceBrowser_ = await setupCache({ nuxtProp: this.nuxtProp, langServiceBrowser: langServiceBrowser_ })

        // -----

        this.syncInit()

        // -----

        this.nuxtProp.ready = true

        // -----

        if (seoService.isActive) headService.update({ nuxtProp: this.nuxtProp }).catch(sendError);

        // -----

        this.middleware().catch(sendError);

    },
    mounted() {
    },
    created() {
    },
    beforeDestroy() {
        eventService.clear()
        stateService.clearEvents()
    },
    destroyed() {
    },
    methods: {
        async middleware() {

            this.nuxtProp.loading = true

            const token = stateService.get(STATE_TOKEN)
            const lang = getLangByNuxtProp(this.nuxtProp)

            const response = await apiClient.do(middleware, middleware.with({
                $nuxtUrl: this.nuxtProp.url,
                $pageId: this.nuxtProp.page.id,
                _langCode: lang,
                _token: token,
            }))

            // on redireige ici
            if (!response.data.authorized) {
                return changeRoute([
                    PATHS.LOGIN,
                    PATHS.REDIRECT
                ])
            }

            this.nuxtProp.payload = response.data.payload

            this.nuxtProp.loading = false
        },
        syncInit() {

            // ----------
            // NuxtProp
            // ----------

            this.nuxtProp = new NuxtProp(this.nuxtProp)
            this.nuxtProp.page = getPageByNuxtProp(this.nuxtProp)

            // ----------
            // url
            // ----------

            urlService.createIndex(pageService.getPages())

            // ----------
            // lang
            // ----------

            const langServiceBrowser = new LangServiceBrowser(langServiceBrowser_)
            langServiceBrowser.start()
            setLangServiceBrowser(langServiceBrowser)
            changeLocalLang(langServiceBrowser.lang.iso6391)
            stateService.set(STATE_LANG, langServiceBrowser.lang.iso6391)

            // ----------
            // history
            // ----------

            historyService.set(urlService.getUrlByNuxtUrl(this.nuxtProp.url))

            // ----------
            // mac address
            // ----------

            createNewDevice().catch(sendError)

            // ----------
            // setup global events
            // ----------

            setupEvent({
                vueInstance: this,
                langServiceBrowser: langServiceBrowser
            })

            // ----------
            // we redirect to home if 404
            // ----------

            getPageByNuxtProp(this.nuxtProp, () => {
                const lang = langServiceBrowser.lang.iso6391
                const pathname = `/${lang}/${lang === 'fr' ? 'accueil' : 'home'}`
                const haveToRedirect = !['', '/', `/${lang}`, pathname].includes(window.location.pathname)
                if (haveToRedirect) window.location.pathname = pathname
            })
        },
    }
}
