"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.isRestrictedArticle = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Article_1 = require("../delta/database/Article");
var ROLE_1 = require("../constant/ROLE");
function isRestrictedArticle(props) {
    // -----
    var article = new Article_1.Article(props.article);
    var roleIds = props.roleIds !== undefined ? props.roleIds : [];
    var userIds = props.userIds !== undefined ? props.userIds : [];
    var stockFinal = props.stockFinal !== undefined ? props.stockFinal : 0;
    var debug = false;
    // -----
    var roleRestrictionsIds = [];
    var userRestrictionsIds = [];
    // -----
    if (article.roleRestrictions)
        for (var _i = 0, _a = article.roleRestrictions; _i < _a.length; _i++) {
            var roleRestriction = _a[_i];
            roleRestrictionsIds.push(roleRestriction.id);
        }
    // -----
    if (article.userRestrictions)
        for (var _b = 0, _c = article.userRestrictions; _b < _c.length; _b++) {
            var userRestriction = _c[_b];
            userRestrictionsIds.push(userRestriction.id);
        }
    // -----
    var canByRole = true;
    if (roleRestrictionsIds.length) {
        if (!roleRestrictionsIds.includes(ROLE_1.ROLE.ROOT))
            roleRestrictionsIds.push(ROLE_1.ROLE.ROOT);
        if (!roleRestrictionsIds.includes(ROLE_1.ROLE.ADMIN))
            roleRestrictionsIds.push(ROLE_1.ROLE.ADMIN);
        if (!roleRestrictionsIds.includes(ROLE_1.ROLE.CHR))
            roleRestrictionsIds.push(ROLE_1.ROLE.CHR);
        canByRole = roleIds.some(function (roleId) { return roleRestrictionsIds.includes(roleId); });
    }
    if (debug)
        console.log('TOTO.roleIds', roleIds);
    if (debug)
        console.log('TOTO.roleRestrictionsIds', roleRestrictionsIds);
    if (debug)
        console.log('TOTO.canByRole', canByRole);
    // -----
    var canByUser = true;
    if (userRestrictionsIds.length) {
        canByUser = userIds.some(function (roleId) { return userRestrictionsIds.includes(roleId); });
    }
    if (debug)
        console.log('TOTO.userIds', userIds);
    if (debug)
        console.log('TOTO.userRestrictionsIds', userRestrictionsIds);
    if (debug)
        console.log('TOTO.canByUser', canByUser);
    // -----
    var canByStock = true;
    if (article.restrictionStockActif) {
        canByStock = deltaService_1.deltaService.number(article.restrictionStockNumber, 0) <= stockFinal;
    }
    if (debug)
        console.log('TOTO.canByStock', canByUser);
    // -----
    return (!canByRole || !canByUser) && canByStock;
}
exports.isRestrictedArticle = isRestrictedArticle;
