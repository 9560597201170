"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Log = void 0;
var deltaService_1 = require("@bbx/delta~master/core/service/deltaService");
var Log = /** @class */ (function () {
    function Log(props) {
        if (props === void 0) { props = {}; }
        this.id = deltaService_1.deltaService.number(props.id, 0);
        this.active = deltaService_1.deltaService.boolean(props.active, true);
        this.createdAt = deltaService_1.deltaService.string(props.createdAt, '');
        this.updatedAt = deltaService_1.deltaService.string(props.updatedAt, '');
        this.scope = deltaService_1.deltaService.string(props.scope, '');
        this.date = deltaService_1.deltaService.string(props.date, '');
        this.origin = deltaService_1.deltaService.string(props.origin, '');
        this.type = deltaService_1.deltaService.string(props.type, '');
        this.value = deltaService_1.deltaService.string(props.value, '');
    }
    return Log;
}());
exports.Log = Log;
