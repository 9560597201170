"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.Env = void 0;
var Env = /** @class */ (function () {
    function Env() {
        this.engine = 'none';
    }
    Env.prototype.getEngine = function () {
        return this.engine;
    };
    Env.prototype.setEngine = function (engine) {
        this.engine = engine;
    };
    return Env;
}());
exports.Env = Env;
