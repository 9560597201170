<style scoped>
.FAQ {

}

.FAQ--body {
  text-align: left;
}

.FAQ--body h2 {
  padding-top: 50px;
}
</style>
<template>
  <PageInfo :nuxtProp="nuxtProp">
    <div class="FAQ--body">
      <h1>F.A.Q.</h1>
      <p>Retrouvez sur cette page des articles utiles et pratiques pour découvrir le monde du mobilier de jardin. Des
        guides pour bien choisir votre mobilier de jardin, mais aussi des astuces et conseils pour bien entretenir votre
        salon d'extérieur.
      </p>
      <br>

      <Link>
        <h2>Quels sont les délais de livraison ?</h2>
      </Link>
      <p>Votre commande est préparé sous 48 heures.</p>
      <p>Pour la France métropolitaine, les délais de livraison peuvent varier de 8 à 10 jours (jours ouvrés).</p>
      <!-- -->
      <Link>
        <h2>Quelle est la zone de livraison pour Les Jardins® ?</h2>
      </Link>
      <p>
        Pour les commandes, la livraison est possible dans toute la France Métropolitaine, seule la participation aux
        frais de livraison diffère pour la Corse (et les îles) et certaines villes en altitude ou lieux difficiles
        d’accès.
      </p>
      <p>
        Nous livrons également en Belgique, au Luxembourg, et aux Pays Bas pour ces derniers pays les frais de livraison
        sont plus élevés.
      </p>
      <!-- -->
      <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_ENTRETENIR_SON_JARDIN_EN_ETE)">
        <h2>Comment entretenir son jardin en été ?</h2>
      </Link>
      <p>Découvrez toutes nos idées pour entretenir votre jardin : arrosage, débroussaillage et mobilier de jardin … Nos
        conseils vous assurent de profiter pleinement de votre extérieur tout l’été !
      </p>
      <!-- -->
      <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_BIEN_CHOISIR_SON_BAIN_DE_SOLEIL_EN_RESINE_TRESSEE)">
        <h2>Comment choisir son bain de soleil en résine tressée ?</h2>
      </Link>
      <p>Tous nos conseils pour l'année 2018 : comment choisir, guide d'achat et comment entretenir votre bain de soleil
        en résine tressée ! .
      </p>
      <!-- -->
      <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_AMENAGER_SA_TERRASSE_EN_BOIS_POUR_PASSER_UN_SUPERBE_ETE)">
        <h2>Comment aménager sa terrasse en bois pour passer un superbe été ?</h2>
      </Link>
      <p>Vous venez de refaire votre terrasse avec un sol tout en bois et vous cherchez des idées pour l’aménagement
        extérieur ? Mobilier, piscine, vis-à-vis et décoration végétale voici notre guide sur le sujet. .
      </p>
      <!-- -->
      <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_BIEN_DECORER_SON_JARDIN_EN_ETE)">
        <h2>Comment bien décorer son jardin en été ?</h2>
      </Link>
      <p>Espace de vie à part entière, le jardin est un trésor à mettre en valeur. Quand l'été frappe à sa porte, il est
        temps de bichonner et de prendre soin de cet espace privilégié.
      </p>
      <!-- -->
      <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_CHOISIR_SON_MOBILIER_DE_JARDIN_SI_VOUS_AVEZ_UN_BALCON)">
        <h2>Comment choisir son mobilier de jardin si vous avez un balcon ?</h2>
      </Link>
      <p> Vous avez un balcon et souhaitez y aménager un coin repas / détente, Les Jardins® vous conseille de choisir
        une table pliante afin d’optimiser son rangement.
      </p>
      <!-- -->
      <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_BIEN_ASSOCIER_SON_MOBILIER_DE_JARDIN_AVEC_SES_PLANTES)">
        <h2>Comment bien associer son mobilier de jardin avec ses plantes ?</h2>
      </Link>
      <p>Associer les plantes de son jardin avec son mobilier n'est pas donné à tous le monde. Voici donc un petit guide
        pour vos aider à choisir les plantes qui iront le mieux avec votre mobilier d'extérieur.
      </p>
      <!-- -->
      <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_CHAISE_DE_JARDIN_ET_BAIN_DE_SOLEIL_EN_ALUMINIUM_AVANTAGES_ET_INCONVENIENTS)">
        <h2>Chaise de jardin et bain de soleil en aluminium : avantages et inconvénients</h2>
      </Link>
      <p>Comme tous les éléments d’un salon de jardin, la chaise ou le bain de soleil sont des éléments très appréciés
        qui doivent être confortables et stables, quelle que soit la personne qui s'y assoie. L’aluminium est un
        matériau inoxydable qui peut être travaillé pour proposer des modèles de chaises classiques, de chaises longues
        ou de bains de soleil résistants aux intempéries et parfaitement adaptés à l’extérieur.
      </p>
      <!-- -->
      <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_BIEN_CHOISIR_SA_TABLE_DE_JARDIN_POUR_LETE)">
        <h2>Comment bien choisir sa table de jardin pour l'été ?</h2>
      </Link>
      <p>La table de jardin, l'élément de mobilier incontournable pour prendre un repas au soleil et pour profiter du
        beau temps. Elle doit donc être choisie avec soin. Vous devez tenir compte de plusieurs facteurs lorsque vous
        sélectionnez votre table d'extérieur.
      </p>
      <!-- -->
      <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_BIEN_CHOISIR_SON_SALON_DE_JARDIN_EN_TECK)">
        <h2>Comment bien choisir son salon de jardin en teck ?</h2>
      </Link>
      <p>Les salons en bois de teck ont beaucoup de succès depuis de nombreuses années. Leur style intemporel et la
        qualité du matériau ayant fait leurs preuves. Petit article pour vous aider à choisir.
      </p>
      <!-- -->
      <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_NETTOYER_SA_TABLE_DE_JARDIN_EN_ALUMINIUM_ET_COMPOSITE)">
        <h2>Comment nettoyer sa table de jardin en aluminium et composite ?</h2>
      </Link>
      <p> Une table de jardin en aluminium et composite est fabriquée pour durer des années. Une éponge, de l’eau
        savonneuse et un bon coup de chiffon suffisent pour l’entretien d’usage et le nettoyage des petites taches !
      </p>
      <!-- -->
      <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_COMMENT_NETTOYER_UNE_TABLE_DE_SALON_DE_JARDIN_EN_TECK)">
        <h2>Comment nettoyer une table de salon de jardin en teck ?</h2>
      </Link>
      <p> Le teck est un bois imputrescible très utilisé pour les meubles de jardin, mais comment le nettoyer ? On vous
        donne des pistes !
      </p>
      <!-- -->
      <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_MOBILIER_DE_JARDIN_EN_ALUMINIUM_4_BONNES_RAISONS_DE_CHOISIR_CE_MATERIAU_POUR_LEXTERIEUR)">
        <h2>Mobilier de jardin en aluminium : 4 bonnes raisons de choisir ce matériau pour l'extérieur</h2>
      </Link>
      <p>Tout mobilier de jardin a pour vocation l'aménagement et la décoration d'un espace dédié à la détente et
        agréable à vivre. L'aluminium offre des qualités inégalées, voyons ensemble pourquoi.
      </p>
      <!-- -->
      <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_RENOVATION_SALON_DE_JARDIN_EN_RESINE_TRESSEE)">
        <h2>Rénovation salon de jardin en résine tressée</h2>
      </Link>
      <p>Découvrez comment entretenir et rénover votre mobilier de jardin en résine tressée, et donnez une seconde
        jeunesse à vos meubles d’extérieur.
      </p>
      <!-- -->
      <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_MOBILIER_DE_JARDIN_RESISTANT_AUX_INTEMPERIES)">
        <h2>Mobilier de jardin résistant aux intempéries</h2>
      </Link>
      <p>Vous souhaitez investir dans du mobilier résistant pour votre jardin ? Découvrez nos conseils pour choisir des
        meubles d’extérieur supportant les intempéries.
      </p>
      <!-- -->
      <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_GAIN_DE_PLACE_POUR_PETITS_ESPACES)">
        <h2>Gain de place pour petits espaces</h2>
      </Link>
      <p>Différentes solutions existent pour maximiser la place disponible de nos extérieurs. Certains meubles de jardin
        sont adaptés pour les petits espaces.
      </p>
      <!-- -->
      <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_AMENAGER_UN_COIN_SALON_DE_JARDIN)">
        <h2>Aménager un coin salon de jardin</h2>
      </Link>
      <p>Découvrez nos conseils pour aménager votre extérieur afin qu’il devienne une pièce à part entière de votre
        maison, cosy et agréable à vivre.
      </p>
      <!-- -->
      <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_AMENAGER_UN_COIN_PISCINE_EN_TERASSE_OU_JARDIN)">
        <h2>Aménager un coin piscine en terasse ou jardin</h2>
      </Link>
      <p>Découvrez comment aménager les abords de votre piscine pour en faire un lieu agréable grâce à du mobilier et
        une décoration adaptée.
      </p>
      <!-- -->
      <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_PEINDRE_SON_SALON_DE_JARDIN)">
        <h2>Peindre son salon de jardin</h2>
      </Link>
      <p>Vous souhaitez rénover vos meubles de jardin défraîchis et leur apporter de la couleur ? Découvrez nos conseils
        pour peindre votre mobilier extérieur
      </p>
      <!-- -->
      <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_AMENAGER_UNE_TERRASSE_COUVERTE)">
        <h2>Aménager une terrasse couverte</h2>
      </Link>
      <p>Pour profiter de son extérieur, quels que soient les caprices de la météo, une terrasse couverte est une bonne
        solution : découvrez nos idées d’aménagement.
      </p>
      <!-- -->
      <Link :url="url(PROJECT_URL.LA_MARQUE_AIDE_SUPPORT_FAQ_RENOVER_SA_TABLE_ET_SES_CHAISES_EN_BOIS)">
        <h2>Rénover sa table et ses chaises en bois</h2>
      </Link>
      <p>Découvrez les astuces et les conseils pour rénover vos meubles en bois et leur donner un aspect neuf ou un
        nouveau style.
      </p>
      <!-- -->

    </div>
  </PageInfo>
</template>


<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {NuxtProp} from "../../../@common/delta/nuxt/NuxtProp";
import {getH1Term} from "../../function/getH1";
import {Term} from "../../../@common/delta/database/Term";
import {getUrlByProjectUrlId} from "../../../@common/function/helperUrl";
import {PROJECT_URL} from "../../../@common/constant/PROJECT_URL";

export default {
  props: {
    nuxtProp: {
      default: () => new NuxtProp()
    }
  },
  data() {
    return {
      PROJECT_URL,
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {},
      state: {},
    }
  },
  watch: {},
  computed: {
    H1() {
      const terms = getH1Term(this.nuxtProp)
      return new Term(terms[terms.length - 1])
    },
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`FAQ-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`FAQ-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`FAQ-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    url(projectUrlId) {
      return getUrlByProjectUrlId(this.nuxtProp, projectUrlId)
    }
  }
}
</script>
