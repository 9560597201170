// http://en.wikipedia.org/wiki/ISO_3166-1_alpha-2
export const COUNTRY_ISO = {
    FR: 'FR',
    US: 'US',
    NL: 'NL',
    BE: 'BE',
    LU: 'LU',
    CH: 'CH',
}

export const COUNTRY_ID = {
    FR: 1,
    NL: 2, // Pays-Bas
    BE: 3,
    LU: 4,
    CH: 5, //Suisse
}
