<style scoped>
.UserForm {
}
</style>

<template>
  <div class="UserForm" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <InputSection :svg="icon.vpn_key" :term="lang.term(427)">
        <InputText :value="state.user.email"
                   :mandatory="true"
                   :onChangeValue="v => state.user.email = v"
                   :label="lang.term(429)"
                   :error="form.display(`email`)">
        </InputText>
        <br>
        <InputText :value="state.user.password"
                   :mandatory="!state.user.id"
                   :password="true"
                   :onChangeValue="v => state.user.password = v"
                   :label="lang.term(103)"
                   :error="form.display(`password`)">
        </InputText>
        <br>
        <InputSelect :items="state.roles"
                     :mandatory="true"
                     :values="state.user.roles"
                     :max="1"
                     :loading="state.loadingRole"
                     :label="lang.term(428)"
                     :onChangeValues="v => state.user.roles = v"
                     :error="form.display(`roles`)">
        </InputSelect>
      </InputSection>

      <InputSection :svg="icon.subject" :term="lang.term(426)">
        <InputText :value="state.user.firstName"
                   :mandatory="isMandatory"
                   :onChangeValue="v => state.user.firstName = v"
                   :label="lang.term(119)"
                   :error="form.display(`firstName`)">
        </InputText>
        <br>
        <InputText :value="state.user.lastName"
                   :mandatory="isMandatory"
                   :onChangeValue="v => state.user.lastName = v"
                   :label="lang.term(118)"
                   :error="form.display(`lastName`)">
        </InputText>
      </InputSection>

      <InputSection :svg="icon.store" :term="lang.term(598)" v-if="isSeller">
        <InputSelect :remotable="true"
                     :onRemoteChange="remoteShops"
                     :items="state.shops.length ? state.shops : state.user.shops"
                     :values="state.user.shops"
                     :label="lang.term(599)"
                     :error="form.display(`user.shops`)"
                     :display="displayShop"
                     :max="9999"
                     :collapseTags="true"
                     :onChangeValues="shops => onChangeShopValues(shops)"
                     :loading="state.loadingShop">
        </InputSelect>
      </InputSection>

      <br>

    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {ApiResponseForm} from "../../../@common/delta/http/ApiResponse";
import {User} from "../../../@common/delta/database/User";
import {apiClient} from "../../service/apiClientService";
import selectRole from "../../../@common/api/role/selectRole";
import {ROLE} from "../../../@common/constant/ROLE";
import selectShops from "../../../@common/api/shop/selectShops";
import {toCapitalyze} from "../../../@common/function/toCapitalyze";
import {Shop} from "../../../@common/delta/database/Shop";
import {sendError} from "../../function/sendError";

export default {

  props: {
    user: {
      default: () => new User()
    },
    onUserChange: {
      type: Function,
      default: () => (user) => {
      }
    },
    form: {
      default: () => new ApiResponseForm()
    },
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
        style: require('@bbx/vector~master/core/assets/svg/material/style.svg'),
        subject: require('@bbx/vector~master/core/assets/svg/material/subject.svg'),
        vpn_key: require('@bbx/vector~master/core/assets/svg/material/vpn_key.svg'),
        phone: require('@bbx/vector~master/core/assets/svg/material/phone.svg'),
        store: require('@bbx/vector~master/core/assets/svg/material/store.svg'),
      },
      state: {
        /**
         * @type boolean
         */
        loadingRole: false,
        /**
         * @type boolean
         */
        loadingShop: false,
        /**
         * @type Role[]
         */
        roles: [],
        /**
         * @type Shop[]
         */
        shops: [],
        /**
         * @type User
         */
        user: new User(this.user)
      },
    }
  },
  watch: {
    'user': {
      handler(v) {
        this.state.user = v
      },
      deep: true
    },
    'state.user': {
      handler(v) {
        this.onUserChange(v)
      },
      deep: true
    },
  },
  computed: {
    class_root() {
      const classes = []
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`UserForm-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`UserForm-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`UserForm-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`UserForm-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`UserForm-desktopY`)
      return classes
    },
    isMandatory() {
      return this.state.user.roles.some(role => role.id !== ROLE.SELLER)
    },
    isSeller() {
      return this.state.user.roles.some(role => role.id === ROLE.SELLER)
    }
  },
  beforeMount() {
    this.selectRole().catch(sendError)
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {
    async selectRole() {
      this.state.loadingRole = true
      const filter = {
        active: true,
      }
      apiClient.do(selectRole, selectRole.with(filter)).then((res) => {
        this.state.roles = res.data;
        this.state.loadingRole = false
      }).catch(err => {
        this.state.loadingRole = false
        sendError(err)
      });
    },

    remoteShops(query) {
      if (!query) return this.state.shops = []
      if (this.state.loadingShop) return
      this.state.loadingShop = true
      apiClient.do(selectShops, selectShops.with({
        active: true,
        $search: query,
      })).then(res => {
        this.state.shops = res.data
        this.state.loadingShop = false
      }).catch(err => {
        this.state.loadingShop = false
        sendError(err);
      });
    },

    /**
     * @param {Shop} shop
     */
    displayShop(shop) {
      let names = []
      for (const sage of shop.sages) {
        names.push(sage.uuid)
      }
      let name = names.join(' ')
      let displays = []
      if (name) displays.push(name)
      displays.push(toCapitalyze(shop.name.trim()))
      return displays.join(' - ')
    },

    /**
     * @param {Shop[]} shops
     */
    onChangeShopValues(shops) {
      if (JSON.stringify(this.state.user.shops) !== JSON.stringify(shops)) {
        this.state.user.shops = shops.map(shop => new Shop(shop))
        this.state.shops = []
      }
    },
  }
}
</script>
