<style scoped>
.PhotoUpload {
}
</style>

<template>
  <div class="PhotoUpload">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <template v-for="(photo, index_photo) in photos">
        <slot :photo="photo"/>
        <FileDownload :files="photo.files"
                      :updatable="updatable"
                      :onClickUpdate="() => onClickUpdate(photo, index_photo)"
                      :onClickDelete="() => onClickDelete(index_photo)"/>
      </template>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';

export default {

  props: {
    photos: {
      type: Array,
      default: () => ([])
    },
    onClickDelete: {
      type: Function,
      default: () => (index_file) => {
      }
    },
    updatable: {
      type: Boolean,
      default: () => true
    },
    onClickUpdate: {
      type: Function,
      default: () => (file) => {
      }
    },
  },
  data() {
    return {
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 810
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1420
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 600
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        tune: require('@bbx/vector~master/core/assets/svg/material/tune.svg'),
      },
      state: {},
    }
  },
  watch: {},
  computed: {},
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
