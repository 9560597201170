<style scoped lang="scss">

@import "../../scss/color";

.MenuSearch {
  width: 100%;
  overflow: hidden;
}

.MenuSearch-mobileY.MenuSearch {
  overflow: auto;
  height: 100vh;
}

.MenuSearch--content {
  width: 100%;
  height: 100%;
}

.MenuSearch--header {
  width: 100%;
  display: flex;
  align-items: center;
  padding-top: 50px;
  transform: translateY(-100px);
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  box-sizing: border-box;
}

.MenuSearch-desktop .MenuSearch--header {
  justify-content: center;
}

.MenuSearch-tablet .MenuSearch--header {
  justify-content: center;
}

.MenuSearch-mobile .MenuSearch--header {
  justify-content: space-between;
  padding-left: 25px;
  padding-right: 25px;
}

.MenuSearch-focused .MenuSearch--header {
  transform: translateY(0px);
  opacity: 1;
}

.MenuSearch--icon {
}

.MenuSearch-desktop .MenuSearch--icon {
  padding-right: 20px;
  width: 36px;
}

.MenuSearch-tablet .MenuSearch--icon {
  padding-right: 20px;
  width: 36px;
}

.MenuSearch-mobile .MenuSearch--icon {
  width: 24px;
}

.MenuSearch--icon ::v-deep .Vector svg {
  fill: $primary;
}

.MenuSearch--close {
  cursor: pointer;
}

.MenuSearch-desktop .MenuSearch--close {
  top: 50px;
  right: 100px;
  width: 36px;
  position: absolute;
}

.MenuSearch-tablet .MenuSearch--close {
  width: 36px;
  position: absolute;
  top: 50px;
  right: 50px;
}

.MenuSearch-mobile .MenuSearch--close {
  top: 50px;
  right: 50px;
  width: 24px;
}

.MenuSearch--close ::v-deep .Vector svg {
  fill: $primary;
}

.MenuSearch--input {
  background: none;
  border: none;
  font-size: 20px;
  outline: none;
  border-bottom: solid 1px $primary;
  padding: 5px 0;
  color: $primary;
  border-radius: 0;
}

.MenuSearch-desktop .MenuSearch--input {

}

.MenuSearch-tablet .MenuSearch--input {

}

.MenuSearch-mobile .MenuSearch--input {
  font-size: 15px;
  position: relative;
  top: -6px;
}

/**/

.MenuSearch--body {
  width: 100%;
}

.MenuSearch-desktop .MenuSearch--body {
  height: calc(500px - 126px);
}

.MenuSearch-tablet .MenuSearch--body {
  height: calc(100vh - 176px);
}

.MenuSearch-mobile .MenuSearch--body {
  height: calc(100vh - 176px);
}

.MenuSearch--footer {
  transform: translateY(100px);
  opacity: 0;
  transition: opacity 300ms, transform 300ms;
  display: flex;
  justify-content: center;
}

.MenuSearch-focused .MenuSearch--footer {
  transform: translateY(0px);
  opacity: 1;
}

.MenuSearch--footerButton {
  padding: 10px 30px;
  border: solid 1px $primary;
  font-family: 'MontserratSemiBold', sans-serif;
  color: $primary;
  font-size: 11px;
  letter-spacing: 1px;
  text-transform: uppercase;
  cursor: pointer;
  border-radius: 4px;
  background: transparent;
}
</style>

<template>
  <div class="MenuSearch" :class="class_root">
    <Responsive :breakPoint="props.Responsive['0'].breakPoint"
                :verticalBreakPoint="props.Responsive['0'].verticalBreakPoint"
                :breakPoints="props.Responsive['0'].breakPoints"
                :verticalBreakPoints="props.Responsive['0'].verticalBreakPoints"
                :onBreakPointChange="props.Responsive['0'].onBreakPointChange"
                :onVerticalBreakPointChange="props.Responsive['0'].onVerticalBreakPointChange">
      <div class="MenuSearch--content">
        <div class="MenuSearch--header">
          <div class="MenuSearch--icon">
            <Vector :svg="icon.search"></Vector>
          </div>
          <input class="MenuSearch--input"
                 :placeholder="lang.translate(94)"
                 type="text"
                 ref="input"
                 v-model="state.search">
          <div class="MenuSearch--close" @click="() => onClickClose()">
            <Vector :svg="icon.clear"></Vector>
          </div>
        </div>
        <div class="MenuSearch--body">
          <ArticleSearch :search="state.search"></ArticleSearch>
        </div>
        <div class="MenuSearch--footer" v-show="state.search" @click="() => onClickClose()">
          <Link :link="getCatalogUrl">
            <button class="MenuSearch--footerButton">
              <Term :term="lang.term(288)"/>
            </button>
          </Link>
        </div>
      </div>
    </Responsive>
  </div>
</template>

<script>

import BreakPoint from '@bbx/responsive~master/core/delta/BreakPoint';
import SCREEN from '@bbx/responsive~master/core/constant/SCREEN';
import {getLangServiceBrowser} from "../../../@common/service/langServiceBrowser";
import {urlService} from "../../../@common/service/urlService";
import {PATHS} from "../../../@common/constant/PATHS";
import {FILTER_OPTION} from "../../../@common/constant/FILTER_OPTION";

export default {

  props: {
    focused: {
      type: Boolean,
      default: () => false
    },
    onClickClose: {
      type: Function,
      default: () => () => {
      }
    }
  },
  data() {
    return {
      lang: getLangServiceBrowser(),
      props: {
        Responsive: {
          "0": {
            breakPoint: new BreakPoint({
              name: SCREEN.DESKTOP
            }),
            verticalBreakPoint: new BreakPoint(),
            breakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                width: 0
              }),
              new BreakPoint({
                name: SCREEN.TABLET,
                width: 920
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                width: 1500
              }),
            ],
            verticalBreakPoints: [
              new BreakPoint({
                name: SCREEN.MOBILE,
                height: 0
              }),
              new BreakPoint({
                name: SCREEN.DESKTOP,
                height: 500
              }),
            ],
            onBreakPointChange: (v) => this.props.Responsive['0'].breakPoint = v,
            onVerticalBreakPointChange: (v) => this.props.Responsive['0'].verticalBreakPoint = v,
          },
        },
      },
      icon: {
        search: require('@bbx/vector~master/core/assets/svg/material/search.svg'),
        clear: require('@bbx/vector~master/core/assets/svg/material/clear.svg'),
      },
      state: {
        search: '',
        focused: false,
      },
    }
  },
  watch: {
    focused: {
      handler(v) {
        if (v) this.$refs.input.focus()
        if (v) setTimeout(() => {
          this.state.focused = true
          this.state.search = ''
        }, 200)
        else this.state.focused = false
      },
      deep: true
    }
  },
  computed: {
    getCatalogUrl() {
      return urlService.getUrl(this.lang.lang.iso6391, [PATHS.CATALOG], {[FILTER_OPTION.SEARCH]: this.state.search})
    },
    class_root() {
      const classes = []
      if (this.state.focused) classes.push('MenuSearch-focused')
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.MOBILE) classes.push(`MenuSearch-mobile`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.TABLET) classes.push(`MenuSearch-tablet`)
      if (this.props.Responsive['0'].breakPoint.name === SCREEN.DESKTOP) classes.push(`MenuSearch-desktop`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.MOBILE) classes.push(`MenuSearch-mobileY`)
      if (this.props.Responsive['0'].verticalBreakPoint.name === SCREEN.DESKTOP) classes.push(`MenuSearch-desktopY`)
      return classes
    }
  },
  beforeMount() {
  },
  mounted() {
  },
  beforeDestroy() {
  },
  methods: {}
}
</script>
